import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import { getInnovator } from 'modules/account';
import media from 'styles/media';
import {
  OutputsField,
  EnglishSkillField,
  CertificationsField,
  PreferredWorkLocationsField,
  PreferredOccupationsField,
  DttScoreField,
  DttRankField,
  NewGraduateProgrammingExperiencesField,
  NewGraduatePreferredWorkingStyleField,
  NewGraduatePreferredBusinessTypesField,
} from 'components/organisms';

const Wrapper = styled.div`
  margin-bottom: -40px;

  ${media.mobile} {
    margin-bottom: -32px;
  }
`;

const NewGraduateProfileSkillsAndPreferredConditionsContents: FC = () => {
  const innovator = useSelector(getInnovator);

  if (!innovator) return null;

  return (
    <Wrapper data-testid="new-graduate-profile-skills-and-preferred-conditions-contents">
      <EnglishSkillField englishSkill={innovator.englishSkill} />

      <CertificationsField certifications={innovator.certifications} />

      <DttScoreField dttScore={innovator.dttScore} />

      <DttRankField dttRank={innovator.dttRank} />

      <NewGraduateProgrammingExperiencesField
        programmingExperiences={innovator.newGraduate?.programmingExperiences}
      />

      <OutputsField outputs={innovator.outputs} />

      <NewGraduatePreferredWorkingStyleField
        preferredWorkStyle={innovator.newGraduate?.preferredWorkStyle}
      />

      <NewGraduatePreferredBusinessTypesField
        preferredBusinessTypes={innovator.newGraduate?.preferredBusinessTypes}
      />

      <PreferredOccupationsField
        preferredOccupations={innovator.preferredOccupations}
      />

      <PreferredWorkLocationsField
        preferredWorkLocations={innovator.preferredWorkLocations}
      />
    </Wrapper>
  );
};

export default NewGraduateProfileSkillsAndPreferredConditionsContents;
