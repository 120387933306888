import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { ProfileFormValues, ProfileFormRegister } from 'types/profile';
import { PROFILE_FIELDS_MAX_LENGTH } from 'constants/profile';
import * as MESSAGES from 'constants/messages';
import { TextInput } from 'components/atoms';
import theme from 'styles/theme';
import { Field } from 'components/molecules';

const StyledTextInput = styled(TextInput)`
  margin-right: 8px;

  input {
    width: 176px;
  }
`;

const FieldInner = styled.div`
  position: relative;
`;

const Unit = styled.div`
  position: absolute;
  top: 50%;
  left: 184px;
  transform: translateY(-50%);
  color: ${theme.textBody};
  font-size: 14px;
  line-height: 1.5;
`;

type CareerMonthlyUnitPriceFormFieldProps = {
  error: string | undefined;
  register: ProfileFormRegister<{
    career: Pick<ProfileFormValues['career'], 'monthlyUnitPrice'>;
  }>;
};

const CareerMonthlyUnitPriceFormField: FC<CareerMonthlyUnitPriceFormFieldProps> = props => {
  const { error, register } = props;

  return (
    <Field
      label={`${MESSAGES.PROFILE.FIELD.CAREER_MONTHLY_UNIT_PRICE.LABEL}${MESSAGES.PROFILE.FIELD.CAREER_MONTHLY_UNIT_PRICE.LABEL_NOTE}`}
      locked
    >
      <FieldInner>
        <StyledTextInput
          enterKeyHint="enter"
          name="career.monthlyUnitPrice"
          inputMode="numeric"
          error={error}
          maxLength={PROFILE_FIELDS_MAX_LENGTH.CAREER_MONTHLY_UNIT_PRICE}
          aria-label={MESSAGES.PROFILE.FIELD.CAREER_MONTHLY_UNIT_PRICE.LABEL}
          ref={register}
        />
        <Unit>{MESSAGES.PROFILE.FIELD.CAREER_MONTHLY_UNIT_PRICE.UNIT}</Unit>
      </FieldInner>
    </Field>
  );
};

export default CareerMonthlyUnitPriceFormField;
