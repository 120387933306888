import * as MESSAGES from 'constants/messages';

export const stepsCareer = {
  formFirst: {
    stepIndex: 0,
    completed: false,
    label: MESSAGES.REGISTER_PROFILE.STEP.INPUT_PROFILE1,
  },
  formSecond: {
    stepIndex: 1,
    completed: false,
    label: MESSAGES.REGISTER_PROFILE.STEP.INPUT_PROFILE2,
  },
  confirm: {
    stepIndex: 2,
    completed: true,
    label: MESSAGES.REGISTER_PROFILE.STEP.CONFIRM_INPUTS,
  },
  completed: {
    stepIndex: 3,
    completed: true,
    label: MESSAGES.REGISTER_PROFILE.STEP.REGISTRATION_COMPLETED,
  },
};

export const stepsNewGraduate = {
  formFirst: {
    stepIndex: 0,
    completed: false,
    label: MESSAGES.REGISTER_PROFILE.STEP.INPUT_PROFILE1,
  },
  formSecond: {
    stepIndex: 1,
    completed: false,
    label: MESSAGES.REGISTER_PROFILE.STEP.INPUT_PROFILE2,
  },
  formThird: {
    stepIndex: 2,
    completed: false,
    label: MESSAGES.REGISTER_PROFILE.STEP.INPUT_PROFILE3,
  },
  confirm: {
    stepIndex: 3,
    completed: true,
    label: MESSAGES.REGISTER_PROFILE.STEP.CONFIRM_INPUTS,
  },
  completed: {
    stepIndex: 4,
    completed: true,
    label: MESSAGES.REGISTER_PROFILE.STEP.REGISTRATION_COMPLETED,
  },
};
