import React, { FC } from 'react';
import * as MESSAGES from 'constants/messages';
import { PROFILE_FIELDS_MAX_LENGTH } from 'constants/profile';
import { ProfileFormRegister, ProfileFormValues } from 'types/profile';
import { TextInput } from 'components/atoms';
import { Field } from 'components/molecules';

type Value = {
  newGraduate: Pick<ProfileFormValues['newGraduate'], 'internshipExperiences'>;
};

type CompanyNameFormFieldProps = {
  register: ProfileFormRegister<Value>;
  index: number;
  defaultValue:
    | ProfileFormValues['newGraduate']['internshipExperiences'][0]['companyName']
    | undefined;
};

const CompanyNameFormField: FC<CompanyNameFormFieldProps> = props => {
  const { register, index, defaultValue } = props;
  return (
    <Field
      label={
        MESSAGES.PROFILE.FIELD.NEW_GRADUATE_INTERNSHIP_EXPERIENCES.COMPANY_NAME
          .LABEL
      }
    >
      <TextInput
        enterKeyHint="enter"
        name={`newGraduate.internshipExperiences[${index}].companyName`}
        maxLength={
          PROFILE_FIELDS_MAX_LENGTH.NEW_GRADUATE_INTERNSHIP_EXPERIENCES_COMPANY_NAME
        }
        aria-label={
          MESSAGES.PROFILE.FIELD.NEW_GRADUATE_INTERNSHIP_EXPERIENCES
            .COMPANY_NAME.LABEL
        }
        ref={register()}
        defaultValue={defaultValue}
      />
    </Field>
  );
};

export default CompanyNameFormField;
