import React, { FC } from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import styled from 'styled-components/macro';
import theme from 'styles/theme';
import media from 'styles/media';
import * as MESSAGES from 'constants/messages';
import { NO_SELECT_NUMBER } from 'constants/form';
import {
  MAX_NEW_GRADUATE_PROGRAMMING_EXPERIENCES,
  PROFILE_FIELDS_MAX_LENGTH,
} from 'constants/profile';
import {
  ProfileFormItems,
  ProfileFormControl,
  ProfileFormValues,
  ProfileFormMethods,
  ProfileFormRegister,
} from 'types/profile';
import { CircleCross } from 'assets/svg';
import { Selectbox, AddField, TextInput, ErrorMessage } from 'components/atoms';
import { FieldLabel as BaseFieldLabel } from 'components/molecules';

const Wrapper = styled.div`
  margin-bottom: 40px;

  ${media.mobile} {
    margin-bottom: 32px;
  }
`;

const FieldsContainer = styled.div`
  display: flex;
  margin-bottom: 16px;
`;

const FieldLabel = styled(BaseFieldLabel)``;

const Fields = styled.div`
  ${media.mobile} {
    width: 100%;
  }
`;

const Field = styled.div.attrs({ 'data-testid': 'field' })``;

const StyledTextInput = styled(TextInput)`
  width: 410px;

  ${media.mobile} {
    width: 100%;
  }
`;

const StyledSelectBox = styled(Selectbox)`
  width: 410px;

  ${media.mobile} {
    width: 100%;
  }
`;

const RemoveProgrammingExperience = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  padding-left: 8px;
  border-left: 1px solid ${theme.borderDefault};
`;

const IconRemove = styled(CircleCross)`
  display: inline-block;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin-top: -10px;
  margin-bottom: 10px;
`;

type Value = {
  newGraduate: Pick<ProfileFormValues['newGraduate'], 'programmingExperiences'>;
};

type NewGraduateProgrammingExperiencesFormFieldProps = {
  control: ProfileFormControl<Value>;
  register: ProfileFormRegister<Value>;
  errors: (string | undefined)[];
  touched: NonNullable<
    ProfileFormMethods<Value>['formState']['touched']['newGraduate']
  >['programmingExperiences'];
  trigger: ProfileFormMethods<Value>['trigger'];
  programmingExperienceLevels: ProfileFormItems['programmingExperienceLevels'];
};

const NewGraduateProgrammingExperiencesFormField: FC<NewGraduateProgrammingExperiencesFormFieldProps> = props => {
  const {
    errors,
    touched,
    control,
    register,
    trigger,
    programmingExperienceLevels,
  } = props;

  const { fields, append, remove } = useFieldArray({
    control,
    name: `newGraduate.programmingExperiences`,
  });

  return (
    <Wrapper>
      <FieldLabel
        label={
          MESSAGES.PROFILE.FIELD.NEW_GRADUATE_PROGRAMMING_EXPERIENCES.LABEL
        }
      />
      {fields.map((field, index) => (
        <div key={field.id}>
          <FieldsContainer data-testid="new-graduate-programming-experiences-fields">
            <Fields>
              <Field style={{ marginBottom: '8px' }}>
                <StyledTextInput
                  enterKeyHint="enter"
                  name={`newGraduate.programmingExperiences[${index}].languageName`}
                  maxLength={
                    PROFILE_FIELDS_MAX_LENGTH.NEW_GRADUATE_PROGRAMMING_EXPERIENCE_LANGUAGE_NAME
                  }
                  placeholder={
                    MESSAGES.PROFILE.FIELD.NEW_GRADUATE_PROGRAMMING_EXPERIENCES
                      .LANGUAGE_NAME.PLACEHOLDER
                  }
                  aria-label={
                    MESSAGES.PROFILE.FIELD.NEW_GRADUATE_PROGRAMMING_EXPERIENCES
                      .LANGUAGE_NAME.LABEL
                  }
                  onChange={() => {
                    trigger(
                      `newGraduate.programmingExperiences[${index}].levelId`,
                    );
                  }}
                  onBlur={() => {
                    trigger(
                      `newGraduate.programmingExperiences[${index}].levelId`,
                    );
                  }}
                  defaultValue={field.languageName}
                  ref={register()}
                />
              </Field>

              <Field>
                <Controller
                  control={control}
                  name={`newGraduate.programmingExperiences[${index}].levelId`}
                  render={innerProps => (
                    <StyledSelectBox
                      value={innerProps.value}
                      options={[
                        {
                          id: NO_SELECT_NUMBER,
                          name:
                            MESSAGES.PROFILE.FIELD
                              .NEW_GRADUATE_PROGRAMMING_EXPERIENCES.LEVEL
                              .NO_SELECT_TEXT,
                        },
                        ...programmingExperienceLevels,
                      ]}
                      aria-label={
                        MESSAGES.PROFILE.FIELD
                          .NEW_GRADUATE_PROGRAMMING_EXPERIENCES.LEVEL.LABEL
                      }
                      onChange={e => {
                        innerProps.onChange(e);
                        trigger(
                          `newGraduate.programmingExperiences[${index}].languageName`,
                        );
                      }}
                      onBlur={() => {
                        innerProps.onBlur();
                        trigger(
                          `newGraduate.programmingExperiences[${index}].languageName`,
                        );
                      }}
                    />
                  )}
                  defaultValue={field.levelId ?? NO_SELECT_NUMBER}
                />
              </Field>
            </Fields>

            {index > 0 && (
              <RemoveProgrammingExperience>
                <IconRemove
                  onClick={() => remove(index)}
                  data-testid="remove-programming-experience"
                />
              </RemoveProgrammingExperience>
            )}
          </FieldsContainer>
          {touched?.[index]?.languageName &&
            touched?.[index]?.levelId &&
            errors?.[index] && (
              <StyledErrorMessage>{errors?.[index]}</StyledErrorMessage>
            )}
        </div>
      ))}

      {fields.length < MAX_NEW_GRADUATE_PROGRAMMING_EXPERIENCES && (
        <AddField
          style={{ marginTop: '-8px' }}
          onClick={() => append({})}
          data-testid="add-programming-experience"
        />
      )}
    </Wrapper>
  );
};

export default NewGraduateProgrammingExperiencesFormField;
