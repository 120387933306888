import styled from 'styled-components/macro';
import media from 'styles/media';
import theme from 'styles/theme';

const LabelBar = styled.div`
  margin-bottom: 16px;
  padding: 8px;
  border-radius: 3px;
  background-color: #a0a9c8;
  color: ${theme.baseWhite};
  font-size: 14px;
  font-weight: 500;

  ${media.mobile} {
    font-size: 12px;
  }
`;

export default LabelBar;
