import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { Helmet } from 'react-helmet';
import { FieldError } from 'react-hook-form';
import * as MESSAGES from 'constants/messages';
import media from 'styles/media';
import { Stepper } from 'hooks/useStepper';
import { Form, ButtonM } from 'components/atoms';
import {
  CareerSkillsFormField,
  EnglishSkillFormField,
  PreferredWorkLocationsFormField,
  CertificationsFormField,
  CareerAchievementFormField,
  OutputsFormField,
  PreferredOccupationsFormField,
  CareerPreferredEmploymentTypeFormField,
  CareerPreferredMonthlyUnitPriceFormField,
  CareerExpectationsFormField,
  CareerResumeFileFormField,
  SelfIntroductionFormField,
} from 'components/organisms';
import { INNOVATOR_TYPE } from 'constants/innovator';
import useRegisterProfileFormCareerSecond from './useRegisterProfileFormCareerSecond';

const Buttons = styled.div`
  display: grid;
  grid-column-gap: 16px;
  grid-template-columns: auto auto;

  ${media.smallMobile} {
    grid-row-gap: 16px;
    grid-template-columns: 1fr;
  }
`;

const GoToPrevButton = styled(ButtonM)`
  justify-self: end;

  ${media.smallMobile} {
    order: 2;
    width: 100%;
  }
`;

const SubmitButton = styled(ButtonM)`
  ${media.smallMobile} {
    order: 1;
    width: 100%;
  }
`;

type RegisterProfileFormCareerSecondProps = {
  stepper: Stepper;
};

const RegisterProfileFormCareerSecond: FC<RegisterProfileFormCareerSecondProps> = props => {
  const { stepper } = props;
  const {
    innovator,
    formMethods,
    onSubmit,
    formItems,
    exampleSkills,
    skillsOptionsState,
    expectationsOptionsState,
    exampleExpectations,
    resumeFile,
    preferredOccupationsError,
    isValidDefault,
    handleClickPrevButton,
    handleInputChangeSkill,
    handleCreateSkillOption,
    handleClickExampleSkill,
    fetchMoreSkillOptions,
    handleInputChangeExpectation,
    handleCreateExpectationOption,
    handleClickExampleExpectation,
    fetchMoreExpectationOptions,
    handleDropResumeFile,
    handleRemoveResumeFile,
  } = useRegisterProfileFormCareerSecond({
    stepper,
  });

  const {
    handleSubmit,
    errors,
    register,
    formState,
    control,
    trigger,
  } = formMethods;

  const { isValid, isSubmitting, isDirty, touched } = formState;
  const isDisabledSubmitButton = isDirty
    ? !isValid || isSubmitting
    : !isValidDefault;

  return (
    <>
      <Helmet>
        <title>{MESSAGES.HEAD.TITLE.RESISTER_PROFILE.FORM_SECOND}</title>
      </Helmet>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        data-testid="profile-form-career-second"
      >
        <CareerSkillsFormField
          inputValue={skillsOptionsState.inputValue}
          options={skillsOptionsState.options}
          fetchMore={fetchMoreSkillOptions}
          isLoading={skillsOptionsState.isLoading}
          onInputChange={handleInputChangeSkill}
          onCreateOption={handleCreateSkillOption}
          onClickExample={handleClickExampleSkill}
          error={
            !skillsOptionsState.isCreating && touched.career?.skills
              ? errors.career?.skills?.message
              : undefined
          }
          control={control}
          exampleSkills={exampleSkills}
        />

        <EnglishSkillFormField
          error={touched.englishSkillId && errors.englishSkillId?.message}
          englishSkills={formItems.englishSkills}
          control={control}
        />

        <CertificationsFormField control={control} register={register} />

        <CareerAchievementFormField
          error={
            touched.career?.achievement && errors.career?.achievement?.message
          }
          register={register}
        />

        {innovator?.innovatorType === INNOVATOR_TYPE.ENGINEER && (
          <OutputsFormField
            register={register}
            control={control}
            errors={errors.outputs}
            touched={touched.outputs}
          />
        )}

        <PreferredWorkLocationsFormField
          error={
            touched.preferredWorkLocationJapanPrefectureIds &&
            (errors.preferredWorkLocationJapanPrefectureIds as
              | FieldError
              | undefined)?.message
          }
          japanPrefectures={formItems.japanPrefectures}
          control={control}
          trigger={trigger}
        />

        <PreferredOccupationsFormField
          error={touched.preferredOccupations && preferredOccupationsError}
          occupations={formItems.occupations}
          control={control}
          trigger={trigger}
        />

        <CareerPreferredEmploymentTypeFormField
          error={
            touched.career?.preferredEmploymentTypeId &&
            errors.career?.preferredEmploymentTypeId?.message
          }
          employmentTypes={formItems.employmentTypes}
          control={control}
        />

        <CareerPreferredMonthlyUnitPriceFormField
          error={
            touched.career?.preferredMonthlyUnitPriceId &&
            errors.career?.preferredMonthlyUnitPriceId?.message
          }
          preferredMonthlyUnitPrices={formItems.preferredMonthlyUnitPrices}
          control={control}
        />

        <CareerExpectationsFormField
          inputValue={expectationsOptionsState.inputValue}
          options={expectationsOptionsState.options}
          fetchMore={fetchMoreExpectationOptions}
          isLoading={expectationsOptionsState.isLoading}
          onInputChange={handleInputChangeExpectation}
          onCreateOption={handleCreateExpectationOption}
          onClickExample={handleClickExampleExpectation}
          error={
            !expectationsOptionsState.isCreating && touched.career?.expectations
              ? errors.career?.expectations?.message
              : undefined
          }
          control={control}
          exampleExpectations={exampleExpectations}
        />

        <CareerResumeFileFormField
          onDrop={handleDropResumeFile}
          onRemove={handleRemoveResumeFile}
          control={control}
          resumeFile={resumeFile}
        />

        <SelfIntroductionFormField
          error={touched.selfIntroduction && errors.selfIntroduction?.message}
          register={register}
          control={control}
        />

        <Buttons>
          <GoToPrevButton buttonTheme="cancel" onClick={handleClickPrevButton}>
            {MESSAGES.REGISTER_PROFILE.BUTTON.GO_TO_PREV}
          </GoToPrevButton>

          <SubmitButton type="submit" disabled={isDisabledSubmitButton}>
            {MESSAGES.REGISTER_PROFILE.BUTTON.SAVE_AND_GO_TO_NEXT}
          </SubmitButton>
        </Buttons>
      </Form>
    </>
  );
};

export default RegisterProfileFormCareerSecond;
