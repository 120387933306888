import React, { FC, useCallback, useRef } from 'react';
import styled, { css } from 'styled-components/macro';
import media from 'styles/media';

const Wrapper = styled.div<{ bgMobile?: string }>`
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  ${({ bgMobile }) => css`
    ${media.mobile} {
      background: ${bgMobile};
    }
  `}
`;

// onScroll用に内部に150%の要素を配置する
const Content = styled.div`
  width: 150%;
  height: 150%;
`;

type MousePos = {
  mouseX: number;
  mouseY: number;
};

type OverlayProps = {
  onDismiss: (pos: MousePos | null) => void;
  bgMobile?: string;
};

const Overlay: FC<OverlayProps> = ({ onDismiss, bgMobile }) => {
  const mousePos = useRef<MousePos | null>(null);

  const callbackRef = useCallback(
    (element: HTMLDivElement | null) => {
      if (!element) return;
      element.scrollTop = 1;
      element.scrollLeft = 1;
      let onInit = true;
      const handleScroll = () => {
        !onInit && onDismiss(mousePos.current);
        onInit = false;
      };
      element.addEventListener('scroll', handleScroll);

      return () => element?.removeEventListener('scroll', handleScroll);
    },
    [onDismiss],
  );

  return (
    <Wrapper
      ref={callbackRef}
      onClick={() => onDismiss(mousePos.current)}
      onMouseMove={e => {
        mousePos.current = { mouseX: e.clientX, mouseY: e.clientY };
      }}
      bgMobile={bgMobile}
    >
      <Content />
    </Wrapper>
  );
};

export default Overlay;
