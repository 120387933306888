export default {
  TITLE: 'メッセージ',
  FORM: {
    FIELD: {
      MESSAGE: {
        LABEL: 'チャットメッセージ',
        PLACEHOLDER:
          'メッセージを入力してください。{{subKey}} + Enter でメッセージを送信します。',
        PLACEHOLDER_MOBILE: 'メッセージを入力してください',
      },
    },
  },
  DESCRIPTION: {
    NO_SELECTED_CHAT_ROOM: 'メッセージ相手を選択してください。',
    NO_MESSAGES_CONSULTANT:
      'メッセージがありません。\nコンサルタントより連絡があるまでしばらくお待ちください。',
    NO_MESSAGES: 'メッセージがありません。',
    KEY_SUBMIT: '{{subKey}} + Enterキーで送信',
  },
  UNCONTACTABLE_MESSAGE: {
    UNPUBLISHED_MY_ACCOUNT:
      '現在、あなたのアカウントが非公開設定となっているため、メッセージを送信できません。',
    UNPUBLISHED_OPPONENT_MISSION:
      '現在、対象の企業が非公開設定となっているため、メッセージを送信できません。',
  },
  MENU: {
    DELETE: '削除',
    COPY: 'コピー',
  },
  MODAL: {
    DELETE_CHAT_MESSAGE: {
      CONTENT: 'このメッセージを削除しますか？\n※削除すると元に戻せません',
    },
    EXCEED_MAX_CHAT_FILE_SIZE: {
      CONTENT:
        '選択されたファイルサイズが{{maxFileSize}}MBを超えています。{{maxFileSize}MB以下のファイルをアップロードしてください。',
    },
    CONFIRM_MESSAGES: {
      TITLE: 'メッセージが届いております',
      CONTENT: '新着メッセージがあります。ご確認ください。',
      TO_MESSAGE: 'メッセージへ',
    },
  },
  FILE_UPLOAD_TOOLTIP: {
    FORMAT: {
      LABEL: 'ファイル形式',
    },
    FILE_SIZE: {
      LABEL: 'ファイル容量',
      SIZE: '{{fileSize}} MB',
    },
  },
  DELETED_CHAT_MESSAGE: 'このメッセージは削除されました',
  CHAT_MESSAGE_FILE_SIZE: 'ファイルサイズ: {{fileSize}}',
};
