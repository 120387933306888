import { useCallback } from 'react';
import throttle from 'lodash.throttle';
import { InputActionMeta } from 'react-select';
import { UseFormMethods } from 'react-hook-form';
import { ReactSelectOption } from 'types/form';
import { SUGGEST_INTERVAL_MS } from 'constants/config';
import { State } from 'hooks/useSelectOptionsState';

type Payload = {
  name: string;
  state: State<ReactSelectOption>;
  fetcher: (inputValue: string) => void;
  onCreateOption: (inputValue: string) => void;
  initOptionsState: () => void;
  formMethods: UseFormMethods<any>;
  setInputValue: (inputValue: string) => void;
};

type SelectedValue = ReactSelectOption | ReactSelectOption[] | null;

const useReactSelectInput = ({
  name,
  fetcher,
  onCreateOption,
  initOptionsState,
  formMethods,
  setInputValue,
  state,
}: Payload) => {
  const { getValues } = formMethods;

  const { inputValue, options, isLoading } = state;

  const hasAlreadySelected = useCallback(() => {
    const selectedValue: SelectedValue = getValues(name);

    if (!selectedValue || !Array.isArray(selectedValue)) {
      return false;
    }
    return selectedValue.some(option => option.label === inputValue);
  }, [getValues, inputValue, name]);

  const canCreate = useCallback(() => {
    return (
      inputValue && !isLoading && options.length === 0 && !hasAlreadySelected()
    );
  }, [hasAlreadySelected, inputValue, isLoading, options.length]);

  const fetchOptions = useCallback(throttle(fetcher, SUGGEST_INTERVAL_MS), []);

  const createOption = useCallback(() => {
    if (canCreate()) {
      onCreateOption(inputValue);
      initOptionsState();
    }
  }, [canCreate, onCreateOption, inputValue, initOptionsState]);

  const handleInputChange = useCallback(
    (input: string, actionMeta: InputActionMeta) => {
      setInputValue(input);
      if (actionMeta.action === 'input-change') {
        fetchOptions(input);
      }
      if (actionMeta.action === 'input-blur') {
        createOption();
      }
    },
    [setInputValue, fetchOptions, createOption],
  );

  return {
    handleInputChange,
  };
};

export default useReactSelectInput;
