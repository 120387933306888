import React, { FC } from 'react';
import { Controller } from 'react-hook-form';
import styled, { css } from 'styled-components/macro';
import theme from 'styles/theme';
import * as MESSAGES from 'constants/messages';
import { PROFILE_FIELDS_MAX_LENGTH } from 'constants/profile';
import {
  ProfileFormItems,
  ProfileFormControl,
  ProfileFormRegister,
} from 'types/profile';
import { TextInput, Selectbox } from 'components/atoms';
import { Field } from 'components/molecules';

const Content = styled.div`
  display: grid;
  grid-template-columns: 68px 1fr;
`;

const PhoneCountryCodeSelectbox = styled(Selectbox)`
  border-radius: 3px 0 0 3px;
  background-position: right 10px center;
  background-size: 8px 9px;

  &:hover,
  &:focus,
  &:active {
    border-radius: 3px 0 0 3px;
  }
`;

const PhoneCountryCodeLabel = styled.span`
  display: flex;
  position: absolute;
  top: 50%;
  left: 12px;
  align-items: center;
  justify-content: flex-end;
  transform: translateY(-50%);
`;

const PhoneNumberTextInput = styled(TextInput)`
  input {
    padding-left: 42px;
    border-left: none;
    border-radius: 0 3px 3px 0;

    &:focus,
    &:active {
      border-left: 1px solid
        ${({ error }) => (error ? theme.baseError : theme.borderActive)};
    }

    ${({ error }) =>
      error &&
      css`
        border-left: 1px solid ${theme.baseError};
      `}
  }
`;

const PhoneNumberTextInputWrapper = styled.div`
  position: relative;
`;

type PhoneNumberFormFieldProps = {
  error: string | undefined;
  phoneCountryCode: string | undefined;
  countries: ProfileFormItems['countries'];
  register: ProfileFormRegister<'phoneNumber'>;
  control: ProfileFormControl<'phoneCountryId'>;
};

const PhoneNumberFormField: FC<PhoneNumberFormFieldProps> = props => {
  const { error, countries, phoneCountryCode, register, control } = props;

  return (
    <Field
      label={`${MESSAGES.PROFILE.FIELD.PHONE_NUMBER.LABEL}${MESSAGES.PROFILE.FIELD.PHONE_NUMBER.LABEL_NOTE}`}
      required
      locked
    >
      <Content>
        <Controller
          control={control}
          name="phoneCountryId"
          render={innerProps => (
            <PhoneCountryCodeSelectbox
              value={innerProps.value}
              options={countries}
              idName="countryId"
              aria-label={MESSAGES.PROFILE.FIELD.PHONE_COUNTRY_CODE.LABEL}
              onChange={innerProps.onChange}
              onBlur={innerProps.onBlur}
            />
          )}
        />

        <PhoneNumberTextInputWrapper>
          <PhoneNumberTextInput
            enterKeyHint="enter"
            name="phoneNumber"
            inputMode="tel"
            error={error}
            maxLength={PROFILE_FIELDS_MAX_LENGTH.PHONE_NUMBER}
            placeholder={MESSAGES.PROFILE.FIELD.PHONE_NUMBER.PLACEHOLDER}
            aria-label={MESSAGES.PROFILE.FIELD.PHONE_NUMBER.LABEL}
            ref={register}
          />
          <PhoneCountryCodeLabel data-testid="phone-country-code-label">
            +{phoneCountryCode}
          </PhoneCountryCodeLabel>
        </PhoneNumberTextInputWrapper>
      </Content>
    </Field>
  );
};

export default PhoneNumberFormField;
