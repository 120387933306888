import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getInnovator, AccountActions } from 'modules/account';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';
import { UiActions } from 'modules/ui';
import apiInnovator from 'external/api/innovator';
import { UpdateInnovatorRequest } from 'proto/v1/innovatorservice/innovatorservice';
import { NewGraduateProfileExperiencesFormValues } from 'types/profile';
import convertToInnovatorToUpdate from 'utils/profile/convertToInnovatorToUpdate';
import {
  getDefaultNewGraduateInternshipExperiencesValue,
  getDefaultNewGraduateStudyAbroadExperiencesValue,
  getDefaultNewGraduateClubExperiencesValue,
} from 'utils/profile/defaultFormValues';
import {
  getNewGraduateInternshipExperiencesValueToUpdate,
  getNewGraduateStudyAbroadExperiencesValueToUpdate,
  getNewGraduateClubExperiencesValueToUpdate,
} from 'utils/profile/formValuesToUpdate';
import {
  newGraduateInternshipExperiencesSchema,
  newGraduateStudyAbroadExperiencesSchema,
  newGraduateClubExperiencesSchema,
  newGraduatePastExperienceAchievementIdsSchema,
  selfIntroductionSchema,
} from 'utils/profile/schema';
import RequestError from 'classes/RequestError';
import useErrorNotification from 'hooks/useErrorNotification';
import useNewGraduatePastExperienceAchievementsFormField from 'hooks/profile/useNewGraduatePastExperienceAchievementsFormField';

const schema = object().shape({
  newGraduate: object().shape({
    pastExperienceAchievementIds: newGraduatePastExperienceAchievementIdsSchema,
    internshipExperiences: newGraduateInternshipExperiencesSchema,
    studyAbroadExperiences: newGraduateStudyAbroadExperiencesSchema,
    clubExperiences: newGraduateClubExperiencesSchema,
  }),
  selfIntroduction: selfIntroductionSchema,
});

const defaultValues: NewGraduateProfileExperiencesFormValues = {
  selfIntroduction: '',
  newGraduate: {
    pastExperienceAchievementIds: [],
    internshipExperiences: [],
    studyAbroadExperiences: [],
    clubExperiences: [],
  },
};

type Payload = {
  onShowContent: () => void;
  onOpenCompletedModal: () => void;
};

const useNewGraduateProfileExperiencesForm = ({
  onShowContent,
  onOpenCompletedModal,
}: Payload) => {
  const dispatch = useDispatch();

  const { handleRequestError } = useErrorNotification();

  const innovator = useSelector(getInnovator);

  const innovatorToUpdate = useMemo(
    () => innovator && convertToInnovatorToUpdate(innovator),
    [innovator],
  );

  const onSubmit = useCallback(
    async (values: NewGraduateProfileExperiencesFormValues) => {
      const valuesToUpdate: UpdateInnovatorRequest = {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        ...innovatorToUpdate!,
        selfIntroduction: values.selfIntroduction,
        newGraduate: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          ...innovatorToUpdate!.newGraduate!,
          pastExperienceAchievementIds:
            values.newGraduate.pastExperienceAchievementIds,
          internshipExperiences: getNewGraduateInternshipExperiencesValueToUpdate(
            values.newGraduate.internshipExperiences,
          ),
          studyAbroadExperiences: getNewGraduateStudyAbroadExperiencesValueToUpdate(
            values.newGraduate.studyAbroadExperiences,
          ),
          clubExperiences: getNewGraduateClubExperiencesValueToUpdate(
            values.newGraduate.clubExperiences,
          ),
        },
      };

      let data;
      try {
        ({ data } = await apiInnovator.updateInnovator(valuesToUpdate));
      } catch (error: unknown) {
        if (error instanceof RequestError) {
          dispatch(UiActions.setLoading(false));
          handleRequestError(error);
        }
        return;
      }
      dispatch(AccountActions.setInnovator(data.innovator));
      dispatch(UiActions.setLoading(false));
      onShowContent();
      onOpenCompletedModal();
    },
    [
      dispatch,
      handleRequestError,
      innovatorToUpdate,
      onOpenCompletedModal,
      onShowContent,
    ],
  );

  const formMethods = useForm<NewGraduateProfileExperiencesFormValues>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues,
  });

  const { reset } = formMethods;

  const {
    handleSelectPastExperienceAchievements,
    handleDeselectPastExperienceAchievements,
  } = useNewGraduatePastExperienceAchievementsFormField({ formMethods });

  // Set default values
  useEffect(() => {
    if (!innovator || !innovatorToUpdate) return;

    const values: NewGraduateProfileExperiencesFormValues = {
      selfIntroduction: innovatorToUpdate.selfIntroduction,
      newGraduate: {
        pastExperienceAchievementIds:
          innovatorToUpdate.newGraduate?.pastExperienceAchievementIds ?? [],
        internshipExperiences: getDefaultNewGraduateInternshipExperiencesValue(
          innovatorToUpdate.newGraduate?.internshipExperiences,
        ),
        studyAbroadExperiences: getDefaultNewGraduateStudyAbroadExperiencesValue(
          innovatorToUpdate.newGraduate?.studyAbroadExperiences,
        ),
        clubExperiences: getDefaultNewGraduateClubExperiencesValue(
          innovatorToUpdate.newGraduate?.clubExperiences,
        ),
      },
    };

    reset(values);
  }, [innovator, innovatorToUpdate, reset]);

  return {
    formMethods,
    onSubmit,
    handleSelectPastExperienceAchievements,
    handleDeselectPastExperienceAchievements,
  };
};

export default useNewGraduateProfileExperiencesForm;
