import React, { FC, HTMLAttributes } from 'react';
import styled from 'styled-components/macro';
import media from 'styles/media';
import * as MESSAGES from 'constants/messages';
import { MissionForInnovator } from 'types/mission';
import { UrlLink } from 'components/atoms';
import { SubTitle } from '../../Typography';

const FieldTitle = styled(SubTitle)`
  display: none;

  ${media.tablet} {
    display: block;
  }
`;

const CompanyUrl = styled.span`
  display: inline-block;
  max-width: 248px;
  overflow: hidden;
  text-decoration: underline;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${media.tablet} {
    max-width: 690px;
  }

  ${media.mobile} {
    max-width: 416px;
  }

  ${media.smallMobile} {
    max-width: 262px;
  }

  ${media.miniMobile} {
    max-width: 248px;
  }
`;

type Props = {
  companyUrl: MissionForInnovator['companyUrl'] | undefined;
} & HTMLAttributes<HTMLDivElement>;

const CompanyUrlField: FC<Props> = ({ companyUrl, ...rest }) => {
  return (
    <div {...rest}>
      <FieldTitle>{MESSAGES.MISSION_CARD.FIELD.COMPANY_URL.LABEL}</FieldTitle>
      <UrlLink href={companyUrl}>
        <CompanyUrl>{companyUrl}</CompanyUrl>
      </UrlLink>
    </div>
  );
};

export default CompanyUrlField;
