import React, { FC, HTMLAttributes } from 'react';
import styled from 'styled-components/macro';
import media from 'styles/media';
import theme from 'styles/theme';
import { MissionForInnovator } from 'types/mission';
import { imageSelector } from 'utils/image';

const Wrapper = styled.div<{ imageSrc: string }>`
  position: relative;
  height: 440px;
  background-image: url(${props => props.imageSrc});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  ${media.tablet} {
    height: 210px;
  }
`;

const TransparentLayer = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.46) 0%,
    rgba(0, 0, 0, 0) 24% 70%,
    rgba(0, 0, 0, 0.3) 100%
  );
`;

const CoverImageContent = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 32px;

  ${media.tablet} {
    padding: 16px 24px;
  }
`;

const MissionName = styled.p`
  color: ${theme.baseWhite};
  font-size: 24px;
  font-weight: bold;
  line-height: 1.5;
  word-wrap: break-word;
  white-space: pre-wrap;

  ${media.tablet} {
    font-size: 18px;
    line-height: 27px;
  }
`;

type Props = {
  coverImage: MissionForInnovator['coverImage'] | undefined;
  name: MissionForInnovator['name'] | undefined;
} & HTMLAttributes<HTMLDivElement>;

const CoverImageField: FC<Props> = ({ name, coverImage, ...rest }) => {
  return (
    <Wrapper imageSrc={imageSelector(coverImage) ?? ''} {...rest}>
      <TransparentLayer>
        <CoverImageContent>
          <MissionName>{name}</MissionName>
        </CoverImageContent>
      </TransparentLayer>
    </Wrapper>
  );
};

export default CoverImageField;
