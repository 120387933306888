import React, { FC } from 'react';
import * as MESSAGES from 'constants/messages';
import { NewGraduateProfile } from 'proto/v1/apimodel/apimodel';
import {
  Label,
  ProfileField,
  ProfileContent,
  GroupLabel,
  ProfileContentGroup,
  ProfileContentGroupItem,
} from 'components/atoms';
import { formatPeriods } from 'utils/profile/fields';

type NewGraduateStudyAbroadExperiencesFieldProps = {
  studyAbroadExperiences:
    | NewGraduateProfile['studyAbroadExperiences']
    | undefined;
};

const NewGraduateStudyAbroadExperiencesField: FC<NewGraduateStudyAbroadExperiencesFieldProps> = props => {
  const { studyAbroadExperiences } = props;

  return (
    <div data-testid="new-graduate-study-abroad-experiences-field">
      <GroupLabel>
        {MESSAGES.PROFILE.FIELD.NEW_GRADUATE_STUDY_ABROAD_EXPERIENCES.LABEL}
      </GroupLabel>
      <ProfileContentGroup>
        {studyAbroadExperiences && studyAbroadExperiences.length > 0
          ? studyAbroadExperiences.map((studyAbroadExperience, index) => (
              <ProfileContentGroupItem
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                data-testid="new-graduate-study-abroad-experiences-field-item"
              >
                <ProfileField data-testid="new-graduate-study-abroad-experiences-field-country-name">
                  <Label>
                    {
                      MESSAGES.PROFILE.FIELD
                        .NEW_GRADUATE_STUDY_ABROAD_EXPERIENCES.COUNTRY_NAME
                        .LABEL
                    }
                  </Label>
                  <ProfileContent>
                    {studyAbroadExperience.countryName || '-'}
                  </ProfileContent>
                </ProfileField>
                <ProfileField data-testid="new-graduate-study-abroad-experiences-field-internship-period">
                  <Label>
                    {
                      MESSAGES.PROFILE.FIELD
                        .NEW_GRADUATE_STUDY_ABROAD_EXPERIENCES
                        .STUDY_ABROAD_PERIOD.LABEL
                    }
                  </Label>
                  <ProfileContent>
                    {formatPeriods({
                      startYear: studyAbroadExperience.startYear,
                      startMonth: studyAbroadExperience.startMonth,
                      endYear: studyAbroadExperience.endYear,
                      endMonth: studyAbroadExperience.endMonth,
                    })}
                  </ProfileContent>
                </ProfileField>
              </ProfileContentGroupItem>
            ))
          : MESSAGES.COMMON.DESCRIPTION.NOT_REGISTERED}
      </ProfileContentGroup>
    </div>
  );
};

export default NewGraduateStudyAbroadExperiencesField;
