import React, { FC, useState, useCallback } from 'react';
import styled from 'styled-components/macro';
import { useSelector, useDispatch } from 'react-redux';
import media from 'styles/media';
import theme from 'styles/theme';
import { getShouldReviewProfile } from 'modules/matter';
import { getMessageNotificationsTotalCount } from 'modules/notification';
import { UiActions } from 'modules/ui';
import { Menu, MenuClose } from 'assets/svg';
import { Logo } from 'components/atoms';
import { Link } from 'react-router-dom';
import * as PROTECTED from 'routes/constants/protected';
import BaseHeader from './BaseHeader';
import MobileMenu from './MobileMenu';

const Header = styled(BaseHeader)`
  position: relative;
  align-items: center;
  justify-content: space-between;
  padding: 20px 32px;
  background-color: #0c132d;

  ${media.mobile} {
    z-index: 9998;
    top: 0;
    padding: 16px 20px;
  }
`;

const LogoLink = styled(Link)`
  text-decoration: none;
`;

const HeaderLeft = styled.div``;

const HeaderRight = styled.div`
  display: none;

  ${media.tablet} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }
`;

const StyledLogo = styled(Logo)`
  position: relative;
  top: 2px;
`;

const MenuIconContainer = styled.div`
  position: relative;
`;

const NotificationPointer = styled.div`
  display: inline-block;
  position: absolute;
  top: -4px;
  right: -8px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${theme.labelBadge};
`;

const PrivateHeader: FC = () => {
  const dispatch = useDispatch();

  const shouldReviewProfile = useSelector(getShouldReviewProfile);
  const unreadMessageCount = useSelector(getMessageNotificationsTotalCount);

  const [showMenu, setShowMenu] = useState<boolean>(false);

  const handleClickLogoLink = useCallback(() => {
    dispatch(UiActions.setIsSidebarShrinked(false));
  }, [dispatch]);

  const toggleMenu = useCallback(() => {
    setShowMenu(prev => !prev);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setShowMenu(false);
  }, []);

  return (
    <>
      <Header data-testid="header">
        <HeaderLeft>
          <LogoLink
            to={
              shouldReviewProfile
                ? PROTECTED.REGISTER_PROFILE
                : PROTECTED.PROFILE
            }
            onClick={handleClickLogoLink}
          >
            <StyledLogo />
          </LogoLink>
        </HeaderLeft>

        <HeaderRight data-testid="header-right" onClick={toggleMenu}>
          {showMenu ? (
            <MenuClose />
          ) : (
            <MenuIconContainer>
              <Menu />
              {unreadMessageCount !== undefined && unreadMessageCount > 0 && (
                <NotificationPointer />
              )}
            </MenuIconContainer>
          )}
        </HeaderRight>
        {showMenu && <MobileMenu onClose={handleCloseMenu} />}
      </Header>
    </>
  );
};

export default PrivateHeader;
