import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { formatChatMessageTimestamp, formatFileSize } from 'utils/chat';
import { MessageDocument } from 'assets/svg';
import { ImArrowUpLeft } from 'react-icons/im';
import { LinkText } from 'components/atoms';
import theme from 'styles/theme';
import media from 'styles/media';
import { chatMessageStyle } from 'styles/chat';
import { SendingChatMessage as SendingChatMessageType } from 'types/chat';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
  padding-top: 40px;

  ${media.mobile} {
    padding-top: 32px;
  }
`;

const Message = styled.div`
  ${chatMessageStyle}
  border-radius: 18px 0 18px 18px;
  background: ${theme.basePrimary};
  color: ${theme.baseWhite};
`;

const FileIcon = styled(MessageDocument)`
  min-width: 22px;
  margin-right: 12px;
`;

const FileName = styled.div`
  margin-bottom: 4px;
`;

const FileSize = styled.div`
  font-size: 11px;
  line-height: 16px;
`;

const FileMessage = styled(Message)`
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    /* stylelint-disable-next-line selector-type-no-unknown */
    ${FileName} {
      text-decoration: underline;
    }
    /* stylelint-disable-next-line selector-type-no-unknown */
    ${FileSize} {
      text-decoration: underline;
    }
  }
`;

const MessageBottom = styled.div`
  display: flex;
  position: relative;
  justify-content: flex-end;
`;

const SentAt = styled.div`
  color: ${theme.textSecondary};
  font-size: 12px;
  line-height: 17px;
  text-align: right;
`;

const SendingIcon = styled(ImArrowUpLeft).attrs({
  fill: theme.textSecondary,
  size: 10,
})`
  position: relative;
  top: 4px;
  right: 8px;
`;

type Props = {
  sendingChatMessage: SendingChatMessageType;
};

const renderMessage = (chatMessage: SendingChatMessageType) => {
  if (chatMessage.file) {
    return (
      <div>
        <FileMessage>
          <FileIcon />
          <div>
            <FileName>{chatMessage.message}</FileName>
            <FileSize>
              ファイルサイズ: {formatFileSize(chatMessage.file.size)}
            </FileSize>
          </div>
        </FileMessage>
        <MessageBottom>
          <SendingIcon />
          <SentAt>{formatChatMessageTimestamp(chatMessage.sentAt)}</SentAt>
        </MessageBottom>
      </div>
    );
  }

  return (
    <div>
      <Message>
        <LinkText>{chatMessage.message}</LinkText>
      </Message>

      <MessageBottom>
        <SendingIcon />
        <SentAt>{formatChatMessageTimestamp(chatMessage.sentAt)}</SentAt>
      </MessageBottom>
    </div>
  );
};

const SendingChatMessage: FC<Props> = props => {
  return (
    <Wrapper data-testid="sending-chat-message">
      {renderMessage(props.sendingChatMessage)}
    </Wrapper>
  );
};

export default SendingChatMessage;
