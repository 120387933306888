import React, { FC, ComponentProps } from 'react';
import { Route } from 'react-router-dom';
import { PublicRouteLayout } from 'components/templates';

const PublicRoute: FC<ComponentProps<
  typeof Route & typeof PublicRouteLayout
>> = props => {
  return (
    <PublicRouteLayout>
      <Route {...props} />
    </PublicRouteLayout>
  );
};

export default PublicRoute;
