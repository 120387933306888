import React, { useCallback } from 'react';
import * as MESSAGES from 'constants/messages';
import * as PROTECTED_ROUTES from 'routes/constants/protected';
import * as ROUTES from 'routes/constants/routes';
import * as EXTERNAL_LINK from 'constants/externalLink';
import { MATTER_STATUS_ID } from 'constants/matter';
import styled, { css } from 'styled-components/macro';
import { useSelector, useDispatch } from 'react-redux';
import { interpolateMessage } from 'utils/string';
import theme from 'styles/theme';
import { getMatterStatus } from 'modules/matter';
import { getMessageNotificationsTotalCount } from 'modules/notification';
import { getIsSidebarShrinked, UiActions } from 'modules/ui';
import { getChatRoomId } from 'modules/account';
import media from 'styles/media';
import useLogout from 'hooks/useLogout';
import { UserBlue, MessageBlue, SettingsBlue, LogoutBlue } from 'assets/svg';
import { NotificationBadge } from 'components/atoms';
import { NavLink } from 'components/molecules';
import SidebarTransition from './SidebarTransition';

const Wrapper = styled.aside<{ isShrinked: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50px;
  height: calc(100vh - 64px);
  transition: width 300ms 0s ease;
  background: #eee;
  box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.05);
  ${({ isShrinked }) =>
    !isShrinked &&
    css`
      width: 220px;
    `}

  ${media.tablet} {
    display: none;
  }
`;

const MenuItem = styled.li`
  height: 48px;
`;

const MenuItemNavLink = styled(NavLink)`
  height: 48px;
`;

const MenuItemLabel = styled.div`
  display: inline-flex;
  align-items: center;
  margin-left: 14px;

  &.enter {
    display: none;
  }

  &.enter-done {
    display: inline-flex;
  }

  &.exit {
    display: none;
  }

  &.exit-done {
    display: none;
  }
`;

const LogoutMenuItem = styled.li`
  padding: 14px 24px;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;

  &:hover {
    background-color: ${theme.borderDefault};
    color: ${theme.textBody};
  }
`;

const ProfileIcon = styled(UserBlue)`
  position: relative;
  bottom: 1px;
`;

const MessagesIcon = styled(MessageBlue)<{ disabled: boolean }>`
  ${({ disabled }) =>
    disabled &&
    css`
      path {
        fill: ${theme.textSecondary};
      }
    `}
`;

const SettingsIcon = styled(SettingsBlue)``;

const LogoutIcon = styled(LogoutBlue)``;

const Footer = styled.div`
  display: block;
  padding: 0 24px;

  &.enter {
    display: none;
  }

  &.enter-done {
    display: block;
  }

  &.exit {
    display: none;
  }

  &.exit-done {
    display: none;
  }
`;

const FooterItem = styled.div`
  margin-bottom: 16px;
  color: ${theme.textSecondary};
  font-size: 12px;
  line-height: 17px;
`;

const CopyRight = styled.div`
  margin-bottom: 8px;
  color: ${theme.textSecondary};
  font-size: 10px;
  line-height: 12px;
`;

const FooterItemLink = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})`
  color: ${theme.textSecondary};
  text-decoration: none;

  &:hover {
    color: ${theme.textSecondaryHover};
  }
`;

const Sidebar: React.FC = () => {
  const { logout } = useLogout();
  const dispatch = useDispatch();
  const { statusId } = useSelector(getMatterStatus);
  const unreadMessageCount = useSelector(getMessageNotificationsTotalCount);
  const chatRoomId = useSelector(getChatRoomId);
  const isSidebarShrinked = useSelector(getIsSidebarShrinked);

  const handleClickMenuItemLink = useCallback(() => {
    if (isSidebarShrinked) dispatch(UiActions.setIsSidebarShrinked(false));
  }, [dispatch, isSidebarShrinked]);

  // Use object style property for shrinked NavItemLinks because of the NavItem component can't extend custom props.
  const shrinkedNavItemLinkStyle: React.CSSProperties = isSidebarShrinked
    ? { padding: '14px 15px' }
    : {};

  return (
    <Wrapper isShrinked={isSidebarShrinked}>
      <ul>
        <MenuItem onClick={handleClickMenuItemLink}>
          <MenuItemNavLink
            to={
              statusId === MATTER_STATUS_ID.NONE ||
              statusId === MATTER_STATUS_ID.SEND_BACK
                ? PROTECTED_ROUTES.REGISTER_PROFILE
                : PROTECTED_ROUTES.PROFILE
            }
            isActive={(_, location) =>
              [
                PROTECTED_ROUTES.REGISTER_PROFILE,
                PROTECTED_ROUTES.PROFILE,
                PROTECTED_ROUTES.PUBLISH_SETTING,
              ].includes(location.pathname)
            }
            style={shrinkedNavItemLinkStyle}
          >
            <ProfileIcon />
            <SidebarTransition isShrinked={isSidebarShrinked}>
              <MenuItemLabel>{MESSAGES.MENU.MENU.PROFILE}</MenuItemLabel>
            </SidebarTransition>
          </MenuItemNavLink>
        </MenuItem>
        <MenuItem>
          <MenuItemNavLink
            to={PROTECTED_ROUTES.MESSAGES}
            disabled={!chatRoomId}
            style={shrinkedNavItemLinkStyle}
          >
            <MessagesIcon disabled={!chatRoomId} />
            <SidebarTransition isShrinked={isSidebarShrinked}>
              <MenuItemLabel>{MESSAGES.MENU.MENU.MESSAGES}</MenuItemLabel>
            </SidebarTransition>
            {!isSidebarShrinked &&
              unreadMessageCount !== undefined &&
              unreadMessageCount > 0 && (
                <NotificationBadge
                  count={unreadMessageCount}
                  style={{ marginLeft: 'auto' }}
                />
              )}
          </MenuItemNavLink>
        </MenuItem>
        <MenuItem onClick={handleClickMenuItemLink}>
          <MenuItemNavLink
            to={PROTECTED_ROUTES.ACCOUNT_SETTING}
            style={shrinkedNavItemLinkStyle}
          >
            <SettingsIcon />
            <SidebarTransition isShrinked={isSidebarShrinked}>
              <MenuItemLabel>{MESSAGES.MENU.MENU.SETTINGS}</MenuItemLabel>
            </SidebarTransition>
          </MenuItemNavLink>
        </MenuItem>
        <LogoutMenuItem
          onClick={logout}
          data-testid="logout"
          style={shrinkedNavItemLinkStyle}
        >
          <LogoutIcon />
          <SidebarTransition isShrinked={isSidebarShrinked}>
            <MenuItemLabel>{MESSAGES.MENU.MENU.LOGOUT}</MenuItemLabel>
          </SidebarTransition>
        </LogoutMenuItem>
      </ul>

      <SidebarTransition isShrinked={isSidebarShrinked}>
        <Footer>
          <FooterItem>
            <FooterItemLink href={EXTERNAL_LINK.COMPANY}>
              {MESSAGES.MENU.FOOTER.COMPANY}
            </FooterItemLink>
          </FooterItem>
          <FooterItem>
            <FooterItemLink href={ROUTES.TERMS}>
              {MESSAGES.MENU.FOOTER.TERMS_OF_SERVICE}
            </FooterItemLink>
          </FooterItem>
          <FooterItem>
            <FooterItemLink href={EXTERNAL_LINK.PRIVACY_POLICY}>
              {MESSAGES.MENU.FOOTER.PRIVACY_POLICY}
            </FooterItemLink>
          </FooterItem>
          <CopyRight>
            {interpolateMessage(MESSAGES.MENU.FOOTER.COPYRIGHT, {
              year: new Date().getFullYear(),
            })}
          </CopyRight>
        </Footer>
      </SidebarTransition>
    </Wrapper>
  );
};

export default Sidebar;
