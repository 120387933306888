import styled from 'styled-components/macro';
import theme from 'styles/theme';
import media from 'styles/media';

const Card = styled.div`
  padding: 32px;
  background-color: ${theme.baseWhite};

  ${media.mobile} {
    padding: 32px 16px;
  }
`;

export default Card;
