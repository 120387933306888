import React, { FC, useEffect } from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import styled from 'styled-components/macro';
import theme from 'styles/theme';
import media from 'styles/media';
import * as MESSAGES from 'constants/messages';
import { NO_SELECT_NUMBER } from 'constants/form';
import { interpolateMessage } from 'utils/string';
import { MAX_CAREER_JOB_OCCUPATIONS } from 'constants/profile';
import {
  ProfileFormItems,
  ProfileFormControl,
  ProfileFormValues,
} from 'types/profile';
import { CircleCross } from 'assets/svg';
import { Selectbox, AddField } from 'components/atoms';
import { FieldLabel as BaseFieldLabel } from 'components/molecules';

const FieldsContainer = styled.div`
  display: flex;
  margin-bottom: 16px;
`;

const FieldLabel = styled(BaseFieldLabel)``;

const Fields = styled.div`
  display: grid;
  grid-column-gap: 16px;
  grid-template-columns: 368px 176px;

  ${media.mobile} {
    grid-row-gap: 8px;
    grid-template-columns: 1fr;
    width: 100%;
  }
`;

const Field = styled.div.attrs({ 'data-testid': 'field' })``;

const Wrapper = styled.div`
  margin-bottom: 40px;

  ${media.mobile} {
    margin-bottom: 32px;
  }

  /* stylelint-disable-next-line selector-type-no-unknown */
  & ${FieldsContainer}:not(:first-child) {
    ${FieldLabel} {
      display: none;

      ${media.mobile} {
        display: block;
      }
    }
  }
`;

const RemoveOccupation = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;

  ${media.mobile} {
    align-self: flex-end;
    height: 110px;
    padding-left: 8px;
    border-left: 1px solid ${theme.borderDefault};
  }
`;

const IconRemove = styled(CircleCross)`
  display: inline-block;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

type Job = ProfileFormValues['career']['job'][0];

type OccupationsFormFieldProps = {
  errors:
    | {
        occupationId: string | undefined;
        yearsOfExperienceId: string | undefined;
      }[]
    | undefined;
  jobIndex: number;
  control: ProfileFormControl<{
    career: {
      job: Pick<Job, 'occupations'>[];
    };
  }>;
  occupations: ProfileFormItems['occupations'];
  yearsOfExperiences: ProfileFormItems['yearsOfExperiences'];
};

const OccupationsFormField: FC<OccupationsFormFieldProps> = props => {
  const { errors, jobIndex, control, occupations, yearsOfExperiences } = props;

  const { fields, append, remove } = useFieldArray({
    control,
    name: `career.job[${jobIndex}].occupations`,
  });

  // Append initial field
  useEffect(() => {
    if (fields.length === 0) {
      append({});
    }
  }, [append, fields.length]);

  return (
    <Wrapper>
      {fields.map((field, index) => (
        <FieldsContainer key={field.id} data-testid="occupation-field">
          <Fields>
            <Field>
              <FieldLabel
                label={
                  MESSAGES.PROFILE.FIELD.CAREER_JOB.OCCUPATIONS.FIELD.OCCUPATION
                    .LABEL
                }
                required
                note={
                  index === 0
                    ? interpolateMessage(
                        MESSAGES.PROFILE.FIELD.CAREER_JOB.OCCUPATIONS.FIELD
                          .OCCUPATION.NOTE,
                        { maxLength: MAX_CAREER_JOB_OCCUPATIONS },
                      )
                    : undefined
                }
              />
              <Controller
                control={control}
                name={`career.job[${jobIndex}].occupations[${index}].occupationId`}
                render={innerProps => (
                  <Selectbox
                    value={innerProps.value}
                    error={errors && errors[index]?.occupationId}
                    options={occupations}
                    aria-label={
                      MESSAGES.PROFILE.FIELD.CAREER_JOB.OCCUPATIONS.FIELD
                        .OCCUPATION.LABEL
                    }
                    noSelectValue={NO_SELECT_NUMBER}
                    onChange={innerProps.onChange}
                    onBlur={innerProps.onBlur}
                  />
                )}
                defaultValue={field.occupationId ?? NO_SELECT_NUMBER}
              />
            </Field>

            <Field>
              <FieldLabel
                label={
                  MESSAGES.PROFILE.FIELD.CAREER_JOB.OCCUPATIONS.FIELD
                    .YEARS_OF_EXPERIENCE.LABEL
                }
                required
              />
              <Controller
                control={control}
                name={`career.job[${jobIndex}].occupations[${index}].yearsOfExperienceId`}
                render={innerProps => (
                  <Selectbox
                    value={innerProps.value}
                    error={errors && errors[index]?.yearsOfExperienceId}
                    options={yearsOfExperiences}
                    aria-label={
                      MESSAGES.PROFILE.FIELD.CAREER_JOB.OCCUPATIONS.FIELD
                        .YEARS_OF_EXPERIENCE.LABEL
                    }
                    noSelectValue={NO_SELECT_NUMBER}
                    onChange={innerProps.onChange}
                    onBlur={innerProps.onBlur}
                  />
                )}
                defaultValue={field.yearsOfExperienceId ?? NO_SELECT_NUMBER}
              />
            </Field>
          </Fields>

          {index > 0 && (
            <RemoveOccupation>
              <IconRemove
                onClick={() => remove(index)}
                data-testid="icon-remove-occupation"
              />
            </RemoveOccupation>
          )}
        </FieldsContainer>
      ))}

      {fields.length < MAX_CAREER_JOB_OCCUPATIONS && (
        <AddField style={{ marginTop: '-8px' }} onClick={() => append({})} />
      )}
    </Wrapper>
  );
};

export default OccupationsFormField;
