import React, { FC } from 'react';
import * as MESSAGES from 'constants/messages';
import styled from 'styled-components/macro';
import { DTT_LP, DTT_LOGIN } from 'constants/externalLink';
import { ButtonM } from 'components/atoms';
import { Modal, ModalContent, ModalFooter } from 'components/molecules';
import theme from 'styles/theme';

const StyledModalContent = styled(ModalContent)`
  color: ${theme.textBody};
`;

const Link = styled.a`
  color: ${theme.basePrimary};
  font-weight: bold;

  &:hover {
    opacity: 0.8;
    color: ${theme.basePrimary};
  }
`;

type AboutDttScoreModalProps = {
  show: boolean;
  onClose: () => void;
};

const AboutDttScoreModal: FC<AboutDttScoreModalProps> = ({ show, onClose }) => {
  return (
    <Modal show={show} closeModal={onClose}>
      <StyledModalContent title={MESSAGES.PROFILE.MODAL.ABOUT_DTT_SCORE.TITLE}>
        <p>
          {MESSAGES.PROFILE.MODAL.ABOUT_DTT_SCORE.CONTENT1}
          <Link href={DTT_LP} target="_blank" rel="noopener noreferrer">
            {MESSAGES.PROFILE.MODAL.ABOUT_DTT_SCORE.CONTENT_DTT}
          </Link>
          {MESSAGES.PROFILE.MODAL.ABOUT_DTT_SCORE.CONTENT2}
        </p>
        <p>
          {MESSAGES.PROFILE.MODAL.ABOUT_DTT_SCORE.CONTENT3}
          <Link href={DTT_LOGIN} target="_blank" rel="noopener noreferrer">
            {MESSAGES.PROFILE.MODAL.ABOUT_DTT_SCORE.CONTENT_MY_PAGE}
          </Link>
          {MESSAGES.PROFILE.MODAL.ABOUT_DTT_SCORE.CONTENT4}
        </p>
      </StyledModalContent>
      <ModalFooter>
        <ButtonM onClick={onClose} buttonTheme="cancel">
          {MESSAGES.COMMON.BUTTON.CLOSE}
        </ButtonM>
      </ModalFooter>
    </Modal>
  );
};

export default AboutDttScoreModal;
