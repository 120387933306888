import React, { FC } from 'react';
import { Controller } from 'react-hook-form';
import styled from 'styled-components/macro';
import media from 'styles/media';
import * as MESSAGES from 'constants/messages';
import { STUDY_ABROAD_PERIOD_MIN_YEAR } from 'constants/profile';
import { NO_SELECT_NUMBER } from 'constants/form';
import { range, rangeRight } from 'utils/array';
import {
  ProfileFormControl,
  ProfileFormMethods,
  ProfileFormValues,
} from 'types/profile';
import { ErrorMessage, Selectbox } from 'components/atoms';
import { Field } from 'components/molecules';

const StyledField = styled(Field)`
  margin-bottom: 0;
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 368px 30px 368px;

  ${media.tablet} {
    grid-template-columns: 1fr;
  }
`;

const SelectBoxes = styled.div`
  display: grid;
  grid-column-gap: 16px;
  grid-template-columns: 176px 176px;

  ${media.tablet} {
    grid-row-gap: 8px;
    grid-template-columns: 1fr;
  }
`;

const SelectboxWrapper = styled.div`
  position: relative;
`;

const StyledSelectBox = styled(Selectbox)`
  & + .error-message {
    position: absolute;
    top: 40px;
    left: 0;
  }
`;

const From = styled.span`
  justify-self: center;
  align-self: center;

  ${media.tablet} {
    justify-self: start;
    padding: 8px 0;
  }
`;

const StudyAbroadPeriodErrorMessage = styled(ErrorMessage)`
  margin-top: 4px;
`;

const noSelectOption = {
  id: NO_SELECT_NUMBER,
  name: MESSAGES.COMMON.SELECT_BOX.NO_SELECT,
};

const yearOptions = [
  noSelectOption,
  ...rangeRight(STUDY_ABROAD_PERIOD_MIN_YEAR, new Date().getFullYear()).map(
    year => ({
      id: year,
      name: `${year}年`,
    }),
  ),
];

const monthOptions = [
  noSelectOption,
  ...range(1, 12).map(month => ({
    id: month,
    name: `${month}月`,
  })),
];

type Value = {
  newGraduate: Pick<ProfileFormValues['newGraduate'], 'studyAbroadExperiences'>;
};

type StudyAbroadPeriodFormFieldProps = {
  error: string | undefined;
  index: number;
  control: ProfileFormControl<Value>;
  trigger: ProfileFormMethods<Value>['trigger'];
  defaultValues: Partial<
    ProfileFormValues['newGraduate']['studyAbroadExperiences'][0]
  >;
};

const StudyAbroadPeriodFormField: FC<StudyAbroadPeriodFormFieldProps> = props => {
  const { error, index, control, trigger, defaultValues } = props;

  const startYearName = `newGraduate.studyAbroadExperiences[${index}].startYear`;
  const startMonthName = `newGraduate.studyAbroadExperiences[${index}].startMonth`;
  const endYearName = `newGraduate.studyAbroadExperiences[${index}].endYear`;
  const endMonthName = `newGraduate.studyAbroadExperiences[${index}].endMonth`;

  return (
    <StyledField
      label={
        MESSAGES.PROFILE.FIELD.NEW_GRADUATE_STUDY_ABROAD_EXPERIENCES
          .STUDY_ABROAD_PERIOD.LABEL
      }
    >
      <Container>
        <SelectBoxes>
          <SelectboxWrapper>
            <Controller
              control={control}
              name={startYearName}
              render={innerProps => (
                <StyledSelectBox
                  value={innerProps.value}
                  name={startYearName}
                  options={yearOptions}
                  aria-label={
                    MESSAGES.PROFILE.FIELD.NEW_GRADUATE_STUDY_ABROAD_EXPERIENCES
                      .STUDY_ABROAD_PERIOD.START_YEAR.LABEL
                  }
                  onChange={e => {
                    innerProps.onChange(e);
                    trigger([startMonthName, endYearName, endMonthName]);
                  }}
                  onBlur={innerProps.onBlur}
                />
              )}
              defaultValue={defaultValues.startYear ?? NO_SELECT_NUMBER}
            />
          </SelectboxWrapper>

          <SelectboxWrapper>
            <Controller
              control={control}
              name={startMonthName}
              render={innerProps => (
                <StyledSelectBox
                  value={innerProps.value}
                  name={startMonthName}
                  options={monthOptions}
                  aria-label={
                    MESSAGES.PROFILE.FIELD.NEW_GRADUATE_STUDY_ABROAD_EXPERIENCES
                      .STUDY_ABROAD_PERIOD.START_MONTH.LABEL
                  }
                  onChange={e => {
                    innerProps.onChange(e);
                    trigger([startYearName, endYearName, endMonthName]);
                  }}
                  onBlur={innerProps.onBlur}
                />
              )}
              defaultValue={defaultValues.startMonth ?? NO_SELECT_NUMBER}
            />
          </SelectboxWrapper>
        </SelectBoxes>

        <From>〜</From>

        <SelectBoxes>
          <SelectboxWrapper>
            <Controller
              control={control}
              name={endYearName}
              render={innerProps => (
                <StyledSelectBox
                  value={innerProps.value}
                  name={endYearName}
                  options={yearOptions}
                  aria-label={
                    MESSAGES.PROFILE.FIELD.NEW_GRADUATE_STUDY_ABROAD_EXPERIENCES
                      .STUDY_ABROAD_PERIOD.END_YEAR.LABEL
                  }
                  onChange={e => {
                    innerProps.onChange(e);
                    trigger([startYearName, startMonthName, endMonthName]);
                  }}
                  onBlur={innerProps.onBlur}
                />
              )}
              defaultValue={defaultValues.endYear ?? NO_SELECT_NUMBER}
            />
          </SelectboxWrapper>

          <SelectboxWrapper>
            <Controller
              control={control}
              name={endMonthName}
              render={innerProps => (
                <StyledSelectBox
                  value={innerProps.value}
                  name={endMonthName}
                  options={monthOptions}
                  aria-label={
                    MESSAGES.PROFILE.FIELD.NEW_GRADUATE_STUDY_ABROAD_EXPERIENCES
                      .STUDY_ABROAD_PERIOD.END_MONTH.LABEL
                  }
                  onChange={e => {
                    innerProps.onChange(e);
                    trigger([startYearName, startMonthName, endYearName]);
                  }}
                  onBlur={innerProps.onBlur}
                />
              )}
              defaultValue={defaultValues.endMonth ?? NO_SELECT_NUMBER}
            />
          </SelectboxWrapper>
        </SelectBoxes>
      </Container>
      {error && (
        <StudyAbroadPeriodErrorMessage>{error}</StudyAbroadPeriodErrorMessage>
      )}
    </StyledField>
  );
};

export default StudyAbroadPeriodFormField;
