export default {
  MENU: {
    PROFILE: 'プロフィール',
    MESSAGES: 'メッセージ',
    SETTINGS: '設定',
    LOGOUT: 'ログアウト',
  },
  FOOTER: {
    COMPANY: '運営会社',
    TERMS_OF_SERVICE: '利用規約',
    PRIVACY_POLICY: 'プライバシーポリシー',
    COPYRIGHT: '© {{year}} VISITS Technologies Inc.',
  },
};
