import { Innovator, Country, JapanPrefecture, EmploymentType, Occupation, EnglishSkill, AnnualIncome, PreferredMonthlyUnitPrice, NumberOfJobChanges, YearsOfExperience, Position, Department, DttRank, ProgrammingExperienceLevel, PreferredWorkStyle, PreferredBusinessType, PastExperienceAchievement, Gender, WorkLocation, MissionCategory, CorporateAttribute, Fund, MemberRange, Location, BlockCompany, InnovatorChatMessage, InnovatorChatRoom, Images, MissionMember, MissionOfficeImage, CompanyEstablishedDate, MissionJobOffer, MissionProductTarget } from '../../../proto/v1/apimodel/apimodel';
import { Reader, Writer } from 'protobufjs/minimal';
import * as Long from 'long';
import { Int32Value, StringValue, BoolValue } from '../../../proto/google/protobuf/wrappers';


export interface GetInnovatorFormRequest {
}

export interface GetInnovatorFormResponse {
  innovator: Innovator | undefined;
  form: GetInnovatorFormResponse_Form | undefined;
}

export interface GetInnovatorFormResponse_Form {
  countries: Country[];
  japanPrefectures: JapanPrefecture[];
  employmentTypes: EmploymentType[];
  occupations: Occupation[];
  englishSkills: EnglishSkill[];
  annualIncomes: AnnualIncome[];
  preferredMonthlyUnitPrices: PreferredMonthlyUnitPrice[];
  numberOfJobChanges: NumberOfJobChanges[];
  yearsOfExperiences: YearsOfExperience[];
  positions: Position[];
  departments: Department[];
  dttRanks: DttRank[];
  programmingExperienceLevels: ProgrammingExperienceLevel[];
  preferredWorkStyles: PreferredWorkStyle[];
  preferredBusinessTypes: PreferredBusinessType[];
  pastExperienceAchievements: PastExperienceAchievement[];
  genders: Gender[];
}

export interface UpdateInnovatorRequest {
  innovatorId: string;
  lastName: string;
  firstName: string;
  lastNameKana: string;
  firstNameKana: string;
  yearOfBirth: number;
  monthOfBirth: number;
  phoneCountryCode: string;
  phoneNumber: string;
  japanPrefectureId: number | undefined;
  career: CareerProfileToUpdate | undefined;
  newGraduate: NewGraduateProfileToUpdate | undefined;
  preferredWorkLocations: WorkLocation[];
  preferredOccupationIds: number[];
  academicBackground: UpdateInnovatorRequest_AcademicBackground | undefined;
  englishSkillId: number;
  selfIntroduction: string;
  certifications: string[];
  outputs: string[];
  dttScore: number | undefined;
  dttRankId: number | undefined;
  dateOfBirth: number;
  genderId: number;
}

export interface UpdateInnovatorRequest_AcademicBackground {
  schoolId: number;
  faculty: string;
  departmentId: number;
  yearOfGraduation: number;
  monthOfGraduation: number;
}

export interface CareerProfileToUpdate {
  job: CareerProfileToUpdate_JobHistory[];
  annualIncomeId: number;
  monthlyUnitPrice: number | undefined;
  numberOfJobChangesId: number;
  fileKey: string;
  preferredMonthlyUnitPriceId: number;
  achievement: string;
  preferredEmploymentTypeId: number;
  skillIds: number[];
  expectationIds: number[];
}

export interface CareerProfileToUpdate_JobHistory {
  name: string;
  positionId: number;
  startYear: number;
  startMonth: number;
  endYear: number | undefined;
  endMonth: number | undefined;
  occupations: CareerProfileToUpdate_ExperiencedOccupation[];
}

export interface CareerProfileToUpdate_ExperiencedOccupation {
  occupationId: number;
  yearsOfExperienceId: number;
}

export interface NewGraduateProfileToUpdate {
  researchRooms: NewGraduateProfileToUpdate_ResearchRoom[];
  programmingExperiences: NewGraduateProfileToUpdate_ProgrammingExperience[];
  preferredWorkStyleId: number | undefined;
  preferredBusinessTypeIds: number[];
  pastExperienceAchievementIds: number[];
  internshipExperiences: NewGraduateProfileToUpdate_InternshipExperience[];
  studyAbroadExperiences: NewGraduateProfileToUpdate_StudyAbroadExperience[];
  clubExperiences: NewGraduateProfileToUpdate_ClubExperience[];
}

export interface NewGraduateProfileToUpdate_ResearchRoom {
  name: string;
  content: string;
}

export interface NewGraduateProfileToUpdate_ProgrammingExperience {
  languageName: string;
  levelId: number;
}

export interface NewGraduateProfileToUpdate_InternshipExperience {
  companyName: string;
  startYear: number | undefined;
  startMonth: number | undefined;
  endYear: number | undefined;
  endMonth: number | undefined;
}

export interface NewGraduateProfileToUpdate_StudyAbroadExperience {
  countryName: string;
  startYear: number | undefined;
  startMonth: number | undefined;
  endYear: number | undefined;
  endMonth: number | undefined;
}

export interface NewGraduateProfileToUpdate_ClubExperience {
  name: string;
  content: string;
}

export interface UpdateInnovatorResponse {
  innovator: Innovator | undefined;
}

export interface SendPublishRequestRequest {
  innovatorId: string;
}

export interface SendPublishRequestResponse {
}

export interface GetPublishSettingFormRequest {
}

export interface GetPublishSettingFormResponse {
  categories: MissionCategory[];
  corporateAttributes: CorporateAttribute[];
  funds: Fund[];
  japanPrefectures: JapanPrefecture[];
  memberRanges: MemberRange[];
}

export interface GetPublishSettingRequest {
}

export interface GetPublishSettingResponse {
  categories: MissionCategory[];
  corporateAttributes: CorporateAttribute[];
  funds: Fund[];
  locations: Location[];
  memberRanges: MemberRange[];
  blockCompanies: BlockCompany[];
  publish: boolean;
}

export interface UpdatePublishSettingRequest {
  categories: number[];
  corporateAttributes: number[];
  funds: number[];
  locations: UpdatePublishSettingRequest_Location[];
  memberRanges: number[];
  blockCompanies: string[];
  publish: boolean;
  innovatorId: string;
}

export interface UpdatePublishSettingRequest_Location {
  japanPrefectureId: number | undefined;
}

export interface UpdatePublishSettingResponse {
}

export interface GetMatterStatusRequest {
}

export interface GetMatterStatusResponse {
  matterId: string | undefined;
  statusId: number | undefined;
  registered: boolean;
}

export interface CreateInnovatorChatRoomRequest {
}

export interface CreateInnovatorChatRoomResponse {
  chatRoomId: string;
}

export interface GetInnovatorChatMessagesRequest {
  chatRoomId: string;
  limit: number;
  messageIdBefore: number | undefined;
  messageIdAfter: number | undefined;
}

export interface GetInnovatorChatMessagesResponse {
  messages: InnovatorChatMessage[];
  prevQuery: string;
  nextQuery: string;
}

export interface PostInnovatorChatMessageRequest {
  chatRoomId: string;
  message: string;
}

export interface PostInnovatorChatMessageResponse {
}

export interface PostInnovatorChatFileRequest {
  chatRoomId: string;
  data: string;
  fileName: string;
}

export interface PostInnovatorChatFileResponse {
}

export interface DeleteInnovatorChatMessageRequest {
  chatRoomId: string;
  messageId: number;
}

export interface DeleteInnovatorChatMessageResponse {
}

export interface FlushInnovatorChatUnreadCountRequest {
  chatRoomId: string;
}

export interface FlushInnovatorChatUnreadCountResponse {
}

export interface GetInnovatorAccountSettingsRequest {
}

export interface GetInnovatorAccountSettingsResponse {
  chatEmailNotificationDisabled: boolean;
  chatPushNotificationDisabled: boolean;
}

export interface ChangeInnovatorAccountSettingsRequest {
  innovatorId: string;
  chatEmailNotificationDisabled: boolean | undefined;
  chatPushNotificationDisabled: boolean | undefined;
}

export interface ChangeInnovatorAccountSettingsResponse {
}

export interface PostConfirmedJoinCompanyRequest {
  companyName: string;
  joinCompanyScheduleAt: number;
}

export interface PostConfirmedJoinCompanyResponse {
}

export interface GetConfirmedJoinCompanySettingRequest {
}

export interface GetConfirmedJoinCompanySettingResponse {
  disabled: boolean;
  description: string;
}

export interface GetMissionCompanyNamesRequest {
  name: string;
  limit: number;
  q: string;
}

export interface GetMissionCompanyNamesResponse {
  names: string[];
  paging: Paging | undefined;
}

export interface Paging {
  nextQ: string;
}

export interface GetInnovatorChatRoomsRequest {
  limit: number;
  chatRoomIdBefore: string;
  updatedAtBefore: number;
}

export interface GetInnovatorChatRoomsResponse {
  chatRooms: InnovatorChatRoom[];
}

export interface GetInnovatorChatRoomsRefreshRequest {
  limit: number;
  chatRoomIdAfter: string;
  updatedAtAfter: number;
}

export interface GetInnovatorChatRoomsRefreshResponse {
  chatRooms: InnovatorChatRoom[];
}

export interface GetMissionRequest {
  missionId: string;
}

export interface GetMissionResponse {
  mission: GetMissionResponse_Mission | undefined;
}

export interface GetMissionResponse_Mission {
  missionId: string;
  name: string;
  iconImage: Images | undefined;
  coverImage: Images | undefined;
  missionDescription: string;
  strengthPoint: string;
  companyName: string;
  japanPrefecture: JapanPrefecture | undefined;
  otherAddress: string;
  memberRange: MemberRange | undefined;
  fund: Fund | undefined;
  missionCategories: MissionCategory[];
  missionMembers: MissionMember[];
  companyUrl: string;
  missionOfficeImages: MissionOfficeImage[];
  missionProducts: GetMissionResponse_MissionProduct[];
  corporateAttributes: CorporateAttribute[];
  companyEstablishedDate: CompanyEstablishedDate | undefined;
  missionJobOffers: MissionJobOffer[];
}

export interface GetMissionResponse_MissionProduct {
  missionProductId: number;
  name: string;
  overview: string;
  url: string;
  problem: string;
  solution: string;
  image: Images | undefined;
  productTarget: MissionProductTarget | undefined;
  tags: string[];
}

const baseGetInnovatorFormRequest: object = {
};

const baseGetInnovatorFormResponse: object = {
  innovator: undefined,
  form: undefined,
};

const baseGetInnovatorFormResponse_Form: object = {
  countries: undefined,
  japanPrefectures: undefined,
  employmentTypes: undefined,
  occupations: undefined,
  englishSkills: undefined,
  annualIncomes: undefined,
  preferredMonthlyUnitPrices: undefined,
  numberOfJobChanges: undefined,
  yearsOfExperiences: undefined,
  positions: undefined,
  departments: undefined,
  dttRanks: undefined,
  programmingExperienceLevels: undefined,
  preferredWorkStyles: undefined,
  preferredBusinessTypes: undefined,
  pastExperienceAchievements: undefined,
  genders: undefined,
};

const baseUpdateInnovatorRequest: object = {
  innovatorId: "",
  lastName: "",
  firstName: "",
  lastNameKana: "",
  firstNameKana: "",
  yearOfBirth: 0,
  monthOfBirth: 0,
  phoneCountryCode: "",
  phoneNumber: "",
  japanPrefectureId: undefined,
  preferredWorkLocations: undefined,
  preferredOccupationIds: 0,
  academicBackground: undefined,
  englishSkillId: 0,
  selfIntroduction: "",
  certifications: "",
  outputs: "",
  dttScore: undefined,
  dttRankId: undefined,
  dateOfBirth: 0,
  genderId: 0,
};

const baseUpdateInnovatorRequest_AcademicBackground: object = {
  schoolId: 0,
  faculty: "",
  departmentId: 0,
  yearOfGraduation: 0,
  monthOfGraduation: 0,
};

const baseCareerProfileToUpdate: object = {
  job: undefined,
  annualIncomeId: 0,
  monthlyUnitPrice: undefined,
  numberOfJobChangesId: 0,
  fileKey: "",
  preferredMonthlyUnitPriceId: 0,
  achievement: "",
  preferredEmploymentTypeId: 0,
  skillIds: 0,
  expectationIds: 0,
};

const baseCareerProfileToUpdate_JobHistory: object = {
  name: "",
  positionId: 0,
  startYear: 0,
  startMonth: 0,
  endYear: undefined,
  endMonth: undefined,
  occupations: undefined,
};

const baseCareerProfileToUpdate_ExperiencedOccupation: object = {
  occupationId: 0,
  yearsOfExperienceId: 0,
};

const baseNewGraduateProfileToUpdate: object = {
  researchRooms: undefined,
  programmingExperiences: undefined,
  preferredWorkStyleId: undefined,
  preferredBusinessTypeIds: 0,
  pastExperienceAchievementIds: 0,
  internshipExperiences: undefined,
  studyAbroadExperiences: undefined,
  clubExperiences: undefined,
};

const baseNewGraduateProfileToUpdate_ResearchRoom: object = {
  name: "",
  content: "",
};

const baseNewGraduateProfileToUpdate_ProgrammingExperience: object = {
  languageName: "",
  levelId: 0,
};

const baseNewGraduateProfileToUpdate_InternshipExperience: object = {
  companyName: "",
  startYear: undefined,
  startMonth: undefined,
  endYear: undefined,
  endMonth: undefined,
};

const baseNewGraduateProfileToUpdate_StudyAbroadExperience: object = {
  countryName: "",
  startYear: undefined,
  startMonth: undefined,
  endYear: undefined,
  endMonth: undefined,
};

const baseNewGraduateProfileToUpdate_ClubExperience: object = {
  name: "",
  content: "",
};

const baseUpdateInnovatorResponse: object = {
  innovator: undefined,
};

const baseSendPublishRequestRequest: object = {
  innovatorId: "",
};

const baseSendPublishRequestResponse: object = {
};

const baseGetPublishSettingFormRequest: object = {
};

const baseGetPublishSettingFormResponse: object = {
  categories: undefined,
  corporateAttributes: undefined,
  funds: undefined,
  japanPrefectures: undefined,
  memberRanges: undefined,
};

const baseGetPublishSettingRequest: object = {
};

const baseGetPublishSettingResponse: object = {
  categories: undefined,
  corporateAttributes: undefined,
  funds: undefined,
  locations: undefined,
  memberRanges: undefined,
  blockCompanies: undefined,
  publish: false,
};

const baseUpdatePublishSettingRequest: object = {
  categories: 0,
  corporateAttributes: 0,
  funds: 0,
  locations: undefined,
  memberRanges: 0,
  blockCompanies: "",
  publish: false,
  innovatorId: "",
};

const baseUpdatePublishSettingRequest_Location: object = {
  japanPrefectureId: undefined,
};

const baseUpdatePublishSettingResponse: object = {
};

const baseGetMatterStatusRequest: object = {
};

const baseGetMatterStatusResponse: object = {
  matterId: undefined,
  statusId: undefined,
  registered: false,
};

const baseCreateInnovatorChatRoomRequest: object = {
};

const baseCreateInnovatorChatRoomResponse: object = {
  chatRoomId: "",
};

const baseGetInnovatorChatMessagesRequest: object = {
  chatRoomId: "",
  limit: 0,
  messageIdBefore: undefined,
  messageIdAfter: undefined,
};

const baseGetInnovatorChatMessagesResponse: object = {
  messages: undefined,
  prevQuery: "",
  nextQuery: "",
};

const basePostInnovatorChatMessageRequest: object = {
  chatRoomId: "",
  message: "",
};

const basePostInnovatorChatMessageResponse: object = {
};

const basePostInnovatorChatFileRequest: object = {
  chatRoomId: "",
  data: "",
  fileName: "",
};

const basePostInnovatorChatFileResponse: object = {
};

const baseDeleteInnovatorChatMessageRequest: object = {
  chatRoomId: "",
  messageId: 0,
};

const baseDeleteInnovatorChatMessageResponse: object = {
};

const baseFlushInnovatorChatUnreadCountRequest: object = {
  chatRoomId: "",
};

const baseFlushInnovatorChatUnreadCountResponse: object = {
};

const baseGetInnovatorAccountSettingsRequest: object = {
};

const baseGetInnovatorAccountSettingsResponse: object = {
  chatEmailNotificationDisabled: false,
  chatPushNotificationDisabled: false,
};

const baseChangeInnovatorAccountSettingsRequest: object = {
  innovatorId: "",
  chatEmailNotificationDisabled: undefined,
  chatPushNotificationDisabled: undefined,
};

const baseChangeInnovatorAccountSettingsResponse: object = {
};

const basePostConfirmedJoinCompanyRequest: object = {
  companyName: "",
  joinCompanyScheduleAt: 0,
};

const basePostConfirmedJoinCompanyResponse: object = {
};

const baseGetConfirmedJoinCompanySettingRequest: object = {
};

const baseGetConfirmedJoinCompanySettingResponse: object = {
  disabled: false,
  description: "",
};

const baseGetMissionCompanyNamesRequest: object = {
  name: "",
  limit: 0,
  q: "",
};

const baseGetMissionCompanyNamesResponse: object = {
  names: "",
  paging: undefined,
};

const basePaging: object = {
  nextQ: "",
};

const baseGetInnovatorChatRoomsRequest: object = {
  limit: 0,
  chatRoomIdBefore: "",
  updatedAtBefore: 0,
};

const baseGetInnovatorChatRoomsResponse: object = {
  chatRooms: undefined,
};

const baseGetInnovatorChatRoomsRefreshRequest: object = {
  limit: 0,
  chatRoomIdAfter: "",
  updatedAtAfter: 0,
};

const baseGetInnovatorChatRoomsRefreshResponse: object = {
  chatRooms: undefined,
};

const baseGetMissionRequest: object = {
  missionId: "",
};

const baseGetMissionResponse: object = {
  mission: undefined,
};

const baseGetMissionResponse_Mission: object = {
  missionId: "",
  name: "",
  iconImage: undefined,
  coverImage: undefined,
  missionDescription: "",
  strengthPoint: "",
  companyName: "",
  japanPrefecture: undefined,
  otherAddress: "",
  memberRange: undefined,
  fund: undefined,
  missionCategories: undefined,
  missionMembers: undefined,
  companyUrl: "",
  missionOfficeImages: undefined,
  missionProducts: undefined,
  corporateAttributes: undefined,
  companyEstablishedDate: undefined,
  missionJobOffers: undefined,
};

const baseGetMissionResponse_MissionProduct: object = {
  missionProductId: 0,
  name: "",
  overview: "",
  url: "",
  problem: "",
  solution: "",
  image: undefined,
  productTarget: undefined,
  tags: "",
};

export interface InnovatorService {

  GetInnovatorForm(request: GetInnovatorFormRequest): Promise<GetInnovatorFormResponse>;

  UpdateInnovator(request: UpdateInnovatorRequest): Promise<UpdateInnovatorResponse>;

  SendPublishRequest(request: SendPublishRequestRequest): Promise<SendPublishRequestResponse>;

  GetPublishSettingForm(request: GetPublishSettingFormRequest): Promise<GetPublishSettingFormResponse>;

  GetPublishSetting(request: GetPublishSettingRequest): Promise<GetPublishSettingResponse>;

  UpdatePublishSetting(request: UpdatePublishSettingRequest): Promise<UpdatePublishSettingResponse>;

  GetMatterStatus(request: GetMatterStatusRequest): Promise<GetMatterStatusResponse>;

  CreateInnovatorChatRoom(request: CreateInnovatorChatRoomRequest): Promise<CreateInnovatorChatRoomResponse>;

  GetInnovatorChatMessages(request: GetInnovatorChatMessagesRequest): Promise<GetInnovatorChatMessagesResponse>;

  PostInnovatorChatMessage(request: PostInnovatorChatMessageRequest): Promise<PostInnovatorChatMessageResponse>;

  DeleteInnovatorChatMessage(request: DeleteInnovatorChatMessageRequest): Promise<DeleteInnovatorChatMessageResponse>;

  PostInnovatorChatFile(request: PostInnovatorChatFileRequest): Promise<PostInnovatorChatFileResponse>;

  FlushInnovatorChatUnreadCount(request: FlushInnovatorChatUnreadCountRequest): Promise<FlushInnovatorChatUnreadCountResponse>;

  GetInnovatorAccountSettings(request: GetInnovatorAccountSettingsRequest): Promise<GetInnovatorAccountSettingsResponse>;

  ChangeInnovatorAccountSettings(request: ChangeInnovatorAccountSettingsRequest): Promise<ChangeInnovatorAccountSettingsResponse>;

  PostConfirmedJoinCompany(request: PostConfirmedJoinCompanyRequest): Promise<PostConfirmedJoinCompanyResponse>;

  GetConfirmedJoinCompanySetting(request: GetConfirmedJoinCompanySettingRequest): Promise<GetConfirmedJoinCompanySettingResponse>;

  GetMissionCompanyNames(request: GetMissionCompanyNamesRequest): Promise<GetMissionCompanyNamesResponse>;

  GetInnovatorChatRooms(request: GetInnovatorChatRoomsRequest): Promise<GetInnovatorChatRoomsResponse>;

  GetInnovatorChatRoomsRefresh(request: GetInnovatorChatRoomsRefreshRequest): Promise<GetInnovatorChatRoomsRefreshResponse>;

  GetMission(request: GetMissionRequest): Promise<GetMissionResponse>;

}

export class InnovatorServiceClientImpl implements InnovatorService {

  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
  }

  GetInnovatorForm(request: GetInnovatorFormRequest): Promise<GetInnovatorFormResponse> {
    const data = GetInnovatorFormRequest.encode(request).finish();
    const promise = this.rpc.request("innovatorservice.InnovatorService", "GetInnovatorForm", data);
    return promise.then(data => GetInnovatorFormResponse.decode(new Reader(data)));
  }

  UpdateInnovator(request: UpdateInnovatorRequest): Promise<UpdateInnovatorResponse> {
    const data = UpdateInnovatorRequest.encode(request).finish();
    const promise = this.rpc.request("innovatorservice.InnovatorService", "UpdateInnovator", data);
    return promise.then(data => UpdateInnovatorResponse.decode(new Reader(data)));
  }

  SendPublishRequest(request: SendPublishRequestRequest): Promise<SendPublishRequestResponse> {
    const data = SendPublishRequestRequest.encode(request).finish();
    const promise = this.rpc.request("innovatorservice.InnovatorService", "SendPublishRequest", data);
    return promise.then(data => SendPublishRequestResponse.decode(new Reader(data)));
  }

  GetPublishSettingForm(request: GetPublishSettingFormRequest): Promise<GetPublishSettingFormResponse> {
    const data = GetPublishSettingFormRequest.encode(request).finish();
    const promise = this.rpc.request("innovatorservice.InnovatorService", "GetPublishSettingForm", data);
    return promise.then(data => GetPublishSettingFormResponse.decode(new Reader(data)));
  }

  GetPublishSetting(request: GetPublishSettingRequest): Promise<GetPublishSettingResponse> {
    const data = GetPublishSettingRequest.encode(request).finish();
    const promise = this.rpc.request("innovatorservice.InnovatorService", "GetPublishSetting", data);
    return promise.then(data => GetPublishSettingResponse.decode(new Reader(data)));
  }

  UpdatePublishSetting(request: UpdatePublishSettingRequest): Promise<UpdatePublishSettingResponse> {
    const data = UpdatePublishSettingRequest.encode(request).finish();
    const promise = this.rpc.request("innovatorservice.InnovatorService", "UpdatePublishSetting", data);
    return promise.then(data => UpdatePublishSettingResponse.decode(new Reader(data)));
  }

  GetMatterStatus(request: GetMatterStatusRequest): Promise<GetMatterStatusResponse> {
    const data = GetMatterStatusRequest.encode(request).finish();
    const promise = this.rpc.request("innovatorservice.InnovatorService", "GetMatterStatus", data);
    return promise.then(data => GetMatterStatusResponse.decode(new Reader(data)));
  }

  CreateInnovatorChatRoom(request: CreateInnovatorChatRoomRequest): Promise<CreateInnovatorChatRoomResponse> {
    const data = CreateInnovatorChatRoomRequest.encode(request).finish();
    const promise = this.rpc.request("innovatorservice.InnovatorService", "CreateInnovatorChatRoom", data);
    return promise.then(data => CreateInnovatorChatRoomResponse.decode(new Reader(data)));
  }

  GetInnovatorChatMessages(request: GetInnovatorChatMessagesRequest): Promise<GetInnovatorChatMessagesResponse> {
    const data = GetInnovatorChatMessagesRequest.encode(request).finish();
    const promise = this.rpc.request("innovatorservice.InnovatorService", "GetInnovatorChatMessages", data);
    return promise.then(data => GetInnovatorChatMessagesResponse.decode(new Reader(data)));
  }

  PostInnovatorChatMessage(request: PostInnovatorChatMessageRequest): Promise<PostInnovatorChatMessageResponse> {
    const data = PostInnovatorChatMessageRequest.encode(request).finish();
    const promise = this.rpc.request("innovatorservice.InnovatorService", "PostInnovatorChatMessage", data);
    return promise.then(data => PostInnovatorChatMessageResponse.decode(new Reader(data)));
  }

  DeleteInnovatorChatMessage(request: DeleteInnovatorChatMessageRequest): Promise<DeleteInnovatorChatMessageResponse> {
    const data = DeleteInnovatorChatMessageRequest.encode(request).finish();
    const promise = this.rpc.request("innovatorservice.InnovatorService", "DeleteInnovatorChatMessage", data);
    return promise.then(data => DeleteInnovatorChatMessageResponse.decode(new Reader(data)));
  }

  PostInnovatorChatFile(request: PostInnovatorChatFileRequest): Promise<PostInnovatorChatFileResponse> {
    const data = PostInnovatorChatFileRequest.encode(request).finish();
    const promise = this.rpc.request("innovatorservice.InnovatorService", "PostInnovatorChatFile", data);
    return promise.then(data => PostInnovatorChatFileResponse.decode(new Reader(data)));
  }

  FlushInnovatorChatUnreadCount(request: FlushInnovatorChatUnreadCountRequest): Promise<FlushInnovatorChatUnreadCountResponse> {
    const data = FlushInnovatorChatUnreadCountRequest.encode(request).finish();
    const promise = this.rpc.request("innovatorservice.InnovatorService", "FlushInnovatorChatUnreadCount", data);
    return promise.then(data => FlushInnovatorChatUnreadCountResponse.decode(new Reader(data)));
  }

  GetInnovatorAccountSettings(request: GetInnovatorAccountSettingsRequest): Promise<GetInnovatorAccountSettingsResponse> {
    const data = GetInnovatorAccountSettingsRequest.encode(request).finish();
    const promise = this.rpc.request("innovatorservice.InnovatorService", "GetInnovatorAccountSettings", data);
    return promise.then(data => GetInnovatorAccountSettingsResponse.decode(new Reader(data)));
  }

  ChangeInnovatorAccountSettings(request: ChangeInnovatorAccountSettingsRequest): Promise<ChangeInnovatorAccountSettingsResponse> {
    const data = ChangeInnovatorAccountSettingsRequest.encode(request).finish();
    const promise = this.rpc.request("innovatorservice.InnovatorService", "ChangeInnovatorAccountSettings", data);
    return promise.then(data => ChangeInnovatorAccountSettingsResponse.decode(new Reader(data)));
  }

  PostConfirmedJoinCompany(request: PostConfirmedJoinCompanyRequest): Promise<PostConfirmedJoinCompanyResponse> {
    const data = PostConfirmedJoinCompanyRequest.encode(request).finish();
    const promise = this.rpc.request("innovatorservice.InnovatorService", "PostConfirmedJoinCompany", data);
    return promise.then(data => PostConfirmedJoinCompanyResponse.decode(new Reader(data)));
  }

  GetConfirmedJoinCompanySetting(request: GetConfirmedJoinCompanySettingRequest): Promise<GetConfirmedJoinCompanySettingResponse> {
    const data = GetConfirmedJoinCompanySettingRequest.encode(request).finish();
    const promise = this.rpc.request("innovatorservice.InnovatorService", "GetConfirmedJoinCompanySetting", data);
    return promise.then(data => GetConfirmedJoinCompanySettingResponse.decode(new Reader(data)));
  }

  GetMissionCompanyNames(request: GetMissionCompanyNamesRequest): Promise<GetMissionCompanyNamesResponse> {
    const data = GetMissionCompanyNamesRequest.encode(request).finish();
    const promise = this.rpc.request("innovatorservice.InnovatorService", "GetMissionCompanyNames", data);
    return promise.then(data => GetMissionCompanyNamesResponse.decode(new Reader(data)));
  }

  GetInnovatorChatRooms(request: GetInnovatorChatRoomsRequest): Promise<GetInnovatorChatRoomsResponse> {
    const data = GetInnovatorChatRoomsRequest.encode(request).finish();
    const promise = this.rpc.request("innovatorservice.InnovatorService", "GetInnovatorChatRooms", data);
    return promise.then(data => GetInnovatorChatRoomsResponse.decode(new Reader(data)));
  }

  GetInnovatorChatRoomsRefresh(request: GetInnovatorChatRoomsRefreshRequest): Promise<GetInnovatorChatRoomsRefreshResponse> {
    const data = GetInnovatorChatRoomsRefreshRequest.encode(request).finish();
    const promise = this.rpc.request("innovatorservice.InnovatorService", "GetInnovatorChatRoomsRefresh", data);
    return promise.then(data => GetInnovatorChatRoomsRefreshResponse.decode(new Reader(data)));
  }

  GetMission(request: GetMissionRequest): Promise<GetMissionResponse> {
    const data = GetMissionRequest.encode(request).finish();
    const promise = this.rpc.request("innovatorservice.InnovatorService", "GetMission", data);
    return promise.then(data => GetMissionResponse.decode(new Reader(data)));
  }

}

interface Rpc {

  request(service: string, method: string, data: Uint8Array): Promise<Uint8Array>;

}

function longToNumber(long: Long) {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new global.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

export const GetInnovatorFormRequest = {
  encode(message: GetInnovatorFormRequest, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): GetInnovatorFormRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetInnovatorFormRequest) as GetInnovatorFormRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetInnovatorFormRequest {
    const message = Object.create(baseGetInnovatorFormRequest) as GetInnovatorFormRequest;
    return message;
  },
  fromPartial(object: DeepPartial<GetInnovatorFormRequest>): GetInnovatorFormRequest {
    const message = Object.create(baseGetInnovatorFormRequest) as GetInnovatorFormRequest;
    return message;
  },
  toJSON(message: GetInnovatorFormRequest): unknown {
    const obj: any = {};
    return obj;
  },
};

export const GetInnovatorFormResponse = {
  encode(message: GetInnovatorFormResponse, writer: Writer = Writer.create()): Writer {
    if (message.innovator !== undefined && message.innovator !== undefined) {
      Innovator.encode(message.innovator, writer.uint32(10).fork()).ldelim();
    }
    if (message.form !== undefined && message.form !== undefined) {
      GetInnovatorFormResponse_Form.encode(message.form, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): GetInnovatorFormResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetInnovatorFormResponse) as GetInnovatorFormResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.innovator = Innovator.decode(reader, reader.uint32());
          break;
        case 2:
          message.form = GetInnovatorFormResponse_Form.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetInnovatorFormResponse {
    const message = Object.create(baseGetInnovatorFormResponse) as GetInnovatorFormResponse;
    if (object.innovator) {
      message.innovator = Innovator.fromJSON(object.innovator);
    }
    if (object.form) {
      message.form = GetInnovatorFormResponse_Form.fromJSON(object.form);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetInnovatorFormResponse>): GetInnovatorFormResponse {
    const message = Object.create(baseGetInnovatorFormResponse) as GetInnovatorFormResponse;
    if (object.innovator) {
      message.innovator = Innovator.fromPartial(object.innovator);
    }
    if (object.form) {
      message.form = GetInnovatorFormResponse_Form.fromPartial(object.form);
    }
    return message;
  },
  toJSON(message: GetInnovatorFormResponse): unknown {
    const obj: any = {};
    obj.innovator = message.innovator ? Innovator.toJSON(message.innovator) : undefined;
    obj.form = message.form ? GetInnovatorFormResponse_Form.toJSON(message.form) : undefined;
    return obj;
  },
};

export const GetInnovatorFormResponse_Form = {
  encode(message: GetInnovatorFormResponse_Form, writer: Writer = Writer.create()): Writer {
    for (const v of message.countries) {
      Country.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.japanPrefectures) {
      JapanPrefecture.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.employmentTypes) {
      EmploymentType.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.occupations) {
      Occupation.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.englishSkills) {
      EnglishSkill.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    for (const v of message.annualIncomes) {
      AnnualIncome.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    for (const v of message.preferredMonthlyUnitPrices) {
      PreferredMonthlyUnitPrice.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    for (const v of message.numberOfJobChanges) {
      NumberOfJobChanges.encode(v!, writer.uint32(66).fork()).ldelim();
    }
    for (const v of message.yearsOfExperiences) {
      YearsOfExperience.encode(v!, writer.uint32(74).fork()).ldelim();
    }
    for (const v of message.positions) {
      Position.encode(v!, writer.uint32(82).fork()).ldelim();
    }
    for (const v of message.departments) {
      Department.encode(v!, writer.uint32(90).fork()).ldelim();
    }
    for (const v of message.dttRanks) {
      DttRank.encode(v!, writer.uint32(98).fork()).ldelim();
    }
    for (const v of message.programmingExperienceLevels) {
      ProgrammingExperienceLevel.encode(v!, writer.uint32(106).fork()).ldelim();
    }
    for (const v of message.preferredWorkStyles) {
      PreferredWorkStyle.encode(v!, writer.uint32(114).fork()).ldelim();
    }
    for (const v of message.preferredBusinessTypes) {
      PreferredBusinessType.encode(v!, writer.uint32(122).fork()).ldelim();
    }
    for (const v of message.pastExperienceAchievements) {
      PastExperienceAchievement.encode(v!, writer.uint32(130).fork()).ldelim();
    }
    for (const v of message.genders) {
      Gender.encode(v!, writer.uint32(138).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): GetInnovatorFormResponse_Form {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetInnovatorFormResponse_Form) as GetInnovatorFormResponse_Form;
    message.countries = [];
    message.japanPrefectures = [];
    message.employmentTypes = [];
    message.occupations = [];
    message.englishSkills = [];
    message.annualIncomes = [];
    message.preferredMonthlyUnitPrices = [];
    message.numberOfJobChanges = [];
    message.yearsOfExperiences = [];
    message.positions = [];
    message.departments = [];
    message.dttRanks = [];
    message.programmingExperienceLevels = [];
    message.preferredWorkStyles = [];
    message.preferredBusinessTypes = [];
    message.pastExperienceAchievements = [];
    message.genders = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.countries.push(Country.decode(reader, reader.uint32()));
          break;
        case 2:
          message.japanPrefectures.push(JapanPrefecture.decode(reader, reader.uint32()));
          break;
        case 3:
          message.employmentTypes.push(EmploymentType.decode(reader, reader.uint32()));
          break;
        case 4:
          message.occupations.push(Occupation.decode(reader, reader.uint32()));
          break;
        case 5:
          message.englishSkills.push(EnglishSkill.decode(reader, reader.uint32()));
          break;
        case 6:
          message.annualIncomes.push(AnnualIncome.decode(reader, reader.uint32()));
          break;
        case 7:
          message.preferredMonthlyUnitPrices.push(PreferredMonthlyUnitPrice.decode(reader, reader.uint32()));
          break;
        case 8:
          message.numberOfJobChanges.push(NumberOfJobChanges.decode(reader, reader.uint32()));
          break;
        case 9:
          message.yearsOfExperiences.push(YearsOfExperience.decode(reader, reader.uint32()));
          break;
        case 10:
          message.positions.push(Position.decode(reader, reader.uint32()));
          break;
        case 11:
          message.departments.push(Department.decode(reader, reader.uint32()));
          break;
        case 12:
          message.dttRanks.push(DttRank.decode(reader, reader.uint32()));
          break;
        case 13:
          message.programmingExperienceLevels.push(ProgrammingExperienceLevel.decode(reader, reader.uint32()));
          break;
        case 14:
          message.preferredWorkStyles.push(PreferredWorkStyle.decode(reader, reader.uint32()));
          break;
        case 15:
          message.preferredBusinessTypes.push(PreferredBusinessType.decode(reader, reader.uint32()));
          break;
        case 16:
          message.pastExperienceAchievements.push(PastExperienceAchievement.decode(reader, reader.uint32()));
          break;
        case 17:
          message.genders.push(Gender.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetInnovatorFormResponse_Form {
    const message = Object.create(baseGetInnovatorFormResponse_Form) as GetInnovatorFormResponse_Form;
    message.countries = [];
    message.japanPrefectures = [];
    message.employmentTypes = [];
    message.occupations = [];
    message.englishSkills = [];
    message.annualIncomes = [];
    message.preferredMonthlyUnitPrices = [];
    message.numberOfJobChanges = [];
    message.yearsOfExperiences = [];
    message.positions = [];
    message.departments = [];
    message.dttRanks = [];
    message.programmingExperienceLevels = [];
    message.preferredWorkStyles = [];
    message.preferredBusinessTypes = [];
    message.pastExperienceAchievements = [];
    message.genders = [];
    if (object.countries) {
      for (const e of object.countries) {
        message.countries.push(Country.fromJSON(e));
      }
    }
    if (object.japanPrefectures) {
      for (const e of object.japanPrefectures) {
        message.japanPrefectures.push(JapanPrefecture.fromJSON(e));
      }
    }
    if (object.employmentTypes) {
      for (const e of object.employmentTypes) {
        message.employmentTypes.push(EmploymentType.fromJSON(e));
      }
    }
    if (object.occupations) {
      for (const e of object.occupations) {
        message.occupations.push(Occupation.fromJSON(e));
      }
    }
    if (object.englishSkills) {
      for (const e of object.englishSkills) {
        message.englishSkills.push(EnglishSkill.fromJSON(e));
      }
    }
    if (object.annualIncomes) {
      for (const e of object.annualIncomes) {
        message.annualIncomes.push(AnnualIncome.fromJSON(e));
      }
    }
    if (object.preferredMonthlyUnitPrices) {
      for (const e of object.preferredMonthlyUnitPrices) {
        message.preferredMonthlyUnitPrices.push(PreferredMonthlyUnitPrice.fromJSON(e));
      }
    }
    if (object.numberOfJobChanges) {
      for (const e of object.numberOfJobChanges) {
        message.numberOfJobChanges.push(NumberOfJobChanges.fromJSON(e));
      }
    }
    if (object.yearsOfExperiences) {
      for (const e of object.yearsOfExperiences) {
        message.yearsOfExperiences.push(YearsOfExperience.fromJSON(e));
      }
    }
    if (object.positions) {
      for (const e of object.positions) {
        message.positions.push(Position.fromJSON(e));
      }
    }
    if (object.departments) {
      for (const e of object.departments) {
        message.departments.push(Department.fromJSON(e));
      }
    }
    if (object.dttRanks) {
      for (const e of object.dttRanks) {
        message.dttRanks.push(DttRank.fromJSON(e));
      }
    }
    if (object.programmingExperienceLevels) {
      for (const e of object.programmingExperienceLevels) {
        message.programmingExperienceLevels.push(ProgrammingExperienceLevel.fromJSON(e));
      }
    }
    if (object.preferredWorkStyles) {
      for (const e of object.preferredWorkStyles) {
        message.preferredWorkStyles.push(PreferredWorkStyle.fromJSON(e));
      }
    }
    if (object.preferredBusinessTypes) {
      for (const e of object.preferredBusinessTypes) {
        message.preferredBusinessTypes.push(PreferredBusinessType.fromJSON(e));
      }
    }
    if (object.pastExperienceAchievements) {
      for (const e of object.pastExperienceAchievements) {
        message.pastExperienceAchievements.push(PastExperienceAchievement.fromJSON(e));
      }
    }
    if (object.genders) {
      for (const e of object.genders) {
        message.genders.push(Gender.fromJSON(e));
      }
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetInnovatorFormResponse_Form>): GetInnovatorFormResponse_Form {
    const message = Object.create(baseGetInnovatorFormResponse_Form) as GetInnovatorFormResponse_Form;
    message.countries = [];
    message.japanPrefectures = [];
    message.employmentTypes = [];
    message.occupations = [];
    message.englishSkills = [];
    message.annualIncomes = [];
    message.preferredMonthlyUnitPrices = [];
    message.numberOfJobChanges = [];
    message.yearsOfExperiences = [];
    message.positions = [];
    message.departments = [];
    message.dttRanks = [];
    message.programmingExperienceLevels = [];
    message.preferredWorkStyles = [];
    message.preferredBusinessTypes = [];
    message.pastExperienceAchievements = [];
    message.genders = [];
    if (object.countries) {
      for (const e of object.countries) {
        message.countries.push(Country.fromPartial(e));
      }
    }
    if (object.japanPrefectures) {
      for (const e of object.japanPrefectures) {
        message.japanPrefectures.push(JapanPrefecture.fromPartial(e));
      }
    }
    if (object.employmentTypes) {
      for (const e of object.employmentTypes) {
        message.employmentTypes.push(EmploymentType.fromPartial(e));
      }
    }
    if (object.occupations) {
      for (const e of object.occupations) {
        message.occupations.push(Occupation.fromPartial(e));
      }
    }
    if (object.englishSkills) {
      for (const e of object.englishSkills) {
        message.englishSkills.push(EnglishSkill.fromPartial(e));
      }
    }
    if (object.annualIncomes) {
      for (const e of object.annualIncomes) {
        message.annualIncomes.push(AnnualIncome.fromPartial(e));
      }
    }
    if (object.preferredMonthlyUnitPrices) {
      for (const e of object.preferredMonthlyUnitPrices) {
        message.preferredMonthlyUnitPrices.push(PreferredMonthlyUnitPrice.fromPartial(e));
      }
    }
    if (object.numberOfJobChanges) {
      for (const e of object.numberOfJobChanges) {
        message.numberOfJobChanges.push(NumberOfJobChanges.fromPartial(e));
      }
    }
    if (object.yearsOfExperiences) {
      for (const e of object.yearsOfExperiences) {
        message.yearsOfExperiences.push(YearsOfExperience.fromPartial(e));
      }
    }
    if (object.positions) {
      for (const e of object.positions) {
        message.positions.push(Position.fromPartial(e));
      }
    }
    if (object.departments) {
      for (const e of object.departments) {
        message.departments.push(Department.fromPartial(e));
      }
    }
    if (object.dttRanks) {
      for (const e of object.dttRanks) {
        message.dttRanks.push(DttRank.fromPartial(e));
      }
    }
    if (object.programmingExperienceLevels) {
      for (const e of object.programmingExperienceLevels) {
        message.programmingExperienceLevels.push(ProgrammingExperienceLevel.fromPartial(e));
      }
    }
    if (object.preferredWorkStyles) {
      for (const e of object.preferredWorkStyles) {
        message.preferredWorkStyles.push(PreferredWorkStyle.fromPartial(e));
      }
    }
    if (object.preferredBusinessTypes) {
      for (const e of object.preferredBusinessTypes) {
        message.preferredBusinessTypes.push(PreferredBusinessType.fromPartial(e));
      }
    }
    if (object.pastExperienceAchievements) {
      for (const e of object.pastExperienceAchievements) {
        message.pastExperienceAchievements.push(PastExperienceAchievement.fromPartial(e));
      }
    }
    if (object.genders) {
      for (const e of object.genders) {
        message.genders.push(Gender.fromPartial(e));
      }
    }
    return message;
  },
  toJSON(message: GetInnovatorFormResponse_Form): unknown {
    const obj: any = {};
    if (message.countries) {
      obj.countries = message.countries.map(e => e ? Country.toJSON(e) : undefined);
    } else {
      obj.countries = [];
    }
    if (message.japanPrefectures) {
      obj.japanPrefectures = message.japanPrefectures.map(e => e ? JapanPrefecture.toJSON(e) : undefined);
    } else {
      obj.japanPrefectures = [];
    }
    if (message.employmentTypes) {
      obj.employmentTypes = message.employmentTypes.map(e => e ? EmploymentType.toJSON(e) : undefined);
    } else {
      obj.employmentTypes = [];
    }
    if (message.occupations) {
      obj.occupations = message.occupations.map(e => e ? Occupation.toJSON(e) : undefined);
    } else {
      obj.occupations = [];
    }
    if (message.englishSkills) {
      obj.englishSkills = message.englishSkills.map(e => e ? EnglishSkill.toJSON(e) : undefined);
    } else {
      obj.englishSkills = [];
    }
    if (message.annualIncomes) {
      obj.annualIncomes = message.annualIncomes.map(e => e ? AnnualIncome.toJSON(e) : undefined);
    } else {
      obj.annualIncomes = [];
    }
    if (message.preferredMonthlyUnitPrices) {
      obj.preferredMonthlyUnitPrices = message.preferredMonthlyUnitPrices.map(e => e ? PreferredMonthlyUnitPrice.toJSON(e) : undefined);
    } else {
      obj.preferredMonthlyUnitPrices = [];
    }
    if (message.numberOfJobChanges) {
      obj.numberOfJobChanges = message.numberOfJobChanges.map(e => e ? NumberOfJobChanges.toJSON(e) : undefined);
    } else {
      obj.numberOfJobChanges = [];
    }
    if (message.yearsOfExperiences) {
      obj.yearsOfExperiences = message.yearsOfExperiences.map(e => e ? YearsOfExperience.toJSON(e) : undefined);
    } else {
      obj.yearsOfExperiences = [];
    }
    if (message.positions) {
      obj.positions = message.positions.map(e => e ? Position.toJSON(e) : undefined);
    } else {
      obj.positions = [];
    }
    if (message.departments) {
      obj.departments = message.departments.map(e => e ? Department.toJSON(e) : undefined);
    } else {
      obj.departments = [];
    }
    if (message.dttRanks) {
      obj.dttRanks = message.dttRanks.map(e => e ? DttRank.toJSON(e) : undefined);
    } else {
      obj.dttRanks = [];
    }
    if (message.programmingExperienceLevels) {
      obj.programmingExperienceLevels = message.programmingExperienceLevels.map(e => e ? ProgrammingExperienceLevel.toJSON(e) : undefined);
    } else {
      obj.programmingExperienceLevels = [];
    }
    if (message.preferredWorkStyles) {
      obj.preferredWorkStyles = message.preferredWorkStyles.map(e => e ? PreferredWorkStyle.toJSON(e) : undefined);
    } else {
      obj.preferredWorkStyles = [];
    }
    if (message.preferredBusinessTypes) {
      obj.preferredBusinessTypes = message.preferredBusinessTypes.map(e => e ? PreferredBusinessType.toJSON(e) : undefined);
    } else {
      obj.preferredBusinessTypes = [];
    }
    if (message.pastExperienceAchievements) {
      obj.pastExperienceAchievements = message.pastExperienceAchievements.map(e => e ? PastExperienceAchievement.toJSON(e) : undefined);
    } else {
      obj.pastExperienceAchievements = [];
    }
    if (message.genders) {
      obj.genders = message.genders.map(e => e ? Gender.toJSON(e) : undefined);
    } else {
      obj.genders = [];
    }
    return obj;
  },
};

export const UpdateInnovatorRequest = {
  encode(message: UpdateInnovatorRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.innovatorId);
    writer.uint32(18).string(message.lastName);
    writer.uint32(26).string(message.firstName);
    writer.uint32(34).string(message.lastNameKana);
    writer.uint32(42).string(message.firstNameKana);
    writer.uint32(48).uint32(message.yearOfBirth);
    writer.uint32(56).uint32(message.monthOfBirth);
    writer.uint32(66).string(message.phoneCountryCode);
    writer.uint32(74).string(message.phoneNumber);
    if (message.japanPrefectureId !== undefined && message.japanPrefectureId !== undefined) {
      Int32Value.encode({ value: message.japanPrefectureId! }, writer.uint32(82).fork()).ldelim();
    }
    if (message.career !== undefined && message.career !== undefined) {
      CareerProfileToUpdate.encode(message.career, writer.uint32(90).fork()).ldelim();
    }
    if (message.newGraduate !== undefined && message.newGraduate !== undefined) {
      NewGraduateProfileToUpdate.encode(message.newGraduate, writer.uint32(98).fork()).ldelim();
    }
    for (const v of message.preferredWorkLocations) {
      WorkLocation.encode(v!, writer.uint32(114).fork()).ldelim();
    }
    writer.uint32(122).fork();
    for (const v of message.preferredOccupationIds) {
      writer.uint32(v);
    }
    writer.ldelim();
    if (message.academicBackground !== undefined && message.academicBackground !== undefined) {
      UpdateInnovatorRequest_AcademicBackground.encode(message.academicBackground, writer.uint32(138).fork()).ldelim();
    }
    writer.uint32(152).uint32(message.englishSkillId);
    writer.uint32(170).string(message.selfIntroduction);
    for (const v of message.certifications) {
      writer.uint32(178).string(v!);
    }
    for (const v of message.outputs) {
      writer.uint32(186).string(v!);
    }
    if (message.dttScore !== undefined && message.dttScore !== undefined) {
      Int32Value.encode({ value: message.dttScore! }, writer.uint32(194).fork()).ldelim();
    }
    if (message.dttRankId !== undefined && message.dttRankId !== undefined) {
      Int32Value.encode({ value: message.dttRankId! }, writer.uint32(202).fork()).ldelim();
    }
    writer.uint32(208).int64(message.dateOfBirth);
    writer.uint32(216).uint32(message.genderId);
    return writer;
  },
  decode(reader: Reader, length?: number): UpdateInnovatorRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseUpdateInnovatorRequest) as UpdateInnovatorRequest;
    message.preferredWorkLocations = [];
    message.preferredOccupationIds = [];
    message.certifications = [];
    message.outputs = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.innovatorId = reader.string();
          break;
        case 2:
          message.lastName = reader.string();
          break;
        case 3:
          message.firstName = reader.string();
          break;
        case 4:
          message.lastNameKana = reader.string();
          break;
        case 5:
          message.firstNameKana = reader.string();
          break;
        case 6:
          message.yearOfBirth = reader.uint32();
          break;
        case 7:
          message.monthOfBirth = reader.uint32();
          break;
        case 8:
          message.phoneCountryCode = reader.string();
          break;
        case 9:
          message.phoneNumber = reader.string();
          break;
        case 10:
          message.japanPrefectureId = Int32Value.decode(reader, reader.uint32()).value;
          break;
        case 11:
          message.career = CareerProfileToUpdate.decode(reader, reader.uint32());
          break;
        case 12:
          message.newGraduate = NewGraduateProfileToUpdate.decode(reader, reader.uint32());
          break;
        case 14:
          message.preferredWorkLocations.push(WorkLocation.decode(reader, reader.uint32()));
          break;
        case 15:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.preferredOccupationIds.push(reader.uint32());
            }
          } else {
            message.preferredOccupationIds.push(reader.uint32());
          }
          break;
        case 17:
          message.academicBackground = UpdateInnovatorRequest_AcademicBackground.decode(reader, reader.uint32());
          break;
        case 19:
          message.englishSkillId = reader.uint32();
          break;
        case 21:
          message.selfIntroduction = reader.string();
          break;
        case 22:
          message.certifications.push(reader.string());
          break;
        case 23:
          message.outputs.push(reader.string());
          break;
        case 24:
          message.dttScore = Int32Value.decode(reader, reader.uint32()).value;
          break;
        case 25:
          message.dttRankId = Int32Value.decode(reader, reader.uint32()).value;
          break;
        case 26:
          message.dateOfBirth = longToNumber(reader.int64() as Long);
          break;
        case 27:
          message.genderId = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): UpdateInnovatorRequest {
    const message = Object.create(baseUpdateInnovatorRequest) as UpdateInnovatorRequest;
    message.preferredWorkLocations = [];
    message.preferredOccupationIds = [];
    message.certifications = [];
    message.outputs = [];
    if (object.innovatorId) {
      message.innovatorId = String(object.innovatorId);
    }
    if (object.lastName) {
      message.lastName = String(object.lastName);
    }
    if (object.firstName) {
      message.firstName = String(object.firstName);
    }
    if (object.lastNameKana) {
      message.lastNameKana = String(object.lastNameKana);
    }
    if (object.firstNameKana) {
      message.firstNameKana = String(object.firstNameKana);
    }
    if (object.yearOfBirth) {
      message.yearOfBirth = Number(object.yearOfBirth);
    }
    if (object.monthOfBirth) {
      message.monthOfBirth = Number(object.monthOfBirth);
    }
    if (object.phoneCountryCode) {
      message.phoneCountryCode = String(object.phoneCountryCode);
    }
    if (object.phoneNumber) {
      message.phoneNumber = String(object.phoneNumber);
    }
    if (object.japanPrefectureId) {
      message.japanPrefectureId = Number(object.japanPrefectureId);
    }
    if (object.career) {
      message.career = CareerProfileToUpdate.fromJSON(object.career);
    }
    if (object.newGraduate) {
      message.newGraduate = NewGraduateProfileToUpdate.fromJSON(object.newGraduate);
    }
    if (object.preferredWorkLocations) {
      for (const e of object.preferredWorkLocations) {
        message.preferredWorkLocations.push(WorkLocation.fromJSON(e));
      }
    }
    if (object.preferredOccupationIds) {
      for (const e of object.preferredOccupationIds) {
        message.preferredOccupationIds.push(Number(e));
      }
    }
    if (object.academicBackground) {
      message.academicBackground = UpdateInnovatorRequest_AcademicBackground.fromJSON(object.academicBackground);
    }
    if (object.englishSkillId) {
      message.englishSkillId = Number(object.englishSkillId);
    }
    if (object.selfIntroduction) {
      message.selfIntroduction = String(object.selfIntroduction);
    }
    if (object.certifications) {
      for (const e of object.certifications) {
        message.certifications.push(String(e));
      }
    }
    if (object.outputs) {
      for (const e of object.outputs) {
        message.outputs.push(String(e));
      }
    }
    if (object.dttScore) {
      message.dttScore = Number(object.dttScore);
    }
    if (object.dttRankId) {
      message.dttRankId = Number(object.dttRankId);
    }
    if (object.dateOfBirth) {
      message.dateOfBirth = Number(object.dateOfBirth);
    }
    if (object.genderId) {
      message.genderId = Number(object.genderId);
    }
    return message;
  },
  fromPartial(object: DeepPartial<UpdateInnovatorRequest>): UpdateInnovatorRequest {
    const message = Object.create(baseUpdateInnovatorRequest) as UpdateInnovatorRequest;
    message.preferredWorkLocations = [];
    message.preferredOccupationIds = [];
    message.certifications = [];
    message.outputs = [];
    if (object.innovatorId) {
      message.innovatorId = object.innovatorId;
    }
    if (object.lastName) {
      message.lastName = object.lastName;
    }
    if (object.firstName) {
      message.firstName = object.firstName;
    }
    if (object.lastNameKana) {
      message.lastNameKana = object.lastNameKana;
    }
    if (object.firstNameKana) {
      message.firstNameKana = object.firstNameKana;
    }
    if (object.yearOfBirth) {
      message.yearOfBirth = object.yearOfBirth;
    }
    if (object.monthOfBirth) {
      message.monthOfBirth = object.monthOfBirth;
    }
    if (object.phoneCountryCode) {
      message.phoneCountryCode = object.phoneCountryCode;
    }
    if (object.phoneNumber) {
      message.phoneNumber = object.phoneNumber;
    }
    if (object.japanPrefectureId) {
      message.japanPrefectureId = object.japanPrefectureId;
    }
    if (object.career) {
      message.career = CareerProfileToUpdate.fromPartial(object.career);
    }
    if (object.newGraduate) {
      message.newGraduate = NewGraduateProfileToUpdate.fromPartial(object.newGraduate);
    }
    if (object.preferredWorkLocations) {
      for (const e of object.preferredWorkLocations) {
        message.preferredWorkLocations.push(WorkLocation.fromPartial(e));
      }
    }
    if (object.preferredOccupationIds) {
      for (const e of object.preferredOccupationIds) {
        message.preferredOccupationIds.push(e);
      }
    }
    if (object.academicBackground) {
      message.academicBackground = UpdateInnovatorRequest_AcademicBackground.fromPartial(object.academicBackground);
    }
    if (object.englishSkillId) {
      message.englishSkillId = object.englishSkillId;
    }
    if (object.selfIntroduction) {
      message.selfIntroduction = object.selfIntroduction;
    }
    if (object.certifications) {
      for (const e of object.certifications) {
        message.certifications.push(e);
      }
    }
    if (object.outputs) {
      for (const e of object.outputs) {
        message.outputs.push(e);
      }
    }
    if (object.dttScore) {
      message.dttScore = object.dttScore;
    }
    if (object.dttRankId) {
      message.dttRankId = object.dttRankId;
    }
    if (object.dateOfBirth) {
      message.dateOfBirth = object.dateOfBirth;
    }
    if (object.genderId) {
      message.genderId = object.genderId;
    }
    return message;
  },
  toJSON(message: UpdateInnovatorRequest): unknown {
    const obj: any = {};
    obj.innovatorId = message.innovatorId || "";
    obj.lastName = message.lastName || "";
    obj.firstName = message.firstName || "";
    obj.lastNameKana = message.lastNameKana || "";
    obj.firstNameKana = message.firstNameKana || "";
    obj.yearOfBirth = message.yearOfBirth || 0;
    obj.monthOfBirth = message.monthOfBirth || 0;
    obj.phoneCountryCode = message.phoneCountryCode || "";
    obj.phoneNumber = message.phoneNumber || "";
    obj.japanPrefectureId = message.japanPrefectureId || undefined;
    obj.career = message.career ? CareerProfileToUpdate.toJSON(message.career) : undefined;
    obj.newGraduate = message.newGraduate ? NewGraduateProfileToUpdate.toJSON(message.newGraduate) : undefined;
    if (message.preferredWorkLocations) {
      obj.preferredWorkLocations = message.preferredWorkLocations.map(e => e ? WorkLocation.toJSON(e) : undefined);
    } else {
      obj.preferredWorkLocations = [];
    }
    if (message.preferredOccupationIds) {
      obj.preferredOccupationIds = message.preferredOccupationIds.map(e => e || 0);
    } else {
      obj.preferredOccupationIds = [];
    }
    obj.academicBackground = message.academicBackground ? UpdateInnovatorRequest_AcademicBackground.toJSON(message.academicBackground) : undefined;
    obj.englishSkillId = message.englishSkillId || 0;
    obj.selfIntroduction = message.selfIntroduction || "";
    if (message.certifications) {
      obj.certifications = message.certifications.map(e => e || "");
    } else {
      obj.certifications = [];
    }
    if (message.outputs) {
      obj.outputs = message.outputs.map(e => e || "");
    } else {
      obj.outputs = [];
    }
    obj.dttScore = message.dttScore || undefined;
    obj.dttRankId = message.dttRankId || undefined;
    obj.dateOfBirth = message.dateOfBirth || 0;
    obj.genderId = message.genderId || 0;
    return obj;
  },
};

export const UpdateInnovatorRequest_AcademicBackground = {
  encode(message: UpdateInnovatorRequest_AcademicBackground, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).uint32(message.schoolId);
    writer.uint32(18).string(message.faculty);
    writer.uint32(24).uint32(message.departmentId);
    writer.uint32(32).uint32(message.yearOfGraduation);
    writer.uint32(40).uint32(message.monthOfGraduation);
    return writer;
  },
  decode(reader: Reader, length?: number): UpdateInnovatorRequest_AcademicBackground {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseUpdateInnovatorRequest_AcademicBackground) as UpdateInnovatorRequest_AcademicBackground;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.schoolId = reader.uint32();
          break;
        case 2:
          message.faculty = reader.string();
          break;
        case 3:
          message.departmentId = reader.uint32();
          break;
        case 4:
          message.yearOfGraduation = reader.uint32();
          break;
        case 5:
          message.monthOfGraduation = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): UpdateInnovatorRequest_AcademicBackground {
    const message = Object.create(baseUpdateInnovatorRequest_AcademicBackground) as UpdateInnovatorRequest_AcademicBackground;
    if (object.schoolId) {
      message.schoolId = Number(object.schoolId);
    }
    if (object.faculty) {
      message.faculty = String(object.faculty);
    }
    if (object.departmentId) {
      message.departmentId = Number(object.departmentId);
    }
    if (object.yearOfGraduation) {
      message.yearOfGraduation = Number(object.yearOfGraduation);
    }
    if (object.monthOfGraduation) {
      message.monthOfGraduation = Number(object.monthOfGraduation);
    }
    return message;
  },
  fromPartial(object: DeepPartial<UpdateInnovatorRequest_AcademicBackground>): UpdateInnovatorRequest_AcademicBackground {
    const message = Object.create(baseUpdateInnovatorRequest_AcademicBackground) as UpdateInnovatorRequest_AcademicBackground;
    if (object.schoolId) {
      message.schoolId = object.schoolId;
    }
    if (object.faculty) {
      message.faculty = object.faculty;
    }
    if (object.departmentId) {
      message.departmentId = object.departmentId;
    }
    if (object.yearOfGraduation) {
      message.yearOfGraduation = object.yearOfGraduation;
    }
    if (object.monthOfGraduation) {
      message.monthOfGraduation = object.monthOfGraduation;
    }
    return message;
  },
  toJSON(message: UpdateInnovatorRequest_AcademicBackground): unknown {
    const obj: any = {};
    obj.schoolId = message.schoolId || 0;
    obj.faculty = message.faculty || "";
    obj.departmentId = message.departmentId || 0;
    obj.yearOfGraduation = message.yearOfGraduation || 0;
    obj.monthOfGraduation = message.monthOfGraduation || 0;
    return obj;
  },
};

export const CareerProfileToUpdate = {
  encode(message: CareerProfileToUpdate, writer: Writer = Writer.create()): Writer {
    for (const v of message.job) {
      CareerProfileToUpdate_JobHistory.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    writer.uint32(16).uint32(message.annualIncomeId);
    if (message.monthlyUnitPrice !== undefined && message.monthlyUnitPrice !== undefined) {
      Int32Value.encode({ value: message.monthlyUnitPrice! }, writer.uint32(26).fork()).ldelim();
    }
    writer.uint32(32).uint32(message.numberOfJobChangesId);
    writer.uint32(42).string(message.fileKey);
    writer.uint32(48).uint32(message.preferredMonthlyUnitPriceId);
    writer.uint32(58).string(message.achievement);
    writer.uint32(72).uint32(message.preferredEmploymentTypeId);
    writer.uint32(82).fork();
    for (const v of message.skillIds) {
      writer.uint64(v);
    }
    writer.ldelim();
    writer.uint32(90).fork();
    for (const v of message.expectationIds) {
      writer.uint64(v);
    }
    writer.ldelim();
    return writer;
  },
  decode(reader: Reader, length?: number): CareerProfileToUpdate {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseCareerProfileToUpdate) as CareerProfileToUpdate;
    message.job = [];
    message.skillIds = [];
    message.expectationIds = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.job.push(CareerProfileToUpdate_JobHistory.decode(reader, reader.uint32()));
          break;
        case 2:
          message.annualIncomeId = reader.uint32();
          break;
        case 3:
          message.monthlyUnitPrice = Int32Value.decode(reader, reader.uint32()).value;
          break;
        case 4:
          message.numberOfJobChangesId = reader.uint32();
          break;
        case 5:
          message.fileKey = reader.string();
          break;
        case 6:
          message.preferredMonthlyUnitPriceId = reader.uint32();
          break;
        case 7:
          message.achievement = reader.string();
          break;
        case 9:
          message.preferredEmploymentTypeId = reader.uint32();
          break;
        case 10:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.skillIds.push(longToNumber(reader.uint64() as Long));
            }
          } else {
            message.skillIds.push(longToNumber(reader.uint64() as Long));
          }
          break;
        case 11:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.expectationIds.push(longToNumber(reader.uint64() as Long));
            }
          } else {
            message.expectationIds.push(longToNumber(reader.uint64() as Long));
          }
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): CareerProfileToUpdate {
    const message = Object.create(baseCareerProfileToUpdate) as CareerProfileToUpdate;
    message.job = [];
    message.skillIds = [];
    message.expectationIds = [];
    if (object.job) {
      for (const e of object.job) {
        message.job.push(CareerProfileToUpdate_JobHistory.fromJSON(e));
      }
    }
    if (object.annualIncomeId) {
      message.annualIncomeId = Number(object.annualIncomeId);
    }
    if (object.monthlyUnitPrice) {
      message.monthlyUnitPrice = Number(object.monthlyUnitPrice);
    }
    if (object.numberOfJobChangesId) {
      message.numberOfJobChangesId = Number(object.numberOfJobChangesId);
    }
    if (object.fileKey) {
      message.fileKey = String(object.fileKey);
    }
    if (object.preferredMonthlyUnitPriceId) {
      message.preferredMonthlyUnitPriceId = Number(object.preferredMonthlyUnitPriceId);
    }
    if (object.achievement) {
      message.achievement = String(object.achievement);
    }
    if (object.preferredEmploymentTypeId) {
      message.preferredEmploymentTypeId = Number(object.preferredEmploymentTypeId);
    }
    if (object.skillIds) {
      for (const e of object.skillIds) {
        message.skillIds.push(Number(e));
      }
    }
    if (object.expectationIds) {
      for (const e of object.expectationIds) {
        message.expectationIds.push(Number(e));
      }
    }
    return message;
  },
  fromPartial(object: DeepPartial<CareerProfileToUpdate>): CareerProfileToUpdate {
    const message = Object.create(baseCareerProfileToUpdate) as CareerProfileToUpdate;
    message.job = [];
    message.skillIds = [];
    message.expectationIds = [];
    if (object.job) {
      for (const e of object.job) {
        message.job.push(CareerProfileToUpdate_JobHistory.fromPartial(e));
      }
    }
    if (object.annualIncomeId) {
      message.annualIncomeId = object.annualIncomeId;
    }
    if (object.monthlyUnitPrice) {
      message.monthlyUnitPrice = object.monthlyUnitPrice;
    }
    if (object.numberOfJobChangesId) {
      message.numberOfJobChangesId = object.numberOfJobChangesId;
    }
    if (object.fileKey) {
      message.fileKey = object.fileKey;
    }
    if (object.preferredMonthlyUnitPriceId) {
      message.preferredMonthlyUnitPriceId = object.preferredMonthlyUnitPriceId;
    }
    if (object.achievement) {
      message.achievement = object.achievement;
    }
    if (object.preferredEmploymentTypeId) {
      message.preferredEmploymentTypeId = object.preferredEmploymentTypeId;
    }
    if (object.skillIds) {
      for (const e of object.skillIds) {
        message.skillIds.push(e);
      }
    }
    if (object.expectationIds) {
      for (const e of object.expectationIds) {
        message.expectationIds.push(e);
      }
    }
    return message;
  },
  toJSON(message: CareerProfileToUpdate): unknown {
    const obj: any = {};
    if (message.job) {
      obj.job = message.job.map(e => e ? CareerProfileToUpdate_JobHistory.toJSON(e) : undefined);
    } else {
      obj.job = [];
    }
    obj.annualIncomeId = message.annualIncomeId || 0;
    obj.monthlyUnitPrice = message.monthlyUnitPrice || undefined;
    obj.numberOfJobChangesId = message.numberOfJobChangesId || 0;
    obj.fileKey = message.fileKey || "";
    obj.preferredMonthlyUnitPriceId = message.preferredMonthlyUnitPriceId || 0;
    obj.achievement = message.achievement || "";
    obj.preferredEmploymentTypeId = message.preferredEmploymentTypeId || 0;
    if (message.skillIds) {
      obj.skillIds = message.skillIds.map(e => e || 0);
    } else {
      obj.skillIds = [];
    }
    if (message.expectationIds) {
      obj.expectationIds = message.expectationIds.map(e => e || 0);
    } else {
      obj.expectationIds = [];
    }
    return obj;
  },
};

export const CareerProfileToUpdate_JobHistory = {
  encode(message: CareerProfileToUpdate_JobHistory, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.name);
    writer.uint32(16).uint32(message.positionId);
    writer.uint32(24).uint32(message.startYear);
    writer.uint32(32).uint32(message.startMonth);
    if (message.endYear !== undefined && message.endYear !== undefined) {
      Int32Value.encode({ value: message.endYear! }, writer.uint32(42).fork()).ldelim();
    }
    if (message.endMonth !== undefined && message.endMonth !== undefined) {
      Int32Value.encode({ value: message.endMonth! }, writer.uint32(50).fork()).ldelim();
    }
    for (const v of message.occupations) {
      CareerProfileToUpdate_ExperiencedOccupation.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): CareerProfileToUpdate_JobHistory {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseCareerProfileToUpdate_JobHistory) as CareerProfileToUpdate_JobHistory;
    message.occupations = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.name = reader.string();
          break;
        case 2:
          message.positionId = reader.uint32();
          break;
        case 3:
          message.startYear = reader.uint32();
          break;
        case 4:
          message.startMonth = reader.uint32();
          break;
        case 5:
          message.endYear = Int32Value.decode(reader, reader.uint32()).value;
          break;
        case 6:
          message.endMonth = Int32Value.decode(reader, reader.uint32()).value;
          break;
        case 7:
          message.occupations.push(CareerProfileToUpdate_ExperiencedOccupation.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): CareerProfileToUpdate_JobHistory {
    const message = Object.create(baseCareerProfileToUpdate_JobHistory) as CareerProfileToUpdate_JobHistory;
    message.occupations = [];
    if (object.name) {
      message.name = String(object.name);
    }
    if (object.positionId) {
      message.positionId = Number(object.positionId);
    }
    if (object.startYear) {
      message.startYear = Number(object.startYear);
    }
    if (object.startMonth) {
      message.startMonth = Number(object.startMonth);
    }
    if (object.endYear) {
      message.endYear = Number(object.endYear);
    }
    if (object.endMonth) {
      message.endMonth = Number(object.endMonth);
    }
    if (object.occupations) {
      for (const e of object.occupations) {
        message.occupations.push(CareerProfileToUpdate_ExperiencedOccupation.fromJSON(e));
      }
    }
    return message;
  },
  fromPartial(object: DeepPartial<CareerProfileToUpdate_JobHistory>): CareerProfileToUpdate_JobHistory {
    const message = Object.create(baseCareerProfileToUpdate_JobHistory) as CareerProfileToUpdate_JobHistory;
    message.occupations = [];
    if (object.name) {
      message.name = object.name;
    }
    if (object.positionId) {
      message.positionId = object.positionId;
    }
    if (object.startYear) {
      message.startYear = object.startYear;
    }
    if (object.startMonth) {
      message.startMonth = object.startMonth;
    }
    if (object.endYear) {
      message.endYear = object.endYear;
    }
    if (object.endMonth) {
      message.endMonth = object.endMonth;
    }
    if (object.occupations) {
      for (const e of object.occupations) {
        message.occupations.push(CareerProfileToUpdate_ExperiencedOccupation.fromPartial(e));
      }
    }
    return message;
  },
  toJSON(message: CareerProfileToUpdate_JobHistory): unknown {
    const obj: any = {};
    obj.name = message.name || "";
    obj.positionId = message.positionId || 0;
    obj.startYear = message.startYear || 0;
    obj.startMonth = message.startMonth || 0;
    obj.endYear = message.endYear || undefined;
    obj.endMonth = message.endMonth || undefined;
    if (message.occupations) {
      obj.occupations = message.occupations.map(e => e ? CareerProfileToUpdate_ExperiencedOccupation.toJSON(e) : undefined);
    } else {
      obj.occupations = [];
    }
    return obj;
  },
};

export const CareerProfileToUpdate_ExperiencedOccupation = {
  encode(message: CareerProfileToUpdate_ExperiencedOccupation, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).uint32(message.occupationId);
    writer.uint32(16).uint32(message.yearsOfExperienceId);
    return writer;
  },
  decode(reader: Reader, length?: number): CareerProfileToUpdate_ExperiencedOccupation {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseCareerProfileToUpdate_ExperiencedOccupation) as CareerProfileToUpdate_ExperiencedOccupation;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.occupationId = reader.uint32();
          break;
        case 2:
          message.yearsOfExperienceId = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): CareerProfileToUpdate_ExperiencedOccupation {
    const message = Object.create(baseCareerProfileToUpdate_ExperiencedOccupation) as CareerProfileToUpdate_ExperiencedOccupation;
    if (object.occupationId) {
      message.occupationId = Number(object.occupationId);
    }
    if (object.yearsOfExperienceId) {
      message.yearsOfExperienceId = Number(object.yearsOfExperienceId);
    }
    return message;
  },
  fromPartial(object: DeepPartial<CareerProfileToUpdate_ExperiencedOccupation>): CareerProfileToUpdate_ExperiencedOccupation {
    const message = Object.create(baseCareerProfileToUpdate_ExperiencedOccupation) as CareerProfileToUpdate_ExperiencedOccupation;
    if (object.occupationId) {
      message.occupationId = object.occupationId;
    }
    if (object.yearsOfExperienceId) {
      message.yearsOfExperienceId = object.yearsOfExperienceId;
    }
    return message;
  },
  toJSON(message: CareerProfileToUpdate_ExperiencedOccupation): unknown {
    const obj: any = {};
    obj.occupationId = message.occupationId || 0;
    obj.yearsOfExperienceId = message.yearsOfExperienceId || 0;
    return obj;
  },
};

export const NewGraduateProfileToUpdate = {
  encode(message: NewGraduateProfileToUpdate, writer: Writer = Writer.create()): Writer {
    for (const v of message.researchRooms) {
      NewGraduateProfileToUpdate_ResearchRoom.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.programmingExperiences) {
      NewGraduateProfileToUpdate_ProgrammingExperience.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    if (message.preferredWorkStyleId !== undefined && message.preferredWorkStyleId !== undefined) {
      Int32Value.encode({ value: message.preferredWorkStyleId! }, writer.uint32(26).fork()).ldelim();
    }
    writer.uint32(34).fork();
    for (const v of message.preferredBusinessTypeIds) {
      writer.uint32(v);
    }
    writer.ldelim();
    writer.uint32(42).fork();
    for (const v of message.pastExperienceAchievementIds) {
      writer.uint32(v);
    }
    writer.ldelim();
    for (const v of message.internshipExperiences) {
      NewGraduateProfileToUpdate_InternshipExperience.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    for (const v of message.studyAbroadExperiences) {
      NewGraduateProfileToUpdate_StudyAbroadExperience.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    for (const v of message.clubExperiences) {
      NewGraduateProfileToUpdate_ClubExperience.encode(v!, writer.uint32(66).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): NewGraduateProfileToUpdate {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseNewGraduateProfileToUpdate) as NewGraduateProfileToUpdate;
    message.researchRooms = [];
    message.programmingExperiences = [];
    message.preferredBusinessTypeIds = [];
    message.pastExperienceAchievementIds = [];
    message.internshipExperiences = [];
    message.studyAbroadExperiences = [];
    message.clubExperiences = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.researchRooms.push(NewGraduateProfileToUpdate_ResearchRoom.decode(reader, reader.uint32()));
          break;
        case 2:
          message.programmingExperiences.push(NewGraduateProfileToUpdate_ProgrammingExperience.decode(reader, reader.uint32()));
          break;
        case 3:
          message.preferredWorkStyleId = Int32Value.decode(reader, reader.uint32()).value;
          break;
        case 4:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.preferredBusinessTypeIds.push(reader.uint32());
            }
          } else {
            message.preferredBusinessTypeIds.push(reader.uint32());
          }
          break;
        case 5:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.pastExperienceAchievementIds.push(reader.uint32());
            }
          } else {
            message.pastExperienceAchievementIds.push(reader.uint32());
          }
          break;
        case 6:
          message.internshipExperiences.push(NewGraduateProfileToUpdate_InternshipExperience.decode(reader, reader.uint32()));
          break;
        case 7:
          message.studyAbroadExperiences.push(NewGraduateProfileToUpdate_StudyAbroadExperience.decode(reader, reader.uint32()));
          break;
        case 8:
          message.clubExperiences.push(NewGraduateProfileToUpdate_ClubExperience.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): NewGraduateProfileToUpdate {
    const message = Object.create(baseNewGraduateProfileToUpdate) as NewGraduateProfileToUpdate;
    message.researchRooms = [];
    message.programmingExperiences = [];
    message.preferredBusinessTypeIds = [];
    message.pastExperienceAchievementIds = [];
    message.internshipExperiences = [];
    message.studyAbroadExperiences = [];
    message.clubExperiences = [];
    if (object.researchRooms) {
      for (const e of object.researchRooms) {
        message.researchRooms.push(NewGraduateProfileToUpdate_ResearchRoom.fromJSON(e));
      }
    }
    if (object.programmingExperiences) {
      for (const e of object.programmingExperiences) {
        message.programmingExperiences.push(NewGraduateProfileToUpdate_ProgrammingExperience.fromJSON(e));
      }
    }
    if (object.preferredWorkStyleId) {
      message.preferredWorkStyleId = Number(object.preferredWorkStyleId);
    }
    if (object.preferredBusinessTypeIds) {
      for (const e of object.preferredBusinessTypeIds) {
        message.preferredBusinessTypeIds.push(Number(e));
      }
    }
    if (object.pastExperienceAchievementIds) {
      for (const e of object.pastExperienceAchievementIds) {
        message.pastExperienceAchievementIds.push(Number(e));
      }
    }
    if (object.internshipExperiences) {
      for (const e of object.internshipExperiences) {
        message.internshipExperiences.push(NewGraduateProfileToUpdate_InternshipExperience.fromJSON(e));
      }
    }
    if (object.studyAbroadExperiences) {
      for (const e of object.studyAbroadExperiences) {
        message.studyAbroadExperiences.push(NewGraduateProfileToUpdate_StudyAbroadExperience.fromJSON(e));
      }
    }
    if (object.clubExperiences) {
      for (const e of object.clubExperiences) {
        message.clubExperiences.push(NewGraduateProfileToUpdate_ClubExperience.fromJSON(e));
      }
    }
    return message;
  },
  fromPartial(object: DeepPartial<NewGraduateProfileToUpdate>): NewGraduateProfileToUpdate {
    const message = Object.create(baseNewGraduateProfileToUpdate) as NewGraduateProfileToUpdate;
    message.researchRooms = [];
    message.programmingExperiences = [];
    message.preferredBusinessTypeIds = [];
    message.pastExperienceAchievementIds = [];
    message.internshipExperiences = [];
    message.studyAbroadExperiences = [];
    message.clubExperiences = [];
    if (object.researchRooms) {
      for (const e of object.researchRooms) {
        message.researchRooms.push(NewGraduateProfileToUpdate_ResearchRoom.fromPartial(e));
      }
    }
    if (object.programmingExperiences) {
      for (const e of object.programmingExperiences) {
        message.programmingExperiences.push(NewGraduateProfileToUpdate_ProgrammingExperience.fromPartial(e));
      }
    }
    if (object.preferredWorkStyleId) {
      message.preferredWorkStyleId = object.preferredWorkStyleId;
    }
    if (object.preferredBusinessTypeIds) {
      for (const e of object.preferredBusinessTypeIds) {
        message.preferredBusinessTypeIds.push(e);
      }
    }
    if (object.pastExperienceAchievementIds) {
      for (const e of object.pastExperienceAchievementIds) {
        message.pastExperienceAchievementIds.push(e);
      }
    }
    if (object.internshipExperiences) {
      for (const e of object.internshipExperiences) {
        message.internshipExperiences.push(NewGraduateProfileToUpdate_InternshipExperience.fromPartial(e));
      }
    }
    if (object.studyAbroadExperiences) {
      for (const e of object.studyAbroadExperiences) {
        message.studyAbroadExperiences.push(NewGraduateProfileToUpdate_StudyAbroadExperience.fromPartial(e));
      }
    }
    if (object.clubExperiences) {
      for (const e of object.clubExperiences) {
        message.clubExperiences.push(NewGraduateProfileToUpdate_ClubExperience.fromPartial(e));
      }
    }
    return message;
  },
  toJSON(message: NewGraduateProfileToUpdate): unknown {
    const obj: any = {};
    if (message.researchRooms) {
      obj.researchRooms = message.researchRooms.map(e => e ? NewGraduateProfileToUpdate_ResearchRoom.toJSON(e) : undefined);
    } else {
      obj.researchRooms = [];
    }
    if (message.programmingExperiences) {
      obj.programmingExperiences = message.programmingExperiences.map(e => e ? NewGraduateProfileToUpdate_ProgrammingExperience.toJSON(e) : undefined);
    } else {
      obj.programmingExperiences = [];
    }
    obj.preferredWorkStyleId = message.preferredWorkStyleId || undefined;
    if (message.preferredBusinessTypeIds) {
      obj.preferredBusinessTypeIds = message.preferredBusinessTypeIds.map(e => e || 0);
    } else {
      obj.preferredBusinessTypeIds = [];
    }
    if (message.pastExperienceAchievementIds) {
      obj.pastExperienceAchievementIds = message.pastExperienceAchievementIds.map(e => e || 0);
    } else {
      obj.pastExperienceAchievementIds = [];
    }
    if (message.internshipExperiences) {
      obj.internshipExperiences = message.internshipExperiences.map(e => e ? NewGraduateProfileToUpdate_InternshipExperience.toJSON(e) : undefined);
    } else {
      obj.internshipExperiences = [];
    }
    if (message.studyAbroadExperiences) {
      obj.studyAbroadExperiences = message.studyAbroadExperiences.map(e => e ? NewGraduateProfileToUpdate_StudyAbroadExperience.toJSON(e) : undefined);
    } else {
      obj.studyAbroadExperiences = [];
    }
    if (message.clubExperiences) {
      obj.clubExperiences = message.clubExperiences.map(e => e ? NewGraduateProfileToUpdate_ClubExperience.toJSON(e) : undefined);
    } else {
      obj.clubExperiences = [];
    }
    return obj;
  },
};

export const NewGraduateProfileToUpdate_ResearchRoom = {
  encode(message: NewGraduateProfileToUpdate_ResearchRoom, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.name);
    writer.uint32(18).string(message.content);
    return writer;
  },
  decode(reader: Reader, length?: number): NewGraduateProfileToUpdate_ResearchRoom {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseNewGraduateProfileToUpdate_ResearchRoom) as NewGraduateProfileToUpdate_ResearchRoom;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.name = reader.string();
          break;
        case 2:
          message.content = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): NewGraduateProfileToUpdate_ResearchRoom {
    const message = Object.create(baseNewGraduateProfileToUpdate_ResearchRoom) as NewGraduateProfileToUpdate_ResearchRoom;
    if (object.name) {
      message.name = String(object.name);
    }
    if (object.content) {
      message.content = String(object.content);
    }
    return message;
  },
  fromPartial(object: DeepPartial<NewGraduateProfileToUpdate_ResearchRoom>): NewGraduateProfileToUpdate_ResearchRoom {
    const message = Object.create(baseNewGraduateProfileToUpdate_ResearchRoom) as NewGraduateProfileToUpdate_ResearchRoom;
    if (object.name) {
      message.name = object.name;
    }
    if (object.content) {
      message.content = object.content;
    }
    return message;
  },
  toJSON(message: NewGraduateProfileToUpdate_ResearchRoom): unknown {
    const obj: any = {};
    obj.name = message.name || "";
    obj.content = message.content || "";
    return obj;
  },
};

export const NewGraduateProfileToUpdate_ProgrammingExperience = {
  encode(message: NewGraduateProfileToUpdate_ProgrammingExperience, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.languageName);
    writer.uint32(16).uint32(message.levelId);
    return writer;
  },
  decode(reader: Reader, length?: number): NewGraduateProfileToUpdate_ProgrammingExperience {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseNewGraduateProfileToUpdate_ProgrammingExperience) as NewGraduateProfileToUpdate_ProgrammingExperience;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.languageName = reader.string();
          break;
        case 2:
          message.levelId = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): NewGraduateProfileToUpdate_ProgrammingExperience {
    const message = Object.create(baseNewGraduateProfileToUpdate_ProgrammingExperience) as NewGraduateProfileToUpdate_ProgrammingExperience;
    if (object.languageName) {
      message.languageName = String(object.languageName);
    }
    if (object.levelId) {
      message.levelId = Number(object.levelId);
    }
    return message;
  },
  fromPartial(object: DeepPartial<NewGraduateProfileToUpdate_ProgrammingExperience>): NewGraduateProfileToUpdate_ProgrammingExperience {
    const message = Object.create(baseNewGraduateProfileToUpdate_ProgrammingExperience) as NewGraduateProfileToUpdate_ProgrammingExperience;
    if (object.languageName) {
      message.languageName = object.languageName;
    }
    if (object.levelId) {
      message.levelId = object.levelId;
    }
    return message;
  },
  toJSON(message: NewGraduateProfileToUpdate_ProgrammingExperience): unknown {
    const obj: any = {};
    obj.languageName = message.languageName || "";
    obj.levelId = message.levelId || 0;
    return obj;
  },
};

export const NewGraduateProfileToUpdate_InternshipExperience = {
  encode(message: NewGraduateProfileToUpdate_InternshipExperience, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.companyName);
    if (message.startYear !== undefined && message.startYear !== undefined) {
      Int32Value.encode({ value: message.startYear! }, writer.uint32(18).fork()).ldelim();
    }
    if (message.startMonth !== undefined && message.startMonth !== undefined) {
      Int32Value.encode({ value: message.startMonth! }, writer.uint32(26).fork()).ldelim();
    }
    if (message.endYear !== undefined && message.endYear !== undefined) {
      Int32Value.encode({ value: message.endYear! }, writer.uint32(34).fork()).ldelim();
    }
    if (message.endMonth !== undefined && message.endMonth !== undefined) {
      Int32Value.encode({ value: message.endMonth! }, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): NewGraduateProfileToUpdate_InternshipExperience {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseNewGraduateProfileToUpdate_InternshipExperience) as NewGraduateProfileToUpdate_InternshipExperience;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.companyName = reader.string();
          break;
        case 2:
          message.startYear = Int32Value.decode(reader, reader.uint32()).value;
          break;
        case 3:
          message.startMonth = Int32Value.decode(reader, reader.uint32()).value;
          break;
        case 4:
          message.endYear = Int32Value.decode(reader, reader.uint32()).value;
          break;
        case 5:
          message.endMonth = Int32Value.decode(reader, reader.uint32()).value;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): NewGraduateProfileToUpdate_InternshipExperience {
    const message = Object.create(baseNewGraduateProfileToUpdate_InternshipExperience) as NewGraduateProfileToUpdate_InternshipExperience;
    if (object.companyName) {
      message.companyName = String(object.companyName);
    }
    if (object.startYear) {
      message.startYear = Number(object.startYear);
    }
    if (object.startMonth) {
      message.startMonth = Number(object.startMonth);
    }
    if (object.endYear) {
      message.endYear = Number(object.endYear);
    }
    if (object.endMonth) {
      message.endMonth = Number(object.endMonth);
    }
    return message;
  },
  fromPartial(object: DeepPartial<NewGraduateProfileToUpdate_InternshipExperience>): NewGraduateProfileToUpdate_InternshipExperience {
    const message = Object.create(baseNewGraduateProfileToUpdate_InternshipExperience) as NewGraduateProfileToUpdate_InternshipExperience;
    if (object.companyName) {
      message.companyName = object.companyName;
    }
    if (object.startYear) {
      message.startYear = object.startYear;
    }
    if (object.startMonth) {
      message.startMonth = object.startMonth;
    }
    if (object.endYear) {
      message.endYear = object.endYear;
    }
    if (object.endMonth) {
      message.endMonth = object.endMonth;
    }
    return message;
  },
  toJSON(message: NewGraduateProfileToUpdate_InternshipExperience): unknown {
    const obj: any = {};
    obj.companyName = message.companyName || "";
    obj.startYear = message.startYear || undefined;
    obj.startMonth = message.startMonth || undefined;
    obj.endYear = message.endYear || undefined;
    obj.endMonth = message.endMonth || undefined;
    return obj;
  },
};

export const NewGraduateProfileToUpdate_StudyAbroadExperience = {
  encode(message: NewGraduateProfileToUpdate_StudyAbroadExperience, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.countryName);
    if (message.startYear !== undefined && message.startYear !== undefined) {
      Int32Value.encode({ value: message.startYear! }, writer.uint32(18).fork()).ldelim();
    }
    if (message.startMonth !== undefined && message.startMonth !== undefined) {
      Int32Value.encode({ value: message.startMonth! }, writer.uint32(26).fork()).ldelim();
    }
    if (message.endYear !== undefined && message.endYear !== undefined) {
      Int32Value.encode({ value: message.endYear! }, writer.uint32(34).fork()).ldelim();
    }
    if (message.endMonth !== undefined && message.endMonth !== undefined) {
      Int32Value.encode({ value: message.endMonth! }, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): NewGraduateProfileToUpdate_StudyAbroadExperience {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseNewGraduateProfileToUpdate_StudyAbroadExperience) as NewGraduateProfileToUpdate_StudyAbroadExperience;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.countryName = reader.string();
          break;
        case 2:
          message.startYear = Int32Value.decode(reader, reader.uint32()).value;
          break;
        case 3:
          message.startMonth = Int32Value.decode(reader, reader.uint32()).value;
          break;
        case 4:
          message.endYear = Int32Value.decode(reader, reader.uint32()).value;
          break;
        case 5:
          message.endMonth = Int32Value.decode(reader, reader.uint32()).value;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): NewGraduateProfileToUpdate_StudyAbroadExperience {
    const message = Object.create(baseNewGraduateProfileToUpdate_StudyAbroadExperience) as NewGraduateProfileToUpdate_StudyAbroadExperience;
    if (object.countryName) {
      message.countryName = String(object.countryName);
    }
    if (object.startYear) {
      message.startYear = Number(object.startYear);
    }
    if (object.startMonth) {
      message.startMonth = Number(object.startMonth);
    }
    if (object.endYear) {
      message.endYear = Number(object.endYear);
    }
    if (object.endMonth) {
      message.endMonth = Number(object.endMonth);
    }
    return message;
  },
  fromPartial(object: DeepPartial<NewGraduateProfileToUpdate_StudyAbroadExperience>): NewGraduateProfileToUpdate_StudyAbroadExperience {
    const message = Object.create(baseNewGraduateProfileToUpdate_StudyAbroadExperience) as NewGraduateProfileToUpdate_StudyAbroadExperience;
    if (object.countryName) {
      message.countryName = object.countryName;
    }
    if (object.startYear) {
      message.startYear = object.startYear;
    }
    if (object.startMonth) {
      message.startMonth = object.startMonth;
    }
    if (object.endYear) {
      message.endYear = object.endYear;
    }
    if (object.endMonth) {
      message.endMonth = object.endMonth;
    }
    return message;
  },
  toJSON(message: NewGraduateProfileToUpdate_StudyAbroadExperience): unknown {
    const obj: any = {};
    obj.countryName = message.countryName || "";
    obj.startYear = message.startYear || undefined;
    obj.startMonth = message.startMonth || undefined;
    obj.endYear = message.endYear || undefined;
    obj.endMonth = message.endMonth || undefined;
    return obj;
  },
};

export const NewGraduateProfileToUpdate_ClubExperience = {
  encode(message: NewGraduateProfileToUpdate_ClubExperience, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.name);
    writer.uint32(18).string(message.content);
    return writer;
  },
  decode(reader: Reader, length?: number): NewGraduateProfileToUpdate_ClubExperience {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseNewGraduateProfileToUpdate_ClubExperience) as NewGraduateProfileToUpdate_ClubExperience;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.name = reader.string();
          break;
        case 2:
          message.content = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): NewGraduateProfileToUpdate_ClubExperience {
    const message = Object.create(baseNewGraduateProfileToUpdate_ClubExperience) as NewGraduateProfileToUpdate_ClubExperience;
    if (object.name) {
      message.name = String(object.name);
    }
    if (object.content) {
      message.content = String(object.content);
    }
    return message;
  },
  fromPartial(object: DeepPartial<NewGraduateProfileToUpdate_ClubExperience>): NewGraduateProfileToUpdate_ClubExperience {
    const message = Object.create(baseNewGraduateProfileToUpdate_ClubExperience) as NewGraduateProfileToUpdate_ClubExperience;
    if (object.name) {
      message.name = object.name;
    }
    if (object.content) {
      message.content = object.content;
    }
    return message;
  },
  toJSON(message: NewGraduateProfileToUpdate_ClubExperience): unknown {
    const obj: any = {};
    obj.name = message.name || "";
    obj.content = message.content || "";
    return obj;
  },
};

export const UpdateInnovatorResponse = {
  encode(message: UpdateInnovatorResponse, writer: Writer = Writer.create()): Writer {
    if (message.innovator !== undefined && message.innovator !== undefined) {
      Innovator.encode(message.innovator, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): UpdateInnovatorResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseUpdateInnovatorResponse) as UpdateInnovatorResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.innovator = Innovator.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): UpdateInnovatorResponse {
    const message = Object.create(baseUpdateInnovatorResponse) as UpdateInnovatorResponse;
    if (object.innovator) {
      message.innovator = Innovator.fromJSON(object.innovator);
    }
    return message;
  },
  fromPartial(object: DeepPartial<UpdateInnovatorResponse>): UpdateInnovatorResponse {
    const message = Object.create(baseUpdateInnovatorResponse) as UpdateInnovatorResponse;
    if (object.innovator) {
      message.innovator = Innovator.fromPartial(object.innovator);
    }
    return message;
  },
  toJSON(message: UpdateInnovatorResponse): unknown {
    const obj: any = {};
    obj.innovator = message.innovator ? Innovator.toJSON(message.innovator) : undefined;
    return obj;
  },
};

export const SendPublishRequestRequest = {
  encode(message: SendPublishRequestRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.innovatorId);
    return writer;
  },
  decode(reader: Reader, length?: number): SendPublishRequestRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseSendPublishRequestRequest) as SendPublishRequestRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.innovatorId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): SendPublishRequestRequest {
    const message = Object.create(baseSendPublishRequestRequest) as SendPublishRequestRequest;
    if (object.innovatorId) {
      message.innovatorId = String(object.innovatorId);
    }
    return message;
  },
  fromPartial(object: DeepPartial<SendPublishRequestRequest>): SendPublishRequestRequest {
    const message = Object.create(baseSendPublishRequestRequest) as SendPublishRequestRequest;
    if (object.innovatorId) {
      message.innovatorId = object.innovatorId;
    }
    return message;
  },
  toJSON(message: SendPublishRequestRequest): unknown {
    const obj: any = {};
    obj.innovatorId = message.innovatorId || "";
    return obj;
  },
};

export const SendPublishRequestResponse = {
  encode(message: SendPublishRequestResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): SendPublishRequestResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseSendPublishRequestResponse) as SendPublishRequestResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): SendPublishRequestResponse {
    const message = Object.create(baseSendPublishRequestResponse) as SendPublishRequestResponse;
    return message;
  },
  fromPartial(object: DeepPartial<SendPublishRequestResponse>): SendPublishRequestResponse {
    const message = Object.create(baseSendPublishRequestResponse) as SendPublishRequestResponse;
    return message;
  },
  toJSON(message: SendPublishRequestResponse): unknown {
    const obj: any = {};
    return obj;
  },
};

export const GetPublishSettingFormRequest = {
  encode(message: GetPublishSettingFormRequest, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): GetPublishSettingFormRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetPublishSettingFormRequest) as GetPublishSettingFormRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetPublishSettingFormRequest {
    const message = Object.create(baseGetPublishSettingFormRequest) as GetPublishSettingFormRequest;
    return message;
  },
  fromPartial(object: DeepPartial<GetPublishSettingFormRequest>): GetPublishSettingFormRequest {
    const message = Object.create(baseGetPublishSettingFormRequest) as GetPublishSettingFormRequest;
    return message;
  },
  toJSON(message: GetPublishSettingFormRequest): unknown {
    const obj: any = {};
    return obj;
  },
};

export const GetPublishSettingFormResponse = {
  encode(message: GetPublishSettingFormResponse, writer: Writer = Writer.create()): Writer {
    for (const v of message.categories) {
      MissionCategory.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.corporateAttributes) {
      CorporateAttribute.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.funds) {
      Fund.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.japanPrefectures) {
      JapanPrefecture.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.memberRanges) {
      MemberRange.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): GetPublishSettingFormResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetPublishSettingFormResponse) as GetPublishSettingFormResponse;
    message.categories = [];
    message.corporateAttributes = [];
    message.funds = [];
    message.japanPrefectures = [];
    message.memberRanges = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.categories.push(MissionCategory.decode(reader, reader.uint32()));
          break;
        case 2:
          message.corporateAttributes.push(CorporateAttribute.decode(reader, reader.uint32()));
          break;
        case 3:
          message.funds.push(Fund.decode(reader, reader.uint32()));
          break;
        case 4:
          message.japanPrefectures.push(JapanPrefecture.decode(reader, reader.uint32()));
          break;
        case 5:
          message.memberRanges.push(MemberRange.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetPublishSettingFormResponse {
    const message = Object.create(baseGetPublishSettingFormResponse) as GetPublishSettingFormResponse;
    message.categories = [];
    message.corporateAttributes = [];
    message.funds = [];
    message.japanPrefectures = [];
    message.memberRanges = [];
    if (object.categories) {
      for (const e of object.categories) {
        message.categories.push(MissionCategory.fromJSON(e));
      }
    }
    if (object.corporateAttributes) {
      for (const e of object.corporateAttributes) {
        message.corporateAttributes.push(CorporateAttribute.fromJSON(e));
      }
    }
    if (object.funds) {
      for (const e of object.funds) {
        message.funds.push(Fund.fromJSON(e));
      }
    }
    if (object.japanPrefectures) {
      for (const e of object.japanPrefectures) {
        message.japanPrefectures.push(JapanPrefecture.fromJSON(e));
      }
    }
    if (object.memberRanges) {
      for (const e of object.memberRanges) {
        message.memberRanges.push(MemberRange.fromJSON(e));
      }
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetPublishSettingFormResponse>): GetPublishSettingFormResponse {
    const message = Object.create(baseGetPublishSettingFormResponse) as GetPublishSettingFormResponse;
    message.categories = [];
    message.corporateAttributes = [];
    message.funds = [];
    message.japanPrefectures = [];
    message.memberRanges = [];
    if (object.categories) {
      for (const e of object.categories) {
        message.categories.push(MissionCategory.fromPartial(e));
      }
    }
    if (object.corporateAttributes) {
      for (const e of object.corporateAttributes) {
        message.corporateAttributes.push(CorporateAttribute.fromPartial(e));
      }
    }
    if (object.funds) {
      for (const e of object.funds) {
        message.funds.push(Fund.fromPartial(e));
      }
    }
    if (object.japanPrefectures) {
      for (const e of object.japanPrefectures) {
        message.japanPrefectures.push(JapanPrefecture.fromPartial(e));
      }
    }
    if (object.memberRanges) {
      for (const e of object.memberRanges) {
        message.memberRanges.push(MemberRange.fromPartial(e));
      }
    }
    return message;
  },
  toJSON(message: GetPublishSettingFormResponse): unknown {
    const obj: any = {};
    if (message.categories) {
      obj.categories = message.categories.map(e => e ? MissionCategory.toJSON(e) : undefined);
    } else {
      obj.categories = [];
    }
    if (message.corporateAttributes) {
      obj.corporateAttributes = message.corporateAttributes.map(e => e ? CorporateAttribute.toJSON(e) : undefined);
    } else {
      obj.corporateAttributes = [];
    }
    if (message.funds) {
      obj.funds = message.funds.map(e => e ? Fund.toJSON(e) : undefined);
    } else {
      obj.funds = [];
    }
    if (message.japanPrefectures) {
      obj.japanPrefectures = message.japanPrefectures.map(e => e ? JapanPrefecture.toJSON(e) : undefined);
    } else {
      obj.japanPrefectures = [];
    }
    if (message.memberRanges) {
      obj.memberRanges = message.memberRanges.map(e => e ? MemberRange.toJSON(e) : undefined);
    } else {
      obj.memberRanges = [];
    }
    return obj;
  },
};

export const GetPublishSettingRequest = {
  encode(message: GetPublishSettingRequest, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): GetPublishSettingRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetPublishSettingRequest) as GetPublishSettingRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetPublishSettingRequest {
    const message = Object.create(baseGetPublishSettingRequest) as GetPublishSettingRequest;
    return message;
  },
  fromPartial(object: DeepPartial<GetPublishSettingRequest>): GetPublishSettingRequest {
    const message = Object.create(baseGetPublishSettingRequest) as GetPublishSettingRequest;
    return message;
  },
  toJSON(message: GetPublishSettingRequest): unknown {
    const obj: any = {};
    return obj;
  },
};

export const GetPublishSettingResponse = {
  encode(message: GetPublishSettingResponse, writer: Writer = Writer.create()): Writer {
    for (const v of message.categories) {
      MissionCategory.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.corporateAttributes) {
      CorporateAttribute.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.funds) {
      Fund.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.locations) {
      Location.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.memberRanges) {
      MemberRange.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    for (const v of message.blockCompanies) {
      BlockCompany.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    writer.uint32(56).bool(message.publish);
    return writer;
  },
  decode(reader: Reader, length?: number): GetPublishSettingResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetPublishSettingResponse) as GetPublishSettingResponse;
    message.categories = [];
    message.corporateAttributes = [];
    message.funds = [];
    message.locations = [];
    message.memberRanges = [];
    message.blockCompanies = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.categories.push(MissionCategory.decode(reader, reader.uint32()));
          break;
        case 2:
          message.corporateAttributes.push(CorporateAttribute.decode(reader, reader.uint32()));
          break;
        case 3:
          message.funds.push(Fund.decode(reader, reader.uint32()));
          break;
        case 4:
          message.locations.push(Location.decode(reader, reader.uint32()));
          break;
        case 5:
          message.memberRanges.push(MemberRange.decode(reader, reader.uint32()));
          break;
        case 6:
          message.blockCompanies.push(BlockCompany.decode(reader, reader.uint32()));
          break;
        case 7:
          message.publish = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetPublishSettingResponse {
    const message = Object.create(baseGetPublishSettingResponse) as GetPublishSettingResponse;
    message.categories = [];
    message.corporateAttributes = [];
    message.funds = [];
    message.locations = [];
    message.memberRanges = [];
    message.blockCompanies = [];
    if (object.categories) {
      for (const e of object.categories) {
        message.categories.push(MissionCategory.fromJSON(e));
      }
    }
    if (object.corporateAttributes) {
      for (const e of object.corporateAttributes) {
        message.corporateAttributes.push(CorporateAttribute.fromJSON(e));
      }
    }
    if (object.funds) {
      for (const e of object.funds) {
        message.funds.push(Fund.fromJSON(e));
      }
    }
    if (object.locations) {
      for (const e of object.locations) {
        message.locations.push(Location.fromJSON(e));
      }
    }
    if (object.memberRanges) {
      for (const e of object.memberRanges) {
        message.memberRanges.push(MemberRange.fromJSON(e));
      }
    }
    if (object.blockCompanies) {
      for (const e of object.blockCompanies) {
        message.blockCompanies.push(BlockCompany.fromJSON(e));
      }
    }
    if (object.publish) {
      message.publish = Boolean(object.publish);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetPublishSettingResponse>): GetPublishSettingResponse {
    const message = Object.create(baseGetPublishSettingResponse) as GetPublishSettingResponse;
    message.categories = [];
    message.corporateAttributes = [];
    message.funds = [];
    message.locations = [];
    message.memberRanges = [];
    message.blockCompanies = [];
    if (object.categories) {
      for (const e of object.categories) {
        message.categories.push(MissionCategory.fromPartial(e));
      }
    }
    if (object.corporateAttributes) {
      for (const e of object.corporateAttributes) {
        message.corporateAttributes.push(CorporateAttribute.fromPartial(e));
      }
    }
    if (object.funds) {
      for (const e of object.funds) {
        message.funds.push(Fund.fromPartial(e));
      }
    }
    if (object.locations) {
      for (const e of object.locations) {
        message.locations.push(Location.fromPartial(e));
      }
    }
    if (object.memberRanges) {
      for (const e of object.memberRanges) {
        message.memberRanges.push(MemberRange.fromPartial(e));
      }
    }
    if (object.blockCompanies) {
      for (const e of object.blockCompanies) {
        message.blockCompanies.push(BlockCompany.fromPartial(e));
      }
    }
    if (object.publish) {
      message.publish = object.publish;
    }
    return message;
  },
  toJSON(message: GetPublishSettingResponse): unknown {
    const obj: any = {};
    if (message.categories) {
      obj.categories = message.categories.map(e => e ? MissionCategory.toJSON(e) : undefined);
    } else {
      obj.categories = [];
    }
    if (message.corporateAttributes) {
      obj.corporateAttributes = message.corporateAttributes.map(e => e ? CorporateAttribute.toJSON(e) : undefined);
    } else {
      obj.corporateAttributes = [];
    }
    if (message.funds) {
      obj.funds = message.funds.map(e => e ? Fund.toJSON(e) : undefined);
    } else {
      obj.funds = [];
    }
    if (message.locations) {
      obj.locations = message.locations.map(e => e ? Location.toJSON(e) : undefined);
    } else {
      obj.locations = [];
    }
    if (message.memberRanges) {
      obj.memberRanges = message.memberRanges.map(e => e ? MemberRange.toJSON(e) : undefined);
    } else {
      obj.memberRanges = [];
    }
    if (message.blockCompanies) {
      obj.blockCompanies = message.blockCompanies.map(e => e ? BlockCompany.toJSON(e) : undefined);
    } else {
      obj.blockCompanies = [];
    }
    obj.publish = message.publish || false;
    return obj;
  },
};

export const UpdatePublishSettingRequest = {
  encode(message: UpdatePublishSettingRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).fork();
    for (const v of message.categories) {
      writer.uint32(v);
    }
    writer.ldelim();
    writer.uint32(18).fork();
    for (const v of message.corporateAttributes) {
      writer.uint32(v);
    }
    writer.ldelim();
    writer.uint32(26).fork();
    for (const v of message.funds) {
      writer.uint32(v);
    }
    writer.ldelim();
    for (const v of message.locations) {
      UpdatePublishSettingRequest_Location.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    writer.uint32(42).fork();
    for (const v of message.memberRanges) {
      writer.uint32(v);
    }
    writer.ldelim();
    for (const v of message.blockCompanies) {
      writer.uint32(50).string(v!);
    }
    writer.uint32(56).bool(message.publish);
    writer.uint32(66).string(message.innovatorId);
    return writer;
  },
  decode(reader: Reader, length?: number): UpdatePublishSettingRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseUpdatePublishSettingRequest) as UpdatePublishSettingRequest;
    message.categories = [];
    message.corporateAttributes = [];
    message.funds = [];
    message.locations = [];
    message.memberRanges = [];
    message.blockCompanies = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.categories.push(reader.uint32());
            }
          } else {
            message.categories.push(reader.uint32());
          }
          break;
        case 2:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.corporateAttributes.push(reader.uint32());
            }
          } else {
            message.corporateAttributes.push(reader.uint32());
          }
          break;
        case 3:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.funds.push(reader.uint32());
            }
          } else {
            message.funds.push(reader.uint32());
          }
          break;
        case 4:
          message.locations.push(UpdatePublishSettingRequest_Location.decode(reader, reader.uint32()));
          break;
        case 5:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.memberRanges.push(reader.uint32());
            }
          } else {
            message.memberRanges.push(reader.uint32());
          }
          break;
        case 6:
          message.blockCompanies.push(reader.string());
          break;
        case 7:
          message.publish = reader.bool();
          break;
        case 8:
          message.innovatorId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): UpdatePublishSettingRequest {
    const message = Object.create(baseUpdatePublishSettingRequest) as UpdatePublishSettingRequest;
    message.categories = [];
    message.corporateAttributes = [];
    message.funds = [];
    message.locations = [];
    message.memberRanges = [];
    message.blockCompanies = [];
    if (object.categories) {
      for (const e of object.categories) {
        message.categories.push(Number(e));
      }
    }
    if (object.corporateAttributes) {
      for (const e of object.corporateAttributes) {
        message.corporateAttributes.push(Number(e));
      }
    }
    if (object.funds) {
      for (const e of object.funds) {
        message.funds.push(Number(e));
      }
    }
    if (object.locations) {
      for (const e of object.locations) {
        message.locations.push(UpdatePublishSettingRequest_Location.fromJSON(e));
      }
    }
    if (object.memberRanges) {
      for (const e of object.memberRanges) {
        message.memberRanges.push(Number(e));
      }
    }
    if (object.blockCompanies) {
      for (const e of object.blockCompanies) {
        message.blockCompanies.push(String(e));
      }
    }
    if (object.publish) {
      message.publish = Boolean(object.publish);
    }
    if (object.innovatorId) {
      message.innovatorId = String(object.innovatorId);
    }
    return message;
  },
  fromPartial(object: DeepPartial<UpdatePublishSettingRequest>): UpdatePublishSettingRequest {
    const message = Object.create(baseUpdatePublishSettingRequest) as UpdatePublishSettingRequest;
    message.categories = [];
    message.corporateAttributes = [];
    message.funds = [];
    message.locations = [];
    message.memberRanges = [];
    message.blockCompanies = [];
    if (object.categories) {
      for (const e of object.categories) {
        message.categories.push(e);
      }
    }
    if (object.corporateAttributes) {
      for (const e of object.corporateAttributes) {
        message.corporateAttributes.push(e);
      }
    }
    if (object.funds) {
      for (const e of object.funds) {
        message.funds.push(e);
      }
    }
    if (object.locations) {
      for (const e of object.locations) {
        message.locations.push(UpdatePublishSettingRequest_Location.fromPartial(e));
      }
    }
    if (object.memberRanges) {
      for (const e of object.memberRanges) {
        message.memberRanges.push(e);
      }
    }
    if (object.blockCompanies) {
      for (const e of object.blockCompanies) {
        message.blockCompanies.push(e);
      }
    }
    if (object.publish) {
      message.publish = object.publish;
    }
    if (object.innovatorId) {
      message.innovatorId = object.innovatorId;
    }
    return message;
  },
  toJSON(message: UpdatePublishSettingRequest): unknown {
    const obj: any = {};
    if (message.categories) {
      obj.categories = message.categories.map(e => e || 0);
    } else {
      obj.categories = [];
    }
    if (message.corporateAttributes) {
      obj.corporateAttributes = message.corporateAttributes.map(e => e || 0);
    } else {
      obj.corporateAttributes = [];
    }
    if (message.funds) {
      obj.funds = message.funds.map(e => e || 0);
    } else {
      obj.funds = [];
    }
    if (message.locations) {
      obj.locations = message.locations.map(e => e ? UpdatePublishSettingRequest_Location.toJSON(e) : undefined);
    } else {
      obj.locations = [];
    }
    if (message.memberRanges) {
      obj.memberRanges = message.memberRanges.map(e => e || 0);
    } else {
      obj.memberRanges = [];
    }
    if (message.blockCompanies) {
      obj.blockCompanies = message.blockCompanies.map(e => e || "");
    } else {
      obj.blockCompanies = [];
    }
    obj.publish = message.publish || false;
    obj.innovatorId = message.innovatorId || "";
    return obj;
  },
};

export const UpdatePublishSettingRequest_Location = {
  encode(message: UpdatePublishSettingRequest_Location, writer: Writer = Writer.create()): Writer {
    if (message.japanPrefectureId !== undefined && message.japanPrefectureId !== undefined) {
      Int32Value.encode({ value: message.japanPrefectureId! }, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): UpdatePublishSettingRequest_Location {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseUpdatePublishSettingRequest_Location) as UpdatePublishSettingRequest_Location;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.japanPrefectureId = Int32Value.decode(reader, reader.uint32()).value;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): UpdatePublishSettingRequest_Location {
    const message = Object.create(baseUpdatePublishSettingRequest_Location) as UpdatePublishSettingRequest_Location;
    if (object.japanPrefectureId) {
      message.japanPrefectureId = Number(object.japanPrefectureId);
    }
    return message;
  },
  fromPartial(object: DeepPartial<UpdatePublishSettingRequest_Location>): UpdatePublishSettingRequest_Location {
    const message = Object.create(baseUpdatePublishSettingRequest_Location) as UpdatePublishSettingRequest_Location;
    if (object.japanPrefectureId) {
      message.japanPrefectureId = object.japanPrefectureId;
    }
    return message;
  },
  toJSON(message: UpdatePublishSettingRequest_Location): unknown {
    const obj: any = {};
    obj.japanPrefectureId = message.japanPrefectureId || undefined;
    return obj;
  },
};

export const UpdatePublishSettingResponse = {
  encode(message: UpdatePublishSettingResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): UpdatePublishSettingResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseUpdatePublishSettingResponse) as UpdatePublishSettingResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): UpdatePublishSettingResponse {
    const message = Object.create(baseUpdatePublishSettingResponse) as UpdatePublishSettingResponse;
    return message;
  },
  fromPartial(object: DeepPartial<UpdatePublishSettingResponse>): UpdatePublishSettingResponse {
    const message = Object.create(baseUpdatePublishSettingResponse) as UpdatePublishSettingResponse;
    return message;
  },
  toJSON(message: UpdatePublishSettingResponse): unknown {
    const obj: any = {};
    return obj;
  },
};

export const GetMatterStatusRequest = {
  encode(message: GetMatterStatusRequest, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): GetMatterStatusRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetMatterStatusRequest) as GetMatterStatusRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetMatterStatusRequest {
    const message = Object.create(baseGetMatterStatusRequest) as GetMatterStatusRequest;
    return message;
  },
  fromPartial(object: DeepPartial<GetMatterStatusRequest>): GetMatterStatusRequest {
    const message = Object.create(baseGetMatterStatusRequest) as GetMatterStatusRequest;
    return message;
  },
  toJSON(message: GetMatterStatusRequest): unknown {
    const obj: any = {};
    return obj;
  },
};

export const GetMatterStatusResponse = {
  encode(message: GetMatterStatusResponse, writer: Writer = Writer.create()): Writer {
    if (message.matterId !== undefined && message.matterId !== undefined) {
      StringValue.encode({ value: message.matterId! }, writer.uint32(10).fork()).ldelim();
    }
    if (message.statusId !== undefined && message.statusId !== undefined) {
      Int32Value.encode({ value: message.statusId! }, writer.uint32(18).fork()).ldelim();
    }
    writer.uint32(24).bool(message.registered);
    return writer;
  },
  decode(reader: Reader, length?: number): GetMatterStatusResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetMatterStatusResponse) as GetMatterStatusResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.matterId = StringValue.decode(reader, reader.uint32()).value;
          break;
        case 2:
          message.statusId = Int32Value.decode(reader, reader.uint32()).value;
          break;
        case 3:
          message.registered = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetMatterStatusResponse {
    const message = Object.create(baseGetMatterStatusResponse) as GetMatterStatusResponse;
    if (object.matterId) {
      message.matterId = String(object.matterId);
    }
    if (object.statusId) {
      message.statusId = Number(object.statusId);
    }
    if (object.registered) {
      message.registered = Boolean(object.registered);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetMatterStatusResponse>): GetMatterStatusResponse {
    const message = Object.create(baseGetMatterStatusResponse) as GetMatterStatusResponse;
    if (object.matterId) {
      message.matterId = object.matterId;
    }
    if (object.statusId) {
      message.statusId = object.statusId;
    }
    if (object.registered) {
      message.registered = object.registered;
    }
    return message;
  },
  toJSON(message: GetMatterStatusResponse): unknown {
    const obj: any = {};
    obj.matterId = message.matterId || undefined;
    obj.statusId = message.statusId || undefined;
    obj.registered = message.registered || false;
    return obj;
  },
};

export const CreateInnovatorChatRoomRequest = {
  encode(message: CreateInnovatorChatRoomRequest, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): CreateInnovatorChatRoomRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseCreateInnovatorChatRoomRequest) as CreateInnovatorChatRoomRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): CreateInnovatorChatRoomRequest {
    const message = Object.create(baseCreateInnovatorChatRoomRequest) as CreateInnovatorChatRoomRequest;
    return message;
  },
  fromPartial(object: DeepPartial<CreateInnovatorChatRoomRequest>): CreateInnovatorChatRoomRequest {
    const message = Object.create(baseCreateInnovatorChatRoomRequest) as CreateInnovatorChatRoomRequest;
    return message;
  },
  toJSON(message: CreateInnovatorChatRoomRequest): unknown {
    const obj: any = {};
    return obj;
  },
};

export const CreateInnovatorChatRoomResponse = {
  encode(message: CreateInnovatorChatRoomResponse, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.chatRoomId);
    return writer;
  },
  decode(reader: Reader, length?: number): CreateInnovatorChatRoomResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseCreateInnovatorChatRoomResponse) as CreateInnovatorChatRoomResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.chatRoomId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): CreateInnovatorChatRoomResponse {
    const message = Object.create(baseCreateInnovatorChatRoomResponse) as CreateInnovatorChatRoomResponse;
    if (object.chatRoomId) {
      message.chatRoomId = String(object.chatRoomId);
    }
    return message;
  },
  fromPartial(object: DeepPartial<CreateInnovatorChatRoomResponse>): CreateInnovatorChatRoomResponse {
    const message = Object.create(baseCreateInnovatorChatRoomResponse) as CreateInnovatorChatRoomResponse;
    if (object.chatRoomId) {
      message.chatRoomId = object.chatRoomId;
    }
    return message;
  },
  toJSON(message: CreateInnovatorChatRoomResponse): unknown {
    const obj: any = {};
    obj.chatRoomId = message.chatRoomId || "";
    return obj;
  },
};

export const GetInnovatorChatMessagesRequest = {
  encode(message: GetInnovatorChatMessagesRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.chatRoomId);
    writer.uint32(16).int64(message.limit);
    if (message.messageIdBefore !== undefined && message.messageIdBefore !== undefined) {
      Int32Value.encode({ value: message.messageIdBefore! }, writer.uint32(26).fork()).ldelim();
    }
    if (message.messageIdAfter !== undefined && message.messageIdAfter !== undefined) {
      Int32Value.encode({ value: message.messageIdAfter! }, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): GetInnovatorChatMessagesRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetInnovatorChatMessagesRequest) as GetInnovatorChatMessagesRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.chatRoomId = reader.string();
          break;
        case 2:
          message.limit = longToNumber(reader.int64() as Long);
          break;
        case 3:
          message.messageIdBefore = Int32Value.decode(reader, reader.uint32()).value;
          break;
        case 4:
          message.messageIdAfter = Int32Value.decode(reader, reader.uint32()).value;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetInnovatorChatMessagesRequest {
    const message = Object.create(baseGetInnovatorChatMessagesRequest) as GetInnovatorChatMessagesRequest;
    if (object.chatRoomId) {
      message.chatRoomId = String(object.chatRoomId);
    }
    if (object.limit) {
      message.limit = Number(object.limit);
    }
    if (object.messageIdBefore) {
      message.messageIdBefore = Number(object.messageIdBefore);
    }
    if (object.messageIdAfter) {
      message.messageIdAfter = Number(object.messageIdAfter);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetInnovatorChatMessagesRequest>): GetInnovatorChatMessagesRequest {
    const message = Object.create(baseGetInnovatorChatMessagesRequest) as GetInnovatorChatMessagesRequest;
    if (object.chatRoomId) {
      message.chatRoomId = object.chatRoomId;
    }
    if (object.limit) {
      message.limit = object.limit;
    }
    if (object.messageIdBefore) {
      message.messageIdBefore = object.messageIdBefore;
    }
    if (object.messageIdAfter) {
      message.messageIdAfter = object.messageIdAfter;
    }
    return message;
  },
  toJSON(message: GetInnovatorChatMessagesRequest): unknown {
    const obj: any = {};
    obj.chatRoomId = message.chatRoomId || "";
    obj.limit = message.limit || 0;
    obj.messageIdBefore = message.messageIdBefore || undefined;
    obj.messageIdAfter = message.messageIdAfter || undefined;
    return obj;
  },
};

export const GetInnovatorChatMessagesResponse = {
  encode(message: GetInnovatorChatMessagesResponse, writer: Writer = Writer.create()): Writer {
    for (const v of message.messages) {
      InnovatorChatMessage.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    writer.uint32(18).string(message.prevQuery);
    writer.uint32(26).string(message.nextQuery);
    return writer;
  },
  decode(reader: Reader, length?: number): GetInnovatorChatMessagesResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetInnovatorChatMessagesResponse) as GetInnovatorChatMessagesResponse;
    message.messages = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.messages.push(InnovatorChatMessage.decode(reader, reader.uint32()));
          break;
        case 2:
          message.prevQuery = reader.string();
          break;
        case 3:
          message.nextQuery = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetInnovatorChatMessagesResponse {
    const message = Object.create(baseGetInnovatorChatMessagesResponse) as GetInnovatorChatMessagesResponse;
    message.messages = [];
    if (object.messages) {
      for (const e of object.messages) {
        message.messages.push(InnovatorChatMessage.fromJSON(e));
      }
    }
    if (object.prevQuery) {
      message.prevQuery = String(object.prevQuery);
    }
    if (object.nextQuery) {
      message.nextQuery = String(object.nextQuery);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetInnovatorChatMessagesResponse>): GetInnovatorChatMessagesResponse {
    const message = Object.create(baseGetInnovatorChatMessagesResponse) as GetInnovatorChatMessagesResponse;
    message.messages = [];
    if (object.messages) {
      for (const e of object.messages) {
        message.messages.push(InnovatorChatMessage.fromPartial(e));
      }
    }
    if (object.prevQuery) {
      message.prevQuery = object.prevQuery;
    }
    if (object.nextQuery) {
      message.nextQuery = object.nextQuery;
    }
    return message;
  },
  toJSON(message: GetInnovatorChatMessagesResponse): unknown {
    const obj: any = {};
    if (message.messages) {
      obj.messages = message.messages.map(e => e ? InnovatorChatMessage.toJSON(e) : undefined);
    } else {
      obj.messages = [];
    }
    obj.prevQuery = message.prevQuery || "";
    obj.nextQuery = message.nextQuery || "";
    return obj;
  },
};

export const PostInnovatorChatMessageRequest = {
  encode(message: PostInnovatorChatMessageRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.chatRoomId);
    writer.uint32(18).string(message.message);
    return writer;
  },
  decode(reader: Reader, length?: number): PostInnovatorChatMessageRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(basePostInnovatorChatMessageRequest) as PostInnovatorChatMessageRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.chatRoomId = reader.string();
          break;
        case 2:
          message.message = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): PostInnovatorChatMessageRequest {
    const message = Object.create(basePostInnovatorChatMessageRequest) as PostInnovatorChatMessageRequest;
    if (object.chatRoomId) {
      message.chatRoomId = String(object.chatRoomId);
    }
    if (object.message) {
      message.message = String(object.message);
    }
    return message;
  },
  fromPartial(object: DeepPartial<PostInnovatorChatMessageRequest>): PostInnovatorChatMessageRequest {
    const message = Object.create(basePostInnovatorChatMessageRequest) as PostInnovatorChatMessageRequest;
    if (object.chatRoomId) {
      message.chatRoomId = object.chatRoomId;
    }
    if (object.message) {
      message.message = object.message;
    }
    return message;
  },
  toJSON(message: PostInnovatorChatMessageRequest): unknown {
    const obj: any = {};
    obj.chatRoomId = message.chatRoomId || "";
    obj.message = message.message || "";
    return obj;
  },
};

export const PostInnovatorChatMessageResponse = {
  encode(message: PostInnovatorChatMessageResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): PostInnovatorChatMessageResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(basePostInnovatorChatMessageResponse) as PostInnovatorChatMessageResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): PostInnovatorChatMessageResponse {
    const message = Object.create(basePostInnovatorChatMessageResponse) as PostInnovatorChatMessageResponse;
    return message;
  },
  fromPartial(object: DeepPartial<PostInnovatorChatMessageResponse>): PostInnovatorChatMessageResponse {
    const message = Object.create(basePostInnovatorChatMessageResponse) as PostInnovatorChatMessageResponse;
    return message;
  },
  toJSON(message: PostInnovatorChatMessageResponse): unknown {
    const obj: any = {};
    return obj;
  },
};

export const PostInnovatorChatFileRequest = {
  encode(message: PostInnovatorChatFileRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.chatRoomId);
    writer.uint32(18).string(message.data);
    writer.uint32(26).string(message.fileName);
    return writer;
  },
  decode(reader: Reader, length?: number): PostInnovatorChatFileRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(basePostInnovatorChatFileRequest) as PostInnovatorChatFileRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.chatRoomId = reader.string();
          break;
        case 2:
          message.data = reader.string();
          break;
        case 3:
          message.fileName = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): PostInnovatorChatFileRequest {
    const message = Object.create(basePostInnovatorChatFileRequest) as PostInnovatorChatFileRequest;
    if (object.chatRoomId) {
      message.chatRoomId = String(object.chatRoomId);
    }
    if (object.data) {
      message.data = String(object.data);
    }
    if (object.fileName) {
      message.fileName = String(object.fileName);
    }
    return message;
  },
  fromPartial(object: DeepPartial<PostInnovatorChatFileRequest>): PostInnovatorChatFileRequest {
    const message = Object.create(basePostInnovatorChatFileRequest) as PostInnovatorChatFileRequest;
    if (object.chatRoomId) {
      message.chatRoomId = object.chatRoomId;
    }
    if (object.data) {
      message.data = object.data;
    }
    if (object.fileName) {
      message.fileName = object.fileName;
    }
    return message;
  },
  toJSON(message: PostInnovatorChatFileRequest): unknown {
    const obj: any = {};
    obj.chatRoomId = message.chatRoomId || "";
    obj.data = message.data || "";
    obj.fileName = message.fileName || "";
    return obj;
  },
};

export const PostInnovatorChatFileResponse = {
  encode(message: PostInnovatorChatFileResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): PostInnovatorChatFileResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(basePostInnovatorChatFileResponse) as PostInnovatorChatFileResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): PostInnovatorChatFileResponse {
    const message = Object.create(basePostInnovatorChatFileResponse) as PostInnovatorChatFileResponse;
    return message;
  },
  fromPartial(object: DeepPartial<PostInnovatorChatFileResponse>): PostInnovatorChatFileResponse {
    const message = Object.create(basePostInnovatorChatFileResponse) as PostInnovatorChatFileResponse;
    return message;
  },
  toJSON(message: PostInnovatorChatFileResponse): unknown {
    const obj: any = {};
    return obj;
  },
};

export const DeleteInnovatorChatMessageRequest = {
  encode(message: DeleteInnovatorChatMessageRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.chatRoomId);
    writer.uint32(16).int64(message.messageId);
    return writer;
  },
  decode(reader: Reader, length?: number): DeleteInnovatorChatMessageRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseDeleteInnovatorChatMessageRequest) as DeleteInnovatorChatMessageRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.chatRoomId = reader.string();
          break;
        case 2:
          message.messageId = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): DeleteInnovatorChatMessageRequest {
    const message = Object.create(baseDeleteInnovatorChatMessageRequest) as DeleteInnovatorChatMessageRequest;
    if (object.chatRoomId) {
      message.chatRoomId = String(object.chatRoomId);
    }
    if (object.messageId) {
      message.messageId = Number(object.messageId);
    }
    return message;
  },
  fromPartial(object: DeepPartial<DeleteInnovatorChatMessageRequest>): DeleteInnovatorChatMessageRequest {
    const message = Object.create(baseDeleteInnovatorChatMessageRequest) as DeleteInnovatorChatMessageRequest;
    if (object.chatRoomId) {
      message.chatRoomId = object.chatRoomId;
    }
    if (object.messageId) {
      message.messageId = object.messageId;
    }
    return message;
  },
  toJSON(message: DeleteInnovatorChatMessageRequest): unknown {
    const obj: any = {};
    obj.chatRoomId = message.chatRoomId || "";
    obj.messageId = message.messageId || 0;
    return obj;
  },
};

export const DeleteInnovatorChatMessageResponse = {
  encode(message: DeleteInnovatorChatMessageResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): DeleteInnovatorChatMessageResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseDeleteInnovatorChatMessageResponse) as DeleteInnovatorChatMessageResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): DeleteInnovatorChatMessageResponse {
    const message = Object.create(baseDeleteInnovatorChatMessageResponse) as DeleteInnovatorChatMessageResponse;
    return message;
  },
  fromPartial(object: DeepPartial<DeleteInnovatorChatMessageResponse>): DeleteInnovatorChatMessageResponse {
    const message = Object.create(baseDeleteInnovatorChatMessageResponse) as DeleteInnovatorChatMessageResponse;
    return message;
  },
  toJSON(message: DeleteInnovatorChatMessageResponse): unknown {
    const obj: any = {};
    return obj;
  },
};

export const FlushInnovatorChatUnreadCountRequest = {
  encode(message: FlushInnovatorChatUnreadCountRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.chatRoomId);
    return writer;
  },
  decode(reader: Reader, length?: number): FlushInnovatorChatUnreadCountRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseFlushInnovatorChatUnreadCountRequest) as FlushInnovatorChatUnreadCountRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.chatRoomId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): FlushInnovatorChatUnreadCountRequest {
    const message = Object.create(baseFlushInnovatorChatUnreadCountRequest) as FlushInnovatorChatUnreadCountRequest;
    if (object.chatRoomId) {
      message.chatRoomId = String(object.chatRoomId);
    }
    return message;
  },
  fromPartial(object: DeepPartial<FlushInnovatorChatUnreadCountRequest>): FlushInnovatorChatUnreadCountRequest {
    const message = Object.create(baseFlushInnovatorChatUnreadCountRequest) as FlushInnovatorChatUnreadCountRequest;
    if (object.chatRoomId) {
      message.chatRoomId = object.chatRoomId;
    }
    return message;
  },
  toJSON(message: FlushInnovatorChatUnreadCountRequest): unknown {
    const obj: any = {};
    obj.chatRoomId = message.chatRoomId || "";
    return obj;
  },
};

export const FlushInnovatorChatUnreadCountResponse = {
  encode(message: FlushInnovatorChatUnreadCountResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): FlushInnovatorChatUnreadCountResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseFlushInnovatorChatUnreadCountResponse) as FlushInnovatorChatUnreadCountResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): FlushInnovatorChatUnreadCountResponse {
    const message = Object.create(baseFlushInnovatorChatUnreadCountResponse) as FlushInnovatorChatUnreadCountResponse;
    return message;
  },
  fromPartial(object: DeepPartial<FlushInnovatorChatUnreadCountResponse>): FlushInnovatorChatUnreadCountResponse {
    const message = Object.create(baseFlushInnovatorChatUnreadCountResponse) as FlushInnovatorChatUnreadCountResponse;
    return message;
  },
  toJSON(message: FlushInnovatorChatUnreadCountResponse): unknown {
    const obj: any = {};
    return obj;
  },
};

export const GetInnovatorAccountSettingsRequest = {
  encode(message: GetInnovatorAccountSettingsRequest, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): GetInnovatorAccountSettingsRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetInnovatorAccountSettingsRequest) as GetInnovatorAccountSettingsRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetInnovatorAccountSettingsRequest {
    const message = Object.create(baseGetInnovatorAccountSettingsRequest) as GetInnovatorAccountSettingsRequest;
    return message;
  },
  fromPartial(object: DeepPartial<GetInnovatorAccountSettingsRequest>): GetInnovatorAccountSettingsRequest {
    const message = Object.create(baseGetInnovatorAccountSettingsRequest) as GetInnovatorAccountSettingsRequest;
    return message;
  },
  toJSON(message: GetInnovatorAccountSettingsRequest): unknown {
    const obj: any = {};
    return obj;
  },
};

export const GetInnovatorAccountSettingsResponse = {
  encode(message: GetInnovatorAccountSettingsResponse, writer: Writer = Writer.create()): Writer {
    writer.uint32(16).bool(message.chatEmailNotificationDisabled);
    writer.uint32(24).bool(message.chatPushNotificationDisabled);
    return writer;
  },
  decode(reader: Reader, length?: number): GetInnovatorAccountSettingsResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetInnovatorAccountSettingsResponse) as GetInnovatorAccountSettingsResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          message.chatEmailNotificationDisabled = reader.bool();
          break;
        case 3:
          message.chatPushNotificationDisabled = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetInnovatorAccountSettingsResponse {
    const message = Object.create(baseGetInnovatorAccountSettingsResponse) as GetInnovatorAccountSettingsResponse;
    if (object.chatEmailNotificationDisabled) {
      message.chatEmailNotificationDisabled = Boolean(object.chatEmailNotificationDisabled);
    }
    if (object.chatPushNotificationDisabled) {
      message.chatPushNotificationDisabled = Boolean(object.chatPushNotificationDisabled);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetInnovatorAccountSettingsResponse>): GetInnovatorAccountSettingsResponse {
    const message = Object.create(baseGetInnovatorAccountSettingsResponse) as GetInnovatorAccountSettingsResponse;
    if (object.chatEmailNotificationDisabled) {
      message.chatEmailNotificationDisabled = object.chatEmailNotificationDisabled;
    }
    if (object.chatPushNotificationDisabled) {
      message.chatPushNotificationDisabled = object.chatPushNotificationDisabled;
    }
    return message;
  },
  toJSON(message: GetInnovatorAccountSettingsResponse): unknown {
    const obj: any = {};
    obj.chatEmailNotificationDisabled = message.chatEmailNotificationDisabled || false;
    obj.chatPushNotificationDisabled = message.chatPushNotificationDisabled || false;
    return obj;
  },
};

export const ChangeInnovatorAccountSettingsRequest = {
  encode(message: ChangeInnovatorAccountSettingsRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(18).string(message.innovatorId);
    if (message.chatEmailNotificationDisabled !== undefined && message.chatEmailNotificationDisabled !== undefined) {
      BoolValue.encode({ value: message.chatEmailNotificationDisabled! }, writer.uint32(26).fork()).ldelim();
    }
    if (message.chatPushNotificationDisabled !== undefined && message.chatPushNotificationDisabled !== undefined) {
      BoolValue.encode({ value: message.chatPushNotificationDisabled! }, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): ChangeInnovatorAccountSettingsRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseChangeInnovatorAccountSettingsRequest) as ChangeInnovatorAccountSettingsRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          message.innovatorId = reader.string();
          break;
        case 3:
          message.chatEmailNotificationDisabled = BoolValue.decode(reader, reader.uint32()).value;
          break;
        case 4:
          message.chatPushNotificationDisabled = BoolValue.decode(reader, reader.uint32()).value;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): ChangeInnovatorAccountSettingsRequest {
    const message = Object.create(baseChangeInnovatorAccountSettingsRequest) as ChangeInnovatorAccountSettingsRequest;
    if (object.innovatorId) {
      message.innovatorId = String(object.innovatorId);
    }
    if (object.chatEmailNotificationDisabled) {
      message.chatEmailNotificationDisabled = Boolean(object.chatEmailNotificationDisabled);
    }
    if (object.chatPushNotificationDisabled) {
      message.chatPushNotificationDisabled = Boolean(object.chatPushNotificationDisabled);
    }
    return message;
  },
  fromPartial(object: DeepPartial<ChangeInnovatorAccountSettingsRequest>): ChangeInnovatorAccountSettingsRequest {
    const message = Object.create(baseChangeInnovatorAccountSettingsRequest) as ChangeInnovatorAccountSettingsRequest;
    if (object.innovatorId) {
      message.innovatorId = object.innovatorId;
    }
    if (object.chatEmailNotificationDisabled) {
      message.chatEmailNotificationDisabled = object.chatEmailNotificationDisabled;
    }
    if (object.chatPushNotificationDisabled) {
      message.chatPushNotificationDisabled = object.chatPushNotificationDisabled;
    }
    return message;
  },
  toJSON(message: ChangeInnovatorAccountSettingsRequest): unknown {
    const obj: any = {};
    obj.innovatorId = message.innovatorId || "";
    obj.chatEmailNotificationDisabled = message.chatEmailNotificationDisabled || undefined;
    obj.chatPushNotificationDisabled = message.chatPushNotificationDisabled || undefined;
    return obj;
  },
};

export const ChangeInnovatorAccountSettingsResponse = {
  encode(message: ChangeInnovatorAccountSettingsResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): ChangeInnovatorAccountSettingsResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseChangeInnovatorAccountSettingsResponse) as ChangeInnovatorAccountSettingsResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): ChangeInnovatorAccountSettingsResponse {
    const message = Object.create(baseChangeInnovatorAccountSettingsResponse) as ChangeInnovatorAccountSettingsResponse;
    return message;
  },
  fromPartial(object: DeepPartial<ChangeInnovatorAccountSettingsResponse>): ChangeInnovatorAccountSettingsResponse {
    const message = Object.create(baseChangeInnovatorAccountSettingsResponse) as ChangeInnovatorAccountSettingsResponse;
    return message;
  },
  toJSON(message: ChangeInnovatorAccountSettingsResponse): unknown {
    const obj: any = {};
    return obj;
  },
};

export const PostConfirmedJoinCompanyRequest = {
  encode(message: PostConfirmedJoinCompanyRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.companyName);
    writer.uint32(16).int64(message.joinCompanyScheduleAt);
    return writer;
  },
  decode(reader: Reader, length?: number): PostConfirmedJoinCompanyRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(basePostConfirmedJoinCompanyRequest) as PostConfirmedJoinCompanyRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.companyName = reader.string();
          break;
        case 2:
          message.joinCompanyScheduleAt = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): PostConfirmedJoinCompanyRequest {
    const message = Object.create(basePostConfirmedJoinCompanyRequest) as PostConfirmedJoinCompanyRequest;
    if (object.companyName) {
      message.companyName = String(object.companyName);
    }
    if (object.joinCompanyScheduleAt) {
      message.joinCompanyScheduleAt = Number(object.joinCompanyScheduleAt);
    }
    return message;
  },
  fromPartial(object: DeepPartial<PostConfirmedJoinCompanyRequest>): PostConfirmedJoinCompanyRequest {
    const message = Object.create(basePostConfirmedJoinCompanyRequest) as PostConfirmedJoinCompanyRequest;
    if (object.companyName) {
      message.companyName = object.companyName;
    }
    if (object.joinCompanyScheduleAt) {
      message.joinCompanyScheduleAt = object.joinCompanyScheduleAt;
    }
    return message;
  },
  toJSON(message: PostConfirmedJoinCompanyRequest): unknown {
    const obj: any = {};
    obj.companyName = message.companyName || "";
    obj.joinCompanyScheduleAt = message.joinCompanyScheduleAt || 0;
    return obj;
  },
};

export const PostConfirmedJoinCompanyResponse = {
  encode(message: PostConfirmedJoinCompanyResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): PostConfirmedJoinCompanyResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(basePostConfirmedJoinCompanyResponse) as PostConfirmedJoinCompanyResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): PostConfirmedJoinCompanyResponse {
    const message = Object.create(basePostConfirmedJoinCompanyResponse) as PostConfirmedJoinCompanyResponse;
    return message;
  },
  fromPartial(object: DeepPartial<PostConfirmedJoinCompanyResponse>): PostConfirmedJoinCompanyResponse {
    const message = Object.create(basePostConfirmedJoinCompanyResponse) as PostConfirmedJoinCompanyResponse;
    return message;
  },
  toJSON(message: PostConfirmedJoinCompanyResponse): unknown {
    const obj: any = {};
    return obj;
  },
};

export const GetConfirmedJoinCompanySettingRequest = {
  encode(message: GetConfirmedJoinCompanySettingRequest, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): GetConfirmedJoinCompanySettingRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetConfirmedJoinCompanySettingRequest) as GetConfirmedJoinCompanySettingRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetConfirmedJoinCompanySettingRequest {
    const message = Object.create(baseGetConfirmedJoinCompanySettingRequest) as GetConfirmedJoinCompanySettingRequest;
    return message;
  },
  fromPartial(object: DeepPartial<GetConfirmedJoinCompanySettingRequest>): GetConfirmedJoinCompanySettingRequest {
    const message = Object.create(baseGetConfirmedJoinCompanySettingRequest) as GetConfirmedJoinCompanySettingRequest;
    return message;
  },
  toJSON(message: GetConfirmedJoinCompanySettingRequest): unknown {
    const obj: any = {};
    return obj;
  },
};

export const GetConfirmedJoinCompanySettingResponse = {
  encode(message: GetConfirmedJoinCompanySettingResponse, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).bool(message.disabled);
    writer.uint32(18).string(message.description);
    return writer;
  },
  decode(reader: Reader, length?: number): GetConfirmedJoinCompanySettingResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetConfirmedJoinCompanySettingResponse) as GetConfirmedJoinCompanySettingResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.disabled = reader.bool();
          break;
        case 2:
          message.description = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetConfirmedJoinCompanySettingResponse {
    const message = Object.create(baseGetConfirmedJoinCompanySettingResponse) as GetConfirmedJoinCompanySettingResponse;
    if (object.disabled) {
      message.disabled = Boolean(object.disabled);
    }
    if (object.description) {
      message.description = String(object.description);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetConfirmedJoinCompanySettingResponse>): GetConfirmedJoinCompanySettingResponse {
    const message = Object.create(baseGetConfirmedJoinCompanySettingResponse) as GetConfirmedJoinCompanySettingResponse;
    if (object.disabled) {
      message.disabled = object.disabled;
    }
    if (object.description) {
      message.description = object.description;
    }
    return message;
  },
  toJSON(message: GetConfirmedJoinCompanySettingResponse): unknown {
    const obj: any = {};
    obj.disabled = message.disabled || false;
    obj.description = message.description || "";
    return obj;
  },
};

export const GetMissionCompanyNamesRequest = {
  encode(message: GetMissionCompanyNamesRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.name);
    writer.uint32(16).uint32(message.limit);
    writer.uint32(26).string(message.q);
    return writer;
  },
  decode(reader: Reader, length?: number): GetMissionCompanyNamesRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetMissionCompanyNamesRequest) as GetMissionCompanyNamesRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.name = reader.string();
          break;
        case 2:
          message.limit = reader.uint32();
          break;
        case 3:
          message.q = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetMissionCompanyNamesRequest {
    const message = Object.create(baseGetMissionCompanyNamesRequest) as GetMissionCompanyNamesRequest;
    if (object.name) {
      message.name = String(object.name);
    }
    if (object.limit) {
      message.limit = Number(object.limit);
    }
    if (object.q) {
      message.q = String(object.q);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetMissionCompanyNamesRequest>): GetMissionCompanyNamesRequest {
    const message = Object.create(baseGetMissionCompanyNamesRequest) as GetMissionCompanyNamesRequest;
    if (object.name) {
      message.name = object.name;
    }
    if (object.limit) {
      message.limit = object.limit;
    }
    if (object.q) {
      message.q = object.q;
    }
    return message;
  },
  toJSON(message: GetMissionCompanyNamesRequest): unknown {
    const obj: any = {};
    obj.name = message.name || "";
    obj.limit = message.limit || 0;
    obj.q = message.q || "";
    return obj;
  },
};

export const GetMissionCompanyNamesResponse = {
  encode(message: GetMissionCompanyNamesResponse, writer: Writer = Writer.create()): Writer {
    for (const v of message.names) {
      writer.uint32(10).string(v!);
    }
    if (message.paging !== undefined && message.paging !== undefined) {
      Paging.encode(message.paging, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): GetMissionCompanyNamesResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetMissionCompanyNamesResponse) as GetMissionCompanyNamesResponse;
    message.names = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.names.push(reader.string());
          break;
        case 2:
          message.paging = Paging.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetMissionCompanyNamesResponse {
    const message = Object.create(baseGetMissionCompanyNamesResponse) as GetMissionCompanyNamesResponse;
    message.names = [];
    if (object.names) {
      for (const e of object.names) {
        message.names.push(String(e));
      }
    }
    if (object.paging) {
      message.paging = Paging.fromJSON(object.paging);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetMissionCompanyNamesResponse>): GetMissionCompanyNamesResponse {
    const message = Object.create(baseGetMissionCompanyNamesResponse) as GetMissionCompanyNamesResponse;
    message.names = [];
    if (object.names) {
      for (const e of object.names) {
        message.names.push(e);
      }
    }
    if (object.paging) {
      message.paging = Paging.fromPartial(object.paging);
    }
    return message;
  },
  toJSON(message: GetMissionCompanyNamesResponse): unknown {
    const obj: any = {};
    if (message.names) {
      obj.names = message.names.map(e => e || "");
    } else {
      obj.names = [];
    }
    obj.paging = message.paging ? Paging.toJSON(message.paging) : undefined;
    return obj;
  },
};

export const Paging = {
  encode(message: Paging, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.nextQ);
    return writer;
  },
  decode(reader: Reader, length?: number): Paging {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(basePaging) as Paging;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.nextQ = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): Paging {
    const message = Object.create(basePaging) as Paging;
    if (object.nextQ) {
      message.nextQ = String(object.nextQ);
    }
    return message;
  },
  fromPartial(object: DeepPartial<Paging>): Paging {
    const message = Object.create(basePaging) as Paging;
    if (object.nextQ) {
      message.nextQ = object.nextQ;
    }
    return message;
  },
  toJSON(message: Paging): unknown {
    const obj: any = {};
    obj.nextQ = message.nextQ || "";
    return obj;
  },
};

export const GetInnovatorChatRoomsRequest = {
  encode(message: GetInnovatorChatRoomsRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).int64(message.limit);
    writer.uint32(18).string(message.chatRoomIdBefore);
    writer.uint32(24).int64(message.updatedAtBefore);
    return writer;
  },
  decode(reader: Reader, length?: number): GetInnovatorChatRoomsRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetInnovatorChatRoomsRequest) as GetInnovatorChatRoomsRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.limit = longToNumber(reader.int64() as Long);
          break;
        case 2:
          message.chatRoomIdBefore = reader.string();
          break;
        case 3:
          message.updatedAtBefore = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetInnovatorChatRoomsRequest {
    const message = Object.create(baseGetInnovatorChatRoomsRequest) as GetInnovatorChatRoomsRequest;
    if (object.limit) {
      message.limit = Number(object.limit);
    }
    if (object.chatRoomIdBefore) {
      message.chatRoomIdBefore = String(object.chatRoomIdBefore);
    }
    if (object.updatedAtBefore) {
      message.updatedAtBefore = Number(object.updatedAtBefore);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetInnovatorChatRoomsRequest>): GetInnovatorChatRoomsRequest {
    const message = Object.create(baseGetInnovatorChatRoomsRequest) as GetInnovatorChatRoomsRequest;
    if (object.limit) {
      message.limit = object.limit;
    }
    if (object.chatRoomIdBefore) {
      message.chatRoomIdBefore = object.chatRoomIdBefore;
    }
    if (object.updatedAtBefore) {
      message.updatedAtBefore = object.updatedAtBefore;
    }
    return message;
  },
  toJSON(message: GetInnovatorChatRoomsRequest): unknown {
    const obj: any = {};
    obj.limit = message.limit || 0;
    obj.chatRoomIdBefore = message.chatRoomIdBefore || "";
    obj.updatedAtBefore = message.updatedAtBefore || 0;
    return obj;
  },
};

export const GetInnovatorChatRoomsResponse = {
  encode(message: GetInnovatorChatRoomsResponse, writer: Writer = Writer.create()): Writer {
    for (const v of message.chatRooms) {
      InnovatorChatRoom.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): GetInnovatorChatRoomsResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetInnovatorChatRoomsResponse) as GetInnovatorChatRoomsResponse;
    message.chatRooms = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.chatRooms.push(InnovatorChatRoom.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetInnovatorChatRoomsResponse {
    const message = Object.create(baseGetInnovatorChatRoomsResponse) as GetInnovatorChatRoomsResponse;
    message.chatRooms = [];
    if (object.chatRooms) {
      for (const e of object.chatRooms) {
        message.chatRooms.push(InnovatorChatRoom.fromJSON(e));
      }
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetInnovatorChatRoomsResponse>): GetInnovatorChatRoomsResponse {
    const message = Object.create(baseGetInnovatorChatRoomsResponse) as GetInnovatorChatRoomsResponse;
    message.chatRooms = [];
    if (object.chatRooms) {
      for (const e of object.chatRooms) {
        message.chatRooms.push(InnovatorChatRoom.fromPartial(e));
      }
    }
    return message;
  },
  toJSON(message: GetInnovatorChatRoomsResponse): unknown {
    const obj: any = {};
    if (message.chatRooms) {
      obj.chatRooms = message.chatRooms.map(e => e ? InnovatorChatRoom.toJSON(e) : undefined);
    } else {
      obj.chatRooms = [];
    }
    return obj;
  },
};

export const GetInnovatorChatRoomsRefreshRequest = {
  encode(message: GetInnovatorChatRoomsRefreshRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).int64(message.limit);
    writer.uint32(18).string(message.chatRoomIdAfter);
    writer.uint32(24).int64(message.updatedAtAfter);
    return writer;
  },
  decode(reader: Reader, length?: number): GetInnovatorChatRoomsRefreshRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetInnovatorChatRoomsRefreshRequest) as GetInnovatorChatRoomsRefreshRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.limit = longToNumber(reader.int64() as Long);
          break;
        case 2:
          message.chatRoomIdAfter = reader.string();
          break;
        case 3:
          message.updatedAtAfter = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetInnovatorChatRoomsRefreshRequest {
    const message = Object.create(baseGetInnovatorChatRoomsRefreshRequest) as GetInnovatorChatRoomsRefreshRequest;
    if (object.limit) {
      message.limit = Number(object.limit);
    }
    if (object.chatRoomIdAfter) {
      message.chatRoomIdAfter = String(object.chatRoomIdAfter);
    }
    if (object.updatedAtAfter) {
      message.updatedAtAfter = Number(object.updatedAtAfter);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetInnovatorChatRoomsRefreshRequest>): GetInnovatorChatRoomsRefreshRequest {
    const message = Object.create(baseGetInnovatorChatRoomsRefreshRequest) as GetInnovatorChatRoomsRefreshRequest;
    if (object.limit) {
      message.limit = object.limit;
    }
    if (object.chatRoomIdAfter) {
      message.chatRoomIdAfter = object.chatRoomIdAfter;
    }
    if (object.updatedAtAfter) {
      message.updatedAtAfter = object.updatedAtAfter;
    }
    return message;
  },
  toJSON(message: GetInnovatorChatRoomsRefreshRequest): unknown {
    const obj: any = {};
    obj.limit = message.limit || 0;
    obj.chatRoomIdAfter = message.chatRoomIdAfter || "";
    obj.updatedAtAfter = message.updatedAtAfter || 0;
    return obj;
  },
};

export const GetInnovatorChatRoomsRefreshResponse = {
  encode(message: GetInnovatorChatRoomsRefreshResponse, writer: Writer = Writer.create()): Writer {
    for (const v of message.chatRooms) {
      InnovatorChatRoom.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): GetInnovatorChatRoomsRefreshResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetInnovatorChatRoomsRefreshResponse) as GetInnovatorChatRoomsRefreshResponse;
    message.chatRooms = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.chatRooms.push(InnovatorChatRoom.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetInnovatorChatRoomsRefreshResponse {
    const message = Object.create(baseGetInnovatorChatRoomsRefreshResponse) as GetInnovatorChatRoomsRefreshResponse;
    message.chatRooms = [];
    if (object.chatRooms) {
      for (const e of object.chatRooms) {
        message.chatRooms.push(InnovatorChatRoom.fromJSON(e));
      }
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetInnovatorChatRoomsRefreshResponse>): GetInnovatorChatRoomsRefreshResponse {
    const message = Object.create(baseGetInnovatorChatRoomsRefreshResponse) as GetInnovatorChatRoomsRefreshResponse;
    message.chatRooms = [];
    if (object.chatRooms) {
      for (const e of object.chatRooms) {
        message.chatRooms.push(InnovatorChatRoom.fromPartial(e));
      }
    }
    return message;
  },
  toJSON(message: GetInnovatorChatRoomsRefreshResponse): unknown {
    const obj: any = {};
    if (message.chatRooms) {
      obj.chatRooms = message.chatRooms.map(e => e ? InnovatorChatRoom.toJSON(e) : undefined);
    } else {
      obj.chatRooms = [];
    }
    return obj;
  },
};

export const GetMissionRequest = {
  encode(message: GetMissionRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.missionId);
    return writer;
  },
  decode(reader: Reader, length?: number): GetMissionRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetMissionRequest) as GetMissionRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.missionId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetMissionRequest {
    const message = Object.create(baseGetMissionRequest) as GetMissionRequest;
    if (object.missionId) {
      message.missionId = String(object.missionId);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetMissionRequest>): GetMissionRequest {
    const message = Object.create(baseGetMissionRequest) as GetMissionRequest;
    if (object.missionId) {
      message.missionId = object.missionId;
    }
    return message;
  },
  toJSON(message: GetMissionRequest): unknown {
    const obj: any = {};
    obj.missionId = message.missionId || "";
    return obj;
  },
};

export const GetMissionResponse = {
  encode(message: GetMissionResponse, writer: Writer = Writer.create()): Writer {
    if (message.mission !== undefined && message.mission !== undefined) {
      GetMissionResponse_Mission.encode(message.mission, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): GetMissionResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetMissionResponse) as GetMissionResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.mission = GetMissionResponse_Mission.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetMissionResponse {
    const message = Object.create(baseGetMissionResponse) as GetMissionResponse;
    if (object.mission) {
      message.mission = GetMissionResponse_Mission.fromJSON(object.mission);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetMissionResponse>): GetMissionResponse {
    const message = Object.create(baseGetMissionResponse) as GetMissionResponse;
    if (object.mission) {
      message.mission = GetMissionResponse_Mission.fromPartial(object.mission);
    }
    return message;
  },
  toJSON(message: GetMissionResponse): unknown {
    const obj: any = {};
    obj.mission = message.mission ? GetMissionResponse_Mission.toJSON(message.mission) : undefined;
    return obj;
  },
};

export const GetMissionResponse_Mission = {
  encode(message: GetMissionResponse_Mission, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.missionId);
    writer.uint32(18).string(message.name);
    if (message.iconImage !== undefined && message.iconImage !== undefined) {
      Images.encode(message.iconImage, writer.uint32(26).fork()).ldelim();
    }
    if (message.coverImage !== undefined && message.coverImage !== undefined) {
      Images.encode(message.coverImage, writer.uint32(34).fork()).ldelim();
    }
    writer.uint32(42).string(message.missionDescription);
    writer.uint32(50).string(message.strengthPoint);
    writer.uint32(58).string(message.companyName);
    if (message.japanPrefecture !== undefined && message.japanPrefecture !== undefined) {
      JapanPrefecture.encode(message.japanPrefecture, writer.uint32(66).fork()).ldelim();
    }
    writer.uint32(74).string(message.otherAddress);
    if (message.memberRange !== undefined && message.memberRange !== undefined) {
      MemberRange.encode(message.memberRange, writer.uint32(82).fork()).ldelim();
    }
    if (message.fund !== undefined && message.fund !== undefined) {
      Fund.encode(message.fund, writer.uint32(90).fork()).ldelim();
    }
    for (const v of message.missionCategories) {
      MissionCategory.encode(v!, writer.uint32(98).fork()).ldelim();
    }
    for (const v of message.missionMembers) {
      MissionMember.encode(v!, writer.uint32(106).fork()).ldelim();
    }
    writer.uint32(114).string(message.companyUrl);
    for (const v of message.missionOfficeImages) {
      MissionOfficeImage.encode(v!, writer.uint32(122).fork()).ldelim();
    }
    for (const v of message.missionProducts) {
      GetMissionResponse_MissionProduct.encode(v!, writer.uint32(130).fork()).ldelim();
    }
    for (const v of message.corporateAttributes) {
      CorporateAttribute.encode(v!, writer.uint32(138).fork()).ldelim();
    }
    if (message.companyEstablishedDate !== undefined && message.companyEstablishedDate !== undefined) {
      CompanyEstablishedDate.encode(message.companyEstablishedDate, writer.uint32(146).fork()).ldelim();
    }
    for (const v of message.missionJobOffers) {
      MissionJobOffer.encode(v!, writer.uint32(154).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): GetMissionResponse_Mission {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetMissionResponse_Mission) as GetMissionResponse_Mission;
    message.missionCategories = [];
    message.missionMembers = [];
    message.missionOfficeImages = [];
    message.missionProducts = [];
    message.corporateAttributes = [];
    message.missionJobOffers = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.missionId = reader.string();
          break;
        case 2:
          message.name = reader.string();
          break;
        case 3:
          message.iconImage = Images.decode(reader, reader.uint32());
          break;
        case 4:
          message.coverImage = Images.decode(reader, reader.uint32());
          break;
        case 5:
          message.missionDescription = reader.string();
          break;
        case 6:
          message.strengthPoint = reader.string();
          break;
        case 7:
          message.companyName = reader.string();
          break;
        case 8:
          message.japanPrefecture = JapanPrefecture.decode(reader, reader.uint32());
          break;
        case 9:
          message.otherAddress = reader.string();
          break;
        case 10:
          message.memberRange = MemberRange.decode(reader, reader.uint32());
          break;
        case 11:
          message.fund = Fund.decode(reader, reader.uint32());
          break;
        case 12:
          message.missionCategories.push(MissionCategory.decode(reader, reader.uint32()));
          break;
        case 13:
          message.missionMembers.push(MissionMember.decode(reader, reader.uint32()));
          break;
        case 14:
          message.companyUrl = reader.string();
          break;
        case 15:
          message.missionOfficeImages.push(MissionOfficeImage.decode(reader, reader.uint32()));
          break;
        case 16:
          message.missionProducts.push(GetMissionResponse_MissionProduct.decode(reader, reader.uint32()));
          break;
        case 17:
          message.corporateAttributes.push(CorporateAttribute.decode(reader, reader.uint32()));
          break;
        case 18:
          message.companyEstablishedDate = CompanyEstablishedDate.decode(reader, reader.uint32());
          break;
        case 19:
          message.missionJobOffers.push(MissionJobOffer.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetMissionResponse_Mission {
    const message = Object.create(baseGetMissionResponse_Mission) as GetMissionResponse_Mission;
    message.missionCategories = [];
    message.missionMembers = [];
    message.missionOfficeImages = [];
    message.missionProducts = [];
    message.corporateAttributes = [];
    message.missionJobOffers = [];
    if (object.missionId) {
      message.missionId = String(object.missionId);
    }
    if (object.name) {
      message.name = String(object.name);
    }
    if (object.iconImage) {
      message.iconImage = Images.fromJSON(object.iconImage);
    }
    if (object.coverImage) {
      message.coverImage = Images.fromJSON(object.coverImage);
    }
    if (object.missionDescription) {
      message.missionDescription = String(object.missionDescription);
    }
    if (object.strengthPoint) {
      message.strengthPoint = String(object.strengthPoint);
    }
    if (object.companyName) {
      message.companyName = String(object.companyName);
    }
    if (object.japanPrefecture) {
      message.japanPrefecture = JapanPrefecture.fromJSON(object.japanPrefecture);
    }
    if (object.otherAddress) {
      message.otherAddress = String(object.otherAddress);
    }
    if (object.memberRange) {
      message.memberRange = MemberRange.fromJSON(object.memberRange);
    }
    if (object.fund) {
      message.fund = Fund.fromJSON(object.fund);
    }
    if (object.missionCategories) {
      for (const e of object.missionCategories) {
        message.missionCategories.push(MissionCategory.fromJSON(e));
      }
    }
    if (object.missionMembers) {
      for (const e of object.missionMembers) {
        message.missionMembers.push(MissionMember.fromJSON(e));
      }
    }
    if (object.companyUrl) {
      message.companyUrl = String(object.companyUrl);
    }
    if (object.missionOfficeImages) {
      for (const e of object.missionOfficeImages) {
        message.missionOfficeImages.push(MissionOfficeImage.fromJSON(e));
      }
    }
    if (object.missionProducts) {
      for (const e of object.missionProducts) {
        message.missionProducts.push(GetMissionResponse_MissionProduct.fromJSON(e));
      }
    }
    if (object.corporateAttributes) {
      for (const e of object.corporateAttributes) {
        message.corporateAttributes.push(CorporateAttribute.fromJSON(e));
      }
    }
    if (object.companyEstablishedDate) {
      message.companyEstablishedDate = CompanyEstablishedDate.fromJSON(object.companyEstablishedDate);
    }
    if (object.missionJobOffers) {
      for (const e of object.missionJobOffers) {
        message.missionJobOffers.push(MissionJobOffer.fromJSON(e));
      }
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetMissionResponse_Mission>): GetMissionResponse_Mission {
    const message = Object.create(baseGetMissionResponse_Mission) as GetMissionResponse_Mission;
    message.missionCategories = [];
    message.missionMembers = [];
    message.missionOfficeImages = [];
    message.missionProducts = [];
    message.corporateAttributes = [];
    message.missionJobOffers = [];
    if (object.missionId) {
      message.missionId = object.missionId;
    }
    if (object.name) {
      message.name = object.name;
    }
    if (object.iconImage) {
      message.iconImage = Images.fromPartial(object.iconImage);
    }
    if (object.coverImage) {
      message.coverImage = Images.fromPartial(object.coverImage);
    }
    if (object.missionDescription) {
      message.missionDescription = object.missionDescription;
    }
    if (object.strengthPoint) {
      message.strengthPoint = object.strengthPoint;
    }
    if (object.companyName) {
      message.companyName = object.companyName;
    }
    if (object.japanPrefecture) {
      message.japanPrefecture = JapanPrefecture.fromPartial(object.japanPrefecture);
    }
    if (object.otherAddress) {
      message.otherAddress = object.otherAddress;
    }
    if (object.memberRange) {
      message.memberRange = MemberRange.fromPartial(object.memberRange);
    }
    if (object.fund) {
      message.fund = Fund.fromPartial(object.fund);
    }
    if (object.missionCategories) {
      for (const e of object.missionCategories) {
        message.missionCategories.push(MissionCategory.fromPartial(e));
      }
    }
    if (object.missionMembers) {
      for (const e of object.missionMembers) {
        message.missionMembers.push(MissionMember.fromPartial(e));
      }
    }
    if (object.companyUrl) {
      message.companyUrl = object.companyUrl;
    }
    if (object.missionOfficeImages) {
      for (const e of object.missionOfficeImages) {
        message.missionOfficeImages.push(MissionOfficeImage.fromPartial(e));
      }
    }
    if (object.missionProducts) {
      for (const e of object.missionProducts) {
        message.missionProducts.push(GetMissionResponse_MissionProduct.fromPartial(e));
      }
    }
    if (object.corporateAttributes) {
      for (const e of object.corporateAttributes) {
        message.corporateAttributes.push(CorporateAttribute.fromPartial(e));
      }
    }
    if (object.companyEstablishedDate) {
      message.companyEstablishedDate = CompanyEstablishedDate.fromPartial(object.companyEstablishedDate);
    }
    if (object.missionJobOffers) {
      for (const e of object.missionJobOffers) {
        message.missionJobOffers.push(MissionJobOffer.fromPartial(e));
      }
    }
    return message;
  },
  toJSON(message: GetMissionResponse_Mission): unknown {
    const obj: any = {};
    obj.missionId = message.missionId || "";
    obj.name = message.name || "";
    obj.iconImage = message.iconImage ? Images.toJSON(message.iconImage) : undefined;
    obj.coverImage = message.coverImage ? Images.toJSON(message.coverImage) : undefined;
    obj.missionDescription = message.missionDescription || "";
    obj.strengthPoint = message.strengthPoint || "";
    obj.companyName = message.companyName || "";
    obj.japanPrefecture = message.japanPrefecture ? JapanPrefecture.toJSON(message.japanPrefecture) : undefined;
    obj.otherAddress = message.otherAddress || "";
    obj.memberRange = message.memberRange ? MemberRange.toJSON(message.memberRange) : undefined;
    obj.fund = message.fund ? Fund.toJSON(message.fund) : undefined;
    if (message.missionCategories) {
      obj.missionCategories = message.missionCategories.map(e => e ? MissionCategory.toJSON(e) : undefined);
    } else {
      obj.missionCategories = [];
    }
    if (message.missionMembers) {
      obj.missionMembers = message.missionMembers.map(e => e ? MissionMember.toJSON(e) : undefined);
    } else {
      obj.missionMembers = [];
    }
    obj.companyUrl = message.companyUrl || "";
    if (message.missionOfficeImages) {
      obj.missionOfficeImages = message.missionOfficeImages.map(e => e ? MissionOfficeImage.toJSON(e) : undefined);
    } else {
      obj.missionOfficeImages = [];
    }
    if (message.missionProducts) {
      obj.missionProducts = message.missionProducts.map(e => e ? GetMissionResponse_MissionProduct.toJSON(e) : undefined);
    } else {
      obj.missionProducts = [];
    }
    if (message.corporateAttributes) {
      obj.corporateAttributes = message.corporateAttributes.map(e => e ? CorporateAttribute.toJSON(e) : undefined);
    } else {
      obj.corporateAttributes = [];
    }
    obj.companyEstablishedDate = message.companyEstablishedDate ? CompanyEstablishedDate.toJSON(message.companyEstablishedDate) : undefined;
    if (message.missionJobOffers) {
      obj.missionJobOffers = message.missionJobOffers.map(e => e ? MissionJobOffer.toJSON(e) : undefined);
    } else {
      obj.missionJobOffers = [];
    }
    return obj;
  },
};

export const GetMissionResponse_MissionProduct = {
  encode(message: GetMissionResponse_MissionProduct, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).uint32(message.missionProductId);
    writer.uint32(18).string(message.name);
    writer.uint32(26).string(message.overview);
    writer.uint32(34).string(message.url);
    writer.uint32(42).string(message.problem);
    writer.uint32(50).string(message.solution);
    if (message.image !== undefined && message.image !== undefined) {
      Images.encode(message.image, writer.uint32(58).fork()).ldelim();
    }
    if (message.productTarget !== undefined && message.productTarget !== undefined) {
      MissionProductTarget.encode(message.productTarget, writer.uint32(66).fork()).ldelim();
    }
    for (const v of message.tags) {
      writer.uint32(74).string(v!);
    }
    return writer;
  },
  decode(reader: Reader, length?: number): GetMissionResponse_MissionProduct {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetMissionResponse_MissionProduct) as GetMissionResponse_MissionProduct;
    message.tags = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.missionProductId = reader.uint32();
          break;
        case 2:
          message.name = reader.string();
          break;
        case 3:
          message.overview = reader.string();
          break;
        case 4:
          message.url = reader.string();
          break;
        case 5:
          message.problem = reader.string();
          break;
        case 6:
          message.solution = reader.string();
          break;
        case 7:
          message.image = Images.decode(reader, reader.uint32());
          break;
        case 8:
          message.productTarget = MissionProductTarget.decode(reader, reader.uint32());
          break;
        case 9:
          message.tags.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetMissionResponse_MissionProduct {
    const message = Object.create(baseGetMissionResponse_MissionProduct) as GetMissionResponse_MissionProduct;
    message.tags = [];
    if (object.missionProductId) {
      message.missionProductId = Number(object.missionProductId);
    }
    if (object.name) {
      message.name = String(object.name);
    }
    if (object.overview) {
      message.overview = String(object.overview);
    }
    if (object.url) {
      message.url = String(object.url);
    }
    if (object.problem) {
      message.problem = String(object.problem);
    }
    if (object.solution) {
      message.solution = String(object.solution);
    }
    if (object.image) {
      message.image = Images.fromJSON(object.image);
    }
    if (object.productTarget) {
      message.productTarget = MissionProductTarget.fromJSON(object.productTarget);
    }
    if (object.tags) {
      for (const e of object.tags) {
        message.tags.push(String(e));
      }
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetMissionResponse_MissionProduct>): GetMissionResponse_MissionProduct {
    const message = Object.create(baseGetMissionResponse_MissionProduct) as GetMissionResponse_MissionProduct;
    message.tags = [];
    if (object.missionProductId) {
      message.missionProductId = object.missionProductId;
    }
    if (object.name) {
      message.name = object.name;
    }
    if (object.overview) {
      message.overview = object.overview;
    }
    if (object.url) {
      message.url = object.url;
    }
    if (object.problem) {
      message.problem = object.problem;
    }
    if (object.solution) {
      message.solution = object.solution;
    }
    if (object.image) {
      message.image = Images.fromPartial(object.image);
    }
    if (object.productTarget) {
      message.productTarget = MissionProductTarget.fromPartial(object.productTarget);
    }
    if (object.tags) {
      for (const e of object.tags) {
        message.tags.push(e);
      }
    }
    return message;
  },
  toJSON(message: GetMissionResponse_MissionProduct): unknown {
    const obj: any = {};
    obj.missionProductId = message.missionProductId || 0;
    obj.name = message.name || "";
    obj.overview = message.overview || "";
    obj.url = message.url || "";
    obj.problem = message.problem || "";
    obj.solution = message.solution || "";
    obj.image = message.image ? Images.toJSON(message.image) : undefined;
    obj.productTarget = message.productTarget ? MissionProductTarget.toJSON(message.productTarget) : undefined;
    if (message.tags) {
      obj.tags = message.tags.map(e => e || "");
    } else {
      obj.tags = [];
    }
    return obj;
  },
};

type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T[P] extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T[P] extends Date | Function | Uint8Array | undefined
  ? T[P]
  : T[P] extends infer U | undefined
  ? DeepPartial<U>
  : T[P] extends object
  ? DeepPartial<T[P]>
  : T[P]
};