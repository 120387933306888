import React, { FC, useCallback } from 'react';
import styled, { css } from 'styled-components/macro';
import theme from 'styles/theme';
import media from 'styles/media';
import * as MESSAGES from 'constants/messages';
import { BsThreeDots } from 'react-icons/bs';
import { InnovatorChatMessage } from 'proto/v1/apimodel/apimodel';

const MenuContainer = styled.div<{ isOpponent: boolean }>`
  position: relative;

  ${media.mobile} {
    position: initial;
  }
`;

const MenuIcon = styled.div<{ isOpponent: boolean }>`
  display: inline-block;
  position: relative;
  ${({ isOpponent }) =>
    isOpponent
      ? css`
          top: -4px;
          margin-left: 8px;
        `
      : css`
          top: -12px;
          margin-right: 8px;
        `}

  padding: 4px;
  border-radius: 50%;
  background: ${theme.borderTable};
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  path {
    fill: ${theme.textCardSecondary};
  }

  ${media.mobile} {
    display: none;
  }
`;

const Menu = styled.div<{ isFileMessage: boolean; isOpponent: boolean }>`
  display: flex;
  position: absolute;
  align-items: center;
  border-radius: 5px;
  background: ${theme.baseWhite};
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
  ${({ isOpponent }) =>
    isOpponent
      ? css`
          top: -58px;
          right: -68px;
        `
      : css`
          top: -74px;
          right: -120px;
        `}
  ${({ isFileMessage }) =>
    isFileMessage &&
    css`
      right: -60px;
    `}

  ${media.mobile} {
    ${({ isOpponent }) =>
      isOpponent
        ? css`
            width: 60px;
            top: -14px;
            left: 0;
          `
        : css`
            top: -8px;
            right: 0;
          `}
  }
`;

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 32px;
  color: ${theme.textPrimaryHover};
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const Divider = styled.div`
  height: 20px;
  border-left: 1px solid #cfcfcf;
`;

type Props = {
  chatMessage: InnovatorChatMessage;
  showMenuIcon: boolean;
  showMenu: boolean;
  toggleMenu: () => void;
  onClickDeleteMenu?: () => void;
  onClickCopyMenu: () => void;
  isOpponent?: boolean;
};

const ChatMessageMenu: FC<Props> = ({
  chatMessage,
  showMenu,
  showMenuIcon,
  toggleMenu,
  onClickDeleteMenu,
  onClickCopyMenu,
  isOpponent = false,
}) => {
  const isFileMessage = !!chatMessage.file;

  const copyTextToClipBoard = useCallback(
    (message: string) => {
      navigator.clipboard.writeText(message).then(() => onClickCopyMenu());
    },
    [onClickCopyMenu],
  );

  return (
    <>
      <MenuContainer isOpponent={isOpponent}>
        {showMenu && (
          <Menu
            data-testid={
              isOpponent
                ? 'opponent-chat-message-menu'
                : 'own-chat-message-menu'
            }
            isFileMessage={isFileMessage}
            isOpponent={isOpponent}
          >
            <>
              {!isOpponent && (
                <MenuItem
                  onClick={onClickDeleteMenu}
                  onTouchEnd={onClickDeleteMenu}
                >
                  {MESSAGES.MESSAGES.MENU.DELETE}
                </MenuItem>
              )}
              {!isFileMessage && (
                <>
                  {!isOpponent && <Divider />}
                  <MenuItem
                    onClick={() => copyTextToClipBoard(chatMessage.message)}
                    onTouchEnd={() => copyTextToClipBoard(chatMessage.message)}
                  >
                    {MESSAGES.MESSAGES.MENU.COPY}
                  </MenuItem>
                </>
              )}
            </>
          </Menu>
        )}
      </MenuContainer>
      {showMenuIcon && (
        <MenuIcon
          onClick={toggleMenu}
          data-testid={
            isOpponent
              ? 'opponent-chat-message-menu-icon'
              : 'own-chat-message-menu-icon'
          }
          isOpponent={isOpponent}
        >
          <BsThreeDots size={24} />
        </MenuIcon>
      )}
    </>
  );
};

export default ChatMessageMenu;
