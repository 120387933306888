import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { Helmet } from 'react-helmet';
import * as MESSAGES from 'constants/messages';
import media from 'styles/media';
import { Stepper } from 'hooks/useStepper';
import {
  Form,
  ButtonM,
  GroupLabel,
  CardInner,
  OptionalLabel,
} from 'components/atoms';
import {
  NewGraduatePastExperienceAchievementFormField,
  NewGraduateInternshipExperiencesFormField,
  NewGraduateStudyAbroadExperiencesFormField,
  NewGraduateClubExperiencesFormField,
  NewGraduateSelfIntroductionFormField,
} from 'components/organisms';
import useRegisterProfileFormNewGraduateThird from './useRegisterProfileFormNewGraduateThird';

const Buttons = styled.div`
  display: grid;
  grid-column-gap: 16px;
  grid-template-columns: auto auto;

  ${media.smallMobile} {
    grid-row-gap: 16px;
    grid-template-columns: 1fr;
  }
`;

const GoToPrevButton = styled(ButtonM)`
  justify-self: end;

  ${media.smallMobile} {
    order: 2;
    width: 100%;
  }
`;

const SubmitButton = styled(ButtonM)`
  ${media.smallMobile} {
    order: 1;
    width: 100%;
  }
`;

type RegisterProfileFormNewGraduateThirdProps = {
  stepper: Stepper;
};

const RegisterProfileFormNewGraduateThird: FC<RegisterProfileFormNewGraduateThirdProps> = props => {
  const { stepper } = props;
  const {
    formMethods,
    onSubmit,
    formItems,
    isValidDefault,
    handleClickPrevButton,
    handleSelectPastExperienceAchievements,
    handleDeselectPastExperienceAchievements,
  } = useRegisterProfileFormNewGraduateThird({
    stepper,
  });

  const {
    handleSubmit,
    errors,
    register,
    formState,
    control,
    trigger,
  } = formMethods;

  const { isValid, isSubmitting, isDirty, touched } = formState;
  const isDisabledSubmitButton = isDirty
    ? !isValid || isSubmitting
    : !isValidDefault;

  return (
    <>
      <Helmet>
        <title>{MESSAGES.HEAD.TITLE.RESISTER_PROFILE.FORM_THIRD}</title>
      </Helmet>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        data-testid="profile-form-new-graduate-third"
      >
        <NewGraduatePastExperienceAchievementFormField
          pastExperienceAchievements={formItems.pastExperienceAchievements}
          control={control}
          onSelect={handleSelectPastExperienceAchievements}
          onDeselect={handleDeselectPastExperienceAchievements}
        />

        <GroupLabel>
          {MESSAGES.PROFILE.FIELD.NEW_GRADUATE_INTERNSHIP_EXPERIENCES.LABEL}
          <OptionalLabel />
        </GroupLabel>
        <CardInner>
          <NewGraduateInternshipExperiencesFormField
            register={register}
            control={control}
            trigger={trigger}
            errors={errors.newGraduate?.internshipExperiences}
            touched={touched.newGraduate?.internshipExperiences}
          />
        </CardInner>

        <GroupLabel>
          {MESSAGES.PROFILE.FIELD.NEW_GRADUATE_STUDY_ABROAD_EXPERIENCES.LABEL}
          <OptionalLabel />
        </GroupLabel>
        <CardInner>
          <NewGraduateStudyAbroadExperiencesFormField
            register={register}
            control={control}
            trigger={trigger}
            errors={errors.newGraduate?.studyAbroadExperiences}
            touched={touched.newGraduate?.studyAbroadExperiences}
          />
        </CardInner>

        <GroupLabel>
          {MESSAGES.PROFILE.FIELD.NEW_GRADUATE_CLUB_EXPERIENCES.LABEL}
          <OptionalLabel />
        </GroupLabel>
        <CardInner>
          <NewGraduateClubExperiencesFormField
            register={register}
            control={control}
          />
        </CardInner>

        <GroupLabel>
          {MESSAGES.PROFILE.FIELD.NEW_GRADUATE_SELF_INTRODUCTION.LABEL}
        </GroupLabel>
        <CardInner>
          <NewGraduateSelfIntroductionFormField
            error={touched.selfIntroduction && errors.selfIntroduction?.message}
            register={register}
            control={control}
          />
        </CardInner>

        <Buttons>
          <GoToPrevButton buttonTheme="cancel" onClick={handleClickPrevButton}>
            {MESSAGES.REGISTER_PROFILE.BUTTON.GO_TO_PREV}
          </GoToPrevButton>

          <SubmitButton type="submit" disabled={isDisabledSubmitButton}>
            {MESSAGES.REGISTER_PROFILE.BUTTON.SAVE_AND_GO_TO_NEXT}
          </SubmitButton>
        </Buttons>
      </Form>
    </>
  );
};

export default RegisterProfileFormNewGraduateThird;
