import React, { FC, useMemo } from 'react';
import qs from 'qs';
import styled from 'styled-components/macro';
import media from 'styles/media';
import { useHistory, useRouteMatch } from 'react-router-dom';
import useMediaQuery from 'hooks/useMediaQuery';
import * as PROTECTED_ROUTES from 'routes/constants/protected';
import { PageTitle } from 'components/atoms';
import {
  PrivateHeader,
  MatterClosedDialog,
  ErrorNotification,
  MessageConfirmModal,
  Sidebar,
} from 'components/organisms';

const HEADER_HEIGHT = 64;
const HEADER_HEIGHT_MOBILE = 54;
const TITLE_HEIGHT = 48;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

const Body = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  overflow: auto;
`;

const Layout = styled.div<{ hasTitle: boolean }>`
  display: flex;
  height: ${({ hasTitle }) =>
    hasTitle
      ? `calc(100% - ${HEADER_HEIGHT + TITLE_HEIGHT}px)`
      : `calc(100% - ${HEADER_HEIGHT}px)`};

  ${media.tablet} {
    height: ${({ hasTitle }) =>
      hasTitle
        ? `calc(100% - ${HEADER_HEIGHT_MOBILE + TITLE_HEIGHT}px)`
        : `calc(100% - ${HEADER_HEIGHT_MOBILE}px)`};
  }
`;

const LayoutContent = styled.div`
  flex: 1;
  width: 100%;
`;

type Props = { title?: string };

const PrivateRouteLayout: FC<Props> = ({ children, title }) => {
  const history = useHistory();
  const isSelectedChatRoom = !!useRouteMatch(
    `${PROTECTED_ROUTES.MESSAGES}/:id`,
  );
  const { isTablet } = useMediaQuery();
  const { pathname, search } = history.location;
  const { missionId }: { missionId?: string } = qs.parse(search, {
    ignoreQueryPrefix: true,
  });

  const canShowTitle = useMemo(() => {
    if (pathname.includes(PROTECTED_ROUTES.MESSAGES)) {
      if (!isTablet) return true;
      return isSelectedChatRoom ? false : !missionId;
    }
    return !!title;
  }, [isSelectedChatRoom, isTablet, missionId, pathname, title]);

  return (
    <Container>
      <PrivateHeader />
      <Layout hasTitle={canShowTitle}>
        <Sidebar />
        <LayoutContent>
          <ErrorNotification />
          {canShowTitle && <PageTitle>{title}</PageTitle>}
          <Body id="layout-body">{children}</Body>
        </LayoutContent>
      </Layout>
      <MatterClosedDialog />
      <MessageConfirmModal />
    </Container>
  );
};

export default PrivateRouteLayout;
