import React, { FC } from 'react';
import * as MESSAGES from 'constants/messages';
import { PROFILE_FIELDS_MAX_LENGTH } from 'constants/profile';
import { ProfileFormRegister } from 'types/profile';
import { TextInput } from 'components/atoms';
import { Field } from 'components/molecules';

type LastNameFormFieldProps = {
  error: string | undefined;
  register: ProfileFormRegister<'lastName'>;
};

const LastNameFormField: FC<LastNameFormFieldProps> = props => {
  const { error, register } = props;
  return (
    <Field label={MESSAGES.PROFILE.FIELD.LAST_NAME.LABEL} required locked>
      <TextInput
        enterKeyHint="enter"
        name="lastName"
        error={error}
        maxLength={PROFILE_FIELDS_MAX_LENGTH.LAST_NAME}
        placeholder={MESSAGES.PROFILE.FIELD.LAST_NAME.PLACEHOLDER}
        aria-label={MESSAGES.PROFILE.FIELD.LAST_NAME.LABEL}
        ref={register}
      />
    </Field>
  );
};

export default LastNameFormField;
