import React, { FC, ComponentProps } from 'react';
import styled, { css } from 'styled-components/macro';
import { NavLink as RouterNavLink } from 'react-router-dom';
import theme from 'styles/theme';

type Props = { disabled?: boolean } & ComponentProps<typeof RouterNavLink>;

const StyledNavLink = styled(RouterNavLink)<Props>`
  display: flex;
  align-items: center;
  padding: 14px 24px;
  color: ${theme.textBody};
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;

  &:hover {
    background-color: ${theme.borderDefault};
    color: ${theme.textBody};
  }

  ${({ disabled = false }) =>
    disabled &&
    css`
      color: ${theme.textSecondary};
      pointer-events: none;
    `}
`;

const NavLink: FC<Props> = props => {
  return (
    <StyledNavLink
      {...props}
      exact
      activeStyle={{
        backgroundColor: theme.borderDefault,
      }}
    />
  );
};

export default NavLink;
