import React, { FC } from 'react';
import styled from 'styled-components/macro';
import media from 'styles/media';
import * as MESSAGES from 'constants/messages';
import { ProfileFormItems } from 'types/profile';
import { Card, ButtonS } from 'components/atoms';
import { ProfileGroupLabel } from 'components/molecules';
import useProfile from '../useProfile';
import CareerProfileBasicInfoContents from './CareerProfileBasicInfoContents';
import CareerProfileBasicInfoForm from './CareerProfileBasicInfoForm';
import { UpdateProfileCompletedModal } from '../Modals';

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 70px;

  ${media.mobile} {
    margin-bottom: 40px;
  }
`;

const ContentHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  margin-bottom: 16px;

  ${media.mobile} {
    margin-bottom: 12px;
  }
`;

const ContentCard = styled(Card)`
  width: 100%;
`;

type CareerProfileBasicInfoProps = {
  formItems: ProfileFormItems;
};

const CareerProfileBasicInfo: FC<CareerProfileBasicInfoProps> = props => {
  const { formItems } = props;
  const {
    canEdit,
    isEdit,
    handleShowContent,
    handleShowEdit,
    showUpdateProfileCompletedModal,
    openUpdateProfileCompletedModal,
    closeUpdateProfileCompletedModal,
  } = useProfile();

  return (
    <>
      <Wrapper>
        <ContentHead>
          <ProfileGroupLabel
            label={MESSAGES.PROFILE_SETTING.BASIC_INFO.LABEL}
            showInformation={isEdit}
          />

          {canEdit && !isEdit && (
            <ButtonS
              onClick={handleShowEdit}
              data-testid="button-edit-basic-info"
            >
              {MESSAGES.PROFILE_SETTING.BUTTON.EDIT}
            </ButtonS>
          )}
        </ContentHead>
        <ContentCard>
          {isEdit ? (
            <CareerProfileBasicInfoForm
              formItems={formItems}
              onShowContent={handleShowContent}
              onOpenCompletedModal={openUpdateProfileCompletedModal}
            />
          ) : (
            <CareerProfileBasicInfoContents />
          )}
        </ContentCard>
      </Wrapper>
      <UpdateProfileCompletedModal
        show={showUpdateProfileCompletedModal}
        onClose={closeUpdateProfileCompletedModal}
      />
    </>
  );
};

export default CareerProfileBasicInfo;
