import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import { getInnovator } from 'modules/account';
import media from 'styles/media';
import {
  MemberNumberField,
  NameField,
  NameKanaField,
  YearMonthOfBirthField,
  PhoneNumberField,
  JapanPrefectureField,
  AcademicBackgroundSchoolField,
  AcademicBackgroundFacultyField,
  AcademicBackgroundDepartmentField,
  AcademicBackgroundYearMonthOfGraduationField,
  CareerAnnualIncomeField,
  CareerMonthlyUnitPriceField,
} from 'components/organisms';

const Wrapper = styled.div`
  margin-bottom: -40px;

  ${media.mobile} {
    margin-bottom: -32px;
  }
`;

const CareerProfileBasicInfoContents: FC = () => {
  const innovator = useSelector(getInnovator);

  if (!innovator) return null;

  return (
    <Wrapper data-testid="career-profile-basic-info-contents">
      <MemberNumberField innovatorId={innovator.innovatorId} />

      <NameField
        lastName={innovator.lastName}
        firstName={innovator.firstName}
      />

      <NameKanaField
        lastNameKana={innovator.lastNameKana}
        firstNameKana={innovator.firstNameKana}
      />

      <YearMonthOfBirthField
        yearOfBirth={innovator.yearOfBirth}
        monthOfBirth={innovator.monthOfBirth}
      />

      <PhoneNumberField
        phoneCountryCode={innovator.phoneCountryCode}
        phoneNumber={innovator.phoneNumber}
      />

      <JapanPrefectureField japanPrefecture={innovator.japanPrefecture} />

      <AcademicBackgroundSchoolField
        academicBackground={innovator.academicBackground}
      />

      <AcademicBackgroundFacultyField
        academicBackground={innovator.academicBackground}
      />

      <AcademicBackgroundDepartmentField
        academicBackground={innovator.academicBackground}
      />

      <AcademicBackgroundYearMonthOfGraduationField
        yearOfGraduation={innovator.academicBackground?.yearOfGraduation}
        monthOfGraduation={innovator.academicBackground?.monthOfGraduation}
      />

      <CareerAnnualIncomeField annualIncome={innovator.career?.annualIncome} />

      <CareerMonthlyUnitPriceField
        monthlyUnitPrice={innovator.career?.monthlyUnitPrice}
      />
    </Wrapper>
  );
};

export default CareerProfileBasicInfoContents;
