import React, { Component, ErrorInfo } from 'react';
import logger from 'external/logger';

type ErrorState = {
  error: boolean;
};

class ErrorBoundary extends Component<{}, ErrorState> {
  // eslint-disable-next-line react/no-unused-state
  public state: ErrorState = { error: false };

  public static getDerivedStateFromError(_error: Error) {
    return { error: true };
  }

  public componentDidMount() {
    window.onunhandledrejection = (e: PromiseRejectionEvent) => {
      logger.error(`[ErrorBoundary:onunhandledrejection] ${e.reason}`);
    };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    logger.exception(error, { extra: errorInfo });
  }

  public render() {
    return <>{this.props.children}</>;
  }
}

export default ErrorBoundary;
