import React, { FC } from 'react';
import * as MESSAGES from 'constants/messages';
import { Label, ProfileField, ProfileContent } from 'components/atoms';
import { CareerJobHistory } from 'types/profile';

type OccupationsFieldProps = {
  occupations: CareerJobHistory['occupations'];
};

const OccupationsField: FC<OccupationsFieldProps> = props => {
  const { occupations } = props;

  return (
    <ProfileField data-testid="career-job-occupations-field">
      <Label>
        {MESSAGES.PROFILE.FIELD.CAREER_JOB.OCCUPATIONS.FIELD.OCCUPATION.LABEL}
      </Label>
      <ProfileContent>
        {occupations &&
          occupations
            .map(
              occupation =>
                `${occupation.occupation?.name}(${occupation.yearsOfExperience?.name})`,
            )
            .join(' 、')}
      </ProfileContent>
    </ProfileField>
  );
};

export default OccupationsField;
