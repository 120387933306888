import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import * as MESSAGES from 'constants/messages';

const Head: FC = () => {
  return (
    <Helmet
      defaultTitle={MESSAGES.HEAD.TITLE.DEFAULT}
      titleTemplate={`%s | ${MESSAGES.HEAD.TITLE.DEFAULT}`}
    />
  );
};

export default Head;
