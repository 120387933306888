import styled from 'styled-components/macro';
import theme from 'styles/theme';
import media from 'styles/media';

const CardInner = styled.div`
  margin-bottom: 40px;
  padding: 24px;
  background-color: ${theme.bgCard};

  ${media.mobile} {
    padding: 24px 12px;
  }
`;

export default CardInner;
