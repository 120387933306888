import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getInnovator, AccountActions } from 'modules/account';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';
import { UiActions } from 'modules/ui';
import { NO_SELECT_NUMBER } from 'constants/form';
import apiInnovator from 'external/api/innovator';
import {
  NewGraduateProfileSkillsAndPreferredConditionsFormValues,
  ProfileFormItems,
} from 'types/profile';
import RequestError from 'classes/RequestError';
import { UpdateInnovatorRequest } from 'proto/v1/innovatorservice/innovatorservice';
import { MAX_PREFERRED_WORK_LOCATIONS } from 'constants/profile';
import { range } from 'utils/array';
import convertToInnovatorToUpdate from 'utils/profile/convertToInnovatorToUpdate';
import {
  getDefaultPreferredWorkLocationJapanPrefectureIdsValue,
  getDefaultPreferredOccupationIdsValue,
  getDefaultDttScoreValue,
  getDefaultNewGraduateProgrammingExperiencesValue,
  getDefaultOutputsValue,
  getDefaultCertificationsValue,
  getDefaultNewGraduatePreferredBusinessTypesValue,
} from 'utils/profile/defaultFormValues';
import {
  getPreferredWorkLocationsValueToUpdate,
  getPreferredOccupationIdsValueToUpdate,
  getOutputsValueToUpdate,
  getCertificationsValueToUpdate,
  getNewGraduateProgrammingExpericenceValueToUpdate,
  getDttScoreToUpdate,
  getDttRankIdToUpdate,
  getNewGraduatePreferredWorkStyleIdToUpdate,
  getNewGraduatePreferredBusinessTypeIdsValueToUpdate,
} from 'utils/profile/formValuesToUpdate';
import {
  englishSkillIdSchema,
  preferredWorkLocationJapanPrefectureIdSchema,
  preferredOccupationsSchema,
  dttScoreSchema,
  dttRankIdSchema,
  newGraduateProgrammingExperiencesSchema,
  outputsSchema,
  newGraduatePreferredWorkStyleIdSchema,
  newGraduatePreferredBusinessTypesSchema,
} from 'utils/profile/schema';
import useErrorNotification from 'hooks/useErrorNotification';
import usePreferredOccupationsFormField from 'hooks/profile/usePreferredOccupationsFormField';
import useDttFormField from 'hooks/profile/useDttFormField';
import useNewGraduateProgrammingExperiencesFormField from 'hooks/profile/useNewGraduateProgrammingExperiencesFormField';
import useNewGraduatePreferredBusinessTypesFormField from 'hooks/profile/useNewGraduatePreferredBusinessTypesFormField';

const schema = object().shape({
  englishSkillId: englishSkillIdSchema,
  preferredWorkLocationJapanPrefectureIds: preferredWorkLocationJapanPrefectureIdSchema,
  preferredOccupations: preferredOccupationsSchema,
  outputs: outputsSchema,
  dttScore: dttScoreSchema,
  dttRankId: dttRankIdSchema,
  newGraduate: object().shape({
    preferredWorkStyleId: newGraduatePreferredWorkStyleIdSchema,
    programmingExperiences: newGraduateProgrammingExperiencesSchema,
    preferredBusinessTypes: newGraduatePreferredBusinessTypesSchema,
  }),
});

const defaultValues: NewGraduateProfileSkillsAndPreferredConditionsFormValues = {
  englishSkillId: NO_SELECT_NUMBER,
  preferredWorkLocationJapanPrefectureIds: range(
    0,
    MAX_PREFERRED_WORK_LOCATIONS - 1,
  ).map(() => NO_SELECT_NUMBER),
  preferredOccupations: [],
  certifications: [],
  dttRankId: NO_SELECT_NUMBER,
  dttScore: '',
  outputs: [],
  newGraduate: {
    programmingExperiences: [],
    preferredWorkStyleId: NO_SELECT_NUMBER,
    preferredBusinessTypes: [],
  },
};

type Payload = {
  formItems: ProfileFormItems;
  onShowContent: () => void;
  onOpenCompletedModal: () => void;
};

const useCareerProfileSkillsAndPreferredConditionsForm = ({
  formItems,
  onShowContent,
  onOpenCompletedModal,
}: Payload) => {
  const dispatch = useDispatch();
  const { handleRequestError } = useErrorNotification();

  const innovator = useSelector(getInnovator);

  const innovatorToUpdate = useMemo(
    () => innovator && convertToInnovatorToUpdate(innovator),
    [innovator],
  );

  const formMethods = useForm<
    NewGraduateProfileSkillsAndPreferredConditionsFormValues
  >({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues,
  });

  const { reset } = formMethods;

  const { preferredOccupationsError } = usePreferredOccupationsFormField({
    formMethods,
  });

  const {
    newGraduateDttScoreError,
    newGraduateRequiredAllDttFieldsError,
  } = useDttFormField({
    formMethods,
  });

  const {
    newGraduateRequiredAllProgrammingExperienceFieldsErrors,
  } = useNewGraduateProgrammingExperiencesFormField({
    formMethods,
  });

  const {
    newGraduatePreferredBusinessTypesError,
  } = useNewGraduatePreferredBusinessTypesFormField({
    formMethods,
  });

  const onSubmit = useCallback(
    async (
      values: NewGraduateProfileSkillsAndPreferredConditionsFormValues,
    ) => {
      const valuesToUpdate: UpdateInnovatorRequest = {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        ...innovatorToUpdate!,
        englishSkillId: values.englishSkillId,
        certifications: getCertificationsValueToUpdate(values.certifications),
        preferredWorkLocations: getPreferredWorkLocationsValueToUpdate(
          values.preferredWorkLocationJapanPrefectureIds,
        ),
        preferredOccupationIds: getPreferredOccupationIdsValueToUpdate(
          values.preferredOccupations,
        ),
        dttScore: getDttScoreToUpdate(values.dttScore),
        dttRankId: getDttRankIdToUpdate(values.dttRankId),
        outputs: getOutputsValueToUpdate(values.outputs),
        newGraduate: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          ...innovatorToUpdate!.newGraduate!,
          programmingExperiences: getNewGraduateProgrammingExpericenceValueToUpdate(
            values.newGraduate.programmingExperiences,
          ),
          preferredWorkStyleId: getNewGraduatePreferredWorkStyleIdToUpdate(
            values.newGraduate.preferredWorkStyleId,
          ),
          preferredBusinessTypeIds: getNewGraduatePreferredBusinessTypeIdsValueToUpdate(
            values.newGraduate.preferredBusinessTypes,
          ),
        },
      };
      let data;
      try {
        ({ data } = await apiInnovator.updateInnovator(valuesToUpdate));
      } catch (error: unknown) {
        if (error instanceof RequestError) {
          dispatch(UiActions.setLoading(false));
          handleRequestError(error);
        }
        return;
      }
      dispatch(AccountActions.setInnovator(data.innovator));
      dispatch(UiActions.setLoading(false));
      onShowContent();
      onOpenCompletedModal();
    },
    [
      dispatch,
      handleRequestError,
      innovatorToUpdate,
      onOpenCompletedModal,
      onShowContent,
    ],
  );

  // Set default values by the fetched innovator
  useEffect(() => {
    if (!innovatorToUpdate || !innovator) return;

    const values: NewGraduateProfileSkillsAndPreferredConditionsFormValues = {
      englishSkillId: innovatorToUpdate.englishSkillId,
      preferredWorkLocationJapanPrefectureIds: getDefaultPreferredWorkLocationJapanPrefectureIdsValue(
        innovatorToUpdate.preferredWorkLocations,
      ),
      preferredOccupations: getDefaultPreferredOccupationIdsValue(
        innovatorToUpdate.preferredOccupationIds,
      ),
      certifications: getDefaultCertificationsValue(
        innovatorToUpdate.certifications,
      ),
      dttScore: getDefaultDttScoreValue(innovatorToUpdate.dttScore),
      dttRankId: innovatorToUpdate.dttRankId ?? NO_SELECT_NUMBER,
      outputs: getDefaultOutputsValue(innovatorToUpdate.outputs),
      newGraduate: {
        programmingExperiences: getDefaultNewGraduateProgrammingExperiencesValue(
          innovatorToUpdate?.newGraduate?.programmingExperiences,
        ),
        preferredWorkStyleId:
          innovatorToUpdate.newGraduate?.preferredWorkStyleId ??
          NO_SELECT_NUMBER,
        preferredBusinessTypes: getDefaultNewGraduatePreferredBusinessTypesValue(
          innovatorToUpdate.newGraduate?.preferredBusinessTypeIds,
        ),
      },
    };

    reset(values);
  }, [formItems.countries, innovator, innovatorToUpdate, reset]);

  return {
    innovator,
    onSubmit,
    formMethods,
    formItems,
    newGraduateDttScoreError,
    newGraduateRequiredAllDttFieldsError,
    newGraduateRequiredAllProgrammingExperienceFieldsErrors,
    newGraduatePreferredBusinessTypesError,
    preferredOccupationsError,
  };
};

export default useCareerProfileSkillsAndPreferredConditionsForm;
