import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import apiFirebase from 'external/firebase/firebase';
import { useDispatch } from 'react-redux';
import { MatterActions } from 'modules/matter';
import { NotificationActions } from 'modules/notification';
import { UiActions } from 'modules/ui';
import * as PUBLIC_ROUTES from 'routes/constants/public';

const useLogout = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const logout = useCallback(async () => {
    await apiFirebase.logout();
    dispatch(MatterActions.initMatterStatus());
    dispatch(NotificationActions.clearNotification());
    dispatch(UiActions.setIsSidebarShrinked(false));
    history.push(PUBLIC_ROUTES.LOGIN);
  }, [dispatch, history]);

  return {
    logout,
  };
};

export default useLogout;
