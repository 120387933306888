import React, { FC } from 'react';
import * as MESSAGES from 'constants/messages';
import { Label, ProfileField, ProfileContent } from 'components/atoms';
import { CareerJobHistory } from 'types/profile';

type NameFieldProps = {
  name: CareerJobHistory['name'];
};

const NameField: FC<NameFieldProps> = props => {
  const { name } = props;

  return (
    <ProfileField data-testid="career-job-name-field">
      <Label>{MESSAGES.PROFILE.FIELD.CAREER_JOB.NAME.LABEL}</Label>
      <ProfileContent>{name}</ProfileContent>
    </ProfileField>
  );
};

export default NameField;
