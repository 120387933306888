import { useState, useEffect, useCallback, useRef } from 'react';

const useLongPress = (onLongPress = () => {}, ms = 1000) => {
  const [isLongPressing, setStartLongPress] = useState(false);
  const timerIdRef = useRef<number | null>(null);

  const clear = useCallback(() => {
    timerIdRef.current && clearTimeout(timerIdRef.current);
  }, []);

  useEffect(() => {
    if (isLongPressing) {
      timerIdRef.current = setTimeout(onLongPress, ms);
    } else {
      clear();
    }
    return () => {
      clear();
    };
  }, [clear, ms, onLongPress, isLongPressing]);

  const start = useCallback(() => {
    setStartLongPress(true);
  }, []);

  const stop = useCallback(() => {
    setStartLongPress(false);
  }, []);

  return {
    handleMouseDown: start,
    handleMouseUp: stop,
    handleMouseLeave: stop,
    handleTouchStart: start,
    handleTouchEnd: stop,
  };
};

export default useLongPress;
