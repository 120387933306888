import React, { FC, HTMLAttributes } from 'react';
import styled from 'styled-components/macro';
import theme from 'styles/theme';
import { CirclePlus } from 'assets/svg';
import * as MESSAGES from 'constants/messages';

const Content = styled.div`
  display: flex;
  align-items: center;
  color: ${theme.basePrimary};
  font-size: 12px;
  line-height: 1.5;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const IconAdd = styled(CirclePlus)`
  margin-right: 4px;
`;

const AddField: FC<HTMLAttributes<HTMLSpanElement>> = props => (
  <Content {...props}>
    <IconAdd />
    {MESSAGES.COMMON.BUTTON.ADD_FIELD}
  </Content>
);

export default AddField;
