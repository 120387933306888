import styled from 'styled-components/macro';
import media from 'styles/media';
import theme from 'styles/theme';

const GroupLabel = styled.p`
  margin-bottom: 12px;
  color: ${theme.basePrimary};
  font-size: 20px;
  font-weight: bold;
  line-height: 1.5;

  ${media.mobile} {
    margin-bottom: 8px;
    font-size: 16px;
  }
`;

export default GroupLabel;
