import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAccountId } from 'modules/account';
import { NotificationActions } from 'modules/notification';
import { DocumentChange } from 'firebase/firestore';
import apiFirebase from 'external/firebase/firebase';

const NOTIFICATION_TYPE_MESSAGE = 'message';
const NOTIFICATION_TYPE_UPDATE_MESSAGE = 'update_message';
const NOTIFICATION_TYPE_DELETED_MESSAGE = 'deleted_message';

const isUpdatedChanges = (changes: DocumentChange[]) =>
  changes.some(change => change.type === 'modified');

const useNotification = () => {
  const dispatch = useDispatch();
  const accountId = useSelector(getAccountId);

  const subscribeUpdateMessage = useCallback(
    (updateMessage: () => void) => {
      if (!accountId) return;
      return apiFirebase.getNotifications(
        accountId,
        NOTIFICATION_TYPE_UPDATE_MESSAGE,
        changes => {
          if (changes.length === 0 || !isUpdatedChanges(changes)) return;
          return updateMessage();
        },
      );
    },
    [accountId],
  );

  const subscribeDeleteMessage = useCallback(
    (handler: (changes: DocumentChange[]) => void) => {
      if (!accountId) return;
      return apiFirebase.getNotifications(
        accountId,
        NOTIFICATION_TYPE_DELETED_MESSAGE,
        changes => {
          if (changes.length === 0) return;
          handler(changes);
        },
      );
    },
    [accountId],
  );

  const subscribeNotification = useCallback(() => {
    if (!accountId) return;
    return apiFirebase.getNotifications(
      accountId,
      NOTIFICATION_TYPE_MESSAGE,
      changes => {
        if (changes.length === 0) return;
        dispatch(NotificationActions.setMessageNotifications(changes));
      },
    );
  }, [dispatch, accountId]);

  return {
    subscribeNotification,
    subscribeUpdateMessage,
    subscribeDeleteMessage,
  };
};

export default useNotification;
