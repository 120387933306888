import React, { FC } from 'react';
import * as MESSAGES from 'constants/messages';
import { Label, ProfileField, ProfileContent } from 'components/atoms';
import { CareerProfile } from 'proto/v1/apimodel/apimodel';

type CareerAchievementFieldProps = {
  achievement: CareerProfile['achievement'] | undefined;
};

const CareerAchievementField: FC<CareerAchievementFieldProps> = props => {
  const { achievement } = props;

  return (
    <ProfileField data-testid="career-achievement-field">
      <Label>{MESSAGES.PROFILE.FIELD.CAREER_ACHIEVEMENT.LABEL}</Label>
      <ProfileContent>{achievement}</ProfileContent>
    </ProfileField>
  );
};

export default CareerAchievementField;
