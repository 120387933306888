import React, { useState, useCallback, useRef, useEffect } from 'react';
import styled from 'styled-components/macro';
import theme from 'styles/theme';
import media from 'styles/media';
import { MissionJobOffer } from 'proto/v1/apimodel/apimodel';
import { CSSTransition } from 'react-transition-group';
import { ChevronUpGray, ChevronDownGray } from 'assets/svg';

/* stylelint-disable value-no-vendor-prefix, property-no-vendor-prefix */

const JOB_DESCRIPTION_HEIGHT = 52;
const DEFAULT_JOB_DESCRIPTION_MAX_HEIGHT = 320;

const Card = styled.div`
  position: relative;
  padding: 18px 20px 16px 20px;
  border: 1px solid ${theme.borderTable};
  border-radius: 5px;
  background-color: ${theme.baseWhite};
`;

const Occupation = styled.div`
  position: relative;
  margin-bottom: 10px;
  padding-left: 8px;
  color: ${theme.textPrimary};
  font-size: 16px;
  font-weight: bold;
  line-height: 1;

  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 1px;
    left: 0;
    width: 2px;
    height: 13px;
    border-radius: 1px;
    background-color: ${theme.textCardSecondary};
  }

  ${media.miniMobile} {
    font-size: 14px;
  }
`;

const ReadMoreIconContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ReadMoreIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  ${media.tablet} {
    width: 100%;
  }
`;

const JobDescription = styled.div<{ maxHeight: number }>`
  display: -webkit-box;
  min-height: ${JOB_DESCRIPTION_HEIGHT}px;
  max-height: ${JOB_DESCRIPTION_HEIGHT}px;
  overflow: hidden;
  transition: all 300ms 0s ease;
  color: ${theme.textPrimary};
  font-size: 14px;
  line-height: 26px;
  text-overflow: ellipsis;
  word-wrap: break-word;
  word-break: break-word;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  &.enter {
    display: block;
    max-height: ${({ maxHeight }) => maxHeight}px;
  }

  &.enter-done {
    display: block;
    max-height: ${({ maxHeight }) => maxHeight}px;
  }

  &.exit {
    -webkit-box-orient: inherit;
  }

  &.exit-done {
    -webkit-box-orient: vertical;
  }
`;

const JobDescriptionInvisibleContainer = styled.div`
  visibility: hidden;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  height: 0;
  overflow: auto;
`;

const JobDescriptionToCheckTextHeight = styled.div`
  width: 432px;
  font-size: 14px;
  line-height: 26px;
  word-wrap: break-word;
  word-break: break-word;
  white-space: pre-wrap;
  overflow-wrap: break-word;

  ${media.tablet} {
    width: calc(100% - 40px);
  }
`;

type Props = {
  missionJobOffer: MissionJobOffer;
};

const MissionJobOfferCard: React.VFC<Props> = ({ missionJobOffer }) => {
  const [isExpandedJobDescription, setIsExpandedJobDescription] = useState<
    boolean
  >(false);
  const [showReadMoreIcon, setShowReadMoreIcon] = useState<boolean>(false);

  const jobDescriptionToCheckTextHeightRef = useRef<HTMLDivElement>(null);

  const toggleJobDescription = useCallback(() => {
    setIsExpandedJobDescription(prev => !prev);
  }, []);

  // Check whether the read more icon should be shown.
  useEffect(() => {
    setShowReadMoreIcon(
      !!jobDescriptionToCheckTextHeightRef.current &&
        jobDescriptionToCheckTextHeightRef.current.clientHeight >
          JOB_DESCRIPTION_HEIGHT,
    );
  }, []);

  return (
    <Card data-testid="mission-job-offer">
      <Occupation>{missionJobOffer.occupation?.name}</Occupation>
      <CSSTransition in={isExpandedJobDescription} timeout={300}>
        <JobDescription
          maxHeight={
            jobDescriptionToCheckTextHeightRef.current?.clientHeight ??
            DEFAULT_JOB_DESCRIPTION_MAX_HEIGHT
          }
        >
          {missionJobOffer.jobDescription}
        </JobDescription>
      </CSSTransition>
      {showReadMoreIcon && (
        <ReadMoreIconContainer>
          <ReadMoreIcon onClick={toggleJobDescription}>
            {isExpandedJobDescription ? <ChevronUpGray /> : <ChevronDownGray />}
          </ReadMoreIcon>
        </ReadMoreIconContainer>
      )}

      <JobDescriptionInvisibleContainer>
        <JobDescriptionToCheckTextHeight
          ref={jobDescriptionToCheckTextHeightRef}
        >
          {missionJobOffer.jobDescription}
        </JobDescriptionToCheckTextHeight>
      </JobDescriptionInvisibleContainer>
    </Card>
  );
};

export default MissionJobOfferCard;
