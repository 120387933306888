import React, { FC, HTMLAttributes } from 'react';
import { MissionForInnovator } from 'types/mission';
import * as MESSAGES from 'constants/messages';
import { SubTitle } from '../../Typography';

type Props = {
  corporateAttributes: MissionForInnovator['corporateAttributes'] | undefined;
} & HTMLAttributes<HTMLDivElement>;

const CorporateAttributesField: FC<Props> = ({
  corporateAttributes,
  ...rest
}) => {
  return (
    <div {...rest}>
      <SubTitle>
        {MESSAGES.MISSION_CARD.FIELD.CORPORATE_ATTRIBUTES.LABEL}
      </SubTitle>
      <p>
        {corporateAttributes?.length ? corporateAttributes[0].nameShort : '-'}
      </p>
    </div>
  );
};

export default CorporateAttributesField;
