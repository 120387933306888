import React, { FC } from 'react';
import Linkify from 'react-linkify';
import styled from 'styled-components/macro';
import theme from 'styles/theme';

type ColorType = 'default' | 'secondary';

const switchColor = (color?: ColorType) => {
  switch (color) {
    case 'secondary':
      return theme.textSecondary;
    default:
      return theme.baseLink;
  }
};

const StyledLink = styled.a<{ color: ColorType }>`
  color: ${({ color }) => switchColor(color)};
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
    color: ${({ color }) => switchColor(color)};
  }
`;

type Props = {
  color?: ColorType;
};

const LinkText: FC<Props> = ({ children, color = 'default' }) => {
  const componentDecorator = (href: string, text: string, key: number) => (
    <StyledLink
      color={color}
      href={href}
      key={key}
      target="_blank"
      rel="noopener noreferrer"
    >
      {text}
    </StyledLink>
  );
  return <Linkify componentDecorator={componentDecorator}>{children}</Linkify>;
};

export default LinkText;
