import React, { FC } from 'react';
import styled from 'styled-components/macro';
import theme from 'styles/theme';
import Card from './Card';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledCard = styled(Card)`
  width: 100%;
  max-width: 468px;
  color: ${theme.textSecondary};
`;

const PublicContentCard: FC = props => {
  return (
    <Wrapper>
      <StyledCard>{props.children}</StyledCard>
    </Wrapper>
  );
};

export default PublicContentCard;
