export default {
  TITLE: 'プロフィール登録',
  STEP: {
    INPUT_PROFILE1: 'プロフィール\n入力1',
    INPUT_PROFILE2: 'プロフィール\n入力2',
    INPUT_PROFILE3: 'プロフィール\n入力3',
    CONFIRM_INPUTS: '入力内容\n確認',
    REGISTRATION_COMPLETED: '登録完了',
  },
  INFORMATION:
    'ご希望にマッチした求人情報を案内させていただくために、プロフィール情報をご入力ください。',
  INFORMATION_NEW_GRADUATE:
    'プロフィール情報を充実させると企業からのスカウトが受けやすくなります。',
  INFORMATION_SEND_BACK:
    'メールにてご連絡させていただいている内容をご確認の上、改めてプロフィールをご登録ください。',
  COMPLETED: {
    MAIN: '登録完了',
    DESCRIPTION:
      'プロフィールの登録が完了しました。\n会員様にふさわしい求人をお届けするために、ご登録いただいた内容を確認させていただきます。\n詳細は、後日メッセージにてご連絡させていただきます。',
    DESCRIPTION_NEW_GRADUATE:
      'プロフィールの登録が完了しました。\nご登録いただいた内容を確認させていただきます。',
    LABEL_MEMBER_NUMBER: '登録会員番号: ',
  },
  BUTTON: {
    SAVE_AND_GO_TO_NEXT: '保存して次へ',
    GO_TO_PREV: '前へ',
    FIX: '修正する',
    REGISTER: '登録する',
    GO_TO_PUBLISH_SETTING: '公開先設定へ',
    GO_TO_PROFILE_PAGE: 'TOPへ',
  },
  MODAL: {
    SEND_BACK: {
      TITLE: '再度プロフィール登録をしてください',
      CONTENT:
        'メールにてご連絡させていただいている内容をご確認の上、改めてプロフィールをご登録ください。',
    },
  },
};
