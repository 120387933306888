import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { ProfileFormItems } from 'types/profile';
import * as MESSAGES from 'constants/messages';
import { isEqual } from 'utils/object';
import { CareerProfileToUpdate } from 'proto/v1/innovatorservice/innovatorservice';
import media from 'styles/media';
import { Form, ButtonM } from 'components/atoms';
import {
  CareerNumberOfJobChangesFormField,
  CareerJobFormField,
} from 'components/organisms';
import useCareerProfileJobHistoryForm from './useCareerProfileJobHistoryForm';

const Fields = styled.div``;

const Buttons = styled.div`
  display: grid;
  grid-column-gap: 16px;
  grid-template-columns: auto auto;

  ${media.smallMobile} {
    grid-row-gap: 16px;
    grid-template-columns: 1fr;
  }
`;

const CancelButton = styled(ButtonM)`
  justify-self: end;

  ${media.smallMobile} {
    order: 2;
    width: 100%;
  }
`;

const SubmitButton = styled(ButtonM)`
  ${media.smallMobile} {
    order: 1;
    width: 100%;
  }
`;

type CareerProfileJobHistoryFormProps = {
  formItems: ProfileFormItems;
  onShowContent: () => void;
  onOpenCompletedModal: () => void;
};

const CareerProfileJobHistoryForm: FC<CareerProfileJobHistoryFormProps> = props => {
  const { formItems, onShowContent, onOpenCompletedModal } = props;

  const {
    formMethods,
    onSubmit,
    handleChangeEndWorkingPeriod,
  } = useCareerProfileJobHistoryForm({
    formItems,
    onShowContent,
    onOpenCompletedModal,
  });

  const {
    handleSubmit,
    errors,
    register,
    formState,
    watch,
    control,
    trigger,
  } = formMethods;

  const { isValid, isSubmitting, isDirty, touched } = formState;

  const isDisabledSubmitButton = !isValid || isSubmitting || !isDirty;

  const watchCareerNumberOfJobChangesId: CareerProfileToUpdate['numberOfJobChangesId'] = watch(
    'career.numberOfJobChangesId',
  );

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      data-testid="profile-career-job-history-form"
    >
      <Fields>
        <CareerNumberOfJobChangesFormField
          error={
            touched.career?.numberOfJobChangesId &&
            errors?.career?.numberOfJobChangesId?.message
          }
          numberOfJobChanges={formItems.numberOfJobChanges}
          control={control}
        />

        <CareerJobFormField
          numberOfJobChanges={watchCareerNumberOfJobChangesId}
          errors={errors.career?.job}
          touched={touched.career?.job}
          occupations={formItems.occupations}
          positions={formItems.positions}
          yearsOfExperiences={formItems.yearsOfExperiences}
          onChangeEndWorkingPeriod={handleChangeEndWorkingPeriod}
          register={register}
          control={control}
          trigger={trigger}
        />
      </Fields>

      <Buttons>
        <CancelButton buttonTheme="cancel" onClick={onShowContent}>
          {MESSAGES.PROFILE_SETTING.BUTTON.CANCEL}
        </CancelButton>

        <SubmitButton type="submit" disabled={isDisabledSubmitButton}>
          {MESSAGES.PROFILE_SETTING.BUTTON.SAVE}
        </SubmitButton>
      </Buttons>
    </Form>
  );
};

export default React.memo(CareerProfileJobHistoryForm, isEqual);
