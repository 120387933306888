import React, { FC, ComponentProps } from 'react';
import styled from 'styled-components/macro';
import BaseButton from './BaseButton';

const StyledButtonL = styled(BaseButton)<ComponentProps<typeof BaseButton>>`
  min-width: 200px;
  height: 48px;
  padding: 6px 14px;
  font-size: 14px;
  text-align: center;
`;

const ButtonL: FC<ComponentProps<typeof BaseButton>> = props => (
  <StyledButtonL {...props}>{props.children}</StyledButtonL>
);

export default ButtonL;
