import React, { FC, ComponentProps } from 'react';
import * as MESSAGES from 'constants/messages';
import { ButtonM } from 'components/atoms';
import { Modal, ModalContent, ModalFooter } from 'components/molecules';

type Props = ComponentProps<typeof Modal> & {
  title?: string;
};

const ModalAlert: FC<Props> = props => {
  const { title, closeModal, children } = props;

  return (
    <Modal {...props}>
      <ModalContent title={title} data-testid="modal-alert">
        {children}
      </ModalContent>
      <ModalFooter>
        <ButtonM
          onClick={closeModal}
          buttonTheme="cancel"
          data-testid="modal-alert-button-ok"
        >
          {MESSAGES.COMMON.BUTTON.CLOSE}
        </ButtonM>
      </ModalFooter>
    </Modal>
  );
};

export default ModalAlert;
