import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UiActions } from 'modules/ui';
import { AccountActions } from 'modules/account';
import { MATTER_STATUS_ID } from 'constants/matter';
import apiInnovator from 'external/api/innovator';
import { getMatterStatus } from 'modules/matter';
import { scrollLayoutBodyToTop } from 'utils/layout';
import RequestError from 'classes/RequestError';
import { Stepper } from 'hooks/useStepper';
import useErrorNotification from 'hooks/useErrorNotification';
import useReloadMatterStatus from 'hooks/useReloadMatterStatus';
import useFetchInnovatorForm from 'hooks/profile/useFetchInnovatorForm';

const useRegisterProfileConfirmCareer = ({ stepper }: { stepper: Stepper }) => {
  const { innovator } = useFetchInnovatorForm();
  const dispatch = useDispatch();
  const { statusId } = useSelector(getMatterStatus);
  const { handleRequestError } = useErrorNotification();
  const { reloadMatterStatus } = useReloadMatterStatus();

  const resisterProfile = useCallback(async () => {
    dispatch(UiActions.setLoading(true));
    let data;
    try {
      await apiInnovator.sendPublishRequest({
        innovatorId: innovator?.innovatorId ?? '',
      });
      if (statusId === MATTER_STATUS_ID.NONE) {
        ({ data } = await apiInnovator.createChatRoom());
      }
      await reloadMatterStatus();
    } catch (error: unknown) {
      if (error instanceof RequestError) {
        dispatch(UiActions.setLoading(false));
        handleRequestError(error);
      }
      return;
    }
    dispatch(UiActions.setLoading(false));
    scrollLayoutBodyToTop();
    if (data?.chatRoomId) {
      dispatch(AccountActions.setChatRoomId(data?.chatRoomId));
    }
    stepper.handleNext();
  }, [
    dispatch,
    handleRequestError,
    innovator,
    reloadMatterStatus,
    statusId,
    stepper,
  ]);

  const handleClickPrevButton = useCallback(() => {
    scrollLayoutBodyToTop();
    stepper.handlePrev();
  }, [stepper]);

  return {
    innovator,
    resisterProfile,
    handleClickPrevButton,
  };
};

export default useRegisterProfileConfirmCareer;
