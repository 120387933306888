import React, {
  useState,
  useEffect,
  useCallback,
  FC,
  ComponentProps,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { onAuthStateChanged } from 'firebase/auth';
import { Route, useHistory, useLocation } from 'react-router-dom';
import * as PUBLIC_ROUTES from 'routes/constants/public';
import { RELOAD_MATTER_STATUS_INTERVAL_MS } from 'constants/config';
import { AccountActions } from 'modules/account';
import { UiActions } from 'modules/ui';
import { ConfigActions, getAfterLoginUrl } from 'modules/config';
import useNotification from 'hooks/useNotification';
import apiAccount from 'external/api/account';
import apiInnovator from 'external/api/innovator';
import apiFirebase from 'external/firebase/firebase';
import { PrivateRouteLayout } from 'components/templates';
import useReloadMatterStatus from 'hooks/useReloadMatterStatus';
import { configureScope } from '@sentry/react';
import { GetInnovatorAccountResponse } from 'proto/v1/accountservice/accountservice';
import { GetPublishSettingResponse } from 'proto/v1/innovatorservice/innovatorservice';

type Props = ComponentProps<typeof Route> &
  ComponentProps<typeof PrivateRouteLayout>;

const PrivateRoute: FC<Props> = props => {
  const { path, exact, component, title } = props;
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const afterLoginUrl = useSelector(getAfterLoginUrl);
  const [isFetchedMatterStatus, setIsFetchedMatterStatus] = useState<boolean>(
    false,
  );
  const { reloadMatterStatus } = useReloadMatterStatus();
  const { subscribeNotification } = useNotification();

  const fetchAccount = useCallback(async () => {
    dispatch(UiActions.setLoading(true));
    let accountData: GetInnovatorAccountResponse;
    let publishData: GetPublishSettingResponse;
    try {
      [{ data: accountData }, { data: publishData }] = await Promise.all([
        apiAccount.getInnovatorAccount(),
        apiInnovator.getPublishSetting(),
      ]);
    } catch (error) {
      dispatch(UiActions.setLoading(false));
      history.push(PUBLIC_ROUTES.LOGIN);
      return;
    }
    dispatch(AccountActions.setInnovator(accountData.innovator));
    dispatch(AccountActions.setAccountId(accountData.accountId));
    dispatch(AccountActions.setChatRoomId(accountData.chatRoomId));
    dispatch(AccountActions.setIsPublished(publishData.publish));
    dispatch(UiActions.setLoading(false));
    setIsLoggedIn(true);

    configureScope(scope => {
      scope.setExtra('account', accountData);
    });
  }, [dispatch, history]);

  const fetchMatterStatus = useCallback(async () => {
    await reloadMatterStatus();
    setIsFetchedMatterStatus(true);
  }, [reloadMatterStatus]);

  // Store latest url which user accessed
  useEffect(() => {
    const currentLocationUrl = `${location.pathname}${location.search}`;
    if (currentLocationUrl !== afterLoginUrl) {
      dispatch(ConfigActions.setAfterLoginUrl(currentLocationUrl));
    }
  }, [dispatch, location, history, afterLoginUrl]);

  // Fetch account info
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(apiFirebase.auth(), user => {
      if (user) {
        fetchAccount();
        // Set user for sentry
        configureScope(scope => {
          scope.setUser({ id: user.uid });
        });
      } else {
        history.push(PUBLIC_ROUTES.LOGIN);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [fetchAccount, history]);

  // Fetch matter status first
  useEffect(() => {
    if (isLoggedIn) {
      fetchMatterStatus();
    }
  }, [isLoggedIn, fetchMatterStatus]);

  // Polling matter status
  useEffect(() => {
    let timer: number;
    const unsubscribe = onAuthStateChanged(apiFirebase.auth(), user => {
      if (user) {
        if (isFetchedMatterStatus) {
          timer = setInterval(() => {
            reloadMatterStatus();
          }, RELOAD_MATTER_STATUS_INTERVAL_MS);
        }
      } else {
        timer && clearInterval(timer);
      }
    });
    return () => {
      timer && clearInterval(timer);
      unsubscribe();
    };
  }, [isFetchedMatterStatus, reloadMatterStatus]);

  useEffect(() => {
    const unSubscribeMessageNotification = subscribeNotification();

    const unsubscribeAuthStateChanged = onAuthStateChanged(
      apiFirebase.auth(),
      user => {
        if (!user) {
          unSubscribeMessageNotification && unSubscribeMessageNotification();
        }
      },
    );

    return () => {
      unsubscribeAuthStateChanged();
      unSubscribeMessageNotification && unSubscribeMessageNotification();
    };
  }, [dispatch, subscribeNotification]);

  // Show private route contents after information of account and matter are fetched.
  if (!isLoggedIn || !isFetchedMatterStatus) return null;

  return (
    <PrivateRouteLayout title={title}>
      <Route path={path} exact={exact} component={component} />
    </PrivateRouteLayout>
  );
};

export default PrivateRoute;
