import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { useFieldArray } from 'react-hook-form';
import * as MESSAGES from 'constants/messages';
import media from 'styles/media';
import theme from 'styles/theme';
import { PROFILE_FIELDS_MAX_LENGTH, MAX_OUTPUTS } from 'constants/profile';
import {
  ProfileFormControl,
  ProfileFormRegister,
  ProfileFormMethods,
} from 'types/profile';
import { CircleCross } from 'assets/svg';
import { TextInput, AddField, ErrorMessage } from 'components/atoms';
import { Field } from 'components/molecules';

const FieldItem = styled.div`
  display: flex;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const TextInputWrapper = styled.div`
  width: 100%;
`;

const StyleTextInput = styled(TextInput)`
  width: 100%;
`;

const StyledField = styled(Field)`
  ${media.mobile} {
    h5 {
      margin-bottom: 4px;
    }

    .field-note {
      display: block;
      margin-bottom: 4px;
      margin-left: 0;
      line-height: 1.5;
    }
  }
`;

const RemoveOutput = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  padding-top: 12px;

  ${media.mobile} {
    align-self: flex-end;
    height: 40px;
    padding-left: 8px;
    border-left: 1px solid ${theme.borderDefault};
  }
`;

const IconRemove = styled(CircleCross)`
  display: inline-block;
  align-self: flex-start;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

type OutputsFormFieldProps = {
  register: ProfileFormRegister<'outputs'>;
  control: ProfileFormControl<'outputs'>;
  errors: ProfileFormMethods<'outputs'>['errors']['outputs'];
  touched: ProfileFormMethods<'outputs'>['formState']['touched']['outputs'];
};

const OutputsFormField: FC<OutputsFormFieldProps> = props => {
  const { register, control, errors, touched } = props;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'outputs',
  });

  return (
    <StyledField
      label={MESSAGES.PROFILE.FIELD.OUTPUTS.LABEL}
      note={MESSAGES.PROFILE.FIELD.OUTPUTS.NOTE}
      locked
    >
      {fields.map((field, index) => (
        <FieldItem key={field.id} data-testid="output-field">
          <TextInputWrapper>
            <StyleTextInput
              enterKeyHint="enter"
              name={`outputs[${index}].value`}
              error={touched?.[index]?.value && errors?.[index]?.value?.message}
              noErrorMessage // Don't use TextInput error message because the error message hides under the next output TextInput.
              maxLength={PROFILE_FIELDS_MAX_LENGTH.OUTPUT}
              placeholder={MESSAGES.PROFILE.FIELD.OUTPUTS.PLACEHOLDER}
              aria-label={MESSAGES.PROFILE.FIELD.OUTPUTS.LABEL}
              defaultValue={field.value}
              ref={register()}
            />
            {touched?.[index]?.value && errors?.[index]?.value?.message && (
              <ErrorMessage
                style={{ marginTop: '4px' }}
                data-testid="output-error-message"
              >
                {errors?.[index]?.value?.message}
              </ErrorMessage>
            )}
          </TextInputWrapper>

          {index > 0 && (
            <RemoveOutput>
              <IconRemove
                onClick={() => remove(index)}
                data-testid="remove-output"
              />
            </RemoveOutput>
          )}
        </FieldItem>
      ))}
      {fields.length < MAX_OUTPUTS && (
        <AddField onClick={() => append({})} data-testid="add-output" />
      )}
    </StyledField>
  );
};

export default OutputsFormField;
