import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components/macro';
import * as MESSAGES from 'constants/messages';
import media from 'styles/media';
import { Stepper } from 'hooks/useStepper';
import { ButtonM } from 'components/atoms';
import {
  NameField,
  NameKanaField,
  YearMonthOfBirthField,
  PhoneNumberField,
  JapanPrefectureField,
  AcademicBackgroundSchoolField,
  AcademicBackgroundFacultyField,
  AcademicBackgroundDepartmentField,
  AcademicBackgroundYearMonthOfGraduationField,
  OutputsField,
  EnglishSkillField,
  CertificationsField,
  PreferredWorkLocationsField,
  PreferredOccupationsField,
  NewGraduateResearchRoomsField,
  DttScoreField,
  DttRankField,
  NewGraduateProgrammingExperiencesField,
  NewGraduatePreferredWorkingStyleField,
  NewGraduatePreferredBusinessTypesField,
  NewGraduatePastExperienceAchievementsField,
  NewGraduateInternshipExperiencesField,
  NewGraduateStudyAbroadExperiencesField,
  NewGraduateClubExperiencesField,
  NewGraduateSelfIntroductionField,
} from 'components/organisms';
import useRegisterProfileConfirm from '../useRegisterProfileConfirm';

const Fields = styled.div``;

const Buttons = styled.div`
  display: grid;
  grid-column-gap: 16px;
  grid-template-columns: auto auto;

  ${media.smallMobile} {
    grid-row-gap: 16px;
    grid-template-columns: 1fr;
  }
`;

const FixButton = styled(ButtonM)`
  justify-self: end;

  ${media.smallMobile} {
    order: 2;
    width: 100%;
  }
`;

const RegisterButton = styled(ButtonM)`
  ${media.smallMobile} {
    order: 1;
    width: 100%;
  }
`;

type RegisterProfileConfirmNewGraduateProps = {
  stepper: Stepper;
};

const RegisterProfileConfirmNewGraduate: FC<RegisterProfileConfirmNewGraduateProps> = props => {
  const { stepper } = props;
  const {
    innovator,
    resisterProfile,
    handleClickPrevButton,
  } = useRegisterProfileConfirm({
    stepper,
  });

  if (!innovator) return null;

  return (
    <>
      <Helmet>
        <title>{MESSAGES.HEAD.TITLE.RESISTER_PROFILE.CONFIRM}</title>
      </Helmet>
      <div data-testid="profile-confirm-new-graduate">
        <Fields>
          <NameField
            lastName={innovator.lastName}
            firstName={innovator.firstName}
          />

          <NameKanaField
            lastNameKana={innovator.lastNameKana}
            firstNameKana={innovator.firstNameKana}
          />

          <YearMonthOfBirthField
            yearOfBirth={innovator.yearOfBirth}
            monthOfBirth={innovator.monthOfBirth}
          />

          <PhoneNumberField
            phoneCountryCode={innovator.phoneCountryCode}
            phoneNumber={innovator.phoneNumber}
          />

          <JapanPrefectureField japanPrefecture={innovator.japanPrefecture} />

          <AcademicBackgroundSchoolField
            label={
              MESSAGES.PROFILE.FIELD.ACADEMIC_BACKGROUND_SCHOOL
                .LABEL_NEW_GRADUATE
            }
            academicBackground={innovator.academicBackground}
          />

          <AcademicBackgroundFacultyField
            label={
              MESSAGES.PROFILE.FIELD.ACADEMIC_BACKGROUND_FACULTY
                .LABEL_NEW_GRADUATE
            }
            academicBackground={innovator.academicBackground}
          />

          <AcademicBackgroundDepartmentField
            academicBackground={innovator.academicBackground}
          />

          <AcademicBackgroundYearMonthOfGraduationField
            label={
              MESSAGES.PROFILE.FIELD
                .ACADEMIC_BACKGROUND_YEAR_MONTH_OF_GRADUATION.LABEL_NEW_GRADUATE
            }
            yearOfGraduation={innovator.academicBackground?.yearOfGraduation}
            monthOfGraduation={innovator.academicBackground?.monthOfGraduation}
          />

          <NewGraduateResearchRoomsField
            researchRooms={innovator.newGraduate?.researchRooms}
          />

          <EnglishSkillField englishSkill={innovator.englishSkill} />

          <CertificationsField certifications={innovator.certifications} />

          <DttScoreField dttScore={innovator.dttScore} />

          <DttRankField dttRank={innovator.dttRank} />

          <NewGraduateProgrammingExperiencesField
            programmingExperiences={
              innovator.newGraduate?.programmingExperiences
            }
          />

          <OutputsField outputs={innovator.outputs} />

          <NewGraduatePreferredWorkingStyleField
            preferredWorkStyle={innovator.newGraduate?.preferredWorkStyle}
          />

          <NewGraduatePreferredBusinessTypesField
            preferredBusinessTypes={
              innovator.newGraduate?.preferredBusinessTypes
            }
          />

          <PreferredOccupationsField
            preferredOccupations={innovator.preferredOccupations}
          />

          <PreferredWorkLocationsField
            preferredWorkLocations={innovator.preferredWorkLocations}
          />

          <NewGraduatePastExperienceAchievementsField
            pastExperienceAchievements={
              innovator.newGraduate?.pastExperienceAchievements
            }
          />

          <NewGraduateInternshipExperiencesField
            internshipExperiences={innovator.newGraduate?.internshipExperiences}
          />

          <NewGraduateStudyAbroadExperiencesField
            studyAbroadExperiences={
              innovator.newGraduate?.studyAbroadExperiences
            }
          />

          <NewGraduateClubExperiencesField
            clubExperiences={innovator.newGraduate?.clubExperiences}
          />

          <NewGraduateSelfIntroductionField
            selfIntroduction={innovator.selfIntroduction}
          />
        </Fields>

        <Buttons>
          <FixButton buttonTheme="cancel" onClick={handleClickPrevButton}>
            {MESSAGES.REGISTER_PROFILE.BUTTON.FIX}
          </FixButton>

          <RegisterButton onClick={resisterProfile}>
            {MESSAGES.REGISTER_PROFILE.BUTTON.REGISTER}
          </RegisterButton>
        </Buttons>
      </div>
    </>
  );
};

export default RegisterProfileConfirmNewGraduate;
