import React, { HTMLAttributes } from 'react';
import * as MESSAGES from 'constants/messages';
import { MissionForInnovator } from 'types/mission';
import { ImageGallery } from 'components/molecules';
import { Title } from '../../Typography';

type Props = {
  missionOfficeImages: MissionForInnovator['missionOfficeImages'] | undefined;
} & HTMLAttributes<HTMLDivElement>;

const MissionOfficeImagesField: React.VFC<Props> = ({
  missionOfficeImages,
  ...rest
}) => {
  return (
    <div {...rest}>
      <Title>{MESSAGES.MISSION_CARD.FIELD.MISSION_OFFICE_IMAGES.LABEL}</Title>
      <ImageGallery
        images={
          missionOfficeImages
            ? missionOfficeImages.map(
                missionOfficeImage => missionOfficeImage.image,
              )
            : []
        }
      />
    </div>
  );
};

export default MissionOfficeImagesField;
