import React, { FC } from 'react';
import styled from 'styled-components/macro';
import theme from 'styles/theme';
import media from 'styles/media';

const Content = styled.div`
  display: flex;
  flex: 1 0 auto;
  justify-content: center;
  height: fit-content;
  padding-bottom: 80px;
  background-color: ${theme.bgDefault};

  ${media.desktop} {
    padding-right: 12px;
    padding-bottom: 40px;
    padding-left: 12px;
  }
`;

const ContentInner = styled.div`
  width: 100%;
  max-width: 900px;
`;

const PrivateRouteLayoutContent: FC = props => {
  return (
    <Content>
      <ContentInner>{props.children}</ContentInner>
    </Content>
  );
};

export default PrivateRouteLayoutContent;
