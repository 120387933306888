import { useMediaQuery as useReactResponsiveMediaQuery } from 'react-responsive';

const useMediaQuery = () => {
  const isMobile = useReactResponsiveMediaQuery({
    query: '(max-width: 768px)',
  });

  const isTablet = useReactResponsiveMediaQuery({
    query: '(max-width: 1024px)',
  });

  return {
    isMobile,
    isTablet,
  };
};

export default useMediaQuery;
