import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import media from 'styles/media';
import theme from 'styles/theme';
import * as MESSAGES from 'constants/messages';
import { ButtonM } from 'components/atoms';

const Wrapper = styled.div`
  padding-top: 212px;
  text-align: center;

  ${media.smallMobile} {
    padding-top: 128px;
  }
`;

const Title = styled.p`
  margin-bottom: 32px;
  color: ${theme.basePrimary};
  font-size: 40px;
  font-weight: bold;
  line-height: 1.5;

  ${media.smallMobile} {
    font-size: 24px;
  }
`;

const Description = styled.p`
  margin-bottom: 88px;
  color: ${theme.textBody};
  font-size: 16px;
  line-height: 1.5;

  ${media.smallMobile} {
    margin-bottom: 80px;
    font-size: 14px;
    word-wrap: break-word;
    white-space: pre-wrap;
  }
`;

const NotFound: FC = () => {
  const history = useHistory();

  return (
    <>
      <Helmet>
        <title>{MESSAGES.HEAD.TITLE.NOT_FOUND}</title>
      </Helmet>
      <Wrapper data-testid="not-found-page">
        <Title>{MESSAGES.NOT_FOUND.TITLE}</Title>
        <Description>{MESSAGES.NOT_FOUND.DESCRIPTION}</Description>
        <ButtonM onClick={() => history.goBack()}>
          {MESSAGES.NOT_FOUND.BUTTON.BACK}
        </ButtonM>
      </Wrapper>
    </>
  );
};

export default NotFound;
