import { Innovator } from 'proto/v1/apimodel/apimodel';
import { createSelector } from 'reselect';
import { ActionsUnion, createAction } from './helper';

/*
 * Redux Action actionTypes
 */

export const SET_ACCOUNT_ID = 'account/SET_ACCOUNT_ID';
export const SET_INNOVATOR = 'account/SET_INNOVATOR';
export const SET_CHAT_ROOM_ID = 'account/SET_CHAT_ROOM_ID';
export const SET_IS_PUBLISHED = 'account/SET_IS_PUBLISHED';
export const CLEAR_ACCOUNT = 'account/CLEAR';

/*
 * Redux Actions
 */

export const AccountActions = {
  setAccountId: (accountId: string) => createAction(SET_ACCOUNT_ID, accountId),
  setChatRoomId: (chatRoomId: string) =>
    createAction(SET_CHAT_ROOM_ID, chatRoomId),
  setInnovator: (innovator: Innovator | undefined) =>
    createAction(SET_INNOVATOR, innovator),
  setIsPublished: (isPublished: boolean) =>
    createAction(SET_IS_PUBLISHED, isPublished),
  clear: () => createAction(CLEAR_ACCOUNT),
};

export type Actions = ActionsUnion<typeof AccountActions>;

/*
 * Selectors
 */
export const getAccount = (state: any): AccountState => state.account;

export const getAccountId = createSelector(
  [getAccount],
  account => account.accountId,
);

export const getChatRoomId = createSelector(
  [getAccount],
  account => account.chatRoomId,
);

export const getInnovator = createSelector(
  [getAccount],
  account => account.innovator,
);

export const getIsCareer = createSelector(
  [getAccount],
  account => !!account.innovator?.career,
);

export const getIsNewGraduate = createSelector(
  [getAccount],
  account => !!account.innovator?.newGraduate,
);

export const getIsPublished = createSelector(
  [getAccount],
  account => !!account.isPublished,
);

/*
 * Reducer
 */

export type AccountState = {
  accountId: string | undefined;
  chatRoomId: string | undefined;
  innovator: Innovator | undefined;
  isPublished: boolean | undefined;
};

const initState: AccountState = {
  accountId: undefined,
  innovator: undefined,
  chatRoomId: undefined,
  isPublished: undefined,
};

export default function reducer(state = initState, action: Actions) {
  if (!action) return state;

  switch (action.type) {
    case SET_ACCOUNT_ID: {
      return { ...state, accountId: action.payload };
    }
    case SET_CHAT_ROOM_ID: {
      return { ...state, chatRoomId: action.payload };
    }
    case SET_INNOVATOR: {
      return { ...state, innovator: action.payload };
    }
    case SET_IS_PUBLISHED: {
      return { ...state, isPublished: action.payload };
    }
    default:
      return state;
  }
}
