import React, { useCallback, useState } from 'react';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { Overlay } from 'components/molecules';
import {
  MultiSelectBoxAdd,
  MultiSelectBoxRemove,
  MultiSelectBoxChecked,
} from 'assets/svg';
import styled, { css } from 'styled-components/macro';
import theme from 'styles/theme';

const MultiSelectboxRoot = styled.div<{ width?: string | number }>`
  position: relative;
  ${({ width }) =>
    width &&
    css`
      width: ${typeof width === 'number' ? `${width}px` : width};
    `}
`;

const InputBase = styled.div`
  display: flex;
  padding: 10px 10px 9px 10px;
  padding-right: 4px;
  border: 1px solid ${theme.borderDefault};
  border-radius: 3px;
  background: ${theme.baseWhite};
  line-height: 1;
  cursor: pointer;
`;

const SelectedValue = styled.div`
  width: calc(100% - 20px);
  margin: auto;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const maxHeight = 36 * 5 + 12; // optionHeight * row + padding;
const OptionsPanel = styled.div`
  position: absolute;
  z-index: 2;
  top: 36px;
  left: 0;
  width: 100%;
  max-height: ${maxHeight}px;
  padding: 6px 0;
  overflow-x: hidden;
  overflow-y: scroll;
  border: 1px solid ${theme.borderDefault};
  border-radius: 3px;
  background: ${theme.baseWhite};

  /* stylelint-disable property-no-vendor-prefix */
  ::-webkit-scrollbar {
    width: 6px;
    -webkit-appearance: none;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    border: none;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

const iconStyle = css`
  position: absolute;
  right: 16px;
`;

const AddIcon = styled(MultiSelectBoxAdd)`
  ${iconStyle};
  visibility: collapse;
`;

const SelectedIcon = styled(MultiSelectBoxChecked)`
  ${iconStyle};
`;
const RemoveIcon = styled(MultiSelectBoxRemove)`
  ${iconStyle};
  visibility: collapse;
`;

const SelectboxOption = styled.li`
  display: inline-flex;
  width: 100%;
  padding: 10px 16px;
  cursor: pointer;

  :hover ${AddIcon} {
    visibility: visible;
  }

  :hover ${RemoveIcon} {
    visibility: visible;
  }

  :hover ${SelectedIcon} {
    visibility: collapse;
  }

  & > *:first-child {
    width: calc(100% - 18px);
    overflow: hidden;
    font-weight: 500;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

type MultiSelectboxProps<Option> = {
  placeholder: string;
  options: Option[];
  values: any[];
  idName: keyof Option;
  textName: keyof Option;
  width: string | number;
  testId: string;
  onSelect: (selectedOption: Option) => void;
  onDeselect: (selectedOption: Option) => void;
};

function MultiSelectbox<Option extends {}>(props: MultiSelectboxProps<Option>) {
  const {
    placeholder,
    values,
    options,
    width,
    idName,
    textName,
    testId,
    onSelect,
    onDeselect,
  } = props;

  const [expanded, setExpanded] = useState(false);
  const handleClickBase = useCallback(() => {
    setExpanded(prev => !prev);
  }, []);

  const handleClickItem = useCallback(
    (option: Option) => {
      if (values.includes(option[idName])) {
        onDeselect(option);
      } else {
        onSelect(option);
      }
    },
    [idName, onDeselect, onSelect, values],
  );

  return (
    <MultiSelectboxRoot width={width}>
      <InputBase
        role="button"
        aria-haspopup
        area-expanded={expanded}
        onClick={handleClickBase}
        data-testid={testId}
      >
        <SelectedValue>
          {options.some(o => values.includes(o[idName]))
            ? options
                .filter(o => values.includes(o[idName]))
                .map(o => o[textName])
                .join(', ')
            : placeholder}
        </SelectedValue>
        <MdKeyboardArrowDown fill={theme.textPrimary} size={20} />
      </InputBase>
      {expanded && (
        <>
          <Overlay onDismiss={() => setExpanded(false)} />
          <OptionsPanel data-testid="multi-selectbox-options-panel">
            <ul role="listbox">
              {options.map((option, index) => (
                <SelectboxOption
                  key={(option[idName] as unknown) as string | number}
                  onClick={() => handleClickItem(option)}
                  data-testid={`multi-selectbox-option${index}`}
                >
                  <>
                    <div>{option[textName]}</div>
                    {values.includes(option[idName]) ? (
                      <>
                        <SelectedIcon />
                        <RemoveIcon />
                      </>
                    ) : (
                      <AddIcon />
                    )}
                  </>
                </SelectboxOption>
              ))}
            </ul>
          </OptionsPanel>
        </>
      )}
    </MultiSelectboxRoot>
  );
}

export default MultiSelectbox;
