export default {
  TAB: {
    TOP: 'トップ',
    MISSION_PRODUCTS: 'サービス',
    MISSION_MEMBERS: 'メンバー',
    MISSION_JOB_OFFERS: '募集',
  },
  FIELD: {
    MISSION_CATEGORIES: {
      LABEL: 'フォーカスしている事業領域',
    },
    COMPANY_URL: {
      LABEL: 'WEBSITE',
    },
    CORPORATE_ATTRIBUTES: {
      LABEL: '企業属性',
    },
    COMPANY_ESTABLISHED_DATE: {
      LABEL: '設立年',
    },
    ADDRESS: {
      LABEL: '所在地',
    },
    MEMBER_RANGE: {
      LABEL: 'チーム規模',
    },
    FUND: {
      LABEL: '資金調達額 / 予算規模 / ファンドサイズ',
    },
    STRENGTH_POINT: {
      LABEL: 'こんな世界を創りたい',
    },
    MISSION_DESCRIPTION: {
      LABEL: 'こんな事をやっています',
    },
    MISSION_MEMBERS: {
      LABEL: 'チームメンバー',
    },
    MISSION_PRODUCTS: {
      LABEL: '私たちのサービス',
      TAG: {
        PRODUCT_TARGET: {
          FOR_BUSINESS: '法人向け',
          FOR_CUSTOMER: '個人向け',
        },
      },
      PROBLEM: {
        LABEL: 'こんな課題を解決します ',
      },
      SOLUTION: {
        LABEL: 'こうやって解決します',
      },
    },
    MISSION_OFFICE_IMAGES: {
      LABEL: '働く環境',
    },
    MISSION_JOB_OFFERS: {
      LABEL: '私たちは一緒に働く仲間を探しています',
    },
  },
};
