import React, { FC } from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import styled from 'styled-components/macro';
import theme from 'styles/theme';
import media from 'styles/media';
import * as MESSAGES from 'constants/messages';
import { NO_SELECT_NUMBER } from 'constants/form';
import { MAX_NEW_GRADUATE_PREFERRED_BUSINESS_TYPES } from 'constants/profile';
import {
  ProfileFormItems,
  ProfileFormControl,
  ProfileFormValues,
  ProfileFormMethods,
} from 'types/profile';
import { interpolateMessage } from 'utils/string';
import { CircleCross } from 'assets/svg';
import { Selectbox, AddField, ErrorMessage } from 'components/atoms';
import { Field } from 'components/molecules';

const FieldItem = styled.div`
  display: flex;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const StyledSelectbox = styled(Selectbox)`
  width: 176px;

  ${media.mobile} {
    width: 100%;
  }
`;

const RemovePreferredBusinessType = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;

  ${media.mobile} {
    align-self: flex-end;
    height: 40px;
    padding-left: 8px;
    border-left: 1px solid ${theme.borderDefault};
  }
`;

const IconRemove = styled(CircleCross)`
  display: inline-block;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

type Value = {
  newGraduate: Pick<ProfileFormValues['newGraduate'], 'preferredBusinessTypes'>;
};

type NewGraduatePreferredBusinessTypesFormFieldProps = {
  error: string | undefined;
  control: ProfileFormControl<Value>;
  preferredBusinessTypes: ProfileFormItems['preferredBusinessTypes'];
  trigger: ProfileFormMethods<Value>['trigger'];
};

const NewGraduatePreferredBusinessTypesFormField: FC<NewGraduatePreferredBusinessTypesFormFieldProps> = props => {
  const { error, control, trigger, preferredBusinessTypes } = props;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'newGraduate.preferredBusinessTypes',
    keyName: 'key',
  });

  return (
    <Field
      label={MESSAGES.PROFILE.FIELD.NEW_GRADUATE_PREFERRED_BUSINESS_TYPES.LABEL}
      required
      note={interpolateMessage(
        MESSAGES.PROFILE.FIELD.NEW_GRADUATE_PREFERRED_BUSINESS_TYPES.NOTE,
        {
          maxLength: MAX_NEW_GRADUATE_PREFERRED_BUSINESS_TYPES,
        },
      )}
    >
      {fields.map((field, index) => (
        <FieldItem key={field.key}>
          <Controller
            control={control}
            name={`newGraduate.preferredBusinessTypes[${index}].id`}
            render={innerProps => (
              <StyledSelectbox
                value={innerProps.value}
                options={preferredBusinessTypes}
                aria-label={
                  MESSAGES.PROFILE.FIELD.NEW_GRADUATE_PREFERRED_BUSINESS_TYPES
                    .LABEL
                }
                noSelectValue={NO_SELECT_NUMBER}
                onChange={e => {
                  innerProps.onChange(e);
                  trigger('newGraduate.preferredBusinessTypes');
                }}
                onBlur={innerProps.onBlur}
              />
            )}
            defaultValue={field.id ?? NO_SELECT_NUMBER}
          />
          {index > 0 && (
            <RemovePreferredBusinessType>
              <IconRemove
                onClick={() => remove(index)}
                data-testid="remove-preferred-business-type"
              />
            </RemovePreferredBusinessType>
          )}
        </FieldItem>
      ))}
      {error && (
        <ErrorMessage data-testid="error-message-preferred-business-types">
          {error}
        </ErrorMessage>
      )}
      {fields.length < MAX_NEW_GRADUATE_PREFERRED_BUSINESS_TYPES && (
        <AddField
          onClick={() => append({})}
          data-testid="add-preferred-business-type"
        />
      )}
    </Field>
  );
};

export default NewGraduatePreferredBusinessTypesFormField;
