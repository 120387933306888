import { TabList } from 'react-tabs';
import styled from 'styled-components/macro';
import theme from 'styles/theme';
import media from 'styles/media';

const StyledTablist = styled(TabList)`
  padding: 0 20%;
  background-color: ${theme.baseWhite};

  ${media.tablet} {
    display: flex;
    justify-content: center;
    padding: 0;
  }
`;

export default StyledTablist;
