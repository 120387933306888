import React, { FC } from 'react';
import * as MESSAGES from 'constants/messages';
import { Label, ProfileField, ProfileContent } from 'components/atoms';
import { Innovator } from 'proto/v1/apimodel/apimodel';

type AcademicBackgroundSchoolFieldProps = {
  academicBackground: Innovator['academicBackground'] | undefined;
  label?: string;
};

const AcademicBackgroundSchoolField: FC<AcademicBackgroundSchoolFieldProps> = props => {
  const {
    academicBackground,
    label = MESSAGES.PROFILE.FIELD.ACADEMIC_BACKGROUND_SCHOOL.LABEL,
  } = props;

  return (
    <ProfileField data-testid="academic-background-school-field">
      <Label>{label}</Label>
      <ProfileContent>{academicBackground?.school?.name}</ProfileContent>
    </ProfileField>
  );
};

export default AcademicBackgroundSchoolField;
