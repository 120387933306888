export default {
    TITLE: 'INNOVATORS CAREER 利用規約',
    CONTENT: `本規約は、VISITS Technologies株式会社（以下「当社」といいます） が提供する転職支援サービス（INNOVATORS CAREER）を利用する上での合意事項となります。ご利用になる際に、必ずご確認、ご承諾いただきますようお願い申し上げます。

第1条（転職支援サービス）

利用者のこれまでのキャリアやスキルに適合すると思われる企業の紹介、企業情報や求人情報の提供、転職相談や転職支援活動、応募手続きや面接日程調整の代行、セミナーや転職フェア等当社ならではの転職ノウハウ提供等のサービスを総称で言います。

第2条（利用者）

転職支援サービスのご利用をお申し込みいただき、当社がこれを承諾し転職支援サービスの提供を開始した方、および事実上転職支援サービスをご利用いただいている方をいいます。利用者は、転職支援サービスの利用を申し込んだ時点もしくは事実上転職支援サービスの利用を開始した時点のいずれか早い時点において、変更後の本規約を含め、本規約の定めに従い転職支援サービスを利用することについて、完全に承諾しているものとみなされます。

第3条（転職支援サービス提供の変更・中断・終了）

1．利用者は当社が指定、もしくは定めるところに従い、いつでも転職支援サービスの利用を終了することができます。
2．転職支援サービスを通じて利用者が求人企業に入社した場合、入社承諾日をもって当該利用者への転職支援サービスの提供を終了いたします。
3．当社は、以下各号のいずれかに該当する事由が発生したと判断した場合、利用者への事前の通知なくして、転職支援サービスの内容を変更し、または転職支援サービスの正常な提供を行うに必要な期間、転職支援サービスの提供を停止することができます。
（1）転職支援サービスを提供するためのインフラ、システム等（以下総じて「システム」といいます）について、定期保守、または点検、更新、もしくは緊急の必要性がある場合
（2）突発的なシステムの故障等が発生した場合
（3）通常講ずべき合理的なウィルス対策では防止できないウィルスによる被害、火災、停電、天災地変等の不可抗力により、本サービスの提供が困難である場合
（4）その他、不測の事態により、転職支援サービスの提供をすることが困難である場合
4．当社は、利用者において以下各号のいずれかに該当する事由が発生したと判断した場合、当該利用者に対して、何らの催告を要することなく、転職支援サービスの提供を終了することができるものとします。
（1）利用者が本規約に定める各事項に違反した場合
（2）正当な理由なく当社からの連絡にご返信いただけない場合
（3）お預かりした利用者の個人情報が不足している場合
（4）転職支援サービスの範囲を超える要望を繰り返し行う場合
（5）暴力団、暴力団員、暴力団関係団体もしくはこれらの関係者、その他の反社会的勢力であることが判明したとき
（6）その他、利用者と当社との信頼関係が維持できないと当社が判断した場合
5．当社は、利用者について、転職支援サービスを提供することが不適切であると判断した場合、いつでもお申し込みを拒絶し、また転職支援サービスの提供を停止・終了することができるものとします。なお、当社はその判断の根拠もしくは理由の開示、質問等への回答、その他一切の対応を原則行わないものとします。

第4条（禁止事項）

利用者は、以下の行為をしてはならないものとします。また、利用者であった者は、以下の2.3.4.5.については5年間、利用者でなくなった後も該当する行為を行ってはならないものとします。
1．虚偽または不正確な個人情報を当社または求人企業に対し提供する行為
2．当社または求人企業の業務・営業を妨害する行為、または社会的信用もしくは評価を毀損する行為
3．他の利用者、求人企業、当社または求人企業の従業員その他当社の事業に関わる一切の（以下「関係者」といいます）を誹謗、中傷もしくは侮辱する行為
4．当社または関係者が有する著作権、商標権その他の知的財産権を含む一切の財産的権利、営業上の秘密、名誉、プライバシー等を侵害する行為
5．転職支援サービスを通じて入手した情報を、転職活動以外の目的において利用し、または当社の承諾なく第三者に漏洩もしくは開示する行為
6．転職支援サービスを利用した、営利目的の情報提供活動行為、営業活動行為および布教行為
7．転職支援サービスを通じて紹介を受けた求人企業に対し、当社の承諾なく直接連絡をとり、採用選考を受けまたは入社する行為
8．正当な理由なく、面談もしくは選考試験・面接を事前の連絡なく欠席し、または採用内定を受諾後に辞退する等の行為
9．公序良俗に反する一切の行為
10．その他あらゆる法令に反する、もしくはそのおそれのある行為

第5条（個人情報の取り扱い）

当社は、転職支援サービスをお使いになる全ての方（利用者を含み、以下「利用者」といいます）のプライバシーを尊重し、利用者の個人情報の管理に細心の注意を払い、別途定める「個人情報保護方針」及び「個人情報の取り扱いについて」に従ってこれを取り扱うものとします。

第6条（個人情報の正確性）

利用者は、当社および求人企業に対し、個人情報を不備・齟齬のないよう正確に提供するものとします。利用者が提供した個人情報が正確でなかったこと、および、その内容の不備・齟齬等に起因して求人企業、その他の第三者から何らかの異議、請求もしくは要求等がなされた場合には、自己の費用負担と責任で対処するものとし、当社に一切の迷惑をかけないことを保証するものとします。

第7条（パスワード管理）

利用者は、当社が転職支援サービスに関連してパスワードを発行した場合は、当該パスワードを自らの責任において使用・管理し、いかなる場合も当該パスワードを第三者に使用させ、または譲渡してはならないものとします。

第8条（本人確認）

当社は、転職支援サービスへの登録や利用者が転職支援サービスを利用する場合、個人情報の開示、訂正、削除または利用停止の求めに応じる場合等、個人を識別できる情報（氏名、住所、電話番号、生年月日、メールアドレス、パスワード等）により、本人であることを確認します。ただし、本人以外の者が、本人になりすまして当社による本人確認をすり抜けた場合、当社は責任を負いかねます。

第9条（書類の提出）

利用者は、当社が求めた場合には、以下の書類をすみやかに提出するものとします。
1．氏名・住所等利用者であることを特定・確認するための本人確認書類
2．在留資格を確認するための外国人登録証明書または就労資格証明書
3．その他求人企業が提出を求める証明書類

第10条（個人情報を提供しなかった場合に生じる結果）

法令で届出等が定められているものを除き、ご自身の判断により必ずしも個人情報をご回答戴く必要はありません。ただし、情報の提供がなかった場合、該当するサービスの一部が受けられないこと、当社からご紹介した求人企業における採用選考に影響を生じる場合がありますので、あらかじめご了承ください。

第11条（求人照合）

当社は、利用者から受領したお申し込み内容と求人企業が希望する求人条件とを照合しますが、検討基準や判断理由等をお伝えすることはできません。また、利用者から求人への応募依頼を受け付けた場合であっても、求人企業より示された選考基準等を参考にして当該求人への適合度合いを判断した結果、当社から当該求人への推薦を行わない場合や、求人企業に代わり求人条件に適合しない旨のご連絡をする場合があります。

第12条（求人票、内定通知書や労働条件通知書等の注意点）

求人票に掲載されている求人条件はあくまでも募集当初の予定であるため、実際の労働条件と異なる場合があります。企業から提示される内定通知書や労働条件通知書等に記載されている労働条件については、必ず直接当該企業にご自身で確認したうえで、慎重かつ最終的なご判断及び意思決定をお願いいたします。

第13条（紹介企業のやりとりについて）

当社からご紹介した求人は企業から当社が依頼を受け応募の窓口となっている求人です。直接問い合わせや直接応募は企業が混乱し、迷惑な行為となってしまいます。お問い合わせ・応募は当社宛てにお願いたします。尚、直接応募等が発覚した場合には、当社転職支援サービスの停止と場合によっては相応の措置を取らせていただくことになりますのでご注意ください。

第14条（内定辞退）

当社の転職支援サービスに基づいて入社承諾をした後、入社辞退や入社直後の退職等は、内定企業より賠償金を請求されるケースもあります。あらかじめ当社担当者にご相談いただく等、慎重なご判断と誠実なご対応をお願いいたします。

第15条（当社の責任）

1．当社は、利用者が転職支援サービスへ申し込み、または転職支援サービスを利用したことにより利用者に生じた一切の精神的、財産的損害につき、何らの責任も負わないものとします。
2．当社は、情報取扱業務において通常講ずべき合理的なウィルス対策では防止できないウィルス被害、その他当社の責に帰すべき事由によらない火災、停電、天災地変等の不可抗力により、転職支援サービスの提供に支障が生じ、または転職支援サービスの提供が困難となった場合、これによって利用者に生じた損害につき何らの責任も負わないものとします。
3．当社は当社の転職支援サイトにエラーその他の不具合がないこと、サーバ等にウィルスその他の有害な要素が含まれていないこと、その他転職支援サービス提供のためのインフラ、システム等に瑕疵がないこと等につき保証するものではありません。
4．当社は、利用者による転職支援サービスの利用によって、転職が成功することを保証するものではありません。

第16条（紛争、損害賠償対応）

利用者は、本契約に違反することにより当社が紛争に巻きこまれた場合、自己の責任と費用をもって当該第三者との紛争解決を図るものとします。また、利用者は、転職支援サービスの利用に際し、本規約に違反することにより当社または第三者に対して損害を与えた場合、直接・間接を問わず一切の損害賠償を行うものとします。

第17条（準拠法と管轄裁判所）

転職支援サービスおよび本規約の準拠法は日本法とし、転職支援サービスまたは本規約に関して生じる一切の紛争については、東京地方裁判所を第一審とする専属的管轄裁判所とします。

第18条（お問い合わせ先）

当社から提供さていただくサービスについて、お気づきの点・お問い合わせ等ございましたら、カスタマーサービス事務局までお申し出ください。サービスの向上に努めさせていただきます。

VISITS Technologies株式会社
INNOVATORS CAREERカスタマーサービス事務局
〒106-6117 東京都港区六本木6-10-1 六本木ヒルズ森タワー17階
e-mail：support@innovators-career.com

2021年1月4日掲載`,
};
