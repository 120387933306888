import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';
import { UiActions } from 'modules/ui';
import { AccountActions } from 'modules/account';
import {
  YEAR_OF_BIRTH_DEFAULT,
  MONTH_OF_BIRTH_DEFAULT,
  JAPAN_PREFECTURE_ID_DEFAULT,
  PHONE_COUNTRY_ID_DEFAULT,
  ACADEMIC_BACKGROUND_YEAR_OF_GRADUATION_DEFAULT,
  ACADEMIC_BACKGROUND_MONTH_OF_GRADUATION_DEFAULT,
  ADDRESS_OVERSEAS_ID,
} from 'constants/profile';
import { NO_SELECT_NUMBER } from 'constants/form';
import apiInnovator from 'external/api/innovator';
import { UpdateInnovatorRequest } from 'proto/v1/innovatorservice/innovatorservice';
import { RegisterProfileFormCareerFirstValues } from 'types/profile';
import {
  getDefaultPhoneCountryIdValue,
  getDefaultAcademicBackgroundSchoolValue,
  getDefaultCareerJobValue,
  getDefaultCareerMonthlyUnitPriceValue,
} from 'utils/profile/defaultFormValues';
import {
  getPhoneCountryCodeValueToUpdate,
  getJapanPrefectureValueToUpdate,
  getCareerJobValueToUpdate,
  getCareerMonthlyUnitPriceValueToUpdate,
} from 'utils/profile/formValuesToUpdate';
import { scrollLayoutBodyToTop } from 'utils/layout';
import {
  lastNameSchema,
  firstNameSchema,
  lastNameKanaSchema,
  firstNameKanaSchema,
  yearOfBirthSchema,
  monthOfBirthSchema,
  phoneNumberSchema,
  phoneCountryIdSchema,
  japanPrefectureIdSchema,
  academicBackgroundSchema,
  careerAnnualIncomeIdSchema,
  careerNumberOfJobChangesIdSchema,
  careerMonthlyUnitPriceSchema,
  careerJobSchema,
} from 'utils/profile/schema';
import RequestError from 'classes/RequestError';
import { Stepper } from 'hooks/useStepper';
import useErrorNotification from 'hooks/useErrorNotification';
import useFetchInnovatorForm from 'hooks/profile/useFetchInnovatorForm';
import useAcademicBackgroundSchoolFormField from 'hooks/profile/useAcademicBackgroundSchoolFormField';
import useCareerWorkingPeriodFormField from 'hooks/profile/useCareerWorkingPeriodFormField';
import useAutoCompleteKanaName from 'hooks/profile/useAutoCompleteKanaName';
import { stepsCareer } from '../../steps';

const schema = object().shape({
  lastName: lastNameSchema,
  firstName: firstNameSchema,
  lastNameKana: lastNameKanaSchema,
  firstNameKana: firstNameKanaSchema,
  yearOfBirth: yearOfBirthSchema,
  monthOfBirth: monthOfBirthSchema,
  phoneNumber: phoneNumberSchema,
  phoneCountryId: phoneCountryIdSchema,
  japanPrefectureId: japanPrefectureIdSchema,
  academicBackground: academicBackgroundSchema,
  career: object().shape({
    annualIncomeId: careerAnnualIncomeIdSchema,
    numberOfJobChangesId: careerNumberOfJobChangesIdSchema,
    monthlyUnitPrice: careerMonthlyUnitPriceSchema,
    job: careerJobSchema,
  }),
});

const defaultValues: RegisterProfileFormCareerFirstValues = {
  lastName: '',
  firstName: '',
  lastNameKana: '',
  firstNameKana: '',
  yearOfBirth: YEAR_OF_BIRTH_DEFAULT,
  monthOfBirth: MONTH_OF_BIRTH_DEFAULT,
  phoneNumber: '',
  phoneCountryId: PHONE_COUNTRY_ID_DEFAULT,
  japanPrefectureId: JAPAN_PREFECTURE_ID_DEFAULT,
  career: {
    job: [],
    annualIncomeId: NO_SELECT_NUMBER,
    monthlyUnitPrice: '',
    numberOfJobChangesId: NO_SELECT_NUMBER,
  },
  academicBackground: {
    school: null,
    faculty: '',
    departmentId: NO_SELECT_NUMBER,
    yearOfGraduation: ACADEMIC_BACKGROUND_YEAR_OF_GRADUATION_DEFAULT,
    monthOfGraduation: ACADEMIC_BACKGROUND_MONTH_OF_GRADUATION_DEFAULT,
  },
};

const useRegisterProfileFormCareerFirst = ({
  stepper,
}: {
  stepper: Stepper;
}) => {
  const dispatch = useDispatch();
  const { handleRequestError } = useErrorNotification();
  const { formItems, innovator, innovatorToUpdate } = useFetchInnovatorForm();

  const [isValidDefault, setIsValidDefault] = useState<boolean>(false);

  const onSubmit = useCallback(
    async (values: RegisterProfileFormCareerFirstValues) => {
      const valuesToUpdate: UpdateInnovatorRequest = {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        ...innovatorToUpdate!,
        lastName: values.lastName,
        firstName: values.firstName,
        lastNameKana: values.lastNameKana,
        firstNameKana: values.firstNameKana,
        yearOfBirth: values.yearOfBirth,
        monthOfBirth: values.monthOfBirth,
        phoneNumber: values.phoneNumber,
        phoneCountryCode: getPhoneCountryCodeValueToUpdate(
          values.phoneCountryId,
          formItems.countries,
        ),
        japanPrefectureId: getJapanPrefectureValueToUpdate(
          values.japanPrefectureId,
        ),
        academicBackground: {
          schoolId: values.academicBackground.school?.value ?? NO_SELECT_NUMBER,
          faculty: values.academicBackground?.faculty ?? '',
          departmentId:
            values.academicBackground?.departmentId ?? NO_SELECT_NUMBER,
          yearOfGraduation:
            values.academicBackground?.yearOfGraduation ??
            ACADEMIC_BACKGROUND_YEAR_OF_GRADUATION_DEFAULT,
          monthOfGraduation:
            values.academicBackground?.monthOfGraduation ??
            ACADEMIC_BACKGROUND_MONTH_OF_GRADUATION_DEFAULT,
        },
        career: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          ...innovatorToUpdate!.career!,
          job: getCareerJobValueToUpdate(values.career.job),
          annualIncomeId: values.career.annualIncomeId,
          monthlyUnitPrice: getCareerMonthlyUnitPriceValueToUpdate(
            values.career.monthlyUnitPrice,
          ),
          numberOfJobChangesId: values.career.numberOfJobChangesId,
        },
      };
      let data;
      try {
        ({ data } = await apiInnovator.updateInnovator(valuesToUpdate));
      } catch (error: unknown) {
        if (error instanceof RequestError) {
          dispatch(UiActions.setLoading(false));
          handleRequestError(error);
        }
        return;
      }
      dispatch(AccountActions.setInnovator(data.innovator));
      dispatch(UiActions.setLoading(false));
      scrollLayoutBodyToTop();
      stepsCareer.formFirst.completed = true;
      stepper.handleNext();
    },
    [
      dispatch,
      formItems.countries,
      handleRequestError,
      innovatorToUpdate,
      stepper,
    ],
  );

  const formMethods = useForm<RegisterProfileFormCareerFirstValues>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues,
  });

  const { reset } = formMethods;

  useAutoCompleteKanaName(formMethods);

  const {
    schoolOptionsState,
    handleInputChangeSchool,
    handleCreateSchoolOption,
    fetchMoreSchoolOptions,
  } = useAcademicBackgroundSchoolFormField({ formMethods });

  const { handleChangeEndWorkingPeriod } = useCareerWorkingPeriodFormField({
    formMethods,
  });

  // Set default values by the fetched innovator
  useEffect(() => {
    if (!innovator || !innovatorToUpdate) return;

    const values: RegisterProfileFormCareerFirstValues = {
      lastName: innovatorToUpdate.lastName,
      firstName: innovatorToUpdate.firstName,
      lastNameKana: innovatorToUpdate.lastNameKana,
      firstNameKana: innovatorToUpdate.firstNameKana,
      yearOfBirth: innovatorToUpdate.yearOfBirth,
      monthOfBirth: innovatorToUpdate.monthOfBirth,
      phoneNumber: innovatorToUpdate.phoneNumber,
      phoneCountryId: getDefaultPhoneCountryIdValue(
        innovatorToUpdate.phoneCountryCode,
        formItems.countries,
      ),
      japanPrefectureId:
        innovatorToUpdate.japanPrefectureId ?? ADDRESS_OVERSEAS_ID,
      academicBackground: {
        school: getDefaultAcademicBackgroundSchoolValue(
          innovator.academicBackground?.school,
        ),
        faculty: innovatorToUpdate.academicBackground?.faculty ?? '',
        departmentId:
          innovatorToUpdate.academicBackground?.departmentId ??
          NO_SELECT_NUMBER,
        yearOfGraduation:
          innovatorToUpdate.academicBackground?.yearOfGraduation ??
          ACADEMIC_BACKGROUND_YEAR_OF_GRADUATION_DEFAULT,
        monthOfGraduation:
          innovatorToUpdate.academicBackground?.monthOfGraduation ??
          ACADEMIC_BACKGROUND_MONTH_OF_GRADUATION_DEFAULT,
      },
      career: {
        job: getDefaultCareerJobValue(innovatorToUpdate.career?.job),
        annualIncomeId:
          innovatorToUpdate.career?.annualIncomeId ?? NO_SELECT_NUMBER,
        monthlyUnitPrice: getDefaultCareerMonthlyUnitPriceValue(
          innovatorToUpdate.career?.monthlyUnitPrice,
        ),
        numberOfJobChangesId:
          innovatorToUpdate.career?.numberOfJobChangesId ?? NO_SELECT_NUMBER,
      },
    };

    setIsValidDefault(schema.isValidSync(values));

    reset(values);
  }, [formItems.countries, innovator, innovatorToUpdate, reset]);

  return {
    formMethods,
    formItems,
    onSubmit,
    schoolOptionsState,
    isValidDefault,
    handleInputChangeSchool,
    handleCreateSchoolOption,
    fetchMoreSchoolOptions,
    handleChangeEndWorkingPeriod,
  };
};

export default useRegisterProfileFormCareerFirst;
