import { createSelector } from 'reselect';
import { ApiError } from 'types/error';
import { ActionsUnion, createAction } from './helper';

export type ErrorNotificationOptions = {
  isClosable?: boolean;
} & ApiError;

export type ErrorNotificationState = {
  readonly show: boolean;
  readonly options: ErrorNotificationOptions | null;
};

/*
 * Redux Action actionTypes
 */
export const OPEN = 'errorNotification/OPEN';
export const CLOSE = 'errorNotification/CLOSE';

/*
 * Redux Actions
 */
export const ErrorNotificationActions = {
  open: (options: ErrorNotificationOptions) => createAction(OPEN, options),
  close: () => createAction(CLOSE),
};

export type Actions = ActionsUnion<typeof ErrorNotificationActions>;

/*
 * Selectors
 */
export const getErrorNotificationState = (state: any): ErrorNotificationState =>
  state.errorNotification;

export const getShow = createSelector(
  [getErrorNotificationState],
  errorNotificationState => errorNotificationState.show,
);

export const getOptions = createSelector(
  [getErrorNotificationState],
  errorNotificationState => errorNotificationState.options,
);

/*
 * Reducer
 */
const initState: ErrorNotificationState = {
  show: false,
  options: {
    isClosable: true,
    message: '',
  },
};

export default function reducer(state = initState, action: Actions) {
  if (!action) return state;

  switch (action.type) {
    case OPEN: {
      return {
        ...state,
        show: true,
        options: { ...state.options, ...action.payload },
      };
    }
    case CLOSE: {
      return initState;
    }
    default:
      return state;
  }
}
