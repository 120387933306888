import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import { getInnovator } from 'modules/account';
import * as MESSAGES from 'constants/messages';
import media from 'styles/media';
import {
  MemberNumberField,
  NameField,
  NameKanaField,
  YearMonthOfBirthField,
  PhoneNumberField,
  JapanPrefectureField,
  AcademicBackgroundSchoolField,
  AcademicBackgroundFacultyField,
  AcademicBackgroundDepartmentField,
  AcademicBackgroundYearMonthOfGraduationField,
  NewGraduateResearchRoomsField,
} from 'components/organisms';

const Wrapper = styled.div`
  margin-bottom: -40px;

  ${media.mobile} {
    margin-bottom: -32px;
  }
`;

const NewGraduateProfileBasicInfoContents: FC = () => {
  const innovator = useSelector(getInnovator);

  if (!innovator) return null;

  return (
    <Wrapper data-testid="new-graduate-profile-basic-info-contents">
      <MemberNumberField innovatorId={innovator.innovatorId} />

      <NameField
        lastName={innovator.lastName}
        firstName={innovator.firstName}
      />

      <NameKanaField
        lastNameKana={innovator.lastNameKana}
        firstNameKana={innovator.firstNameKana}
      />

      <YearMonthOfBirthField
        yearOfBirth={innovator.yearOfBirth}
        monthOfBirth={innovator.monthOfBirth}
      />

      <PhoneNumberField
        phoneCountryCode={innovator.phoneCountryCode}
        phoneNumber={innovator.phoneNumber}
      />

      <JapanPrefectureField japanPrefecture={innovator.japanPrefecture} />

      <AcademicBackgroundSchoolField
        label={
          MESSAGES.PROFILE.FIELD.ACADEMIC_BACKGROUND_SCHOOL.LABEL_NEW_GRADUATE
        }
        academicBackground={innovator.academicBackground}
      />

      <AcademicBackgroundFacultyField
        label={
          MESSAGES.PROFILE.FIELD.ACADEMIC_BACKGROUND_FACULTY.LABEL_NEW_GRADUATE
        }
        academicBackground={innovator.academicBackground}
      />

      <AcademicBackgroundDepartmentField
        academicBackground={innovator.academicBackground}
      />

      <AcademicBackgroundYearMonthOfGraduationField
        label={
          MESSAGES.PROFILE.FIELD.ACADEMIC_BACKGROUND_YEAR_MONTH_OF_GRADUATION
            .LABEL_NEW_GRADUATE
        }
        yearOfGraduation={innovator.academicBackground?.yearOfGraduation}
        monthOfGraduation={innovator.academicBackground?.monthOfGraduation}
      />

      <NewGraduateResearchRoomsField
        researchRooms={innovator.newGraduate?.researchRooms}
      />
    </Wrapper>
  );
};

export default NewGraduateProfileBasicInfoContents;
