import React, { FC } from 'react';
import { useWatch } from 'react-hook-form';
import * as MESSAGES from 'constants/messages';
import {
  PROFILE_FIELDS_MAX_LENGTH,
  PROFILE_FIELDS_MIN_LENGTH,
} from 'constants/profile';
import media from 'styles/media';
import { interpolateMessage } from 'utils/string';
import {
  ProfileFormControl,
  ProfileFormRegister,
  ProfileFormValues,
} from 'types/profile';
import styled from 'styled-components/macro';
import { Textarea } from 'components/atoms';
import { Field } from 'components/molecules';

const StyledField = styled(Field)`
  position: relative;
`;

const StyledTextarea = styled(Textarea)`
  position: relative;

  textarea {
    min-height: 178px;

    ${media.smallMobile} {
      min-height: 320px;
    }
  }

  small {
    position: absolute;
    bottom: -22px;
    left: 0;

    ${media.smallMobile} {
      bottom: -16px;
    }
  }
`;

const TextCount = styled.p`
  font-size: 12px;
  text-align: right;
`;

type SelfIntroductionFormFieldProps = {
  error: string | undefined;
  register: ProfileFormRegister<'selfIntroduction'>;
  control: ProfileFormControl<'selfIntroduction'>;
};

const SelfIntroductionFormField: FC<SelfIntroductionFormFieldProps> = props => {
  const { error, register, control } = props;

  const selfIntroduction = useWatch<ProfileFormValues['selfIntroduction']>({
    name: 'selfIntroduction',
    control,
  });

  return (
    <StyledField
      label={MESSAGES.PROFILE.FIELD.SELF_INTRODUCTION.LABEL}
      note={interpolateMessage(MESSAGES.PROFILE.FIELD.SELF_INTRODUCTION.NOTE, {
        minLength: PROFILE_FIELDS_MIN_LENGTH.SELF_INTRODUCTION,
        maxLength: PROFILE_FIELDS_MAX_LENGTH.SELF_INTRODUCTION,
      })}
      required
    >
      <StyledTextarea
        name="selfIntroduction"
        error={error}
        maxLength={PROFILE_FIELDS_MAX_LENGTH.SELF_INTRODUCTION}
        placeholder={MESSAGES.PROFILE.FIELD.SELF_INTRODUCTION.PLACEHOLDER}
        aria-label={MESSAGES.PROFILE.FIELD.SELF_INTRODUCTION.LABEL}
        ref={register}
      />
      <TextCount data-testid="self-introduction-text-count">
        {selfIntroduction?.length}
        {MESSAGES.PROFILE.FIELD.SELF_INTRODUCTION.TEXT_UNIT}
      </TextCount>
    </StyledField>
  );
};

export default SelfIntroductionFormField;
