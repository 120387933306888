import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { useFieldArray } from 'react-hook-form';
import * as MESSAGES from 'constants/messages';
import media from 'styles/media';
import theme from 'styles/theme';
import {
  PROFILE_FIELDS_MAX_LENGTH,
  MAX_NEW_GRADUATE_CLUB_EXPERIENCES,
} from 'constants/profile';
import {
  ProfileFormControl,
  ProfileFormRegister,
  ProfileFormValues,
} from 'types/profile';
import { TextInput, Textarea, AddField } from 'components/atoms';
import { Field } from 'components/molecules';

const Wrapper = styled.div``;

const FieldsContainer = styled.div<{ isLast: boolean }>`
  display: flex;
  margin-bottom: ${({ isLast }) => (isLast ? 0 : '24px')};
  padding-bottom: ${({ isLast }) => (isLast ? 0 : '16px')};
  border-bottom: ${({ isLast }) =>
    isLast ? 'none' : `1px solid ${theme.borderDefault}`};
`;

const Fields = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const ClubExperienceNameTextInput = styled(TextInput)`
  width: 412px;

  ${media.mobile} {
    width: 100%;
  }
`;

const ClubExperienceNameTextarea = styled(Textarea)`
  textarea {
    min-height: 83px;

    ${media.mobile} {
      min-height: 162px;
    }
  }
`;

const RemoveClubExperience = styled.div`
  align-self: flex-end;
  margin-top: 6px;
  color: ${theme.basePrimary};
  font-size: 12px;
  line-height: 1.5;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

type Value = {
  newGraduate: Pick<ProfileFormValues['newGraduate'], 'clubExperiences'>;
};

type NewGraduateClubExperiencesFormFieldProps = {
  register: ProfileFormRegister<Value>;
  control: ProfileFormControl<Value>;
};

const NewGraduateClubExperiencesFormField: FC<NewGraduateClubExperiencesFormFieldProps> = props => {
  const { register, control } = props;

  const { fields, append, remove } = useFieldArray<
    ProfileFormValues['newGraduate']['clubExperiences'][0]
  >({
    control,
    name: 'newGraduate.clubExperiences',
  });

  return (
    <Wrapper>
      {fields.map((field, index) => (
        <FieldsContainer
          key={field?.id ?? index}
          isLast={index === MAX_NEW_GRADUATE_CLUB_EXPERIENCES - 1}
        >
          <Fields data-testid="new-graduate-club-experience-fields">
            <Field
              label={
                MESSAGES.PROFILE.FIELD.NEW_GRADUATE_CLUB_EXPERIENCES.NAME.LABEL
              }
            >
              <ClubExperienceNameTextInput
                enterKeyHint="enter"
                name={`newGraduate.clubExperiences[${index}].name`}
                maxLength={
                  PROFILE_FIELDS_MAX_LENGTH.NEW_GRADUATE_CLUB_EXPERIENCES_NAME
                }
                aria-label={
                  MESSAGES.PROFILE.FIELD.NEW_GRADUATE_CLUB_EXPERIENCES.NAME
                    .LABEL
                }
                ref={register()}
                defaultValue={field.name}
              />
            </Field>

            <Field
              label={
                MESSAGES.PROFILE.FIELD.NEW_GRADUATE_CLUB_EXPERIENCES.CONTENT
                  .LABEL
              }
              style={{ marginBottom: 0 }}
            >
              <ClubExperienceNameTextarea
                name={`newGraduate.clubExperiences[${index}].content`}
                maxLength={
                  PROFILE_FIELDS_MAX_LENGTH.NEW_GRADUATE_CLUB_EXPERIENCES_CONTENT
                }
                aria-label={
                  MESSAGES.PROFILE.FIELD.NEW_GRADUATE_CLUB_EXPERIENCES.CONTENT
                    .LABEL
                }
                ref={register()}
                defaultValue={field.content}
              />
            </Field>
            {index > 0 && (
              <RemoveClubExperience
                onClick={() => remove(index)}
                data-testid="remove-club-experience"
              >
                {MESSAGES.COMMON.BUTTON.REMOVE_FIELD}
              </RemoveClubExperience>
            )}
          </Fields>
        </FieldsContainer>
      ))}

      {fields.length < MAX_NEW_GRADUATE_CLUB_EXPERIENCES && (
        <AddField
          style={{ marginTop: '-8px' }}
          onClick={() => append({})}
          data-testid="add-club-experience"
        />
      )}
    </Wrapper>
  );
};

export default NewGraduateClubExperiencesFormField;
