import React, { FC } from 'react';
import * as MESSAGES from 'constants/messages';
import { NewGraduateProfile } from 'proto/v1/apimodel/apimodel';
import {
  Label,
  ProfileField,
  ProfileContent,
  GroupLabel,
  ProfileContentGroup,
  ProfileContentGroupItem,
} from 'components/atoms';
import { formatPeriodsWithEmptyEndYearMonth } from 'utils/profile/fields';

type NewGraduateInternshipExperiencesFieldProps = {
  internshipExperiences:
    | NewGraduateProfile['internshipExperiences']
    | undefined;
};

const NewGraduateInternshipExperiencesField: FC<NewGraduateInternshipExperiencesFieldProps> = props => {
  const { internshipExperiences } = props;

  return (
    <div data-testid="new-graduate-internship-experiences-field">
      <GroupLabel>
        {MESSAGES.PROFILE.FIELD.NEW_GRADUATE_INTERNSHIP_EXPERIENCES.LABEL}
      </GroupLabel>
      <ProfileContentGroup>
        {internshipExperiences && internshipExperiences.length > 0
          ? internshipExperiences.map((internshipExperience, index) => (
              <ProfileContentGroupItem
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                data-testid="new-graduate-internship-experiences-field-item"
              >
                <ProfileField data-testid="new-graduate-internship-experiences-field-company-name">
                  <Label>
                    {
                      MESSAGES.PROFILE.FIELD.NEW_GRADUATE_INTERNSHIP_EXPERIENCES
                        .COMPANY_NAME.LABEL
                    }
                  </Label>
                  <ProfileContent>
                    {internshipExperience.companyName || '-'}
                  </ProfileContent>
                </ProfileField>
                <ProfileField data-testid="new-graduate-internship-experiences-field-internship-period">
                  <Label>
                    {
                      MESSAGES.PROFILE.FIELD.NEW_GRADUATE_INTERNSHIP_EXPERIENCES
                        .INTERNSHIP_PERIOD.LABEL
                    }
                  </Label>
                  <ProfileContent>
                    {formatPeriodsWithEmptyEndYearMonth({
                      startYear: internshipExperience.startYear,
                      startMonth: internshipExperience.startMonth,
                      endYear: internshipExperience.endYear,
                      endMonth: internshipExperience.endMonth,
                    })}
                  </ProfileContent>
                </ProfileField>
              </ProfileContentGroupItem>
            ))
          : MESSAGES.COMMON.DESCRIPTION.NOT_REGISTERED}
      </ProfileContentGroup>
    </div>
  );
};

export default NewGraduateInternshipExperiencesField;
