import React from 'react';
import styled from 'styled-components';
import { Field, MultiSelectbox } from 'components/molecules';
import { Controller } from 'react-hook-form';
import {
  PublishSettingFormMethods,
  UpdatePublishSettingForm,
  UpdatePublishSettingValues,
} from 'types/publishSetting';
import * as MESSAGES from 'constants/messages';
import theme from 'styles/theme';
import media from 'styles/media';

const StyledField = styled(Field)`
  & > div > h5 {
    color: ${theme.labelDefault};
    font-size: 16px;

    ${media.mobile} {
      font-size: 14px;
    }
  }
`;

type OptionsName = keyof UpdatePublishSettingForm &
  keyof UpdatePublishSettingValues;

type BaseMultiSelectFieldProps<T extends OptionsName> = {
  label: string;
  formMethods: PublishSettingFormMethods;
  form: UpdatePublishSettingForm;
  idName: keyof UpdatePublishSettingForm[T][0];
  textName: keyof UpdatePublishSettingForm[T][0];
  optionsName: T;
};

type BaseMultiSelectFieldType = <T extends OptionsName>(
  props: BaseMultiSelectFieldProps<T>,
) => React.ReactElement<BaseMultiSelectFieldProps<T>>;

const BaseMultiSelectField: BaseMultiSelectFieldType = props => {
  const { label, formMethods, form, optionsName, idName, textName } = props;
  const { control, setValue, getValues, errors } = formMethods;

  return (
    <StyledField label={label}>
      <Controller
        name={optionsName}
        control={control}
        error={errors[optionsName]}
        render={innerProps => (
          <MultiSelectbox
            values={innerProps.value}
            placeholder={MESSAGES.PUBLISH_SETTING.FIELD.PLACEHOLDER_ALL}
            options={form[optionsName] ?? []}
            idName={idName}
            textName={textName}
            onSelect={option =>
              setValue(optionsName, [
                ...(getValues(optionsName) ?? []),
                option[idName],
              ] as any)
            }
            onDeselect={option =>
              setValue(
                optionsName,
                (getValues(optionsName) ?? []).filter(
                  a => a !== (option[idName] as any),
                ) as any,
              )
            }
            width={'100%'}
            testId={`multi-select-box-${optionsName}`}
          />
        )}
      />
    </StyledField>
  );
};

export default BaseMultiSelectField;
