import React from 'react';
import styled from 'styled-components/macro';
import media from 'styles/media';
import * as MESSAGES from 'constants/messages';
import { MissionForInnovator } from 'types/mission';
import { Title } from '../../Typography';
import MissionJobOfferCard from './MissionJobOfferCard';

const MissionJobOfferCards = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

const MissionJobOffersTitle = styled(Title)`
  ${media.miniMobile} {
    font-size: 16px;
  }
`;

type Props = {
  missionJobOffers: MissionForInnovator['missionJobOffers'] | undefined;
};

const MissionJobOffersField: React.VFC<Props> = ({
  missionJobOffers,
  ...rest
}) => {
  return (
    <div {...rest}>
      <MissionJobOffersTitle>
        {MESSAGES.MISSION_CARD.FIELD.MISSION_JOB_OFFERS.LABEL}
      </MissionJobOffersTitle>
      <MissionJobOfferCards>
        {(missionJobOffers ?? []).map(missionJobOffer => (
          <MissionJobOfferCard
            key={missionJobOffer.id}
            missionJobOffer={missionJobOffer}
          />
        ))}
      </MissionJobOfferCards>
    </div>
  );
};

export default MissionJobOffersField;
