import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { Helmet } from 'react-helmet';
import * as MESSAGES from 'constants/messages';
import media from 'styles/media';
import { getPhoneCountryCode } from 'utils/profile/fields';
import { Stepper } from 'hooks/useStepper';
import { Form, ButtonM, GroupLabel, CardInner } from 'components/atoms';
import {
  FirstNameFormField,
  LastNameFormField,
  FirstNameKanaFormField,
  LastNameKanaFormField,
  YearMonthOfBirthFormField,
  PhoneNumberFormField,
  JapanPrefectureFormField,
  AcademicBackgroundSchoolFormField,
  AcademicBackgroundFacultyFormField,
  AcademicBackgroundDepartmentFormField,
  AcademicBackgroundYearMonthOfGraduationFormField,
  NewGraduateResearchRoomsFormField,
} from 'components/organisms';
import useRegisterProfileFormNewGraduateFirst from './useRegisterProfileFormNewGraduateFirst';

const SubmitButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SubmitButton = styled(ButtonM)`
  ${media.smallMobile} {
    width: 100%;
  }
`;

const NameFields = styled.div`
  display: grid;
  grid-column-gap: 16px;
  grid-template-columns: 1fr 1fr;

  ${media.mobile} {
    grid-template-columns: 1fr;
  }
`;

type RegisterProfileFormCareerFirstProps = {
  stepper: Stepper;
};

const RegisterProfileFormCareerFirst: FC<RegisterProfileFormCareerFirstProps> = props => {
  const { stepper } = props;
  const {
    formMethods,
    onSubmit,
    formItems,
    schoolOptionsState,
    isValidDefault,
    handleInputChangeSchool,
    handleCreateSchoolOption,
    fetchMoreSchoolOptions,
  } = useRegisterProfileFormNewGraduateFirst({
    stepper,
  });
  const {
    handleSubmit,
    errors,
    register,
    formState,
    watch,
    control,
  } = formMethods;
  const { isValid, isSubmitting, isDirty, touched } = formState;
  const isDisabledSubmitButton = isDirty
    ? !isValid || isSubmitting
    : !isValidDefault;
  const watchPhoneCountryId = watch('phoneCountryId');

  return (
    <>
      <Helmet>
        <title>{MESSAGES.HEAD.TITLE.RESISTER_PROFILE.FORM_FIRST}</title>
      </Helmet>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        data-testid="profile-form-new-graduate-first"
      >
        <NameFields>
          <LastNameFormField
            error={touched.lastName && errors.lastName?.message}
            register={register}
          />
          <FirstNameFormField
            error={touched.firstName && errors.firstName?.message}
            register={register}
          />
        </NameFields>

        <NameFields>
          <LastNameKanaFormField
            error={touched.lastNameKana && errors.lastNameKana?.message}
            register={register}
          />
          <FirstNameKanaFormField
            error={touched.firstNameKana && errors.firstNameKana?.message}
            register={register}
          />
        </NameFields>

        <YearMonthOfBirthFormField
          errors={{
            yearOfBirth: touched.yearOfBirth && errors.yearOfBirth?.message,
            monthOfBirth: touched.monthOfBirth && errors.monthOfBirth?.message,
          }}
          control={control}
        />

        <PhoneNumberFormField
          countries={formItems.countries}
          error={touched.phoneNumber && errors.phoneNumber?.message}
          phoneCountryCode={getPhoneCountryCode(
            watchPhoneCountryId,
            formItems.countries,
          )}
          control={control}
          register={register}
        />

        <JapanPrefectureFormField
          error={touched.japanPrefectureId && errors.japanPrefectureId?.message}
          japanPrefectures={formItems.japanPrefectures}
          control={control}
        />

        <AcademicBackgroundSchoolFormField
          label={
            MESSAGES.PROFILE.FIELD.ACADEMIC_BACKGROUND_SCHOOL.LABEL_NEW_GRADUATE
          }
          inputValue={schoolOptionsState.inputValue}
          options={schoolOptionsState.options}
          fetchMore={fetchMoreSchoolOptions}
          isLoading={schoolOptionsState.isLoading}
          onInputChange={handleInputChangeSchool}
          onCreateOption={handleCreateSchoolOption}
          error={
            !schoolOptionsState.isCreating && touched.academicBackground?.school
              ? errors.academicBackground?.school?.message
              : undefined
          }
          control={control}
        />

        <AcademicBackgroundFacultyFormField
          label={
            MESSAGES.PROFILE.FIELD.ACADEMIC_BACKGROUND_FACULTY
              .LABEL_NEW_GRADUATE
          }
          error={
            touched.academicBackground?.faculty &&
            errors.academicBackground?.faculty?.message
          }
          register={register}
        />

        <AcademicBackgroundDepartmentFormField
          error={
            touched.academicBackground?.departmentId &&
            errors.academicBackground?.departmentId?.message
          }
          departments={formItems.departments}
          control={control}
        />

        <AcademicBackgroundYearMonthOfGraduationFormField
          label={
            MESSAGES.PROFILE.FIELD.ACADEMIC_BACKGROUND_YEAR_MONTH_OF_GRADUATION
              .LABEL_NEW_GRADUATE
          }
          errors={{
            yearOfGraduation:
              touched.academicBackground?.yearOfGraduation &&
              errors.academicBackground?.yearOfGraduation?.message,
            monthOfGraduation:
              touched.academicBackground?.monthOfGraduation &&
              errors.academicBackground?.monthOfGraduation?.message,
          }}
          control={control}
          isNewGraduate
        />

        <GroupLabel>
          {MESSAGES.PROFILE.FIELD.NEW_GRADUATE_RESEARCH_ROOMS.LABEL}
        </GroupLabel>
        <CardInner>
          <NewGraduateResearchRoomsFormField
            register={register}
            control={control}
          />
        </CardInner>

        <SubmitButtonWrapper>
          <SubmitButton type="submit" disabled={isDisabledSubmitButton}>
            {MESSAGES.REGISTER_PROFILE.BUTTON.SAVE_AND_GO_TO_NEXT}
          </SubmitButton>
        </SubmitButtonWrapper>
      </Form>
    </>
  );
};

export default RegisterProfileFormCareerFirst;
