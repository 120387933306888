import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import { getInnovator } from 'modules/account';
import media from 'styles/media';
import {
  CareerNumberOfJobChangesField,
  CareerJobField,
} from 'components/organisms';

const Wrapper = styled.div`
  margin-bottom: -40px;

  ${media.mobile} {
    margin-bottom: -32px;
  }
`;

const CareerProfileJobHistoryContents: FC = () => {
  const innovator = useSelector(getInnovator);

  if (!innovator) return null;

  return (
    <Wrapper data-testid="career-profile-job-history-contents">
      <CareerNumberOfJobChangesField
        numberOfJobChanges={innovator.career?.numberOfJobChanges}
      />

      <CareerJobField job={innovator.career?.job} />
    </Wrapper>
  );
};

export default CareerProfileJobHistoryContents;
