import React, { FC, ComponentProps } from 'react';
import { ButtonM } from 'components/atoms';
import { Modal, ModalContent, ModalFooter } from 'components/molecules';

type Props = ComponentProps<typeof Modal> & {
  title?: string;
  cancelCaption?: string;
  confirmCaption?: string;
  confirm?: () => void;
};

const ModalConfirm: FC<Props> = props => {
  const {
    title,
    cancelCaption,
    closeModal,
    confirm,
    confirmCaption,
    children,
  } = props;

  return (
    <Modal {...props}>
      <ModalContent title={title} data-testid="modal-confirm">
        {children}
      </ModalContent>
      <ModalFooter>
        {cancelCaption && (
          <ButtonM
            buttonTheme="cancel"
            data-testid="modal-confirm-button-cancel"
            onClick={closeModal}
          >
            {cancelCaption}
          </ButtonM>
        )}

        {confirmCaption && confirm && (
          <ButtonM onClick={confirm} data-testid="modal-confirm-button-ok">
            {confirmCaption}
          </ButtonM>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default ModalConfirm;
