import React, { FC, ComponentProps } from 'react';
import styled from 'styled-components/macro';
import BaseButton from './BaseButton';

const StyledButtonXL = styled(BaseButton)<ComponentProps<typeof BaseButton>>`
  min-width: 240px;
  height: 48px;
  padding: 6px 16px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.5;
  text-align: center;
`;

const ButtonXL: FC<ComponentProps<typeof BaseButton>> = React.forwardRef(
  (props, ref) => (
    <StyledButtonXL {...props} ref={ref}>
      {props.children}
    </StyledButtonXL>
  ),
);
export default ButtonXL;
