import { useCallback, ChangeEvent } from 'react';
import { UseFormMethods } from 'react-hook-form';
import {
  END_YEAR_MONTH_CURRENTLY_ENROLLED_ID,
  WORKING_PERIOD_YEAR_DEFAULT,
  WORKING_PERIOD_MONTH_DEFAULT,
} from 'constants/profile';

type Payload = {
  formMethods: UseFormMethods<any>;
};

const useCareerWorkingPeriodFormField = ({ formMethods }: Payload) => {
  const { setValue, getValues } = formMethods;

  const handleChangeEndWorkingPeriod = useCallback(
    (e: ChangeEvent<HTMLSelectElement>, index: number) => {
      const selected = Number(e.target.value);
      const endYearName = `career.job[${index}].endYear`;
      const endMonthName = `career.job[${index}].endMonth`;
      if (selected === END_YEAR_MONTH_CURRENTLY_ENROLLED_ID) {
        setValue(endYearName, END_YEAR_MONTH_CURRENTLY_ENROLLED_ID, {
          shouldValidate: true,
          shouldDirty: true,
        });
        setValue(endMonthName, END_YEAR_MONTH_CURRENTLY_ENROLLED_ID, {
          shouldValidate: true,
          shouldDirty: true,
        });
      } else {
        setValue(e.target.name, selected, {
          shouldValidate: true,
          shouldDirty: true,
        });

        const isEndYearOpposite = e.target.name.includes('endMonth');
        const isMonthOpposite = e.target.name.includes('endYear');

        // Set default endYear value if endYear is END_YEAR_MONTH_CURRENTLY_ENROLLED_ID
        if (
          isEndYearOpposite &&
          getValues(endYearName) === END_YEAR_MONTH_CURRENTLY_ENROLLED_ID
        ) {
          setValue(endYearName, WORKING_PERIOD_YEAR_DEFAULT, {
            shouldValidate: true,
            shouldDirty: true,
          });
        }
        // Set default endMonth value if endMonth is END_YEAR_MONTH_CURRENTLY_ENROLLED_ID
        if (
          isMonthOpposite &&
          getValues(endMonthName) === END_YEAR_MONTH_CURRENTLY_ENROLLED_ID
        ) {
          setValue(endMonthName, WORKING_PERIOD_MONTH_DEFAULT, {
            shouldValidate: true,
            shouldDirty: true,
          });
        }
      }
    },
    [getValues, setValue],
  );

  return {
    handleChangeEndWorkingPeriod,
  };
};

export default useCareerWorkingPeriodFormField;
