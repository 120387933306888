import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components/macro';
import media from 'styles/media';
import * as MESSAGES from 'constants/messages';
import { Card } from 'components/atoms';

const Wrapper = styled.div`
  padding-top: 40px;
`;

const Title = styled.p`
  margin-bottom: 16px;
  font-size: 20px;
  line-height: 1.5;

  ${media.mobile} {
    font-size: 18px;
  }
`;

const StyledCard = styled(Card)`
  width: 100%;
  padding: 44px 32px;
  font-size: 14px;
  line-height: 1.5;
  word-wrap: break-word;
  white-space: pre-wrap;

  ${media.mobile} {
    padding: 32px 16px;
  }
`;

const Terms: FC = () => {
  return (
    <>
      <Helmet>
        <title>{MESSAGES.HEAD.TITLE.TERMS}</title>
      </Helmet>
      <Wrapper>
        <Title>{MESSAGES.TERMS.TITLE}</Title>
        <StyledCard>{MESSAGES.TERMS.CONTENT}</StyledCard>
      </Wrapper>
    </>
  );
};

export default Terms;
