import {
  init,
  captureException,
  captureMessage,
  Severity,
} from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

type LoggerOptions = {
  fingerprint?: string[];
  extra?: { [key: string]: any };
};

type SentryContext = Parameters<typeof captureException>[1];

const SENTRY_DSN =
  'https://3c1464356d8546c7858050588e271cea@o1170840.ingest.sentry.io/6321316';

const useLogger =
  process.env.NODE_ENV === 'production' &&
  process.env.REACT_APP_BUILD_ENV !== 'local';

init({
  dsn: useLogger ? SENTRY_DSN : undefined,
  integrations: [new BrowserTracing()],
  environment: process.env.REACT_APP_BUILD_ENV,
  release: process.env.REACT_APP_SENTRY_RELEASE_VERSION,
  normalizeDepth: 10,
  ignoreErrors: [
    'https://firestore.googleapis.com/google.firestore.v1.Firestore/Listen/channel',
  ],
});

const toSentryContext = (
  options?: LoggerOptions & { level?: Severity },
): SentryContext => {
  if (!options) return;
  return {
    fingerprint: options.fingerprint,
    level: options.level,
    extra: options.extra,
  };
};

const logger = {
  exception(error: Error, options?: LoggerOptions) {
    captureException(error, toSentryContext(options));
  },
  error(message: string, options?: LoggerOptions) {
    captureMessage(
      message,
      toSentryContext({ ...options, level: Severity.Error }),
    );
  },
};

export default logger;
