import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import qs from 'qs';
import { useLocation } from 'react-router-dom';
import { UiActions } from 'modules/ui';
import * as MESSAGES from 'constants/messages';
import apiAccount from 'external/api/account';
import RequestError from 'classes/RequestError';
import useErrorNotification from 'hooks/useErrorNotification';

type Values = {
  email: string;
};

const schema = object().shape({
  email: string()
    .email(MESSAGES.ERROR.INVALID_EMAIL)
    .required(MESSAGES.ERROR.REQUIRED_EMAIL),
});

const useUnsubscribeEmail = () => {
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const dispatch = useDispatch();
  const location = useLocation();

  const { email }: { email?: string } = qs.parse(location?.search, {
    ignoreQueryPrefix: true,
  });

  const defaultEmail = decodeURIComponent(email ?? '');

  const { handleRequestError } = useErrorNotification();

  const onSubmit = useCallback(
    async (values: Values) => {
      try {
        await apiAccount.unsubscribeEmail({
          email: values.email,
        });
      } catch (error: unknown) {
        if (error instanceof RequestError) {
          dispatch(UiActions.setLoading(false));
          handleRequestError(error);
        }
        return;
      }
      dispatch(UiActions.setLoading(false));
      setIsCompleted(true);
    },
    [dispatch, handleRequestError],
  );

  const formMethods = useForm<Values>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      email: defaultEmail,
    },
  });

  const { isValid, isSubmitting } = formMethods.formState;

  const isDisabledSubmitButton = !isValid || isSubmitting;

  return {
    formMethods,
    onSubmit,
    isDisabledSubmitButton,
    isCompleted,
  };
};

export default useUnsubscribeEmail;
