import axios, { AxiosError, Cancel } from 'axios';
import { FirebaseError } from 'firebase/app';
import { ApiError } from 'types/error';
import { ERROR_TYPE } from 'constants/error';
import * as MESSAGES from 'constants/messages';

export const getErrorStatus = (error: AxiosError): number | undefined =>
  error.response && error.response.status;

export const isAxiosError = (error: AxiosError | Cancel): error is AxiosError =>
  'config' in error;

export const isClientError = (error: AxiosError) => {
  const status = getErrorStatus(error);
  return !!status && status >= 400 && status < 500;
};

export const isServerError = (error: AxiosError) => {
  const status = getErrorStatus(error);
  return !!status && status >= 500;
};

export const isTimeout = (error: AxiosError) => error.code === 'ECONNABORTED';

export const isNetworkError = (error: AxiosError) =>
  !error.response && !!error.request;

export const isCanceledError = (error: AxiosError | Cancel) =>
  axios.isCancel(error);

export const applyClientErrorMessage = (
  error: ApiError,
  clientErrorMessage: string,
) =>
  error.errorType === ERROR_TYPE.CLIENT_ERROR
    ? {
        ...error,
        message: clientErrorMessage,
      }
    : error;

export const isFirebaseError = (error: unknown): error is FirebaseError =>
  !!error && !!(error as FirebaseError).code;

export const getFirebaseErrorMessage = (errorCode: string): string => {
  switch (errorCode) {
    case 'auth/invalid-email':
      return MESSAGES.ERROR.INVALID_EMAIL;
    case 'auth/invalid-password':
    case 'auth/wrong-password':
    case 'auth/user-not-found':
      return MESSAGES.ERROR.INVALID_EMAIL_OR_PASSWORD;
    default:
      return MESSAGES.ERROR.FAILED_UNEXPECTED;
  }
};
