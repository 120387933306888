import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { Controller } from 'react-hook-form';
import { InputActionMeta } from 'react-select';
import media from 'styles/media';
import * as MESSAGES from 'constants/messages';
import { ProfileFormValues, ProfileFormControl } from 'types/profile';
import { Field, CreatableInputSelect } from 'components/molecules';
import { ReactSelectOption } from 'types/form';

const StyledCreatableInputSelect = styled(CreatableInputSelect)`
  width: 368px;

  ${media.mobile} {
    width: 100%;
  }
`;

type AcademicBackgroundSchoolFormFieldProps = {
  inputValue: string;
  error: string | undefined;
  options: ReactSelectOption[];
  isLoading: boolean;
  label?: string;
  onInputChange: (inputValue: string, actionMeta: InputActionMeta) => void;
  onCreateOption: (inputValue: string) => void;
  fetchMore: () => Promise<void>;
  control: ProfileFormControl<{
    academicBackground: Pick<ProfileFormValues['academicBackground'], 'school'>;
  }>;
};

const AcademicBackgroundSchoolFormField: FC<AcademicBackgroundSchoolFormFieldProps> = props => {
  const {
    inputValue,
    error,
    control,
    options,
    isLoading,
    label = MESSAGES.PROFILE.FIELD.ACADEMIC_BACKGROUND_SCHOOL.LABEL,
    fetchMore,
    onInputChange,
    onCreateOption,
  } = props;

  return (
    <Field
      label={label}
      required
      data-testid="academic-background-school-field"
    >
      <Controller
        control={control}
        name="academicBackground.school"
        render={innerProps => (
          <StyledCreatableInputSelect
            enterKeyHint="enter"
            inputValue={inputValue}
            value={innerProps.value}
            error={error}
            options={options}
            onInputChange={onInputChange}
            isLoading={isLoading}
            onMenuScrollToBottom={fetchMore}
            onChange={innerProps.onChange}
            onBlur={innerProps.onBlur}
            onCreateOption={onCreateOption}
            isClearable
            placeholder={
              MESSAGES.PROFILE.FIELD.ACADEMIC_BACKGROUND_SCHOOL.PLACEHOLDER
            }
            aria-label={label}
          />
        )}
      />
    </Field>
  );
};

export default AcademicBackgroundSchoolFormField;
