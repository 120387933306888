import React, { FC, ComponentProps, HTMLAttributes } from 'react';
import styled from 'styled-components/macro';
import theme from 'styles/theme';
import media from 'styles/media';
import { Field } from 'visits-style';
import { RequiredMark } from 'components/atoms';
import { Lock } from 'assets/svg';

const Wrapper = styled.div`
  margin-bottom: 12px;

  ${media.mobile} {
    margin-bottom: 8px;
  }
`;

const Note = styled.span`
  margin-left: 8px;
  color: ${theme.textSecondary};
  font-size: 12px;
`;

const IconLocked = styled(Lock)`
  width: 8px;
  margin-bottom: 4px;
  margin-left: 8px;
`;

const Label = styled.h5`
  display: inline-block;
  color: ${theme.labelDefault};
  font-size: 16px;
  font-weight: bold;

  ${media.mobile} {
    font-size: 14px;
  }
`;

type FieldLabelProps = {
  label: ComponentProps<typeof Field>['label'];
  required?: boolean;
  locked?: boolean;
  note?: string;
} & HTMLAttributes<HTMLDivElement>;

const FieldLabel: FC<FieldLabelProps> = ({
  label,
  required,
  locked,
  note,
  ...rest
}) => (
  <Wrapper {...rest}>
    <Label>{label}</Label>
    {required && <RequiredMark style={{ marginLeft: '4px' }} />}
    {locked && <IconLocked />}
    {note && <Note className="field-note">{note}</Note>}
  </Wrapper>
);

export default FieldLabel;
