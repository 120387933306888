import React, { FC, ComponentProps, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { onAuthStateChanged } from 'firebase/auth';
import apiInnovator from 'external/api/innovator';
import apiFirebase from 'external/firebase/firebase';
import { useHistory, Route } from 'react-router-dom';
import { UiActions } from 'modules/ui';
import RequestError from 'classes/RequestError';
import * as PROTECTED_ROUTES from 'routes/constants/protected';
import useErrorNotification from 'hooks/useErrorNotification';
import { PublicRouteLayout } from 'components/templates';

const LoginRoute: FC<ComponentProps<
  typeof Route & typeof PublicRouteLayout
>> = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isCheckedLoginStatus, setIsCheckedLoginStatus] = useState<boolean>(
    false,
  );
  const { handleRequestError } = useErrorNotification();

  // Redirect to private page, when the user has logged in already.
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(apiFirebase.auth(), async user => {
      if (!user) {
        setIsCheckedLoginStatus(true);
        return;
      }
      dispatch(UiActions.setLoading(true));
      let statusId;
      try {
        ({
          data: { statusId },
        } = await apiInnovator.getMatterStatus());
      } catch (error: unknown) {
        if (error instanceof RequestError && error.isNetworkError()) {
          handleRequestError(error);
        }
        dispatch(UiActions.setLoading(false));
        setIsCheckedLoginStatus(true);
        return;
      }

      dispatch(UiActions.setLoading(false));

      // StatusId is undefined when the profile has not been registered yet.
      if (statusId === undefined) {
        history.push(PROTECTED_ROUTES.REGISTER_PROFILE);
      } else {
        history.push(PROTECTED_ROUTES.PROFILE);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [dispatch, handleRequestError, history]);

  if (!isCheckedLoginStatus) return null;

  return (
    <PublicRouteLayout>
      <Route {...props} />
    </PublicRouteLayout>
  );
};

export default LoginRoute;
