import React, { FC } from 'react';
import * as MESSAGES from 'constants/messages';
import styled from 'styled-components/macro';
import media from 'styles/media';
import { Innovator } from 'proto/v1/apimodel/apimodel';
import {
  Label,
  ProfileField,
  GroupLabel,
  ProfileContentGroup,
} from 'components/atoms';

const SelfIntroduction = styled.div`
  font-size: 16px;
  line-height: 1.5;
  word-wrap: break-word;
  white-space: pre-wrap;

  ${media.mobile} {
    font-size: 14px;
  }
`;

type NewGraduateSelfIntroductionFieldProps = {
  selfIntroduction: Innovator['selfIntroduction'];
};

const NewGraduateSelfIntroductionField: FC<NewGraduateSelfIntroductionFieldProps> = props => {
  const { selfIntroduction } = props;

  return (
    <div data-testid="new-graduate-self-introduction-field">
      <GroupLabel>
        {MESSAGES.PROFILE.FIELD.NEW_GRADUATE_SELF_INTRODUCTION.LABEL}
      </GroupLabel>
      <ProfileContentGroup>
        <ProfileField style={{ marginBottom: 0 }}>
          <Label>
            {MESSAGES.PROFILE.FIELD.NEW_GRADUATE_SELF_INTRODUCTION.FIELD_LABEL}
          </Label>
          <SelfIntroduction>{selfIntroduction}</SelfIntroduction>
        </ProfileField>
      </ProfileContentGroup>
    </div>
  );
};

export default NewGraduateSelfIntroductionField;
