import React, { FC } from 'react';
import styled from 'styled-components';
import { Switch } from 'components/atoms';
import { Controller } from 'react-hook-form';
import { PublishSettingFormMethods } from 'types/publishSetting';
import * as MESSAGES from 'constants/messages';

const Layout = styled.div`
  margin-bottom: 55px;
`;

type PublishFieldProps = {
  formMethods: PublishSettingFormMethods;
};

const PublishField: FC<PublishFieldProps> = props => {
  const { formMethods } = props;
  const { control, setValue, errors } = formMethods;

  return (
    <Layout>
      <Controller
        name="publish"
        control={control}
        error={errors.publish}
        render={innerProps => (
          <Switch
            label={MESSAGES.PUBLISH_SETTING.FIELD.LABEL_PUBLISH}
            name={'publish'}
            checked={innerProps.value}
            onChange={e => {
              setValue('publish', e.target.checked);
            }}
            data-testid="publish-switch"
          />
        )}
      />
    </Layout>
  );
};

export default PublishField;
