import { createSelector } from 'reselect';
import { ActionsUnion, createAction } from './helper';

/*
 * Redux Action actionTypes
 */
export const SET_LOADING = 'ui/Loading';
export const SET_IS_SIDEBAR_SHRINKED = 'ui/isSidebarShrinked';

/*
 * Redux Actions
 */
export const UiActions = {
  setLoading: (loading: boolean) => createAction(SET_LOADING, loading),
  setIsSidebarShrinked: (isShrinked: boolean) =>
    createAction(SET_IS_SIDEBAR_SHRINKED, isShrinked),
};
export type Actions = ActionsUnion<typeof UiActions>;

/*
 * Selectors
 */
export const getUi = (state: any): UiState => state.ui;

export const getLoading = createSelector([getUi], ui => ui.loading);

export const getIsSidebarShrinked = createSelector(
  [getUi],
  ui => ui.isSidebarShrinked,
);

/*
 * Reducer
 */
export type UiState = {
  readonly loading: boolean;
  readonly isSidebarShrinked: boolean;
};

const initState: UiState = { loading: false, isSidebarShrinked: false };

export default function reducer(state = initState, action: Actions) {
  if (!action) return state;

  switch (action.type) {
    case SET_LOADING: {
      return { ...state, loading: action.payload };
    }
    case SET_IS_SIDEBAR_SHRINKED: {
      return { ...state, isSidebarShrinked: action.payload };
    }
    default:
      return state;
  }
}
