import { MatterStatusId } from 'types/matter';
import { MATTER_STATUS_ID } from 'constants/matter';

// eslint-disable-next-line import/prefer-default-export
export const checkShouldReviewProfile = ({
  registered,
  statusId,
}: {
  registered: boolean;
  statusId: MatterStatusId;
}): boolean => {
  return !registered || statusId === MATTER_STATUS_ID.SEND_BACK;
};
