import React, { FC } from 'react';
import * as MESSAGES from 'constants/messages';
import { NewGraduateProfile } from 'proto/v1/apimodel/apimodel';
import { Label, ProfileField, ProfileContent } from 'components/atoms';

type NewGraduatePreferredWorkingStyleFieldProps = {
  preferredWorkStyle: NewGraduateProfile['preferredWorkStyle'] | undefined;
};

const NewGraduatePreferredWorkingStyleField: FC<NewGraduatePreferredWorkingStyleFieldProps> = props => {
  const { preferredWorkStyle } = props;

  return (
    <ProfileField data-testid="new-graduate-preferred-working-style-field">
      <Label>
        {MESSAGES.PROFILE.FIELD.NEW_GRADUATE_PREFERRED_WORKING_STYLE.LABEL}
      </Label>
      <ProfileContent>{preferredWorkStyle?.name ?? '-'}</ProfileContent>
    </ProfileField>
  );
};

export default NewGraduatePreferredWorkingStyleField;
