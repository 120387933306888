import React, { Suspense, FC, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getLoading } from 'modules/ui';
import { ErrorNotificationActions } from 'modules/errorNotification';
import { Loading } from 'components/atoms';
import { Head } from 'components/templates';
import useMediaQuery from 'hooks/useMediaQuery';
import ErrorBoundary from './ErrorBoundary';

const GlobalLayout: FC = props => {
  const location = useLocation();
  const dispatch = useDispatch();
  const isLoading = useSelector(getLoading);
  const { isTablet } = useMediaQuery();

  // Close ErrorNotification when the page is changed.
  useEffect(() => {
    dispatch(ErrorNotificationActions.close());
  }, [dispatch, location.pathname]);

  const handleDocumentDbClick = useCallback((e: MouseEvent) => {
    e.preventDefault();
  }, []);

  // Prevent zooming by double tapping.
  useEffect(() => {
    if (isTablet) {
      document.addEventListener('dblclick', handleDocumentDbClick, {
        passive: false,
      });
      return () => {
        document.removeEventListener('dblclick', handleDocumentDbClick);
      };
    }
  }, [handleDocumentDbClick, isTablet]);

  return (
    <>
      <Head />
      <ErrorBoundary>
        <Suspense fallback={<Loading />}>
          {props.children}
          {isLoading && <Loading />}
        </Suspense>
      </ErrorBoundary>
    </>
  );
};

export default GlobalLayout;
