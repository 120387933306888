import React, { FC } from 'react';
import * as MESSAGES from 'constants/messages';
import { Label, ProfileField, ProfileContent } from 'components/atoms';
import { Innovator } from 'proto/v1/apimodel/apimodel';

type AcademicBackgroundFacultyFieldProps = {
  academicBackground: Innovator['academicBackground'] | undefined;
  label?: string | undefined;
};

const AcademicBackgroundFacultyField: FC<AcademicBackgroundFacultyFieldProps> = props => {
  const {
    academicBackground,
    label = MESSAGES.PROFILE.FIELD.ACADEMIC_BACKGROUND_FACULTY.LABEL,
  } = props;

  return (
    <ProfileField data-testid="academic-background-faculty-field">
      <Label>{label}</Label>
      <ProfileContent>{academicBackground?.faculty}</ProfileContent>
    </ProfileField>
  );
};

export default AcademicBackgroundFacultyField;
