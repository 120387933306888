import RequestError from 'classes/RequestError';
import { useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  ErrorNotificationActions,
  ErrorNotificationOptions,
} from 'modules/errorNotification';
import { applyClientErrorMessage } from 'utils/error';
import useLogout from './useLogout';

const useErrorNotification = () => {
  const dispatch = useDispatch();
  const { logout } = useLogout();
  const [_401ErrorHandled, set401ErrorHandled] = useState(false);

  useEffect(() => {
    if (_401ErrorHandled) logout();
  }, [_401ErrorHandled, logout]);

  const openErrorNotification = useCallback(
    (options: ErrorNotificationOptions) => {
      dispatch(ErrorNotificationActions.open(options));
    },
    [dispatch],
  );

  const handleRequestError = useCallback(
    (error: RequestError, clientErrorMessage?: string) => {
      if (error.isCanceled()) return;

      if (error.status === 401) {
        set401ErrorHandled(true);
        return;
      }

      const options = {
        ...applyClientErrorMessage(
          { ...error.toObject() },
          clientErrorMessage ?? error.message,
        ),
      };

      openErrorNotification(options);
    },
    [openErrorNotification],
  );

  return {
    openErrorNotification,
    handleRequestError,
  };
};

export default useErrorNotification;
