import React, { FC } from 'react';
import * as MESSAGES from 'constants/messages';
import { Label, ProfileField, ProfileContent } from 'components/atoms';
import { NewGraduateProfile } from 'proto/v1/apimodel/apimodel';

type NewGraduatePreferredBusinessTypesFieldProps = {
  preferredBusinessTypes:
    | NewGraduateProfile['preferredBusinessTypes']
    | undefined;
};

const NewGraduatePreferredBusinessTypesField: FC<NewGraduatePreferredBusinessTypesFieldProps> = props => {
  const { preferredBusinessTypes } = props;

  return (
    <ProfileField data-testid="new-graduate-preferred-business-types-field">
      <Label>
        {MESSAGES.PROFILE.FIELD.NEW_GRADUATE_PREFERRED_BUSINESS_TYPES.LABEL}
      </Label>
      <ProfileContent>
        {preferredBusinessTypes && preferredBusinessTypes.length > 0
          ? preferredBusinessTypes
              .map(preferredBusinessType => preferredBusinessType.name)
              .join('、')
          : '-'}
      </ProfileContent>
    </ProfileField>
  );
};

export default NewGraduatePreferredBusinessTypesField;
