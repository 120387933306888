import React, { FC, HTMLAttributes } from 'react';
import { imageSelector } from 'utils/image';
import { ImageCenter } from 'components/atoms';
import { MissionForInnovator } from 'types/mission';

type Props = {
  iconImage: MissionForInnovator['iconImage'] | undefined;
  size?: number;
} & HTMLAttributes<HTMLDivElement>;

const IconImageField: FC<Props> = ({ iconImage, size = 100, ...rest }) => {
  return (
    <ImageCenter
      imageSrc={imageSelector(iconImage, 'small') ?? ''}
      width={size}
      height={size}
      data-test-image={imageSelector(iconImage, 'small')}
      {...rest}
    />
  );
};

export default IconImageField;
