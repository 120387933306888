import React, { VFC, useCallback } from 'react';
import styled, { css } from 'styled-components/macro';
import {
  InnovatorChatRoom,
  InnovatorChatUser,
} from 'proto/v1/apimodel/apimodel';
import theme from 'styles/theme';
import media from 'styles/media';
import useMediaQuery from 'hooks/useMediaQuery';
import { CONSULTANT_CHAT_USER_NAME } from 'constants/chat';
import { formatChatRoomTimestamp } from 'utils/chat';
import { ChatUserIconConsultant } from 'assets/svg';
import { ImageCircle, EllipsisBox } from 'components/atoms';

const Wrapper = styled.div<{ isSelected: boolean }>`
  display: flex;
  width: 100%;
  padding: 16px 16px 16px 12px;
  border-bottom: 1px solid ${theme.borderDefault};
  background-color: ${theme.baseWhite};
  cursor: pointer;

  &:hover {
    background-color: rgba(35, 55, 128, 0.15);
  }

  ${media.tablet} {
    background-color: ${theme.bgDefault};
  }

  ${media.miniMobile} {
    padding: 16px 12px 16px 8px;
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: rgba(35, 55, 128, 0.15);
    `}
`;

const IconColumn = styled.div`
  display: flex;
  margin-right: 12px;
`;

const ContentColumn = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const ContentRowTop = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
`;

const ContentRowBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 8px;
`;

const Names = styled.div`
  width: 100%;
`;

const UnreadCountBadge = styled.div`
  display: inline-block;
  align-self: flex-start;
  padding: 2px 10px;
  border-radius: 18px;
  background-color: ${theme.basePrimary};
  color: ${theme.baseWhite};
  font-size: 12px;
  font-weight: bold;
  line-height: 12px;
`;

const ChatUserName = styled.div`
  max-width: 224px;
  overflow: hidden;
  color: ${theme.textPrimary};
  font-size: 16px;
  font-weight: bold;
  line-height: 23px;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const CompanyName = styled.div`
  margin-top: 4px;
  color: ${theme.textSecondary};
  font-size: 11px;
  line-height: 16px;

  ${media.tablet} {
    display: none;
  }
`;

const LatestMessage = styled.div`
  color: ${theme.textSecondary};
  font-size: 12px;
  line-height: 18px;
`;

const LatestMessageEllipsisBox = styled(EllipsisBox)`
  ${media.miniMobile} {
    width: 200px;
  }
`;

const UpdatedAt = styled.div`
  color: ${theme.textSecondary};
  font-size: 11px;
  line-height: 16px;
`;

const Divider = styled.div`
  margin-right: 26px;
  border-top: 1px solid ${theme.borderTable};
`;

const getChatUserName = (
  chatUser: InnovatorChatUser | undefined,
  isTablet: boolean,
) => {
  if (chatUser?.mission) {
    return isTablet ? chatUser.mission.companyName : chatUser.mission.name;
  }
  if (chatUser?.consultant) {
    return CONSULTANT_CHAT_USER_NAME;
  }
  return '';
};

type Props = {
  chatRoom: InnovatorChatRoom;
  onSelect: (chatRoomId: InnovatorChatRoom['chatRoomId']) => void;
  onClickMissionIcon: (mission: InnovatorChatUser['mission']) => Promise<void>;
  isSelected: boolean;
};

const ChatRoom: VFC<Props> = ({
  chatRoom,
  isSelected,
  onSelect,
  onClickMissionIcon,
}) => {
  const { isTablet } = useMediaQuery();

  const handleClickMissionChatUserIcon = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
      onClickMissionIcon(chatRoom?.chatUser?.mission);
    },
    [chatRoom, onClickMissionIcon],
  );

  return (
    <Wrapper
      data-testid="chat-room"
      isSelected={isSelected}
      onClick={() => {
        onSelect(chatRoom.chatRoomId);
      }}
    >
      <IconColumn>
        {chatRoom.chatUser?.consultant && (
          <ChatUserIconConsultant data-testid="chat-user-icon-consultant" />
        )}
        {chatRoom.chatUser?.mission && (
          <ImageCircle
            imageSrc={chatRoom.chatUser?.iconImageUrl ?? ''}
            diameter="56px"
            border
            data-testid="chat-user-icon"
            onClick={handleClickMissionChatUserIcon}
          />
        )}
      </IconColumn>
      <ContentColumn>
        <ContentRowTop>
          <Names>
            <ChatUserName>
              {getChatUserName(chatRoom.chatUser, isTablet)}
            </ChatUserName>
            {chatRoom.chatUser?.mission && (
              <CompanyName>
                {chatRoom.chatUser?.mission.companyName}
              </CompanyName>
            )}
          </Names>
          {chatRoom.unreadCount > 0 && (
            <UnreadCountBadge>{chatRoom.unreadCount}</UnreadCountBadge>
          )}
        </ContentRowTop>
        <Divider />
        <ContentRowBottom>
          <LatestMessage>
            <LatestMessageEllipsisBox line={2} width="212px" maxHeight="36px">
              {chatRoom.latestMessage}
            </LatestMessageEllipsisBox>
          </LatestMessage>
          <UpdatedAt>{formatChatRoomTimestamp(chatRoom.updatedAt)}</UpdatedAt>
        </ContentRowBottom>
      </ContentColumn>
    </Wrapper>
  );
};

export default ChatRoom;
