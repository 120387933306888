import React, { FC, HTMLAttributes } from 'react';
import styled from 'styled-components/macro';
import theme from 'styles/theme';

const MAX_COUNT = 999;

const Badge = styled.div`
  display: inline-block;
  padding: 2px 12px;
  border-radius: 18px;
  background: ${theme.labelBadge};
  color: ${theme.baseWhite};
  font-size: 12px;
  font-weight: bold;
  line-height: 12px;
  text-align: center;
`;

type Props = {
  count: number;
} & HTMLAttributes<HTMLDivElement>;

const NotificationBadge: FC<Props> = ({ count, ...rest }) => {
  return <Badge {...rest}>{count > MAX_COUNT ? `${MAX_COUNT}+` : count}</Badge>;
};

export default NotificationBadge;
