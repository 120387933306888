import React, { FC } from 'react';
import styled from 'styled-components/macro';
import * as MESSAGES from 'constants/messages';
import { formatToKiloBytes } from 'utils/file';
import { CareerProfile } from 'proto/v1/apimodel/apimodel';
import { Label, ProfileField, ProfileContent } from 'components/atoms';

type CareerResumeFileFieldProps = {
  resumeFile: CareerProfile['resumeFile'];
};

const FileName = styled.span`
  margin-right: 4px;
`;

const FileSize = styled.span``;

const CareerResumeFileField: FC<CareerResumeFileFieldProps> = props => {
  const { resumeFile } = props;

  return (
    <ProfileField data-testid="career-resume-file-field">
      <Label>{MESSAGES.PROFILE.FIELD.CAREER_RESUME_FILE.LABEL}</Label>
      <ProfileContent>
        {resumeFile ? (
          <>
            <FileName>{resumeFile.name}</FileName>
            <FileSize>({formatToKiloBytes(resumeFile.size)})</FileSize>
          </>
        ) : (
          MESSAGES.PROFILE.NONE
        )}
      </ProfileContent>
    </ProfileField>
  );
};

export default CareerResumeFileField;
