import React, { FC } from 'react';
import * as MESSAGES from 'constants/messages';
import styled from 'styled-components/macro';
import { Label, ProfileField, ProfileContent } from 'components/atoms';
import { Innovator } from 'proto/v1/apimodel/apimodel';

const Output = styled.a`
  display: block;
  margin-bottom: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

type OutputsFieldProps = {
  outputs: Innovator['outputs'] | undefined;
};

const OutputsField: FC<OutputsFieldProps> = props => {
  const { outputs } = props;

  return (
    <ProfileField data-testid="outputs-field">
      <Label>{MESSAGES.PROFILE.FIELD.OUTPUTS.LABEL}</Label>
      <ProfileContent>
        {outputs &&
          outputs.map((output, index) => (
            <Output
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              href={output}
              target="_blank"
              rel="noopener noreferrer"
            >
              {output}
            </Output>
          ))}
      </ProfileContent>
    </ProfileField>
  );
};

export default OutputsField;
