export default {
  INVALID_EMAIL: '不正なメールアドレスの形式です。',
  REQUIRED_EMAIL: 'メールアドレスを入力してください。',
  INVALID_PASSWORD:
    '8文字以上の英大小文字、数字、記号の組み合わせを入力してください。',
  REQUIRED_FIELD: '入力が必須の項目です。',
  REQUIRED_INPUT: '入力してください。',
  REQUIRED_SELECT: '選択してください。',
  URL_AND_EMAIL_NOT_ALLOWED: 'URLまたはメールアドレスの入力はできません。',
  SELECT_ONE_OR_MORE: '1つ以上選択してください。',
  MIN_LENGTH: '{{minLength}}文字以上入力してください。',
  MAX_REGISTER_COUNT: '登録できるのは最大{{maxCount}}件までです。',
  INVALID_URL: '正しいURL形式ではありません。',
  CONFIRMATION_PASSWORD_NOT_MATCH:
    '新しいパスワードと新しいパスワード(確認用)が一致しません。',
  FAILED_UNEXPECTED:
    'ご不便をおかけし申し訳ございません。\n繰り返し同様の操作をしても改善されない場合、お手数ですがINNOVATORS CAREER事務局までお問い合わせください。',
  FAILED_TO_GET: '読み込みに失敗しました。画面のリロードをお試しください。',
  TIMED_OUT:
    'タイムアウトが発生しました。\n時間をおいて再度操作を実行してください。',
  DISCONNECTED_NETWORK:
    'ネットワーク接続に失敗しました。\nネットワーク環境を確認し再度お試しください。',
  FAILED_TO_POST_MESSAGE_BECAUSE_OPPONENT_MISSION_IS_NOT_PUBLISHED:
    '現在、対象の企業が非公開設定となっているため、メッセージを送信できません。',
  FAILED_TO_GET_MISSION_BECAUSE_THE_MISSION_NOT_FOUND:
    '現在、対象の企業は非公開設定になっています。',
  INVALID_EMAIL_OR_PASSWORD:
    'メールアドレスまたはパスワードが正しくありません。',
  FAILED_TO_LOGIN: 'ログインに失敗しました。',
  FAILED_TO_SEND_EMAIL: 'メールの送信に失敗しました。',
  FAILED_TO_RESET_PASSWORD: 'パスワードの再設定に失敗しました。',
  INVALID_PHONE_NUMBER: '有効な電話番号を入力してください。',
  INVALID_MONTHLY_UNIT_PRICE: '3桁以内の正の整数を半角で入力してください。',
  INVALID_DTT_SCORE: '3桁以内の正の整数を半角で入力してください。',
  INVALID_WORKING_PERIOD:
    '開始年月は終了年月よりも前の年月を選択してください。',
  REQUIRED_INPUT_ALL_DTT_FIELDS:
    'デザイン思考テスト・スコアの両方を入力してください。',
  REQUIRED_INPUT_ALL_PROGRAMMING_EXPERIENCES_FIELDS:
    'プログラミング言語・経験レベルの両方を入力してください。',
  DUPLICATED_SELECT: '同じ{{label}}が選択されています。',
  OVER_MAX_RESUME_FILE_SIZE:
    '選択されたファイルのサイズが25MBを超えています。\n25MB以下のファイルを選択してください。',
  INVALID_PERIOD_ORDER: '開始年月は終了年月以下の年月を選択してください。',
};
