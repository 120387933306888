import React, { FC, HTMLAttributes } from 'react';
import * as MESSAGES from 'constants/messages';
import { MissionForInnovator } from 'types/mission';
import { SubTitle } from '../../Typography';

type Props = {
  fund: MissionForInnovator['fund'] | undefined;
} & HTMLAttributes<HTMLDivElement>;

const FundField: FC<Props> = ({ fund, ...rest }) => {
  return (
    <div {...rest}>
      <SubTitle>{MESSAGES.MISSION_CARD.FIELD.FUND.LABEL}</SubTitle>
      <p>{fund ? fund.displayText : '-'}</p>
    </div>
  );
};

export default FundField;
