import React, { FC } from 'react';
import { Stepper as StepperType } from 'hooks/useStepper';
import { isEqual } from 'utils/object';
import { stepsNewGraduate as steps } from './steps';
import {
  RegisterProfileFormNewGraduateFirst,
  RegisterProfileFormNewGraduateSecond,
  RegisterProfileFormNewGraduateThird,
} from './Forms';
import { RegisterProfileConfirmNewGraduate } from './Confirms';
import RegisterProfileCompleted from './Completed';

type StepContentNewGraduateProps = {
  currentStepIndex: number;
  stepper: StepperType;
};

const StepContentNewGraduate: FC<StepContentNewGraduateProps> = ({
  currentStepIndex,
  stepper,
}) => {
  return (
    <>
      {currentStepIndex === steps.formFirst.stepIndex && (
        <RegisterProfileFormNewGraduateFirst stepper={stepper} />
      )}
      {currentStepIndex === steps.formSecond.stepIndex && (
        <RegisterProfileFormNewGraduateSecond stepper={stepper} />
      )}
      {currentStepIndex === steps.formThird.stepIndex && (
        <RegisterProfileFormNewGraduateThird stepper={stepper} />
      )}
      {currentStepIndex === steps.confirm.stepIndex && (
        <RegisterProfileConfirmNewGraduate stepper={stepper} />
      )}
      {currentStepIndex === steps.completed.stepIndex && (
        <RegisterProfileCompleted />
      )}
    </>
  );
};

export default React.memo(StepContentNewGraduate, isEqual);
