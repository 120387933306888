import React, { FC, HTMLAttributes } from 'react';
import * as MESSAGES from 'constants/messages';
import { MissionForInnovator } from 'types/mission';
import { SubTitle } from '../../Typography';

type Props = {
  companyEstablishedDate: MissionForInnovator['companyEstablishedDate'];
} & HTMLAttributes<HTMLDivElement>;

const CompanyEstablishedDateField: FC<Props> = ({
  companyEstablishedDate,
  ...rest
}) => {
  return (
    <div {...rest}>
      <SubTitle>
        {MESSAGES.MISSION_CARD.FIELD.COMPANY_ESTABLISHED_DATE.LABEL}
      </SubTitle>
      <p>
        {companyEstablishedDate
          ? `${companyEstablishedDate.year}/${companyEstablishedDate.month}`
          : '-'}
      </p>
    </div>
  );
};

export default CompanyEstablishedDateField;
