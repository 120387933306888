import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { Controller } from 'react-hook-form';
import media from 'styles/media';
import * as MESSAGES from 'constants/messages';
import { NO_SELECT_NUMBER } from 'constants/form';
import {
  ProfileFormItems,
  ProfileFormControl,
  ProfileFormValues,
} from 'types/profile';
import { Selectbox } from 'components/atoms';
import { Field } from 'components/molecules';

const StyledSelectbox = styled(Selectbox)`
  width: 368px;

  ${media.mobile} {
    width: 100%;
  }
`;

type CareerPreferredEmploymentTypeFormFieldProps = {
  error: string | undefined;
  control: ProfileFormControl<{
    career: Pick<ProfileFormValues['career'], 'preferredEmploymentTypeId'>;
  }>;
  employmentTypes: ProfileFormItems['employmentTypes'];
};

const CareerPreferredEmploymentTypeFormField: FC<CareerPreferredEmploymentTypeFormFieldProps> = props => {
  const { error, control, employmentTypes } = props;

  return (
    <Field
      label={MESSAGES.PROFILE.FIELD.PREFERRED_EMPLOYMENT_TYPE.LABEL}
      required
    >
      <Controller
        control={control}
        name="career.preferredEmploymentTypeId"
        render={innerProps => (
          <StyledSelectbox
            value={innerProps.value}
            error={error}
            options={employmentTypes}
            aria-label={MESSAGES.PROFILE.FIELD.PREFERRED_EMPLOYMENT_TYPE.LABEL}
            onChange={innerProps.onChange}
            onBlur={innerProps.onBlur}
            noSelectValue={NO_SELECT_NUMBER}
          />
        )}
      />
    </Field>
  );
};

export default CareerPreferredEmploymentTypeFormField;
