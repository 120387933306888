import React, { FC } from 'react';
import * as MESSAGES from 'constants/messages';
import { Label, ProfileField, ProfileContent } from 'components/atoms';
import { CareerProfile } from 'proto/v1/apimodel/apimodel';

type CareerExpectationsFieldProps = {
  expectations: CareerProfile['expectations'] | undefined;
};

const CareerExpectationsField: FC<CareerExpectationsFieldProps> = props => {
  const { expectations } = props;

  return (
    <ProfileField data-testid="expectations-field">
      <Label>{MESSAGES.PROFILE.FIELD.EXPECTATIONS.LABEL}</Label>
      <ProfileContent>
        {expectations
          ? expectations.map(expectation => expectation.name).join('、')
          : '-'}
      </ProfileContent>
    </ProfileField>
  );
};

export default CareerExpectationsField;
