import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components/macro';
import media from 'styles/media';
import theme from 'styles/theme';
import * as MESSAGES from 'constants/messages';

const Wrapper = styled.div`
  padding-top: 212px;
  text-align: center;

  ${media.smallMobile} {
    padding-top: 128px;
  }
`;

const Title = styled.p`
  margin-bottom: 32px;
  color: ${theme.basePrimary};
  font-size: 40px;
  font-weight: bold;
  line-height: 1.5;

  ${media.smallMobile} {
    font-size: 24px;
  }
`;

const Description = styled.p`
  margin-bottom: 88px;
  color: ${theme.textBody};
  font-size: 16px;
  line-height: 1.5;

  ${media.smallMobile} {
    font-size: 14px;
  }
`;

const ExpiredUrl: FC = () => {
  return (
    <>
      <Helmet>
        <title>{MESSAGES.HEAD.TITLE.EXPIRED_URL}</title>
      </Helmet>
      <Wrapper data-testid="expired-url">
        <Title>{MESSAGES.EXPIRED_URL.TITLE}</Title>
        <Description>{MESSAGES.EXPIRED_URL.DESCRIPTION}</Description>
      </Wrapper>
    </>
  );
};

export default ExpiredUrl;
