import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { Controller } from 'react-hook-form';
import media from 'styles/media';
import * as MESSAGES from 'constants/messages';
import {
  MAX_PREFERRED_WORK_LOCATIONS,
  ADDRESS_OVERSEAS_ID,
  PREFERRED_WORK_LOCATIONS_CIRCLE_NUMBERS,
} from 'constants/profile';
import {
  ProfileFormItems,
  ProfileFormControl,
  ProfileFormMethods,
} from 'types/profile';
import { range } from 'utils/array';
import { Selectbox, ErrorMessage } from 'components/atoms';
import { Field } from 'components/molecules';
import { NO_SELECT_NUMBER } from 'constants/form';

type PreferredWorkLocationsFormFieldProps = {
  error: string | undefined;
  japanPrefectures: ProfileFormItems['japanPrefectures'];
  control: ProfileFormControl<'preferredWorkLocationJapanPrefectureIds'>;
  trigger: ProfileFormMethods<
    'preferredWorkLocationJapanPrefectureIds'
  >['trigger'];
};

const SelectBoxes = styled.div`
  display: grid;
  grid-column-gap: 16px;
  grid-template-columns: 176px 176px 176px;

  ${media.mobile} {
    grid-row-gap: 8px;
    grid-template-columns: 1fr;
  }
`;

const StyledSelectbox = styled(Selectbox)`
  width: 176px;

  ${media.mobile} {
    width: 100%;
  }
`;

const PreferredWorkLocationsFormField: FC<PreferredWorkLocationsFormFieldProps> = props => {
  const { error, japanPrefectures, control, trigger } = props;
  const indexes = range(0, MAX_PREFERRED_WORK_LOCATIONS - 1);

  return (
    <Field
      label={MESSAGES.PROFILE.FIELD.PREFERRED_WORK_LOCATIONS.LABEL}
      note={MESSAGES.PROFILE.FIELD.PREFERRED_WORK_LOCATIONS.NOTE}
      required
    >
      <SelectBoxes>
        {indexes.map(index => (
          <Controller
            key={index}
            control={control}
            name={`preferredWorkLocationJapanPrefectureIds[${index}]`}
            render={innerProps => (
              <StyledSelectbox
                value={innerProps.value}
                idName="japanPrefectureId"
                options={[
                  {
                    japanPrefectureId: NO_SELECT_NUMBER,
                    name: `${MESSAGES.PROFILE.FIELD.PREFERRED_WORK_LOCATIONS.NO_SELECT}${PREFERRED_WORK_LOCATIONS_CIRCLE_NUMBERS[index]}`,
                  },
                  ...japanPrefectures,
                  {
                    japanPrefectureId: ADDRESS_OVERSEAS_ID,
                    name: MESSAGES.PROFILE.OVERSEAS,
                  },
                ]}
                aria-label={
                  MESSAGES.PROFILE.FIELD.PREFERRED_WORK_LOCATIONS.LABEL
                }
                onChange={e => {
                  innerProps.onChange(e);
                  trigger('preferredWorkLocationJapanPrefectureIds');
                }}
                onBlur={() => {
                  innerProps.onBlur();
                  trigger('preferredWorkLocationJapanPrefectureIds');
                }}
              />
            )}
            defaultValue={NO_SELECT_NUMBER}
          />
        ))}
      </SelectBoxes>
      {error && (
        <ErrorMessage data-testid="error-message-preferred-work-location">
          {error}
        </ErrorMessage>
      )}
    </Field>
  );
};

export default PreferredWorkLocationsFormField;
