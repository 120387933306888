import { lazy } from 'react';

export const Login = lazy(
  () => import(/* webpackChunkName: 'login' */ 'components/pages/Public/Login'),
);
export const CreateAccount = lazy(
  () =>
    import(
      /* webpackChunkName: 'createAccount' */ 'components/pages/Public/CreateAccount'
    ),
);
export const PasswordReset = lazy(
  () =>
    import(
      /* webpackChunkName: 'passwordReset' */ 'components/pages/Public/Password/Reset'
    ),
);
