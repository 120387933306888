import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { INNOVATOR_TYPE } from 'constants/innovator';
import { InnovatorType } from 'types/innovator';
import * as MESSAGES from 'constants/messages';
import * as PUBLIC_ROUTES from 'routes/constants/public';
import media from 'styles/media';
import { Logo } from 'components/atoms';
import BaseHeader from './BaseHeader';

const StyledHeader = styled(BaseHeader)`
  ${media.miniMobile} {
    padding: 24px 12px;
  }
`;

const StyledLogo = styled(Logo)`
  align-self: center;

  ${media.miniMobile} {
    font-size: 18px;
  }
`;

const LabelForEngineer = styled.span`
  position: relative;
  bottom: 2px;
  box-sizing: border-box;
  margin-left: 12px;
  padding: 5px 12px;
  border: 1px solid #aaa;
  border-radius: 4px;
  background-color: #1e3963;
  font-size: 12px;
  font-weight: bold;
  line-height: 14px;
`;

const LogoLink = styled(Link)`
  text-decoration: none;
`;

type PublicHeaderProps = {
  innovatorType: InnovatorType | undefined;
};

const PublicHeader: FC<PublicHeaderProps> = ({ innovatorType }) => {
  return (
    <StyledHeader>
      <LogoLink to={PUBLIC_ROUTES.LOGIN}>
        <StyledLogo />
      </LogoLink>
      {innovatorType === INNOVATOR_TYPE.ENGINEER && (
        <LabelForEngineer>
          {MESSAGES.HEADER.LABEL.FOR_ENGINEER}
        </LabelForEngineer>
      )}
    </StyledHeader>
  );
};

export default PublicHeader;
