import { CancelToken } from 'axios';
import {
  GetInnovatorFormResponse,
  GetPublishSettingFormResponse,
  GetPublishSettingResponse,
  UpdateInnovatorRequest,
  UpdateInnovatorResponse,
  UpdatePublishSettingRequest,
  UpdatePublishSettingResponse,
  GetInnovatorAccountSettingsResponse,
  ChangeInnovatorAccountSettingsRequest,
  ChangeInnovatorAccountSettingsResponse,
  SendPublishRequestRequest,
  SendPublishRequestResponse,
  GetMatterStatusResponse,
  CreateInnovatorChatRoomResponse,
  GetInnovatorChatMessagesRequest,
  GetInnovatorChatMessagesResponse,
  PostInnovatorChatMessageRequest,
  PostInnovatorChatMessageResponse,
  PostInnovatorChatFileRequest,
  PostInnovatorChatFileResponse,
  DeleteInnovatorChatMessageRequest,
  DeleteInnovatorChatMessageResponse,
  FlushInnovatorChatUnreadCountRequest,
  FlushInnovatorChatUnreadCountResponse,
  GetConfirmedJoinCompanySettingResponse,
  PostConfirmedJoinCompanyRequest,
  PostConfirmedJoinCompanyResponse,
  GetMissionCompanyNamesResponse,
  GetMissionCompanyNamesRequest,
  GetInnovatorChatRoomsRequest,
  GetInnovatorChatRoomsResponse,
  GetInnovatorChatRoomsRefreshResponse,
  GetInnovatorChatRoomsRefreshRequest,
  GetMissionRequest,
  GetMissionResponse,
} from 'proto/v1/innovatorservice/innovatorservice';
import client from 'external/api/config/client';
import apiFirebase from 'external/firebase/firebase';

export default {
  async getInnovatorForm(
    cancelToken: CancelToken,
  ): Promise<{ data: GetInnovatorFormResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/innovators/form`;
    return client(token)
      .get(url, {
        cancelToken,
      })
      .then(x => {
        return {
          data: GetInnovatorFormResponse.fromJSON(x.data),
        };
      });
  },

  async updateInnovator(
    req: UpdateInnovatorRequest,
  ): Promise<{ data: UpdateInnovatorResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/innovators/${req.innovatorId}`;
    return client(token)
      .put(url, req)
      .then(x => {
        return {
          data: UpdateInnovatorResponse.fromJSON(x.data),
        };
      });
  },

  async sendPublishRequest(
    req: SendPublishRequestRequest,
  ): Promise<{
    data: SendPublishRequestResponse;
  }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/innovators/${req.innovatorId}/publish`;
    return client(token)
      .put(url)
      .then(x => {
        return {
          data: SendPublishRequestResponse.fromJSON(x.data),
        };
      });
  },

  async getPublishSettingForm(
    cancelToken?: CancelToken,
  ): Promise<{ data: GetPublishSettingFormResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/innovators/publish_setting_form`;
    return client(token)
      .get(url, {
        cancelToken,
      })
      .then(x => {
        return {
          data: GetPublishSettingFormResponse.fromJSON(x.data),
        };
      });
  },

  async getPublishSetting(
    cancelToken?: CancelToken,
  ): Promise<{ data: GetPublishSettingResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/innovators/publish_setting`;
    return client(token)
      .get(url, {
        cancelToken,
      })
      .then(x => {
        return {
          data: GetPublishSettingResponse.fromJSON(x.data),
        };
      });
  },

  async updatePublishSetting(
    req: UpdatePublishSettingRequest,
  ): Promise<{ data: UpdatePublishSettingResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/innovators/${req.innovatorId}/publish_setting`;
    return client(token).put(url, req);
  },

  async getAccountSetting(
    cancelToken?: CancelToken,
  ): Promise<{ data: GetInnovatorAccountSettingsResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/innovators/account_setting`;
    return client(token)
      .get(url, {
        cancelToken,
      })
      .then(x => {
        return {
          data: GetInnovatorAccountSettingsResponse.fromJSON(x.data),
        };
      });
  },

  async updateAccountSetting(
    req: ChangeInnovatorAccountSettingsRequest,
  ): Promise<{ data: ChangeInnovatorAccountSettingsResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/innovators/${req.innovatorId}/account_setting`;
    return client(token).put(url, req);
  },

  async getMatterStatus(
    cancelToken?: CancelToken,
  ): Promise<{ data: GetMatterStatusResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/innovators/matter_status`;
    return client(token)
      .get(url, {
        cancelToken,
      })
      .then(x => {
        return {
          data: GetMatterStatusResponse.fromJSON(x.data),
        };
      });
  },

  async createChatRoom(): Promise<{ data: CreateInnovatorChatRoomResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/innovators/chatrooms`;
    return client(token)
      .post(url)
      .then(res => {
        return {
          data: CreateInnovatorChatRoomResponse.fromJSON(res.data),
        };
      });
  },

  async getChatRooms(
    req: GetInnovatorChatRoomsRequest,
    cancelToken?: CancelToken,
  ): Promise<{ data: GetInnovatorChatRoomsResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/innovators/chatrooms`;
    return client(token)
      .get(url, { params: req, cancelToken })
      .then(res => {
        return {
          data: GetInnovatorChatRoomsRefreshResponse.fromJSON(res.data),
        };
      });
  },

  async getChatRoomsUpdates(
    req: GetInnovatorChatRoomsRefreshRequest,
    cancelToken?: CancelToken,
  ): Promise<{ data: GetInnovatorChatRoomsRefreshResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/innovators/chatrooms/refresh`;
    return client(token)
      .get(url, { params: req, cancelToken })
      .then(x => {
        return {
          data: GetInnovatorChatRoomsRefreshResponse.fromJSON(x.data),
        };
      });
  },

  async getChatMessages(
    req: GetInnovatorChatMessagesRequest,
    cancelToken?: CancelToken,
  ): Promise<{ data: GetInnovatorChatMessagesResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/innovators/chatrooms/${req.chatRoomId}/messages`;
    return client(token)
      .get(url, { params: req, cancelToken })
      .then(res => {
        return {
          data: GetInnovatorChatMessagesResponse.fromJSON(res.data),
        };
      });
  },

  async postChatMessage(
    req: PostInnovatorChatMessageRequest,
  ): Promise<{ data: PostInnovatorChatMessageResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/innovators/chatrooms/${req.chatRoomId}/messages`;
    return client(token)
      .post(url, PostInnovatorChatMessageRequest.toJSON(req))
      .then(res => {
        return {
          data: PostInnovatorChatMessageResponse.fromJSON(res.data),
        };
      });
  },

  async postChatFile(
    req: PostInnovatorChatFileRequest,
  ): Promise<{ data: PostInnovatorChatFileResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/innovators/chatrooms/${req.chatRoomId}/files`;
    return client(token)
      .post(url, PostInnovatorChatFileRequest.toJSON(req))
      .then(res => {
        return {
          data: PostInnovatorChatFileResponse.fromJSON(res.data),
        };
      });
  },

  async deleteChatMessage(
    req: DeleteInnovatorChatMessageRequest,
  ): Promise<{ data: DeleteInnovatorChatMessageResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/innovators/chatrooms/${req.chatRoomId}/messages/${req.messageId}`;
    return client(token)
      .delete(url)
      .then(x => {
        return {
          data: DeleteInnovatorChatMessageResponse.fromJSON(x.data),
        };
      });
  },

  async flushUnreadCount(
    req: FlushInnovatorChatUnreadCountRequest,
    cancelToken?: CancelToken,
  ): Promise<{ data: FlushInnovatorChatUnreadCountResponse }> {
    const { chatRoomId } = req;
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/innovators/chatrooms/${chatRoomId}/flush`;
    return client(token)
      .put(url, FlushInnovatorChatUnreadCountRequest.toJSON({ chatRoomId }), {
        cancelToken,
      })
      .then(x => {
        return {
          data: FlushInnovatorChatUnreadCountResponse.fromJSON(x.data),
        };
      });
  },

  async getConfirmedJoinCompanySetting(
    cancelToken: CancelToken,
  ): Promise<{ data: GetConfirmedJoinCompanySettingResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/innovators/confirmed_join_company_setting`;
    return client(token)
      .get(url, {
        cancelToken,
      })
      .then(x => {
        return {
          data: GetConfirmedJoinCompanySettingResponse.fromJSON(x.data),
        };
      });
  },

  async postConfirmedJoinCompany(
    req: PostConfirmedJoinCompanyRequest,
  ): Promise<{ data: PostConfirmedJoinCompanyResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/innovators/confirmed_join_company`;
    return client(token)
      .post(url, PostConfirmedJoinCompanyRequest.toJSON(req))
      .then(res => {
        return {
          data: PostConfirmedJoinCompanyResponse.fromJSON(res.data),
        };
      });
  },

  async getMissionCompanyNames(
    req: GetMissionCompanyNamesRequest,
  ): Promise<{ data: GetMissionCompanyNamesResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/innovators/mission_company_name`;
    return client(token)
      .get(url, {
        params: req,
      })
      .then(x => {
        return {
          data: GetMissionCompanyNamesResponse.fromJSON(x.data),
        };
      });
  },

  async getMission(
    req: GetMissionRequest,
    cancelToken: CancelToken,
  ): Promise<{ data: GetMissionResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/innovators/missions/${req.missionId}`;
    return client(token)
      .get(url, {
        params: req,
        cancelToken,
      })
      .then(x => {
        return {
          data: GetMissionResponse.fromJSON(x.data),
        };
      });
  },
};
