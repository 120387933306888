import styled from 'styled-components/macro';
import theme from 'styles/theme';
import media from 'styles/media';

const ErrorMessage = styled.p`
  color: ${theme.baseError};
  font-size: 14px;
  line-height: 1.5;

  ${media.mobile} {
    font-size: 12px;
  }
`;

export default ErrorMessage;
