import React, { FC } from 'react';
import { CSSTransition } from 'react-transition-group';

type Props = {
  isShrinked: boolean;
};

const SidebarTransition: FC<Props> = ({ isShrinked, children }) => {
  return (
    <CSSTransition
      mountOnEnter
      in={!isShrinked}
      appear={!isShrinked}
      timeout={300}
    >
      {children}
    </CSSTransition>
  );
};

export default SidebarTransition;
