import { useCallback, useEffect } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { extractHiragana, hiraganaToKatakana } from 'utils/string';

const useAutoCompleteKanaName = ({ watch, setValue }: UseFormMethods<any>) => {
  const watchFirstName = watch('firstName');
  const watchLastName = watch('lastName');

  const autoCompleteFirstNameKana = useCallback(() => {
    const hiraganaValue = extractHiragana(watchFirstName);
    if (hiraganaValue === '') return;
    const katakanaValue = hiraganaToKatakana(hiraganaValue);
    setValue('firstNameKana', katakanaValue);
  }, [watchFirstName, setValue]);

  const autoCompleteLastNameKana = useCallback(() => {
    const hiraganaValue = extractHiragana(watchLastName);
    if (hiraganaValue === '') return;
    const katakanaValue = hiraganaToKatakana(hiraganaValue);
    setValue('lastNameKana', katakanaValue);
  }, [watchLastName, setValue]);

  useEffect(() => {
    autoCompleteFirstNameKana();
    autoCompleteLastNameKana();
  }, [autoCompleteFirstNameKana, autoCompleteLastNameKana]);
};

export default useAutoCompleteKanaName;
