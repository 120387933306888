import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import media from 'styles/media';
import * as MESSAGES from 'constants/messages';
import { MATTER_STATUS_ID } from 'constants/matter';
import { getMatterStatus } from 'modules/matter';
import { getIsCareer, getIsNewGraduate } from 'modules/account';
import useFetchInnovatorForm from 'hooks/profile/useFetchInnovatorForm';
import { Information } from 'components/atoms';
import { ProfileNavBar } from 'components/organisms';
import { PrivateRouteLayoutContent } from 'components/templates';
import CareerProfileBasicInfo from './CareerProfileBasicInfo';
import CareerProfileJobHistory from './CareerProfileJobHistory';
import CareerProfileSkillsAndPreferredConditions from './CareerProfileSkillsAndPreferredConditions';
import NewGraduateProfileBasicInfo from './NewGraduateProfileBasicInfo';
import NewGraduateProfileSkillsAndPreferredConditions from './NewGraduateProfileSkillsAndPreferredConditions';
import NewGraduateProfileExperiences from './NewGraduateProfileExperiences';

const ProfileContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 42px;

  ${media.mobile} {
    padding-top: 32px;
  }
`;

const InReviewInformation = styled(Information)`
  margin-bottom: 32px;
  font-size: 14px;
`;

const Profile: FC = () => {
  const { statusId } = useSelector(getMatterStatus);
  const isCareer = useSelector(getIsCareer);
  const isNewGraduate = useSelector(getIsNewGraduate);
  const { formItems } = useFetchInnovatorForm();

  return (
    <>
      <Helmet>
        <title>{MESSAGES.HEAD.TITLE.PROFILE}</title>
      </Helmet>

      <div>
        {isCareer && <ProfileNavBar />}
        <PrivateRouteLayoutContent>
          <ProfileContent data-testid="profile">
            {statusId === MATTER_STATUS_ID.WAITING && (
              <InReviewInformation>
                {MESSAGES.PROFILE_SETTING.DESCRIPTION.IN_REVIEW}
              </InReviewInformation>
            )}
            {isCareer && (
              <>
                <CareerProfileBasicInfo formItems={formItems} />

                <CareerProfileJobHistory formItems={formItems} />

                <CareerProfileSkillsAndPreferredConditions
                  formItems={formItems}
                />
              </>
            )}
            {isNewGraduate && (
              <>
                <NewGraduateProfileBasicInfo formItems={formItems} />

                <NewGraduateProfileSkillsAndPreferredConditions
                  formItems={formItems}
                />

                <NewGraduateProfileExperiences formItems={formItems} />
              </>
            )}
          </ProfileContent>
        </PrivateRouteLayoutContent>
      </div>
    </>
  );
};

export default Profile;
