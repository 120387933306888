import React, { FC } from 'react';
import * as MESSAGES from 'constants/messages';
import styled from 'styled-components/macro';
import media from 'styles/media';
import { NewGraduateProfile } from 'proto/v1/apimodel/apimodel';
import {
  Label,
  ProfileField,
  ProfileContent,
  GroupLabel,
  ProfileContentGroup,
  ProfileContentGroupItem,
} from 'components/atoms';

const ResearchRoomContent = styled.div`
  font-size: 16px;
  line-height: 1.5;
  word-wrap: break-word;
  white-space: pre-wrap;

  ${media.mobile} {
    font-size: 14px;
  }
`;

type NewGraduateSelfIntroductionFieldProps = {
  researchRooms: NewGraduateProfile['researchRooms'] | undefined;
};

const NewGraduateSelfIntroductionField: FC<NewGraduateSelfIntroductionFieldProps> = props => {
  const { researchRooms } = props;

  return (
    <div data-testid="new-graduate-research-rooms-field">
      <GroupLabel>
        {MESSAGES.PROFILE.FIELD.NEW_GRADUATE_RESEARCH_ROOMS.LABEL}
      </GroupLabel>
      <ProfileContentGroup>
        {researchRooms && researchRooms.length > 0
          ? researchRooms.map((researchRoom, index) => (
              <ProfileContentGroupItem
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                data-testid="new-graduate-research-rooms-field-item"
              >
                <ProfileField data-testid="new-graduate-research-rooms-field-name">
                  <Label>
                    {
                      MESSAGES.PROFILE.FIELD.NEW_GRADUATE_RESEARCH_ROOMS.NAME
                        .LABEL
                    }
                  </Label>
                  <ProfileContent>{researchRoom.name || '-'}</ProfileContent>
                </ProfileField>
                <ProfileField data-testid="new-graduate-research-rooms-field-content">
                  <Label>
                    {
                      MESSAGES.PROFILE.FIELD.NEW_GRADUATE_RESEARCH_ROOMS.CONTENT
                        .LABEL
                    }
                  </Label>
                  <ResearchRoomContent>
                    {researchRoom.content || '-'}
                  </ResearchRoomContent>
                </ProfileField>
              </ProfileContentGroupItem>
            ))
          : MESSAGES.COMMON.DESCRIPTION.NOT_REGISTERED}
      </ProfileContentGroup>
    </div>
  );
};

export default NewGraduateSelfIntroductionField;
