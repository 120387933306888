import React, { FC, HTMLAttributes } from 'react';
import styled from 'styled-components/macro';
import theme from 'styles/theme';
import media from 'styles/media';
import * as MESSAGES from 'constants/messages';
import { MissionForInnovator } from 'types/mission';
import { imageSelector } from 'utils/image';
import { WrapText, LinkText, UrlLink } from 'components/atoms';
import { Title } from '../../Typography';

const MissionProductTitle = styled(Title)`
  margin-bottom: 0;
  padding-left: 24px;

  ${media.tablet} {
    margin-bottom: 24px;
    padding-left: 0;
  }
`;

const MissionProduct = styled.div`
  padding-top: 32px;
  padding-right: 32px;
  padding-left: 24px;

  &:not(:first-child) {
    border-top: 1px solid ${theme.borderDefault};
  }

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  ${media.tablet} {
    box-sizing: border-box;
    padding: 20px;
    border: 2px solid ${theme.borderCard};
    border-radius: 8px;

    &:not(:first-child) {
      border-top: 2px solid ${theme.borderCard};
    }
  }
`;

const MissionProductImage = styled.img`
  width: 100%;
  height: 254px;
  margin-bottom: 16px;

  ${media.tablet} {
    height: auto;
  }
`;

const MissionProductName = styled.div`
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;

  ${media.tablet} {
    margin-bottom: 12px;
  }
`;

const MissionProductOverview = styled.div`
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 1.5;
`;

const MissionProductTags = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;

  ${media.tablet} {
    row-gap: 12px;
  }
`;

const MissionProductTag = styled.div`
  box-sizing: border-box;
  padding: 3px 12px;
  border: 1px solid ${theme.labelSecondary};
  border-radius: 18px;
  background: ${theme.baseWhite};
  color: ${theme.labelSecondary};
  font-size: 12px;
  line-height: 1.5;
`;

const MissionProductFieldTitle = styled.div`
  margin-bottom: 24px;
  color: ${theme.textPrimary};
  font-size: 20px;
  font-weight: bold;
  line-height: 1.5;
`;

const MissionProductItem = styled.div`
  margin-top: 32px;
`;

const MissionProductUrlLink = styled(UrlLink)`
  margin-bottom: 20px;

  ${media.tablet} {
    margin-bottom: 16px;
  }
`;

const MissionProductUrl = styled.span`
  display: inline-block;
  max-width: 488px;
  margin-right: 5px;
  overflow: hidden;
  text-decoration: underline;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${media.tablet} {
    max-width: 690px;
  }

  ${media.mobile} {
    max-width: 416px;
  }

  ${media.smallMobile} {
    max-width: 262px;
  }

  ${media.miniMobile} {
    max-width: 210px;
  }
`;

const MissionProductText = styled(WrapText)`
  overflow: auto;
  line-height: 26px;
`;

const ProductTargetTags = styled.div`
  display: flex;
  gap: 4px;
  margin-bottom: 8px;

  ${media.tablet} {
    margin-bottom: 16px;
  }
`;

const ProductTargetTag = styled.div`
  padding: 5px 12px 3px 12px;
  border: 1px solid ${theme.borderDefault};
  border-radius: 4px;
  background-color: ${theme.baseWhite};
  color: ${theme.textCardSecondary};
  font-size: 12px;
  line-height: 17px;
`;

type Props = {
  missionProducts: MissionForInnovator['missionProducts'] | undefined;
  missionProductRefs?: React.RefObject<HTMLDivElement>[];
} & HTMLAttributes<HTMLDivElement>;

const ProductDetailContent: FC<Props> = ({
  missionProducts,
  missionProductRefs,
  ...rest
}) => {
  return (
    <div {...rest}>
      <MissionProductTitle>
        {MESSAGES.MISSION_CARD.FIELD.MISSION_PRODUCTS.LABEL}
      </MissionProductTitle>
      <div>
        {(missionProducts ?? []).map((missionProduct, index) => (
          <MissionProduct
            key={missionProduct.missionProductId}
            ref={missionProductRefs && missionProductRefs[index]}
            data-testid="mission-product"
          >
            <MissionProductImage
              src={imageSelector(missionProduct.image, 'large') ?? ''}
              data-testid="mission-product-image"
            />

            <ProductTargetTags>
              {missionProduct.productTarget?.isForBusiness && (
                <ProductTargetTag>
                  {
                    MESSAGES.MISSION_CARD.FIELD.MISSION_PRODUCTS.TAG
                      .PRODUCT_TARGET.FOR_BUSINESS
                  }
                </ProductTargetTag>
              )}
              {missionProduct.productTarget?.isForCustomer && (
                <ProductTargetTag>
                  {
                    MESSAGES.MISSION_CARD.FIELD.MISSION_PRODUCTS.TAG
                      .PRODUCT_TARGET.FOR_CUSTOMER
                  }
                </ProductTargetTag>
              )}
            </ProductTargetTags>

            <MissionProductName>{missionProduct.name}</MissionProductName>

            <MissionProductOverview>
              {missionProduct.overview}
            </MissionProductOverview>

            <MissionProductUrlLink
              href={missionProduct.url}
              data-testid="mission-product-url"
            >
              <MissionProductUrl>{missionProduct.url}</MissionProductUrl>
            </MissionProductUrlLink>

            <MissionProductTags>
              {missionProduct.tags
                .filter(tag => tag !== '')
                .map((tag, tagIndex) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <MissionProductTag key={tagIndex}>{tag}</MissionProductTag>
                ))}
            </MissionProductTags>

            <MissionProductItem>
              <MissionProductFieldTitle>
                {MESSAGES.MISSION_CARD.FIELD.MISSION_PRODUCTS.PROBLEM.LABEL}
              </MissionProductFieldTitle>
              <MissionProductText>
                <LinkText color="secondary">{missionProduct.problem}</LinkText>
              </MissionProductText>
            </MissionProductItem>

            <MissionProductItem>
              <MissionProductFieldTitle>
                {MESSAGES.MISSION_CARD.FIELD.MISSION_PRODUCTS.SOLUTION.LABEL}
              </MissionProductFieldTitle>
              <MissionProductText>
                <LinkText color="secondary">{missionProduct.solution}</LinkText>
              </MissionProductText>
            </MissionProductItem>
          </MissionProduct>
        ))}
      </div>
    </div>
  );
};

export default ProductDetailContent;
