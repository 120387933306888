import React, { FC } from 'react';
import * as MESSAGES from 'constants/messages';
import { Label, ProfileField, ProfileContent } from 'components/atoms';
import { Innovator } from 'proto/v1/apimodel/apimodel';

type PreferredOccupationsFieldProps = {
  preferredOccupations: Innovator['preferredOccupations'];
};

const PreferredOccupationsField: FC<PreferredOccupationsFieldProps> = props => {
  const { preferredOccupations } = props;

  return (
    <ProfileField data-testid="preferred-occupations-field">
      <Label>{MESSAGES.PROFILE.FIELD.PREFERRED_OCCUPATIONS.LABEL}</Label>
      <ProfileContent>
        {preferredOccupations
          .map(preferredOccupation => preferredOccupation.name)
          .join('、')}
      </ProfileContent>
    </ProfileField>
  );
};

export default PreferredOccupationsField;
