import React, { FC, ComponentProps, useEffect, useRef } from 'react';
import CreatableSelect from 'react-select/creatable';
import styled from 'styled-components/macro';
import theme from 'styles/theme';
import media from 'styles/media';
import { ErrorMessage } from 'components/atoms';

const StyledSelect = styled(CreatableSelect)<InputSelectProps>`
  .react-select__control {
    min-height: 40px;
    padding: 0;
    border-width: 1px;
    border-color: ${({ error }) =>
      error ? theme.baseError : theme.borderDefault};
    outline: none;
    box-shadow: none;

    &:hover {
      border-color: ${({ error }) =>
        error ? theme.baseError : theme.basePrimary};
      box-shadow: none;
      cursor: text;
    }

    ${media.mobile} {
      font-size: 16px;
    }
  }

  .react-select__multi-value {
    margin-right: 6px;
    margin-left: 0;
    border-radius: 5px;
    background-color: ${theme.basePrimary};
    color: ${theme.baseWhite};
  }

  .react-select__multi-value__label {
    padding: 4px 2px 4px 8px;
    color: ${theme.baseWhite};
    font-size: 14px;
  }

  .react-select__multi-value__remove {
    padding: 0 8px;

    &:hover {
      border-radius: 0 5px 5px 0;
      opacity: 0.8;
      background-color: ${theme.basePrimary};
      cursor: pointer;
    }

    svg {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: ${theme.baseWhite};
      color: ${theme.basePrimary};
    }
  }

  .react-select__option {
    padding: 12px;
    border-bottom: 1px solid ${theme.borderTable};
    color: ${theme.textPrimary};

    &:hover {
      background-color: rgba(35, 55, 128, 0.7);
      color: ${theme.baseWhite};
      cursor: pointer;
    }
  }

  /* Hide option to create */
  .react-select__option:empty {
    display: none;
  }

  .react-select__option--is-focused,
  .react-select__option--is-selected {
    background-color: rgba(35, 55, 128, 0.7);
    color: ${theme.baseWhite};
  }

  .react-select__menu {
    width: auto;
    min-width: 194px;
    border-radius: 3px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
  }

  .react-select__menu-list {
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 3px;
  }

  .react-select__dropdown-indicator,
  .react-select__indicator-separator {
    display: none;
  }

  .react-select__placeholder {
    color: ${theme.textPlaceholder};
  }
`;

type InputSelectProps = {
  error: string | undefined;
  enterKeyHint?: HTMLInputElement['enterKeyHint'];
} & ComponentProps<typeof CreatableSelect>;

const InputSelect: FC<InputSelectProps> = ({
  children,
  error,
  enterKeyHint,
  ...props
}) => {
  const ref = useRef<any>(null);

  useEffect(() => {
    if (!enterKeyHint) return;
    const inputRef: HTMLInputElement | undefined =
      ref.current?.select?.select?.inputRef;
    if (inputRef) inputRef.enterKeyHint = enterKeyHint;
  }, [enterKeyHint]);

  return (
    <>
      <StyledSelect
        error={error}
        noOptionsMessage={() => null}
        classNamePrefix="react-select"
        formatCreateLabel={() => undefined}
        isClearable={false}
        openMenuOnClick={false}
        createOptionPosition="first" // To be able to register an option with Enter when there is a forward matching option and the option is not selected.
        ref={ref}
        {...props}
      />
      {error && (
        <ErrorMessage style={{ marginTop: '4px' }}>{error}</ErrorMessage>
      )}
    </>
  );
};

export default InputSelect;
