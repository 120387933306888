import React, { FC, HTMLAttributes } from 'react';
import styled from 'styled-components/macro';
import theme from 'styles/theme';

const Mark = styled.span`
  color: ${theme.labelRequired};
  font-size: 16px;
`;

const RequiredMark: FC<HTMLAttributes<HTMLSpanElement>> = props => (
  <Mark {...props}>*</Mark>
);

export default RequiredMark;
