import React, { useState, useCallback } from 'react';
import styled from 'styled-components/macro';
import { Images } from 'proto/v1/apimodel/apimodel';
import media from 'styles/media';
import { imageSelector } from 'utils/image';

const DisplayImageContainer = styled.div`
  position: relative;
  height: 323px;

  ${media.tablet} {
    height: 440px;
  }

  ${media.mobile} {
    height: 360px;
  }

  ${media.smallMobile} {
    height: 210px;
  }

  ${media.miniMobile} {
    height: 171px;
  }
`;

const DisplayImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  height: 100%;
  transition: opacity 0.3s linear;
  opacity: 0;

  ${media.tablet} {
    left: 50%;
    transform: translateX(-50%);
  }

  /* using additional class name to active transition animation.
   If using extended component, animation doesn't work by dom rendering */
  &.selected {
    opacity: 1;
  }
`;

const ImageSelector = styled.div`
  margin-top: 12px;
`;

const SelectableImages = styled.ul`
  display: flex;
  justify-content: center;
  gap: 7.2px;

  ${media.tablet} {
    gap: 4px;
  }
`;

const SelectableImageContainer = styled.li`
  /* To heighten image on hover. So color should be fixed pure white. */
  background-color: #fff;
`;

const SelectableImage = styled.div<{
  imageSrc: string | undefined;
}>`
  width: 72px;
  height: 50px;
  background-image: url(${props => props.imageSrc});
  background-position: center;
  background-size: contain;
  cursor: pointer;

  ${media.mobile} {
    width: 56px;
    height: 40px;
  }

  ${media.smallMobile} {
    width: 48px;
    height: 32px;
  }

  ${media.miniMobile} {
    width: 39px;
    height: 20px;
  }

  &:hover {
    opacity: 0.7;
  }
`;

type Props = { images: (Images | undefined)[] };

const ImageGallery: React.VFC<Props> = ({ images }) => {
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(
    images.length > 0 ? 0 : undefined,
  );

  const handleSelectImage = useCallback(
    (index: number) => {
      setSelectedIndex(index);
    },
    [setSelectedIndex],
  );

  return (
    <div>
      <DisplayImageContainer>
        {images.map((imageSources, index) => {
          const imageUrl = imageSelector(imageSources, 'large');
          return (
            <DisplayImage
              key={imageUrl}
              src={imageSelector(imageSources, 'large')}
              className={selectedIndex === index ? 'selected' : ''}
              data-testid="display-image"
            />
          );
        })}
      </DisplayImageContainer>
      {/* show selectors only when registered multiple images */}
      {images.length > 1 && (
        <ImageSelector>
          <SelectableImages>
            {images.map((imageSources, index) => {
              const imageUrl = imageSelector(imageSources, 'small');
              return (
                <SelectableImageContainer key={imageUrl}>
                  <SelectableImage
                    imageSrc={imageUrl}
                    onClick={() => {
                      handleSelectImage(index);
                    }}
                    data-testid="selectable-image"
                  />
                </SelectableImageContainer>
              );
            })}
          </SelectableImages>
        </ImageSelector>
      )}
    </div>
  );
};

export default ImageGallery;
