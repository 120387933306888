/*
 * Redux Action actionTypes
 */

import { MATTER_STATUS_ID } from 'constants/matter';
import { createSelector } from 'reselect';
import { MatterStatusId } from 'types/matter';
import { checkShouldReviewProfile } from 'utils/matter';
import { ActionsUnion, createAction } from './helper';

export const INIT_MATTER_STATUS = 'matter/INIT_MATTER_STATUS';
export const SET_MATTER_STATUS = 'matter/SET_MATTER_STATUS';
export const CLOSE_MATTER_CLOSED_DIALOG = 'matter/CLOSE_MATTER_CLOSED_DIALOG';

/*
 * Redux Actions
 */

export const MatterActions = {
  initMatterStatus: () => createAction(INIT_MATTER_STATUS),
  setMatterStatus: (payload: {
    matterId: string;
    statusId: MatterStatusId;
    registered: boolean;
  }) => createAction(SET_MATTER_STATUS, payload),
  closeMatterClosedDialog: () => createAction(CLOSE_MATTER_CLOSED_DIALOG),
};

export type Actions = ActionsUnion<typeof MatterActions>;

/*
 * Selectors
 */
export const getMatter = (state: any): MatterState => state.matter;

export const getMatterStatus = createSelector([getMatter], matter => matter);

export const getShowMatterClosedDialog = createSelector(
  [getMatter],
  matter => matter.showMatterClosedDialog,
);

export const getShouldReviewProfile = createSelector([getMatter], matter =>
  checkShouldReviewProfile({
    registered: matter.registered,
    statusId: matter.statusId,
  }),
);

/*
 * Reducer
 */

export type MatterState = {
  matterId: string | undefined;
  statusId: MatterStatusId;
  registered: boolean;
  showMatterClosedDialog: boolean;
  canShowMatterClosedDialog: boolean;
};

const initState: MatterState = {
  matterId: undefined,
  statusId: MATTER_STATUS_ID.NONE,
  registered: false,
  showMatterClosedDialog: false,
  canShowMatterClosedDialog: true,
};

export default function reducer(
  state = initState,
  action: Actions,
): MatterState {
  if (!action) return state;

  switch (action.type) {
    case INIT_MATTER_STATUS: {
      return initState;
    }
    case SET_MATTER_STATUS: {
      const { statusId } = action.payload;
      const showDialog =
        state.canShowMatterClosedDialog && statusId === MATTER_STATUS_ID.CLOSED;
      return {
        ...state,
        matterId: action.payload.matterId,
        statusId: action.payload.statusId,
        registered: action.payload.registered,
        showMatterClosedDialog: showDialog,
      };
    }
    case CLOSE_MATTER_CLOSED_DIALOG: {
      return {
        ...state,
        showMatterClosedDialog: false,
        canShowMatterClosedDialog: false,
      };
    }
    default:
      return state;
  }
}
