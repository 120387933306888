import React, { FC } from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import styled from 'styled-components/macro';
import theme from 'styles/theme';
import media from 'styles/media';
import * as MESSAGES from 'constants/messages';
import { NO_SELECT_NUMBER } from 'constants/form';
import { MAX_PREFERRED_OCCUPATIONS } from 'constants/profile';
import {
  ProfileFormItems,
  ProfileFormControl,
  ProfileFormMethods,
} from 'types/profile';
import { interpolateMessage } from 'utils/string';
import { CircleCross } from 'assets/svg';
import { Selectbox, AddField, ErrorMessage } from 'components/atoms';
import { Field } from 'components/molecules';

const FieldItem = styled.div`
  display: flex;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const StyledSelectbox = styled(Selectbox)`
  width: 176px;

  ${media.mobile} {
    width: 100%;
  }
`;

const RemovePreferredOccupation = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;

  ${media.mobile} {
    align-self: flex-end;
    height: 40px;
    padding-left: 8px;
    border-left: 1px solid ${theme.borderDefault};
  }
`;

const IconRemove = styled(CircleCross)`
  display: inline-block;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

type PreferredOccupationsFormFieldProps = {
  error: string | undefined;
  control: ProfileFormControl<'preferredOccupations'>;
  occupations: ProfileFormItems['occupations'];
  trigger: ProfileFormMethods<'preferredOccupations'>['trigger'];
};

const PreferredOccupationsFormField: FC<PreferredOccupationsFormFieldProps> = props => {
  const { error, control, occupations, trigger } = props;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'preferredOccupations',
    keyName: 'key',
  });

  return (
    <Field
      label={MESSAGES.PROFILE.FIELD.PREFERRED_OCCUPATIONS.LABEL}
      required
      note={interpolateMessage(
        MESSAGES.PROFILE.FIELD.PREFERRED_OCCUPATIONS.NOTE,
        { maxLength: MAX_PREFERRED_OCCUPATIONS },
      )}
    >
      {fields.map((field, index) => (
        <FieldItem key={field.key}>
          <Controller
            control={control}
            name={`preferredOccupations[${index}].id`}
            render={innerProps => (
              <StyledSelectbox
                value={innerProps.value}
                options={occupations}
                aria-label={MESSAGES.PROFILE.FIELD.PREFERRED_OCCUPATIONS.LABEL}
                noSelectValue={NO_SELECT_NUMBER}
                onChange={e => {
                  innerProps.onChange(e);
                  trigger('preferredOccupations');
                }}
                onBlur={innerProps.onBlur}
              />
            )}
            defaultValue={field.id ?? NO_SELECT_NUMBER}
          />
          {index > 0 && (
            <RemovePreferredOccupation>
              <IconRemove
                onClick={() => remove(index)}
                data-testid="icon-remove-preferred-occupations"
              />
            </RemovePreferredOccupation>
          )}
        </FieldItem>
      ))}
      {error && (
        <ErrorMessage data-testid="error-message-preferred-occupations">
          {error}
        </ErrorMessage>
      )}
      {fields.length < MAX_PREFERRED_OCCUPATIONS && (
        <AddField
          onClick={() => append({})}
          data-testid="add-preferred-occupation"
        />
      )}
    </Field>
  );
};

export default PreferredOccupationsFormField;
