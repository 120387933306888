import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as MESSAGES from 'constants/messages';
import { getIsCareer, getIsNewGraduate, getInnovator } from 'modules/account';
import * as PROTECTED_ROUTES from 'routes/constants/protected';
import media from 'styles/media';
import theme from 'styles/theme';
import { CircleInfo } from 'assets/svg';
import { ButtonL } from 'components/atoms';

const IconInfo = styled(CircleInfo)`
  margin-bottom: 8px;
`;

const Wrapper = styled.div`
  padding: 16px 76px;
  text-align: center;

  ${media.mobile} {
    padding: 8px 0 0 0;
  }
`;

const Main = styled.p`
  margin-bottom: 16px;
  color: ${theme.basePrimary};
  font-size: 20px;
  line-height: 1.5;

  ${media.smallMobile} {
    font-size: 16px;
  }
`;

const MemberNumber = styled.div`
  margin-bottom: 40px;
  color: ${theme.textSecondary};
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
`;

const Description = styled.p`
  margin-bottom: 40px;
  font-size: 14px;
  line-height: 1.5;
  word-wrap: break-word;
  white-space: pre-wrap;
`;

const StyledButton = styled(ButtonL)`
  ${media.smallMobile} {
    width: 100%;
  }
`;

const RegisterProfileCompleted: FC = () => {
  const history = useHistory();
  const isCareer = useSelector(getIsCareer);
  const isNewGraduate = useSelector(getIsNewGraduate);
  const innovator = useSelector(getInnovator);

  return (
    <>
      <Helmet>
        <title>{MESSAGES.HEAD.TITLE.RESISTER_PROFILE.COMPLETED}</title>
      </Helmet>
      <Wrapper data-testid="register-profile-completed">
        <IconInfo />

        <Main>{MESSAGES.REGISTER_PROFILE.COMPLETED.MAIN}</Main>

        <Description>
          {isCareer && MESSAGES.REGISTER_PROFILE.COMPLETED.DESCRIPTION}
          {isNewGraduate &&
            MESSAGES.REGISTER_PROFILE.COMPLETED.DESCRIPTION_NEW_GRADUATE}
        </Description>
        <MemberNumber>
          {`${MESSAGES.REGISTER_PROFILE.COMPLETED.LABEL_MEMBER_NUMBER}${
            innovator?.innovatorId ?? ''
          }`}
        </MemberNumber>
        {isCareer && (
          <StyledButton
            onClick={() => history.push(PROTECTED_ROUTES.PUBLISH_SETTING)}
          >
            {MESSAGES.REGISTER_PROFILE.BUTTON.GO_TO_PUBLISH_SETTING}
          </StyledButton>
        )}
        {isNewGraduate && (
          <StyledButton onClick={() => history.push(PROTECTED_ROUTES.PROFILE)}>
            {MESSAGES.REGISTER_PROFILE.BUTTON.GO_TO_PROFILE_PAGE}
          </StyledButton>
        )}
      </Wrapper>
    </>
  );
};

export default RegisterProfileCompleted;
