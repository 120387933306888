import React, { FC, HTMLAttributes } from 'react';
import * as MESSAGES from 'constants/messages';
import { MissionForInnovator } from 'types/mission';
import { SubTitle } from '../../Typography';

type Props = {
  memberRange: MissionForInnovator['memberRange'] | undefined;
} & HTMLAttributes<HTMLDivElement>;

const MemberRangeField: FC<Props> = ({ memberRange, ...rest }) => {
  return (
    <div {...rest}>
      <SubTitle>{MESSAGES.MISSION_CARD.FIELD.MEMBER_RANGE.LABEL}</SubTitle>
      <p>{memberRange ? memberRange.displayText : '-'}</p>
    </div>
  );
};

export default MemberRangeField;
