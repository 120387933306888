import React, { FC } from 'react';
import * as MESSAGES from 'constants/messages';
import * as PROTECTED_ROUTES from 'routes/constants/protected';
import { useHistory } from 'react-router-dom';
import { scrollLayoutBodyToTop } from 'utils/layout';
import { ProfileNavTab, ProfileNavTabList } from 'components/molecules';

const ProfileNavBar: FC = () => {
  const history = useHistory();
  const { pathname } = history.location;

  const renderTab = (path: string, label: string) => (
    <ProfileNavTab
      selected={pathname === path}
      onClick={() => {
        if (path !== pathname) {
          history.push(path);
          scrollLayoutBodyToTop();
        }
      }}
    >
      {label}
    </ProfileNavTab>
  );

  return (
    <ProfileNavTabList data-testid="nav-bar">
      {renderTab(PROTECTED_ROUTES.PROFILE, MESSAGES.NAV.PROFILE)}
      {renderTab(
        PROTECTED_ROUTES.PUBLISH_SETTING,
        MESSAGES.NAV.PUBLISH_SETTING,
      )}
    </ProfileNavTabList>
  );
};

export default ProfileNavBar;
