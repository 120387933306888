import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { Spinner } from 'visits-style';

const Wrapper = styled.div`
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
`;

const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const Loading: FC = () => {
  return (
    <Wrapper>
      <Inner>
        <Spinner size="40px" />
      </Inner>
    </Wrapper>
  );
};

export default Loading;
