import React, { FC, HTMLAttributes } from 'react';
import styled from 'styled-components/macro';
import * as MESSAGES from 'constants/messages';
import { MissionForInnovator } from 'types/mission';
import theme from 'styles/theme';
import { imageSelector } from 'utils/image';
import { Title } from '../../Typography';

const Card = styled.div`
  box-sizing: border-box;
  padding: 16px;
  border: 2px solid ${theme.borderCard};
  border-radius: 8px;
  background: ${theme.baseWhite};

  &:hover {
    background: ${theme.bgCardHover};
    cursor: pointer;
  }

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const MissionProductImage = styled.img`
  width: 100%;
  height: 254px;
  margin-bottom: 16px;
`;

const HeadItems = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const MissionProductName = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
`;

const MissionProductOverview = styled.div`
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 1.5;
`;

const MissionProductTags = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`;

const MissionProductTag = styled.div`
  box-sizing: border-box;
  padding: 3px 12px;
  border: 1px solid ${theme.labelSecondary};
  border-radius: 18px;
  background: ${theme.baseWhite};
  color: ${theme.labelSecondary};
  font-size: 12px;
  line-height: 1.5;
`;

const ProductTargetTags = styled.div`
  display: flex;
  gap: 4px;
`;

const ProductTargetTag = styled.div`
  padding: 5px 12px 3px 12px;
  border: 1px solid ${theme.borderDefault};
  border-radius: 4px;
  background-color: ${theme.baseWhite};
  color: ${theme.textCardSecondary};
  font-size: 12px;
  line-height: 17px;
`;

type Props = {
  missionProducts: MissionForInnovator['missionProducts'] | undefined;
  onClickCard: (index: number) => void;
} & HTMLAttributes<HTMLDivElement>;

const MissionProductSummariesField: FC<Props> = ({
  missionProducts,
  onClickCard,
  ...rest
}) => {
  return (
    <div {...rest}>
      <Title>{MESSAGES.MISSION_CARD.FIELD.MISSION_PRODUCTS.LABEL}</Title>
      {(missionProducts ?? []).map((missionProduct, index) => (
        <Card
          key={missionProduct.missionProductId}
          onClick={() => onClickCard(index)}
          data-testid="mission-product-summary-card"
        >
          <MissionProductImage
            src={imageSelector(missionProduct.image, 'large') ?? ''}
          />
          <HeadItems>
            <ProductTargetTags>
              {missionProduct.productTarget?.isForBusiness && (
                <ProductTargetTag>
                  {
                    MESSAGES.MISSION_CARD.FIELD.MISSION_PRODUCTS.TAG
                      .PRODUCT_TARGET.FOR_BUSINESS
                  }
                </ProductTargetTag>
              )}
              {missionProduct.productTarget?.isForCustomer && (
                <ProductTargetTag>
                  {
                    MESSAGES.MISSION_CARD.FIELD.MISSION_PRODUCTS.TAG
                      .PRODUCT_TARGET.FOR_CUSTOMER
                  }
                </ProductTargetTag>
              )}
            </ProductTargetTags>
          </HeadItems>
          <MissionProductName>{missionProduct.name}</MissionProductName>
          <MissionProductOverview>
            {missionProduct.overview}
          </MissionProductOverview>
          <MissionProductTags>
            {missionProduct.tags
              .filter(tag => tag !== '')
              .map((tag, tagIndex) => (
                // eslint-disable-next-line react/no-array-index-key
                <MissionProductTag key={tagIndex}>{tag}</MissionProductTag>
              ))}
          </MissionProductTags>
        </Card>
      ))}
    </div>
  );
};

export default MissionProductSummariesField;
