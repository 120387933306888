import React, { FC } from 'react';
import * as MESSAGES from 'constants/messages';
import { Innovator } from 'proto/v1/apimodel/apimodel';
import { PREFERRED_WORK_LOCATIONS_CIRCLE_NUMBERS } from 'constants/profile';
import { Label, ProfileField, ProfileContent } from 'components/atoms';

type PreferredWorkLocationsFieldProps = {
  preferredWorkLocations: Innovator['preferredWorkLocations'];
};

const PreferredWorkLocationsField: FC<PreferredWorkLocationsFieldProps> = props => {
  const { preferredWorkLocations } = props;

  return (
    <ProfileField data-testid="preferred-work-locations-field">
      <Label>{MESSAGES.PROFILE.FIELD.PREFERRED_WORK_LOCATIONS.LABEL}</Label>
      <ProfileContent>
        {preferredWorkLocations
          .map(
            (preferredWorkLocation, index) =>
              `${PREFERRED_WORK_LOCATIONS_CIRCLE_NUMBERS[index]}${
                preferredWorkLocation.japanPrefecture
                  ? preferredWorkLocation.japanPrefecture.name
                  : MESSAGES.PROFILE.OVERSEAS
              }`,
          )
          .join('、')}
      </ProfileContent>
    </ProfileField>
  );
};

export default PreferredWorkLocationsField;
