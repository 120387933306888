import React, { FC, ComponentProps } from 'react';
import styled from 'styled-components/macro';
import media from 'styles/media';
import { Field as BaseField } from 'visits-style';
import FieldLabel from './FieldLabel';

const StyledField = styled(BaseField)`
  margin-top: 0;
  margin-bottom: 40px;

  ${media.mobile} {
    margin-bottom: 32px;
  }
`;

type FieldProps = ComponentProps<typeof BaseField> &
  ComponentProps<typeof FieldLabel>;

const Field: FC<FieldProps> = ({
  label,
  required,
  locked,
  note,
  children,
  ...rest
}) => (
  <StyledField data-testid="field" {...rest}>
    <FieldLabel label={label} required={required} locked={locked} note={note} />
    {children}
  </StyledField>
);

export default Field;
