import { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { onAuthStateChanged } from 'firebase/auth';
import axios, { CancelTokenSource } from 'axios';
import { UseFormMethods } from 'react-hook-form';
import { UiActions } from 'modules/ui';
import * as MESSAGES from 'constants/messages';
import { ReactSelectOption } from 'types/form';
import apiCommon from 'external/api/common';
import apiFirebase from 'external/firebase/firebase';
import RequestError from 'classes/RequestError';
import { ProfileFormMethods, ProfileFormValues } from 'types/profile';
import useErrorNotification from 'hooks/useErrorNotification';
import useSelectOptionsState from 'hooks/useSelectOptionsState';
import useReactSelectInput from 'hooks/useReactSelectInput';

type Payload = {
  formMethods: UseFormMethods<any>;
};

type Value = {
  career: Pick<ProfileFormValues['career'], 'expectations'>;
};

const useCareerExpectationsFormField = ({ formMethods }: Payload) => {
  const dispatch = useDispatch();
  const { handleRequestError } = useErrorNotification();

  const {
    init,
    startLoading,
    endLoading,
    startCreating,
    endCreating,
    fetchFirstDone,
    fetchMoreDone,
    setInputValue,
    state: expectationsOptionsState,
    isCreatingOptionRef,
  } = useSelectOptionsState();

  const [exampleExpectations, setExampleExpectations] = useState<
    ReactSelectOption[]
  >([]);

  const {
    setValue,
    getValues,
  } = (formMethods as unknown) as ProfileFormMethods<Value>;

  const fetchExpectationOptions = useCallback(
    async (inputValue: string) => {
      if (!inputValue) return;
      let data;
      startLoading();
      try {
        ({ data } = await apiCommon.getExpectations({
          nameStartWith: inputValue,
          limit: 20,
          q: '',
        }));
      } catch (error: unknown) {
        // nothing to do
        endLoading();
        return;
      }
      const options =
        data?.expectations.map(expectation => ({
          value: expectation.id,
          label: expectation.name,
        })) ?? [];

      const nextQuery = data.paging?.nextQ ?? '';

      fetchFirstDone({ options, nextQuery });
    },
    [endLoading, fetchFirstDone, startLoading],
  );

  const fetchMoreExpectationOptions = useCallback(async () => {
    const hasMore = !!expectationsOptionsState.nextQuery;
    if (!hasMore) return;

    let data;
    startLoading();
    try {
      ({ data } = await apiCommon.getExpectations({
        nameStartWith: expectationsOptionsState.inputValue,
        limit: 20,
        q: expectationsOptionsState.nextQuery,
      }));
    } catch (error: unknown) {
      endLoading();
      return;
    }

    const options = data?.expectations.map(expectation => ({
      value: expectation.id,
      label: expectation.name,
    }));

    const nextQuery = data.paging?.nextQ ?? '';

    fetchMoreDone({
      options,
      nextQuery,
    });
  }, [
    endLoading,
    fetchMoreDone,
    expectationsOptionsState.inputValue,
    expectationsOptionsState.nextQuery,
    startLoading,
  ]);

  const handleCreateExpectationOption = useCallback(
    async (inputValue: string) => {
      if (!inputValue || isCreatingOptionRef.current) return;
      startCreating();
      let data;
      try {
        ({ data } = await apiCommon.registerExpectation({
          name: inputValue,
        }));
      } catch (error: unknown) {
        endCreating();
        if (error instanceof RequestError) {
          handleRequestError(error);
        }
        return;
      }

      setValue(
        'career.expectations',
        [
          ...((getValues(
            'career.expectations',
          ) as ProfileFormValues['career']['expectations']) ?? []),
          { value: data?.expectation?.id, label: data?.expectation?.name },
        ],
        {
          shouldValidate: true,
          shouldDirty: true,
        },
      );
      endCreating();
    },
    [
      endCreating,
      getValues,
      handleRequestError,
      isCreatingOptionRef,
      setValue,
      startCreating,
    ],
  );

  const { handleInputChange } = useReactSelectInput({
    name: 'career.expectations',
    formMethods,
    state: expectationsOptionsState,
    fetcher: fetchExpectationOptions,
    onCreateOption: handleCreateExpectationOption,
    initOptionsState: init,
    setInputValue,
  });

  const fetchExampleExpectations = useCallback(
    async (source: CancelTokenSource) => {
      let data;
      try {
        ({ data } = await apiCommon.getExampleExpectations(source.token));
      } catch (error: unknown) {
        dispatch(UiActions.setLoading(false));
        if (error instanceof RequestError) {
          handleRequestError(error, MESSAGES.ERROR.FAILED_TO_GET);
        }
        return;
      }
      if (data?.expectations) {
        setExampleExpectations(
          data?.expectations.map(expectation => ({
            value: expectation.id,
            label: expectation.name,
          })),
        );
      }
    },
    [dispatch, handleRequestError],
  );

  const handleClickExampleExpectation = useCallback(
    async (targetExpectation: ReactSelectOption) => {
      const expectations =
        (getValues(
          'career.expectations',
        ) as ProfileFormValues['career']['expectations']) ?? [];
      const expectationIds = expectations ? expectations.map(s => s.value) : [];
      if (expectationIds.includes(targetExpectation.value)) {
        return;
      }
      setValue('career.expectations', [...expectations, targetExpectation], {
        shouldValidate: true,
        shouldDirty: true,
      });
    },
    [getValues, setValue],
  );

  // Fetch Example expectations
  useEffect(() => {
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    const unsubscribeAuthStateChanged = onAuthStateChanged(
      apiFirebase.auth(),
      user => {
        user ? fetchExampleExpectations(source) : source.cancel();
      },
    );

    return () => {
      unsubscribeAuthStateChanged();
      source?.cancel();
    };
  }, [fetchExampleExpectations]);

  return {
    expectationsOptionsState,
    exampleExpectations,
    handleClickExampleExpectation,
    fetchMoreExpectationOptions,
    handleCreateExpectationOption,
    handleInputChangeExpectation: handleInputChange,
  };
};

export default useCareerExpectationsFormField;
