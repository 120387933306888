import { CORPORATE_ATTRIBUTES_ID } from 'constants/mission';
import { CorporateAttribute } from 'proto/v1/apimodel/apimodel';

// eslint-disable-next-line import/prefer-default-export
export const isCompanyCorporateAttribute = (
  corporateAttributes: CorporateAttribute[] | undefined,
) =>
  corporateAttributes && corporateAttributes?.length
    ? ([
        CORPORATE_ATTRIBUTES_ID.STARTUP,
        CORPORATE_ATTRIBUTES_ID.ENTERPRISE,
        CORPORATE_ATTRIBUTES_ID.INVESTOR,
      ] as number[]).includes(corporateAttributes[0].id)
    : false;
