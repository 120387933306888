export default {
  TITLE: 'プロフィール',
  DESCRIPTION: {
    IN_REVIEW:
      'ご登録いただきましたプロフィールを確認させていただいております。確認が完了しましたら、プロフィールの再編集が可能になります。',
  },
  BASIC_INFO: {
    LABEL: '基本情報',
  },
  CAREER_JOB_HISTORY: {
    LABEL: '経験職種・職務経歴',
  },
  CAREER_PROFILE_SKILLS_AND_PREFERRED_CONDITIONS: {
    LABEL: '希望事項・自己PR',
  },
  NEW_GRADUATE_PROFILE_SKILLS_AND_PREFERRED_CONDITIONS: {
    LABEL: 'スキル・希望等',
  },
  NEW_GRADUATE_PROFILE_EXPERIENCES: {
    LABEL: '経験・自己PR',
  },
  BUTTON: {
    EDIT: '編集する',
    CANCEL: 'キャンセル',
    SAVE: '変更を保存',
  },
  MODAL: {
    UPDATE_PROFILE_COMPLETED: {
      CONTENT: 'プロフィールを更新しました。',
    },
  },
};
