import React, { FC } from 'react';
import * as MESSAGES from 'constants/messages';
import styled from 'styled-components/macro';
import { Label, ProfileField, ProfileContent } from 'components/atoms';
import { NewGraduateProfile } from 'proto/v1/apimodel/apimodel';

const ProgrammingExperience = styled.span`
  display: block;
`;

type NewGraduateProgrammingExperiencesFieldProps = {
  programmingExperiences:
    | NewGraduateProfile['programmingExperiences']
    | undefined;
};

const NewGraduateProgrammingExperiencesField: FC<NewGraduateProgrammingExperiencesFieldProps> = props => {
  const { programmingExperiences } = props;

  return (
    <ProfileField data-testid="new-graduate-programming-experiences-field">
      <Label>
        {MESSAGES.PROFILE.FIELD.NEW_GRADUATE_PROGRAMMING_EXPERIENCES.LABEL}
      </Label>
      <ProfileContent>
        {programmingExperiences && programmingExperiences.length > 0
          ? programmingExperiences.map((programmingExperience, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <ProgrammingExperience key={index}>
                {programmingExperience.languageName}
                {programmingExperience.level &&
                  ` / ${programmingExperience.level.name}`}
              </ProgrammingExperience>
            ))
          : '-'}
      </ProfileContent>
    </ProfileField>
  );
};

export default NewGraduateProgrammingExperiencesField;
