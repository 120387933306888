import { useCallback } from 'react';
import apiCommon from 'external/api/common';
import { UseFormMethods } from 'react-hook-form';
import RequestError from 'classes/RequestError';
import useErrorNotification from 'hooks/useErrorNotification';
import useSelectOptionsState from 'hooks/useSelectOptionsState';
import useReactSelectInput from 'hooks/useReactSelectInput';

type Payload = {
  formMethods: UseFormMethods<any>;
};

const useAcademicBackgroundSchoolFormField = ({ formMethods }: Payload) => {
  const { handleRequestError } = useErrorNotification();
  const {
    init,
    startLoading,
    endLoading,
    startCreating,
    endCreating,
    fetchFirstDone,
    fetchMoreDone,
    setInputValue,
    state: schoolOptionsState,
  } = useSelectOptionsState();

  const { setValue } = formMethods;

  const fetchSchoolOptions = useCallback(
    async (inputValue: string) => {
      if (!inputValue) return;
      let data;
      startLoading();
      try {
        ({ data } = await apiCommon.getSchools({
          nameStartWith: inputValue,
          limit: 20,
          q: '',
          schoolIds: [],
        }));
      } catch (error: unknown) {
        // nothing to do
        endLoading();
        return;
      }
      const options =
        data?.schools.map(school => ({
          value: school.id,
          label: school.name,
        })) ?? [];

      const nextQuery = data.paging?.nextQ ?? '';

      fetchFirstDone({ options, nextQuery });
    },
    [endLoading, fetchFirstDone, startLoading],
  );

  const fetchMoreSchoolOptions = useCallback(async () => {
    const hasMore = !!schoolOptionsState.nextQuery;
    if (!hasMore) return;

    let data;
    startLoading();
    try {
      ({ data } = await apiCommon.getSchools({
        nameStartWith: schoolOptionsState.inputValue,
        limit: 20,
        q: schoolOptionsState.nextQuery,
        schoolIds: [],
      }));
    } catch (error: unknown) {
      endLoading();
      return;
    }

    const options = data?.schools.map(school => ({
      value: school.id,
      label: school.name,
    }));

    const nextQuery = data.paging?.nextQ ?? '';

    fetchMoreDone({
      options,
      nextQuery,
    });
  }, [
    endLoading,
    fetchMoreDone,
    schoolOptionsState.inputValue,
    schoolOptionsState.nextQuery,
    startLoading,
  ]);

  const handleCreateSchoolOption = useCallback(
    async (inputValue: string) => {
      if (!inputValue) return;
      startCreating();
      let data;
      try {
        ({ data } = await apiCommon.registerSchool({
          name: inputValue,
        }));
      } catch (error: unknown) {
        endCreating();
        if (error instanceof RequestError) {
          handleRequestError(error);
        }
        return;
      }

      setValue(
        'academicBackground.school',
        { value: data?.school?.id, label: data?.school?.name },
        {
          shouldValidate: true,
          shouldDirty: true,
        },
      );
      endCreating();
    },
    [endCreating, handleRequestError, setValue, startCreating],
  );

  const { handleInputChange } = useReactSelectInput({
    name: 'academicBackground.school',
    formMethods,
    fetcher: fetchSchoolOptions,
    onCreateOption: handleCreateSchoolOption,
    initOptionsState: init,
    setInputValue,
    state: schoolOptionsState,
  });

  return {
    schoolOptionsState,
    fetchMoreSchoolOptions,
    handleInputChangeSchool: handleInputChange,
    handleCreateSchoolOption,
  };
};

export default useAcademicBackgroundSchoolFormField;
