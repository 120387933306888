import styled from 'styled-components/macro';
import theme from 'styles/theme';

const UrlLink = styled.a.attrs({ target: '_blank', rel: 'noopener' })`
  display: inline-block;
  color: ${theme.textSecondary};
  font-size: 14px;
  line-height: 1.5;

  &:hover {
    color: ${theme.textSecondaryHover};
  }
`;

export default UrlLink;
