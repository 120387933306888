import React, { FC } from 'react';
import { Controller } from 'react-hook-form';
import styled from 'styled-components/macro';
import media from 'styles/media';
import * as MESSAGES from 'constants/messages';
import { NO_SELECT_NUMBER } from 'constants/form';
import {
  ProfileFormControl,
  ProfileFormItems,
  ProfileFormValues,
} from 'types/profile';
import { Selectbox } from 'components/atoms';
import { Field } from 'components/molecules';

const PositionSelectbox = styled(Selectbox)`
  width: 368px;

  ${media.mobile} {
    width: 100%;
  }
`;

type Job = ProfileFormValues['career']['job'][0];

type Value = {
  career: {
    job: Pick<Job, 'positionId'>[];
  };
};

type PositionFormFieldProps = {
  error: string | undefined;
  jobIndex: number;
  control: ProfileFormControl<Value>;
  positions: ProfileFormItems['positions'];
};

const PositionFormField: FC<PositionFormFieldProps> = props => {
  const { error, positions, jobIndex, control } = props;
  return (
    <Field label={MESSAGES.PROFILE.FIELD.CAREER_JOB.POSITION.LABEL} required>
      <Controller
        control={control}
        name={`career.job[${jobIndex}].positionId`}
        render={innerProps => (
          <PositionSelectbox
            value={innerProps.value}
            error={error}
            options={positions}
            aria-label={MESSAGES.PROFILE.FIELD.CAREER_JOB.POSITION.LABEL}
            noSelectValue={NO_SELECT_NUMBER}
            onChange={innerProps.onChange}
            onBlur={innerProps.onBlur}
          />
        )}
        defaultValue={NO_SELECT_NUMBER}
      />
    </Field>
  );
};

export default PositionFormField;
