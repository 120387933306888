import React, { FC, useMemo } from 'react';
import styled from 'styled-components/macro';
import { Controller } from 'react-hook-form';
import media from 'styles/media';
import { ProfileFormControl, ProfileFormValues } from 'types/profile';
import * as MESSAGES from 'constants/messages';
import { ACADEMIC_BACKGROUND_GRADUATION_MIN_YEAR } from 'constants/profile';
import { range, rangeRight } from 'utils/array';
import { Selectbox } from 'components/atoms';
import { Field } from 'components/molecules';

const NEW_GRADUATE_ADDITIONAL_YEARS = 4;

const SelectBoxes = styled.div`
  display: grid;
  grid-column-gap: 16px;
  grid-template-columns: 176px 176px;

  ${media.mobile} {
    grid-row-gap: 8px;
    grid-template-columns: 1fr;
  }
`;

const monthOfGraduationOptions = range(1, 12).map(month => ({
  id: month,
  name: `${month}月`,
}));

type AcademicBackgroundYearMonthOfGraduationFormFieldProps = {
  errors: {
    yearOfGraduation: string | undefined;
    monthOfGraduation: string | undefined;
  };
  control: ProfileFormControl<{
    academicBackground: Pick<
      ProfileFormValues['academicBackground'],
      'yearOfGraduation' | 'monthOfGraduation'
    >;
  }>;
  label?: string;
  isNewGraduate?: boolean;
};

const AcademicBackgroundYearMonthOfGraduationFormField: FC<AcademicBackgroundYearMonthOfGraduationFormFieldProps> = props => {
  const {
    errors,
    control,
    label = MESSAGES.PROFILE.FIELD.ACADEMIC_BACKGROUND_YEAR_MONTH_OF_GRADUATION
      .LABEL,
    isNewGraduate = false,
  } = props;

  const yearOfGraduationOptions = useMemo(() => {
    const currentYear = new Date().getFullYear();
    const years = isNewGraduate
      ? range(currentYear, currentYear + NEW_GRADUATE_ADDITIONAL_YEARS)
      : rangeRight(ACADEMIC_BACKGROUND_GRADUATION_MIN_YEAR, currentYear);
    return years.map(year => ({
      id: year,
      name: `${year}年`,
    }));
  }, [isNewGraduate]);

  return (
    <Field label={label} required>
      <SelectBoxes>
        <Controller
          control={control}
          name="academicBackground.yearOfGraduation"
          render={innerProps => (
            <Selectbox
              value={innerProps.value}
              error={errors.yearOfGraduation}
              options={yearOfGraduationOptions}
              aria-label={
                MESSAGES.PROFILE.FIELD
                  .ACADEMIC_BACKGROUND_YEAR_MONTH_OF_GRADUATION.YEAR.LABEL
              }
              onChange={innerProps.onChange}
              onBlur={innerProps.onBlur}
            />
          )}
        />

        <Controller
          control={control}
          name="academicBackground.monthOfGraduation"
          render={innerProps => (
            <Selectbox
              value={innerProps.value}
              error={errors.monthOfGraduation}
              options={monthOfGraduationOptions}
              aria-label={
                MESSAGES.PROFILE.FIELD
                  .ACADEMIC_BACKGROUND_YEAR_MONTH_OF_GRADUATION.MONTH.LABEL
              }
              onChange={innerProps.onChange}
              onBlur={innerProps.onBlur}
            />
          )}
        />
      </SelectBoxes>
    </Field>
  );
};

export default AcademicBackgroundYearMonthOfGraduationFormField;
