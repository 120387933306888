import styled from 'styled-components';
import { Radio } from 'visits-style';
import theme from 'styles/theme';

const StyledRadio = styled(Radio)`
  & > label > div {
    width: 20px;
    height: 20px;
    margin: 0;
    margin-right: 8px;
    border-color: ${theme.borderDefault} !important;

    i {
      width: 12px;
      height: 12px;
      background: ${theme.basePrimary} !important;
    }
  }
`;

export default StyledRadio;
