import React, { FC, HTMLAttributes } from 'react';
import styled from 'styled-components/macro';
import media from 'styles/media';
import { MissionForInnovator } from 'types/mission';
import { WrapText } from 'components/atoms';

const CompanyName = styled(WrapText)`
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;

  ${media.tablet} {
    align-self: center;
    font-size: 14px;
  }
`;

type Props = {
  companyName: MissionForInnovator['companyName'] | undefined;
} & HTMLAttributes<HTMLDivElement>;

const CompanyNameField: FC<Props> = ({ companyName, ...rest }) => {
  return <CompanyName {...rest}>{companyName}</CompanyName>;
};

export default CompanyNameField;
