import styled from 'styled-components/macro';
import theme from 'styles/theme';
import media from 'styles/media';

const ProfileContentGroupItem = styled.div`
  margin-bottom: -44px;

  &:not(:first-child) {
    padding-top: 32px;
    border-top: 1px solid ${theme.borderDefault};
  }

  &:not(:last-child) {
    padding-bottom: 32px;
  }

  ${media.mobile} {
    margin-bottom: -24px;

    &:not(:first-child) {
      padding-top: 24px;
    }

    &:not(:last-child) {
      padding-bottom: 24px;
    }
  }
`;

export default ProfileContentGroupItem;
