import { useState, useCallback } from 'react';

const useStepper = (stepLabels: string[]) => {
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);

  const isFirstStep = currentStepIndex === 0;

  const isFinalStep = stepLabels.length === currentStepIndex + 1;

  const handleNext = useCallback(() => {
    if (isFinalStep) return;
    setCurrentStepIndex(prev => prev + 1);
  }, [isFinalStep]);

  const handlePrev = useCallback(() => {
    if (isFirstStep) return;
    setCurrentStepIndex(prev => prev - 1);
  }, [isFirstStep]);

  const moveToFirst = useCallback(() => {
    setCurrentStepIndex(0);
  }, []);

  return {
    currentStepIndex,
    setCurrentStepIndex,
    isFirstStep,
    isFinalStep,
    handlePrev,
    handleNext,
    moveToFirst,
  };
};

export type Stepper = ReturnType<typeof useStepper>;

export default useStepper;
