import styled from 'styled-components/macro';
import theme from 'styles/theme';
import media from 'styles/media';

const Heading = styled.h2`
  margin-bottom: 32px;
  color: ${theme.textPrimary};
  font-size: 24px;
  font-weight: normal;
  line-height: 1.5;

  ${media.mobile} {
    margin-bottom: 24px;
    font-size: 20px;
  }
`;

export default Heading;
