import React, { FC, HTMLAttributes } from 'react';
import styled from 'styled-components/macro';
import media from 'styles/media';
import { WrapText, LinkText } from 'components/atoms';
import * as MESSAGES from 'constants/messages';
import { MissionForInnovator } from 'types/mission';
import { Title } from '../../Typography';

const MissionDescription = styled(WrapText)`
  min-height: 248px;
  overflow: auto;
  line-height: 26px;

  ${media.tablet} {
    min-height: auto;
  }
`;

type Props = {
  missionDescription: MissionForInnovator['missionDescription'] | undefined;
} & HTMLAttributes<HTMLDivElement>;

const MissionDescriptionField: FC<Props> = ({
  missionDescription,
  ...rest
}) => {
  return (
    <div {...rest}>
      <Title>{MESSAGES.MISSION_CARD.FIELD.MISSION_DESCRIPTION.LABEL}</Title>
      <MissionDescription>
        <LinkText color="secondary">{missionDescription ?? '-'}</LinkText>
      </MissionDescription>
    </div>
  );
};

export default MissionDescriptionField;
