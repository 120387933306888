import React, { FC } from 'react';
import styled from 'styled-components/macro';
import * as MESSAGES from 'constants/messages';
import { Innovator } from 'proto/v1/apimodel/apimodel';
import theme from 'styles/theme';
import { ProfileContent } from 'components/atoms';
import { Field } from 'components/molecules';

const Content = styled(ProfileContent)`
  color: ${theme.textSecondary};
`;

type Props = {
  innovatorId: Innovator['innovatorId'] | undefined;
};

const MemberNumberFormField: FC<Props> = ({ innovatorId }) => {
  return (
    <Field
      label={MESSAGES.PROFILE.FIELD.MEMBER_NUMBER.LABEL}
      data-testid="member-number-form-field"
    >
      <Content>{innovatorId ?? '-'}</Content>
    </Field>
  );
};

export default MemberNumberFormField;
