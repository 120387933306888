import React, { FC } from 'react';
import * as MESSAGES from 'constants/messages';
import { Label, ProfileField, ProfileContent } from 'components/atoms';
import { CareerProfile } from 'proto/v1/apimodel/apimodel';

type CareerPreferredMonthlyUnitPriceFieldProps = {
  preferredMonthlyUnitPrice: CareerProfile['preferredMonthlyUnitPrice'];
};

const CareerPreferredMonthlyUnitPriceField: FC<CareerPreferredMonthlyUnitPriceFieldProps> = props => {
  const { preferredMonthlyUnitPrice } = props;

  return (
    <ProfileField data-testid="career-preferred-monthly-unit-price-field">
      <Label>
        {MESSAGES.PROFILE.FIELD.CAREER_PREFERRED_MONTHLY_UNIT_PRICE.LABEL}
      </Label>
      <ProfileContent>{preferredMonthlyUnitPrice?.name}</ProfileContent>
    </ProfileField>
  );
};

export default CareerPreferredMonthlyUnitPriceField;
