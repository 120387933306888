import React, { FC } from 'react';
import styled from 'styled-components/macro';
import media from 'styles/media';
import * as PUBLIC_ROUTES from 'routes/constants/public';
import { Link } from 'react-router-dom';
import { Logo } from 'components/atoms';
import BaseHeader from './BaseHeader';

const StyledHeader = styled(BaseHeader)`
  position: relative;
  align-items: center;
  padding: 24px 32px;
  background-color: #0c132d;

  ${media.mobile} {
    padding: 21px 20px;
  }
`;

const LogoLink = styled(Link)`
  text-decoration: none;
`;

const StyledLogo = styled(Logo)`
  position: relative;
  top: 2px;
`;

const Header: FC = () => {
  return (
    <StyledHeader>
      <LogoLink to={PUBLIC_ROUTES.LOGIN}>
        <StyledLogo />
      </LogoLink>
    </StyledHeader>
  );
};

export default Header;
