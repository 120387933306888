import React, { FC } from 'react';
import styled from 'styled-components/macro';
import theme from 'styles/theme';
import * as MESSAGES from 'constants/messages';

const Label = styled.span`
  margin-left: 8px;
  color: ${theme.textSecondary};
  font-size: 14px;
  line-height: 1.5;
`;

const OptionalLabel: FC = () => {
  return <Label>({MESSAGES.COMMON.FORM.FIELD.OPTIONAL})</Label>;
};

export default OptionalLabel;
