import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { Controller } from 'react-hook-form';
import media from 'styles/media';
import * as MESSAGES from 'constants/messages';
import { ADDRESS_OVERSEAS_ID } from 'constants/profile';
import { ProfileFormItems, ProfileFormControl } from 'types/profile';
import { Selectbox } from 'components/atoms';
import { Field } from 'components/molecules';

type JapanPrefectureFormFieldProps = {
  error: string | undefined;
  japanPrefectures: ProfileFormItems['japanPrefectures'];
  control: ProfileFormControl<'japanPrefectureId'>;
};

const StyledSelectbox = styled(Selectbox)`
  width: 176px;

  ${media.mobile} {
    width: 100%;
  }
`;

const JapanPrefectureFormField: FC<JapanPrefectureFormFieldProps> = props => {
  const { error, japanPrefectures, control } = props;

  return (
    <Field
      label={MESSAGES.PROFILE.FIELD.JAPAN_PREFECTURE.LABEL}
      required
      locked
    >
      <Controller
        control={control}
        name="japanPrefectureId"
        render={innerProps => (
          <StyledSelectbox
            value={innerProps.value}
            idName="japanPrefectureId"
            error={error}
            options={[
              ...japanPrefectures,
              {
                japanPrefectureId: ADDRESS_OVERSEAS_ID,
                name: MESSAGES.PROFILE.OVERSEAS,
              },
            ]}
            aria-label={MESSAGES.PROFILE.FIELD.JAPAN_PREFECTURE.LABEL}
            onChange={innerProps.onChange}
            onBlur={innerProps.onBlur}
          />
        )}
      />
    </Field>
  );
};

export default JapanPrefectureFormField;
