import { createGlobalStyle } from 'styled-components/macro';
import { normalizeStyle } from 'visits-style';
import theme from 'styles/theme';
/* eslint-disable global-require */

export default createGlobalStyle`
  ${normalizeStyle}

  @font-face {
    font-family: NotoSansJP;
    font-style: normal;
    font-weight: 500;
    src: url(${require('./fonts/NotoSansJP_regular.woff')}) format("woff");
  }

  @font-face {
    font-family: NotoSansJP;
    font-style: normal;
    font-weight: 700;
    src: url(${require('./fonts/NotoSansJP_medium.woff')}) format("woff");
  }

  @font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    src: url(${require('./fonts/Roboto_regular.woff')}) format("woff");
  }

  @font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    src: url(${require('./fonts/Roboto_medium.woff')}) format("woff");
  }

  @font-face {
    font-family: 'Gilroy-Bold';
    font-style: normal;
    font-weight: 700;
    src: url(${require('./fonts/Gilroy_bold.woff')}) format("woff");
  }

  html {
    height: 100%;
  }

  body {
    height: 100%;
    color: ${theme.textPrimary};
    font-family: Roboto, YakuHanJP_Noto, NotoSansJP, sans-serif;
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  #root {
    height: 100%;
  }

  a {
    color: ${theme.baseLink};
    text-decoration: underline;

    &:hover {
      color: ${theme.baseLink};
    }
  }
`;
