import { NO_SELECT_NUMBER } from 'constants/form';
import {
  School,
  WorkLocation,
  CareerProfile,
} from 'proto/v1/apimodel/apimodel';
import {
  MAX_PREFERRED_WORK_LOCATIONS,
  ADDRESS_OVERSEAS_ID,
  END_YEAR_MONTH_CURRENTLY_ENROLLED_ID,
} from 'constants/profile';
import { range } from 'utils/array';
import {
  UpdateInnovatorRequest,
  CareerProfileToUpdate,
  NewGraduateProfileToUpdate,
} from 'proto/v1/innovatorservice/innovatorservice';
import { ProfileFormItems, ProfileFormValues } from 'types/profile';

export const getDefaultAcademicBackgroundSchoolValue = (
  school: School | undefined,
) =>
  school && school.id !== NO_SELECT_NUMBER
    ? {
        value: school.id,
        label: school.name,
      }
    : null;

export const getDefaultPhoneCountryIdValue = (
  phoneCountryCode: UpdateInnovatorRequest['phoneCountryCode'],
  countries: ProfileFormItems['countries'],
) =>
  countries.find(country => country.phoneCountryCode === phoneCountryCode)
    ?.countryId ?? '';

export const getDefaultPreferredWorkLocationJapanPrefectureIdsValue = (
  workLocations: UpdateInnovatorRequest['preferredWorkLocations'],
): ProfileFormValues['preferredWorkLocationJapanPrefectureIds'] =>
  range(0, MAX_PREFERRED_WORK_LOCATIONS - 1).map(index => {
    const workLocation: WorkLocation | undefined = workLocations[index];
    if (workLocation === undefined) {
      return NO_SELECT_NUMBER;
    }
    return (
      workLocation.japanPrefecture?.japanPrefectureId ?? ADDRESS_OVERSEAS_ID
    );
  });

export const getDefaultCareerSkillsValue = (
  skills: CareerProfile['skills'] | undefined,
) =>
  skills
    ? skills.map(skill => ({
        value: skill.id,
        label: skill.name,
      }))
    : [];

export const getDefaultPreferredOccupationIdsValue = (
  preferredOccupationIds: UpdateInnovatorRequest['preferredOccupationIds'],
) =>
  preferredOccupationIds.length > 0
    ? preferredOccupationIds.map(id => ({ id }))
    : [{ id: NO_SELECT_NUMBER }];

export const getDefaultCareerExpectationsValue = (
  expectations: CareerProfile['expectations'] | undefined,
) =>
  expectations
    ? expectations.map(expectation => ({
        value: expectation.id,
        label: expectation.name,
      }))
    : [];

export const getDefaultCareerJobValue = (
  job: CareerProfileToUpdate['job'] | undefined,
) =>
  job
    ? job.map(jobHistory => ({
        ...jobHistory,
        endYear: jobHistory.endYear ?? END_YEAR_MONTH_CURRENTLY_ENROLLED_ID,
        endMonth: jobHistory.endMonth ?? END_YEAR_MONTH_CURRENTLY_ENROLLED_ID,
      }))
    : [];

export const getDefaultCareerMonthlyUnitPriceValue = (
  monthlyUnitPrice: CareerProfileToUpdate['monthlyUnitPrice'] | undefined,
) => (monthlyUnitPrice !== undefined ? String(monthlyUnitPrice) : '');

export const getDefaultOutputsValue = (
  outputs: UpdateInnovatorRequest['outputs'] | undefined,
) =>
  outputs && outputs.length > 0
    ? outputs.map(output => ({ value: output }))
    : [{ value: '' }];

export const getDefaultCertificationsValue = (
  certifications: UpdateInnovatorRequest['certifications'] | undefined,
) =>
  certifications && certifications.length > 0
    ? certifications.map(certification => ({ value: certification }))
    : [{ value: '' }];

export const getDefaultDttScoreValue = (
  dttScore: UpdateInnovatorRequest['dttScore'] | undefined,
) => (dttScore !== undefined ? String(dttScore) : '');

export const getDefaultNewGraduateResearchRoomsValue = (
  researchRooms: NewGraduateProfileToUpdate['researchRooms'] | undefined,
) =>
  researchRooms && researchRooms.length > 0
    ? researchRooms.map(researchRoom => ({
        name: researchRoom.name,
        content: researchRoom.content,
      }))
    : [
        {
          name: '',
          content: '',
        },
      ];

export const getDefaultNewGraduateProgrammingExperiencesValue = (
  programmingExperiences:
    | NewGraduateProfileToUpdate['programmingExperiences']
    | undefined,
) =>
  programmingExperiences && programmingExperiences.length > 0
    ? programmingExperiences
    : [{ languageName: '', levelId: NO_SELECT_NUMBER }];

export const getDefaultNewGraduatePreferredBusinessTypesValue = (
  preferredBusinessTypeIds:
    | NewGraduateProfileToUpdate['preferredBusinessTypeIds']
    | undefined,
) =>
  preferredBusinessTypeIds && preferredBusinessTypeIds.length > 0
    ? preferredBusinessTypeIds.map(id => ({ id }))
    : [{ id: NO_SELECT_NUMBER }];

export const getDefaultNewGraduateInternshipExperiencesValue = (
  internshipExperiences:
    | NewGraduateProfileToUpdate['internshipExperiences']
    | undefined,
) =>
  internshipExperiences && internshipExperiences.length > 0
    ? internshipExperiences.map(internshipExperience => ({
        companyName: internshipExperience.companyName,
        startYear: internshipExperience.startYear ?? NO_SELECT_NUMBER,
        startMonth: internshipExperience.startMonth ?? NO_SELECT_NUMBER,
        endYear: internshipExperience.endYear ?? NO_SELECT_NUMBER,
        endMonth: internshipExperience.endMonth ?? NO_SELECT_NUMBER,
      }))
    : [
        {
          companyName: '',
          startYear: NO_SELECT_NUMBER,
          startMonth: NO_SELECT_NUMBER,
          endYear: NO_SELECT_NUMBER,
          endMonth: NO_SELECT_NUMBER,
        },
      ];

export const getDefaultNewGraduateStudyAbroadExperiencesValue = (
  studyAbroadExperiences:
    | NewGraduateProfileToUpdate['studyAbroadExperiences']
    | undefined,
) =>
  studyAbroadExperiences && studyAbroadExperiences.length > 0
    ? studyAbroadExperiences.map(studyAbroadExperience => ({
        countryName: studyAbroadExperience.countryName,
        startYear: studyAbroadExperience.startYear ?? NO_SELECT_NUMBER,
        startMonth: studyAbroadExperience.startMonth ?? NO_SELECT_NUMBER,
        endYear: studyAbroadExperience.endYear ?? NO_SELECT_NUMBER,
        endMonth: studyAbroadExperience.endMonth ?? NO_SELECT_NUMBER,
      }))
    : [
        {
          countryName: '',
          startYear: NO_SELECT_NUMBER,
          startMonth: NO_SELECT_NUMBER,
          endYear: NO_SELECT_NUMBER,
          endMonth: NO_SELECT_NUMBER,
        },
      ];

export const getDefaultNewGraduateClubExperiencesValue = (
  clubExperiences: NewGraduateProfileToUpdate['clubExperiences'] | undefined,
) =>
  clubExperiences && clubExperiences.length > 0
    ? clubExperiences.map(clubExperience => ({
        name: clubExperience.name,
        content: clubExperience.content,
      }))
    : [
        {
          name: '',
          content: '',
        },
      ];
