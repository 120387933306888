import { Language } from '../../../proto/v1/apimodel/apimodel';
import { Reader, Writer } from 'protobufjs/minimal';
import * as Long from 'long';


export interface LoginRequest {
  email: string;
  password: string;
  accountId: string;
}

export interface LoginResponse {
  token: string;
  missionId: string | undefined;
  supporterId: string | undefined;
  preferredLanguage: Language | undefined;
  userId: string;
  accountId: string;
}

export interface AdminLoginRequest {
  email: string;
  password: string;
}

export interface AdminLoginResponse {
  token: string;
  adminId: string;
  preferredLanguage: Language | undefined;
  userId: string;
  accountId: string;
}

export interface InnovatorLoginRequest {
  email: string;
  password: string;
}

export interface InnovatorLoginResponse {
  token: string;
  innovatorId: string;
  userId: string;
  accountId: string;
}

export interface ConsultantLoginRequest {
  email: string;
  password: string;
}

export interface ConsultantLoginResponse {
  token: string;
  consultantId: string;
  userId: string;
  accountId: string;
}

const baseLoginRequest: object = {
  email: "",
  password: "",
  accountId: "",
};

const baseLoginResponse: object = {
  token: "",
  preferredLanguage: undefined,
  userId: "",
  accountId: "",
};

const baseAdminLoginRequest: object = {
  email: "",
  password: "",
};

const baseAdminLoginResponse: object = {
  token: "",
  adminId: "",
  preferredLanguage: undefined,
  userId: "",
  accountId: "",
};

const baseInnovatorLoginRequest: object = {
  email: "",
  password: "",
};

const baseInnovatorLoginResponse: object = {
  token: "",
  innovatorId: "",
  userId: "",
  accountId: "",
};

const baseConsultantLoginRequest: object = {
  email: "",
  password: "",
};

const baseConsultantLoginResponse: object = {
  token: "",
  consultantId: "",
  userId: "",
  accountId: "",
};

export interface LoginService {

  Login(request: LoginRequest): Promise<LoginResponse>;

  AdminLogin(request: AdminLoginRequest): Promise<AdminLoginResponse>;

  InnovatorLogin(request: InnovatorLoginRequest): Promise<InnovatorLoginResponse>;

  ConsultantLogin(request: ConsultantLoginRequest): Promise<ConsultantLoginResponse>;

}

export class LoginServiceClientImpl implements LoginService {

  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
  }

  Login(request: LoginRequest): Promise<LoginResponse> {
    const data = LoginRequest.encode(request).finish();
    const promise = this.rpc.request("loginservice.LoginService", "Login", data);
    return promise.then(data => LoginResponse.decode(new Reader(data)));
  }

  AdminLogin(request: AdminLoginRequest): Promise<AdminLoginResponse> {
    const data = AdminLoginRequest.encode(request).finish();
    const promise = this.rpc.request("loginservice.LoginService", "AdminLogin", data);
    return promise.then(data => AdminLoginResponse.decode(new Reader(data)));
  }

  InnovatorLogin(request: InnovatorLoginRequest): Promise<InnovatorLoginResponse> {
    const data = InnovatorLoginRequest.encode(request).finish();
    const promise = this.rpc.request("loginservice.LoginService", "InnovatorLogin", data);
    return promise.then(data => InnovatorLoginResponse.decode(new Reader(data)));
  }

  ConsultantLogin(request: ConsultantLoginRequest): Promise<ConsultantLoginResponse> {
    const data = ConsultantLoginRequest.encode(request).finish();
    const promise = this.rpc.request("loginservice.LoginService", "ConsultantLogin", data);
    return promise.then(data => ConsultantLoginResponse.decode(new Reader(data)));
  }

}

interface Rpc {

  request(service: string, method: string, data: Uint8Array): Promise<Uint8Array>;

}

function longToNumber(long: Long) {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new global.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

export const LoginRequest = {
  encode(message: LoginRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.email);
    writer.uint32(18).string(message.password);
    writer.uint32(26).string(message.accountId);
    return writer;
  },
  decode(reader: Reader, length?: number): LoginRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseLoginRequest) as LoginRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.email = reader.string();
          break;
        case 2:
          message.password = reader.string();
          break;
        case 3:
          message.accountId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): LoginRequest {
    const message = Object.create(baseLoginRequest) as LoginRequest;
    if (object.email) {
      message.email = String(object.email);
    }
    if (object.password) {
      message.password = String(object.password);
    }
    if (object.accountId) {
      message.accountId = String(object.accountId);
    }
    return message;
  },
  fromPartial(object: DeepPartial<LoginRequest>): LoginRequest {
    const message = Object.create(baseLoginRequest) as LoginRequest;
    if (object.email) {
      message.email = object.email;
    }
    if (object.password) {
      message.password = object.password;
    }
    if (object.accountId) {
      message.accountId = object.accountId;
    }
    return message;
  },
  toJSON(message: LoginRequest): unknown {
    const obj: any = {};
    obj.email = message.email || "";
    obj.password = message.password || "";
    obj.accountId = message.accountId || "";
    return obj;
  },
};

export const LoginResponse = {
  encode(message: LoginResponse, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.token);
    if (message.missionId !== undefined && message.missionId !== "") {
      writer.uint32(18).string(message.missionId);
    }
    if (message.supporterId !== undefined && message.supporterId !== "") {
      writer.uint32(26).string(message.supporterId);
    }
    if (message.preferredLanguage !== undefined && message.preferredLanguage !== undefined) {
      Language.encode(message.preferredLanguage, writer.uint32(34).fork()).ldelim();
    }
    writer.uint32(42).string(message.userId);
    writer.uint32(50).string(message.accountId);
    return writer;
  },
  decode(reader: Reader, length?: number): LoginResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseLoginResponse) as LoginResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.token = reader.string();
          break;
        case 2:
          message.missionId = reader.string();
          break;
        case 3:
          message.supporterId = reader.string();
          break;
        case 4:
          message.preferredLanguage = Language.decode(reader, reader.uint32());
          break;
        case 5:
          message.userId = reader.string();
          break;
        case 6:
          message.accountId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): LoginResponse {
    const message = Object.create(baseLoginResponse) as LoginResponse;
    if (object.token) {
      message.token = String(object.token);
    }
    if (object.missionId) {
      message.missionId = String(object.missionId);
    }
    if (object.supporterId) {
      message.supporterId = String(object.supporterId);
    }
    if (object.preferredLanguage) {
      message.preferredLanguage = Language.fromJSON(object.preferredLanguage);
    }
    if (object.userId) {
      message.userId = String(object.userId);
    }
    if (object.accountId) {
      message.accountId = String(object.accountId);
    }
    return message;
  },
  fromPartial(object: DeepPartial<LoginResponse>): LoginResponse {
    const message = Object.create(baseLoginResponse) as LoginResponse;
    if (object.token) {
      message.token = object.token;
    }
    if (object.missionId) {
      message.missionId = object.missionId;
    }
    if (object.supporterId) {
      message.supporterId = object.supporterId;
    }
    if (object.preferredLanguage) {
      message.preferredLanguage = Language.fromPartial(object.preferredLanguage);
    }
    if (object.userId) {
      message.userId = object.userId;
    }
    if (object.accountId) {
      message.accountId = object.accountId;
    }
    return message;
  },
  toJSON(message: LoginResponse): unknown {
    const obj: any = {};
    obj.token = message.token || "";
    obj.missionId = message.missionId || "";
    obj.supporterId = message.supporterId || "";
    obj.preferredLanguage = message.preferredLanguage ? Language.toJSON(message.preferredLanguage) : undefined;
    obj.userId = message.userId || "";
    obj.accountId = message.accountId || "";
    return obj;
  },
};

export const AdminLoginRequest = {
  encode(message: AdminLoginRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.email);
    writer.uint32(18).string(message.password);
    return writer;
  },
  decode(reader: Reader, length?: number): AdminLoginRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseAdminLoginRequest) as AdminLoginRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.email = reader.string();
          break;
        case 2:
          message.password = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): AdminLoginRequest {
    const message = Object.create(baseAdminLoginRequest) as AdminLoginRequest;
    if (object.email) {
      message.email = String(object.email);
    }
    if (object.password) {
      message.password = String(object.password);
    }
    return message;
  },
  fromPartial(object: DeepPartial<AdminLoginRequest>): AdminLoginRequest {
    const message = Object.create(baseAdminLoginRequest) as AdminLoginRequest;
    if (object.email) {
      message.email = object.email;
    }
    if (object.password) {
      message.password = object.password;
    }
    return message;
  },
  toJSON(message: AdminLoginRequest): unknown {
    const obj: any = {};
    obj.email = message.email || "";
    obj.password = message.password || "";
    return obj;
  },
};

export const AdminLoginResponse = {
  encode(message: AdminLoginResponse, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.token);
    writer.uint32(18).string(message.adminId);
    if (message.preferredLanguage !== undefined && message.preferredLanguage !== undefined) {
      Language.encode(message.preferredLanguage, writer.uint32(26).fork()).ldelim();
    }
    writer.uint32(34).string(message.userId);
    writer.uint32(42).string(message.accountId);
    return writer;
  },
  decode(reader: Reader, length?: number): AdminLoginResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseAdminLoginResponse) as AdminLoginResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.token = reader.string();
          break;
        case 2:
          message.adminId = reader.string();
          break;
        case 3:
          message.preferredLanguage = Language.decode(reader, reader.uint32());
          break;
        case 4:
          message.userId = reader.string();
          break;
        case 5:
          message.accountId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): AdminLoginResponse {
    const message = Object.create(baseAdminLoginResponse) as AdminLoginResponse;
    if (object.token) {
      message.token = String(object.token);
    }
    if (object.adminId) {
      message.adminId = String(object.adminId);
    }
    if (object.preferredLanguage) {
      message.preferredLanguage = Language.fromJSON(object.preferredLanguage);
    }
    if (object.userId) {
      message.userId = String(object.userId);
    }
    if (object.accountId) {
      message.accountId = String(object.accountId);
    }
    return message;
  },
  fromPartial(object: DeepPartial<AdminLoginResponse>): AdminLoginResponse {
    const message = Object.create(baseAdminLoginResponse) as AdminLoginResponse;
    if (object.token) {
      message.token = object.token;
    }
    if (object.adminId) {
      message.adminId = object.adminId;
    }
    if (object.preferredLanguage) {
      message.preferredLanguage = Language.fromPartial(object.preferredLanguage);
    }
    if (object.userId) {
      message.userId = object.userId;
    }
    if (object.accountId) {
      message.accountId = object.accountId;
    }
    return message;
  },
  toJSON(message: AdminLoginResponse): unknown {
    const obj: any = {};
    obj.token = message.token || "";
    obj.adminId = message.adminId || "";
    obj.preferredLanguage = message.preferredLanguage ? Language.toJSON(message.preferredLanguage) : undefined;
    obj.userId = message.userId || "";
    obj.accountId = message.accountId || "";
    return obj;
  },
};

export const InnovatorLoginRequest = {
  encode(message: InnovatorLoginRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.email);
    writer.uint32(18).string(message.password);
    return writer;
  },
  decode(reader: Reader, length?: number): InnovatorLoginRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseInnovatorLoginRequest) as InnovatorLoginRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.email = reader.string();
          break;
        case 2:
          message.password = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): InnovatorLoginRequest {
    const message = Object.create(baseInnovatorLoginRequest) as InnovatorLoginRequest;
    if (object.email) {
      message.email = String(object.email);
    }
    if (object.password) {
      message.password = String(object.password);
    }
    return message;
  },
  fromPartial(object: DeepPartial<InnovatorLoginRequest>): InnovatorLoginRequest {
    const message = Object.create(baseInnovatorLoginRequest) as InnovatorLoginRequest;
    if (object.email) {
      message.email = object.email;
    }
    if (object.password) {
      message.password = object.password;
    }
    return message;
  },
  toJSON(message: InnovatorLoginRequest): unknown {
    const obj: any = {};
    obj.email = message.email || "";
    obj.password = message.password || "";
    return obj;
  },
};

export const InnovatorLoginResponse = {
  encode(message: InnovatorLoginResponse, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.token);
    writer.uint32(18).string(message.innovatorId);
    writer.uint32(26).string(message.userId);
    writer.uint32(34).string(message.accountId);
    return writer;
  },
  decode(reader: Reader, length?: number): InnovatorLoginResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseInnovatorLoginResponse) as InnovatorLoginResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.token = reader.string();
          break;
        case 2:
          message.innovatorId = reader.string();
          break;
        case 3:
          message.userId = reader.string();
          break;
        case 4:
          message.accountId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): InnovatorLoginResponse {
    const message = Object.create(baseInnovatorLoginResponse) as InnovatorLoginResponse;
    if (object.token) {
      message.token = String(object.token);
    }
    if (object.innovatorId) {
      message.innovatorId = String(object.innovatorId);
    }
    if (object.userId) {
      message.userId = String(object.userId);
    }
    if (object.accountId) {
      message.accountId = String(object.accountId);
    }
    return message;
  },
  fromPartial(object: DeepPartial<InnovatorLoginResponse>): InnovatorLoginResponse {
    const message = Object.create(baseInnovatorLoginResponse) as InnovatorLoginResponse;
    if (object.token) {
      message.token = object.token;
    }
    if (object.innovatorId) {
      message.innovatorId = object.innovatorId;
    }
    if (object.userId) {
      message.userId = object.userId;
    }
    if (object.accountId) {
      message.accountId = object.accountId;
    }
    return message;
  },
  toJSON(message: InnovatorLoginResponse): unknown {
    const obj: any = {};
    obj.token = message.token || "";
    obj.innovatorId = message.innovatorId || "";
    obj.userId = message.userId || "";
    obj.accountId = message.accountId || "";
    return obj;
  },
};

export const ConsultantLoginRequest = {
  encode(message: ConsultantLoginRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.email);
    writer.uint32(18).string(message.password);
    return writer;
  },
  decode(reader: Reader, length?: number): ConsultantLoginRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseConsultantLoginRequest) as ConsultantLoginRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.email = reader.string();
          break;
        case 2:
          message.password = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): ConsultantLoginRequest {
    const message = Object.create(baseConsultantLoginRequest) as ConsultantLoginRequest;
    if (object.email) {
      message.email = String(object.email);
    }
    if (object.password) {
      message.password = String(object.password);
    }
    return message;
  },
  fromPartial(object: DeepPartial<ConsultantLoginRequest>): ConsultantLoginRequest {
    const message = Object.create(baseConsultantLoginRequest) as ConsultantLoginRequest;
    if (object.email) {
      message.email = object.email;
    }
    if (object.password) {
      message.password = object.password;
    }
    return message;
  },
  toJSON(message: ConsultantLoginRequest): unknown {
    const obj: any = {};
    obj.email = message.email || "";
    obj.password = message.password || "";
    return obj;
  },
};

export const ConsultantLoginResponse = {
  encode(message: ConsultantLoginResponse, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.token);
    writer.uint32(18).string(message.consultantId);
    writer.uint32(26).string(message.userId);
    writer.uint32(34).string(message.accountId);
    return writer;
  },
  decode(reader: Reader, length?: number): ConsultantLoginResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseConsultantLoginResponse) as ConsultantLoginResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.token = reader.string();
          break;
        case 2:
          message.consultantId = reader.string();
          break;
        case 3:
          message.userId = reader.string();
          break;
        case 4:
          message.accountId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): ConsultantLoginResponse {
    const message = Object.create(baseConsultantLoginResponse) as ConsultantLoginResponse;
    if (object.token) {
      message.token = String(object.token);
    }
    if (object.consultantId) {
      message.consultantId = String(object.consultantId);
    }
    if (object.userId) {
      message.userId = String(object.userId);
    }
    if (object.accountId) {
      message.accountId = String(object.accountId);
    }
    return message;
  },
  fromPartial(object: DeepPartial<ConsultantLoginResponse>): ConsultantLoginResponse {
    const message = Object.create(baseConsultantLoginResponse) as ConsultantLoginResponse;
    if (object.token) {
      message.token = object.token;
    }
    if (object.consultantId) {
      message.consultantId = object.consultantId;
    }
    if (object.userId) {
      message.userId = object.userId;
    }
    if (object.accountId) {
      message.accountId = object.accountId;
    }
    return message;
  },
  toJSON(message: ConsultantLoginResponse): unknown {
    const obj: any = {};
    obj.token = message.token || "";
    obj.consultantId = message.consultantId || "";
    obj.userId = message.userId || "";
    obj.accountId = message.accountId || "";
    return obj;
  },
};

type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T[P] extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T[P] extends Date | Function | Uint8Array | undefined
  ? T[P]
  : T[P] extends infer U | undefined
  ? DeepPartial<U>
  : T[P] extends object
  ? DeepPartial<T[P]>
  : T[P]
};