import React, { FC } from 'react';
import {
  InnovatorChatMessage,
  InnovatorChatUser,
} from 'proto/v1/apimodel/apimodel';
import styled, { css } from 'styled-components/macro';
import { formatChatMessageTimestamp, formatFileSize } from 'utils/chat';
import { interpolateMessage } from 'utils/string';
import {
  MessageDocument,
  MessageDownload,
  ChatUserIconConsultant as BaseChatUserIconConsultant,
} from 'assets/svg';
import * as MESSAGES from 'constants/messages';
import media from 'styles/media';
import theme from 'styles/theme';
import { chatMessageStyle } from 'styles/chat';
import useChatMessageMenu from 'hooks/chat/useChatMessageMenu';
import { ImageCircle, LinkText } from 'components/atoms';
import { ChatMessageMenu } from 'components/organisms';
import useMediaQuery from 'hooks/useMediaQuery';

const CHAT_USER_ICON_SIZE = '56px';

const Wrapper = styled.div`
  padding-top: 40px;

  ${media.mobile} {
    padding-top: 32px;
    user-select: none;
  }
`;

const MessageContainer = styled.div`
  display: flex;
  position: relative;
`;

const MessageContent = styled.div`
  padding-top: 24px;
`;

const MessageContentInner = styled.div`
  display: flex;
  align-items: center;
`;

const Message = styled.div`
  ${chatMessageStyle}
  width: fit-content;
  border: 1px solid ${theme.borderDefault};
  border-radius: 0 18px 18px 18px;
  background: ${theme.baseWhite};
  color: ${theme.textPrimary};
`;

const FileIcon = styled(MessageDocument)`
  min-width: 22px;
  margin-right: 12px;

  path {
    fill: ${theme.textPrimary};
  }
`;

const FileName = styled.div`
  margin-bottom: 4px;
`;

const FileSize = styled.div`
  font-size: 11px;
  line-height: 16px;
`;

const FileMessage = styled(Message)`
  display: flex;
  align-items: center;
`;

const DownloadIcon = styled(MessageDownload)`
  min-width: 22px;
  margin-left: 12px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  path {
    fill: ${theme.textPrimary};
  }
`;

const DeletedMessage = styled.div`
  width: fit-content;
  margin-right: auto;
  padding: 3px 16px;
  border-radius: 18px;
  background-color: ${theme.baseDisabled};
  color: #fff;
  font-size: 12px;
  line-height: 17px;
`;

const SentAt = styled.div`
  color: ${theme.textSecondary};
  font-size: 12px;
  line-height: 17px;
`;

const chatUserIconStyle = css`
  margin-right: 8px;

  ${media.mobile} {
    display: none;
  }
`;

const ChatUserIcon = styled(ImageCircle)`
  ${chatUserIconStyle}
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const ChatUserIconConsultant = styled(BaseChatUserIconConsultant)`
  ${chatUserIconStyle}
`;

const renderChatUserIcon: FC<{
  chatUser: InnovatorChatUser | undefined;
  onClickMissionIcon: (
    mission: InnovatorChatUser['mission'] | undefined,
  ) => Promise<void>;
}> = ({ chatUser, onClickMissionIcon }) => {
  return (
    <>
      {chatUser?.consultant && (
        <ChatUserIconConsultant
          data-testid="chat-message-user-icon-consultant"
          width={CHAT_USER_ICON_SIZE}
          height={CHAT_USER_ICON_SIZE}
        />
      )}
      {chatUser?.mission && (
        <ChatUserIcon
          imageSrc={chatUser?.iconImageUrl ?? ''}
          diameter={CHAT_USER_ICON_SIZE}
          border
          onClick={() => onClickMissionIcon(chatUser?.mission)}
          data-testid="chat-message-user-icon"
        />
      )}
    </>
  );
};

type Props = {
  chatMessage: InnovatorChatMessage;
  chatUser: InnovatorChatUser | undefined;
  onDownloadFile: (chatMessage: InnovatorChatMessage) => Promise<void>;
  onClickMissionIcon: (mission: InnovatorChatUser['mission']) => Promise<void>;
  unreadMessageRef?: React.RefObject<HTMLDivElement> | null;
};

type RenderMessagePayload = Props & {
  showMenuIcon: boolean;
  showMenu: boolean;
  toggleMenu: () => void;
  onClickCopyMenu: () => void;
  handleClick?: () => void;
  handleMouseDown?: () => void;
  handleMouseEnter?: () => void;
  handleMouseLeave?: () => void;
  handleTouchStart?: () => void;
  handleTouchEnd?: () => void;
};

const renderMessage: FC<RenderMessagePayload> = ({
  chatMessage,
  chatUser,
  onDownloadFile,
  toggleMenu,
  showMenu,
  showMenuIcon,
  onClickCopyMenu,
  onClickMissionIcon,
  handleClick,
  handleMouseDown,
  handleMouseEnter,
  handleMouseLeave,
  handleTouchStart,
  handleTouchEnd,
}) => {
  if (chatMessage.deleted) {
    return (
      <DeletedMessage>{MESSAGES.MESSAGES.DELETED_CHAT_MESSAGE}</DeletedMessage>
    );
  }

  if (chatMessage.file) {
    return (
      <MessageContainer>
        {renderChatUserIcon({ chatUser, onClickMissionIcon })}
        <MessageContent>
          <MessageContentInner>
            <FileMessage>
              <FileIcon />
              <div style={{ marginRight: 4 }}>
                <FileName>{chatMessage.message}</FileName>
                <FileSize>
                  {interpolateMessage(
                    MESSAGES.MESSAGES.CHAT_MESSAGE_FILE_SIZE,
                    {
                      fileSize: formatFileSize(chatMessage.file.size),
                    },
                  )}
                </FileSize>
              </div>
              <DownloadIcon onClick={() => onDownloadFile(chatMessage)} />
            </FileMessage>
          </MessageContentInner>
          <SentAt>{formatChatMessageTimestamp(chatMessage.sentAt)}</SentAt>
        </MessageContent>
      </MessageContainer>
    );
  }

  return (
    <MessageContainer>
      {renderChatUserIcon({ chatUser, onClickMissionIcon })}
      <MessageContent>
        <MessageContentInner>
          <Message
            onClick={handleClick}
            onMouseDown={handleMouseDown}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
          >
            <LinkText>{chatMessage.message}</LinkText>
          </Message>
          <ChatMessageMenu
            isOpponent
            chatMessage={chatMessage}
            showMenuIcon={showMenuIcon}
            showMenu={showMenu}
            toggleMenu={toggleMenu}
            onClickCopyMenu={onClickCopyMenu}
          />
        </MessageContentInner>
        <SentAt>{formatChatMessageTimestamp(chatMessage.sentAt)}</SentAt>
      </MessageContent>
    </MessageContainer>
  );
};

const OpponentChatMessage: FC<Props> = props => {
  const {
    showMenu,
    showMenuIcon,
    toggleMenu,
    handleClickCopyMenu,
    handleClick,
    handleMouseEnter,
    handleMouseLeave,
    handleMouseDown,
    handleTouchStart,
    handleTouchEnd,
  } = useChatMessageMenu();
  const { isMobile } = useMediaQuery();

  if (isMobile) {
    return (
      <Wrapper ref={props.unreadMessageRef}>
        {renderMessage({
          ...props,
          showMenu,
          showMenuIcon,
          toggleMenu,
          onClickCopyMenu: handleClickCopyMenu,
          handleClick,
          handleMouseEnter,
          handleMouseLeave,
          handleMouseDown,
          handleTouchStart,
          handleTouchEnd,
        })}
      </Wrapper>
    );
  }
  return (
    <Wrapper
      data-testid="opponent-chat-message"
      onClick={handleClick}
      onMouseDown={handleMouseDown}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={props.unreadMessageRef}
    >
      {renderMessage({
        ...props,
        showMenu,
        showMenuIcon,
        toggleMenu,
        onClickCopyMenu: handleClickCopyMenu,
      })}
    </Wrapper>
  );
};

export default OpponentChatMessage;
