import React from 'react';
import styled from 'styled-components';
import { Controller } from 'react-hook-form';
import * as MESSAGES from 'constants/messages';
import theme from 'styles/theme';
import media from 'styles/media';
import {
  ProfileFormControl,
  ProfileFormValues,
  ProfileFormItems,
} from 'types/profile';
import { PastExperienceAchievement } from 'proto/v1/apimodel/apimodel';
import { Field, MultiSelectbox } from 'components/molecules';

const StyledField = styled(Field)`
  & > div > h5 {
    color: ${theme.labelDefault};
    font-size: 16px;

    ${media.mobile} {
      font-size: 14px;
    }
  }
`;

type Value = {
  newGraduate: Pick<
    ProfileFormValues['newGraduate'],
    'pastExperienceAchievementIds'
  >;
};

type NewGraduatePastExperienceAchievementFormFieldProps = {
  control: ProfileFormControl<Value>;
  onSelect: (option: PastExperienceAchievement) => void;
  onDeselect: (option: PastExperienceAchievement) => void;
  pastExperienceAchievements: ProfileFormItems['pastExperienceAchievements'];
};

const NewGraduatePastExperienceAchievementFormField: React.FC<NewGraduatePastExperienceAchievementFormFieldProps> = props => {
  const { control, onSelect, onDeselect, pastExperienceAchievements } = props;

  return (
    <StyledField
      label={
        MESSAGES.PROFILE.FIELD.NEW_GRADUATE_PAST_EXPERIENCE_ACHIEVEMENTS.LABEL
      }
      note={
        MESSAGES.PROFILE.FIELD.NEW_GRADUATE_PAST_EXPERIENCE_ACHIEVEMENTS.NOTE
      }
    >
      <Controller
        name="newGraduate.pastExperienceAchievementIds"
        control={control}
        render={innerProps => (
          <MultiSelectbox<ProfileFormItems['pastExperienceAchievements'][0]>
            values={innerProps.value}
            placeholder={MESSAGES.COMMON.SELECT_BOX.NO_SELECT}
            options={pastExperienceAchievements}
            idName="id"
            textName="name"
            onSelect={onSelect}
            onDeselect={onDeselect}
            width={'100%'}
            testId={`multi-select-box-new-graduate-past-experience-achievement-ids`}
          />
        )}
      />
    </StyledField>
  );
};

export default NewGraduatePastExperienceAchievementFormField;
