import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { Login, CreateAccount, PasswordReset } from 'components/pages/Public';
import {
  RegisterProfile,
  Profile,
  PublishSetting,
  AccountSetting,
  Messages,
} from 'components/pages/Private';
import Terms from 'components/pages/Terms';
import ExpiredUrl from 'components/pages/ExpiredUrl';
import NotFound from 'components/pages/404';
import UnsubscribeEmail from 'components/pages/UnsubscribeEmail';
import * as ROUTES from 'routes/constants/routes';
import * as PUBLIC_ROUTES from 'routes/constants/public';
import * as PROTECTED_ROUTES from 'routes/constants/protected';
import * as MESSAGES from 'constants/messages';
import Route from 'routes/Route';
import PublicRoute from 'routes/PublicRoute';
import LoginRoute from 'routes/LoginRoute';
import PrivateRoute from 'routes/PrivateRoute';

const Routes = () => {
  return (
    <Switch>
      <LoginRoute path={PUBLIC_ROUTES.LOGIN} exact component={Login} />
      <PublicRoute
        path={`${PUBLIC_ROUTES.CREATE_ACCOUNT}/:type`}
        exact
        component={CreateAccount}
      />
      <PublicRoute
        path={PUBLIC_ROUTES.PASSWORD_RESET}
        exact
        component={PasswordReset}
      />
      <PrivateRoute
        path={PROTECTED_ROUTES.REGISTER_PROFILE}
        exact
        component={RegisterProfile}
      />
      <PrivateRoute
        path={PROTECTED_ROUTES.PROFILE}
        exact
        component={Profile}
        title={MESSAGES.PROFILE_SETTING.TITLE}
      />
      <PrivateRoute
        path={PROTECTED_ROUTES.PUBLISH_SETTING}
        exact
        component={PublishSetting}
        title={MESSAGES.PROFILE_SETTING.TITLE}
      />
      <PrivateRoute
        path={PROTECTED_ROUTES.ACCOUNT_SETTING}
        exact
        component={AccountSetting}
        title={MESSAGES.ACCOUNT_SETTING.TITLE}
      />
      <PrivateRoute
        path={[PROTECTED_ROUTES.MESSAGES, `${PROTECTED_ROUTES.MESSAGES}/:id`]}
        exact
        component={Messages}
        title={MESSAGES.MESSAGES.TITLE}
      />
      <Route path={ROUTES.TERMS} exact component={Terms} />
      <Route path={ROUTES.EXPIRED_URL} exact component={ExpiredUrl} />
      <Route
        path={ROUTES.UNSUBSCRIBE_EMAIL}
        exact
        component={UnsubscribeEmail}
      />
      <Redirect from={ROUTES.TOP} to={PUBLIC_ROUTES.LOGIN} exact />
      <Route component={NotFound} />
    </Switch>
  );
};

export default Routes;
