import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { useFieldArray } from 'react-hook-form';
import * as MESSAGES from 'constants/messages';
import media from 'styles/media';
import theme from 'styles/theme';
import {
  PROFILE_FIELDS_MAX_LENGTH,
  MAX_CERTIFICATIONS,
} from 'constants/profile';
import { ProfileFormControl, ProfileFormRegister } from 'types/profile';
import { CircleCross } from 'assets/svg';
import { TextInput, AddField } from 'components/atoms';
import { Field } from 'components/molecules';

const FieldItem = styled.div`
  display: flex;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const TextInputWrapper = styled.div`
  width: 100%;
`;

const StyleTextInput = styled(TextInput)`
  width: 100%;
`;

const StyledField = styled(Field)`
  ${media.mobile} {
    h5 {
      margin-bottom: 4px;
    }

    .field-note {
      display: block;
      margin-bottom: 4px;
      margin-left: 0;
      line-height: 1.5;
    }
  }
`;

const RemoveCertification = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  padding-top: 12px;

  ${media.mobile} {
    align-self: flex-end;
    height: 40px;
    padding-left: 8px;
    border-left: 1px solid ${theme.borderDefault};
  }
`;

const IconRemove = styled(CircleCross)`
  display: inline-block;
  align-self: flex-start;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

type CertificationsFormFieldProps = {
  register: ProfileFormRegister<'certifications'>;
  control: ProfileFormControl<'certifications'>;
};

const CertificationsFormField: FC<CertificationsFormFieldProps> = props => {
  const { register, control } = props;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'certifications',
  });

  return (
    <StyledField label={MESSAGES.PROFILE.FIELD.CERTIFICATIONS.LABEL}>
      {fields.map((field, index) => (
        <FieldItem key={field.id}>
          <TextInputWrapper>
            <StyleTextInput
              enterKeyHint="enter"
              name={`certifications[${index}].value`}
              maxLength={PROFILE_FIELDS_MAX_LENGTH.CERTIFICATION}
              placeholder={MESSAGES.PROFILE.FIELD.CERTIFICATIONS.PLACEHOLDER}
              aria-label={MESSAGES.PROFILE.FIELD.CERTIFICATIONS.LABEL}
              defaultValue={field.value}
              ref={register()}
            />
          </TextInputWrapper>

          {index > 0 && (
            <RemoveCertification>
              <IconRemove
                onClick={() => remove(index)}
                data-testid="remove-certification"
              />
            </RemoveCertification>
          )}
        </FieldItem>
      ))}
      {fields.length < MAX_CERTIFICATIONS && (
        <AddField onClick={() => append({})} data-testid="add-certification" />
      )}
    </StyledField>
  );
};

export default CertificationsFormField;
