import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getInnovator, AccountActions } from 'modules/account';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';
import { UiActions } from 'modules/ui';
import { NO_SELECT_NUMBER } from 'constants/form';
import apiInnovator from 'external/api/innovator';
import {
  CareerProfileSkillsAndPreferredConditionsFormValues,
  ProfileFormItems,
} from 'types/profile';
import RequestError from 'classes/RequestError';
import { UpdateInnovatorRequest } from 'proto/v1/innovatorservice/innovatorservice';
import {
  MAX_PREFERRED_WORK_LOCATIONS,
  RESUME_FILE_DEFAULT,
} from 'constants/profile';
import { range } from 'utils/array';
import convertToInnovatorToUpdate from 'utils/profile/convertToInnovatorToUpdate';
import {
  getDefaultCareerSkillsValue,
  getDefaultPreferredWorkLocationJapanPrefectureIdsValue,
  getDefaultPreferredOccupationIdsValue,
  getDefaultCareerExpectationsValue,
  getDefaultOutputsValue,
  getDefaultCertificationsValue,
} from 'utils/profile/defaultFormValues';
import {
  getCareerSkillIdsValueToUpdate,
  getPreferredWorkLocationsValueToUpdate,
  getPreferredOccupationIdsValueToUpdate,
  getCareerExpectationIdsValueToUpdate,
  getOutputsValueToUpdate,
  getCertificationsValueToUpdate,
} from 'utils/profile/formValuesToUpdate';
import {
  careerSkillsSchema,
  englishSkillIdSchema,
  outputsSchema,
  preferredWorkLocationJapanPrefectureIdSchema,
  preferredOccupationsSchema,
  careerPreferredEmploymentTypeIdSchema,
  careerPreferredMonthlyUnitPriceIdSchema,
  careerExpectationsSchema,
  selfIntroductionSchema,
} from 'utils/profile/schema';
import useErrorNotification from 'hooks/useErrorNotification';
import useCareerExpectationsFormField from 'hooks/profile/useCareerExpectationsFormField';
import useCareerSkillsFormField from 'hooks/profile/useCareerSkillsFormField';
import useResumeFileFormField from 'hooks/profile/useCareerResumeFileFormField';
import usePreferredOccupationsFormField from 'hooks/profile/usePreferredOccupationsFormField';

const schema = object().shape({
  englishSkillId: englishSkillIdSchema,
  preferredWorkLocationJapanPrefectureIds: preferredWorkLocationJapanPrefectureIdSchema,
  preferredOccupations: preferredOccupationsSchema,
  outputs: outputsSchema,
  career: object().shape({
    skills: careerSkillsSchema,
    preferredEmploymentTypeId: careerPreferredEmploymentTypeIdSchema,
    expectations: careerExpectationsSchema,
    preferredMonthlyUnitPriceId: careerPreferredMonthlyUnitPriceIdSchema,
  }),
  selfIntroduction: selfIntroductionSchema,
});

const defaultValues: CareerProfileSkillsAndPreferredConditionsFormValues = {
  englishSkillId: NO_SELECT_NUMBER,
  preferredWorkLocationJapanPrefectureIds: range(
    0,
    MAX_PREFERRED_WORK_LOCATIONS - 1,
  ).map(() => NO_SELECT_NUMBER),
  preferredOccupations: [],
  outputs: [],
  career: {
    skills: [],
    expectations: [],
    achievement: '',
    preferredMonthlyUnitPriceId: NO_SELECT_NUMBER,
    resumeFile: RESUME_FILE_DEFAULT,
    preferredEmploymentTypeId: NO_SELECT_NUMBER,
  },
  certifications: [],
  selfIntroduction: '',
};

type Payload = {
  formItems: ProfileFormItems;
  onShowContent: () => void;
  onOpenCompletedModal: () => void;
};

const useCareerProfileSkillsAndPreferredConditionsForm = ({
  formItems,
  onShowContent,
  onOpenCompletedModal,
}: Payload) => {
  const dispatch = useDispatch();
  const { handleRequestError } = useErrorNotification();

  const innovator = useSelector(getInnovator);

  const innovatorToUpdate = useMemo(
    () => innovator && convertToInnovatorToUpdate(innovator),
    [innovator],
  );

  const formMethods = useForm<
    CareerProfileSkillsAndPreferredConditionsFormValues
  >({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues,
  });

  const { reset } = formMethods;

  const {
    skillsOptionsState,
    exampleSkills,
    handleClickExampleSkill,
    handleInputChangeSkill,
    handleCreateSkillOption,
    fetchMoreSkillOptions,
  } = useCareerSkillsFormField({
    formMethods,
  });

  const {
    expectationsOptionsState,
    exampleExpectations,
    handleClickExampleExpectation,
    handleInputChangeExpectation,
    handleCreateExpectationOption,
    fetchMoreExpectationOptions,
  } = useCareerExpectationsFormField({ formMethods });

  const { preferredOccupationsError } = usePreferredOccupationsFormField({
    formMethods,
  });

  const {
    resumeFile,
    setResumeFile,
    handleDropResumeFile,
    handleRemoveResumeFile,
  } = useResumeFileFormField({
    formMethods,
  });

  const onSubmit = useCallback(
    async (values: CareerProfileSkillsAndPreferredConditionsFormValues) => {
      const valuesToUpdate: UpdateInnovatorRequest = {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        ...innovatorToUpdate!,
        englishSkillId: values.englishSkillId,
        preferredWorkLocations: getPreferredWorkLocationsValueToUpdate(
          values.preferredWorkLocationJapanPrefectureIds,
        ),
        preferredOccupationIds: getPreferredOccupationIdsValueToUpdate(
          values.preferredOccupations,
        ),
        certifications: getCertificationsValueToUpdate(values.certifications),
        selfIntroduction: values.selfIntroduction,
        outputs: getOutputsValueToUpdate(values.outputs),
        career: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          ...innovatorToUpdate!.career!,
          achievement: values.career.achievement,
          fileKey: values.career.resumeFile.key ?? '',
          preferredMonthlyUnitPriceId:
            values.career.preferredMonthlyUnitPriceId,
          preferredEmploymentTypeId: values.career.preferredEmploymentTypeId,
          skillIds: getCareerSkillIdsValueToUpdate(values.career.skills),
          expectationIds: getCareerExpectationIdsValueToUpdate(
            values.career.expectations,
          ),
        },
      };
      let data;
      try {
        ({ data } = await apiInnovator.updateInnovator(valuesToUpdate));
      } catch (error: unknown) {
        if (error instanceof RequestError) {
          dispatch(UiActions.setLoading(false));
          handleRequestError(error);
        }
        return;
      }
      dispatch(AccountActions.setInnovator(data.innovator));
      dispatch(UiActions.setLoading(false));
      onShowContent();
      onOpenCompletedModal();
    },
    [
      dispatch,
      handleRequestError,
      innovatorToUpdate,
      onOpenCompletedModal,
      onShowContent,
    ],
  );

  // Set default values by the fetched innovator
  useEffect(() => {
    if (!innovatorToUpdate || !innovator) return;

    const values: CareerProfileSkillsAndPreferredConditionsFormValues = {
      englishSkillId: innovatorToUpdate.englishSkillId,
      preferredWorkLocationJapanPrefectureIds: getDefaultPreferredWorkLocationJapanPrefectureIdsValue(
        innovatorToUpdate.preferredWorkLocations,
      ),
      preferredOccupations: getDefaultPreferredOccupationIdsValue(
        innovatorToUpdate.preferredOccupationIds,
      ),
      selfIntroduction: innovatorToUpdate.selfIntroduction,
      certifications: getDefaultCertificationsValue(
        innovatorToUpdate.certifications,
      ),
      outputs: getDefaultOutputsValue(innovatorToUpdate.outputs),
      career: {
        skills: getDefaultCareerSkillsValue(innovator.career?.skills),
        expectations: getDefaultCareerExpectationsValue(
          innovator.career?.expectations,
        ),
        preferredEmploymentTypeId:
          innovatorToUpdate.career?.preferredEmploymentTypeId ??
          NO_SELECT_NUMBER,
        achievement: innovatorToUpdate.career?.achievement ?? '',
        resumeFile: innovator.career?.resumeFile ?? RESUME_FILE_DEFAULT,
        preferredMonthlyUnitPriceId:
          innovatorToUpdate.career?.preferredMonthlyUnitPriceId ??
          NO_SELECT_NUMBER,
      },
    };

    setResumeFile(innovator.career?.resumeFile ?? RESUME_FILE_DEFAULT);

    reset(values);
  }, [formItems.countries, innovator, innovatorToUpdate, reset, setResumeFile]);

  return {
    innovator,
    onSubmit,
    formMethods,
    formItems,
    exampleSkills,
    skillsOptionsState,
    expectationsOptionsState,
    exampleExpectations,
    resumeFile,
    preferredOccupationsError,
    fetchMoreSkillOptions,
    handleInputChangeSkill,
    handleCreateSkillOption,
    handleClickExampleSkill,
    fetchMoreExpectationOptions,
    handleClickExampleExpectation,
    handleInputChangeExpectation,
    handleCreateExpectationOption,
    handleDropResumeFile,
    handleRemoveResumeFile,
  };
};

export default useCareerProfileSkillsAndPreferredConditionsForm;
