import { Tab } from 'react-tabs';
import styled, { css } from 'styled-components/macro';
import theme from 'styles/theme';
import media from 'styles/media';

const tabUnderLine = css`
  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 3px;
    margin: 0 auto;
    border-radius: 8px;
    background-color: ${theme.basePrimary};
  }
`;

const StyledTab = styled(Tab)`
  ${tabUnderLine}
  display: inline-block;
  position: relative;
  padding: 24px 32px;
  color: ${theme.textPrimary};
  font-size: 20px;
  font-weight: bold;
  line-height: 1.5;
  text-align: center;
  cursor: pointer;

  ${media.mobile} {
    padding: 16px 28px;
    font-size: 16px;
  }

  &::after {
    width: 0;
  }

  &:hover {
    color: ${theme.basePrimary};
    ${tabUnderLine}

    &::after {
      width: 100%;
      transition: 0.3s;
    }
  }

  &:focus {
    outline: none;
  }

  &[aria-selected='true'] {
    cursor: default;

    &::after {
      width: 100%;
    }
  }

  &[aria-disabled='true'] {
    color: ${theme.baseDisabled};
    cursor: default;
    pointer-events: none;
  }
`;

export default StyledTab;
