import React, { FC } from 'react';
import * as MESSAGES from 'constants/messages';
import { ButtonM } from 'components/atoms';
import { Modal, ModalContent, ModalFooter } from 'components/molecules';

type SendBackModalProps = {
  show: boolean;
  onClose: () => void;
};

const SendBackModal: FC<SendBackModalProps> = ({ show, onClose }) => {
  return (
    <Modal show={show} closeModal={onClose}>
      <ModalContent
        title={MESSAGES.REGISTER_PROFILE.MODAL.SEND_BACK.TITLE}
        data-testid="modal-send-back"
      >
        {MESSAGES.REGISTER_PROFILE.MODAL.SEND_BACK.CONTENT}
      </ModalContent>
      <ModalFooter>
        <ButtonM onClick={onClose} buttonTheme="cancel">
          {MESSAGES.COMMON.BUTTON.CLOSE}
        </ButtonM>
      </ModalFooter>
    </Modal>
  );
};

export default SendBackModal;
