// eslint-disable-next-line import/prefer-default-export
export const MATTER_STATUS_ID = {
  NONE: 0,
  WAITING: 1,
  DISAPPROVED: 2,
  SEND_BACK: 3,
  APPROVED: 4,
  UNPUBLISHED: 5,
  CLOSED: 6,
} as const;
