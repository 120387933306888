import { Mission, MissionProduct, Images, Supporter, CareerMattersFilter, NewGraduateMattersFilter, Matter, AccountFollowee, AccountFollower, FinancingSeries, CorporateAttribute, JapanPrefecture, MissionCategory, Country, Demand, SupportArea, EmploymentType, Occupation, PreferredWorkStyle, Department, PastExperienceAchievement, PreferredBusinessType, DttRank, Company, School, Skill, Expectation, MessageTemplate } from '../../../proto/v1/apimodel/apimodel';
import { Reader, Writer } from 'protobufjs/minimal';
import * as Long from 'long';
import { StringValue, BoolValue } from '../../../proto/google/protobuf/wrappers';


export interface GetMissionsRequest {
  limit: number;
  q: string;
  demandIds: number[];
  financingSeriesIds: number[];
  corporateAttributeIds: number[];
  japanPrefectureIds: number[];
  categoryIds: number[];
  countryId: string | undefined;
}

export interface Paging {
  nextQ: string;
}

export interface GetMissionsResponse {
  missions: Mission[];
  paging: Paging | undefined;
}

export interface GetMissionProductsRequest {
  limit: number;
  q: string;
  isForBusiness: boolean;
  isForCustomer: boolean;
  hasMemberBenefit: boolean;
}

export interface GetMissionProductsResponse {
  products: GetMissionProductsResponse_MissionProductSummary[];
  paging: Paging | undefined;
}

export interface GetMissionProductsResponse_MissionProductSummary {
  product: MissionProduct | undefined;
  missionId: string;
  iconImage: Images | undefined;
  companyName: string;
}

export interface GetSupportersRequest {
  limit: number;
  q: string;
  countryId: string | undefined;
  japanPrefectureIds: number[];
  supportAreaIds: number[];
}

export interface GetSupportersResponse {
  supporters: Supporter[];
  paging: Paging | undefined;
}

export interface GetMattersRequest {
  q: string;
  limit: number;
  careerMattersFilter: CareerMattersFilter | undefined;
  newGraduateMattersFilter: NewGraduateMattersFilter | undefined;
}

export interface GetMattersResponse {
  matters: Matter[];
  paging: Paging | undefined;
}

export interface GetMattersTotalCountRequest {
  careerMattersFilter: CareerMattersFilter | undefined;
  newGraduateMattersFilter: NewGraduateMattersFilter | undefined;
}

export interface GetMattersTotalCountResponse {
  count: number;
}

export interface GetMissionRequest {
  missionId: string;
  language: string | undefined;
}

export interface GetMissionResponse {
  mission: Mission | undefined;
  followed: boolean;
  followable: boolean;
}

export interface GetSupporterRequest {
  supporterId: string;
  language: string | undefined;
}

export interface GetSupporterResponse {
  supporter: Supporter | undefined;
  followed: boolean;
  followable: boolean;
}

export interface GetMatterRequest {
  matterId: string;
}

export interface GetMatterResponse {
  matter: Matter | undefined;
}

export interface GetFollowingAccountsRequest {
  q: string;
}

export interface GetFollowingAccountsResponse {
  accountFollowees: AccountFollowee[];
  nextQuery: string;
}

export interface GetFollowerAccountsRequest {
  limit: number;
  haveContacted: boolean | undefined;
  mutualFollower: boolean | undefined;
  nextQuery: string;
}

export interface GetFollowerAccountsResponse {
  accountFollowers: AccountFollower[];
  nextQuery: string;
}

export interface UnfollowAccountsRequest {
  accountIds: string[];
}

export interface UnfollowAccountsResponse {
}

export interface ChangePasswordRequest {
  currentPassword: string;
  newPassword: string;
}

export interface ChangePasswordResponse {
}

export interface UploadImageRequest {
  type: string;
  data: string;
}

export interface UploadImageResponse {
  key: string;
  images: Images | undefined;
}

export interface ClearNotificationRequest {
  messageType: string;
  notificationId: string;
}

export interface ClearNotificationResponse {
}

export interface GetMissionSearchConditionsRequest {
}

export interface GetMissionSearchConditionsResponse {
  financingSeries: FinancingSeries[];
  corporateAttributes: CorporateAttribute[];
  japanPrefectures: JapanPrefecture[];
  missionCategories: MissionCategory[];
  countries: Country[];
  demands: Demand[];
}

export interface GetSupporterSearchConditionsRequest {
}

export interface GetSupporterSearchConditionsResponse {
  countries: Country[];
  japanPrefectures: JapanPrefecture[];
  supportAreas: SupportArea[];
}

export interface GetMatterSearchConditionsRequest {
  matterSearchConditionType: string;
}

export interface GetMatterSearchConditionsResponse {
  careerMatterSearchConditions: CareerMatterSearchConditions | undefined;
  newGraduateMatterSearchConditions: NewGraduateMatterSearchConditions | undefined;
}

export interface CareerMatterSearchConditions {
  employmentTypes: EmploymentType[];
  occupations: Occupation[];
  japanPrefectures: JapanPrefecture[];
}

export interface NewGraduateMatterSearchConditions {
  preferredWorkStyles: PreferredWorkStyle[];
  departments: Department[];
  pastExperienceAchievements: PastExperienceAchievement[];
  preferredBusinessTypes: PreferredBusinessType[];
  occupations: Occupation[];
  japanPrefectures: JapanPrefecture[];
  dttRanks: DttRank[];
}

export interface GetFeaturesRequest {
}

export interface GetFeaturesResponse {
  features: { [key: string]: boolean };
}

export interface GetFeaturesResponse_FeaturesEntry {
  key: string;
  value: boolean;
}

export interface GetMissionDemandsRequest {
  missionId: string;
}

export interface GetMissionDemandsResponse {
  demands: Demand[];
}

export interface GetCompaniesRequest {
  nameLike: string;
  limit: number;
  q: string;
}

export interface GetCompaniesResponse {
  companies: Company[];
  paging: Paging | undefined;
}

export interface GetSchoolsRequest {
  nameStartWith: string;
  limit: number;
  q: string;
  schoolIds: number[];
}

export interface GetSchoolsResponse {
  schools: School[];
  paging: Paging | undefined;
}

export interface RegisterSchoolRequest {
  name: string;
}

export interface RegisterSchoolResponse {
  school: School | undefined;
}

export interface GetSkillsRequest {
  nameStartWith: string;
  skillIds: number[];
  limit: number;
  q: string;
}

export interface GetSkillsResponse {
  skills: Skill[];
  paging: Paging | undefined;
}

export interface GetExampleSkillsRequest {
}

export interface GetExampleSkillsResponse {
  skills: Skill[];
}

export interface RegisterSkillRequest {
  name: string;
}

export interface RegisterSkillResponse {
  skill: Skill | undefined;
}

export interface GetExpectationsRequest {
  nameStartWith: string;
  limit: number;
  q: string;
}

export interface GetExpectationsResponse {
  expectations: Expectation[];
  paging: Paging | undefined;
}

export interface GetExampleExpectationsRequest {
}

export interface GetExampleExpectationsResponse {
  expectations: Expectation[];
}

export interface RegisterExpectationRequest {
  name: string;
}

export interface RegisterExpectationResponse {
  expectation: Expectation | undefined;
}

export interface UploadFileRequest {
  data: string;
  name: string;
}

export interface UploadFileResponse {
  key: string;
}

export interface PutMatterViewedRequest {
  matterPublishId: number;
}

export interface PutMatterViewedResponse {
}

export interface PutMatterFavoriteRequest {
  matterPublishId: number;
  favorite: boolean;
}

export interface PutMatterFavoriteResponse {
}

export interface GetMessageTemplatesRequest {
}

export interface GetMessageTemplatesResponse {
  messageTemplates: MessageTemplate[];
}

export interface RegisterMessageTemplateRequest {
  name: string;
  template: string;
}

export interface RegisterMessageTemplateResponse {
}

export interface PutMessageTemplateRequest {
  messageTemplateId: number;
  name: string;
  template: string;
}

export interface PutMessageTemplateResponse {
}

export interface DeleteMessageTemplatesRequest {
  messageTemplateIds: number[];
}

export interface DeleteMessageTemplatesResponse {
}

export interface UseMessageTemplateRequest {
  messageTemplateId: number;
  accountId: string;
}

export interface UseMessageTemplateResponse {
  message: string;
}

const baseGetMissionsRequest: object = {
  limit: 0,
  q: "",
  demandIds: 0,
  financingSeriesIds: 0,
  corporateAttributeIds: 0,
  japanPrefectureIds: 0,
  categoryIds: 0,
  countryId: undefined,
};

const basePaging: object = {
  nextQ: "",
};

const baseGetMissionsResponse: object = {
  missions: undefined,
  paging: undefined,
};

const baseGetMissionProductsRequest: object = {
  limit: 0,
  q: "",
  isForBusiness: false,
  isForCustomer: false,
  hasMemberBenefit: false,
};

const baseGetMissionProductsResponse: object = {
  products: undefined,
  paging: undefined,
};

const baseGetMissionProductsResponse_MissionProductSummary: object = {
  product: undefined,
  missionId: "",
  iconImage: undefined,
  companyName: "",
};

const baseGetSupportersRequest: object = {
  limit: 0,
  q: "",
  countryId: undefined,
  japanPrefectureIds: 0,
  supportAreaIds: 0,
};

const baseGetSupportersResponse: object = {
  supporters: undefined,
  paging: undefined,
};

const baseGetMattersRequest: object = {
  q: "",
  limit: 0,
};

const baseGetMattersResponse: object = {
  matters: undefined,
  paging: undefined,
};

const baseGetMattersTotalCountRequest: object = {
};

const baseGetMattersTotalCountResponse: object = {
  count: 0,
};

const baseGetMissionRequest: object = {
  missionId: "",
  language: undefined,
};

const baseGetMissionResponse: object = {
  mission: undefined,
  followed: false,
  followable: false,
};

const baseGetSupporterRequest: object = {
  supporterId: "",
  language: undefined,
};

const baseGetSupporterResponse: object = {
  supporter: undefined,
  followed: false,
  followable: false,
};

const baseGetMatterRequest: object = {
  matterId: "",
};

const baseGetMatterResponse: object = {
  matter: undefined,
};

const baseGetFollowingAccountsRequest: object = {
  q: "",
};

const baseGetFollowingAccountsResponse: object = {
  accountFollowees: undefined,
  nextQuery: "",
};

const baseGetFollowerAccountsRequest: object = {
  limit: 0,
  haveContacted: undefined,
  mutualFollower: undefined,
  nextQuery: "",
};

const baseGetFollowerAccountsResponse: object = {
  accountFollowers: undefined,
  nextQuery: "",
};

const baseUnfollowAccountsRequest: object = {
  accountIds: "",
};

const baseUnfollowAccountsResponse: object = {
};

const baseChangePasswordRequest: object = {
  currentPassword: "",
  newPassword: "",
};

const baseChangePasswordResponse: object = {
};

const baseUploadImageRequest: object = {
  type: "",
  data: "",
};

const baseUploadImageResponse: object = {
  key: "",
  images: undefined,
};

const baseClearNotificationRequest: object = {
  messageType: "",
  notificationId: "",
};

const baseClearNotificationResponse: object = {
};

const baseGetMissionSearchConditionsRequest: object = {
};

const baseGetMissionSearchConditionsResponse: object = {
  financingSeries: undefined,
  corporateAttributes: undefined,
  japanPrefectures: undefined,
  missionCategories: undefined,
  countries: undefined,
  demands: undefined,
};

const baseGetSupporterSearchConditionsRequest: object = {
};

const baseGetSupporterSearchConditionsResponse: object = {
  countries: undefined,
  japanPrefectures: undefined,
  supportAreas: undefined,
};

const baseGetMatterSearchConditionsRequest: object = {
  matterSearchConditionType: "",
};

const baseGetMatterSearchConditionsResponse: object = {
};

const baseCareerMatterSearchConditions: object = {
  employmentTypes: undefined,
  occupations: undefined,
  japanPrefectures: undefined,
};

const baseNewGraduateMatterSearchConditions: object = {
  preferredWorkStyles: undefined,
  departments: undefined,
  pastExperienceAchievements: undefined,
  preferredBusinessTypes: undefined,
  occupations: undefined,
  japanPrefectures: undefined,
  dttRanks: undefined,
};

const baseGetFeaturesRequest: object = {
};

const baseGetFeaturesResponse: object = {
  features: undefined,
};

const baseGetFeaturesResponse_FeaturesEntry: object = {
  key: "",
  value: false,
};

const baseGetMissionDemandsRequest: object = {
  missionId: "",
};

const baseGetMissionDemandsResponse: object = {
  demands: undefined,
};

const baseGetCompaniesRequest: object = {
  nameLike: "",
  limit: 0,
  q: "",
};

const baseGetCompaniesResponse: object = {
  companies: undefined,
  paging: undefined,
};

const baseGetSchoolsRequest: object = {
  nameStartWith: "",
  limit: 0,
  q: "",
  schoolIds: 0,
};

const baseGetSchoolsResponse: object = {
  schools: undefined,
  paging: undefined,
};

const baseRegisterSchoolRequest: object = {
  name: "",
};

const baseRegisterSchoolResponse: object = {
  school: undefined,
};

const baseGetSkillsRequest: object = {
  nameStartWith: "",
  skillIds: 0,
  limit: 0,
  q: "",
};

const baseGetSkillsResponse: object = {
  skills: undefined,
  paging: undefined,
};

const baseGetExampleSkillsRequest: object = {
};

const baseGetExampleSkillsResponse: object = {
  skills: undefined,
};

const baseRegisterSkillRequest: object = {
  name: "",
};

const baseRegisterSkillResponse: object = {
  skill: undefined,
};

const baseGetExpectationsRequest: object = {
  nameStartWith: "",
  limit: 0,
  q: "",
};

const baseGetExpectationsResponse: object = {
  expectations: undefined,
  paging: undefined,
};

const baseGetExampleExpectationsRequest: object = {
};

const baseGetExampleExpectationsResponse: object = {
  expectations: undefined,
};

const baseRegisterExpectationRequest: object = {
  name: "",
};

const baseRegisterExpectationResponse: object = {
  expectation: undefined,
};

const baseUploadFileRequest: object = {
  data: "",
  name: "",
};

const baseUploadFileResponse: object = {
  key: "",
};

const basePutMatterViewedRequest: object = {
  matterPublishId: 0,
};

const basePutMatterViewedResponse: object = {
};

const basePutMatterFavoriteRequest: object = {
  matterPublishId: 0,
  favorite: false,
};

const basePutMatterFavoriteResponse: object = {
};

const baseGetMessageTemplatesRequest: object = {
};

const baseGetMessageTemplatesResponse: object = {
  messageTemplates: undefined,
};

const baseRegisterMessageTemplateRequest: object = {
  name: "",
  template: "",
};

const baseRegisterMessageTemplateResponse: object = {
};

const basePutMessageTemplateRequest: object = {
  messageTemplateId: 0,
  name: "",
  template: "",
};

const basePutMessageTemplateResponse: object = {
};

const baseDeleteMessageTemplatesRequest: object = {
  messageTemplateIds: 0,
};

const baseDeleteMessageTemplatesResponse: object = {
};

const baseUseMessageTemplateRequest: object = {
  messageTemplateId: 0,
  accountId: "",
};

const baseUseMessageTemplateResponse: object = {
  message: "",
};

export interface CommonService {

  GetMissions(request: GetMissionsRequest): Promise<GetMissionsResponse>;

  GetMissionProducts(request: GetMissionProductsRequest): Promise<GetMissionProductsResponse>;

  GetSupporters(request: GetSupportersRequest): Promise<GetSupportersResponse>;

  GetMatters(request: GetMattersRequest): Promise<GetMattersResponse>;

  GetMattersTotalCount(request: GetMattersTotalCountRequest): Promise<GetMattersTotalCountResponse>;

  GetFollowingAccounts(request: GetFollowingAccountsRequest): Promise<GetFollowingAccountsResponse>;

  GetFollowerAccounts(request: GetFollowerAccountsRequest): Promise<GetFollowerAccountsResponse>;

  GetMission(request: GetMissionRequest): Promise<GetMissionResponse>;

  GetSupporter(request: GetSupporterRequest): Promise<GetSupporterResponse>;

  GetMatter(request: GetMatterRequest): Promise<GetMatterResponse>;

  UnfollowAccounts(request: UnfollowAccountsRequest): Promise<UnfollowAccountsResponse>;

  ChangePassword(request: ChangePasswordRequest): Promise<ChangePasswordResponse>;

  UploadImage(request: UploadImageRequest): Promise<UploadImageResponse>;

  ClearNotification(request: ClearNotificationRequest): Promise<ClearNotificationResponse>;

  GetMissionSearchConditions(request: GetMissionSearchConditionsRequest): Promise<GetMissionSearchConditionsResponse>;

  GetSupporterSearchConditions(request: GetSupporterSearchConditionsRequest): Promise<GetSupporterSearchConditionsResponse>;

  GetMatterSearchConditions(request: GetMatterSearchConditionsRequest): Promise<GetMatterSearchConditionsResponse>;

  GetFeatures(request: GetFeaturesRequest): Promise<GetFeaturesResponse>;

  GetMissionDemand(request: GetMissionDemandsRequest): Promise<GetMissionDemandsResponse>;

  GetCompanies(request: GetCompaniesRequest): Promise<GetCompaniesResponse>;

  GetSchools(request: GetSchoolsRequest): Promise<GetSchoolsResponse>;

  RegisterSchool(request: RegisterSchoolRequest): Promise<RegisterSchoolResponse>;

  GetSkills(request: GetSkillsRequest): Promise<GetSkillsResponse>;

  GetExampleSkills(request: GetExampleSkillsRequest): Promise<GetExampleSkillsResponse>;

  RegisterSkill(request: RegisterSkillRequest): Promise<RegisterSkillResponse>;

  GetExpectations(request: GetExpectationsRequest): Promise<GetExpectationsResponse>;

  GetExampleExpectations(request: GetExampleExpectationsRequest): Promise<GetExampleExpectationsResponse>;

  RegisterExpectation(request: RegisterExpectationRequest): Promise<RegisterExpectationResponse>;

  UploadFile(request: UploadFileRequest): Promise<UploadFileResponse>;

  PutMatterViewed(request: PutMatterViewedRequest): Promise<PutMatterViewedResponse>;

  PutMatterFavorite(request: PutMatterFavoriteRequest): Promise<PutMatterFavoriteResponse>;

  GetMessageTemplates(request: GetMessageTemplatesRequest): Promise<GetMessageTemplatesResponse>;

  RegisterMessageTemplate(request: RegisterMessageTemplateRequest): Promise<RegisterMessageTemplateResponse>;

  PutMessageTemplate(request: PutMessageTemplateRequest): Promise<PutMessageTemplateResponse>;

  DeleteMessageTemplates(request: DeleteMessageTemplatesRequest): Promise<DeleteMessageTemplatesResponse>;

  UseMessageTemplate(request: UseMessageTemplateRequest): Promise<UseMessageTemplateResponse>;

}

export class CommonServiceClientImpl implements CommonService {

  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
  }

  GetMissions(request: GetMissionsRequest): Promise<GetMissionsResponse> {
    const data = GetMissionsRequest.encode(request).finish();
    const promise = this.rpc.request("commonservice.CommonService", "GetMissions", data);
    return promise.then(data => GetMissionsResponse.decode(new Reader(data)));
  }

  GetMissionProducts(request: GetMissionProductsRequest): Promise<GetMissionProductsResponse> {
    const data = GetMissionProductsRequest.encode(request).finish();
    const promise = this.rpc.request("commonservice.CommonService", "GetMissionProducts", data);
    return promise.then(data => GetMissionProductsResponse.decode(new Reader(data)));
  }

  GetSupporters(request: GetSupportersRequest): Promise<GetSupportersResponse> {
    const data = GetSupportersRequest.encode(request).finish();
    const promise = this.rpc.request("commonservice.CommonService", "GetSupporters", data);
    return promise.then(data => GetSupportersResponse.decode(new Reader(data)));
  }

  GetMatters(request: GetMattersRequest): Promise<GetMattersResponse> {
    const data = GetMattersRequest.encode(request).finish();
    const promise = this.rpc.request("commonservice.CommonService", "GetMatters", data);
    return promise.then(data => GetMattersResponse.decode(new Reader(data)));
  }

  GetMattersTotalCount(request: GetMattersTotalCountRequest): Promise<GetMattersTotalCountResponse> {
    const data = GetMattersTotalCountRequest.encode(request).finish();
    const promise = this.rpc.request("commonservice.CommonService", "GetMattersTotalCount", data);
    return promise.then(data => GetMattersTotalCountResponse.decode(new Reader(data)));
  }

  GetFollowingAccounts(request: GetFollowingAccountsRequest): Promise<GetFollowingAccountsResponse> {
    const data = GetFollowingAccountsRequest.encode(request).finish();
    const promise = this.rpc.request("commonservice.CommonService", "GetFollowingAccounts", data);
    return promise.then(data => GetFollowingAccountsResponse.decode(new Reader(data)));
  }

  GetFollowerAccounts(request: GetFollowerAccountsRequest): Promise<GetFollowerAccountsResponse> {
    const data = GetFollowerAccountsRequest.encode(request).finish();
    const promise = this.rpc.request("commonservice.CommonService", "GetFollowerAccounts", data);
    return promise.then(data => GetFollowerAccountsResponse.decode(new Reader(data)));
  }

  GetMission(request: GetMissionRequest): Promise<GetMissionResponse> {
    const data = GetMissionRequest.encode(request).finish();
    const promise = this.rpc.request("commonservice.CommonService", "GetMission", data);
    return promise.then(data => GetMissionResponse.decode(new Reader(data)));
  }

  GetSupporter(request: GetSupporterRequest): Promise<GetSupporterResponse> {
    const data = GetSupporterRequest.encode(request).finish();
    const promise = this.rpc.request("commonservice.CommonService", "GetSupporter", data);
    return promise.then(data => GetSupporterResponse.decode(new Reader(data)));
  }

  GetMatter(request: GetMatterRequest): Promise<GetMatterResponse> {
    const data = GetMatterRequest.encode(request).finish();
    const promise = this.rpc.request("commonservice.CommonService", "GetMatter", data);
    return promise.then(data => GetMatterResponse.decode(new Reader(data)));
  }

  UnfollowAccounts(request: UnfollowAccountsRequest): Promise<UnfollowAccountsResponse> {
    const data = UnfollowAccountsRequest.encode(request).finish();
    const promise = this.rpc.request("commonservice.CommonService", "UnfollowAccounts", data);
    return promise.then(data => UnfollowAccountsResponse.decode(new Reader(data)));
  }

  ChangePassword(request: ChangePasswordRequest): Promise<ChangePasswordResponse> {
    const data = ChangePasswordRequest.encode(request).finish();
    const promise = this.rpc.request("commonservice.CommonService", "ChangePassword", data);
    return promise.then(data => ChangePasswordResponse.decode(new Reader(data)));
  }

  UploadImage(request: UploadImageRequest): Promise<UploadImageResponse> {
    const data = UploadImageRequest.encode(request).finish();
    const promise = this.rpc.request("commonservice.CommonService", "UploadImage", data);
    return promise.then(data => UploadImageResponse.decode(new Reader(data)));
  }

  ClearNotification(request: ClearNotificationRequest): Promise<ClearNotificationResponse> {
    const data = ClearNotificationRequest.encode(request).finish();
    const promise = this.rpc.request("commonservice.CommonService", "ClearNotification", data);
    return promise.then(data => ClearNotificationResponse.decode(new Reader(data)));
  }

  GetMissionSearchConditions(request: GetMissionSearchConditionsRequest): Promise<GetMissionSearchConditionsResponse> {
    const data = GetMissionSearchConditionsRequest.encode(request).finish();
    const promise = this.rpc.request("commonservice.CommonService", "GetMissionSearchConditions", data);
    return promise.then(data => GetMissionSearchConditionsResponse.decode(new Reader(data)));
  }

  GetSupporterSearchConditions(request: GetSupporterSearchConditionsRequest): Promise<GetSupporterSearchConditionsResponse> {
    const data = GetSupporterSearchConditionsRequest.encode(request).finish();
    const promise = this.rpc.request("commonservice.CommonService", "GetSupporterSearchConditions", data);
    return promise.then(data => GetSupporterSearchConditionsResponse.decode(new Reader(data)));
  }

  GetMatterSearchConditions(request: GetMatterSearchConditionsRequest): Promise<GetMatterSearchConditionsResponse> {
    const data = GetMatterSearchConditionsRequest.encode(request).finish();
    const promise = this.rpc.request("commonservice.CommonService", "GetMatterSearchConditions", data);
    return promise.then(data => GetMatterSearchConditionsResponse.decode(new Reader(data)));
  }

  GetFeatures(request: GetFeaturesRequest): Promise<GetFeaturesResponse> {
    const data = GetFeaturesRequest.encode(request).finish();
    const promise = this.rpc.request("commonservice.CommonService", "GetFeatures", data);
    return promise.then(data => GetFeaturesResponse.decode(new Reader(data)));
  }

  GetMissionDemand(request: GetMissionDemandsRequest): Promise<GetMissionDemandsResponse> {
    const data = GetMissionDemandsRequest.encode(request).finish();
    const promise = this.rpc.request("commonservice.CommonService", "GetMissionDemand", data);
    return promise.then(data => GetMissionDemandsResponse.decode(new Reader(data)));
  }

  GetCompanies(request: GetCompaniesRequest): Promise<GetCompaniesResponse> {
    const data = GetCompaniesRequest.encode(request).finish();
    const promise = this.rpc.request("commonservice.CommonService", "GetCompanies", data);
    return promise.then(data => GetCompaniesResponse.decode(new Reader(data)));
  }

  GetSchools(request: GetSchoolsRequest): Promise<GetSchoolsResponse> {
    const data = GetSchoolsRequest.encode(request).finish();
    const promise = this.rpc.request("commonservice.CommonService", "GetSchools", data);
    return promise.then(data => GetSchoolsResponse.decode(new Reader(data)));
  }

  RegisterSchool(request: RegisterSchoolRequest): Promise<RegisterSchoolResponse> {
    const data = RegisterSchoolRequest.encode(request).finish();
    const promise = this.rpc.request("commonservice.CommonService", "RegisterSchool", data);
    return promise.then(data => RegisterSchoolResponse.decode(new Reader(data)));
  }

  GetSkills(request: GetSkillsRequest): Promise<GetSkillsResponse> {
    const data = GetSkillsRequest.encode(request).finish();
    const promise = this.rpc.request("commonservice.CommonService", "GetSkills", data);
    return promise.then(data => GetSkillsResponse.decode(new Reader(data)));
  }

  GetExampleSkills(request: GetExampleSkillsRequest): Promise<GetExampleSkillsResponse> {
    const data = GetExampleSkillsRequest.encode(request).finish();
    const promise = this.rpc.request("commonservice.CommonService", "GetExampleSkills", data);
    return promise.then(data => GetExampleSkillsResponse.decode(new Reader(data)));
  }

  RegisterSkill(request: RegisterSkillRequest): Promise<RegisterSkillResponse> {
    const data = RegisterSkillRequest.encode(request).finish();
    const promise = this.rpc.request("commonservice.CommonService", "RegisterSkill", data);
    return promise.then(data => RegisterSkillResponse.decode(new Reader(data)));
  }

  GetExpectations(request: GetExpectationsRequest): Promise<GetExpectationsResponse> {
    const data = GetExpectationsRequest.encode(request).finish();
    const promise = this.rpc.request("commonservice.CommonService", "GetExpectations", data);
    return promise.then(data => GetExpectationsResponse.decode(new Reader(data)));
  }

  GetExampleExpectations(request: GetExampleExpectationsRequest): Promise<GetExampleExpectationsResponse> {
    const data = GetExampleExpectationsRequest.encode(request).finish();
    const promise = this.rpc.request("commonservice.CommonService", "GetExampleExpectations", data);
    return promise.then(data => GetExampleExpectationsResponse.decode(new Reader(data)));
  }

  RegisterExpectation(request: RegisterExpectationRequest): Promise<RegisterExpectationResponse> {
    const data = RegisterExpectationRequest.encode(request).finish();
    const promise = this.rpc.request("commonservice.CommonService", "RegisterExpectation", data);
    return promise.then(data => RegisterExpectationResponse.decode(new Reader(data)));
  }

  UploadFile(request: UploadFileRequest): Promise<UploadFileResponse> {
    const data = UploadFileRequest.encode(request).finish();
    const promise = this.rpc.request("commonservice.CommonService", "UploadFile", data);
    return promise.then(data => UploadFileResponse.decode(new Reader(data)));
  }

  PutMatterViewed(request: PutMatterViewedRequest): Promise<PutMatterViewedResponse> {
    const data = PutMatterViewedRequest.encode(request).finish();
    const promise = this.rpc.request("commonservice.CommonService", "PutMatterViewed", data);
    return promise.then(data => PutMatterViewedResponse.decode(new Reader(data)));
  }

  PutMatterFavorite(request: PutMatterFavoriteRequest): Promise<PutMatterFavoriteResponse> {
    const data = PutMatterFavoriteRequest.encode(request).finish();
    const promise = this.rpc.request("commonservice.CommonService", "PutMatterFavorite", data);
    return promise.then(data => PutMatterFavoriteResponse.decode(new Reader(data)));
  }

  GetMessageTemplates(request: GetMessageTemplatesRequest): Promise<GetMessageTemplatesResponse> {
    const data = GetMessageTemplatesRequest.encode(request).finish();
    const promise = this.rpc.request("commonservice.CommonService", "GetMessageTemplates", data);
    return promise.then(data => GetMessageTemplatesResponse.decode(new Reader(data)));
  }

  RegisterMessageTemplate(request: RegisterMessageTemplateRequest): Promise<RegisterMessageTemplateResponse> {
    const data = RegisterMessageTemplateRequest.encode(request).finish();
    const promise = this.rpc.request("commonservice.CommonService", "RegisterMessageTemplate", data);
    return promise.then(data => RegisterMessageTemplateResponse.decode(new Reader(data)));
  }

  PutMessageTemplate(request: PutMessageTemplateRequest): Promise<PutMessageTemplateResponse> {
    const data = PutMessageTemplateRequest.encode(request).finish();
    const promise = this.rpc.request("commonservice.CommonService", "PutMessageTemplate", data);
    return promise.then(data => PutMessageTemplateResponse.decode(new Reader(data)));
  }

  DeleteMessageTemplates(request: DeleteMessageTemplatesRequest): Promise<DeleteMessageTemplatesResponse> {
    const data = DeleteMessageTemplatesRequest.encode(request).finish();
    const promise = this.rpc.request("commonservice.CommonService", "DeleteMessageTemplates", data);
    return promise.then(data => DeleteMessageTemplatesResponse.decode(new Reader(data)));
  }

  UseMessageTemplate(request: UseMessageTemplateRequest): Promise<UseMessageTemplateResponse> {
    const data = UseMessageTemplateRequest.encode(request).finish();
    const promise = this.rpc.request("commonservice.CommonService", "UseMessageTemplate", data);
    return promise.then(data => UseMessageTemplateResponse.decode(new Reader(data)));
  }

}

interface Rpc {

  request(service: string, method: string, data: Uint8Array): Promise<Uint8Array>;

}

function longToNumber(long: Long) {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new global.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

export const GetMissionsRequest = {
  encode(message: GetMissionsRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).uint32(message.limit);
    writer.uint32(34).string(message.q);
    writer.uint32(42).fork();
    for (const v of message.demandIds) {
      writer.uint32(v);
    }
    writer.ldelim();
    writer.uint32(50).fork();
    for (const v of message.financingSeriesIds) {
      writer.uint32(v);
    }
    writer.ldelim();
    writer.uint32(58).fork();
    for (const v of message.corporateAttributeIds) {
      writer.uint32(v);
    }
    writer.ldelim();
    writer.uint32(66).fork();
    for (const v of message.japanPrefectureIds) {
      writer.uint32(v);
    }
    writer.ldelim();
    writer.uint32(74).fork();
    for (const v of message.categoryIds) {
      writer.uint32(v);
    }
    writer.ldelim();
    if (message.countryId !== undefined && message.countryId !== undefined) {
      StringValue.encode({ value: message.countryId! }, writer.uint32(82).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): GetMissionsRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetMissionsRequest) as GetMissionsRequest;
    message.demandIds = [];
    message.financingSeriesIds = [];
    message.corporateAttributeIds = [];
    message.japanPrefectureIds = [];
    message.categoryIds = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.limit = reader.uint32();
          break;
        case 4:
          message.q = reader.string();
          break;
        case 5:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.demandIds.push(reader.uint32());
            }
          } else {
            message.demandIds.push(reader.uint32());
          }
          break;
        case 6:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.financingSeriesIds.push(reader.uint32());
            }
          } else {
            message.financingSeriesIds.push(reader.uint32());
          }
          break;
        case 7:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.corporateAttributeIds.push(reader.uint32());
            }
          } else {
            message.corporateAttributeIds.push(reader.uint32());
          }
          break;
        case 8:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.japanPrefectureIds.push(reader.uint32());
            }
          } else {
            message.japanPrefectureIds.push(reader.uint32());
          }
          break;
        case 9:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.categoryIds.push(reader.uint32());
            }
          } else {
            message.categoryIds.push(reader.uint32());
          }
          break;
        case 10:
          message.countryId = StringValue.decode(reader, reader.uint32()).value;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetMissionsRequest {
    const message = Object.create(baseGetMissionsRequest) as GetMissionsRequest;
    message.demandIds = [];
    message.financingSeriesIds = [];
    message.corporateAttributeIds = [];
    message.japanPrefectureIds = [];
    message.categoryIds = [];
    if (object.limit) {
      message.limit = Number(object.limit);
    }
    if (object.q) {
      message.q = String(object.q);
    }
    if (object.demandIds) {
      for (const e of object.demandIds) {
        message.demandIds.push(Number(e));
      }
    }
    if (object.financingSeriesIds) {
      for (const e of object.financingSeriesIds) {
        message.financingSeriesIds.push(Number(e));
      }
    }
    if (object.corporateAttributeIds) {
      for (const e of object.corporateAttributeIds) {
        message.corporateAttributeIds.push(Number(e));
      }
    }
    if (object.japanPrefectureIds) {
      for (const e of object.japanPrefectureIds) {
        message.japanPrefectureIds.push(Number(e));
      }
    }
    if (object.categoryIds) {
      for (const e of object.categoryIds) {
        message.categoryIds.push(Number(e));
      }
    }
    if (object.countryId) {
      message.countryId = String(object.countryId);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetMissionsRequest>): GetMissionsRequest {
    const message = Object.create(baseGetMissionsRequest) as GetMissionsRequest;
    message.demandIds = [];
    message.financingSeriesIds = [];
    message.corporateAttributeIds = [];
    message.japanPrefectureIds = [];
    message.categoryIds = [];
    if (object.limit) {
      message.limit = object.limit;
    }
    if (object.q) {
      message.q = object.q;
    }
    if (object.demandIds) {
      for (const e of object.demandIds) {
        message.demandIds.push(e);
      }
    }
    if (object.financingSeriesIds) {
      for (const e of object.financingSeriesIds) {
        message.financingSeriesIds.push(e);
      }
    }
    if (object.corporateAttributeIds) {
      for (const e of object.corporateAttributeIds) {
        message.corporateAttributeIds.push(e);
      }
    }
    if (object.japanPrefectureIds) {
      for (const e of object.japanPrefectureIds) {
        message.japanPrefectureIds.push(e);
      }
    }
    if (object.categoryIds) {
      for (const e of object.categoryIds) {
        message.categoryIds.push(e);
      }
    }
    if (object.countryId) {
      message.countryId = object.countryId;
    }
    return message;
  },
  toJSON(message: GetMissionsRequest): unknown {
    const obj: any = {};
    obj.limit = message.limit || 0;
    obj.q = message.q || "";
    if (message.demandIds) {
      obj.demandIds = message.demandIds.map(e => e || 0);
    } else {
      obj.demandIds = [];
    }
    if (message.financingSeriesIds) {
      obj.financingSeriesIds = message.financingSeriesIds.map(e => e || 0);
    } else {
      obj.financingSeriesIds = [];
    }
    if (message.corporateAttributeIds) {
      obj.corporateAttributeIds = message.corporateAttributeIds.map(e => e || 0);
    } else {
      obj.corporateAttributeIds = [];
    }
    if (message.japanPrefectureIds) {
      obj.japanPrefectureIds = message.japanPrefectureIds.map(e => e || 0);
    } else {
      obj.japanPrefectureIds = [];
    }
    if (message.categoryIds) {
      obj.categoryIds = message.categoryIds.map(e => e || 0);
    } else {
      obj.categoryIds = [];
    }
    obj.countryId = message.countryId || undefined;
    return obj;
  },
};

export const Paging = {
  encode(message: Paging, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.nextQ);
    return writer;
  },
  decode(reader: Reader, length?: number): Paging {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(basePaging) as Paging;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.nextQ = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): Paging {
    const message = Object.create(basePaging) as Paging;
    if (object.nextQ) {
      message.nextQ = String(object.nextQ);
    }
    return message;
  },
  fromPartial(object: DeepPartial<Paging>): Paging {
    const message = Object.create(basePaging) as Paging;
    if (object.nextQ) {
      message.nextQ = object.nextQ;
    }
    return message;
  },
  toJSON(message: Paging): unknown {
    const obj: any = {};
    obj.nextQ = message.nextQ || "";
    return obj;
  },
};

export const GetMissionsResponse = {
  encode(message: GetMissionsResponse, writer: Writer = Writer.create()): Writer {
    for (const v of message.missions) {
      Mission.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.paging !== undefined && message.paging !== undefined) {
      Paging.encode(message.paging, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): GetMissionsResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetMissionsResponse) as GetMissionsResponse;
    message.missions = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.missions.push(Mission.decode(reader, reader.uint32()));
          break;
        case 2:
          message.paging = Paging.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetMissionsResponse {
    const message = Object.create(baseGetMissionsResponse) as GetMissionsResponse;
    message.missions = [];
    if (object.missions) {
      for (const e of object.missions) {
        message.missions.push(Mission.fromJSON(e));
      }
    }
    if (object.paging) {
      message.paging = Paging.fromJSON(object.paging);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetMissionsResponse>): GetMissionsResponse {
    const message = Object.create(baseGetMissionsResponse) as GetMissionsResponse;
    message.missions = [];
    if (object.missions) {
      for (const e of object.missions) {
        message.missions.push(Mission.fromPartial(e));
      }
    }
    if (object.paging) {
      message.paging = Paging.fromPartial(object.paging);
    }
    return message;
  },
  toJSON(message: GetMissionsResponse): unknown {
    const obj: any = {};
    if (message.missions) {
      obj.missions = message.missions.map(e => e ? Mission.toJSON(e) : undefined);
    } else {
      obj.missions = [];
    }
    obj.paging = message.paging ? Paging.toJSON(message.paging) : undefined;
    return obj;
  },
};

export const GetMissionProductsRequest = {
  encode(message: GetMissionProductsRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).uint32(message.limit);
    writer.uint32(18).string(message.q);
    writer.uint32(24).bool(message.isForBusiness);
    writer.uint32(32).bool(message.isForCustomer);
    writer.uint32(40).bool(message.hasMemberBenefit);
    return writer;
  },
  decode(reader: Reader, length?: number): GetMissionProductsRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetMissionProductsRequest) as GetMissionProductsRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.limit = reader.uint32();
          break;
        case 2:
          message.q = reader.string();
          break;
        case 3:
          message.isForBusiness = reader.bool();
          break;
        case 4:
          message.isForCustomer = reader.bool();
          break;
        case 5:
          message.hasMemberBenefit = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetMissionProductsRequest {
    const message = Object.create(baseGetMissionProductsRequest) as GetMissionProductsRequest;
    if (object.limit) {
      message.limit = Number(object.limit);
    }
    if (object.q) {
      message.q = String(object.q);
    }
    if (object.isForBusiness) {
      message.isForBusiness = Boolean(object.isForBusiness);
    }
    if (object.isForCustomer) {
      message.isForCustomer = Boolean(object.isForCustomer);
    }
    if (object.hasMemberBenefit) {
      message.hasMemberBenefit = Boolean(object.hasMemberBenefit);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetMissionProductsRequest>): GetMissionProductsRequest {
    const message = Object.create(baseGetMissionProductsRequest) as GetMissionProductsRequest;
    if (object.limit) {
      message.limit = object.limit;
    }
    if (object.q) {
      message.q = object.q;
    }
    if (object.isForBusiness) {
      message.isForBusiness = object.isForBusiness;
    }
    if (object.isForCustomer) {
      message.isForCustomer = object.isForCustomer;
    }
    if (object.hasMemberBenefit) {
      message.hasMemberBenefit = object.hasMemberBenefit;
    }
    return message;
  },
  toJSON(message: GetMissionProductsRequest): unknown {
    const obj: any = {};
    obj.limit = message.limit || 0;
    obj.q = message.q || "";
    obj.isForBusiness = message.isForBusiness || false;
    obj.isForCustomer = message.isForCustomer || false;
    obj.hasMemberBenefit = message.hasMemberBenefit || false;
    return obj;
  },
};

export const GetMissionProductsResponse = {
  encode(message: GetMissionProductsResponse, writer: Writer = Writer.create()): Writer {
    for (const v of message.products) {
      GetMissionProductsResponse_MissionProductSummary.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.paging !== undefined && message.paging !== undefined) {
      Paging.encode(message.paging, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): GetMissionProductsResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetMissionProductsResponse) as GetMissionProductsResponse;
    message.products = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.products.push(GetMissionProductsResponse_MissionProductSummary.decode(reader, reader.uint32()));
          break;
        case 2:
          message.paging = Paging.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetMissionProductsResponse {
    const message = Object.create(baseGetMissionProductsResponse) as GetMissionProductsResponse;
    message.products = [];
    if (object.products) {
      for (const e of object.products) {
        message.products.push(GetMissionProductsResponse_MissionProductSummary.fromJSON(e));
      }
    }
    if (object.paging) {
      message.paging = Paging.fromJSON(object.paging);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetMissionProductsResponse>): GetMissionProductsResponse {
    const message = Object.create(baseGetMissionProductsResponse) as GetMissionProductsResponse;
    message.products = [];
    if (object.products) {
      for (const e of object.products) {
        message.products.push(GetMissionProductsResponse_MissionProductSummary.fromPartial(e));
      }
    }
    if (object.paging) {
      message.paging = Paging.fromPartial(object.paging);
    }
    return message;
  },
  toJSON(message: GetMissionProductsResponse): unknown {
    const obj: any = {};
    if (message.products) {
      obj.products = message.products.map(e => e ? GetMissionProductsResponse_MissionProductSummary.toJSON(e) : undefined);
    } else {
      obj.products = [];
    }
    obj.paging = message.paging ? Paging.toJSON(message.paging) : undefined;
    return obj;
  },
};

export const GetMissionProductsResponse_MissionProductSummary = {
  encode(message: GetMissionProductsResponse_MissionProductSummary, writer: Writer = Writer.create()): Writer {
    if (message.product !== undefined && message.product !== undefined) {
      MissionProduct.encode(message.product, writer.uint32(10).fork()).ldelim();
    }
    writer.uint32(18).string(message.missionId);
    if (message.iconImage !== undefined && message.iconImage !== undefined) {
      Images.encode(message.iconImage, writer.uint32(34).fork()).ldelim();
    }
    writer.uint32(42).string(message.companyName);
    return writer;
  },
  decode(reader: Reader, length?: number): GetMissionProductsResponse_MissionProductSummary {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetMissionProductsResponse_MissionProductSummary) as GetMissionProductsResponse_MissionProductSummary;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.product = MissionProduct.decode(reader, reader.uint32());
          break;
        case 2:
          message.missionId = reader.string();
          break;
        case 4:
          message.iconImage = Images.decode(reader, reader.uint32());
          break;
        case 5:
          message.companyName = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetMissionProductsResponse_MissionProductSummary {
    const message = Object.create(baseGetMissionProductsResponse_MissionProductSummary) as GetMissionProductsResponse_MissionProductSummary;
    if (object.product) {
      message.product = MissionProduct.fromJSON(object.product);
    }
    if (object.missionId) {
      message.missionId = String(object.missionId);
    }
    if (object.iconImage) {
      message.iconImage = Images.fromJSON(object.iconImage);
    }
    if (object.companyName) {
      message.companyName = String(object.companyName);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetMissionProductsResponse_MissionProductSummary>): GetMissionProductsResponse_MissionProductSummary {
    const message = Object.create(baseGetMissionProductsResponse_MissionProductSummary) as GetMissionProductsResponse_MissionProductSummary;
    if (object.product) {
      message.product = MissionProduct.fromPartial(object.product);
    }
    if (object.missionId) {
      message.missionId = object.missionId;
    }
    if (object.iconImage) {
      message.iconImage = Images.fromPartial(object.iconImage);
    }
    if (object.companyName) {
      message.companyName = object.companyName;
    }
    return message;
  },
  toJSON(message: GetMissionProductsResponse_MissionProductSummary): unknown {
    const obj: any = {};
    obj.product = message.product ? MissionProduct.toJSON(message.product) : undefined;
    obj.missionId = message.missionId || "";
    obj.iconImage = message.iconImage ? Images.toJSON(message.iconImage) : undefined;
    obj.companyName = message.companyName || "";
    return obj;
  },
};

export const GetSupportersRequest = {
  encode(message: GetSupportersRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).uint32(message.limit);
    writer.uint32(18).string(message.q);
    if (message.countryId !== undefined && message.countryId !== undefined) {
      StringValue.encode({ value: message.countryId! }, writer.uint32(26).fork()).ldelim();
    }
    writer.uint32(34).fork();
    for (const v of message.japanPrefectureIds) {
      writer.uint32(v);
    }
    writer.ldelim();
    writer.uint32(42).fork();
    for (const v of message.supportAreaIds) {
      writer.uint32(v);
    }
    writer.ldelim();
    return writer;
  },
  decode(reader: Reader, length?: number): GetSupportersRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetSupportersRequest) as GetSupportersRequest;
    message.japanPrefectureIds = [];
    message.supportAreaIds = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.limit = reader.uint32();
          break;
        case 2:
          message.q = reader.string();
          break;
        case 3:
          message.countryId = StringValue.decode(reader, reader.uint32()).value;
          break;
        case 4:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.japanPrefectureIds.push(reader.uint32());
            }
          } else {
            message.japanPrefectureIds.push(reader.uint32());
          }
          break;
        case 5:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.supportAreaIds.push(reader.uint32());
            }
          } else {
            message.supportAreaIds.push(reader.uint32());
          }
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetSupportersRequest {
    const message = Object.create(baseGetSupportersRequest) as GetSupportersRequest;
    message.japanPrefectureIds = [];
    message.supportAreaIds = [];
    if (object.limit) {
      message.limit = Number(object.limit);
    }
    if (object.q) {
      message.q = String(object.q);
    }
    if (object.countryId) {
      message.countryId = String(object.countryId);
    }
    if (object.japanPrefectureIds) {
      for (const e of object.japanPrefectureIds) {
        message.japanPrefectureIds.push(Number(e));
      }
    }
    if (object.supportAreaIds) {
      for (const e of object.supportAreaIds) {
        message.supportAreaIds.push(Number(e));
      }
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetSupportersRequest>): GetSupportersRequest {
    const message = Object.create(baseGetSupportersRequest) as GetSupportersRequest;
    message.japanPrefectureIds = [];
    message.supportAreaIds = [];
    if (object.limit) {
      message.limit = object.limit;
    }
    if (object.q) {
      message.q = object.q;
    }
    if (object.countryId) {
      message.countryId = object.countryId;
    }
    if (object.japanPrefectureIds) {
      for (const e of object.japanPrefectureIds) {
        message.japanPrefectureIds.push(e);
      }
    }
    if (object.supportAreaIds) {
      for (const e of object.supportAreaIds) {
        message.supportAreaIds.push(e);
      }
    }
    return message;
  },
  toJSON(message: GetSupportersRequest): unknown {
    const obj: any = {};
    obj.limit = message.limit || 0;
    obj.q = message.q || "";
    obj.countryId = message.countryId || undefined;
    if (message.japanPrefectureIds) {
      obj.japanPrefectureIds = message.japanPrefectureIds.map(e => e || 0);
    } else {
      obj.japanPrefectureIds = [];
    }
    if (message.supportAreaIds) {
      obj.supportAreaIds = message.supportAreaIds.map(e => e || 0);
    } else {
      obj.supportAreaIds = [];
    }
    return obj;
  },
};

export const GetSupportersResponse = {
  encode(message: GetSupportersResponse, writer: Writer = Writer.create()): Writer {
    for (const v of message.supporters) {
      Supporter.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.paging !== undefined && message.paging !== undefined) {
      Paging.encode(message.paging, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): GetSupportersResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetSupportersResponse) as GetSupportersResponse;
    message.supporters = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.supporters.push(Supporter.decode(reader, reader.uint32()));
          break;
        case 2:
          message.paging = Paging.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetSupportersResponse {
    const message = Object.create(baseGetSupportersResponse) as GetSupportersResponse;
    message.supporters = [];
    if (object.supporters) {
      for (const e of object.supporters) {
        message.supporters.push(Supporter.fromJSON(e));
      }
    }
    if (object.paging) {
      message.paging = Paging.fromJSON(object.paging);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetSupportersResponse>): GetSupportersResponse {
    const message = Object.create(baseGetSupportersResponse) as GetSupportersResponse;
    message.supporters = [];
    if (object.supporters) {
      for (const e of object.supporters) {
        message.supporters.push(Supporter.fromPartial(e));
      }
    }
    if (object.paging) {
      message.paging = Paging.fromPartial(object.paging);
    }
    return message;
  },
  toJSON(message: GetSupportersResponse): unknown {
    const obj: any = {};
    if (message.supporters) {
      obj.supporters = message.supporters.map(e => e ? Supporter.toJSON(e) : undefined);
    } else {
      obj.supporters = [];
    }
    obj.paging = message.paging ? Paging.toJSON(message.paging) : undefined;
    return obj;
  },
};

export const GetMattersRequest = {
  encode(message: GetMattersRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.q);
    writer.uint32(16).int32(message.limit);
    if (message.careerMattersFilter !== undefined && message.careerMattersFilter !== undefined) {
      CareerMattersFilter.encode(message.careerMattersFilter, writer.uint32(34).fork()).ldelim();
    }
    if (message.newGraduateMattersFilter !== undefined && message.newGraduateMattersFilter !== undefined) {
      NewGraduateMattersFilter.encode(message.newGraduateMattersFilter, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): GetMattersRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetMattersRequest) as GetMattersRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.q = reader.string();
          break;
        case 2:
          message.limit = reader.int32();
          break;
        case 4:
          message.careerMattersFilter = CareerMattersFilter.decode(reader, reader.uint32());
          break;
        case 5:
          message.newGraduateMattersFilter = NewGraduateMattersFilter.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetMattersRequest {
    const message = Object.create(baseGetMattersRequest) as GetMattersRequest;
    if (object.q) {
      message.q = String(object.q);
    }
    if (object.limit) {
      message.limit = Number(object.limit);
    }
    if (object.careerMattersFilter) {
      message.careerMattersFilter = CareerMattersFilter.fromJSON(object.careerMattersFilter);
    }
    if (object.newGraduateMattersFilter) {
      message.newGraduateMattersFilter = NewGraduateMattersFilter.fromJSON(object.newGraduateMattersFilter);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetMattersRequest>): GetMattersRequest {
    const message = Object.create(baseGetMattersRequest) as GetMattersRequest;
    if (object.q) {
      message.q = object.q;
    }
    if (object.limit) {
      message.limit = object.limit;
    }
    if (object.careerMattersFilter) {
      message.careerMattersFilter = CareerMattersFilter.fromPartial(object.careerMattersFilter);
    }
    if (object.newGraduateMattersFilter) {
      message.newGraduateMattersFilter = NewGraduateMattersFilter.fromPartial(object.newGraduateMattersFilter);
    }
    return message;
  },
  toJSON(message: GetMattersRequest): unknown {
    const obj: any = {};
    obj.q = message.q || "";
    obj.limit = message.limit || 0;
    obj.careerMattersFilter = message.careerMattersFilter ? CareerMattersFilter.toJSON(message.careerMattersFilter) : undefined;
    obj.newGraduateMattersFilter = message.newGraduateMattersFilter ? NewGraduateMattersFilter.toJSON(message.newGraduateMattersFilter) : undefined;
    return obj;
  },
};

export const GetMattersResponse = {
  encode(message: GetMattersResponse, writer: Writer = Writer.create()): Writer {
    for (const v of message.matters) {
      Matter.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.paging !== undefined && message.paging !== undefined) {
      Paging.encode(message.paging, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): GetMattersResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetMattersResponse) as GetMattersResponse;
    message.matters = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.matters.push(Matter.decode(reader, reader.uint32()));
          break;
        case 2:
          message.paging = Paging.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetMattersResponse {
    const message = Object.create(baseGetMattersResponse) as GetMattersResponse;
    message.matters = [];
    if (object.matters) {
      for (const e of object.matters) {
        message.matters.push(Matter.fromJSON(e));
      }
    }
    if (object.paging) {
      message.paging = Paging.fromJSON(object.paging);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetMattersResponse>): GetMattersResponse {
    const message = Object.create(baseGetMattersResponse) as GetMattersResponse;
    message.matters = [];
    if (object.matters) {
      for (const e of object.matters) {
        message.matters.push(Matter.fromPartial(e));
      }
    }
    if (object.paging) {
      message.paging = Paging.fromPartial(object.paging);
    }
    return message;
  },
  toJSON(message: GetMattersResponse): unknown {
    const obj: any = {};
    if (message.matters) {
      obj.matters = message.matters.map(e => e ? Matter.toJSON(e) : undefined);
    } else {
      obj.matters = [];
    }
    obj.paging = message.paging ? Paging.toJSON(message.paging) : undefined;
    return obj;
  },
};

export const GetMattersTotalCountRequest = {
  encode(message: GetMattersTotalCountRequest, writer: Writer = Writer.create()): Writer {
    if (message.careerMattersFilter !== undefined && message.careerMattersFilter !== undefined) {
      CareerMattersFilter.encode(message.careerMattersFilter, writer.uint32(10).fork()).ldelim();
    }
    if (message.newGraduateMattersFilter !== undefined && message.newGraduateMattersFilter !== undefined) {
      NewGraduateMattersFilter.encode(message.newGraduateMattersFilter, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): GetMattersTotalCountRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetMattersTotalCountRequest) as GetMattersTotalCountRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.careerMattersFilter = CareerMattersFilter.decode(reader, reader.uint32());
          break;
        case 2:
          message.newGraduateMattersFilter = NewGraduateMattersFilter.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetMattersTotalCountRequest {
    const message = Object.create(baseGetMattersTotalCountRequest) as GetMattersTotalCountRequest;
    if (object.careerMattersFilter) {
      message.careerMattersFilter = CareerMattersFilter.fromJSON(object.careerMattersFilter);
    }
    if (object.newGraduateMattersFilter) {
      message.newGraduateMattersFilter = NewGraduateMattersFilter.fromJSON(object.newGraduateMattersFilter);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetMattersTotalCountRequest>): GetMattersTotalCountRequest {
    const message = Object.create(baseGetMattersTotalCountRequest) as GetMattersTotalCountRequest;
    if (object.careerMattersFilter) {
      message.careerMattersFilter = CareerMattersFilter.fromPartial(object.careerMattersFilter);
    }
    if (object.newGraduateMattersFilter) {
      message.newGraduateMattersFilter = NewGraduateMattersFilter.fromPartial(object.newGraduateMattersFilter);
    }
    return message;
  },
  toJSON(message: GetMattersTotalCountRequest): unknown {
    const obj: any = {};
    obj.careerMattersFilter = message.careerMattersFilter ? CareerMattersFilter.toJSON(message.careerMattersFilter) : undefined;
    obj.newGraduateMattersFilter = message.newGraduateMattersFilter ? NewGraduateMattersFilter.toJSON(message.newGraduateMattersFilter) : undefined;
    return obj;
  },
};

export const GetMattersTotalCountResponse = {
  encode(message: GetMattersTotalCountResponse, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).uint32(message.count);
    return writer;
  },
  decode(reader: Reader, length?: number): GetMattersTotalCountResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetMattersTotalCountResponse) as GetMattersTotalCountResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.count = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetMattersTotalCountResponse {
    const message = Object.create(baseGetMattersTotalCountResponse) as GetMattersTotalCountResponse;
    if (object.count) {
      message.count = Number(object.count);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetMattersTotalCountResponse>): GetMattersTotalCountResponse {
    const message = Object.create(baseGetMattersTotalCountResponse) as GetMattersTotalCountResponse;
    if (object.count) {
      message.count = object.count;
    }
    return message;
  },
  toJSON(message: GetMattersTotalCountResponse): unknown {
    const obj: any = {};
    obj.count = message.count || 0;
    return obj;
  },
};

export const GetMissionRequest = {
  encode(message: GetMissionRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.missionId);
    if (message.language !== undefined && message.language !== undefined) {
      StringValue.encode({ value: message.language! }, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): GetMissionRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetMissionRequest) as GetMissionRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.missionId = reader.string();
          break;
        case 2:
          message.language = StringValue.decode(reader, reader.uint32()).value;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetMissionRequest {
    const message = Object.create(baseGetMissionRequest) as GetMissionRequest;
    if (object.missionId) {
      message.missionId = String(object.missionId);
    }
    if (object.language) {
      message.language = String(object.language);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetMissionRequest>): GetMissionRequest {
    const message = Object.create(baseGetMissionRequest) as GetMissionRequest;
    if (object.missionId) {
      message.missionId = object.missionId;
    }
    if (object.language) {
      message.language = object.language;
    }
    return message;
  },
  toJSON(message: GetMissionRequest): unknown {
    const obj: any = {};
    obj.missionId = message.missionId || "";
    obj.language = message.language || undefined;
    return obj;
  },
};

export const GetMissionResponse = {
  encode(message: GetMissionResponse, writer: Writer = Writer.create()): Writer {
    if (message.mission !== undefined && message.mission !== undefined) {
      Mission.encode(message.mission, writer.uint32(10).fork()).ldelim();
    }
    writer.uint32(16).bool(message.followed);
    writer.uint32(24).bool(message.followable);
    return writer;
  },
  decode(reader: Reader, length?: number): GetMissionResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetMissionResponse) as GetMissionResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.mission = Mission.decode(reader, reader.uint32());
          break;
        case 2:
          message.followed = reader.bool();
          break;
        case 3:
          message.followable = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetMissionResponse {
    const message = Object.create(baseGetMissionResponse) as GetMissionResponse;
    if (object.mission) {
      message.mission = Mission.fromJSON(object.mission);
    }
    if (object.followed) {
      message.followed = Boolean(object.followed);
    }
    if (object.followable) {
      message.followable = Boolean(object.followable);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetMissionResponse>): GetMissionResponse {
    const message = Object.create(baseGetMissionResponse) as GetMissionResponse;
    if (object.mission) {
      message.mission = Mission.fromPartial(object.mission);
    }
    if (object.followed) {
      message.followed = object.followed;
    }
    if (object.followable) {
      message.followable = object.followable;
    }
    return message;
  },
  toJSON(message: GetMissionResponse): unknown {
    const obj: any = {};
    obj.mission = message.mission ? Mission.toJSON(message.mission) : undefined;
    obj.followed = message.followed || false;
    obj.followable = message.followable || false;
    return obj;
  },
};

export const GetSupporterRequest = {
  encode(message: GetSupporterRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.supporterId);
    if (message.language !== undefined && message.language !== undefined) {
      StringValue.encode({ value: message.language! }, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): GetSupporterRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetSupporterRequest) as GetSupporterRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.supporterId = reader.string();
          break;
        case 2:
          message.language = StringValue.decode(reader, reader.uint32()).value;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetSupporterRequest {
    const message = Object.create(baseGetSupporterRequest) as GetSupporterRequest;
    if (object.supporterId) {
      message.supporterId = String(object.supporterId);
    }
    if (object.language) {
      message.language = String(object.language);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetSupporterRequest>): GetSupporterRequest {
    const message = Object.create(baseGetSupporterRequest) as GetSupporterRequest;
    if (object.supporterId) {
      message.supporterId = object.supporterId;
    }
    if (object.language) {
      message.language = object.language;
    }
    return message;
  },
  toJSON(message: GetSupporterRequest): unknown {
    const obj: any = {};
    obj.supporterId = message.supporterId || "";
    obj.language = message.language || undefined;
    return obj;
  },
};

export const GetSupporterResponse = {
  encode(message: GetSupporterResponse, writer: Writer = Writer.create()): Writer {
    if (message.supporter !== undefined && message.supporter !== undefined) {
      Supporter.encode(message.supporter, writer.uint32(10).fork()).ldelim();
    }
    writer.uint32(16).bool(message.followed);
    writer.uint32(24).bool(message.followable);
    return writer;
  },
  decode(reader: Reader, length?: number): GetSupporterResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetSupporterResponse) as GetSupporterResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.supporter = Supporter.decode(reader, reader.uint32());
          break;
        case 2:
          message.followed = reader.bool();
          break;
        case 3:
          message.followable = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetSupporterResponse {
    const message = Object.create(baseGetSupporterResponse) as GetSupporterResponse;
    if (object.supporter) {
      message.supporter = Supporter.fromJSON(object.supporter);
    }
    if (object.followed) {
      message.followed = Boolean(object.followed);
    }
    if (object.followable) {
      message.followable = Boolean(object.followable);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetSupporterResponse>): GetSupporterResponse {
    const message = Object.create(baseGetSupporterResponse) as GetSupporterResponse;
    if (object.supporter) {
      message.supporter = Supporter.fromPartial(object.supporter);
    }
    if (object.followed) {
      message.followed = object.followed;
    }
    if (object.followable) {
      message.followable = object.followable;
    }
    return message;
  },
  toJSON(message: GetSupporterResponse): unknown {
    const obj: any = {};
    obj.supporter = message.supporter ? Supporter.toJSON(message.supporter) : undefined;
    obj.followed = message.followed || false;
    obj.followable = message.followable || false;
    return obj;
  },
};

export const GetMatterRequest = {
  encode(message: GetMatterRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.matterId);
    return writer;
  },
  decode(reader: Reader, length?: number): GetMatterRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetMatterRequest) as GetMatterRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.matterId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetMatterRequest {
    const message = Object.create(baseGetMatterRequest) as GetMatterRequest;
    if (object.matterId) {
      message.matterId = String(object.matterId);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetMatterRequest>): GetMatterRequest {
    const message = Object.create(baseGetMatterRequest) as GetMatterRequest;
    if (object.matterId) {
      message.matterId = object.matterId;
    }
    return message;
  },
  toJSON(message: GetMatterRequest): unknown {
    const obj: any = {};
    obj.matterId = message.matterId || "";
    return obj;
  },
};

export const GetMatterResponse = {
  encode(message: GetMatterResponse, writer: Writer = Writer.create()): Writer {
    if (message.matter !== undefined && message.matter !== undefined) {
      Matter.encode(message.matter, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): GetMatterResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetMatterResponse) as GetMatterResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.matter = Matter.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetMatterResponse {
    const message = Object.create(baseGetMatterResponse) as GetMatterResponse;
    if (object.matter) {
      message.matter = Matter.fromJSON(object.matter);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetMatterResponse>): GetMatterResponse {
    const message = Object.create(baseGetMatterResponse) as GetMatterResponse;
    if (object.matter) {
      message.matter = Matter.fromPartial(object.matter);
    }
    return message;
  },
  toJSON(message: GetMatterResponse): unknown {
    const obj: any = {};
    obj.matter = message.matter ? Matter.toJSON(message.matter) : undefined;
    return obj;
  },
};

export const GetFollowingAccountsRequest = {
  encode(message: GetFollowingAccountsRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.q);
    return writer;
  },
  decode(reader: Reader, length?: number): GetFollowingAccountsRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetFollowingAccountsRequest) as GetFollowingAccountsRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.q = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetFollowingAccountsRequest {
    const message = Object.create(baseGetFollowingAccountsRequest) as GetFollowingAccountsRequest;
    if (object.q) {
      message.q = String(object.q);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetFollowingAccountsRequest>): GetFollowingAccountsRequest {
    const message = Object.create(baseGetFollowingAccountsRequest) as GetFollowingAccountsRequest;
    if (object.q) {
      message.q = object.q;
    }
    return message;
  },
  toJSON(message: GetFollowingAccountsRequest): unknown {
    const obj: any = {};
    obj.q = message.q || "";
    return obj;
  },
};

export const GetFollowingAccountsResponse = {
  encode(message: GetFollowingAccountsResponse, writer: Writer = Writer.create()): Writer {
    for (const v of message.accountFollowees) {
      AccountFollowee.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    writer.uint32(18).string(message.nextQuery);
    return writer;
  },
  decode(reader: Reader, length?: number): GetFollowingAccountsResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetFollowingAccountsResponse) as GetFollowingAccountsResponse;
    message.accountFollowees = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.accountFollowees.push(AccountFollowee.decode(reader, reader.uint32()));
          break;
        case 2:
          message.nextQuery = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetFollowingAccountsResponse {
    const message = Object.create(baseGetFollowingAccountsResponse) as GetFollowingAccountsResponse;
    message.accountFollowees = [];
    if (object.accountFollowees) {
      for (const e of object.accountFollowees) {
        message.accountFollowees.push(AccountFollowee.fromJSON(e));
      }
    }
    if (object.nextQuery) {
      message.nextQuery = String(object.nextQuery);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetFollowingAccountsResponse>): GetFollowingAccountsResponse {
    const message = Object.create(baseGetFollowingAccountsResponse) as GetFollowingAccountsResponse;
    message.accountFollowees = [];
    if (object.accountFollowees) {
      for (const e of object.accountFollowees) {
        message.accountFollowees.push(AccountFollowee.fromPartial(e));
      }
    }
    if (object.nextQuery) {
      message.nextQuery = object.nextQuery;
    }
    return message;
  },
  toJSON(message: GetFollowingAccountsResponse): unknown {
    const obj: any = {};
    if (message.accountFollowees) {
      obj.accountFollowees = message.accountFollowees.map(e => e ? AccountFollowee.toJSON(e) : undefined);
    } else {
      obj.accountFollowees = [];
    }
    obj.nextQuery = message.nextQuery || "";
    return obj;
  },
};

export const GetFollowerAccountsRequest = {
  encode(message: GetFollowerAccountsRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).uint32(message.limit);
    if (message.haveContacted !== undefined && message.haveContacted !== undefined) {
      BoolValue.encode({ value: message.haveContacted! }, writer.uint32(18).fork()).ldelim();
    }
    if (message.mutualFollower !== undefined && message.mutualFollower !== undefined) {
      BoolValue.encode({ value: message.mutualFollower! }, writer.uint32(26).fork()).ldelim();
    }
    writer.uint32(34).string(message.nextQuery);
    return writer;
  },
  decode(reader: Reader, length?: number): GetFollowerAccountsRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetFollowerAccountsRequest) as GetFollowerAccountsRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.limit = reader.uint32();
          break;
        case 2:
          message.haveContacted = BoolValue.decode(reader, reader.uint32()).value;
          break;
        case 3:
          message.mutualFollower = BoolValue.decode(reader, reader.uint32()).value;
          break;
        case 4:
          message.nextQuery = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetFollowerAccountsRequest {
    const message = Object.create(baseGetFollowerAccountsRequest) as GetFollowerAccountsRequest;
    if (object.limit) {
      message.limit = Number(object.limit);
    }
    if (object.haveContacted) {
      message.haveContacted = Boolean(object.haveContacted);
    }
    if (object.mutualFollower) {
      message.mutualFollower = Boolean(object.mutualFollower);
    }
    if (object.nextQuery) {
      message.nextQuery = String(object.nextQuery);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetFollowerAccountsRequest>): GetFollowerAccountsRequest {
    const message = Object.create(baseGetFollowerAccountsRequest) as GetFollowerAccountsRequest;
    if (object.limit) {
      message.limit = object.limit;
    }
    if (object.haveContacted) {
      message.haveContacted = object.haveContacted;
    }
    if (object.mutualFollower) {
      message.mutualFollower = object.mutualFollower;
    }
    if (object.nextQuery) {
      message.nextQuery = object.nextQuery;
    }
    return message;
  },
  toJSON(message: GetFollowerAccountsRequest): unknown {
    const obj: any = {};
    obj.limit = message.limit || 0;
    obj.haveContacted = message.haveContacted || undefined;
    obj.mutualFollower = message.mutualFollower || undefined;
    obj.nextQuery = message.nextQuery || "";
    return obj;
  },
};

export const GetFollowerAccountsResponse = {
  encode(message: GetFollowerAccountsResponse, writer: Writer = Writer.create()): Writer {
    for (const v of message.accountFollowers) {
      AccountFollower.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    writer.uint32(18).string(message.nextQuery);
    return writer;
  },
  decode(reader: Reader, length?: number): GetFollowerAccountsResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetFollowerAccountsResponse) as GetFollowerAccountsResponse;
    message.accountFollowers = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.accountFollowers.push(AccountFollower.decode(reader, reader.uint32()));
          break;
        case 2:
          message.nextQuery = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetFollowerAccountsResponse {
    const message = Object.create(baseGetFollowerAccountsResponse) as GetFollowerAccountsResponse;
    message.accountFollowers = [];
    if (object.accountFollowers) {
      for (const e of object.accountFollowers) {
        message.accountFollowers.push(AccountFollower.fromJSON(e));
      }
    }
    if (object.nextQuery) {
      message.nextQuery = String(object.nextQuery);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetFollowerAccountsResponse>): GetFollowerAccountsResponse {
    const message = Object.create(baseGetFollowerAccountsResponse) as GetFollowerAccountsResponse;
    message.accountFollowers = [];
    if (object.accountFollowers) {
      for (const e of object.accountFollowers) {
        message.accountFollowers.push(AccountFollower.fromPartial(e));
      }
    }
    if (object.nextQuery) {
      message.nextQuery = object.nextQuery;
    }
    return message;
  },
  toJSON(message: GetFollowerAccountsResponse): unknown {
    const obj: any = {};
    if (message.accountFollowers) {
      obj.accountFollowers = message.accountFollowers.map(e => e ? AccountFollower.toJSON(e) : undefined);
    } else {
      obj.accountFollowers = [];
    }
    obj.nextQuery = message.nextQuery || "";
    return obj;
  },
};

export const UnfollowAccountsRequest = {
  encode(message: UnfollowAccountsRequest, writer: Writer = Writer.create()): Writer {
    for (const v of message.accountIds) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },
  decode(reader: Reader, length?: number): UnfollowAccountsRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseUnfollowAccountsRequest) as UnfollowAccountsRequest;
    message.accountIds = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.accountIds.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): UnfollowAccountsRequest {
    const message = Object.create(baseUnfollowAccountsRequest) as UnfollowAccountsRequest;
    message.accountIds = [];
    if (object.accountIds) {
      for (const e of object.accountIds) {
        message.accountIds.push(String(e));
      }
    }
    return message;
  },
  fromPartial(object: DeepPartial<UnfollowAccountsRequest>): UnfollowAccountsRequest {
    const message = Object.create(baseUnfollowAccountsRequest) as UnfollowAccountsRequest;
    message.accountIds = [];
    if (object.accountIds) {
      for (const e of object.accountIds) {
        message.accountIds.push(e);
      }
    }
    return message;
  },
  toJSON(message: UnfollowAccountsRequest): unknown {
    const obj: any = {};
    if (message.accountIds) {
      obj.accountIds = message.accountIds.map(e => e || "");
    } else {
      obj.accountIds = [];
    }
    return obj;
  },
};

export const UnfollowAccountsResponse = {
  encode(message: UnfollowAccountsResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): UnfollowAccountsResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseUnfollowAccountsResponse) as UnfollowAccountsResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): UnfollowAccountsResponse {
    const message = Object.create(baseUnfollowAccountsResponse) as UnfollowAccountsResponse;
    return message;
  },
  fromPartial(object: DeepPartial<UnfollowAccountsResponse>): UnfollowAccountsResponse {
    const message = Object.create(baseUnfollowAccountsResponse) as UnfollowAccountsResponse;
    return message;
  },
  toJSON(message: UnfollowAccountsResponse): unknown {
    const obj: any = {};
    return obj;
  },
};

export const ChangePasswordRequest = {
  encode(message: ChangePasswordRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.currentPassword);
    writer.uint32(18).string(message.newPassword);
    return writer;
  },
  decode(reader: Reader, length?: number): ChangePasswordRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseChangePasswordRequest) as ChangePasswordRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.currentPassword = reader.string();
          break;
        case 2:
          message.newPassword = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): ChangePasswordRequest {
    const message = Object.create(baseChangePasswordRequest) as ChangePasswordRequest;
    if (object.currentPassword) {
      message.currentPassword = String(object.currentPassword);
    }
    if (object.newPassword) {
      message.newPassword = String(object.newPassword);
    }
    return message;
  },
  fromPartial(object: DeepPartial<ChangePasswordRequest>): ChangePasswordRequest {
    const message = Object.create(baseChangePasswordRequest) as ChangePasswordRequest;
    if (object.currentPassword) {
      message.currentPassword = object.currentPassword;
    }
    if (object.newPassword) {
      message.newPassword = object.newPassword;
    }
    return message;
  },
  toJSON(message: ChangePasswordRequest): unknown {
    const obj: any = {};
    obj.currentPassword = message.currentPassword || "";
    obj.newPassword = message.newPassword || "";
    return obj;
  },
};

export const ChangePasswordResponse = {
  encode(message: ChangePasswordResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): ChangePasswordResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseChangePasswordResponse) as ChangePasswordResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): ChangePasswordResponse {
    const message = Object.create(baseChangePasswordResponse) as ChangePasswordResponse;
    return message;
  },
  fromPartial(object: DeepPartial<ChangePasswordResponse>): ChangePasswordResponse {
    const message = Object.create(baseChangePasswordResponse) as ChangePasswordResponse;
    return message;
  },
  toJSON(message: ChangePasswordResponse): unknown {
    const obj: any = {};
    return obj;
  },
};

export const UploadImageRequest = {
  encode(message: UploadImageRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.type);
    writer.uint32(18).string(message.data);
    return writer;
  },
  decode(reader: Reader, length?: number): UploadImageRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseUploadImageRequest) as UploadImageRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.type = reader.string();
          break;
        case 2:
          message.data = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): UploadImageRequest {
    const message = Object.create(baseUploadImageRequest) as UploadImageRequest;
    if (object.type) {
      message.type = String(object.type);
    }
    if (object.data) {
      message.data = String(object.data);
    }
    return message;
  },
  fromPartial(object: DeepPartial<UploadImageRequest>): UploadImageRequest {
    const message = Object.create(baseUploadImageRequest) as UploadImageRequest;
    if (object.type) {
      message.type = object.type;
    }
    if (object.data) {
      message.data = object.data;
    }
    return message;
  },
  toJSON(message: UploadImageRequest): unknown {
    const obj: any = {};
    obj.type = message.type || "";
    obj.data = message.data || "";
    return obj;
  },
};

export const UploadImageResponse = {
  encode(message: UploadImageResponse, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.key);
    if (message.images !== undefined && message.images !== undefined) {
      Images.encode(message.images, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): UploadImageResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseUploadImageResponse) as UploadImageResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.key = reader.string();
          break;
        case 2:
          message.images = Images.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): UploadImageResponse {
    const message = Object.create(baseUploadImageResponse) as UploadImageResponse;
    if (object.key) {
      message.key = String(object.key);
    }
    if (object.images) {
      message.images = Images.fromJSON(object.images);
    }
    return message;
  },
  fromPartial(object: DeepPartial<UploadImageResponse>): UploadImageResponse {
    const message = Object.create(baseUploadImageResponse) as UploadImageResponse;
    if (object.key) {
      message.key = object.key;
    }
    if (object.images) {
      message.images = Images.fromPartial(object.images);
    }
    return message;
  },
  toJSON(message: UploadImageResponse): unknown {
    const obj: any = {};
    obj.key = message.key || "";
    obj.images = message.images ? Images.toJSON(message.images) : undefined;
    return obj;
  },
};

export const ClearNotificationRequest = {
  encode(message: ClearNotificationRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.messageType);
    writer.uint32(18).string(message.notificationId);
    return writer;
  },
  decode(reader: Reader, length?: number): ClearNotificationRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseClearNotificationRequest) as ClearNotificationRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.messageType = reader.string();
          break;
        case 2:
          message.notificationId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): ClearNotificationRequest {
    const message = Object.create(baseClearNotificationRequest) as ClearNotificationRequest;
    if (object.messageType) {
      message.messageType = String(object.messageType);
    }
    if (object.notificationId) {
      message.notificationId = String(object.notificationId);
    }
    return message;
  },
  fromPartial(object: DeepPartial<ClearNotificationRequest>): ClearNotificationRequest {
    const message = Object.create(baseClearNotificationRequest) as ClearNotificationRequest;
    if (object.messageType) {
      message.messageType = object.messageType;
    }
    if (object.notificationId) {
      message.notificationId = object.notificationId;
    }
    return message;
  },
  toJSON(message: ClearNotificationRequest): unknown {
    const obj: any = {};
    obj.messageType = message.messageType || "";
    obj.notificationId = message.notificationId || "";
    return obj;
  },
};

export const ClearNotificationResponse = {
  encode(message: ClearNotificationResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): ClearNotificationResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseClearNotificationResponse) as ClearNotificationResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): ClearNotificationResponse {
    const message = Object.create(baseClearNotificationResponse) as ClearNotificationResponse;
    return message;
  },
  fromPartial(object: DeepPartial<ClearNotificationResponse>): ClearNotificationResponse {
    const message = Object.create(baseClearNotificationResponse) as ClearNotificationResponse;
    return message;
  },
  toJSON(message: ClearNotificationResponse): unknown {
    const obj: any = {};
    return obj;
  },
};

export const GetMissionSearchConditionsRequest = {
  encode(message: GetMissionSearchConditionsRequest, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): GetMissionSearchConditionsRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetMissionSearchConditionsRequest) as GetMissionSearchConditionsRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetMissionSearchConditionsRequest {
    const message = Object.create(baseGetMissionSearchConditionsRequest) as GetMissionSearchConditionsRequest;
    return message;
  },
  fromPartial(object: DeepPartial<GetMissionSearchConditionsRequest>): GetMissionSearchConditionsRequest {
    const message = Object.create(baseGetMissionSearchConditionsRequest) as GetMissionSearchConditionsRequest;
    return message;
  },
  toJSON(message: GetMissionSearchConditionsRequest): unknown {
    const obj: any = {};
    return obj;
  },
};

export const GetMissionSearchConditionsResponse = {
  encode(message: GetMissionSearchConditionsResponse, writer: Writer = Writer.create()): Writer {
    for (const v of message.financingSeries) {
      FinancingSeries.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.corporateAttributes) {
      CorporateAttribute.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.japanPrefectures) {
      JapanPrefecture.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.missionCategories) {
      MissionCategory.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.countries) {
      Country.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    for (const v of message.demands) {
      Demand.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): GetMissionSearchConditionsResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetMissionSearchConditionsResponse) as GetMissionSearchConditionsResponse;
    message.financingSeries = [];
    message.corporateAttributes = [];
    message.japanPrefectures = [];
    message.missionCategories = [];
    message.countries = [];
    message.demands = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.financingSeries.push(FinancingSeries.decode(reader, reader.uint32()));
          break;
        case 2:
          message.corporateAttributes.push(CorporateAttribute.decode(reader, reader.uint32()));
          break;
        case 3:
          message.japanPrefectures.push(JapanPrefecture.decode(reader, reader.uint32()));
          break;
        case 4:
          message.missionCategories.push(MissionCategory.decode(reader, reader.uint32()));
          break;
        case 5:
          message.countries.push(Country.decode(reader, reader.uint32()));
          break;
        case 6:
          message.demands.push(Demand.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetMissionSearchConditionsResponse {
    const message = Object.create(baseGetMissionSearchConditionsResponse) as GetMissionSearchConditionsResponse;
    message.financingSeries = [];
    message.corporateAttributes = [];
    message.japanPrefectures = [];
    message.missionCategories = [];
    message.countries = [];
    message.demands = [];
    if (object.financingSeries) {
      for (const e of object.financingSeries) {
        message.financingSeries.push(FinancingSeries.fromJSON(e));
      }
    }
    if (object.corporateAttributes) {
      for (const e of object.corporateAttributes) {
        message.corporateAttributes.push(CorporateAttribute.fromJSON(e));
      }
    }
    if (object.japanPrefectures) {
      for (const e of object.japanPrefectures) {
        message.japanPrefectures.push(JapanPrefecture.fromJSON(e));
      }
    }
    if (object.missionCategories) {
      for (const e of object.missionCategories) {
        message.missionCategories.push(MissionCategory.fromJSON(e));
      }
    }
    if (object.countries) {
      for (const e of object.countries) {
        message.countries.push(Country.fromJSON(e));
      }
    }
    if (object.demands) {
      for (const e of object.demands) {
        message.demands.push(Demand.fromJSON(e));
      }
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetMissionSearchConditionsResponse>): GetMissionSearchConditionsResponse {
    const message = Object.create(baseGetMissionSearchConditionsResponse) as GetMissionSearchConditionsResponse;
    message.financingSeries = [];
    message.corporateAttributes = [];
    message.japanPrefectures = [];
    message.missionCategories = [];
    message.countries = [];
    message.demands = [];
    if (object.financingSeries) {
      for (const e of object.financingSeries) {
        message.financingSeries.push(FinancingSeries.fromPartial(e));
      }
    }
    if (object.corporateAttributes) {
      for (const e of object.corporateAttributes) {
        message.corporateAttributes.push(CorporateAttribute.fromPartial(e));
      }
    }
    if (object.japanPrefectures) {
      for (const e of object.japanPrefectures) {
        message.japanPrefectures.push(JapanPrefecture.fromPartial(e));
      }
    }
    if (object.missionCategories) {
      for (const e of object.missionCategories) {
        message.missionCategories.push(MissionCategory.fromPartial(e));
      }
    }
    if (object.countries) {
      for (const e of object.countries) {
        message.countries.push(Country.fromPartial(e));
      }
    }
    if (object.demands) {
      for (const e of object.demands) {
        message.demands.push(Demand.fromPartial(e));
      }
    }
    return message;
  },
  toJSON(message: GetMissionSearchConditionsResponse): unknown {
    const obj: any = {};
    if (message.financingSeries) {
      obj.financingSeries = message.financingSeries.map(e => e ? FinancingSeries.toJSON(e) : undefined);
    } else {
      obj.financingSeries = [];
    }
    if (message.corporateAttributes) {
      obj.corporateAttributes = message.corporateAttributes.map(e => e ? CorporateAttribute.toJSON(e) : undefined);
    } else {
      obj.corporateAttributes = [];
    }
    if (message.japanPrefectures) {
      obj.japanPrefectures = message.japanPrefectures.map(e => e ? JapanPrefecture.toJSON(e) : undefined);
    } else {
      obj.japanPrefectures = [];
    }
    if (message.missionCategories) {
      obj.missionCategories = message.missionCategories.map(e => e ? MissionCategory.toJSON(e) : undefined);
    } else {
      obj.missionCategories = [];
    }
    if (message.countries) {
      obj.countries = message.countries.map(e => e ? Country.toJSON(e) : undefined);
    } else {
      obj.countries = [];
    }
    if (message.demands) {
      obj.demands = message.demands.map(e => e ? Demand.toJSON(e) : undefined);
    } else {
      obj.demands = [];
    }
    return obj;
  },
};

export const GetSupporterSearchConditionsRequest = {
  encode(message: GetSupporterSearchConditionsRequest, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): GetSupporterSearchConditionsRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetSupporterSearchConditionsRequest) as GetSupporterSearchConditionsRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetSupporterSearchConditionsRequest {
    const message = Object.create(baseGetSupporterSearchConditionsRequest) as GetSupporterSearchConditionsRequest;
    return message;
  },
  fromPartial(object: DeepPartial<GetSupporterSearchConditionsRequest>): GetSupporterSearchConditionsRequest {
    const message = Object.create(baseGetSupporterSearchConditionsRequest) as GetSupporterSearchConditionsRequest;
    return message;
  },
  toJSON(message: GetSupporterSearchConditionsRequest): unknown {
    const obj: any = {};
    return obj;
  },
};

export const GetSupporterSearchConditionsResponse = {
  encode(message: GetSupporterSearchConditionsResponse, writer: Writer = Writer.create()): Writer {
    for (const v of message.countries) {
      Country.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.japanPrefectures) {
      JapanPrefecture.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.supportAreas) {
      SupportArea.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): GetSupporterSearchConditionsResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetSupporterSearchConditionsResponse) as GetSupporterSearchConditionsResponse;
    message.countries = [];
    message.japanPrefectures = [];
    message.supportAreas = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.countries.push(Country.decode(reader, reader.uint32()));
          break;
        case 2:
          message.japanPrefectures.push(JapanPrefecture.decode(reader, reader.uint32()));
          break;
        case 3:
          message.supportAreas.push(SupportArea.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetSupporterSearchConditionsResponse {
    const message = Object.create(baseGetSupporterSearchConditionsResponse) as GetSupporterSearchConditionsResponse;
    message.countries = [];
    message.japanPrefectures = [];
    message.supportAreas = [];
    if (object.countries) {
      for (const e of object.countries) {
        message.countries.push(Country.fromJSON(e));
      }
    }
    if (object.japanPrefectures) {
      for (const e of object.japanPrefectures) {
        message.japanPrefectures.push(JapanPrefecture.fromJSON(e));
      }
    }
    if (object.supportAreas) {
      for (const e of object.supportAreas) {
        message.supportAreas.push(SupportArea.fromJSON(e));
      }
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetSupporterSearchConditionsResponse>): GetSupporterSearchConditionsResponse {
    const message = Object.create(baseGetSupporterSearchConditionsResponse) as GetSupporterSearchConditionsResponse;
    message.countries = [];
    message.japanPrefectures = [];
    message.supportAreas = [];
    if (object.countries) {
      for (const e of object.countries) {
        message.countries.push(Country.fromPartial(e));
      }
    }
    if (object.japanPrefectures) {
      for (const e of object.japanPrefectures) {
        message.japanPrefectures.push(JapanPrefecture.fromPartial(e));
      }
    }
    if (object.supportAreas) {
      for (const e of object.supportAreas) {
        message.supportAreas.push(SupportArea.fromPartial(e));
      }
    }
    return message;
  },
  toJSON(message: GetSupporterSearchConditionsResponse): unknown {
    const obj: any = {};
    if (message.countries) {
      obj.countries = message.countries.map(e => e ? Country.toJSON(e) : undefined);
    } else {
      obj.countries = [];
    }
    if (message.japanPrefectures) {
      obj.japanPrefectures = message.japanPrefectures.map(e => e ? JapanPrefecture.toJSON(e) : undefined);
    } else {
      obj.japanPrefectures = [];
    }
    if (message.supportAreas) {
      obj.supportAreas = message.supportAreas.map(e => e ? SupportArea.toJSON(e) : undefined);
    } else {
      obj.supportAreas = [];
    }
    return obj;
  },
};

export const GetMatterSearchConditionsRequest = {
  encode(message: GetMatterSearchConditionsRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.matterSearchConditionType);
    return writer;
  },
  decode(reader: Reader, length?: number): GetMatterSearchConditionsRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetMatterSearchConditionsRequest) as GetMatterSearchConditionsRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.matterSearchConditionType = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetMatterSearchConditionsRequest {
    const message = Object.create(baseGetMatterSearchConditionsRequest) as GetMatterSearchConditionsRequest;
    if (object.matterSearchConditionType) {
      message.matterSearchConditionType = String(object.matterSearchConditionType);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetMatterSearchConditionsRequest>): GetMatterSearchConditionsRequest {
    const message = Object.create(baseGetMatterSearchConditionsRequest) as GetMatterSearchConditionsRequest;
    if (object.matterSearchConditionType) {
      message.matterSearchConditionType = object.matterSearchConditionType;
    }
    return message;
  },
  toJSON(message: GetMatterSearchConditionsRequest): unknown {
    const obj: any = {};
    obj.matterSearchConditionType = message.matterSearchConditionType || "";
    return obj;
  },
};

export const GetMatterSearchConditionsResponse = {
  encode(message: GetMatterSearchConditionsResponse, writer: Writer = Writer.create()): Writer {
    if (message.careerMatterSearchConditions !== undefined && message.careerMatterSearchConditions !== undefined) {
      CareerMatterSearchConditions.encode(message.careerMatterSearchConditions, writer.uint32(18).fork()).ldelim();
    }
    if (message.newGraduateMatterSearchConditions !== undefined && message.newGraduateMatterSearchConditions !== undefined) {
      NewGraduateMatterSearchConditions.encode(message.newGraduateMatterSearchConditions, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): GetMatterSearchConditionsResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetMatterSearchConditionsResponse) as GetMatterSearchConditionsResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          message.careerMatterSearchConditions = CareerMatterSearchConditions.decode(reader, reader.uint32());
          break;
        case 3:
          message.newGraduateMatterSearchConditions = NewGraduateMatterSearchConditions.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetMatterSearchConditionsResponse {
    const message = Object.create(baseGetMatterSearchConditionsResponse) as GetMatterSearchConditionsResponse;
    if (object.careerMatterSearchConditions) {
      message.careerMatterSearchConditions = CareerMatterSearchConditions.fromJSON(object.careerMatterSearchConditions);
    }
    if (object.newGraduateMatterSearchConditions) {
      message.newGraduateMatterSearchConditions = NewGraduateMatterSearchConditions.fromJSON(object.newGraduateMatterSearchConditions);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetMatterSearchConditionsResponse>): GetMatterSearchConditionsResponse {
    const message = Object.create(baseGetMatterSearchConditionsResponse) as GetMatterSearchConditionsResponse;
    if (object.careerMatterSearchConditions) {
      message.careerMatterSearchConditions = CareerMatterSearchConditions.fromPartial(object.careerMatterSearchConditions);
    }
    if (object.newGraduateMatterSearchConditions) {
      message.newGraduateMatterSearchConditions = NewGraduateMatterSearchConditions.fromPartial(object.newGraduateMatterSearchConditions);
    }
    return message;
  },
  toJSON(message: GetMatterSearchConditionsResponse): unknown {
    const obj: any = {};
    obj.careerMatterSearchConditions = message.careerMatterSearchConditions ? CareerMatterSearchConditions.toJSON(message.careerMatterSearchConditions) : undefined;
    obj.newGraduateMatterSearchConditions = message.newGraduateMatterSearchConditions ? NewGraduateMatterSearchConditions.toJSON(message.newGraduateMatterSearchConditions) : undefined;
    return obj;
  },
};

export const CareerMatterSearchConditions = {
  encode(message: CareerMatterSearchConditions, writer: Writer = Writer.create()): Writer {
    for (const v of message.employmentTypes) {
      EmploymentType.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.occupations) {
      Occupation.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.japanPrefectures) {
      JapanPrefecture.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): CareerMatterSearchConditions {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseCareerMatterSearchConditions) as CareerMatterSearchConditions;
    message.employmentTypes = [];
    message.occupations = [];
    message.japanPrefectures = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.employmentTypes.push(EmploymentType.decode(reader, reader.uint32()));
          break;
        case 2:
          message.occupations.push(Occupation.decode(reader, reader.uint32()));
          break;
        case 3:
          message.japanPrefectures.push(JapanPrefecture.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): CareerMatterSearchConditions {
    const message = Object.create(baseCareerMatterSearchConditions) as CareerMatterSearchConditions;
    message.employmentTypes = [];
    message.occupations = [];
    message.japanPrefectures = [];
    if (object.employmentTypes) {
      for (const e of object.employmentTypes) {
        message.employmentTypes.push(EmploymentType.fromJSON(e));
      }
    }
    if (object.occupations) {
      for (const e of object.occupations) {
        message.occupations.push(Occupation.fromJSON(e));
      }
    }
    if (object.japanPrefectures) {
      for (const e of object.japanPrefectures) {
        message.japanPrefectures.push(JapanPrefecture.fromJSON(e));
      }
    }
    return message;
  },
  fromPartial(object: DeepPartial<CareerMatterSearchConditions>): CareerMatterSearchConditions {
    const message = Object.create(baseCareerMatterSearchConditions) as CareerMatterSearchConditions;
    message.employmentTypes = [];
    message.occupations = [];
    message.japanPrefectures = [];
    if (object.employmentTypes) {
      for (const e of object.employmentTypes) {
        message.employmentTypes.push(EmploymentType.fromPartial(e));
      }
    }
    if (object.occupations) {
      for (const e of object.occupations) {
        message.occupations.push(Occupation.fromPartial(e));
      }
    }
    if (object.japanPrefectures) {
      for (const e of object.japanPrefectures) {
        message.japanPrefectures.push(JapanPrefecture.fromPartial(e));
      }
    }
    return message;
  },
  toJSON(message: CareerMatterSearchConditions): unknown {
    const obj: any = {};
    if (message.employmentTypes) {
      obj.employmentTypes = message.employmentTypes.map(e => e ? EmploymentType.toJSON(e) : undefined);
    } else {
      obj.employmentTypes = [];
    }
    if (message.occupations) {
      obj.occupations = message.occupations.map(e => e ? Occupation.toJSON(e) : undefined);
    } else {
      obj.occupations = [];
    }
    if (message.japanPrefectures) {
      obj.japanPrefectures = message.japanPrefectures.map(e => e ? JapanPrefecture.toJSON(e) : undefined);
    } else {
      obj.japanPrefectures = [];
    }
    return obj;
  },
};

export const NewGraduateMatterSearchConditions = {
  encode(message: NewGraduateMatterSearchConditions, writer: Writer = Writer.create()): Writer {
    for (const v of message.preferredWorkStyles) {
      PreferredWorkStyle.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.departments) {
      Department.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.pastExperienceAchievements) {
      PastExperienceAchievement.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.preferredBusinessTypes) {
      PreferredBusinessType.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.occupations) {
      Occupation.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    for (const v of message.japanPrefectures) {
      JapanPrefecture.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    for (const v of message.dttRanks) {
      DttRank.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): NewGraduateMatterSearchConditions {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseNewGraduateMatterSearchConditions) as NewGraduateMatterSearchConditions;
    message.preferredWorkStyles = [];
    message.departments = [];
    message.pastExperienceAchievements = [];
    message.preferredBusinessTypes = [];
    message.occupations = [];
    message.japanPrefectures = [];
    message.dttRanks = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.preferredWorkStyles.push(PreferredWorkStyle.decode(reader, reader.uint32()));
          break;
        case 2:
          message.departments.push(Department.decode(reader, reader.uint32()));
          break;
        case 3:
          message.pastExperienceAchievements.push(PastExperienceAchievement.decode(reader, reader.uint32()));
          break;
        case 4:
          message.preferredBusinessTypes.push(PreferredBusinessType.decode(reader, reader.uint32()));
          break;
        case 5:
          message.occupations.push(Occupation.decode(reader, reader.uint32()));
          break;
        case 6:
          message.japanPrefectures.push(JapanPrefecture.decode(reader, reader.uint32()));
          break;
        case 7:
          message.dttRanks.push(DttRank.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): NewGraduateMatterSearchConditions {
    const message = Object.create(baseNewGraduateMatterSearchConditions) as NewGraduateMatterSearchConditions;
    message.preferredWorkStyles = [];
    message.departments = [];
    message.pastExperienceAchievements = [];
    message.preferredBusinessTypes = [];
    message.occupations = [];
    message.japanPrefectures = [];
    message.dttRanks = [];
    if (object.preferredWorkStyles) {
      for (const e of object.preferredWorkStyles) {
        message.preferredWorkStyles.push(PreferredWorkStyle.fromJSON(e));
      }
    }
    if (object.departments) {
      for (const e of object.departments) {
        message.departments.push(Department.fromJSON(e));
      }
    }
    if (object.pastExperienceAchievements) {
      for (const e of object.pastExperienceAchievements) {
        message.pastExperienceAchievements.push(PastExperienceAchievement.fromJSON(e));
      }
    }
    if (object.preferredBusinessTypes) {
      for (const e of object.preferredBusinessTypes) {
        message.preferredBusinessTypes.push(PreferredBusinessType.fromJSON(e));
      }
    }
    if (object.occupations) {
      for (const e of object.occupations) {
        message.occupations.push(Occupation.fromJSON(e));
      }
    }
    if (object.japanPrefectures) {
      for (const e of object.japanPrefectures) {
        message.japanPrefectures.push(JapanPrefecture.fromJSON(e));
      }
    }
    if (object.dttRanks) {
      for (const e of object.dttRanks) {
        message.dttRanks.push(DttRank.fromJSON(e));
      }
    }
    return message;
  },
  fromPartial(object: DeepPartial<NewGraduateMatterSearchConditions>): NewGraduateMatterSearchConditions {
    const message = Object.create(baseNewGraduateMatterSearchConditions) as NewGraduateMatterSearchConditions;
    message.preferredWorkStyles = [];
    message.departments = [];
    message.pastExperienceAchievements = [];
    message.preferredBusinessTypes = [];
    message.occupations = [];
    message.japanPrefectures = [];
    message.dttRanks = [];
    if (object.preferredWorkStyles) {
      for (const e of object.preferredWorkStyles) {
        message.preferredWorkStyles.push(PreferredWorkStyle.fromPartial(e));
      }
    }
    if (object.departments) {
      for (const e of object.departments) {
        message.departments.push(Department.fromPartial(e));
      }
    }
    if (object.pastExperienceAchievements) {
      for (const e of object.pastExperienceAchievements) {
        message.pastExperienceAchievements.push(PastExperienceAchievement.fromPartial(e));
      }
    }
    if (object.preferredBusinessTypes) {
      for (const e of object.preferredBusinessTypes) {
        message.preferredBusinessTypes.push(PreferredBusinessType.fromPartial(e));
      }
    }
    if (object.occupations) {
      for (const e of object.occupations) {
        message.occupations.push(Occupation.fromPartial(e));
      }
    }
    if (object.japanPrefectures) {
      for (const e of object.japanPrefectures) {
        message.japanPrefectures.push(JapanPrefecture.fromPartial(e));
      }
    }
    if (object.dttRanks) {
      for (const e of object.dttRanks) {
        message.dttRanks.push(DttRank.fromPartial(e));
      }
    }
    return message;
  },
  toJSON(message: NewGraduateMatterSearchConditions): unknown {
    const obj: any = {};
    if (message.preferredWorkStyles) {
      obj.preferredWorkStyles = message.preferredWorkStyles.map(e => e ? PreferredWorkStyle.toJSON(e) : undefined);
    } else {
      obj.preferredWorkStyles = [];
    }
    if (message.departments) {
      obj.departments = message.departments.map(e => e ? Department.toJSON(e) : undefined);
    } else {
      obj.departments = [];
    }
    if (message.pastExperienceAchievements) {
      obj.pastExperienceAchievements = message.pastExperienceAchievements.map(e => e ? PastExperienceAchievement.toJSON(e) : undefined);
    } else {
      obj.pastExperienceAchievements = [];
    }
    if (message.preferredBusinessTypes) {
      obj.preferredBusinessTypes = message.preferredBusinessTypes.map(e => e ? PreferredBusinessType.toJSON(e) : undefined);
    } else {
      obj.preferredBusinessTypes = [];
    }
    if (message.occupations) {
      obj.occupations = message.occupations.map(e => e ? Occupation.toJSON(e) : undefined);
    } else {
      obj.occupations = [];
    }
    if (message.japanPrefectures) {
      obj.japanPrefectures = message.japanPrefectures.map(e => e ? JapanPrefecture.toJSON(e) : undefined);
    } else {
      obj.japanPrefectures = [];
    }
    if (message.dttRanks) {
      obj.dttRanks = message.dttRanks.map(e => e ? DttRank.toJSON(e) : undefined);
    } else {
      obj.dttRanks = [];
    }
    return obj;
  },
};

export const GetFeaturesRequest = {
  encode(message: GetFeaturesRequest, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): GetFeaturesRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetFeaturesRequest) as GetFeaturesRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetFeaturesRequest {
    const message = Object.create(baseGetFeaturesRequest) as GetFeaturesRequest;
    return message;
  },
  fromPartial(object: DeepPartial<GetFeaturesRequest>): GetFeaturesRequest {
    const message = Object.create(baseGetFeaturesRequest) as GetFeaturesRequest;
    return message;
  },
  toJSON(message: GetFeaturesRequest): unknown {
    const obj: any = {};
    return obj;
  },
};

export const GetFeaturesResponse = {
  encode(message: GetFeaturesResponse, writer: Writer = Writer.create()): Writer {
    Object.entries(message.features).forEach(([key, value]) => {
      GetFeaturesResponse_FeaturesEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).ldelim();
    })
    return writer;
  },
  decode(reader: Reader, length?: number): GetFeaturesResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetFeaturesResponse) as GetFeaturesResponse;
    message.features = {};
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          const entry = GetFeaturesResponse_FeaturesEntry.decode(reader, reader.uint32());
          if (entry.value) {
            message.features[entry.key] = entry.value;
          }
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetFeaturesResponse {
    const message = Object.create(baseGetFeaturesResponse) as GetFeaturesResponse;
    message.features = {};
    if (object.features) {
      const entry = GetFeaturesResponse_FeaturesEntry.fromJSON(object.features);
      if (entry.value) {
        message.features[entry.key] = entry.value;
      }
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetFeaturesResponse>): GetFeaturesResponse {
    const message = Object.create(baseGetFeaturesResponse) as GetFeaturesResponse;
    message.features = {};
    if (object.features) {
      const entry = GetFeaturesResponse_FeaturesEntry.fromPartial(object.features);
      if (entry.value) {
        message.features[entry.key] = entry.value;
      }
    }
    return message;
  },
  toJSON(message: GetFeaturesResponse): unknown {
    const obj: any = {};
    obj.features = message.features || undefined;
    return obj;
  },
};

export const GetFeaturesResponse_FeaturesEntry = {
  encode(message: GetFeaturesResponse_FeaturesEntry, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.key);
    writer.uint32(16).bool(message.value);
    return writer;
  },
  decode(reader: Reader, length?: number): GetFeaturesResponse_FeaturesEntry {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetFeaturesResponse_FeaturesEntry) as GetFeaturesResponse_FeaturesEntry;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.key = reader.string();
          break;
        case 2:
          message.value = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetFeaturesResponse_FeaturesEntry {
    const message = Object.create(baseGetFeaturesResponse_FeaturesEntry) as GetFeaturesResponse_FeaturesEntry;
    if (object.key) {
      message.key = String(object.key);
    }
    if (object.value) {
      message.value = Boolean(object.value);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetFeaturesResponse_FeaturesEntry>): GetFeaturesResponse_FeaturesEntry {
    const message = Object.create(baseGetFeaturesResponse_FeaturesEntry) as GetFeaturesResponse_FeaturesEntry;
    if (object.key) {
      message.key = object.key;
    }
    if (object.value) {
      message.value = object.value;
    }
    return message;
  },
  toJSON(message: GetFeaturesResponse_FeaturesEntry): unknown {
    const obj: any = {};
    obj.key = message.key || "";
    obj.value = message.value || false;
    return obj;
  },
};

export const GetMissionDemandsRequest = {
  encode(message: GetMissionDemandsRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.missionId);
    return writer;
  },
  decode(reader: Reader, length?: number): GetMissionDemandsRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetMissionDemandsRequest) as GetMissionDemandsRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.missionId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetMissionDemandsRequest {
    const message = Object.create(baseGetMissionDemandsRequest) as GetMissionDemandsRequest;
    if (object.missionId) {
      message.missionId = String(object.missionId);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetMissionDemandsRequest>): GetMissionDemandsRequest {
    const message = Object.create(baseGetMissionDemandsRequest) as GetMissionDemandsRequest;
    if (object.missionId) {
      message.missionId = object.missionId;
    }
    return message;
  },
  toJSON(message: GetMissionDemandsRequest): unknown {
    const obj: any = {};
    obj.missionId = message.missionId || "";
    return obj;
  },
};

export const GetMissionDemandsResponse = {
  encode(message: GetMissionDemandsResponse, writer: Writer = Writer.create()): Writer {
    for (const v of message.demands) {
      Demand.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): GetMissionDemandsResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetMissionDemandsResponse) as GetMissionDemandsResponse;
    message.demands = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.demands.push(Demand.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetMissionDemandsResponse {
    const message = Object.create(baseGetMissionDemandsResponse) as GetMissionDemandsResponse;
    message.demands = [];
    if (object.demands) {
      for (const e of object.demands) {
        message.demands.push(Demand.fromJSON(e));
      }
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetMissionDemandsResponse>): GetMissionDemandsResponse {
    const message = Object.create(baseGetMissionDemandsResponse) as GetMissionDemandsResponse;
    message.demands = [];
    if (object.demands) {
      for (const e of object.demands) {
        message.demands.push(Demand.fromPartial(e));
      }
    }
    return message;
  },
  toJSON(message: GetMissionDemandsResponse): unknown {
    const obj: any = {};
    if (message.demands) {
      obj.demands = message.demands.map(e => e ? Demand.toJSON(e) : undefined);
    } else {
      obj.demands = [];
    }
    return obj;
  },
};

export const GetCompaniesRequest = {
  encode(message: GetCompaniesRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.nameLike);
    writer.uint32(16).uint32(message.limit);
    writer.uint32(26).string(message.q);
    return writer;
  },
  decode(reader: Reader, length?: number): GetCompaniesRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetCompaniesRequest) as GetCompaniesRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.nameLike = reader.string();
          break;
        case 2:
          message.limit = reader.uint32();
          break;
        case 3:
          message.q = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetCompaniesRequest {
    const message = Object.create(baseGetCompaniesRequest) as GetCompaniesRequest;
    if (object.nameLike) {
      message.nameLike = String(object.nameLike);
    }
    if (object.limit) {
      message.limit = Number(object.limit);
    }
    if (object.q) {
      message.q = String(object.q);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetCompaniesRequest>): GetCompaniesRequest {
    const message = Object.create(baseGetCompaniesRequest) as GetCompaniesRequest;
    if (object.nameLike) {
      message.nameLike = object.nameLike;
    }
    if (object.limit) {
      message.limit = object.limit;
    }
    if (object.q) {
      message.q = object.q;
    }
    return message;
  },
  toJSON(message: GetCompaniesRequest): unknown {
    const obj: any = {};
    obj.nameLike = message.nameLike || "";
    obj.limit = message.limit || 0;
    obj.q = message.q || "";
    return obj;
  },
};

export const GetCompaniesResponse = {
  encode(message: GetCompaniesResponse, writer: Writer = Writer.create()): Writer {
    for (const v of message.companies) {
      Company.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.paging !== undefined && message.paging !== undefined) {
      Paging.encode(message.paging, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): GetCompaniesResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetCompaniesResponse) as GetCompaniesResponse;
    message.companies = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.companies.push(Company.decode(reader, reader.uint32()));
          break;
        case 2:
          message.paging = Paging.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetCompaniesResponse {
    const message = Object.create(baseGetCompaniesResponse) as GetCompaniesResponse;
    message.companies = [];
    if (object.companies) {
      for (const e of object.companies) {
        message.companies.push(Company.fromJSON(e));
      }
    }
    if (object.paging) {
      message.paging = Paging.fromJSON(object.paging);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetCompaniesResponse>): GetCompaniesResponse {
    const message = Object.create(baseGetCompaniesResponse) as GetCompaniesResponse;
    message.companies = [];
    if (object.companies) {
      for (const e of object.companies) {
        message.companies.push(Company.fromPartial(e));
      }
    }
    if (object.paging) {
      message.paging = Paging.fromPartial(object.paging);
    }
    return message;
  },
  toJSON(message: GetCompaniesResponse): unknown {
    const obj: any = {};
    if (message.companies) {
      obj.companies = message.companies.map(e => e ? Company.toJSON(e) : undefined);
    } else {
      obj.companies = [];
    }
    obj.paging = message.paging ? Paging.toJSON(message.paging) : undefined;
    return obj;
  },
};

export const GetSchoolsRequest = {
  encode(message: GetSchoolsRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.nameStartWith);
    writer.uint32(16).uint32(message.limit);
    writer.uint32(26).string(message.q);
    writer.uint32(34).fork();
    for (const v of message.schoolIds) {
      writer.uint32(v);
    }
    writer.ldelim();
    return writer;
  },
  decode(reader: Reader, length?: number): GetSchoolsRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetSchoolsRequest) as GetSchoolsRequest;
    message.schoolIds = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.nameStartWith = reader.string();
          break;
        case 2:
          message.limit = reader.uint32();
          break;
        case 3:
          message.q = reader.string();
          break;
        case 4:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.schoolIds.push(reader.uint32());
            }
          } else {
            message.schoolIds.push(reader.uint32());
          }
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetSchoolsRequest {
    const message = Object.create(baseGetSchoolsRequest) as GetSchoolsRequest;
    message.schoolIds = [];
    if (object.nameStartWith) {
      message.nameStartWith = String(object.nameStartWith);
    }
    if (object.limit) {
      message.limit = Number(object.limit);
    }
    if (object.q) {
      message.q = String(object.q);
    }
    if (object.schoolIds) {
      for (const e of object.schoolIds) {
        message.schoolIds.push(Number(e));
      }
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetSchoolsRequest>): GetSchoolsRequest {
    const message = Object.create(baseGetSchoolsRequest) as GetSchoolsRequest;
    message.schoolIds = [];
    if (object.nameStartWith) {
      message.nameStartWith = object.nameStartWith;
    }
    if (object.limit) {
      message.limit = object.limit;
    }
    if (object.q) {
      message.q = object.q;
    }
    if (object.schoolIds) {
      for (const e of object.schoolIds) {
        message.schoolIds.push(e);
      }
    }
    return message;
  },
  toJSON(message: GetSchoolsRequest): unknown {
    const obj: any = {};
    obj.nameStartWith = message.nameStartWith || "";
    obj.limit = message.limit || 0;
    obj.q = message.q || "";
    if (message.schoolIds) {
      obj.schoolIds = message.schoolIds.map(e => e || 0);
    } else {
      obj.schoolIds = [];
    }
    return obj;
  },
};

export const GetSchoolsResponse = {
  encode(message: GetSchoolsResponse, writer: Writer = Writer.create()): Writer {
    for (const v of message.schools) {
      School.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.paging !== undefined && message.paging !== undefined) {
      Paging.encode(message.paging, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): GetSchoolsResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetSchoolsResponse) as GetSchoolsResponse;
    message.schools = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.schools.push(School.decode(reader, reader.uint32()));
          break;
        case 2:
          message.paging = Paging.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetSchoolsResponse {
    const message = Object.create(baseGetSchoolsResponse) as GetSchoolsResponse;
    message.schools = [];
    if (object.schools) {
      for (const e of object.schools) {
        message.schools.push(School.fromJSON(e));
      }
    }
    if (object.paging) {
      message.paging = Paging.fromJSON(object.paging);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetSchoolsResponse>): GetSchoolsResponse {
    const message = Object.create(baseGetSchoolsResponse) as GetSchoolsResponse;
    message.schools = [];
    if (object.schools) {
      for (const e of object.schools) {
        message.schools.push(School.fromPartial(e));
      }
    }
    if (object.paging) {
      message.paging = Paging.fromPartial(object.paging);
    }
    return message;
  },
  toJSON(message: GetSchoolsResponse): unknown {
    const obj: any = {};
    if (message.schools) {
      obj.schools = message.schools.map(e => e ? School.toJSON(e) : undefined);
    } else {
      obj.schools = [];
    }
    obj.paging = message.paging ? Paging.toJSON(message.paging) : undefined;
    return obj;
  },
};

export const RegisterSchoolRequest = {
  encode(message: RegisterSchoolRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.name);
    return writer;
  },
  decode(reader: Reader, length?: number): RegisterSchoolRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseRegisterSchoolRequest) as RegisterSchoolRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.name = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): RegisterSchoolRequest {
    const message = Object.create(baseRegisterSchoolRequest) as RegisterSchoolRequest;
    if (object.name) {
      message.name = String(object.name);
    }
    return message;
  },
  fromPartial(object: DeepPartial<RegisterSchoolRequest>): RegisterSchoolRequest {
    const message = Object.create(baseRegisterSchoolRequest) as RegisterSchoolRequest;
    if (object.name) {
      message.name = object.name;
    }
    return message;
  },
  toJSON(message: RegisterSchoolRequest): unknown {
    const obj: any = {};
    obj.name = message.name || "";
    return obj;
  },
};

export const RegisterSchoolResponse = {
  encode(message: RegisterSchoolResponse, writer: Writer = Writer.create()): Writer {
    if (message.school !== undefined && message.school !== undefined) {
      School.encode(message.school, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): RegisterSchoolResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseRegisterSchoolResponse) as RegisterSchoolResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.school = School.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): RegisterSchoolResponse {
    const message = Object.create(baseRegisterSchoolResponse) as RegisterSchoolResponse;
    if (object.school) {
      message.school = School.fromJSON(object.school);
    }
    return message;
  },
  fromPartial(object: DeepPartial<RegisterSchoolResponse>): RegisterSchoolResponse {
    const message = Object.create(baseRegisterSchoolResponse) as RegisterSchoolResponse;
    if (object.school) {
      message.school = School.fromPartial(object.school);
    }
    return message;
  },
  toJSON(message: RegisterSchoolResponse): unknown {
    const obj: any = {};
    obj.school = message.school ? School.toJSON(message.school) : undefined;
    return obj;
  },
};

export const GetSkillsRequest = {
  encode(message: GetSkillsRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.nameStartWith);
    writer.uint32(18).fork();
    for (const v of message.skillIds) {
      writer.uint64(v);
    }
    writer.ldelim();
    writer.uint32(24).uint32(message.limit);
    writer.uint32(34).string(message.q);
    return writer;
  },
  decode(reader: Reader, length?: number): GetSkillsRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetSkillsRequest) as GetSkillsRequest;
    message.skillIds = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.nameStartWith = reader.string();
          break;
        case 2:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.skillIds.push(longToNumber(reader.uint64() as Long));
            }
          } else {
            message.skillIds.push(longToNumber(reader.uint64() as Long));
          }
          break;
        case 3:
          message.limit = reader.uint32();
          break;
        case 4:
          message.q = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetSkillsRequest {
    const message = Object.create(baseGetSkillsRequest) as GetSkillsRequest;
    message.skillIds = [];
    if (object.nameStartWith) {
      message.nameStartWith = String(object.nameStartWith);
    }
    if (object.skillIds) {
      for (const e of object.skillIds) {
        message.skillIds.push(Number(e));
      }
    }
    if (object.limit) {
      message.limit = Number(object.limit);
    }
    if (object.q) {
      message.q = String(object.q);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetSkillsRequest>): GetSkillsRequest {
    const message = Object.create(baseGetSkillsRequest) as GetSkillsRequest;
    message.skillIds = [];
    if (object.nameStartWith) {
      message.nameStartWith = object.nameStartWith;
    }
    if (object.skillIds) {
      for (const e of object.skillIds) {
        message.skillIds.push(e);
      }
    }
    if (object.limit) {
      message.limit = object.limit;
    }
    if (object.q) {
      message.q = object.q;
    }
    return message;
  },
  toJSON(message: GetSkillsRequest): unknown {
    const obj: any = {};
    obj.nameStartWith = message.nameStartWith || "";
    if (message.skillIds) {
      obj.skillIds = message.skillIds.map(e => e || 0);
    } else {
      obj.skillIds = [];
    }
    obj.limit = message.limit || 0;
    obj.q = message.q || "";
    return obj;
  },
};

export const GetSkillsResponse = {
  encode(message: GetSkillsResponse, writer: Writer = Writer.create()): Writer {
    for (const v of message.skills) {
      Skill.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.paging !== undefined && message.paging !== undefined) {
      Paging.encode(message.paging, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): GetSkillsResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetSkillsResponse) as GetSkillsResponse;
    message.skills = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.skills.push(Skill.decode(reader, reader.uint32()));
          break;
        case 2:
          message.paging = Paging.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetSkillsResponse {
    const message = Object.create(baseGetSkillsResponse) as GetSkillsResponse;
    message.skills = [];
    if (object.skills) {
      for (const e of object.skills) {
        message.skills.push(Skill.fromJSON(e));
      }
    }
    if (object.paging) {
      message.paging = Paging.fromJSON(object.paging);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetSkillsResponse>): GetSkillsResponse {
    const message = Object.create(baseGetSkillsResponse) as GetSkillsResponse;
    message.skills = [];
    if (object.skills) {
      for (const e of object.skills) {
        message.skills.push(Skill.fromPartial(e));
      }
    }
    if (object.paging) {
      message.paging = Paging.fromPartial(object.paging);
    }
    return message;
  },
  toJSON(message: GetSkillsResponse): unknown {
    const obj: any = {};
    if (message.skills) {
      obj.skills = message.skills.map(e => e ? Skill.toJSON(e) : undefined);
    } else {
      obj.skills = [];
    }
    obj.paging = message.paging ? Paging.toJSON(message.paging) : undefined;
    return obj;
  },
};

export const GetExampleSkillsRequest = {
  encode(message: GetExampleSkillsRequest, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): GetExampleSkillsRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetExampleSkillsRequest) as GetExampleSkillsRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetExampleSkillsRequest {
    const message = Object.create(baseGetExampleSkillsRequest) as GetExampleSkillsRequest;
    return message;
  },
  fromPartial(object: DeepPartial<GetExampleSkillsRequest>): GetExampleSkillsRequest {
    const message = Object.create(baseGetExampleSkillsRequest) as GetExampleSkillsRequest;
    return message;
  },
  toJSON(message: GetExampleSkillsRequest): unknown {
    const obj: any = {};
    return obj;
  },
};

export const GetExampleSkillsResponse = {
  encode(message: GetExampleSkillsResponse, writer: Writer = Writer.create()): Writer {
    for (const v of message.skills) {
      Skill.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): GetExampleSkillsResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetExampleSkillsResponse) as GetExampleSkillsResponse;
    message.skills = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.skills.push(Skill.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetExampleSkillsResponse {
    const message = Object.create(baseGetExampleSkillsResponse) as GetExampleSkillsResponse;
    message.skills = [];
    if (object.skills) {
      for (const e of object.skills) {
        message.skills.push(Skill.fromJSON(e));
      }
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetExampleSkillsResponse>): GetExampleSkillsResponse {
    const message = Object.create(baseGetExampleSkillsResponse) as GetExampleSkillsResponse;
    message.skills = [];
    if (object.skills) {
      for (const e of object.skills) {
        message.skills.push(Skill.fromPartial(e));
      }
    }
    return message;
  },
  toJSON(message: GetExampleSkillsResponse): unknown {
    const obj: any = {};
    if (message.skills) {
      obj.skills = message.skills.map(e => e ? Skill.toJSON(e) : undefined);
    } else {
      obj.skills = [];
    }
    return obj;
  },
};

export const RegisterSkillRequest = {
  encode(message: RegisterSkillRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.name);
    return writer;
  },
  decode(reader: Reader, length?: number): RegisterSkillRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseRegisterSkillRequest) as RegisterSkillRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.name = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): RegisterSkillRequest {
    const message = Object.create(baseRegisterSkillRequest) as RegisterSkillRequest;
    if (object.name) {
      message.name = String(object.name);
    }
    return message;
  },
  fromPartial(object: DeepPartial<RegisterSkillRequest>): RegisterSkillRequest {
    const message = Object.create(baseRegisterSkillRequest) as RegisterSkillRequest;
    if (object.name) {
      message.name = object.name;
    }
    return message;
  },
  toJSON(message: RegisterSkillRequest): unknown {
    const obj: any = {};
    obj.name = message.name || "";
    return obj;
  },
};

export const RegisterSkillResponse = {
  encode(message: RegisterSkillResponse, writer: Writer = Writer.create()): Writer {
    if (message.skill !== undefined && message.skill !== undefined) {
      Skill.encode(message.skill, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): RegisterSkillResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseRegisterSkillResponse) as RegisterSkillResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.skill = Skill.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): RegisterSkillResponse {
    const message = Object.create(baseRegisterSkillResponse) as RegisterSkillResponse;
    if (object.skill) {
      message.skill = Skill.fromJSON(object.skill);
    }
    return message;
  },
  fromPartial(object: DeepPartial<RegisterSkillResponse>): RegisterSkillResponse {
    const message = Object.create(baseRegisterSkillResponse) as RegisterSkillResponse;
    if (object.skill) {
      message.skill = Skill.fromPartial(object.skill);
    }
    return message;
  },
  toJSON(message: RegisterSkillResponse): unknown {
    const obj: any = {};
    obj.skill = message.skill ? Skill.toJSON(message.skill) : undefined;
    return obj;
  },
};

export const GetExpectationsRequest = {
  encode(message: GetExpectationsRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.nameStartWith);
    writer.uint32(16).uint32(message.limit);
    writer.uint32(26).string(message.q);
    return writer;
  },
  decode(reader: Reader, length?: number): GetExpectationsRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetExpectationsRequest) as GetExpectationsRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.nameStartWith = reader.string();
          break;
        case 2:
          message.limit = reader.uint32();
          break;
        case 3:
          message.q = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetExpectationsRequest {
    const message = Object.create(baseGetExpectationsRequest) as GetExpectationsRequest;
    if (object.nameStartWith) {
      message.nameStartWith = String(object.nameStartWith);
    }
    if (object.limit) {
      message.limit = Number(object.limit);
    }
    if (object.q) {
      message.q = String(object.q);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetExpectationsRequest>): GetExpectationsRequest {
    const message = Object.create(baseGetExpectationsRequest) as GetExpectationsRequest;
    if (object.nameStartWith) {
      message.nameStartWith = object.nameStartWith;
    }
    if (object.limit) {
      message.limit = object.limit;
    }
    if (object.q) {
      message.q = object.q;
    }
    return message;
  },
  toJSON(message: GetExpectationsRequest): unknown {
    const obj: any = {};
    obj.nameStartWith = message.nameStartWith || "";
    obj.limit = message.limit || 0;
    obj.q = message.q || "";
    return obj;
  },
};

export const GetExpectationsResponse = {
  encode(message: GetExpectationsResponse, writer: Writer = Writer.create()): Writer {
    for (const v of message.expectations) {
      Expectation.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.paging !== undefined && message.paging !== undefined) {
      Paging.encode(message.paging, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): GetExpectationsResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetExpectationsResponse) as GetExpectationsResponse;
    message.expectations = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.expectations.push(Expectation.decode(reader, reader.uint32()));
          break;
        case 2:
          message.paging = Paging.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetExpectationsResponse {
    const message = Object.create(baseGetExpectationsResponse) as GetExpectationsResponse;
    message.expectations = [];
    if (object.expectations) {
      for (const e of object.expectations) {
        message.expectations.push(Expectation.fromJSON(e));
      }
    }
    if (object.paging) {
      message.paging = Paging.fromJSON(object.paging);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetExpectationsResponse>): GetExpectationsResponse {
    const message = Object.create(baseGetExpectationsResponse) as GetExpectationsResponse;
    message.expectations = [];
    if (object.expectations) {
      for (const e of object.expectations) {
        message.expectations.push(Expectation.fromPartial(e));
      }
    }
    if (object.paging) {
      message.paging = Paging.fromPartial(object.paging);
    }
    return message;
  },
  toJSON(message: GetExpectationsResponse): unknown {
    const obj: any = {};
    if (message.expectations) {
      obj.expectations = message.expectations.map(e => e ? Expectation.toJSON(e) : undefined);
    } else {
      obj.expectations = [];
    }
    obj.paging = message.paging ? Paging.toJSON(message.paging) : undefined;
    return obj;
  },
};

export const GetExampleExpectationsRequest = {
  encode(message: GetExampleExpectationsRequest, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): GetExampleExpectationsRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetExampleExpectationsRequest) as GetExampleExpectationsRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetExampleExpectationsRequest {
    const message = Object.create(baseGetExampleExpectationsRequest) as GetExampleExpectationsRequest;
    return message;
  },
  fromPartial(object: DeepPartial<GetExampleExpectationsRequest>): GetExampleExpectationsRequest {
    const message = Object.create(baseGetExampleExpectationsRequest) as GetExampleExpectationsRequest;
    return message;
  },
  toJSON(message: GetExampleExpectationsRequest): unknown {
    const obj: any = {};
    return obj;
  },
};

export const GetExampleExpectationsResponse = {
  encode(message: GetExampleExpectationsResponse, writer: Writer = Writer.create()): Writer {
    for (const v of message.expectations) {
      Expectation.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): GetExampleExpectationsResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetExampleExpectationsResponse) as GetExampleExpectationsResponse;
    message.expectations = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.expectations.push(Expectation.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetExampleExpectationsResponse {
    const message = Object.create(baseGetExampleExpectationsResponse) as GetExampleExpectationsResponse;
    message.expectations = [];
    if (object.expectations) {
      for (const e of object.expectations) {
        message.expectations.push(Expectation.fromJSON(e));
      }
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetExampleExpectationsResponse>): GetExampleExpectationsResponse {
    const message = Object.create(baseGetExampleExpectationsResponse) as GetExampleExpectationsResponse;
    message.expectations = [];
    if (object.expectations) {
      for (const e of object.expectations) {
        message.expectations.push(Expectation.fromPartial(e));
      }
    }
    return message;
  },
  toJSON(message: GetExampleExpectationsResponse): unknown {
    const obj: any = {};
    if (message.expectations) {
      obj.expectations = message.expectations.map(e => e ? Expectation.toJSON(e) : undefined);
    } else {
      obj.expectations = [];
    }
    return obj;
  },
};

export const RegisterExpectationRequest = {
  encode(message: RegisterExpectationRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.name);
    return writer;
  },
  decode(reader: Reader, length?: number): RegisterExpectationRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseRegisterExpectationRequest) as RegisterExpectationRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.name = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): RegisterExpectationRequest {
    const message = Object.create(baseRegisterExpectationRequest) as RegisterExpectationRequest;
    if (object.name) {
      message.name = String(object.name);
    }
    return message;
  },
  fromPartial(object: DeepPartial<RegisterExpectationRequest>): RegisterExpectationRequest {
    const message = Object.create(baseRegisterExpectationRequest) as RegisterExpectationRequest;
    if (object.name) {
      message.name = object.name;
    }
    return message;
  },
  toJSON(message: RegisterExpectationRequest): unknown {
    const obj: any = {};
    obj.name = message.name || "";
    return obj;
  },
};

export const RegisterExpectationResponse = {
  encode(message: RegisterExpectationResponse, writer: Writer = Writer.create()): Writer {
    if (message.expectation !== undefined && message.expectation !== undefined) {
      Expectation.encode(message.expectation, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): RegisterExpectationResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseRegisterExpectationResponse) as RegisterExpectationResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.expectation = Expectation.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): RegisterExpectationResponse {
    const message = Object.create(baseRegisterExpectationResponse) as RegisterExpectationResponse;
    if (object.expectation) {
      message.expectation = Expectation.fromJSON(object.expectation);
    }
    return message;
  },
  fromPartial(object: DeepPartial<RegisterExpectationResponse>): RegisterExpectationResponse {
    const message = Object.create(baseRegisterExpectationResponse) as RegisterExpectationResponse;
    if (object.expectation) {
      message.expectation = Expectation.fromPartial(object.expectation);
    }
    return message;
  },
  toJSON(message: RegisterExpectationResponse): unknown {
    const obj: any = {};
    obj.expectation = message.expectation ? Expectation.toJSON(message.expectation) : undefined;
    return obj;
  },
};

export const UploadFileRequest = {
  encode(message: UploadFileRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.data);
    writer.uint32(18).string(message.name);
    return writer;
  },
  decode(reader: Reader, length?: number): UploadFileRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseUploadFileRequest) as UploadFileRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.data = reader.string();
          break;
        case 2:
          message.name = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): UploadFileRequest {
    const message = Object.create(baseUploadFileRequest) as UploadFileRequest;
    if (object.data) {
      message.data = String(object.data);
    }
    if (object.name) {
      message.name = String(object.name);
    }
    return message;
  },
  fromPartial(object: DeepPartial<UploadFileRequest>): UploadFileRequest {
    const message = Object.create(baseUploadFileRequest) as UploadFileRequest;
    if (object.data) {
      message.data = object.data;
    }
    if (object.name) {
      message.name = object.name;
    }
    return message;
  },
  toJSON(message: UploadFileRequest): unknown {
    const obj: any = {};
    obj.data = message.data || "";
    obj.name = message.name || "";
    return obj;
  },
};

export const UploadFileResponse = {
  encode(message: UploadFileResponse, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.key);
    return writer;
  },
  decode(reader: Reader, length?: number): UploadFileResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseUploadFileResponse) as UploadFileResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.key = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): UploadFileResponse {
    const message = Object.create(baseUploadFileResponse) as UploadFileResponse;
    if (object.key) {
      message.key = String(object.key);
    }
    return message;
  },
  fromPartial(object: DeepPartial<UploadFileResponse>): UploadFileResponse {
    const message = Object.create(baseUploadFileResponse) as UploadFileResponse;
    if (object.key) {
      message.key = object.key;
    }
    return message;
  },
  toJSON(message: UploadFileResponse): unknown {
    const obj: any = {};
    obj.key = message.key || "";
    return obj;
  },
};

export const PutMatterViewedRequest = {
  encode(message: PutMatterViewedRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).int64(message.matterPublishId);
    return writer;
  },
  decode(reader: Reader, length?: number): PutMatterViewedRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(basePutMatterViewedRequest) as PutMatterViewedRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.matterPublishId = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): PutMatterViewedRequest {
    const message = Object.create(basePutMatterViewedRequest) as PutMatterViewedRequest;
    if (object.matterPublishId) {
      message.matterPublishId = Number(object.matterPublishId);
    }
    return message;
  },
  fromPartial(object: DeepPartial<PutMatterViewedRequest>): PutMatterViewedRequest {
    const message = Object.create(basePutMatterViewedRequest) as PutMatterViewedRequest;
    if (object.matterPublishId) {
      message.matterPublishId = object.matterPublishId;
    }
    return message;
  },
  toJSON(message: PutMatterViewedRequest): unknown {
    const obj: any = {};
    obj.matterPublishId = message.matterPublishId || 0;
    return obj;
  },
};

export const PutMatterViewedResponse = {
  encode(message: PutMatterViewedResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): PutMatterViewedResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(basePutMatterViewedResponse) as PutMatterViewedResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): PutMatterViewedResponse {
    const message = Object.create(basePutMatterViewedResponse) as PutMatterViewedResponse;
    return message;
  },
  fromPartial(object: DeepPartial<PutMatterViewedResponse>): PutMatterViewedResponse {
    const message = Object.create(basePutMatterViewedResponse) as PutMatterViewedResponse;
    return message;
  },
  toJSON(message: PutMatterViewedResponse): unknown {
    const obj: any = {};
    return obj;
  },
};

export const PutMatterFavoriteRequest = {
  encode(message: PutMatterFavoriteRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).int64(message.matterPublishId);
    writer.uint32(16).bool(message.favorite);
    return writer;
  },
  decode(reader: Reader, length?: number): PutMatterFavoriteRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(basePutMatterFavoriteRequest) as PutMatterFavoriteRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.matterPublishId = longToNumber(reader.int64() as Long);
          break;
        case 2:
          message.favorite = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): PutMatterFavoriteRequest {
    const message = Object.create(basePutMatterFavoriteRequest) as PutMatterFavoriteRequest;
    if (object.matterPublishId) {
      message.matterPublishId = Number(object.matterPublishId);
    }
    if (object.favorite) {
      message.favorite = Boolean(object.favorite);
    }
    return message;
  },
  fromPartial(object: DeepPartial<PutMatterFavoriteRequest>): PutMatterFavoriteRequest {
    const message = Object.create(basePutMatterFavoriteRequest) as PutMatterFavoriteRequest;
    if (object.matterPublishId) {
      message.matterPublishId = object.matterPublishId;
    }
    if (object.favorite) {
      message.favorite = object.favorite;
    }
    return message;
  },
  toJSON(message: PutMatterFavoriteRequest): unknown {
    const obj: any = {};
    obj.matterPublishId = message.matterPublishId || 0;
    obj.favorite = message.favorite || false;
    return obj;
  },
};

export const PutMatterFavoriteResponse = {
  encode(message: PutMatterFavoriteResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): PutMatterFavoriteResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(basePutMatterFavoriteResponse) as PutMatterFavoriteResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): PutMatterFavoriteResponse {
    const message = Object.create(basePutMatterFavoriteResponse) as PutMatterFavoriteResponse;
    return message;
  },
  fromPartial(object: DeepPartial<PutMatterFavoriteResponse>): PutMatterFavoriteResponse {
    const message = Object.create(basePutMatterFavoriteResponse) as PutMatterFavoriteResponse;
    return message;
  },
  toJSON(message: PutMatterFavoriteResponse): unknown {
    const obj: any = {};
    return obj;
  },
};

export const GetMessageTemplatesRequest = {
  encode(message: GetMessageTemplatesRequest, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): GetMessageTemplatesRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetMessageTemplatesRequest) as GetMessageTemplatesRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetMessageTemplatesRequest {
    const message = Object.create(baseGetMessageTemplatesRequest) as GetMessageTemplatesRequest;
    return message;
  },
  fromPartial(object: DeepPartial<GetMessageTemplatesRequest>): GetMessageTemplatesRequest {
    const message = Object.create(baseGetMessageTemplatesRequest) as GetMessageTemplatesRequest;
    return message;
  },
  toJSON(message: GetMessageTemplatesRequest): unknown {
    const obj: any = {};
    return obj;
  },
};

export const GetMessageTemplatesResponse = {
  encode(message: GetMessageTemplatesResponse, writer: Writer = Writer.create()): Writer {
    for (const v of message.messageTemplates) {
      MessageTemplate.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): GetMessageTemplatesResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetMessageTemplatesResponse) as GetMessageTemplatesResponse;
    message.messageTemplates = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.messageTemplates.push(MessageTemplate.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetMessageTemplatesResponse {
    const message = Object.create(baseGetMessageTemplatesResponse) as GetMessageTemplatesResponse;
    message.messageTemplates = [];
    if (object.messageTemplates) {
      for (const e of object.messageTemplates) {
        message.messageTemplates.push(MessageTemplate.fromJSON(e));
      }
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetMessageTemplatesResponse>): GetMessageTemplatesResponse {
    const message = Object.create(baseGetMessageTemplatesResponse) as GetMessageTemplatesResponse;
    message.messageTemplates = [];
    if (object.messageTemplates) {
      for (const e of object.messageTemplates) {
        message.messageTemplates.push(MessageTemplate.fromPartial(e));
      }
    }
    return message;
  },
  toJSON(message: GetMessageTemplatesResponse): unknown {
    const obj: any = {};
    if (message.messageTemplates) {
      obj.messageTemplates = message.messageTemplates.map(e => e ? MessageTemplate.toJSON(e) : undefined);
    } else {
      obj.messageTemplates = [];
    }
    return obj;
  },
};

export const RegisterMessageTemplateRequest = {
  encode(message: RegisterMessageTemplateRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.name);
    writer.uint32(18).string(message.template);
    return writer;
  },
  decode(reader: Reader, length?: number): RegisterMessageTemplateRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseRegisterMessageTemplateRequest) as RegisterMessageTemplateRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.name = reader.string();
          break;
        case 2:
          message.template = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): RegisterMessageTemplateRequest {
    const message = Object.create(baseRegisterMessageTemplateRequest) as RegisterMessageTemplateRequest;
    if (object.name) {
      message.name = String(object.name);
    }
    if (object.template) {
      message.template = String(object.template);
    }
    return message;
  },
  fromPartial(object: DeepPartial<RegisterMessageTemplateRequest>): RegisterMessageTemplateRequest {
    const message = Object.create(baseRegisterMessageTemplateRequest) as RegisterMessageTemplateRequest;
    if (object.name) {
      message.name = object.name;
    }
    if (object.template) {
      message.template = object.template;
    }
    return message;
  },
  toJSON(message: RegisterMessageTemplateRequest): unknown {
    const obj: any = {};
    obj.name = message.name || "";
    obj.template = message.template || "";
    return obj;
  },
};

export const RegisterMessageTemplateResponse = {
  encode(message: RegisterMessageTemplateResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): RegisterMessageTemplateResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseRegisterMessageTemplateResponse) as RegisterMessageTemplateResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): RegisterMessageTemplateResponse {
    const message = Object.create(baseRegisterMessageTemplateResponse) as RegisterMessageTemplateResponse;
    return message;
  },
  fromPartial(object: DeepPartial<RegisterMessageTemplateResponse>): RegisterMessageTemplateResponse {
    const message = Object.create(baseRegisterMessageTemplateResponse) as RegisterMessageTemplateResponse;
    return message;
  },
  toJSON(message: RegisterMessageTemplateResponse): unknown {
    const obj: any = {};
    return obj;
  },
};

export const PutMessageTemplateRequest = {
  encode(message: PutMessageTemplateRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).uint64(message.messageTemplateId);
    writer.uint32(18).string(message.name);
    writer.uint32(26).string(message.template);
    return writer;
  },
  decode(reader: Reader, length?: number): PutMessageTemplateRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(basePutMessageTemplateRequest) as PutMessageTemplateRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.messageTemplateId = longToNumber(reader.uint64() as Long);
          break;
        case 2:
          message.name = reader.string();
          break;
        case 3:
          message.template = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): PutMessageTemplateRequest {
    const message = Object.create(basePutMessageTemplateRequest) as PutMessageTemplateRequest;
    if (object.messageTemplateId) {
      message.messageTemplateId = Number(object.messageTemplateId);
    }
    if (object.name) {
      message.name = String(object.name);
    }
    if (object.template) {
      message.template = String(object.template);
    }
    return message;
  },
  fromPartial(object: DeepPartial<PutMessageTemplateRequest>): PutMessageTemplateRequest {
    const message = Object.create(basePutMessageTemplateRequest) as PutMessageTemplateRequest;
    if (object.messageTemplateId) {
      message.messageTemplateId = object.messageTemplateId;
    }
    if (object.name) {
      message.name = object.name;
    }
    if (object.template) {
      message.template = object.template;
    }
    return message;
  },
  toJSON(message: PutMessageTemplateRequest): unknown {
    const obj: any = {};
    obj.messageTemplateId = message.messageTemplateId || 0;
    obj.name = message.name || "";
    obj.template = message.template || "";
    return obj;
  },
};

export const PutMessageTemplateResponse = {
  encode(message: PutMessageTemplateResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): PutMessageTemplateResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(basePutMessageTemplateResponse) as PutMessageTemplateResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): PutMessageTemplateResponse {
    const message = Object.create(basePutMessageTemplateResponse) as PutMessageTemplateResponse;
    return message;
  },
  fromPartial(object: DeepPartial<PutMessageTemplateResponse>): PutMessageTemplateResponse {
    const message = Object.create(basePutMessageTemplateResponse) as PutMessageTemplateResponse;
    return message;
  },
  toJSON(message: PutMessageTemplateResponse): unknown {
    const obj: any = {};
    return obj;
  },
};

export const DeleteMessageTemplatesRequest = {
  encode(message: DeleteMessageTemplatesRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).fork();
    for (const v of message.messageTemplateIds) {
      writer.uint64(v);
    }
    writer.ldelim();
    return writer;
  },
  decode(reader: Reader, length?: number): DeleteMessageTemplatesRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseDeleteMessageTemplatesRequest) as DeleteMessageTemplatesRequest;
    message.messageTemplateIds = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.messageTemplateIds.push(longToNumber(reader.uint64() as Long));
            }
          } else {
            message.messageTemplateIds.push(longToNumber(reader.uint64() as Long));
          }
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): DeleteMessageTemplatesRequest {
    const message = Object.create(baseDeleteMessageTemplatesRequest) as DeleteMessageTemplatesRequest;
    message.messageTemplateIds = [];
    if (object.messageTemplateIds) {
      for (const e of object.messageTemplateIds) {
        message.messageTemplateIds.push(Number(e));
      }
    }
    return message;
  },
  fromPartial(object: DeepPartial<DeleteMessageTemplatesRequest>): DeleteMessageTemplatesRequest {
    const message = Object.create(baseDeleteMessageTemplatesRequest) as DeleteMessageTemplatesRequest;
    message.messageTemplateIds = [];
    if (object.messageTemplateIds) {
      for (const e of object.messageTemplateIds) {
        message.messageTemplateIds.push(e);
      }
    }
    return message;
  },
  toJSON(message: DeleteMessageTemplatesRequest): unknown {
    const obj: any = {};
    if (message.messageTemplateIds) {
      obj.messageTemplateIds = message.messageTemplateIds.map(e => e || 0);
    } else {
      obj.messageTemplateIds = [];
    }
    return obj;
  },
};

export const DeleteMessageTemplatesResponse = {
  encode(message: DeleteMessageTemplatesResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): DeleteMessageTemplatesResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseDeleteMessageTemplatesResponse) as DeleteMessageTemplatesResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): DeleteMessageTemplatesResponse {
    const message = Object.create(baseDeleteMessageTemplatesResponse) as DeleteMessageTemplatesResponse;
    return message;
  },
  fromPartial(object: DeepPartial<DeleteMessageTemplatesResponse>): DeleteMessageTemplatesResponse {
    const message = Object.create(baseDeleteMessageTemplatesResponse) as DeleteMessageTemplatesResponse;
    return message;
  },
  toJSON(message: DeleteMessageTemplatesResponse): unknown {
    const obj: any = {};
    return obj;
  },
};

export const UseMessageTemplateRequest = {
  encode(message: UseMessageTemplateRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).uint64(message.messageTemplateId);
    writer.uint32(18).string(message.accountId);
    return writer;
  },
  decode(reader: Reader, length?: number): UseMessageTemplateRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseUseMessageTemplateRequest) as UseMessageTemplateRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.messageTemplateId = longToNumber(reader.uint64() as Long);
          break;
        case 2:
          message.accountId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): UseMessageTemplateRequest {
    const message = Object.create(baseUseMessageTemplateRequest) as UseMessageTemplateRequest;
    if (object.messageTemplateId) {
      message.messageTemplateId = Number(object.messageTemplateId);
    }
    if (object.accountId) {
      message.accountId = String(object.accountId);
    }
    return message;
  },
  fromPartial(object: DeepPartial<UseMessageTemplateRequest>): UseMessageTemplateRequest {
    const message = Object.create(baseUseMessageTemplateRequest) as UseMessageTemplateRequest;
    if (object.messageTemplateId) {
      message.messageTemplateId = object.messageTemplateId;
    }
    if (object.accountId) {
      message.accountId = object.accountId;
    }
    return message;
  },
  toJSON(message: UseMessageTemplateRequest): unknown {
    const obj: any = {};
    obj.messageTemplateId = message.messageTemplateId || 0;
    obj.accountId = message.accountId || "";
    return obj;
  },
};

export const UseMessageTemplateResponse = {
  encode(message: UseMessageTemplateResponse, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.message);
    return writer;
  },
  decode(reader: Reader, length?: number): UseMessageTemplateResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseUseMessageTemplateResponse) as UseMessageTemplateResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.message = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): UseMessageTemplateResponse {
    const message = Object.create(baseUseMessageTemplateResponse) as UseMessageTemplateResponse;
    if (object.message) {
      message.message = String(object.message);
    }
    return message;
  },
  fromPartial(object: DeepPartial<UseMessageTemplateResponse>): UseMessageTemplateResponse {
    const message = Object.create(baseUseMessageTemplateResponse) as UseMessageTemplateResponse;
    if (object.message) {
      message.message = object.message;
    }
    return message;
  },
  toJSON(message: UseMessageTemplateResponse): unknown {
    const obj: any = {};
    obj.message = message.message || "";
    return obj;
  },
};

type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T[P] extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T[P] extends Date | Function | Uint8Array | undefined
  ? T[P]
  : T[P] extends infer U | undefined
  ? DeepPartial<U>
  : T[P] extends object
  ? DeepPartial<T[P]>
  : T[P]
};