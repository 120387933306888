import { NO_SELECT_NUMBER } from 'constants/form';
import {
  ADDRESS_OVERSEAS_ID,
  END_YEAR_MONTH_CURRENTLY_ENROLLED_ID,
} from 'constants/profile';
import { getPhoneCountryCode } from 'utils/profile/fields';
import { ProfileFormItems, ProfileFormValues } from 'types/profile';

export const getPhoneCountryCodeValueToUpdate = (
  phoneCountryId: ProfileFormValues['phoneCountryId'],
  countries: ProfileFormItems['countries'],
) => getPhoneCountryCode(phoneCountryId, countries) ?? '';

export const getJapanPrefectureValueToUpdate = (
  japanPrefectureId: ProfileFormValues['japanPrefectureId'],
) =>
  japanPrefectureId === ADDRESS_OVERSEAS_ID ? undefined : japanPrefectureId;

export const getCareerJobValueToUpdate = (
  job: ProfileFormValues['career']['job'],
) =>
  job.map(jobHistory => ({
    ...jobHistory,
    endYear:
      jobHistory.endYear === END_YEAR_MONTH_CURRENTLY_ENROLLED_ID
        ? undefined
        : jobHistory.endYear,
    endMonth:
      jobHistory.endMonth === END_YEAR_MONTH_CURRENTLY_ENROLLED_ID
        ? undefined
        : jobHistory.endMonth,
  }));

export const getCareerMonthlyUnitPriceValueToUpdate = (
  monthlyUnitPrice: ProfileFormValues['career']['monthlyUnitPrice'],
) =>
  Number.isNaN(parseInt(monthlyUnitPrice, 10))
    ? undefined
    : parseInt(monthlyUnitPrice, 10);

export const getCareerSkillIdsValueToUpdate = (
  skills: ProfileFormValues['career']['skills'],
) => (skills ? skills.map(skill => skill.value) : []);

export const getPreferredWorkLocationsValueToUpdate = (
  preferredWorkLocationJapanPrefectureIds: ProfileFormValues['preferredWorkLocationJapanPrefectureIds'],
) =>
  preferredWorkLocationJapanPrefectureIds
    .filter(japanPrefectureId => japanPrefectureId !== NO_SELECT_NUMBER)
    .map(japanPrefectureId => ({
      japanPrefecture:
        japanPrefectureId !== ADDRESS_OVERSEAS_ID
          ? {
              japanPrefectureId,
              name: '',
            }
          : undefined,
    }));

export const getPreferredOccupationIdsValueToUpdate = (
  preferredOccupations: ProfileFormValues['preferredOccupations'],
) =>
  preferredOccupations
    .filter(occupation => occupation.id !== NO_SELECT_NUMBER)
    .map(occupation => occupation.id);

export const getCareerExpectationIdsValueToUpdate = (
  expectations: ProfileFormValues['career']['expectations'],
) => (expectations ? expectations.map(expectation => expectation.value) : []);

export const getOutputsValueToUpdate = (
  outputs: ProfileFormValues['outputs'],
) =>
  outputs
    ? outputs.filter(output => output.value).map(output => output.value)
    : [];

export const getCertificationsValueToUpdate = (
  certifications: ProfileFormValues['certifications'],
) =>
  certifications
    ? certifications
        .filter(certification => certification.value)
        .map(output => output.value)
    : [];

export const getNewGraduateResearchRoomsValueToUpdate = (
  researchRooms: ProfileFormValues['newGraduate']['researchRooms'],
) =>
  researchRooms
    ? researchRooms.filter(
        researchRoom => researchRoom.name || researchRoom.content,
      )
    : [];

export const getNewGraduateProgrammingExpericenceValueToUpdate = (
  programmingExperiences: ProfileFormValues['newGraduate']['programmingExperiences'],
) =>
  programmingExperiences
    ? programmingExperiences.filter(
        programmingExperience =>
          programmingExperience.languageName && programmingExperience.levelId,
      )
    : [];

export const getDttScoreToUpdate = (dttScore: ProfileFormValues['dttScore']) =>
  Number.isNaN(parseInt(dttScore, 10)) ? undefined : parseInt(dttScore, 10);

export const getDttRankIdToUpdate = (
  dttRankId: ProfileFormValues['dttRankId'],
) => (dttRankId === NO_SELECT_NUMBER ? undefined : dttRankId);

export const getNewGraduatePreferredWorkStyleIdToUpdate = (
  preferredWorkStyleId: ProfileFormValues['newGraduate']['preferredWorkStyleId'],
) =>
  preferredWorkStyleId === NO_SELECT_NUMBER ? undefined : preferredWorkStyleId;

export const getNewGraduatePreferredBusinessTypeIdsValueToUpdate = (
  preferredBusinessTypes: ProfileFormValues['newGraduate']['preferredBusinessTypes'],
) =>
  preferredBusinessTypes
    .filter(
      preferredBusinessType => preferredBusinessType.id !== NO_SELECT_NUMBER,
    )
    .map(preferredBusinessType => preferredBusinessType.id);

export const getNewGraduateInternshipExperiencesValueToUpdate = (
  internshipExperiences: ProfileFormValues['newGraduate']['internshipExperiences'],
) =>
  internshipExperiences
    ? internshipExperiences
        .filter(
          internshipExperience =>
            internshipExperience.companyName ||
            internshipExperience.startYear ||
            internshipExperience.startMonth ||
            internshipExperience.endYear ||
            internshipExperience.endYear,
        )
        .map(internshipExperience => ({
          companyName: internshipExperience.companyName,
          startYear:
            internshipExperience.startYear === NO_SELECT_NUMBER
              ? undefined
              : internshipExperience.startYear,
          startMonth:
            internshipExperience.startMonth === NO_SELECT_NUMBER
              ? undefined
              : internshipExperience.startMonth,
          endYear:
            internshipExperience.endYear === NO_SELECT_NUMBER
              ? undefined
              : internshipExperience.endYear,
          endMonth:
            internshipExperience.endMonth === NO_SELECT_NUMBER
              ? undefined
              : internshipExperience.endMonth,
        }))
    : [];

export const getNewGraduateStudyAbroadExperiencesValueToUpdate = (
  studyAbroadExperiences: ProfileFormValues['newGraduate']['studyAbroadExperiences'],
) =>
  studyAbroadExperiences
    ? studyAbroadExperiences
        .filter(
          studyAbroadExperience =>
            studyAbroadExperience.countryName ||
            studyAbroadExperience.startYear ||
            studyAbroadExperience.startMonth ||
            studyAbroadExperience.endYear ||
            studyAbroadExperience.endYear,
        )
        .map(internshipExperience => ({
          countryName: internshipExperience.countryName,
          startYear:
            internshipExperience.startYear === NO_SELECT_NUMBER
              ? undefined
              : internshipExperience.startYear,
          startMonth:
            internshipExperience.startMonth === NO_SELECT_NUMBER
              ? undefined
              : internshipExperience.startMonth,
          endYear:
            internshipExperience.endYear === NO_SELECT_NUMBER
              ? undefined
              : internshipExperience.endYear,
          endMonth:
            internshipExperience.endMonth === NO_SELECT_NUMBER
              ? undefined
              : internshipExperience.endMonth,
        }))
    : [];

export const getNewGraduateClubExperiencesValueToUpdate = (
  clubExperiences: ProfileFormValues['newGraduate']['clubExperiences'],
) =>
  clubExperiences
    ? clubExperiences.filter(
        clubExperience => clubExperience.name || clubExperience.content,
      )
    : [];
