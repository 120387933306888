export const range = (start: number, end: number) =>
  [...Array(end - start + 1).keys()].map((_, i) => start + i);

export const rangeRight = (start: number, end: number) =>
  [...Array(end - start + 1).keys()].map((_, i) => end - i);

export const isUnique = <T>(arr: T[]) => new Set(arr).size === arr.length;

export const uniqBy = <T>(arr: T[], key: keyof T) => [
  ...new Map(arr.map(v => [v[key], v])).values(),
];
