import React, { FC } from 'react';
import { useDropzone, DropzoneOptions } from 'react-dropzone';
import styled from 'styled-components/macro';
import theme from 'styles/theme';

const Wrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  padding: 12px;
  border: 1px dashed ${theme.borderDefault};
  border-radius: 3px;
  outline: none;
  background-color: ${theme.bgDropzone};
  color: ${theme.textBody};
  font-size: 12px;
  line-height: 1.5;

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
`;

type DropzoneProps = DropzoneOptions;

const Dropzone: FC<DropzoneProps> = ({ children, ...props }) => {
  const { getRootProps, getInputProps } = useDropzone(props);

  return (
    <Wrapper {...getRootProps()} data-testid="dropzone">
      <input {...getInputProps()} />
      {children}
    </Wrapper>
  );
};

export default Dropzone;
