import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { InnovatorChatMessage } from 'proto/v1/apimodel/apimodel';
import media from 'styles/media';
import { formatChatMessageTimestamp, formatFileSize } from 'utils/chat';
import { interpolateMessage } from 'utils/string';
import * as MESSAGES from 'constants/messages';
import { MessageDocument, MessageDownload } from 'assets/svg';
import theme from 'styles/theme';
import { chatMessageStyle } from 'styles/chat';
import useChatMessageMenu from 'hooks/chat/useChatMessageMenu';
import { LinkText } from 'components/atoms';
import { ChatMessageMenu } from 'components/organisms';
import useMediaQuery from 'hooks/useMediaQuery';

const Wrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
  padding-top: 40px;

  ${media.mobile} {
    padding-top: 32px;
    user-select: none;
  }
`;

const Message = styled.div`
  ${chatMessageStyle}
  border-radius: 18px 0 18px 18px;
  background: ${theme.basePrimary};
  color: ${theme.baseWhite};
`;

const FileIcon = styled(MessageDocument)`
  min-width: 22px;
  margin-right: 12px;
`;

const FileName = styled.div`
  margin-bottom: 4px;
`;

const FileSize = styled.div`
  font-size: 11px;
  line-height: 16px;
`;

const FileMessage = styled(Message)`
  display: flex;
  align-items: center;
`;

const DeletedMessage = styled.div`
  width: fit-content;
  margin-left: auto;
  padding: 3px 16px;
  border-radius: 18px;
  background-color: ${theme.baseDisabled};
  color: ${theme.baseWhite};
  font-size: 12px;
  line-height: 17px;
`;

const SentAt = styled.div`
  color: ${theme.textSecondary};
  font-size: 12px;
  line-height: 17px;
  text-align: right;
`;

const DownloadIcon = styled(MessageDownload)`
  min-width: 22px;
  margin-left: 18px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  path {
    fill: ${theme.baseWhite};
  }
`;

type Props = {
  chatMessage: InnovatorChatMessage;
  onDownloadFile: (chatMessage: InnovatorChatMessage) => Promise<void>;
  onClickDeleteMenu: (messageId: InnovatorChatMessage['messageId']) => void;
};

type RenderMessagePayload = Props & {
  showMenuIcon: boolean;
  showMenu: boolean;
  toggleMenu: () => void;
  onClickCopyMenu: () => void;
  handleClick?: () => void;
  handleMouseDown?: () => void;
  handleMouseEnter?: () => void;
  handleMouseLeave?: () => void;
  handleTouchStart?: () => void;
  handleTouchEnd?: () => void;
};

const renderMessage = ({
  chatMessage,
  onDownloadFile,
  toggleMenu,
  showMenu,
  showMenuIcon,
  onClickDeleteMenu,
  onClickCopyMenu,
  handleClick,
  handleMouseDown,
  handleMouseEnter,
  handleMouseLeave,
  handleTouchStart,
  handleTouchEnd,
}: RenderMessagePayload) => {
  if (chatMessage.deleted) {
    return (
      <DeletedMessage>{MESSAGES.MESSAGES.DELETED_CHAT_MESSAGE}</DeletedMessage>
    );
  }

  if (chatMessage.file) {
    return (
      <>
        <ChatMessageMenu
          chatMessage={chatMessage}
          showMenuIcon={showMenuIcon}
          showMenu={showMenu}
          toggleMenu={toggleMenu}
          onClickDeleteMenu={() => onClickDeleteMenu(chatMessage.messageId)}
          onClickCopyMenu={onClickCopyMenu}
        />
        <div>
          <FileMessage
            onClick={handleClick}
            onMouseDown={handleMouseDown}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
          >
            <FileIcon />
            <div>
              <FileName>{chatMessage.message}</FileName>
              <FileSize>
                {interpolateMessage(MESSAGES.MESSAGES.CHAT_MESSAGE_FILE_SIZE, {
                  fileSize: formatFileSize(chatMessage.file.size),
                })}
              </FileSize>
            </div>
            <DownloadIcon onClick={() => onDownloadFile(chatMessage)} />
          </FileMessage>
          <SentAt>{formatChatMessageTimestamp(chatMessage.sentAt)}</SentAt>
        </div>
      </>
    );
  }

  return (
    <>
      <ChatMessageMenu
        chatMessage={chatMessage}
        showMenuIcon={showMenuIcon}
        showMenu={showMenu}
        toggleMenu={toggleMenu}
        onClickDeleteMenu={() => onClickDeleteMenu(chatMessage.messageId)}
        onClickCopyMenu={onClickCopyMenu}
      />
      <div>
        <Message
          onClick={handleClick}
          onMouseDown={handleMouseDown}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
        >
          <LinkText>{chatMessage.message}</LinkText>
        </Message>
        <SentAt>{formatChatMessageTimestamp(chatMessage.sentAt)}</SentAt>
      </div>
    </>
  );
};

const OwnChatMessage: FC<Props> = props => {
  const {
    showMenu,
    showMenuIcon,
    toggleMenu,
    handleClickCopyMenu,
    handleClick,
    handleMouseEnter,
    handleMouseLeave,
    handleMouseDown,
    handleTouchStart,
    handleTouchEnd,
  } = useChatMessageMenu();
  const { isMobile } = useMediaQuery();

  if (isMobile) {
    return (
      <Wrapper>
        {renderMessage({
          ...props,
          showMenu,
          showMenuIcon,
          toggleMenu,
          onClickCopyMenu: handleClickCopyMenu,
          handleClick,
          handleMouseEnter,
          handleMouseLeave,
          handleMouseDown,
          handleTouchStart,
          handleTouchEnd,
        })}
      </Wrapper>
    );
  }

  return (
    <Wrapper
      data-testid="own-chat-message"
      onClick={handleClick}
      onMouseDown={handleMouseDown}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {renderMessage({
        ...props,
        showMenu,
        showMenuIcon,
        toggleMenu,
        onClickCopyMenu: handleClickCopyMenu,
      })}
    </Wrapper>
  );
};

export default OwnChatMessage;
