import styled from 'styled-components/macro';
import theme from 'styles/theme';

const PageTitle = styled.h2`
  position: relative;
  padding: 8.5px 28px;
  border-bottom: 1px solid ${theme.borderDefault};
  background-color: ${theme.bgMain};
  color: ${theme.textPrimary};
  font-size: 20px;
  font-weight: bold;
  line-height: 1.5;
`;

export default PageTitle;
