import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import App from './App';
import './polyfills';

const gtmId = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID;
const gtmAuth = process.env.REACT_APP_GOOGLE_TAG_MANAGER_AUTH || undefined;
const gtmPreview =
  process.env.REACT_APP_GOOGLE_TAG_MANAGER_PREVIEW || undefined;

if (gtmId) {
  TagManager.initialize({
    gtmId,
    auth: gtmAuth,
    preview: gtmPreview,
  });
}

const root = document.getElementById('root');
if (root) {
  ReactDOM.render(<App />, root);
}
