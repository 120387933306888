import React, { FC } from 'react';
import * as MESSAGES from 'constants/messages';
import { Label, ProfileField, ProfileContent } from 'components/atoms';
import { Innovator } from 'proto/v1/apimodel/apimodel';

type AcademicBackgroundDepartmentFieldProps = {
  academicBackground: Innovator['academicBackground'] | undefined;
};

const AcademicBackgroundDepartmentField: FC<AcademicBackgroundDepartmentFieldProps> = props => {
  const { academicBackground } = props;

  return (
    <ProfileField data-testid="academic-background-department-field">
      <Label>
        {MESSAGES.PROFILE.FIELD.ACADEMIC_BACKGROUND_DEPARTMENT.LABEL}
      </Label>
      <ProfileContent>{academicBackground?.department?.name}</ProfileContent>
    </ProfileField>
  );
};

export default AcademicBackgroundDepartmentField;
