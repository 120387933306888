import { css } from 'styled-components/macro';
import media from 'styles/media';

// eslint-disable-next-line import/prefer-default-export
export const chatMessageStyle = css`
  box-sizing: border-box;
  max-width: 770px;
  margin-bottom: 8px;
  padding: 12px 16px;
  font-size: 14px;
  line-height: 20px;
  word-wrap: break-word;
  word-break: break-all;
  white-space: pre-wrap;
  overflow-wrap: break-word;

  ${media.mobile} {
    max-width: 264px;
    margin-bottom: 4px;
  }

  ${media.miniMobile} {
    max-width: 209px;
  }
`;
