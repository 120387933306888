import { Method, AxiosError, Cancel } from 'axios';
import logger from 'external/logger';
import { ERROR_TYPE } from 'constants/error';
import { ApiError, ErrorType } from 'types/error';
import {
  isAxiosError,
  isTimeout,
  isNetworkError,
  isCanceledError,
  isClientError,
  isServerError,
} from 'utils/error';
import * as MESSAGES from 'constants/messages';

export default class RequestError extends Error {
  readonly status?: number;

  readonly method?: Method;

  readonly errorType?: ErrorType;

  readonly errorCode?: string;

  readonly locale?: string;

  constructor(error: AxiosError | Cancel) {
    super(RequestError._getErrorMessage(error));

    Object.setPrototypeOf(this, new.target.prototype);

    this.errorType = RequestError._getErrorType(error);

    if (!isAxiosError(error)) return;

    this.status = error?.response?.status;
    this.method = error?.config?.method;
    this.errorCode = error?.response?.data?.error?.errorCode;
    this.locale = error?.response?.data?.error?.locale;

    if (isServerError(error)) {
      logger.exception(error, {
        fingerprint: [
          '{{ default }}',
          error?.response?.config?.url ?? '',
          String(this.status),
          String(this.method),
        ],
      });
    }
  }

  private static _getErrorType(error: AxiosError | Cancel) {
    if (!isAxiosError(error)) {
      return isCanceledError(error)
        ? ERROR_TYPE.CANCELED_ERROR
        : ERROR_TYPE.UNEXPECTED_ERROR;
    }
    if (isTimeout(error)) return ERROR_TYPE.TIMEOUT;
    if (isNetworkError(error)) return ERROR_TYPE.NETWORK_ERROR;
    if (isClientError(error)) return ERROR_TYPE.CLIENT_ERROR;
    if (isServerError(error)) return ERROR_TYPE.SERVER_ERROR;
    if (isCanceledError(error)) return ERROR_TYPE.CANCELED_ERROR;

    return ERROR_TYPE.UNEXPECTED_ERROR;
  }

  private static _getErrorMessage(error: AxiosError | Cancel) {
    if (!isAxiosError(error)) return MESSAGES.ERROR.FAILED_UNEXPECTED;
    if (isTimeout(error)) return MESSAGES.ERROR.TIMED_OUT;
    if (isNetworkError(error)) return MESSAGES.ERROR.DISCONNECTED_NETWORK;
    if (isClientError(error)) {
      return (
        error?.response?.data?.error?.message ||
        MESSAGES.ERROR.FAILED_UNEXPECTED
      );
    }
    if (isServerError(error)) return MESSAGES.ERROR.FAILED_UNEXPECTED;

    return MESSAGES.ERROR.FAILED_UNEXPECTED;
  }

  public toObject(): ApiError {
    return {
      ...this,
      message: this.message,
    };
  }

  public isServerError() {
    return this.errorType === ERROR_TYPE.SERVER_ERROR;
  }

  public isClientError() {
    return this.errorType === ERROR_TYPE.CLIENT_ERROR;
  }

  public isNetworkError() {
    return this.errorType === ERROR_TYPE.NETWORK_ERROR;
  }

  public isUnexpectedError() {
    return this.errorType === ERROR_TYPE.UNEXPECTED_ERROR;
  }

  public isCanceled() {
    return this.errorType === ERROR_TYPE.CANCELED_ERROR;
  }

  public isTimeout() {
    return this.errorType === ERROR_TYPE.TIMEOUT;
  }
}
