import React, { FC } from 'react';
import styled from 'styled-components/macro';
import * as MESSAGES from 'constants/messages';
import { Label, ProfileField, ProfileContent } from 'components/atoms';
import { Innovator } from 'proto/v1/apimodel/apimodel';

const PhoneCountryCode = styled.span`
  margin-right: 4px;
`;

const PhoneNumber = styled.span``;

type PhoneNumberFieldProps = {
  phoneCountryCode: Innovator['phoneCountryCode'];
  phoneNumber: Innovator['phoneNumber'];
};

const PhoneNumberField: FC<PhoneNumberFieldProps> = props => {
  const { phoneCountryCode, phoneNumber } = props;

  return (
    <ProfileField data-testid="phone-number-field">
      <Label>{MESSAGES.PROFILE.FIELD.PHONE_NUMBER.LABEL}</Label>
      <ProfileContent>
        <PhoneCountryCode>+{phoneCountryCode}</PhoneCountryCode>
        <PhoneNumber>{phoneNumber}</PhoneNumber>
      </ProfileContent>
    </ProfileField>
  );
};

export default PhoneNumberField;
