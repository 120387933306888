import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import { getInnovator } from 'modules/account';
import media from 'styles/media';
import {
  NewGraduatePastExperienceAchievementsField,
  NewGraduateInternshipExperiencesField,
  NewGraduateStudyAbroadExperiencesField,
  NewGraduateClubExperiencesField,
  NewGraduateSelfIntroductionField,
} from 'components/organisms';

const Wrapper = styled.div`
  margin-bottom: -40px;

  ${media.mobile} {
    margin-bottom: -32px;
  }
`;

const NewGraduateProfileExperiencesContents: FC = () => {
  const innovator = useSelector(getInnovator);

  if (!innovator) return null;

  return (
    <Wrapper data-testid="new-graduate-profile-experiences-contents">
      <NewGraduatePastExperienceAchievementsField
        pastExperienceAchievements={
          innovator.newGraduate?.pastExperienceAchievements
        }
      />

      <NewGraduateInternshipExperiencesField
        internshipExperiences={innovator.newGraduate?.internshipExperiences}
      />

      <NewGraduateStudyAbroadExperiencesField
        studyAbroadExperiences={innovator.newGraduate?.studyAbroadExperiences}
      />

      <NewGraduateClubExperiencesField
        clubExperiences={innovator.newGraduate?.clubExperiences}
      />

      <NewGraduateSelfIntroductionField
        selfIntroduction={innovator.selfIntroduction}
      />
    </Wrapper>
  );
};

export default NewGraduateProfileExperiencesContents;
