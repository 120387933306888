import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import { getInnovator } from 'modules/account';
import { INNOVATOR_TYPE } from 'constants/innovator';
import media from 'styles/media';
import {
  CareerSkillsField,
  EnglishSkillField,
  CertificationsField,
  CareerAchievementField,
  OutputsField,
  PreferredWorkLocationsField,
  PreferredOccupationsField,
  CareerPreferredMonthlyUnitPriceField,
  CareerPreferredEmploymentTypeField,
  CareerExpectationsField,
  CareerResumeFileField,
  SelfIntroductionField,
} from 'components/organisms';

const Wrapper = styled.div`
  margin-bottom: -40px;

  ${media.mobile} {
    margin-bottom: -32px;
  }
`;

const ProfileSkillsAndPreferredConditionsCareerContents: FC = () => {
  const innovator = useSelector(getInnovator);

  if (!innovator) return null;

  return (
    <Wrapper data-testid="profile-skills-and-preferred-conditions-contents">
      <CareerSkillsField skills={innovator.career?.skills} />

      <EnglishSkillField englishSkill={innovator.englishSkill} />

      <CertificationsField certifications={innovator.certifications} />

      <CareerAchievementField achievement={innovator.career?.achievement} />

      {innovator?.innovatorType === INNOVATOR_TYPE.ENGINEER && (
        <OutputsField outputs={innovator.outputs} />
      )}

      <PreferredWorkLocationsField
        preferredWorkLocations={innovator.preferredWorkLocations}
      />

      <PreferredOccupationsField
        preferredOccupations={innovator.preferredOccupations}
      />

      <CareerPreferredMonthlyUnitPriceField
        preferredMonthlyUnitPrice={innovator.career?.preferredMonthlyUnitPrice}
      />

      <CareerPreferredEmploymentTypeField
        preferredEmploymentType={innovator.career?.preferredEmploymentType}
      />

      <CareerExpectationsField expectations={innovator.career?.expectations} />

      <CareerResumeFileField resumeFile={innovator.career?.resumeFile} />

      <SelfIntroductionField selfIntroduction={innovator.selfIntroduction} />
    </Wrapper>
  );
};

export default ProfileSkillsAndPreferredConditionsCareerContents;
