import React, { FC } from 'react';
import * as MESSAGES from 'constants/messages';
import { Label, ProfileField, ProfileContent } from 'components/atoms';
import { CareerProfile } from 'proto/v1/apimodel/apimodel';

type CareerSkillsFieldProps = {
  skills: CareerProfile['skills'] | undefined;
};

const CareerSkillsField: FC<CareerSkillsFieldProps> = props => {
  const { skills } = props;

  return (
    <ProfileField data-testid="skills-field">
      <Label>{MESSAGES.PROFILE.FIELD.SKILLS.LABEL}</Label>
      <ProfileContent>
        {skills ? skills.map(skill => skill.name).join('、') : '-'}
      </ProfileContent>
    </ProfileField>
  );
};

export default CareerSkillsField;
