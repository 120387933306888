const mediaQuery = (range: 'min' | 'max', width: number) =>
  `@media (${range}-width: ${width}px)`;

export default {
  largeDesktop: mediaQuery('max', 1440),
  desktop: mediaQuery('max', 1280),
  largeTablet: mediaQuery('max', 1025),
  tablet: mediaQuery('max', 1024),
  mobile: mediaQuery('max', 768),
  smallMobile: mediaQuery('max', 480),
  miniMobile: mediaQuery('max', 320),
};
