import { CancelToken } from 'axios';
import {
  RegisterSchoolRequest,
  RegisterSchoolResponse,
  GetSkillsRequest,
  GetSkillsResponse,
  RegisterSkillRequest,
  RegisterSkillResponse,
  RegisterExpectationRequest,
  RegisterExpectationResponse,
  GetExpectationsRequest,
  GetExpectationsResponse,
  GetExampleSkillsResponse,
  GetExampleExpectationsResponse,
  GetCompaniesRequest,
  GetCompaniesResponse,
  ChangePasswordRequest,
  ChangePasswordResponse,
  UploadFileRequest,
  UploadFileResponse,
  GetSchoolsRequest,
  GetSchoolsResponse,
  ClearNotificationRequest,
  ClearNotificationResponse,
} from 'proto/v1/commonservice/commonservice';
import client from 'external/api/config/client';
import apiFirebase from 'external/firebase/firebase';

export default {
  async getSchools(
    req: GetSchoolsRequest,
  ): Promise<{ data: GetSchoolsResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/search_schools`;
    return client(token)
      .post(url, req)
      .then(x => {
        return {
          data: GetSchoolsResponse.fromJSON(x.data),
        };
      });
  },

  async registerSchool(
    req: RegisterSchoolRequest,
  ): Promise<{ data: RegisterSchoolResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/school`;
    return client(token)
      .post(url, req)
      .then(x => {
        return {
          data: RegisterSchoolResponse.fromJSON(x.data),
        };
      });
  },

  async getSkills(req: GetSkillsRequest): Promise<{ data: GetSkillsResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/search_skills`;
    return client(token)
      .post(url, req)
      .then(x => {
        return {
          data: GetSkillsResponse.fromJSON(x.data),
        };
      });
  },

  async getExampleSkills(
    cancelToken: CancelToken,
  ): Promise<{ data: GetExampleSkillsResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/example_skills`;
    return client(token)
      .get(url, {
        cancelToken,
      })
      .then(x => {
        return {
          data: GetExampleSkillsResponse.fromJSON(x.data),
        };
      });
  },

  async registerSkill(
    req: RegisterSkillRequest,
  ): Promise<{ data: RegisterSkillResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/skills`;
    return client(token)
      .post(url, req)
      .then(x => {
        return {
          data: RegisterSkillResponse.fromJSON(x.data),
        };
      });
  },

  async getExpectations(
    req: GetExpectationsRequest,
  ): Promise<{ data: GetExpectationsResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/expectations`;
    return client(token)
      .get(url, {
        params: req,
      })
      .then(x => {
        return {
          data: GetExpectationsResponse.fromJSON(x.data),
        };
      });
  },

  async getExampleExpectations(
    cancelToken: CancelToken,
  ): Promise<{ data: GetExampleExpectationsResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/example_expectations`;
    return client(token)
      .get(url, {
        cancelToken,
      })
      .then(x => {
        return {
          data: GetExampleExpectationsResponse.fromJSON(x.data),
        };
      });
  },

  async registerExpectation(
    req: RegisterExpectationRequest,
  ): Promise<{ data: RegisterExpectationResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/expectations`;
    return client(token)
      .post(url, req)
      .then(x => {
        return {
          data: RegisterExpectationResponse.fromJSON(x.data),
        };
      });
  },

  async getCompanies(
    req: GetCompaniesRequest,
    cancelToken?: CancelToken,
  ): Promise<{ data: GetCompaniesResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/search_companies`;
    return client(token)
      .get(url, {
        params: req,
        cancelToken,
      })
      .then(x => {
        return {
          data: GetCompaniesResponse.fromJSON(x.data),
        };
      });
  },

  async changePassword(
    req: ChangePasswordRequest,
  ): Promise<{ data: ChangePasswordResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/password`;
    return client(token)
      .put(url, req)
      .then(x => {
        return {
          data: ChangePasswordResponse.fromJSON(x.data),
        };
      });
  },

  async uploadFile(
    req: UploadFileRequest,
  ): Promise<{ data: UploadFileResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/files`;
    return client(token)
      .post(url, UploadFileRequest.toJSON(req))
      .then(res => {
        return {
          data: UploadFileResponse.fromJSON(res.data),
        };
      });
  },

  async clearNotification(
    req: ClearNotificationRequest,
    cancelToken: CancelToken,
  ): Promise<{ data: ClearNotificationResponse }> {
    const url = `${process.env.REACT_APP_API_URL}/v1/notifications/clear`;
    const token = await apiFirebase.currentToken();
    return client(token)
      .post(url, ClearNotificationRequest.toJSON(req), { cancelToken })
      .then(res => {
        return {
          data: ClearNotificationResponse.fromJSON(res.data),
        };
      });
  },
};
