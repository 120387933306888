import React, { FC } from 'react';
import * as MESSAGES from 'constants/messages';
import { Lock } from 'assets/svg';
import { RequiredMark } from 'components/atoms';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  width: 100%;
`;

const Description = styled.p`
  display: flex;
  width: 100%;
  font-size: 12px;
  line-height: 1.5;
`;

const RequiredDescription = styled(Description)`
  margin-bottom: 4px;
`;

const RequiredIcon = styled(RequiredMark)`
  margin-right: 4px;
  margin-left: 8px;
`;

const LockedIcon = styled(Lock)`
  position: relative;
  bottom: 1px;
  left: 2px;
  min-width: 12px;
  margin-right: 7px;
`;

const FormFieldMarkDescription: FC = () => {
  return (
    <Wrapper>
      <RequiredDescription>
        <RequiredIcon />
        <span>{MESSAGES.COMMON.FORM.DESCRIPTION.REQUIRED_MARK}</span>
      </RequiredDescription>
      <Description>
        <LockedIcon />
        <span>{MESSAGES.COMMON.FORM.DESCRIPTION.LOCKED_MARK}</span>
      </Description>
    </Wrapper>
  );
};

export default FormFieldMarkDescription;
