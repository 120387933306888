export default {
  FIELD: {
    MEMBER_NUMBER: {
      LABEL: '会員番号',
    },
    NAME: {
      LABEL: '姓名',
    },
    LAST_NAME: {
      LABEL: '姓',
      PLACEHOLDER: '田中',
    },
    FIRST_NAME: {
      LABEL: '名',
      PLACEHOLDER: '一郎',
    },
    NAME_KANA: {
      LABEL: 'セイメイ',
    },
    LAST_NAME_KANA: {
      LABEL: 'セイ',
      PLACEHOLDER: 'タナカ',
    },
    FIRST_NAME_KANA: {
      LABEL: 'メイ',
      PLACEHOLDER: 'イチロウ',
    },
    YEAR_MONTH_OF_BIRTH: {
      LABEL: '生年月',
      YEAR: {
        LABEL: '年',
      },
      MONTH: {
        LABEL: '月',
      },
    },
    PHONE_NUMBER: {
      LABEL: '携帯電話番号',
      LABEL_NOTE: '（ハイフンなし）',
      PLACEHOLDER: '09012345678',
    },
    PHONE_COUNTRY_CODE: {
      LABEL: '国コード',
    },
    JAPAN_PREFECTURE: {
      LABEL: '現住所',
    },
    ACADEMIC_BACKGROUND_SCHOOL: {
      LABEL: '最終学歴',
      LABEL_NEW_GRADUATE: '所属大学',
      PLACEHOLDER: '〇〇〇〇大学',
    },
    ACADEMIC_BACKGROUND_FACULTY: {
      LABEL: '学部',
      LABEL_NEW_GRADUATE: '学部・研究科',
      PLACEHOLDER: '〇〇〇〇学部',
    },
    ACADEMIC_BACKGROUND_DEPARTMENT: {
      LABEL: '学科系統',
    },
    ACADEMIC_BACKGROUND_YEAR_MONTH_OF_GRADUATION: {
      LABEL: '卒業年月',
      LABEL_NEW_GRADUATE: '卒業予定年月',
      YEAR: {
        LABEL: '年',
      },
      MONTH: {
        LABEL: '月',
      },
    },
    CAREER_ANNUAL_INCOME: {
      LABEL: '直近の年収',
    },
    CAREER_MONTHLY_UNIT_PRICE: {
      LABEL: '直近の月額単価',
      LABEL_NOTE: '（業務委託の場合のみ）',
      UNIT: '万円',
    },
    CAREER_NUMBER_OF_JOB_CHANGES: {
      LABEL: '経験企業数',
    },
    CAREER_JOB: {
      LABEL: '職務経歴',
      HISTORY_LABEL: {
        MOST_RESENT: '直近の企業',
        EXPERIENCED_COMPANY: '経験企業',
      },
      NAME: {
        LABEL: '勤務先',
        NOTE: '※フリーランスの場合は、「フリーランス」と記入',
        PLACEHOLDER: '株式会社〇〇〇〇、またはフリーランス',
      },
      INFORMATION: '※直近の勤務先から入力してください',
      WORKING_PERIOD: {
        LABEL: '就業期間',
        CURRENTLY_ENROLLED: '在籍中',
        START_YEAR: {
          LABEL: '就業開始年',
        },
        START_MONTH: {
          LABEL: '就業開始月',
        },
        END_YEAR: {
          LABEL: '就業終了年',
        },
        END_MONTH: {
          LABEL: '就業終了月',
        },
      },
      OCCUPATIONS: {
        FIELD: {
          OCCUPATION: {
            LABEL: '経験職種',
            NOTE: '※直近の職種から入力（最大{{maxLength}}件）',
          },
          YEARS_OF_EXPERIENCE: {
            LABEL: '経験職種年数',
          },
        },
      },
      POSITION: {
        LABEL: '役職・マネジメント経験',
      },
    },
    SKILLS: {
      LABEL: '経験スキル',
      PLACEHOLDER: 'スキルを入力、または下記から選択してください',
      DESCRIPTION: {
        EXAMPLE: '例えばこんなスキル（クリックで登録）',
      },
      NOTE: '※最大{{maxLength}}件',
    },
    ENGLISH_SKILL: {
      LABEL: '英語力',
    },
    PREFERRED_WORK_LOCATIONS: {
      LABEL: '希望勤務地',
      NOTE: '※1つ以上選択してください',
      NO_SELECT: '希望勤務地',
    },
    CERTIFICATIONS: {
      LABEL: '保有資格',
      PLACEHOLDER: 'TOEIC 800、日商簿記2級 などの資格を記入（項目追加可能）',
    },
    CAREER_ACHIEVEMENT: {
      LABEL: '表彰・実績',
      PLACEHOLDER: '表彰経験、実績などを記入',
    },
    OUTPUTS: {
      LABEL: 'アウトプット',
      NOTE:
        '※見てもらいたいGitHub、Qiita、AtCoderなどのアカウントのURLをご記入ください',
      PLACEHOLDER: 'URL',
    },
    PREFERRED_OCCUPATIONS: {
      LABEL: '希望職種',
      NOTE: '※最大{{maxLength}}件',
    },
    PREFERRED_EMPLOYMENT_TYPE: {
      LABEL: '希望雇用形態',
    },
    CAREER_PREFERRED_MONTHLY_UNIT_PRICE: {
      LABEL: '希望月額単価',
      LABEL_NOTE: '（業務委託の場合のみ）',
    },
    EXPECTATIONS: {
      LABEL: '希望事項',
      NOTE: '※最大{{maxLength}}件',
      PLACEHOLDER: '希望事項を入力、または下記から選択してください',
      DESCRIPTION: {
        EXAMPLE: '例えばこんな希望事項 （クリックで登録）',
      },
    },
    CAREER_RESUME_FILE: {
      LABEL: '職務経歴書',
      LABEL_NOTE: '（任意）',
      BODY:
        'ここにPDF, Word, Excel 形式のデータを\nドラッグ＆ドロップしてください\nまたは、',
      LINK: 'ファイルを選択',
    },
    SELF_INTRODUCTION: {
      LABEL: '自己PR',
      PLACEHOLDER:
        '・どのような業務やプロジェクトに関わったか\n・その中でどのような成果を残したか\n・その経験からどんな学びや気付きがあったか\n・今後何をやっていきたいか（キャリア選択の基準も踏まえて）\n等を簡潔に記入してください',
      NOTE: '※{{minLength}}文字以上{{maxLength}}文字以内で入力してください',
      TEXT_UNIT: '文字',
    },
    NEW_GRADUATE_SELF_INTRODUCTION: {
      LABEL: '自己PR',
      FIELD_LABEL: '内容',
      PLACEHOLDER:
        '・あなたが学生時代に努力し、成し遂げたこと\n・その経験を通じて、あなたが学んだこと、気づいたこと\n・これからどのようなキャリアを歩みたいか\nこれらを参考にあなたの価値観も含めて自由に記述してください',
      NOTE: '※{{minLength}}文字以上{{maxLength}}文字以内で入力してください',
      TEXT_UNIT: '文字',
    },
    NEW_GRADUATE_RESEARCH_ROOMS: {
      LABEL: 'ゼミ・研究について',
      NAME: {
        LABEL: 'ゼミ・研究室名',
        PLACEHOLDER: '佐藤ゼミ',
      },
      CONTENT: {
        LABEL: '内容',
        PLACEHOLDER: 'イノベーション創発の研究',
      },
    },
    DTT: {
      LABEL: 'デザイン思考スコア / ランク',
      ABOUT_DTT: '※デザイン思考テストとは？',
      DTT_SCORE: {
        LABEL: 'デザイン思考スコア',
        PLACEHOLDER: '220',
      },
      DTT_RANK: {
        LABEL: 'デザイン思考ランク',
      },
    },
    NEW_GRADUATE_PROGRAMMING_EXPERIENCES: {
      LABEL: 'プログラミング経験',
      LANGUAGE_NAME: {
        LABEL: 'プログラミング言語',
        PLACEHOLDER: 'Javascript, Go, HTML などの言語を入力してください',
      },
      LEVEL: {
        LABEL: '経験レベル',
        NO_SELECT_TEXT: '経験レベルを選択してください',
      },
    },
    NEW_GRADUATE_PREFERRED_WORKING_STYLE: {
      LABEL: '希望する働き方',
    },
    NEW_GRADUATE_PREFERRED_BUSINESS_TYPES: {
      LABEL: '希望業種',
      NOTE: '※最大{{maxLength}}件',
    },
    NEW_GRADUATE_PAST_EXPERIENCE_ACHIEVEMENTS: {
      LABEL: 'これまでの経験・実績',
      NOTE: '(複数選択可)',
    },
    NEW_GRADUATE_INTERNSHIP_EXPERIENCES: {
      LABEL: 'インターンシップ',
      COMPANY_NAME: {
        LABEL: '企業名',
      },
      INTERNSHIP_PERIOD: {
        LABEL: '期間',
        START_YEAR: {
          LABEL: 'インターンシップ開始年',
        },
        START_MONTH: {
          LABEL: 'インターンシップ開始月',
        },
        END_YEAR: {
          LABEL: 'インターンシップ終了年',
        },
        END_MONTH: {
          LABEL: 'インターンシップ終了月',
        },
      },
    },
    NEW_GRADUATE_STUDY_ABROAD_EXPERIENCES: {
      LABEL: '留学経験',
      COUNTRY_NAME: {
        LABEL: '国名',
      },
      STUDY_ABROAD_PERIOD: {
        LABEL: '期間',
        START_YEAR: {
          LABEL: '留学開始年',
        },
        START_MONTH: {
          LABEL: '留学開始月',
        },
        END_YEAR: {
          LABEL: '留学終了年',
        },
        END_MONTH: {
          LABEL: '留学終了月',
        },
      },
    },
    NEW_GRADUATE_CLUB_EXPERIENCES: {
      LABEL: 'クラブ・サークル経験',
      NAME: {
        LABEL: 'クラブ・サークル名',
      },
      CONTENT: {
        LABEL: '内容',
      },
    },
  },
  OVERSEAS: '海外',
  NONE: 'なし',
  YEAR_MONTH: '{{year}}年{{month}}月',
  MODAL: {
    ABOUT_DTT_SCORE: {
      TITLE: 'デザイン思考スコアとは？',
      CONTENT1: 'デザイン思考スコアは「',
      CONTENT_DTT: 'デザイン思考テスト',
      CONTENT2: '」によって創造力を数値化したスコアです。',
      CONTENT3: 'アカウントをお持ちの方は、デザイン思考テストの',
      CONTENT_MY_PAGE: 'マイページ',
      CONTENT4: 'にてスコア及びランクをご確認ください。',
    },
  },
};
