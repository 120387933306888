import React, { FC } from 'react';
import * as MESSAGES from 'constants/messages';
import { Label, ProfileField, ProfileContent } from 'components/atoms';
import { Innovator } from 'proto/v1/apimodel/apimodel';

type DttRankFieldProps = {
  dttRank: Innovator['dttRank'];
};

const DttRankField: FC<DttRankFieldProps> = props => {
  const { dttRank } = props;

  return (
    <ProfileField data-testid="dtt-rank-field">
      <Label>{MESSAGES.PROFILE.FIELD.DTT.DTT_RANK.LABEL}</Label>
      <ProfileContent>{dttRank?.name ?? '-'}</ProfileContent>
    </ProfileField>
  );
};

export default DttRankField;
