import styled from 'styled-components/macro';

const Title = styled.p`
  margin-bottom: 24px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.5;
`;

export default Title;
