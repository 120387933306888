import styled from 'styled-components/macro';
import theme from 'styles/theme';

const SubTitle = styled.p`
  margin-bottom: 8px;
  color: ${theme.textSecondary};
  font-size: 14px;
  font-weight: bold;
  line-height: 1.5;
`;

export default SubTitle;
