import { Country } from 'proto/v1/apimodel/apimodel';
import { FieldErrors, FieldError } from 'react-hook-form';
import uniqBy from 'lodash.uniqby';
import { NO_SELECT_NUMBER } from 'constants/form';
import * as MESSAGES from 'constants/messages';
import { interpolateMessage } from 'utils/string';
import { CareerFormValue } from 'types/profile';

export const getPhoneCountryCode = (
  countryId: Country['countryId'],
  countries: Country[],
) =>
  countries.find(country => country.countryId === countryId)?.phoneCountryCode;

export const getCareerJobHistoryLabel = (index: number) => {
  if (index === 0) {
    return MESSAGES.PROFILE.FIELD.CAREER_JOB.HISTORY_LABEL.MOST_RESENT;
  }
  return `${
    MESSAGES.PROFILE.FIELD.CAREER_JOB.HISTORY_LABEL.EXPERIENCED_COMPANY
  }${index + 1}`;
};

export const isWorkingPeriodStartDateBeforeEndDate = (
  workingPeriods: Pick<
    CareerFormValue['job'][0],
    'startYear' | 'startMonth' | 'endYear' | 'endMonth'
  >,
) => {
  const { startYear, startMonth, endYear, endMonth } = workingPeriods;
  if (endYear === undefined || endMonth === undefined) {
    return true;
  }
  if ([startYear, startMonth, endYear, endMonth].includes(NO_SELECT_NUMBER)) {
    return true;
  }
  const startDate = new Date(startYear, startMonth);
  const endDate = new Date(endYear, endMonth);

  return startDate.getTime() < endDate.getTime();
};

export const getWorkingPeriodErrorMessage = (
  jobErrors: FieldErrors<CareerFormValue['job'][0]> | undefined,
  touched: {
    startYear: boolean | undefined;
    startMonth: boolean | undefined;
    endYear: boolean | undefined;
    endMonth: boolean | undefined;
  },
) => {
  const workingPeriodErrorTypes = uniqBy(
    [
      jobErrors?.startYear,
      jobErrors?.startMonth,
      jobErrors?.endYear,
      jobErrors?.endMonth,
    ],
    'type',
  ).map(error => error?.type);

  const isTouchedAtLeastOne =
    touched.startYear ||
    touched.startMonth ||
    touched.endYear ||
    touched.endMonth;

  const isTouchedAll =
    touched.startYear &&
    touched.startMonth &&
    touched.endYear &&
    touched.endMonth;

  if (
    isTouchedAtLeastOne &&
    workingPeriodErrorTypes.includes('invalidDateOrder')
  ) {
    return MESSAGES.ERROR.INVALID_WORKING_PERIOD;
  }

  if (isTouchedAll && workingPeriodErrorTypes.includes('min')) {
    return MESSAGES.ERROR.REQUIRED_SELECT;
  }

  return '';
};

export const isValidPeriodOrder = (period: {
  startYear: number;
  startMonth: number;
  endYear: number;
  endMonth: number;
}) => {
  const { startYear, startMonth, endYear, endMonth } = period;

  if ([startYear, startMonth, endYear, endMonth].includes(NO_SELECT_NUMBER)) {
    return true;
  }
  const startDate = new Date(startYear, startMonth);
  const endDate = new Date(endYear, endMonth);

  return startDate.getTime() <= endDate.getTime();
};

export const getPeriodErrorMessage = (
  errors: {
    startYear: FieldError | undefined;
    startMonth: FieldError | undefined;
    endYear: FieldError | undefined;
    endMonth: FieldError | undefined;
  },
  touched: {
    startYear: boolean | undefined;
    startMonth: boolean | undefined;
    endYear: boolean | undefined;
    endMonth: boolean | undefined;
  },
): string | undefined => {
  const periodErrorTypes = uniqBy(
    [errors?.startYear, errors?.startMonth, errors?.endYear, errors?.endMonth],
    'type',
  ).map(error => error?.type);

  const isTouchedAtLeastOne =
    touched.startYear ||
    touched.startMonth ||
    touched.endYear ||
    touched.endMonth;

  if (isTouchedAtLeastOne && periodErrorTypes.includes('invalidPeriodOrder')) {
    return MESSAGES.ERROR.INVALID_PERIOD_ORDER;
  }

  return undefined;
};

export const formatPeriods = (period: {
  startYear: number | undefined;
  startMonth: number | undefined;
  endYear: number | undefined;
  endMonth: number | undefined;
}) => {
  const { startYear, startMonth, endYear, endMonth } = period;
  if (!startYear || !startMonth || !endYear || !endMonth) {
    return '-';
  }
  const startYearMonth = interpolateMessage(MESSAGES.PROFILE.YEAR_MONTH, {
    year: startYear,
    month: startMonth,
  });
  const endYearMonth = interpolateMessage(MESSAGES.PROFILE.YEAR_MONTH, {
    year: endYear,
    month: endMonth,
  });
  return `${startYearMonth} 〜 ${endYearMonth}`;
};

export const formatPeriodsWithEmptyEndYearMonth = (period: {
  startYear: number | undefined;
  startMonth: number | undefined;
  endYear: number | undefined;
  endMonth: number | undefined;
}) => {
  const { startYear, startMonth, endYear, endMonth } = period;
  if (!startYear || !startMonth) {
    return '-';
  }
  const startYearMonth = interpolateMessage(MESSAGES.PROFILE.YEAR_MONTH, {
    year: startYear,
    month: startMonth,
  });
  const endYearMonth =
    endYear && endMonth
      ? interpolateMessage(MESSAGES.PROFILE.YEAR_MONTH, {
          year: endYear,
          month: endMonth,
        })
      : '';
  return `${startYearMonth} 〜 ${endYearMonth}`;
};
