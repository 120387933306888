import styled from 'styled-components/macro';
import media from 'styles/media';

const PublicTitle = styled.p`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  font-size: 40px;

  ${media.mobile} {
    margin-bottom: 12px;
    font-size: 28px;
  }
`;

export default PublicTitle;
