import { Images, Mission, Supporter, Innovator } from '../../../proto/v1/apimodel/apimodel';
import { Reader, Writer } from 'protobufjs/minimal';
import * as Long from 'long';
import { BoolValue } from '../../../proto/google/protobuf/wrappers';


export interface GetMyAccountsRequest {
}

export interface GetMyAccountsResponse {
  accounts: GetMyAccountsResponse_Account[];
}

export interface GetMyAccountsResponse_Account {
  accountId: string;
  missionId: string | undefined;
  supporterId: string | undefined;
  name: string;
  coverImage: Images | undefined;
  published: boolean;
}

export interface GetAccountSettingsRequest {
}

export interface GetAccountSettingsResponse {
  notificationDisabled: boolean;
  publishedMissionNotificationDisabled: boolean;
}

export interface GetActiveAccountRequest {
}

export interface GetActiveAccountResponse {
  accountId: string;
  mission: Mission | undefined;
  supporter: Supporter | undefined;
  published: boolean;
}

export interface RegisterInnovatorAccountRequest {
  email: string;
  password: string;
  innovatorType: string;
  query: { [key: string]: string };
}

export interface RegisterInnovatorAccountRequest_QueryEntry {
  key: string;
  value: string;
}

export interface RegisterInnovatorAccountResponse {
}

export interface GetInnovatorAccountRequest {
}

export interface GetInnovatorAccountResponse {
  accountId: string;
  innovator: Innovator | undefined;
  chatRoomId: string;
}

export interface PublishActiveAccountRequest {
  publish: boolean;
}

export interface PublishActiveAccountResponse {
}

export interface ChangeActiveAccountRequest {
  accountId: string;
}

export interface ChangeActiveAccountResponse {
  token: string;
}

export interface ChangeAccountSettingsRequest {
  notificationDisabled: boolean | undefined;
  publishedMissionNotificationDisabled: boolean | undefined;
}

export interface ChangeAccountSettingsResponse {
}

export interface ChangeUserSettingsRequest {
  email: Email | undefined;
}

export interface ChangeUserSettingsResponse {
}

export interface GetUserSettingsRequest {
}

export interface GetUserSettingsResponse {
  email: string;
}

export interface Email {
  value: string;
}

export interface RegisterMessagingDeviceTokenRequest {
  token: string;
}

export interface RegisterMessagingDeviceTokenResponse {
}

const baseGetMyAccountsRequest: object = {
};

const baseGetMyAccountsResponse: object = {
  accounts: undefined,
};

const baseGetMyAccountsResponse_Account: object = {
  accountId: "",
  name: "",
  coverImage: undefined,
  published: false,
};

const baseGetAccountSettingsRequest: object = {
};

const baseGetAccountSettingsResponse: object = {
  notificationDisabled: false,
  publishedMissionNotificationDisabled: false,
};

const baseGetActiveAccountRequest: object = {
};

const baseGetActiveAccountResponse: object = {
  accountId: "",
  published: false,
};

const baseRegisterInnovatorAccountRequest: object = {
  email: "",
  password: "",
  innovatorType: "",
  query: undefined,
};

const baseRegisterInnovatorAccountRequest_QueryEntry: object = {
  key: "",
  value: "",
};

const baseRegisterInnovatorAccountResponse: object = {
};

const baseGetInnovatorAccountRequest: object = {
};

const baseGetInnovatorAccountResponse: object = {
  accountId: "",
  innovator: undefined,
  chatRoomId: "",
};

const basePublishActiveAccountRequest: object = {
  publish: false,
};

const basePublishActiveAccountResponse: object = {
};

const baseChangeActiveAccountRequest: object = {
  accountId: "",
};

const baseChangeActiveAccountResponse: object = {
  token: "",
};

const baseChangeAccountSettingsRequest: object = {
  notificationDisabled: undefined,
  publishedMissionNotificationDisabled: undefined,
};

const baseChangeAccountSettingsResponse: object = {
};

const baseChangeUserSettingsRequest: object = {
  email: undefined,
};

const baseChangeUserSettingsResponse: object = {
};

const baseGetUserSettingsRequest: object = {
};

const baseGetUserSettingsResponse: object = {
  email: "",
};

const baseEmail: object = {
  value: "",
};

const baseRegisterMessagingDeviceTokenRequest: object = {
  token: "",
};

const baseRegisterMessagingDeviceTokenResponse: object = {
};

export interface AccountService {

  GetMyAccounts(request: GetMyAccountsRequest): Promise<GetMyAccountsResponse>;

  GetAccountSettings(request: GetAccountSettingsRequest): Promise<GetAccountSettingsResponse>;

  GetActiveAccount(request: GetActiveAccountRequest): Promise<GetActiveAccountResponse>;

  RegisterInnovatorAccount(request: RegisterInnovatorAccountRequest): Promise<RegisterInnovatorAccountResponse>;

  GetInnovatorAccount(request: GetInnovatorAccountRequest): Promise<GetInnovatorAccountResponse>;

  PublishActiveAccount(request: PublishActiveAccountRequest): Promise<PublishActiveAccountResponse>;

  ChangeActiveAccount(request: ChangeActiveAccountRequest): Promise<ChangeActiveAccountResponse>;

  ChangeAccountSettings(request: ChangeAccountSettingsRequest): Promise<ChangeAccountSettingsResponse>;

  ChangeUserSettings(request: ChangeUserSettingsRequest): Promise<ChangeUserSettingsResponse>;

  GetUserSettings(request: GetUserSettingsRequest): Promise<GetUserSettingsResponse>;

  RegisterMessagingDeviceToken(request: RegisterMessagingDeviceTokenRequest): Promise<RegisterMessagingDeviceTokenResponse>;

}

export class AccountServiceClientImpl implements AccountService {

  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
  }

  GetMyAccounts(request: GetMyAccountsRequest): Promise<GetMyAccountsResponse> {
    const data = GetMyAccountsRequest.encode(request).finish();
    const promise = this.rpc.request("accountservice.AccountService", "GetMyAccounts", data);
    return promise.then(data => GetMyAccountsResponse.decode(new Reader(data)));
  }

  GetAccountSettings(request: GetAccountSettingsRequest): Promise<GetAccountSettingsResponse> {
    const data = GetAccountSettingsRequest.encode(request).finish();
    const promise = this.rpc.request("accountservice.AccountService", "GetAccountSettings", data);
    return promise.then(data => GetAccountSettingsResponse.decode(new Reader(data)));
  }

  GetActiveAccount(request: GetActiveAccountRequest): Promise<GetActiveAccountResponse> {
    const data = GetActiveAccountRequest.encode(request).finish();
    const promise = this.rpc.request("accountservice.AccountService", "GetActiveAccount", data);
    return promise.then(data => GetActiveAccountResponse.decode(new Reader(data)));
  }

  RegisterInnovatorAccount(request: RegisterInnovatorAccountRequest): Promise<RegisterInnovatorAccountResponse> {
    const data = RegisterInnovatorAccountRequest.encode(request).finish();
    const promise = this.rpc.request("accountservice.AccountService", "RegisterInnovatorAccount", data);
    return promise.then(data => RegisterInnovatorAccountResponse.decode(new Reader(data)));
  }

  GetInnovatorAccount(request: GetInnovatorAccountRequest): Promise<GetInnovatorAccountResponse> {
    const data = GetInnovatorAccountRequest.encode(request).finish();
    const promise = this.rpc.request("accountservice.AccountService", "GetInnovatorAccount", data);
    return promise.then(data => GetInnovatorAccountResponse.decode(new Reader(data)));
  }

  PublishActiveAccount(request: PublishActiveAccountRequest): Promise<PublishActiveAccountResponse> {
    const data = PublishActiveAccountRequest.encode(request).finish();
    const promise = this.rpc.request("accountservice.AccountService", "PublishActiveAccount", data);
    return promise.then(data => PublishActiveAccountResponse.decode(new Reader(data)));
  }

  ChangeActiveAccount(request: ChangeActiveAccountRequest): Promise<ChangeActiveAccountResponse> {
    const data = ChangeActiveAccountRequest.encode(request).finish();
    const promise = this.rpc.request("accountservice.AccountService", "ChangeActiveAccount", data);
    return promise.then(data => ChangeActiveAccountResponse.decode(new Reader(data)));
  }

  ChangeAccountSettings(request: ChangeAccountSettingsRequest): Promise<ChangeAccountSettingsResponse> {
    const data = ChangeAccountSettingsRequest.encode(request).finish();
    const promise = this.rpc.request("accountservice.AccountService", "ChangeAccountSettings", data);
    return promise.then(data => ChangeAccountSettingsResponse.decode(new Reader(data)));
  }

  ChangeUserSettings(request: ChangeUserSettingsRequest): Promise<ChangeUserSettingsResponse> {
    const data = ChangeUserSettingsRequest.encode(request).finish();
    const promise = this.rpc.request("accountservice.AccountService", "ChangeUserSettings", data);
    return promise.then(data => ChangeUserSettingsResponse.decode(new Reader(data)));
  }

  GetUserSettings(request: GetUserSettingsRequest): Promise<GetUserSettingsResponse> {
    const data = GetUserSettingsRequest.encode(request).finish();
    const promise = this.rpc.request("accountservice.AccountService", "GetUserSettings", data);
    return promise.then(data => GetUserSettingsResponse.decode(new Reader(data)));
  }

  RegisterMessagingDeviceToken(request: RegisterMessagingDeviceTokenRequest): Promise<RegisterMessagingDeviceTokenResponse> {
    const data = RegisterMessagingDeviceTokenRequest.encode(request).finish();
    const promise = this.rpc.request("accountservice.AccountService", "RegisterMessagingDeviceToken", data);
    return promise.then(data => RegisterMessagingDeviceTokenResponse.decode(new Reader(data)));
  }

}

interface Rpc {

  request(service: string, method: string, data: Uint8Array): Promise<Uint8Array>;

}

function longToNumber(long: Long) {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new global.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

export const GetMyAccountsRequest = {
  encode(message: GetMyAccountsRequest, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): GetMyAccountsRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetMyAccountsRequest) as GetMyAccountsRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetMyAccountsRequest {
    const message = Object.create(baseGetMyAccountsRequest) as GetMyAccountsRequest;
    return message;
  },
  fromPartial(object: DeepPartial<GetMyAccountsRequest>): GetMyAccountsRequest {
    const message = Object.create(baseGetMyAccountsRequest) as GetMyAccountsRequest;
    return message;
  },
  toJSON(message: GetMyAccountsRequest): unknown {
    const obj: any = {};
    return obj;
  },
};

export const GetMyAccountsResponse = {
  encode(message: GetMyAccountsResponse, writer: Writer = Writer.create()): Writer {
    for (const v of message.accounts) {
      GetMyAccountsResponse_Account.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): GetMyAccountsResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetMyAccountsResponse) as GetMyAccountsResponse;
    message.accounts = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.accounts.push(GetMyAccountsResponse_Account.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetMyAccountsResponse {
    const message = Object.create(baseGetMyAccountsResponse) as GetMyAccountsResponse;
    message.accounts = [];
    if (object.accounts) {
      for (const e of object.accounts) {
        message.accounts.push(GetMyAccountsResponse_Account.fromJSON(e));
      }
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetMyAccountsResponse>): GetMyAccountsResponse {
    const message = Object.create(baseGetMyAccountsResponse) as GetMyAccountsResponse;
    message.accounts = [];
    if (object.accounts) {
      for (const e of object.accounts) {
        message.accounts.push(GetMyAccountsResponse_Account.fromPartial(e));
      }
    }
    return message;
  },
  toJSON(message: GetMyAccountsResponse): unknown {
    const obj: any = {};
    if (message.accounts) {
      obj.accounts = message.accounts.map(e => e ? GetMyAccountsResponse_Account.toJSON(e) : undefined);
    } else {
      obj.accounts = [];
    }
    return obj;
  },
};

export const GetMyAccountsResponse_Account = {
  encode(message: GetMyAccountsResponse_Account, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.accountId);
    if (message.missionId !== undefined && message.missionId !== "") {
      writer.uint32(18).string(message.missionId);
    }
    if (message.supporterId !== undefined && message.supporterId !== "") {
      writer.uint32(26).string(message.supporterId);
    }
    writer.uint32(34).string(message.name);
    if (message.coverImage !== undefined && message.coverImage !== undefined) {
      Images.encode(message.coverImage, writer.uint32(42).fork()).ldelim();
    }
    writer.uint32(48).bool(message.published);
    return writer;
  },
  decode(reader: Reader, length?: number): GetMyAccountsResponse_Account {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetMyAccountsResponse_Account) as GetMyAccountsResponse_Account;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.accountId = reader.string();
          break;
        case 2:
          message.missionId = reader.string();
          break;
        case 3:
          message.supporterId = reader.string();
          break;
        case 4:
          message.name = reader.string();
          break;
        case 5:
          message.coverImage = Images.decode(reader, reader.uint32());
          break;
        case 6:
          message.published = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetMyAccountsResponse_Account {
    const message = Object.create(baseGetMyAccountsResponse_Account) as GetMyAccountsResponse_Account;
    if (object.accountId) {
      message.accountId = String(object.accountId);
    }
    if (object.missionId) {
      message.missionId = String(object.missionId);
    }
    if (object.supporterId) {
      message.supporterId = String(object.supporterId);
    }
    if (object.name) {
      message.name = String(object.name);
    }
    if (object.coverImage) {
      message.coverImage = Images.fromJSON(object.coverImage);
    }
    if (object.published) {
      message.published = Boolean(object.published);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetMyAccountsResponse_Account>): GetMyAccountsResponse_Account {
    const message = Object.create(baseGetMyAccountsResponse_Account) as GetMyAccountsResponse_Account;
    if (object.accountId) {
      message.accountId = object.accountId;
    }
    if (object.missionId) {
      message.missionId = object.missionId;
    }
    if (object.supporterId) {
      message.supporterId = object.supporterId;
    }
    if (object.name) {
      message.name = object.name;
    }
    if (object.coverImage) {
      message.coverImage = Images.fromPartial(object.coverImage);
    }
    if (object.published) {
      message.published = object.published;
    }
    return message;
  },
  toJSON(message: GetMyAccountsResponse_Account): unknown {
    const obj: any = {};
    obj.accountId = message.accountId || "";
    obj.missionId = message.missionId || "";
    obj.supporterId = message.supporterId || "";
    obj.name = message.name || "";
    obj.coverImage = message.coverImage ? Images.toJSON(message.coverImage) : undefined;
    obj.published = message.published || false;
    return obj;
  },
};

export const GetAccountSettingsRequest = {
  encode(message: GetAccountSettingsRequest, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): GetAccountSettingsRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetAccountSettingsRequest) as GetAccountSettingsRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetAccountSettingsRequest {
    const message = Object.create(baseGetAccountSettingsRequest) as GetAccountSettingsRequest;
    return message;
  },
  fromPartial(object: DeepPartial<GetAccountSettingsRequest>): GetAccountSettingsRequest {
    const message = Object.create(baseGetAccountSettingsRequest) as GetAccountSettingsRequest;
    return message;
  },
  toJSON(message: GetAccountSettingsRequest): unknown {
    const obj: any = {};
    return obj;
  },
};

export const GetAccountSettingsResponse = {
  encode(message: GetAccountSettingsResponse, writer: Writer = Writer.create()): Writer {
    writer.uint32(16).bool(message.notificationDisabled);
    writer.uint32(24).bool(message.publishedMissionNotificationDisabled);
    return writer;
  },
  decode(reader: Reader, length?: number): GetAccountSettingsResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetAccountSettingsResponse) as GetAccountSettingsResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          message.notificationDisabled = reader.bool();
          break;
        case 3:
          message.publishedMissionNotificationDisabled = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetAccountSettingsResponse {
    const message = Object.create(baseGetAccountSettingsResponse) as GetAccountSettingsResponse;
    if (object.notificationDisabled) {
      message.notificationDisabled = Boolean(object.notificationDisabled);
    }
    if (object.publishedMissionNotificationDisabled) {
      message.publishedMissionNotificationDisabled = Boolean(object.publishedMissionNotificationDisabled);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetAccountSettingsResponse>): GetAccountSettingsResponse {
    const message = Object.create(baseGetAccountSettingsResponse) as GetAccountSettingsResponse;
    if (object.notificationDisabled) {
      message.notificationDisabled = object.notificationDisabled;
    }
    if (object.publishedMissionNotificationDisabled) {
      message.publishedMissionNotificationDisabled = object.publishedMissionNotificationDisabled;
    }
    return message;
  },
  toJSON(message: GetAccountSettingsResponse): unknown {
    const obj: any = {};
    obj.notificationDisabled = message.notificationDisabled || false;
    obj.publishedMissionNotificationDisabled = message.publishedMissionNotificationDisabled || false;
    return obj;
  },
};

export const GetActiveAccountRequest = {
  encode(message: GetActiveAccountRequest, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): GetActiveAccountRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetActiveAccountRequest) as GetActiveAccountRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetActiveAccountRequest {
    const message = Object.create(baseGetActiveAccountRequest) as GetActiveAccountRequest;
    return message;
  },
  fromPartial(object: DeepPartial<GetActiveAccountRequest>): GetActiveAccountRequest {
    const message = Object.create(baseGetActiveAccountRequest) as GetActiveAccountRequest;
    return message;
  },
  toJSON(message: GetActiveAccountRequest): unknown {
    const obj: any = {};
    return obj;
  },
};

export const GetActiveAccountResponse = {
  encode(message: GetActiveAccountResponse, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.accountId);
    if (message.mission !== undefined && message.mission !== undefined) {
      Mission.encode(message.mission, writer.uint32(18).fork()).ldelim();
    }
    if (message.supporter !== undefined && message.supporter !== undefined) {
      Supporter.encode(message.supporter, writer.uint32(26).fork()).ldelim();
    }
    writer.uint32(32).bool(message.published);
    return writer;
  },
  decode(reader: Reader, length?: number): GetActiveAccountResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetActiveAccountResponse) as GetActiveAccountResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.accountId = reader.string();
          break;
        case 2:
          message.mission = Mission.decode(reader, reader.uint32());
          break;
        case 3:
          message.supporter = Supporter.decode(reader, reader.uint32());
          break;
        case 4:
          message.published = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetActiveAccountResponse {
    const message = Object.create(baseGetActiveAccountResponse) as GetActiveAccountResponse;
    if (object.accountId) {
      message.accountId = String(object.accountId);
    }
    if (object.mission) {
      message.mission = Mission.fromJSON(object.mission);
    }
    if (object.supporter) {
      message.supporter = Supporter.fromJSON(object.supporter);
    }
    if (object.published) {
      message.published = Boolean(object.published);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetActiveAccountResponse>): GetActiveAccountResponse {
    const message = Object.create(baseGetActiveAccountResponse) as GetActiveAccountResponse;
    if (object.accountId) {
      message.accountId = object.accountId;
    }
    if (object.mission) {
      message.mission = Mission.fromPartial(object.mission);
    }
    if (object.supporter) {
      message.supporter = Supporter.fromPartial(object.supporter);
    }
    if (object.published) {
      message.published = object.published;
    }
    return message;
  },
  toJSON(message: GetActiveAccountResponse): unknown {
    const obj: any = {};
    obj.accountId = message.accountId || "";
    obj.mission = message.mission ? Mission.toJSON(message.mission) : undefined;
    obj.supporter = message.supporter ? Supporter.toJSON(message.supporter) : undefined;
    obj.published = message.published || false;
    return obj;
  },
};

export const RegisterInnovatorAccountRequest = {
  encode(message: RegisterInnovatorAccountRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.email);
    writer.uint32(18).string(message.password);
    writer.uint32(26).string(message.innovatorType);
    Object.entries(message.query).forEach(([key, value]) => {
      RegisterInnovatorAccountRequest_QueryEntry.encode({ key: key as any, value }, writer.uint32(34).fork()).ldelim();
    })
    return writer;
  },
  decode(reader: Reader, length?: number): RegisterInnovatorAccountRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseRegisterInnovatorAccountRequest) as RegisterInnovatorAccountRequest;
    message.query = {};
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.email = reader.string();
          break;
        case 2:
          message.password = reader.string();
          break;
        case 3:
          message.innovatorType = reader.string();
          break;
        case 4:
          const entry = RegisterInnovatorAccountRequest_QueryEntry.decode(reader, reader.uint32());
          if (entry.value) {
            message.query[entry.key] = entry.value;
          }
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): RegisterInnovatorAccountRequest {
    const message = Object.create(baseRegisterInnovatorAccountRequest) as RegisterInnovatorAccountRequest;
    message.query = {};
    if (object.email) {
      message.email = String(object.email);
    }
    if (object.password) {
      message.password = String(object.password);
    }
    if (object.innovatorType) {
      message.innovatorType = String(object.innovatorType);
    }
    if (object.query) {
      const entry = RegisterInnovatorAccountRequest_QueryEntry.fromJSON(object.query);
      if (entry.value) {
        message.query[entry.key] = entry.value;
      }
    }
    return message;
  },
  fromPartial(object: DeepPartial<RegisterInnovatorAccountRequest>): RegisterInnovatorAccountRequest {
    const message = Object.create(baseRegisterInnovatorAccountRequest) as RegisterInnovatorAccountRequest;
    message.query = {};
    if (object.email) {
      message.email = object.email;
    }
    if (object.password) {
      message.password = object.password;
    }
    if (object.innovatorType) {
      message.innovatorType = object.innovatorType;
    }
    if (object.query) {
      const entry = RegisterInnovatorAccountRequest_QueryEntry.fromPartial(object.query);
      if (entry.value) {
        message.query[entry.key] = entry.value;
      }
    }
    return message;
  },
  toJSON(message: RegisterInnovatorAccountRequest): unknown {
    const obj: any = {};
    obj.email = message.email || "";
    obj.password = message.password || "";
    obj.innovatorType = message.innovatorType || "";
    obj.query = message.query || undefined;
    return obj;
  },
};

export const RegisterInnovatorAccountRequest_QueryEntry = {
  encode(message: RegisterInnovatorAccountRequest_QueryEntry, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.key);
    writer.uint32(18).string(message.value);
    return writer;
  },
  decode(reader: Reader, length?: number): RegisterInnovatorAccountRequest_QueryEntry {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseRegisterInnovatorAccountRequest_QueryEntry) as RegisterInnovatorAccountRequest_QueryEntry;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.key = reader.string();
          break;
        case 2:
          message.value = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): RegisterInnovatorAccountRequest_QueryEntry {
    const message = Object.create(baseRegisterInnovatorAccountRequest_QueryEntry) as RegisterInnovatorAccountRequest_QueryEntry;
    if (object.key) {
      message.key = String(object.key);
    }
    if (object.value) {
      message.value = String(object.value);
    }
    return message;
  },
  fromPartial(object: DeepPartial<RegisterInnovatorAccountRequest_QueryEntry>): RegisterInnovatorAccountRequest_QueryEntry {
    const message = Object.create(baseRegisterInnovatorAccountRequest_QueryEntry) as RegisterInnovatorAccountRequest_QueryEntry;
    if (object.key) {
      message.key = object.key;
    }
    if (object.value) {
      message.value = object.value;
    }
    return message;
  },
  toJSON(message: RegisterInnovatorAccountRequest_QueryEntry): unknown {
    const obj: any = {};
    obj.key = message.key || "";
    obj.value = message.value || "";
    return obj;
  },
};

export const RegisterInnovatorAccountResponse = {
  encode(message: RegisterInnovatorAccountResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): RegisterInnovatorAccountResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseRegisterInnovatorAccountResponse) as RegisterInnovatorAccountResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): RegisterInnovatorAccountResponse {
    const message = Object.create(baseRegisterInnovatorAccountResponse) as RegisterInnovatorAccountResponse;
    return message;
  },
  fromPartial(object: DeepPartial<RegisterInnovatorAccountResponse>): RegisterInnovatorAccountResponse {
    const message = Object.create(baseRegisterInnovatorAccountResponse) as RegisterInnovatorAccountResponse;
    return message;
  },
  toJSON(message: RegisterInnovatorAccountResponse): unknown {
    const obj: any = {};
    return obj;
  },
};

export const GetInnovatorAccountRequest = {
  encode(message: GetInnovatorAccountRequest, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): GetInnovatorAccountRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetInnovatorAccountRequest) as GetInnovatorAccountRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetInnovatorAccountRequest {
    const message = Object.create(baseGetInnovatorAccountRequest) as GetInnovatorAccountRequest;
    return message;
  },
  fromPartial(object: DeepPartial<GetInnovatorAccountRequest>): GetInnovatorAccountRequest {
    const message = Object.create(baseGetInnovatorAccountRequest) as GetInnovatorAccountRequest;
    return message;
  },
  toJSON(message: GetInnovatorAccountRequest): unknown {
    const obj: any = {};
    return obj;
  },
};

export const GetInnovatorAccountResponse = {
  encode(message: GetInnovatorAccountResponse, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.accountId);
    if (message.innovator !== undefined && message.innovator !== undefined) {
      Innovator.encode(message.innovator, writer.uint32(18).fork()).ldelim();
    }
    writer.uint32(26).string(message.chatRoomId);
    return writer;
  },
  decode(reader: Reader, length?: number): GetInnovatorAccountResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetInnovatorAccountResponse) as GetInnovatorAccountResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.accountId = reader.string();
          break;
        case 2:
          message.innovator = Innovator.decode(reader, reader.uint32());
          break;
        case 3:
          message.chatRoomId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetInnovatorAccountResponse {
    const message = Object.create(baseGetInnovatorAccountResponse) as GetInnovatorAccountResponse;
    if (object.accountId) {
      message.accountId = String(object.accountId);
    }
    if (object.innovator) {
      message.innovator = Innovator.fromJSON(object.innovator);
    }
    if (object.chatRoomId) {
      message.chatRoomId = String(object.chatRoomId);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetInnovatorAccountResponse>): GetInnovatorAccountResponse {
    const message = Object.create(baseGetInnovatorAccountResponse) as GetInnovatorAccountResponse;
    if (object.accountId) {
      message.accountId = object.accountId;
    }
    if (object.innovator) {
      message.innovator = Innovator.fromPartial(object.innovator);
    }
    if (object.chatRoomId) {
      message.chatRoomId = object.chatRoomId;
    }
    return message;
  },
  toJSON(message: GetInnovatorAccountResponse): unknown {
    const obj: any = {};
    obj.accountId = message.accountId || "";
    obj.innovator = message.innovator ? Innovator.toJSON(message.innovator) : undefined;
    obj.chatRoomId = message.chatRoomId || "";
    return obj;
  },
};

export const PublishActiveAccountRequest = {
  encode(message: PublishActiveAccountRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).bool(message.publish);
    return writer;
  },
  decode(reader: Reader, length?: number): PublishActiveAccountRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(basePublishActiveAccountRequest) as PublishActiveAccountRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.publish = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): PublishActiveAccountRequest {
    const message = Object.create(basePublishActiveAccountRequest) as PublishActiveAccountRequest;
    if (object.publish) {
      message.publish = Boolean(object.publish);
    }
    return message;
  },
  fromPartial(object: DeepPartial<PublishActiveAccountRequest>): PublishActiveAccountRequest {
    const message = Object.create(basePublishActiveAccountRequest) as PublishActiveAccountRequest;
    if (object.publish) {
      message.publish = object.publish;
    }
    return message;
  },
  toJSON(message: PublishActiveAccountRequest): unknown {
    const obj: any = {};
    obj.publish = message.publish || false;
    return obj;
  },
};

export const PublishActiveAccountResponse = {
  encode(message: PublishActiveAccountResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): PublishActiveAccountResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(basePublishActiveAccountResponse) as PublishActiveAccountResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): PublishActiveAccountResponse {
    const message = Object.create(basePublishActiveAccountResponse) as PublishActiveAccountResponse;
    return message;
  },
  fromPartial(object: DeepPartial<PublishActiveAccountResponse>): PublishActiveAccountResponse {
    const message = Object.create(basePublishActiveAccountResponse) as PublishActiveAccountResponse;
    return message;
  },
  toJSON(message: PublishActiveAccountResponse): unknown {
    const obj: any = {};
    return obj;
  },
};

export const ChangeActiveAccountRequest = {
  encode(message: ChangeActiveAccountRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.accountId);
    return writer;
  },
  decode(reader: Reader, length?: number): ChangeActiveAccountRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseChangeActiveAccountRequest) as ChangeActiveAccountRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.accountId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): ChangeActiveAccountRequest {
    const message = Object.create(baseChangeActiveAccountRequest) as ChangeActiveAccountRequest;
    if (object.accountId) {
      message.accountId = String(object.accountId);
    }
    return message;
  },
  fromPartial(object: DeepPartial<ChangeActiveAccountRequest>): ChangeActiveAccountRequest {
    const message = Object.create(baseChangeActiveAccountRequest) as ChangeActiveAccountRequest;
    if (object.accountId) {
      message.accountId = object.accountId;
    }
    return message;
  },
  toJSON(message: ChangeActiveAccountRequest): unknown {
    const obj: any = {};
    obj.accountId = message.accountId || "";
    return obj;
  },
};

export const ChangeActiveAccountResponse = {
  encode(message: ChangeActiveAccountResponse, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.token);
    return writer;
  },
  decode(reader: Reader, length?: number): ChangeActiveAccountResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseChangeActiveAccountResponse) as ChangeActiveAccountResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.token = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): ChangeActiveAccountResponse {
    const message = Object.create(baseChangeActiveAccountResponse) as ChangeActiveAccountResponse;
    if (object.token) {
      message.token = String(object.token);
    }
    return message;
  },
  fromPartial(object: DeepPartial<ChangeActiveAccountResponse>): ChangeActiveAccountResponse {
    const message = Object.create(baseChangeActiveAccountResponse) as ChangeActiveAccountResponse;
    if (object.token) {
      message.token = object.token;
    }
    return message;
  },
  toJSON(message: ChangeActiveAccountResponse): unknown {
    const obj: any = {};
    obj.token = message.token || "";
    return obj;
  },
};

export const ChangeAccountSettingsRequest = {
  encode(message: ChangeAccountSettingsRequest, writer: Writer = Writer.create()): Writer {
    if (message.notificationDisabled !== undefined && message.notificationDisabled !== undefined) {
      BoolValue.encode({ value: message.notificationDisabled! }, writer.uint32(18).fork()).ldelim();
    }
    if (message.publishedMissionNotificationDisabled !== undefined && message.publishedMissionNotificationDisabled !== undefined) {
      BoolValue.encode({ value: message.publishedMissionNotificationDisabled! }, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): ChangeAccountSettingsRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseChangeAccountSettingsRequest) as ChangeAccountSettingsRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          message.notificationDisabled = BoolValue.decode(reader, reader.uint32()).value;
          break;
        case 3:
          message.publishedMissionNotificationDisabled = BoolValue.decode(reader, reader.uint32()).value;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): ChangeAccountSettingsRequest {
    const message = Object.create(baseChangeAccountSettingsRequest) as ChangeAccountSettingsRequest;
    if (object.notificationDisabled) {
      message.notificationDisabled = Boolean(object.notificationDisabled);
    }
    if (object.publishedMissionNotificationDisabled) {
      message.publishedMissionNotificationDisabled = Boolean(object.publishedMissionNotificationDisabled);
    }
    return message;
  },
  fromPartial(object: DeepPartial<ChangeAccountSettingsRequest>): ChangeAccountSettingsRequest {
    const message = Object.create(baseChangeAccountSettingsRequest) as ChangeAccountSettingsRequest;
    if (object.notificationDisabled) {
      message.notificationDisabled = object.notificationDisabled;
    }
    if (object.publishedMissionNotificationDisabled) {
      message.publishedMissionNotificationDisabled = object.publishedMissionNotificationDisabled;
    }
    return message;
  },
  toJSON(message: ChangeAccountSettingsRequest): unknown {
    const obj: any = {};
    obj.notificationDisabled = message.notificationDisabled || undefined;
    obj.publishedMissionNotificationDisabled = message.publishedMissionNotificationDisabled || undefined;
    return obj;
  },
};

export const ChangeAccountSettingsResponse = {
  encode(message: ChangeAccountSettingsResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): ChangeAccountSettingsResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseChangeAccountSettingsResponse) as ChangeAccountSettingsResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): ChangeAccountSettingsResponse {
    const message = Object.create(baseChangeAccountSettingsResponse) as ChangeAccountSettingsResponse;
    return message;
  },
  fromPartial(object: DeepPartial<ChangeAccountSettingsResponse>): ChangeAccountSettingsResponse {
    const message = Object.create(baseChangeAccountSettingsResponse) as ChangeAccountSettingsResponse;
    return message;
  },
  toJSON(message: ChangeAccountSettingsResponse): unknown {
    const obj: any = {};
    return obj;
  },
};

export const ChangeUserSettingsRequest = {
  encode(message: ChangeUserSettingsRequest, writer: Writer = Writer.create()): Writer {
    if (message.email !== undefined && message.email !== undefined) {
      Email.encode(message.email, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): ChangeUserSettingsRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseChangeUserSettingsRequest) as ChangeUserSettingsRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.email = Email.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): ChangeUserSettingsRequest {
    const message = Object.create(baseChangeUserSettingsRequest) as ChangeUserSettingsRequest;
    if (object.email) {
      message.email = Email.fromJSON(object.email);
    }
    return message;
  },
  fromPartial(object: DeepPartial<ChangeUserSettingsRequest>): ChangeUserSettingsRequest {
    const message = Object.create(baseChangeUserSettingsRequest) as ChangeUserSettingsRequest;
    if (object.email) {
      message.email = Email.fromPartial(object.email);
    }
    return message;
  },
  toJSON(message: ChangeUserSettingsRequest): unknown {
    const obj: any = {};
    obj.email = message.email ? Email.toJSON(message.email) : undefined;
    return obj;
  },
};

export const ChangeUserSettingsResponse = {
  encode(message: ChangeUserSettingsResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): ChangeUserSettingsResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseChangeUserSettingsResponse) as ChangeUserSettingsResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): ChangeUserSettingsResponse {
    const message = Object.create(baseChangeUserSettingsResponse) as ChangeUserSettingsResponse;
    return message;
  },
  fromPartial(object: DeepPartial<ChangeUserSettingsResponse>): ChangeUserSettingsResponse {
    const message = Object.create(baseChangeUserSettingsResponse) as ChangeUserSettingsResponse;
    return message;
  },
  toJSON(message: ChangeUserSettingsResponse): unknown {
    const obj: any = {};
    return obj;
  },
};

export const GetUserSettingsRequest = {
  encode(message: GetUserSettingsRequest, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): GetUserSettingsRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetUserSettingsRequest) as GetUserSettingsRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetUserSettingsRequest {
    const message = Object.create(baseGetUserSettingsRequest) as GetUserSettingsRequest;
    return message;
  },
  fromPartial(object: DeepPartial<GetUserSettingsRequest>): GetUserSettingsRequest {
    const message = Object.create(baseGetUserSettingsRequest) as GetUserSettingsRequest;
    return message;
  },
  toJSON(message: GetUserSettingsRequest): unknown {
    const obj: any = {};
    return obj;
  },
};

export const GetUserSettingsResponse = {
  encode(message: GetUserSettingsResponse, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.email);
    return writer;
  },
  decode(reader: Reader, length?: number): GetUserSettingsResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGetUserSettingsResponse) as GetUserSettingsResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.email = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GetUserSettingsResponse {
    const message = Object.create(baseGetUserSettingsResponse) as GetUserSettingsResponse;
    if (object.email) {
      message.email = String(object.email);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GetUserSettingsResponse>): GetUserSettingsResponse {
    const message = Object.create(baseGetUserSettingsResponse) as GetUserSettingsResponse;
    if (object.email) {
      message.email = object.email;
    }
    return message;
  },
  toJSON(message: GetUserSettingsResponse): unknown {
    const obj: any = {};
    obj.email = message.email || "";
    return obj;
  },
};

export const Email = {
  encode(message: Email, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.value);
    return writer;
  },
  decode(reader: Reader, length?: number): Email {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseEmail) as Email;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.value = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): Email {
    const message = Object.create(baseEmail) as Email;
    if (object.value) {
      message.value = String(object.value);
    }
    return message;
  },
  fromPartial(object: DeepPartial<Email>): Email {
    const message = Object.create(baseEmail) as Email;
    if (object.value) {
      message.value = object.value;
    }
    return message;
  },
  toJSON(message: Email): unknown {
    const obj: any = {};
    obj.value = message.value || "";
    return obj;
  },
};

export const RegisterMessagingDeviceTokenRequest = {
  encode(message: RegisterMessagingDeviceTokenRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.token);
    return writer;
  },
  decode(reader: Reader, length?: number): RegisterMessagingDeviceTokenRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseRegisterMessagingDeviceTokenRequest) as RegisterMessagingDeviceTokenRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.token = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): RegisterMessagingDeviceTokenRequest {
    const message = Object.create(baseRegisterMessagingDeviceTokenRequest) as RegisterMessagingDeviceTokenRequest;
    if (object.token) {
      message.token = String(object.token);
    }
    return message;
  },
  fromPartial(object: DeepPartial<RegisterMessagingDeviceTokenRequest>): RegisterMessagingDeviceTokenRequest {
    const message = Object.create(baseRegisterMessagingDeviceTokenRequest) as RegisterMessagingDeviceTokenRequest;
    if (object.token) {
      message.token = object.token;
    }
    return message;
  },
  toJSON(message: RegisterMessagingDeviceTokenRequest): unknown {
    const obj: any = {};
    obj.token = message.token || "";
    return obj;
  },
};

export const RegisterMessagingDeviceTokenResponse = {
  encode(message: RegisterMessagingDeviceTokenResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): RegisterMessagingDeviceTokenResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseRegisterMessagingDeviceTokenResponse) as RegisterMessagingDeviceTokenResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): RegisterMessagingDeviceTokenResponse {
    const message = Object.create(baseRegisterMessagingDeviceTokenResponse) as RegisterMessagingDeviceTokenResponse;
    return message;
  },
  fromPartial(object: DeepPartial<RegisterMessagingDeviceTokenResponse>): RegisterMessagingDeviceTokenResponse {
    const message = Object.create(baseRegisterMessagingDeviceTokenResponse) as RegisterMessagingDeviceTokenResponse;
    return message;
  },
  toJSON(message: RegisterMessagingDeviceTokenResponse): unknown {
    const obj: any = {};
    return obj;
  },
};

type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T[P] extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T[P] extends Date | Function | Uint8Array | undefined
  ? T[P]
  : T[P] extends infer U | undefined
  ? DeepPartial<U>
  : T[P] extends object
  ? DeepPartial<T[P]>
  : T[P]
};