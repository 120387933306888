import React, { FC, useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import throttle from 'lodash.throttle';
import { Controller } from 'react-hook-form';
import theme from 'styles/theme';
import media from 'styles/media';
import * as MESSAGES from 'constants/messages';
import { SUGGEST_INTERVAL_MS } from 'constants/config';
import { PublishSettingFormMethods } from 'types/publishSetting';
import { Company } from 'proto/v1/apimodel/apimodel';
import { TextInput, ButtonM } from 'components/atoms';

const SCROLL_HEADROOM = 100;

const Layout = styled.div`
  display: grid;
  position: relative;
  grid-template:
    '... ...... ...... ...... ...'
    '... field  ...... button ...' auto
    '... ...... ...... ...... ...' 12px
    '... values values values ...' auto
    '... ...... ...... ...... ...'
    / 0 1fr 12px auto 0;
  max-width: 636px;
  margin-bottom: 40px;
`;

const StyledInput = styled(TextInput)`
  grid-area: field;
`;

const StyledButton = styled(ButtonM)`
  grid-area: button;
  width: 80px;
  height: 40px;
`;

const SearchResults = styled.div`
  position: absolute;
  z-index: 2;
  width: 100%;
  max-height: 215px;
  margin-top: 48px;
  overflow-y: scroll;
  border: 1px solid ${theme.borderDefault};
  border-radius: 3px;
  background: ${theme.white};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
`;

const SearchResult = styled.div`
  padding: 13px 12px;

  &:hover {
    background: rgba(35, 55, 128, 0.7);
    color: ${theme.white};
    cursor: pointer;
  }

  &:not(:last-child):not(:hover) {
    border-bottom: 1px solid ${theme.borderDefault};
  }
`;

const EmptyBlcokComapnies = styled.div`
  display: flex;
  grid-area: values;
  align-items: center;
  justify-content: center;
  height: 136px;
  border: 1px solid ${theme.borderDefault};
  border-radius: 3px;
  background: ${theme.bgBlockCompany};

  ${media.mobile} {
    height: 96px;
  }
`;

const BlockCompanies = styled.div`
  display: flex;
  grid-area: values;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  border: 1px solid ${theme.borderDefault};
  border-radius: 3px;
  background: ${theme.bgBlockCompany};
`;

const BlockCompany = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  width: 100%;
  padding: 15px 0;

  &:not(:last-child) {
    border-bottom: 1px solid ${theme.borderDefault};
  }
`;

const DeleteBlockCompany = styled.div`
  color: ${theme.textSecondary};
  cursor: pointer;
`;

type BlcokCompaniesFieldProps = {
  formMethods: PublishSettingFormMethods;
  companies: Company[];
  fetchCompanies: (nameLike: string) => void;
  fetchMoreCompanies: () => void;
};

const BlockCompaniesField: FC<BlcokCompaniesFieldProps> = props => {
  const { formMethods, companies, fetchCompanies, fetchMoreCompanies } = props;
  const { control, setValue, getValues, errors, register, watch } = formMethods;
  const [showCandidate, setShowCandidates] = useState(false);
  const fetch = useCallback(throttle(fetchCompanies, SUGGEST_INTERVAL_MS), []);

  const search = watch('blockCompanySearch');

  const addBlockCompany = useCallback(
    (bc: string) => {
      if (!bc) return;
      const current = getValues('blockCompanies') ?? [];
      if (!current.some(c => c === bc)) {
        setValue('blockCompanies', [...current, bc]);
      }
      setValue('blockCompanySearch', '');
    },
    [getValues, setValue],
  );

  const deleteBlockCompany = useCallback(
    (bc: string) => {
      setValue('blockCompanies', [
        ...(getValues('blockCompanies') ?? []).filter(v => v !== bc),
      ]);
    },
    [getValues, setValue],
  );

  const ref = useRef<HTMLDivElement>(null);
  const onScrollCandidates = useCallback(() => {
    if (!ref.current) return;
    const { scrollHeight, scrollTop, clientHeight } = ref.current;
    if (scrollHeight - (scrollTop + clientHeight) < SCROLL_HEADROOM) {
      fetchMoreCompanies();
    }
  }, [fetchMoreCompanies]);

  return (
    <Controller
      name={'blockCompanies'}
      control={control}
      error={errors.blockCompanies}
      render={innerProps => {
        const current = innerProps.value as string[];
        return (
          <Layout>
            <StyledInput
              enterKeyHint="enter"
              name="blockCompanySearch"
              ref={register}
              autoComplete="off"
              onChange={e => fetch(e.target.value)}
              onFocus={() => setShowCandidates(true)}
              onBlur={() => setShowCandidates(false)}
              placeholder={
                MESSAGES.PUBLISH_SETTING.FIELD.PLACEHOLDER_INPUT_COMPANY_NAME
              }
              data-testid="company-search-input"
            />
            <StyledButton
              type="button"
              onClick={() => addBlockCompany(getValues('blockCompanySearch'))}
              disabled={!search || current.some(bc => bc === search)}
            >
              {MESSAGES.PUBLISH_SETTING.BUTTON.REGISTER}
            </StyledButton>
            {showCandidate && companies.length > 0 && (
              <SearchResults ref={ref} onScroll={onScrollCandidates}>
                {companies
                  .filter(c => !current.some(bc => bc === c.name))
                  .map(c => (
                    // StyledInputのonBlurとSearchResultのクリックを
                    // 両立するためにonClickではなくonMouseDownを使う
                    <SearchResult
                      key={c.id}
                      onMouseDown={() => addBlockCompany(c.name)}
                    >
                      {c.name}
                    </SearchResult>
                  ))}
              </SearchResults>
            )}
            {innerProps.value.length === 0 ? (
              <EmptyBlcokComapnies>
                {MESSAGES.PUBLISH_SETTING.NO_BLOCK_COMPANIES}
              </EmptyBlcokComapnies>
            ) : (
              <BlockCompanies>
                {(innerProps.value as string[]).map(bc => (
                  <BlockCompany key={bc} data-testid={'block-company'}>
                    <div>{bc}</div>
                    <DeleteBlockCompany
                      onClick={() => deleteBlockCompany(bc)}
                      data-testid={'block-company-delete'}
                    >
                      {MESSAGES.PUBLISH_SETTING.BUTTON.DELETE}
                    </DeleteBlockCompany>
                  </BlockCompany>
                ))}
              </BlockCompanies>
            )}
          </Layout>
        );
      }}
    />
  );
};

export default BlockCompaniesField;
