import React, { FC } from 'react';
import { Stepper as StepperType } from 'hooks/useStepper';
import { isEqual } from 'utils/object';
import { stepsCareer as steps } from './steps';
import {
  RegisterProfileFormCareerFirst,
  RegisterProfileFormCareerSecond,
} from './Forms';
import { RegisterProfileConfirmCareer } from './Confirms';
import RegisterProfileCompleted from './Completed';

type StepContentProps = {
  currentStepIndex: number;
  stepper: StepperType;
};

const StepContentCareer: FC<StepContentProps> = ({
  currentStepIndex,
  stepper,
}) => {
  return (
    <>
      {currentStepIndex === steps.formFirst.stepIndex && (
        <RegisterProfileFormCareerFirst stepper={stepper} />
      )}
      {currentStepIndex === steps.formSecond.stepIndex && (
        <RegisterProfileFormCareerSecond stepper={stepper} />
      )}
      {currentStepIndex === steps.confirm.stepIndex && (
        <RegisterProfileConfirmCareer stepper={stepper} />
      )}
      {currentStepIndex === steps.completed.stepIndex && (
        <RegisterProfileCompleted />
      )}
    </>
  );
};

export default React.memo(StepContentCareer, isEqual);
