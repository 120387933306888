import React, { FC } from 'react';
import * as MESSAGES from 'constants/messages';
import { Label, ProfileField, ProfileContent } from 'components/atoms';
import { Innovator } from 'proto/v1/apimodel/apimodel';

type JapanPrefectureFieldProps = {
  japanPrefecture: Innovator['japanPrefecture'];
};

const JapanPrefectureField: FC<JapanPrefectureFieldProps> = props => {
  const { japanPrefecture } = props;

  return (
    <ProfileField data-testid="japan-prefecture-field">
      <Label>{MESSAGES.PROFILE.FIELD.JAPAN_PREFECTURE.LABEL}</Label>
      <ProfileContent>
        {japanPrefecture ? japanPrefecture?.name : MESSAGES.PROFILE.OVERSEAS}
      </ProfileContent>
    </ProfileField>
  );
};

export default JapanPrefectureField;
