import React, { FC, HTMLAttributes } from 'react';
import styled from 'styled-components/macro';

const StyledCopyRight = styled.div`
  display: flex;
  align-items: center;
  font-size: 10px;
`;

const CopyRight: FC<HTMLAttributes<HTMLDivElement>> = props => (
  <StyledCopyRight {...props}>
    &copy; VISITS Technologies All rights reserved.
  </StyledCopyRight>
);

export default CopyRight;
