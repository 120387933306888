import React, { FC, ChangeEvent } from 'react';
import { Controller } from 'react-hook-form';
import styled from 'styled-components/macro';
import media from 'styles/media';
import * as MESSAGES from 'constants/messages';
import {
  END_YEAR_MONTH_CURRENTLY_ENROLLED_ID,
  WORKING_PERIOD_MIN_YEAR,
} from 'constants/profile';
import { NO_SELECT_NUMBER } from 'constants/form';
import { range, rangeRight } from 'utils/array';
import {
  ProfileFormControl,
  ProfileFormMethods,
  ProfileFormValues,
} from 'types/profile';
import { ErrorMessage, Selectbox } from 'components/atoms';
import { Field } from 'components/molecules';

const Container = styled.div`
  display: grid;
  grid-template-columns: 368px 30px 368px;

  ${media.tablet} {
    grid-template-columns: 1fr;
  }
`;

const SelectBoxes = styled.div`
  display: grid;
  grid-column-gap: 16px;
  grid-template-columns: 176px 176px;

  ${media.tablet} {
    grid-row-gap: 8px;
    grid-template-columns: 1fr;
  }
`;

const SelectboxWrapper = styled.div`
  position: relative;
`;

const StyledSelectBox = styled(Selectbox)`
  & + .error-message {
    position: absolute;
    top: 40px;
    left: 0;
  }
`;

const From = styled.span`
  justify-self: center;
  align-self: center;

  ${media.tablet} {
    justify-self: start;
    padding: 8px 0;
  }
`;

const WorkingPeriodErrorMessage = styled(ErrorMessage)`
  margin-top: 4px;
`;

const currentlyEnrolledOption = {
  id: END_YEAR_MONTH_CURRENTLY_ENROLLED_ID,
  name: MESSAGES.PROFILE.FIELD.CAREER_JOB.WORKING_PERIOD.CURRENTLY_ENROLLED,
};

const yearOptions = rangeRight(
  WORKING_PERIOD_MIN_YEAR,
  new Date().getFullYear(),
).map(year => ({
  id: year,
  name: `${year}年`,
}));

const monthOptions = range(1, 12).map(month => ({
  id: month,
  name: `${month}月`,
}));

type Job = ProfileFormValues['career']['job'][0];

type Value = {
  career: {
    job: Pick<Job, 'startYear' | 'startMonth' | 'endYear' | 'endMonth'>[];
  };
};

type WorkingPeriodFormFieldProps = {
  error: string | undefined;
  jobIndex: number;
  onChangeEnd: (e: ChangeEvent<HTMLSelectElement>, index: number) => void;
  control: ProfileFormControl<Value>;
  trigger: ProfileFormMethods<Value>['trigger'];
};

const WorkingPeriodFormField: FC<WorkingPeriodFormFieldProps> = props => {
  const { error, jobIndex, control, trigger, onChangeEnd } = props;

  const startYearName = `career.job[${jobIndex}].startYear`;
  const startMonthName = `career.job[${jobIndex}].startMonth`;
  const endYearName = `career.job[${jobIndex}].endYear`;
  const endMonthName = `career.job[${jobIndex}].endMonth`;

  return (
    <Field
      label={MESSAGES.PROFILE.FIELD.CAREER_JOB.WORKING_PERIOD.LABEL}
      required
    >
      <Container>
        <SelectBoxes>
          <SelectboxWrapper>
            <Controller
              control={control}
              name={startYearName}
              render={innerProps => (
                <StyledSelectBox
                  value={innerProps.value}
                  name={startYearName}
                  options={yearOptions}
                  aria-label={
                    MESSAGES.PROFILE.FIELD.CAREER_JOB.WORKING_PERIOD.START_YEAR
                      .LABEL
                  }
                  onChange={e => {
                    innerProps.onChange(e);
                    trigger([startMonthName, endYearName, endMonthName]);
                  }}
                  onBlur={innerProps.onBlur}
                  noSelectValue={NO_SELECT_NUMBER}
                />
              )}
              defaultValue={NO_SELECT_NUMBER}
            />
          </SelectboxWrapper>

          <SelectboxWrapper>
            <Controller
              control={control}
              name={startMonthName}
              render={innerProps => (
                <StyledSelectBox
                  value={innerProps.value}
                  name={startMonthName}
                  options={monthOptions}
                  aria-label={
                    MESSAGES.PROFILE.FIELD.CAREER_JOB.WORKING_PERIOD.START_MONTH
                      .LABEL
                  }
                  onChange={e => {
                    innerProps.onChange(e);
                    trigger([startYearName, endYearName, endMonthName]);
                  }}
                  onBlur={innerProps.onBlur}
                  noSelectValue={NO_SELECT_NUMBER}
                />
              )}
              defaultValue={NO_SELECT_NUMBER}
            />
          </SelectboxWrapper>
        </SelectBoxes>

        <From>〜</From>

        <SelectBoxes>
          <SelectboxWrapper>
            <Controller
              control={control}
              name={endYearName}
              render={innerProps => (
                <StyledSelectBox
                  value={innerProps.value}
                  name={endYearName}
                  options={[currentlyEnrolledOption, ...yearOptions]}
                  aria-label={
                    MESSAGES.PROFILE.FIELD.CAREER_JOB.WORKING_PERIOD.END_YEAR
                      .LABEL
                  }
                  onChange={e => {
                    onChangeEnd(e, jobIndex);
                    trigger([startYearName, startMonthName, endMonthName]);
                  }}
                  onBlur={innerProps.onBlur}
                  noSelectValue={NO_SELECT_NUMBER}
                />
              )}
              defaultValue={NO_SELECT_NUMBER}
            />
          </SelectboxWrapper>

          <SelectboxWrapper>
            <Controller
              control={control}
              name={endMonthName}
              render={innerProps => (
                <StyledSelectBox
                  value={innerProps.value}
                  name={endMonthName}
                  options={[currentlyEnrolledOption, ...monthOptions]}
                  aria-label={
                    MESSAGES.PROFILE.FIELD.CAREER_JOB.WORKING_PERIOD.END_MONTH
                      .LABEL
                  }
                  onChange={e => {
                    onChangeEnd(e, jobIndex);
                    trigger([startYearName, startMonthName, endYearName]);
                  }}
                  onBlur={innerProps.onBlur}
                  noSelectValue={NO_SELECT_NUMBER}
                />
              )}
              defaultValue={NO_SELECT_NUMBER}
            />
          </SelectboxWrapper>
        </SelectBoxes>
      </Container>
      {error && <WorkingPeriodErrorMessage>{error}</WorkingPeriodErrorMessage>}
    </Field>
  );
};

export default WorkingPeriodFormField;
