import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { Controller } from 'react-hook-form';
import { InputActionMeta } from 'react-select';
import * as MESSAGES from 'constants/messages';
import { MAX_EXPECTATIONS } from 'constants/profile';
import { ProfileFormControl, ProfileFormValues } from 'types/profile';
import theme from 'styles/theme';
import { interpolateMessage } from 'utils/string';
import { ReactSelectOption } from 'types/form';
import { Field, CreatableInputSelect } from 'components/molecules';
import { Tag } from 'components/atoms';

const ExampleExpectations = styled.div`
  margin-top: 8px;
`;

const ExampleExpectationsDescription = styled.p`
  margin-bottom: 8px;
  color: ${theme.textBody};
  font-size: 12px;
`;

const ExampleExpectationTags = styled.div``;

type Value = {
  career: Pick<ProfileFormValues['career'], 'expectations'>;
};

type CareerExpectationsFormFieldProps = {
  inputValue: string;
  error: string | undefined;
  control: ProfileFormControl<Value>;
  options: ReactSelectOption[];
  exampleExpectations: ProfileFormValues['career']['expectations'];
  isLoading: boolean;
  onInputChange: (inputValue: string, actionMeta: InputActionMeta) => void;
  onCreateOption: (inputValue: string) => void;
  fetchMore: () => Promise<void>;
  onClickExample: (skill: ReactSelectOption) => void;
};

const CareerExpectationsFormField: FC<CareerExpectationsFormFieldProps> = props => {
  const {
    inputValue,
    error,
    control,
    exampleExpectations,
    options,
    isLoading,
    fetchMore,
    onInputChange,
    onCreateOption,
    onClickExample,
  } = props;

  return (
    <Field
      label={MESSAGES.PROFILE.FIELD.EXPECTATIONS.LABEL}
      note={interpolateMessage(MESSAGES.PROFILE.FIELD.EXPECTATIONS.NOTE, {
        maxLength: MAX_EXPECTATIONS,
      })}
      required
      data-testid="expectations-field"
    >
      <Controller
        control={control}
        name="career.expectations"
        render={innerProps => (
          <CreatableInputSelect
            enterKeyHint="enter"
            isMulti
            inputValue={inputValue}
            value={innerProps.value}
            error={error}
            options={options}
            onInputChange={onInputChange}
            isLoading={isLoading}
            onMenuScrollToBottom={fetchMore}
            onChange={innerProps.onChange}
            onBlur={innerProps.onBlur}
            onCreateOption={onCreateOption}
            placeholder={MESSAGES.PROFILE.FIELD.EXPECTATIONS.PLACEHOLDER}
            aria-label={MESSAGES.PROFILE.FIELD.EXPECTATIONS.LABEL}
          />
        )}
      />
      <ExampleExpectations>
        <ExampleExpectationsDescription>
          {MESSAGES.PROFILE.FIELD.EXPECTATIONS.DESCRIPTION.EXAMPLE}
        </ExampleExpectationsDescription>
        <ExampleExpectationTags>
          {exampleExpectations &&
            exampleExpectations.map(expectation => (
              <Tag
                onClick={() => onClickExample(expectation)}
                key={expectation.value}
              >
                {expectation.label}
              </Tag>
            ))}
        </ExampleExpectationTags>
      </ExampleExpectations>
    </Field>
  );
};

export default CareerExpectationsFormField;
