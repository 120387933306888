import styled from 'styled-components/macro';

const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 112px;
  height: 112px;
  border-radius: 50%;
  background-color: rgba(35, 55, 128, 0.08);
`;

export default Circle;
