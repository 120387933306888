export const ERROR_TYPE = {
  SERVER_ERROR: 'SERVER_ERROR',
  CLIENT_ERROR: 'CLIENT_ERROR',
  NETWORK_ERROR: 'NETWORK_ERROR',
  UNEXPECTED_ERROR: 'UNEXPECTED_ERROR',
  CANCELED_ERROR: 'CANCELED_ERROR',
  TIMEOUT: 'TIMEOUT',
} as const;

export const ERROR_CODE = {
  INVALID_USER_OOB_CODE: 'INVALID_USER_OOB_CODE',
  FAILED_TO_POST_MESSAGE_BECAUSE_OPPONENT_MISSION_IS_NOT_PUBLISHED:
    'FAILED_TO_POST_MESSAGE_BECAUSE_OPPONENT_MISSION_IS_NOT_PUBLISHED',
  MISSION_NOT_FOUND: 'MISSION_NOT_FOUND',
  MISSION_CLOSED: 'MISSION_CLOSED',
  FAILED_TO_GET_MISSIONS: 'FAILED_TO_GET_MISSIONS',
} as const;
