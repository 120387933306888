import { defaultTheme } from 'visits-style';

const base = {
  baseWhite: '#FFFFFF',
  basePrimary: '#233780',
  baseDisabled: '#DEDEDE',
  baseLink: '#233780',
  baseError: '#E32D2D',
};

const text = {
  textPrimary: '#333333',
  textPrimaryHover: '#666666',
  textSecondary: '#9698A4',
  textSecondaryHover: '#BFC0C7',
  textSecondaryDisabled: '#DEDEDE',
  textCardSecondary: '#696F7B',
  textBody: '#363636',
  textPlaceholder: '#c7c7c7',
};

const border = {
  borderDefault: '#DCDCDC',
  borderActive: '#233780',
  borderTable: '#EEEEEE',
  borderCard: '#F3F4F7',
};

const button = {
  buttonDefaultHover: '#4F5F99',
  buttonCancelHover: '#EDEFF5',
};

const bg = {
  bgMain: '#fafafa',
  bgDefault: '#F5F6F7',
  bgCard: '#f4f5f8',
  bgDropzone: '#fcfcfc',
  bgBlockCompany: '#fcfcfc',
  bgLightGray: '#fcfcfc',
  bgSwitchOn: '#233780',
  bgSwitchOff: '#c7c7c7',
  bgCardHover: '#F3F4F7',
};

const label = {
  labelDefault: '#636776',
  labelRequired: '#E31338',
  labelTag: '#2337808f',
  labelBadge: '#F25C5C',
  labelSecondary: '#8693A8',
};

const tab = {
  tabUnderline: '#696F7B',
};

export default {
  ...defaultTheme,
  ...base,
  ...text,
  ...border,
  ...button,
  ...bg,
  ...label,
  ...tab,
};
