export default {
  TITLE: '設定',
  CHANGE_PASSWORD: {
    TITLE: 'パスワードを変更',
    FIELD: {
      CURRENT_PASSWORD: {
        LABEL: '現在のパスワード',
      },
      NEW_PASSWORD: {
        LABEL: '新しいパスワード',
      },
      CONFIRM_NEW_PASSWORD: {
        LABEL: '新しいパスワード (確認用)',
      },
    },
    BUTTON: {
      SUBMIT: '変更を保存',
    },
    MODAL: {
      COMPLETED: {
        CONTENT: 'パスワードを変更しました。',
      },
    },
  },
  PUBLISH_SETTING: {
    TITLE: 'プロフィール公開設定',
    NOTE: '※プロフィール審査完了後、プロフィール公開設定を変更できます',
  },
  NOTIFICATION_SETTING: {
    TITLE: '通知設定',
    SWITCH: {
      EMAIL: {
        LABEL: 'メール通知',
      },
    },
    BUTTON: {
      SUBMIT: '設定する',
    },
    MODAL: {
      COMPLETED: {
        CONTENT: '変更が保存されました。',
      },
    },
  },
  REPORT_JOINING: {
    TITLE: '入社確定連絡',
    INFORMATION_TITLE: 'AMAZON ギフト券の贈呈',
    NOTE:
      '※INNOVATORS CAREERの退会手続きをされた場合は無効となります。受け取りまでは、退会されないようご注意ください。',
    SCOUTING_NOTE: {
      TITLE: '入社確定連絡後に、スカウトの受信を希望されない方へ',
      DESCRIPTION:
        'プロフィール公開設定を「OFF」に設定していただくと、他の企業様からプロフィールの閲覧がされなくなります。入社先の確定後、必要に応じてご設定ください。',
    },
    FIELD: {
      COMPANY_NAME: {
        LABEL: '企業名',
        PLACEHOLDER: '○○株式会社（正式名称でご入力ください）',
      },
      JOIN_COMPANY_SCHEDULE: {
        LABEL: '入社予定日',
        YEAR: {
          LABEL: '入社予定年',
        },
        MONTH: {
          LABEL: '入社予定月',
        },
        DAY: {
          LABEL: '入社予定日',
        },
      },
    },
    BUTTON: {
      SUBMIT: '入社確定連絡をする',
    },
    MODAL: {
      COMPLETED: {
        TITLE: '入社連絡ありがとうございます',
        CONTENT: ' Innovators Careerコンサルタントよりご連絡させていただきます',
      },
    },
  },
};
