import React, { FC } from 'react';
import * as MESSAGES from 'constants/messages';
import { CareerProfile } from 'proto/v1/apimodel/apimodel';
import { Label, ProfileField, ProfileContent } from 'components/atoms';

type CareerPreferredEmploymentTypeFieldProps = {
  preferredEmploymentType: CareerProfile['preferredEmploymentType'] | undefined;
};

const CareerPreferredEmploymentTypeField: FC<CareerPreferredEmploymentTypeFieldProps> = props => {
  const { preferredEmploymentType } = props;

  return (
    <ProfileField data-testid="preferred-employment-type-field">
      <Label>{MESSAGES.PROFILE.FIELD.PREFERRED_EMPLOYMENT_TYPE.LABEL}</Label>
      <ProfileContent>{preferredEmploymentType?.name}</ProfileContent>
    </ProfileField>
  );
};

export default CareerPreferredEmploymentTypeField;
