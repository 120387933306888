import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { Controller } from 'react-hook-form';
import media from 'styles/media';
import theme from 'styles/theme';
import * as MESSAGES from 'constants/messages';
import { RESUME_FILE_ACCEPTS } from 'constants/profile';
import { formatToKiloBytes } from 'utils/file';
import {
  ResumeFileFormValue,
  ProfileFormValues,
  ProfileFormControl,
} from 'types/profile';

import { CircleCrossGray } from 'assets/svg';
import { Field, Dropzone } from 'components/molecules';

const StyldField = styled(Field)`
  ${media.mobile} {
    display: none;
  }
`;

const DropzoneBody = styled.p`
  text-align: center;
  word-wrap: break-word;
  white-space: pre-wrap;
`;

const LinkText = styled.span`
  color: ${theme.baseLink};
  text-decoration: underline;
`;

const UploadedResumeFile = styled.div`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  padding: 10px 12px;
  border: 1px solid ${theme.borderDefault};
  border-radius: 3px;
  background-color: ${theme.bgDropzone};
`;

const FileName = styled.span`
  margin-right: 4px;
  color: ${theme.basePrimary};
  font-size: 12px;
`;

const FileSize = styled.span`
  margin-right: 8px;
  color: ${theme.labelDefault};
  font-size: 12px;
  font-weight: bold;
`;

const IconRemoveFile = styled(CircleCrossGray)`
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
`;

type CareerResumeFileFormFieldProps = {
  onDrop: (files: File[]) => void;
  onRemove: () => void;
  control: ProfileFormControl<{
    career: Pick<ProfileFormValues['career'], 'resumeFile'>;
  }>;
  resumeFile: ResumeFileFormValue;
};

const CareerResumeFileFormField: FC<CareerResumeFileFormFieldProps> = props => {
  const { onDrop, onRemove, control, resumeFile } = props;

  return (
    <StyldField
      label={`${MESSAGES.PROFILE.FIELD.CAREER_RESUME_FILE.LABEL}${MESSAGES.PROFILE.FIELD.CAREER_RESUME_FILE.LABEL_NOTE}`}
      locked
    >
      <Controller
        name="career.resumeFile"
        control={control}
        render={() => (
          <>
            {resumeFile?.key || resumeFile?.url ? (
              <UploadedResumeFile data-testid="uploaded-resume-file">
                <FileName>{resumeFile.name}</FileName>
                <FileSize>({formatToKiloBytes(resumeFile.size)})</FileSize>
                <IconRemoveFile
                  onClick={onRemove}
                  data-testid="icon-remove-resume-file"
                />
              </UploadedResumeFile>
            ) : (
              <Dropzone
                multiple={false}
                accept={RESUME_FILE_ACCEPTS}
                onDropAccepted={onDrop}
              >
                <DropzoneBody>
                  {MESSAGES.PROFILE.FIELD.CAREER_RESUME_FILE.BODY}
                  <LinkText>
                    {MESSAGES.PROFILE.FIELD.CAREER_RESUME_FILE.LINK}
                  </LinkText>
                </DropzoneBody>
              </Dropzone>
            )}
          </>
        )}
      />
    </StyldField>
  );
};

export default CareerResumeFileFormField;
