import React, { useReducer, useRef } from 'react';
import { ReactSelectOption } from 'types/form';

const types = {
  INIT: 'INIT',
  SET_LOADING: 'SET_LOADING',
  SET_CREATING: 'SET_CREATING',
  FETCH_FIRST_DONE: 'FETCH_FIRST_DONE',
  FETCH_MORE_DONE: 'FETCH_MORE_DONE',
  SET_INPUT_VALUE: 'SET_INPUT_VALUE',
} as const;

type ActionTypes = typeof types;

export type State<T> = {
  inputValue: string;
  nextQuery: string;
  options: T[];
  isLoading: boolean;
  isCreating: boolean;
};

type Payload<T> = {
  [types.SET_LOADING]: Pick<State<T>, 'isLoading'>;
  [types.SET_CREATING]: Pick<State<T>, 'isCreating'>;
  [types.SET_CREATING]: Pick<State<T>, 'isCreating'>;
  [types.FETCH_FIRST_DONE]: Pick<State<T>, 'options' | 'nextQuery'> &
    Partial<Pick<State<T>, 'inputValue'>>;
  [types.FETCH_MORE_DONE]: Pick<State<T>, 'options' | 'nextQuery'>;
  [types.SET_INPUT_VALUE]: Pick<State<T>, 'inputValue'>;
};

type Actions<T> =
  | {
      type: ActionTypes['INIT'];
    }
  | {
      type: ActionTypes['SET_LOADING'];
      payload: Payload<T>['SET_LOADING'];
    }
  | {
      type: ActionTypes['SET_CREATING'];
      payload: Payload<T>['SET_CREATING'];
    }
  | {
      type: ActionTypes['FETCH_FIRST_DONE'];
      payload: Payload<T>['FETCH_FIRST_DONE'];
    }
  | {
      type: ActionTypes['FETCH_MORE_DONE'];
      payload: Payload<T>['FETCH_MORE_DONE'];
    }
  | {
      type: ActionTypes['SET_INPUT_VALUE'];
      payload: Payload<T>['SET_INPUT_VALUE'];
    };

const initialState = {
  inputValue: '',
  nextQuery: '',
  options: [],
  isLoading: false,
  isCreating: false,
};

const createReducer = <T>(): React.Reducer<State<T>, Actions<T>> => (
  state: State<T>,
  action: Actions<T>,
) => {
  switch (action.type) {
    case types.INIT:
      return initialState;

    case types.SET_LOADING:
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };

    case types.SET_CREATING:
      return {
        ...state,
        isCreating: action.payload.isCreating,
      };

    case types.FETCH_FIRST_DONE:
      return {
        ...state,
        isLoading: false,
        options: action.payload.options,
        nextQuery: action.payload.nextQuery,
        inputValue: action.payload.inputValue ?? state.inputValue,
      };

    case types.FETCH_MORE_DONE:
      return {
        ...state,
        isLoading: false,
        options: [...state.options, ...action.payload.options],
        nextQuery: action.payload.nextQuery,
      };

    case types.SET_INPUT_VALUE:
      return {
        ...state,
        inputValue: action.payload.inputValue,
      };

    default:
      return state;
  }
};

const useSelectOptionsState = <T = ReactSelectOption>() => {
  const [state, dispatch] = useReducer(createReducer<T>(), initialState);
  // To prevent react-select's onCreate from being triggered twice, manage the creating flag by ref.
  // This happens when you press Enter on the iOS keyboard to register.
  const isCreatingOptionRef = useRef<boolean>(false);

  const init = () => dispatch({ type: types.INIT });

  const startLoading = () =>
    dispatch({ type: types.SET_LOADING, payload: { isLoading: true } });

  const endLoading = () =>
    dispatch({ type: types.SET_LOADING, payload: { isLoading: false } });

  const startCreating = () => {
    isCreatingOptionRef.current = true;
    dispatch({ type: types.SET_CREATING, payload: { isCreating: true } });
  };

  const endCreating = () => {
    isCreatingOptionRef.current = false;
    dispatch({ type: types.SET_CREATING, payload: { isCreating: false } });
  };

  const fetchFirstDone = (payload: Payload<T>['FETCH_FIRST_DONE']) =>
    dispatch({ type: types.FETCH_FIRST_DONE, payload });

  const fetchMoreDone = (payload: Payload<T>['FETCH_MORE_DONE']) =>
    dispatch({ type: types.FETCH_MORE_DONE, payload });

  const setInputValue = (
    inputValue: Payload<T>['SET_INPUT_VALUE']['inputValue'],
  ) => dispatch({ type: types.SET_INPUT_VALUE, payload: { inputValue } });

  return {
    state,
    init,
    startLoading,
    endLoading,
    startCreating,
    endCreating,
    fetchFirstDone,
    fetchMoreDone,
    setInputValue,
    isCreatingOptionRef,
  };
};

export default useSelectOptionsState;
