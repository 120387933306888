import React, { FC, useEffect, useState, useMemo, useCallback } from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import media from 'styles/media';
import * as MESSAGES from 'constants/messages';
import * as PROTECTED_ROUTES from 'routes/constants/protected';
import { getShouldReviewProfile, getMatterStatus } from 'modules/matter';
import { getIsCareer, getIsNewGraduate } from 'modules/account';
import { Heading, Information, Card } from 'components/atoms';
import { Stepper, FormFieldMarkDescription } from 'components/molecules';
import { PrivateRouteLayoutContent } from 'components/templates';
import useStepper from 'hooks/useStepper';
import { MATTER_STATUS_ID } from 'constants/matter';
import { stepsCareer, stepsNewGraduate } from './steps';
import StepContentCareer from './StepContentCareer';
import StepContentNewGraduate from './StepContentNewGraduate';
import { SendBackModal } from './Modals';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 40px;

  ${media.mobile} {
    padding-top: 32px;
  }
`;

const ContentCard = styled(Card)`
  width: 100%;
`;

const RegisterProfile: FC = () => {
  const history = useHistory();
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const [showSendBackModal, setShowSendBackModal] = useState<boolean>(false);
  const isCareer = useSelector(getIsCareer);
  const isNewGraduate = useSelector(getIsNewGraduate);
  const shouldReviewProfile = useSelector(getShouldReviewProfile);
  const { statusId } = useSelector(getMatterStatus);

  const stepLabels = useMemo(() => {
    if (isCareer) {
      return Object.values(stepsCareer).map(step => step.label);
    }
    if (isNewGraduate) {
      return Object.values(stepsNewGraduate).map(step => step.label);
    }
    return [];
  }, [isCareer, isNewGraduate]);

  const stepper = useStepper(stepLabels);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  const isFormStep = useMemo(() => {
    if (isCareer) {
      return [
        stepsCareer.formFirst.stepIndex,
        stepsCareer.formSecond.stepIndex,
      ].includes(currentStepIndex);
    }
    if (isNewGraduate) {
      return [
        stepsNewGraduate.formFirst.stepIndex,
        stepsNewGraduate.formSecond.stepIndex,
        stepsNewGraduate.formThird.stepIndex,
      ].includes(currentStepIndex);
    }
    return [];
  }, [isCareer, isNewGraduate, currentStepIndex]);

  const informationMessage = useMemo(() => {
    if (statusId === MATTER_STATUS_ID.SEND_BACK) {
      return MESSAGES.REGISTER_PROFILE.INFORMATION_SEND_BACK;
    }
    if (isNewGraduate) {
      return MESSAGES.REGISTER_PROFILE.INFORMATION_NEW_GRADUATE;
    }
    return MESSAGES.REGISTER_PROFILE.INFORMATION;
  }, [isNewGraduate, statusId]);

  const checkStepSelectable = useCallback(
    (index: number) => {
      if (index === currentStepIndex || index === stepLabels.length - 1) {
        // Don't allow to select current step index or final step by clicking stepper
        return false;
      }

      let completed = true;
      if (isCareer) {
        for (let i = currentStepIndex; i < index; i += 1) {
          completed = completed && Object.values(stepsCareer)[i].completed;
        }
      } else if (isNewGraduate) {
        for (let i = currentStepIndex; i < index; i += 1) {
          completed = completed && Object.values(stepsNewGraduate)[i].completed;
        }
      }

      if (!completed && index > currentStepIndex) {
        // Don't allow to select next steps if the current step or between steps aren't completed
        return false;
      }

      return true;
    },
    [isCareer, isNewGraduate, stepLabels, currentStepIndex],
  );

  const moveToStep = useCallback(
    (index: number) => {
      const isSelectable = checkStepSelectable(index);
      if (!isSelectable) {
        return;
      }

      stepper.setCurrentStepIndex(index);
      setCurrentStepIndex(index);
    },
    [checkStepSelectable, stepper],
  );

  useEffect(() => {
    setCurrentStepIndex(stepper.currentStepIndex);
  }, [stepper.currentStepIndex]);

  // Move to profile page if the profile shouldn't be review.
  useEffect(() => {
    if (isMounted) return;
    if (!shouldReviewProfile) {
      history.push(PROTECTED_ROUTES.PROFILE);
    }
    setIsMounted(true);
  }, [history, isMounted, shouldReviewProfile]);

  // Show SendBackModal if the matter status is SEND_BACK.
  useEffect(() => {
    if (statusId === MATTER_STATUS_ID.SEND_BACK) {
      setShowSendBackModal(true);
    }
  }, [statusId]);

  return (
    <>
      <PrivateRouteLayoutContent>
        <Wrapper data-testid="register-profile-page">
          <Heading>{MESSAGES.REGISTER_PROFILE.TITLE}</Heading>
          <Stepper
            currentStepIndex={currentStepIndex}
            stepLabels={stepLabels}
            onCheckStepSelectable={checkStepSelectable}
            onClickStep={moveToStep}
          />
          {isFormStep && (
            <div style={{ marginBottom: '16px' }}>
              <Information>{informationMessage}</Information>
              <FormFieldMarkDescription />
            </div>
          )}

          <ContentCard>
            {isCareer && (
              <StepContentCareer
                currentStepIndex={currentStepIndex}
                stepper={stepper}
              />
            )}
            {isNewGraduate && (
              <StepContentNewGraduate
                currentStepIndex={currentStepIndex}
                stepper={stepper}
              />
            )}
          </ContentCard>
        </Wrapper>
      </PrivateRouteLayoutContent>
      <SendBackModal
        show={showSendBackModal}
        onClose={() => setShowSendBackModal(false)}
      />
    </>
  );
};

export default RegisterProfile;
