import { useCallback } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { ProfileFormMethods, ProfileFormValues } from 'types/profile';
import { PastExperienceAchievement } from 'proto/v1/apimodel/apimodel';

type Payload = {
  formMethods: UseFormMethods<any>;
};

const useNewGraduatePastExperienceAchievementsFormField = ({
  formMethods,
}: Payload) => {
  const {
    setValue,
    getValues,
  } = (formMethods as unknown) as ProfileFormMethods<{
    newGraduate: Pick<
      ProfileFormValues['newGraduate'],
      'pastExperienceAchievementIds'
    >;
  }>;

  const handleSelectPastExperienceAchievements = useCallback(
    (selectedPastExperienceAchievement: PastExperienceAchievement) => {
      const currentPastExperienceAchievementIds = getValues(
        'newGraduate.pastExperienceAchievementIds',
      ) as ProfileFormValues['newGraduate']['pastExperienceAchievementIds'];
      setValue(
        'newGraduate.pastExperienceAchievementIds',
        [
          ...(currentPastExperienceAchievementIds ?? []),
          selectedPastExperienceAchievement.id,
        ],
        { shouldValidate: true, shouldDirty: true },
      );
    },
    [getValues, setValue],
  );

  const handleDeselectPastExperienceAchievements = useCallback(
    (deselectedPastExperienceAchievement: PastExperienceAchievement) => {
      const currentPastExperienceAchievementIds = getValues(
        'newGraduate.pastExperienceAchievementIds',
      ) as ProfileFormValues['newGraduate']['pastExperienceAchievementIds'];

      setValue(
        'newGraduate.pastExperienceAchievementIds',
        (currentPastExperienceAchievementIds ?? []).filter(
          id => id !== deselectedPastExperienceAchievement.id,
        ),
        { shouldValidate: true, shouldDirty: true },
      );
    },
    [getValues, setValue],
  );

  return {
    handleSelectPastExperienceAchievements,
    handleDeselectPastExperienceAchievements,
  };
};

export default useNewGraduatePastExperienceAchievementsFormField;
