import styled from 'styled-components/macro';
import media from 'styles/media';

const PublicSubTitle = styled.p`
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
  font-size: 16px;

  ${media.mobile} {
    font-size: 12px;
  }
`;

export default PublicSubTitle;
