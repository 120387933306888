import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { Controller } from 'react-hook-form';
import { ProfileFormControl, ProfileFormValues } from 'types/profile';
import * as MESSAGES from 'constants/messages';
import media from 'styles/media';
import { BIRTH_MIN_YEAR } from 'constants/profile';
import { range } from 'utils/array';
import { Selectbox } from 'components/atoms';
import { Field } from 'components/molecules';

const SelectBoxes = styled.div`
  display: grid;
  grid-column-gap: 16px;
  grid-template-columns: 176px 176px;

  ${media.mobile} {
    grid-row-gap: 8px;
    grid-template-columns: 1fr;
  }
`;

const yearOfBirthOptions = range(BIRTH_MIN_YEAR, new Date().getFullYear()).map(
  year => ({
    id: year,
    name: `${year}年`,
  }),
);

const monthOfBirthOptions = range(1, 12).map(month => ({
  id: month,
  name: `${month}月`,
}));

type YearMonthOfBirthFormFieldProps = {
  errors: {
    yearOfBirth: string | undefined;
    monthOfBirth: string | undefined;
  };
  control: ProfileFormControl<
    Pick<ProfileFormValues, 'yearOfBirth' | 'monthOfBirth'>
  >;
};

const YearMonthOfBirthFormField: FC<YearMonthOfBirthFormFieldProps> = props => {
  const { errors, control } = props;
  return (
    <Field
      label={MESSAGES.PROFILE.FIELD.YEAR_MONTH_OF_BIRTH.LABEL}
      required
      locked
    >
      <SelectBoxes>
        <Controller
          control={control}
          name="yearOfBirth"
          render={innerProps => (
            <Selectbox
              value={innerProps.value}
              error={errors.yearOfBirth}
              options={yearOfBirthOptions}
              aria-label={MESSAGES.PROFILE.FIELD.YEAR_MONTH_OF_BIRTH.YEAR.LABEL}
              onChange={innerProps.onChange}
              onBlur={innerProps.onBlur}
            />
          )}
        />

        <Controller
          control={control}
          name="monthOfBirth"
          render={innerProps => (
            <Selectbox
              value={innerProps.value}
              error={errors.monthOfBirth}
              options={monthOfBirthOptions}
              aria-label={
                MESSAGES.PROFILE.FIELD.YEAR_MONTH_OF_BIRTH.MONTH.LABEL
              }
              onChange={innerProps.onChange}
              onBlur={innerProps.onBlur}
            />
          )}
        />
      </SelectBoxes>
    </Field>
  );
};

export default YearMonthOfBirthFormField;
