import React, { FC, HTMLAttributes } from 'react';
import styled from 'styled-components/macro';
import media from 'styles/media';
import * as MESSAGES from 'constants/messages';
import { MissionForInnovator } from 'types/mission';
import { WrapText, LinkText } from 'components/atoms';
import { imageSelector } from 'utils/image';
import { Title, SubTitle } from '../../Typography';

const FieldTitle = styled(SubTitle)`
  ${media.tablet} {
    display: none;
  }
`;

const FieldTitleMobile = styled(Title)`
  display: none;

  ${media.tablet} {
    display: block;
  }
`;

const MissionMembers = styled.div`
  width: 100%;
`;

const MissionMember = styled.div`
  margin-bottom: 32px;
  overflow: hidden;

  &:last-child {
    margin-bottom: 0;
  }
`;

const MemberIconName = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const MissionMemberName = styled(SubTitle).attrs({ as: 'div' })`
  width: 188px;
`;

const MissionMemberCareer = styled(WrapText)`
  overflow: auto;
  font-size: 12px;
  line-height: 1.5;
`;

const IconImage = styled.img`
  width: 64px;
  min-width: 64px;
  height: 64px;
  min-height: 64px;
  margin-right: 16px;
`;

type Props = {
  missionMembers: MissionForInnovator['missionMembers'] | undefined;
} & HTMLAttributes<HTMLDivElement>;

const MissionMembersField: FC<Props> = ({ missionMembers, ...rest }) => {
  return (
    <div {...rest}>
      <FieldTitle>
        {MESSAGES.MISSION_CARD.FIELD.MISSION_MEMBERS.LABEL}
      </FieldTitle>
      <FieldTitleMobile>
        {MESSAGES.MISSION_CARD.FIELD.MISSION_MEMBERS.LABEL}
      </FieldTitleMobile>
      <MissionMembers>
        {(missionMembers ?? []).map(missionMember => (
          <MissionMember key={missionMember.missionMemberId}>
            <MemberIconName>
              <IconImage
                src={imageSelector(missionMember.iconImage, 'small') ?? ''}
                alt={missionMember.name}
                data-testid="mission-member-icon-image"
              />
              <MissionMemberName data-testid="mission-member-name">
                <WrapText>{missionMember.name}</WrapText>
              </MissionMemberName>
            </MemberIconName>
            <MissionMemberCareer data-testid="mission-member-career">
              <LinkText color="secondary">{missionMember.career}</LinkText>
            </MissionMemberCareer>
          </MissionMember>
        ))}
      </MissionMembers>
    </div>
  );
};

export default MissionMembersField;
