export default {
  TITLE: 'Reset your password',
  SUB_TITLE: 'パスワード再設定',
  SEND_EMAIL: {
    FIELD: {
      EMAIL: 'メールアドレス',
    },
    DESCRIPTION:
      'ご登録されたメールアドレスを入力して「送信」ボタンを押してください。\nパスワード再設定用のURL を記載したメールが送信されます。',
    COMPLETED: {
      MAIN: 'パスワード再設定用のメールを送信しました',
      DESCRIPTION:
        '届いたメールに記載されているURLから\nパスワードの再設定を行ってください。',
    },
    BUTTON: {
      SUBMIT: '送信',
    },
  },
  PASSWORD_FORM: {
    FIELD: {
      PASSWORD: '新しいパスワード',
      CONFIRM_PASSWORD: '新しいパスワード（確認用）',
    },
    COMPLETED: {
      MAIN: 'パスワードの再設定が完了しました',
    },
    BUTTON: {
      SUBMIT: '送信',
      TO_LOGIN: 'ログインページへ',
    },
  },
};
