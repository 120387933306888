import { UseFormMethods } from 'react-hook-form';
import * as MESSAGES from 'constants/messages';
import { ProfileFormMethods, ProfileFormValues } from 'types/profile';

type Payload = {
  formMethods: UseFormMethods<any>;
};

const NewGraduateProgrammingExperiencesFormField = ({
  formMethods,
}: Payload) => {
  const { errors } = (formMethods as unknown) as ProfileFormMethods<{
    newGraduate: Pick<
      ProfileFormValues['newGraduate'],
      'programmingExperiences'
    >;
  }>;

  const newGraduateRequiredAllProgrammingExperienceFieldsErrors = (() => {
    const programmingExperienceErrors = [
      ...(errors.newGraduate?.programmingExperiences ?? []), // To avoid empty array value, copy errors.newGraduate?.programmingExperiences.
    ];

    return programmingExperienceErrors?.map(programmingExperienceError => {
      const languageNameErrorType =
        programmingExperienceError?.languageName?.type;
      const levelIdErrorType = programmingExperienceError?.levelId?.type;

      return [languageNameErrorType, levelIdErrorType].includes(
        'requiredAllProgrammingExperiencesFields',
      )
        ? MESSAGES.ERROR.REQUIRED_INPUT_ALL_PROGRAMMING_EXPERIENCES_FIELDS
        : undefined;
    });
  })();

  return {
    newGraduateRequiredAllProgrammingExperienceFieldsErrors,
  };
};

export default NewGraduateProgrammingExperiencesFormField;
