import React, { FC } from 'react';
import { CareerProfile } from 'proto/v1/apimodel/apimodel';
import { getCareerJobHistoryLabel } from 'utils/profile/fields';
import { LabelBar } from 'components/atoms';
import {
  NameField,
  WorkingPeriodField,
  OccupationsField,
  PositionField,
} from './SubFields';

type CareerJobFieldProps = {
  job: CareerProfile['job'] | undefined;
};

const CareerJobField: FC<CareerJobFieldProps> = props => {
  const { job } = props;

  if (!job) return null;

  return (
    <>
      {job.map((jobHistory, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index}>
          {job.length > 1 && (
            <LabelBar data-testid="job-history-label">
              {getCareerJobHistoryLabel(index)}
            </LabelBar>
          )}
          <NameField name={jobHistory.name} />

          <WorkingPeriodField
            startYear={jobHistory.startYear}
            startMonth={jobHistory.startMonth}
            endYear={jobHistory.endYear}
            endMonth={jobHistory.endMonth}
          />

          <OccupationsField occupations={jobHistory.occupations} />

          <PositionField position={jobHistory.position} />
        </div>
      ))}
    </>
  );
};

export default CareerJobField;
