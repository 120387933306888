import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { UseFormMethods } from 'react-hook-form';
import apiCommon from 'external/api/common';
import {
  MAX_RESUME_FILE_SIZE_BYTES,
  RESUME_FILE_DEFAULT,
} from 'constants/profile';
import * as MESSAGES from 'constants/messages';
import { UiActions } from 'modules/ui';
import RequestError from 'classes/RequestError';
import {
  ProfileFormMethods,
  ResumeFileFormValue,
  ProfileFormValues,
} from 'types/profile';
import useErrorNotification from 'hooks/useErrorNotification';

type Payload = {
  formMethods: UseFormMethods<any>;
};

const useResumeFileFormField = ({ formMethods }: Payload) => {
  const { setValue } = (formMethods as unknown) as ProfileFormMethods<{
    career: {
      resumeFile: ProfileFormValues['career']['resumeFile'];
    };
  }>;
  const dispatch = useDispatch();
  const { handleRequestError, openErrorNotification } = useErrorNotification();
  // Use useState not useWatch to avoid type error in testing.
  const [resumeFile, setResumeFile] = useState<ResumeFileFormValue>(
    RESUME_FILE_DEFAULT,
  );

  const handleDropResumeFile = useCallback(
    (files: File[]) => {
      if (!files || files.length === 0) return;
      const reader = new FileReader();

      if (files[0].size > MAX_RESUME_FILE_SIZE_BYTES) {
        openErrorNotification({
          message: MESSAGES.ERROR.OVER_MAX_RESUME_FILE_SIZE,
        });
        return;
      }

      reader.onload = async () => {
        dispatch(UiActions.setLoading(true));
        let data;
        try {
          ({ data } = await apiCommon.uploadFile({
            data: reader.result as string,
            name: files[0].name,
          }));
        } catch (error: unknown) {
          dispatch(UiActions.setLoading(false));
          if (error instanceof RequestError) {
            handleRequestError(error);
          }
          return;
        }
        const value = {
          name: files[0].name,
          size: files[0].size,
          key: data.key,
          url: '',
        };
        setValue('career.resumeFile', value);
        setResumeFile(value);
        dispatch(UiActions.setLoading(false));
      };
      reader.readAsDataURL(files[0]);
    },
    [dispatch, handleRequestError, openErrorNotification, setValue],
  );

  const handleRemoveResumeFile = useCallback(() => {
    setValue('career.resumeFile', RESUME_FILE_DEFAULT, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setResumeFile(RESUME_FILE_DEFAULT);
  }, [setValue]);

  return {
    resumeFile,
    setResumeFile,
    handleDropResumeFile,
    handleRemoveResumeFile,
  };
};

export default useResumeFileFormField;
