import { useState, useCallback } from 'react';
import useLongPress from 'hooks/useLongPress';
import useMediaQuery from 'hooks/useMediaQuery';

const useChatMessageMenu = () => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [showMenuIcon, setShowMenuIcon] = useState<boolean>(false);
  const { isTablet } = useMediaQuery();

  const openMenuIcon = useCallback(() => {
    setShowMenuIcon(true);
  }, []);

  const openMenu = useCallback(() => {
    setShowMenu(true);
  }, []);

  const closeMenu = useCallback(() => {
    setShowMenuIcon(false);
    setShowMenu(false);
  }, []);

  const toggleMenu = useCallback(() => {
    setShowMenu(prev => !prev);
  }, []);

  const handleClickCopyMenu = useCallback(() => {
    closeMenu();
  }, [closeMenu]);

  const longPressHandlers = useLongPress(() => {
    openMenu();
  }, 250);

  const handleClick = useCallback(() => {
    if (isTablet) return;
    openMenuIcon();
  }, [isTablet, openMenuIcon]);

  const handleMouseEnter = useCallback(() => {
    if (isTablet) return;
    openMenuIcon();
  }, [isTablet, openMenuIcon]);

  const handleMouseLeave = useCallback(() => {
    if (isTablet) {
      longPressHandlers.handleMouseLeave();
    }
    closeMenu();
  }, [closeMenu, isTablet, longPressHandlers]);

  const handleMouseDown = useCallback(() => {
    if (!isTablet) return;
    longPressHandlers.handleMouseDown();
  }, [isTablet, longPressHandlers]);

  const handleTouchStart = useCallback(() => {
    if (!isTablet) return;
    longPressHandlers.handleTouchStart();
  }, [isTablet, longPressHandlers]);

  const handleTouchEnd = useCallback(() => {
    if (!isTablet) return;
    longPressHandlers.handleTouchEnd();
  }, [isTablet, longPressHandlers]);

  return {
    showMenu,
    showMenuIcon,
    toggleMenu,
    handleClickCopyMenu,
    handleClick,
    handleMouseEnter,
    handleMouseLeave,
    handleMouseDown,
    handleTouchStart,
    handleTouchEnd,
  };
};

export default useChatMessageMenu;
