import React, { FC } from 'react';
import * as MESSAGES from 'constants/messages';
import { Label, ProfileField, ProfileContent } from 'components/atoms';
import { CareerJobHistory } from 'types/profile';

type PositionFieldProps = {
  position: CareerJobHistory['position'];
};

const PositionField: FC<PositionFieldProps> = props => {
  const { position } = props;

  return (
    <ProfileField data-testid="career-job-position-field">
      <Label>{MESSAGES.PROFILE.FIELD.CAREER_JOB.POSITION.LABEL}</Label>
      <ProfileContent>{position?.name}</ProfileContent>
    </ProfileField>
  );
};

export default PositionField;
