import React, { FC } from 'react';
import * as MESSAGES from 'constants/messages';
import { Label, ProfileField, ProfileContent } from 'components/atoms';
import { Innovator } from 'proto/v1/apimodel/apimodel';

type Props = {
  innovatorId: Innovator['innovatorId'];
};

const MemberNumberField: FC<Props> = ({ innovatorId }) => {
  return (
    <ProfileField data-testid="member-number-field">
      <Label>{MESSAGES.PROFILE.FIELD.MEMBER_NUMBER.LABEL}</Label>
      <ProfileContent>{innovatorId}</ProfileContent>
    </ProfileField>
  );
};

export default MemberNumberField;
