import React, { FC, useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import media from 'styles/media';
import { useHistory, useLocation } from 'react-router-dom';
import * as MESSAGES from 'constants/messages';
import { getMessageNotificationsTotalCount } from 'modules/notification';
import * as PROTECTED_ROUTES from 'routes/constants/protected';
import { ModalConfirm } from 'components/molecules';

const Content = styled.div`
  width: 581px;
  height: 48px;

  ${media.mobile} {
    width: auto;
    height: 26px;
  }

  ${media.smallMobile} {
    height: 72px;
  }
`;

const MessageConfirmModal: FC = () => {
  const history = useHistory();
  const location = useLocation();

  const unreadMessageCount = useSelector(getMessageNotificationsTotalCount);

  const [showMessageConfirmModal, setShowMessageConfirmModal] = useState<
    boolean
  >(false);
  const [canShowMessageConfirmModal, setCanShowMessageConfirmModal] = useState<
    boolean
  >(!location.pathname.includes(PROTECTED_ROUTES.MESSAGES));

  const closeMessageConfirmModal = useCallback(() => {
    setShowMessageConfirmModal(false);
  }, []);

  const moveToMessagesPage = useCallback(() => {
    history.push(PROTECTED_ROUTES.MESSAGES);
    setShowMessageConfirmModal(false);
  }, [history]);

  useEffect(() => {
    if (!canShowMessageConfirmModal || unreadMessageCount === undefined) {
      return;
    }
    setShowMessageConfirmModal(unreadMessageCount > 0);
    setCanShowMessageConfirmModal(false);
  }, [canShowMessageConfirmModal, unreadMessageCount]);

  return (
    <ModalConfirm
      show={showMessageConfirmModal}
      title={MESSAGES.MESSAGES.MODAL.CONFIRM_MESSAGES.TITLE}
      closeModal={closeMessageConfirmModal}
      confirm={moveToMessagesPage}
      cancelCaption={MESSAGES.COMMON.BUTTON.CLOSE}
      confirmCaption={MESSAGES.MESSAGES.MODAL.CONFIRM_MESSAGES.TO_MESSAGE}
    >
      <Content>{MESSAGES.MESSAGES.MODAL.CONFIRM_MESSAGES.CONTENT}</Content>
    </ModalConfirm>
  );
};

export default MessageConfirmModal;
