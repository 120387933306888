export default {
  PUBLISH_SETTING_DESCRIPTION:
    'プロフィール登録した情報を公開する企業の種類を選択してください。(複数選択可)',
  BLOCK_COMPANY_DESCRIPTION:
    'プロフィール情報を公開したくない企業を入力してください。',
  NO_BLOCK_COMPANIES: '現在ブロックしている企業はありません',
  FIELD: {
    LABEL_CORPORATE_ATTRIUBTES: '企業属性',
    LABEL_CATEGORIES: '事業領域',
    LABEL_LOCATIONS: '所在地',
    LABEL_MEMBER_RANGES: '従業員規模',
    LABEL_FUNDS: '資金調達額',
    LABEL_PUBLISH: 'プロフィール公開設定',
    LABEL_PUBLISH_SWITCH: '企業に対してプロフィールを非公開にする',
    PLACEHOLDER_ALL: 'すべて',
    PLACEHOLDER_INPUT_COMPANY_NAME: '会社名を入力してください',
  },
  BUTTON: {
    SUBMIT: '設定する',
    REGISTER: '登録',
    DELETE: '削除',
  },
  DIALOG: {
    UNPUBLISH: {
      TITLE: 'プロフィールを非公開にしてよろしいでしょうか？',
      CONTENT:
        'プロフィールを非公開にすると、企業からのスカウトが届かなくなり、メッセージの送受信も行えなくなります。\nスカウト受信やメッセージの送受信を希望する場合は、プロフィールを公開にしてください。',
    },
    CLOSED: {
      TITLE: 'プロフィールが非公開に設定されています。',
      CONTENT_PROFILE_PUBLISH_SETTING_LINK: 'プロフィール公開設定',
      CONTENT_1:
        '採用・契約が成立したのであなたのプロフィールは非公開になっています。\nもう一度サービスを利用するには、',
      CONTENT_2: 'をONにしてください。',
      CONTENT_NEW_GRADUATE_1:
        '就職が決定したため、あなたのプロフィールは非公開になっています。\nもう一度サービスを利用するには、',
      CONTENT_NEW_GRADUATE_2: 'をONにしてください。',
    },
    UPDATE_COMPLETED: {
      CONTENT: '設定が完了しました。',
    },
  },
};
