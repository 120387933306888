import React, { FC } from 'react';
import * as MESSAGES from 'constants/messages';
import { Label, ProfileField, ProfileContent } from 'components/atoms';
import { Innovator } from 'proto/v1/apimodel/apimodel';
import { interpolateMessage } from 'utils/string';

type YearMonthOfBirthFieldProps = {
  yearOfBirth: Innovator['yearOfBirth'];
  monthOfBirth: Innovator['monthOfBirth'];
};

const YearMonthOfBirthField: FC<YearMonthOfBirthFieldProps> = props => {
  const { yearOfBirth, monthOfBirth } = props;

  return (
    <ProfileField data-testid="year-month-of-birth-field">
      <Label>{MESSAGES.PROFILE.FIELD.YEAR_MONTH_OF_BIRTH.LABEL}</Label>
      <ProfileContent>
        {interpolateMessage(MESSAGES.PROFILE.YEAR_MONTH, {
          year: yearOfBirth,
          month: monthOfBirth,
        })}
      </ProfileContent>
    </ProfileField>
  );
};

export default YearMonthOfBirthField;
