import { ButtonHTMLAttributes } from 'react';
import styled from 'styled-components/macro';
import buttonThemes, { ThemesType } from './buttonThemes';

type BaseButtonType = {
  buttonTheme?: ThemesType;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const BaseButton = styled.button<BaseButtonType>`
  transition: background-color 300ms ease;
  border: none;
  border-radius: 4px;
  outline: none;
  font-weight: bold;
  cursor: pointer;

  &:disabled {
    cursor: default;
  }

  ${({ buttonTheme = 'default' }) => buttonThemes[buttonTheme]}
`;

export default BaseButton;
