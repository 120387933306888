import React, { forwardRef, SelectHTMLAttributes } from 'react';
import styled, { css } from 'styled-components/macro';
import * as MESSAGES from 'constants/messages';
import theme from 'styles/theme';
import media from 'styles/media';
import iconArrowDown from 'assets/svg/arrowDown.svg';

const StyledSelectbox = styled.select<SelectboxProps>`
  width: 100%;
  height: 40px;
  padding: 0 26px 0 10px;
  border: 1px solid
    ${({ error }) => (error ? theme.baseError : theme.borderDefault)};
  border-radius: 4px;
  outline: none;
  background-color: ${theme.baseWhite};
  background-image: url(${iconArrowDown});
  background-repeat: no-repeat;
  background-position: right 14px center;
  background-size: 12px 10px;
  color: ${theme.textPrimary};
  appearance: none;
  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${theme.borderDefault};
      color: ${theme.textSecondary};
    `}

  &:focus,
  &:active {
    border: 1px solid ${theme.borderActive};
    box-shadow: none;
  }

  ${media.mobile} {
    font-size: 16px;
  }
`;

const ErrorMessage = styled.p`
  margin-top: 4px;
  color: ${theme.baseError};
  font-size: 14px;
  line-height: 1.5;

  ${media.mobile} {
    font-size: 12px;
  }
`;

type Option = { [key: string]: any };

type SelectboxProps = SelectHTMLAttributes<HTMLSelectElement> & {
  options: Option[];
  idName?: keyof Option;
  textName?: keyof Option;
  error?: string;
  noSelectValue?: string | number;
  noSelectText?: string;
  noErrorMessage?: boolean;
};

const Selectbox = forwardRef<HTMLSelectElement | null, SelectboxProps>(
  (props, ref) => {
    const {
      options,
      idName = 'id',
      textName = 'name',
      error,
      noSelectValue,
      noSelectText,
      noErrorMessage = false,
    } = props;

    const renderOption = (option: Option) => {
      if (!(idName in option)) {
        throw new Error('idName is not in options');
      }
      if (!(textName in option)) {
        throw new Error('textName does not in options');
      }

      return (
        <option
          key={option[idName]}
          value={option[idName]}
          aria-label={option[textName] as string}
        >
          {option[textName]}
        </option>
      );
    };

    return (
      <>
        <StyledSelectbox {...props} ref={ref}>
          {noSelectValue !== undefined && (
            <option value={noSelectValue} hidden>
              {noSelectText ?? MESSAGES.COMMON.SELECT_BOX.NO_SELECT}
            </option>
          )}
          {options.map(option => renderOption(option))}
        </StyledSelectbox>
        {!noErrorMessage && error && (
          <ErrorMessage className="error-message" data-testid="error-message">
            {error}
          </ErrorMessage>
        )}
      </>
    );
  },
);

export default Selectbox;
