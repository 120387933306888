import styled from 'styled-components/macro';

const WrapText = styled.div`
  word-wrap: break-word;
  word-break: break-word;
  white-space: pre-wrap;
  overflow-wrap: break-word;
`;

export default WrapText;
