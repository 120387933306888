import { createSelector } from 'reselect';
import { ActionsUnion, createAction } from './helper';

/*
 * Redux Action actionTypes
 */
export const SET_AFTER_LOGIN_URL = 'config/SET_AFTER_LOGIN_URL';
export const CLEAR_AFTER_LOGIN_URL = 'config/CLEAR_AFTER_LOGIN_URL';

/*
 * Redux Actions
 */
export const ConfigActions = {
  setAfterLoginUrl: (url: string) => createAction(SET_AFTER_LOGIN_URL, url),
  clearAfterLoginUrl: () => createAction(CLEAR_AFTER_LOGIN_URL),
};

export type Actions = ActionsUnion<typeof ConfigActions>;

/*
 * Selectors
 */
export const getConfig = (state: any): ConfigState => state.config;

export const getAfterLoginUrl = createSelector(
  [getConfig],
  state => state.afterLoginUrl,
);

/*
 * Reducer
 */
export type ConfigState = {
  afterLoginUrl?: string;
};

const initState: ConfigState = {
  afterLoginUrl: undefined,
};

export default function reducer(state = initState, action: Actions) {
  if (!action) return state;

  switch (action.type) {
    case SET_AFTER_LOGIN_URL: {
      return { ...state, afterLoginUrl: action.payload };
    }
    case CLEAR_AFTER_LOGIN_URL: {
      return { ...state, afterLoginUrl: initState.afterLoginUrl };
    }
    default:
      return state;
  }
}
