import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getInnovator, AccountActions } from 'modules/account';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';
import { UiActions } from 'modules/ui';
import { NO_SELECT_NUMBER } from 'constants/form';
import apiInnovator from 'external/api/innovator';
import { UpdateInnovatorRequest } from 'proto/v1/innovatorservice/innovatorservice';
import {
  CareerProfileJobHistoryFormValues,
  ProfileFormItems,
} from 'types/profile';
import convertToInnovatorToUpdate from 'utils/profile/convertToInnovatorToUpdate';
import { getDefaultCareerJobValue } from 'utils/profile/defaultFormValues';
import { getCareerJobValueToUpdate } from 'utils/profile/formValuesToUpdate';
import {
  careerNumberOfJobChangesIdSchema,
  careerJobSchema,
} from 'utils/profile/schema';
import RequestError from 'classes/RequestError';
import useErrorNotification from 'hooks/useErrorNotification';
import useCareerWorkingPeriodFormField from 'hooks/profile/useCareerWorkingPeriodFormField';

const schema = object().shape({
  career: object().shape({
    numberOfJobChangesId: careerNumberOfJobChangesIdSchema,
    job: careerJobSchema,
  }),
});

const defaultValues: CareerProfileJobHistoryFormValues = {
  career: {
    job: [],
    numberOfJobChangesId: NO_SELECT_NUMBER,
  },
};

type Payload = {
  formItems: ProfileFormItems;
  onShowContent: () => void;
  onOpenCompletedModal: () => void;
};

const useCareerProfileBasicInfoForm = ({
  formItems,
  onShowContent,
  onOpenCompletedModal,
}: Payload) => {
  const dispatch = useDispatch();
  const { handleRequestError } = useErrorNotification();
  const innovator = useSelector(getInnovator);

  const innovatorToUpdate = useMemo(
    () => innovator && convertToInnovatorToUpdate(innovator),
    [innovator],
  );

  const onSubmit = useCallback(
    async (values: CareerProfileJobHistoryFormValues) => {
      const valuesToUpdate: UpdateInnovatorRequest = {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        ...innovatorToUpdate!,
        career: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          ...innovatorToUpdate!.career!,
          job: getCareerJobValueToUpdate(values.career.job),
          numberOfJobChangesId: values.career.numberOfJobChangesId,
        },
      };
      let data;
      try {
        ({ data } = await apiInnovator.updateInnovator(valuesToUpdate));
      } catch (error: unknown) {
        if (error instanceof RequestError) {
          dispatch(UiActions.setLoading(false));
          handleRequestError(error);
        }
        return;
      }
      dispatch(AccountActions.setInnovator(data.innovator));
      dispatch(UiActions.setLoading(false));
      onShowContent();
      onOpenCompletedModal();
    },
    [
      dispatch,
      handleRequestError,
      innovatorToUpdate,
      onOpenCompletedModal,
      onShowContent,
    ],
  );

  const formMethods = useForm<CareerProfileJobHistoryFormValues>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues,
  });

  const { reset } = formMethods;

  const { handleChangeEndWorkingPeriod } = useCareerWorkingPeriodFormField({
    formMethods,
  });

  // Set default values
  useEffect(() => {
    if (!innovatorToUpdate) return;

    const values: CareerProfileJobHistoryFormValues = {
      career: {
        job: getDefaultCareerJobValue(innovatorToUpdate.career?.job),
        numberOfJobChangesId:
          innovatorToUpdate.career?.numberOfJobChangesId ?? NO_SELECT_NUMBER,
      },
    };

    reset(values);
  }, [formItems.countries, innovatorToUpdate, reset]);

  return {
    formMethods,
    onSubmit,
    handleChangeEndWorkingPeriod,
  };
};

export default useCareerProfileBasicInfoForm;
