import React, { VFC } from 'react';
import { MissionForInnovator } from 'types/mission';
import MissionCard from './MissionCard';
import MissionCardMobile from './MissionCardMobile';

export type Props = {
  show: boolean;
  mission: MissionForInnovator | undefined;
  onExited: () => void;
  onClose: () => void;
};

const MissionCardIndex: VFC<Props> = props => {
  return (
    <>
      <MissionCard {...props} />
      <MissionCardMobile {...props} />
    </>
  );
};

export default MissionCardIndex;
