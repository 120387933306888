import React, { FC, useState, useCallback } from 'react';
import { Controller } from 'react-hook-form';
import styled from 'styled-components/macro';
import media from 'styles/media';
import theme from 'styles/theme';
import {
  ProfileFormItems,
  ProfileFormValues,
  ProfileFormControl,
  ProfileFormRegister,
  ProfileFormMethods,
} from 'types/profile';
import * as MESSAGES from 'constants/messages';
import { PROFILE_FIELDS_MAX_LENGTH } from 'constants/profile';
import { NO_SELECT_NUMBER } from 'constants/form';
import { Selectbox, TextInput, ErrorMessage } from 'components/atoms';
import { FieldLabel } from 'components/molecules';
import AboutDttScoreModal from './AboutDttScoreModal';

const Wrapper = styled.div`
  margin-bottom: 40px;

  ${media.mobile} {
    margin-bottom: 32px;
  }
`;

const Label = styled.div`
  display: flex;
`;

const AboutDttLink = styled.span`
  align-self: flex-end;
  margin-bottom: 12px;
  margin-left: 12px;
  color: ${theme.basePrimary};
  cursor: pointer;

  ${media.mobile} {
    margin-bottom: 8px;
  }

  &:hover {
    opacity: 0.8;
  }
`;

const Fields = styled.div`
  display: flex;

  ${media.mobile} {
    flex-direction: column;
  }
`;

const DttScoreField = styled.div`
  width: 176px;
  margin-right: 8px;

  ${media.mobile} {
    width: 100%;
    margin-right: 0;
    margin-bottom: 8px;
  }
`;

const DttLevelField = styled.div`
  width: 176px;

  ${media.mobile} {
    width: 100%;
  }
`;

type DttRankFormFieldProps = {
  errors: {
    dttScore: string | undefined;
    requiredInputAll: string | undefined;
  };
  dttRanks: ProfileFormItems['dttRanks'];
  control: ProfileFormControl<Pick<ProfileFormValues, 'dttRankId'>>;
  register: ProfileFormRegister<Pick<ProfileFormValues, 'dttScore'>>;
  trigger: ProfileFormMethods<
    Pick<ProfileFormValues, 'dttScore' | 'dttRankId'>
  >['trigger'];
};

const DttRankFormField: FC<DttRankFormFieldProps> = props => {
  const { errors, dttRanks, control, register, trigger } = props;
  const [showAboutDttScoreModal, setShowAboutDttScoreModal] = useState<boolean>(
    false,
  );

  const handleOpenAboutDttScoreModal = useCallback(() => {
    setShowAboutDttScoreModal(true);
  }, []);

  const handleCloseAboutDttScoreModal = useCallback(() => {
    setShowAboutDttScoreModal(false);
  }, []);

  return (
    <Wrapper>
      <Label>
        <FieldLabel label={MESSAGES.PROFILE.FIELD.DTT.LABEL} />
        <AboutDttLink onClick={handleOpenAboutDttScoreModal}>
          {MESSAGES.PROFILE.FIELD.DTT.ABOUT_DTT}
        </AboutDttLink>
      </Label>
      <Fields>
        <DttScoreField>
          <TextInput
            enterKeyHint="enter"
            name="dttScore"
            error={errors.dttScore}
            maxLength={PROFILE_FIELDS_MAX_LENGTH.NEW_GRADUATE_DTT_SCORE}
            aria-label={MESSAGES.PROFILE.FIELD.DTT.DTT_SCORE.LABEL}
            placeholder={MESSAGES.PROFILE.FIELD.DTT.DTT_SCORE.PLACEHOLDER}
            noErrorMessage
            onChange={() => {
              trigger('dttRankId');
            }}
            onBlur={() => {
              trigger('dttRankId');
            }}
            ref={register}
          />
          {errors.dttScore && (
            <ErrorMessage
              data-testid="dtt-score-error-message"
              style={{ whiteSpace: 'nowrap', marginTop: '4px' }}
            >
              {errors.dttScore}
            </ErrorMessage>
          )}
        </DttScoreField>

        <DttLevelField>
          <Controller
            control={control}
            name="dttRankId"
            render={innerProps => (
              <Selectbox
                value={innerProps.value}
                options={[
                  {
                    id: NO_SELECT_NUMBER,
                    name: MESSAGES.COMMON.SELECT_BOX.NO_SELECT,
                  },
                  ...dttRanks,
                ]}
                aria-label={MESSAGES.PROFILE.FIELD.DTT.DTT_RANK.LABEL}
                onChange={e => {
                  innerProps.onChange(e);
                  trigger('dttScore');
                }}
                onBlur={() => {
                  innerProps.onBlur();
                  trigger('dttScore');
                }}
              />
            )}
          />
        </DttLevelField>
      </Fields>
      {errors.requiredInputAll && (
        <ErrorMessage>{errors.requiredInputAll}</ErrorMessage>
      )}
      <AboutDttScoreModal
        show={showAboutDttScoreModal}
        onClose={handleCloseAboutDttScoreModal}
      />
    </Wrapper>
  );
};

export default DttRankFormField;
