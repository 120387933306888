import React, { FC } from 'react';
import styled, { css } from 'styled-components/macro';
import theme from 'styles/theme';
import media from 'styles/media';

const Wrapper = styled.div<{ width: string | undefined }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 448px;
  margin-bottom: 82px;

  ${media.mobile} {
    margin-bottom: 60px;
    padding-right: 36px;
    padding-left: 36px;
  }
`;

const Step = styled.div<{ isActive: boolean; isEnabledPointer: boolean }>`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  transition: background-color 500ms ease 100ms;
  border-radius: 50%;
  background-color: ${({ isActive }) =>
    isActive ? theme.basePrimary : theme.borderDefault};
  color: ${theme.baseWhite};
  font-size: 20px;
  cursor: ${({ isEnabledPointer }) =>
    isEnabledPointer ? 'pointer' : 'initial'};

  ${media.mobile} {
    width: 24px;
    height: 24px;
    font-size: 14px;
  }

  &:hover {
    opacity: ${({ isEnabledPointer }) => (isEnabledPointer ? 0.8 : 1.0)};
  }
`;

const StepLabel = styled.div<{ isCurrent: boolean }>`
  position: absolute;
  top: 42px;
  left: 50%;
  width: 120px;
  transform: translateX(-50%);
  color: ${theme.textPrimary};
  font-size: 12px;
  line-height: 1.5;
  text-align: center;
  white-space: pre-wrap;
  ${({ isCurrent }) =>
    isCurrent &&
    css`
      font-weight: bold;
      color: ${theme.basePrimary};
    `}

  ${media.mobile} {
    top: 30px;
    font-size: 10px;
  }
`;

const StepConnector = styled.span<{ isActive: boolean }>`
  display: inline-block;
  flex: 1;
  transition: transform 0.4s linear;
  transition: border-color 300ms ease 0s;
  border-top-width: 1px;
  border-top-style: solid;
  border-color: ${({ isActive }) =>
    isActive ? theme.basePrimary : theme.borderDefault};
`;

type StepperProps = {
  currentStepIndex: number;
  stepLabels: string[];
  width?: string;
  onCheckStepSelectable?: (index: number) => boolean;
  onClickStep: (index: number) => void;
};

const Stepper: FC<StepperProps> = ({
  currentStepIndex,
  stepLabels,
  width,
  onCheckStepSelectable,
  onClickStep,
}) => {
  return (
    <Wrapper width={width}>
      {stepLabels.map((stepLabel, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={index}>
          {index !== 0 && (
            <StepConnector isActive={index <= currentStepIndex} />
          )}
          <Step
            isActive={index <= currentStepIndex}
            isEnabledPointer={onCheckStepSelectable?.(index) ?? false}
            onClick={() => {
              onClickStep(index);
            }}
            data-testid={`step-${index + 1}`}
          >
            {index + 1}
            <StepLabel isCurrent={currentStepIndex === index}>
              {stepLabel}
            </StepLabel>
          </Step>
        </React.Fragment>
      ))}
    </Wrapper>
  );
};

export default Stepper;
