import React, { FC } from 'react';
import * as MESSAGES from 'constants/messages';
import { Label, ProfileField, ProfileContent } from 'components/atoms';
import { Innovator } from 'proto/v1/apimodel/apimodel';

type NameKanaFieldProps = {
  lastNameKana: Innovator['lastNameKana'];
  firstNameKana: Innovator['firstNameKana'];
};

const NameKanaField: FC<NameKanaFieldProps> = props => {
  const { lastNameKana, firstNameKana } = props;

  return (
    <ProfileField data-testid="name-kana-field">
      <Label>{MESSAGES.PROFILE.FIELD.NAME_KANA.LABEL}</Label>
      <ProfileContent>
        {lastNameKana}
        {firstNameKana}
      </ProfileContent>
    </ProfileField>
  );
};

export default NameKanaField;
