import { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getMatterStatus } from 'modules/matter';
import { MATTER_STATUS_ID } from 'constants/matter';

const useProfile = () => {
  const { statusId } = useSelector(getMatterStatus);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [
    showUpdateProfileCompletedModal,
    setShowUpdateProfileCompletedModal,
  ] = useState<boolean>(false);

  const handleShowEdit = useCallback(() => {
    setIsEdit(true);
  }, []);

  const handleShowContent = useCallback(() => {
    setIsEdit(false);
  }, []);

  const openUpdateProfileCompletedModal = useCallback(() => {
    setShowUpdateProfileCompletedModal(true);
  }, []);

  const closeUpdateProfileCompletedModal = useCallback(() => {
    setShowUpdateProfileCompletedModal(false);
  }, []);

  const canEdit =
    statusId !== MATTER_STATUS_ID.DISAPPROVED &&
    statusId !== MATTER_STATUS_ID.WAITING;

  return {
    canEdit,
    isEdit,
    handleShowEdit,
    handleShowContent,
    showUpdateProfileCompletedModal,
    openUpdateProfileCompletedModal,
    closeUpdateProfileCompletedModal,
  };
};

export default useProfile;
