import { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { onAuthStateChanged } from 'firebase/auth';
import { UseFormMethods } from 'react-hook-form';
import axios, { CancelTokenSource } from 'axios';
import { UiActions } from 'modules/ui';
import * as MESSAGES from 'constants/messages';
import { ReactSelectOption } from 'types/form';
import apiCommon from 'external/api/common';
import apiFirebase from 'external/firebase/firebase';
import RequestError from 'classes/RequestError';
import { ProfileFormMethods, ProfileFormValues } from 'types/profile';
import useErrorNotification from 'hooks/useErrorNotification';
import useReactSelectInput from 'hooks/useReactSelectInput';
import useSelectOptionsState from 'hooks/useSelectOptionsState';

type Payload = {
  formMethods: UseFormMethods<any>;
};

type Value = {
  career: Pick<ProfileFormValues['career'], 'skills'>;
};

const useCareerSkillsFormField = ({ formMethods }: Payload) => {
  const dispatch = useDispatch();
  const { handleRequestError } = useErrorNotification();

  const {
    init,
    startLoading,
    endLoading,
    startCreating,
    endCreating,
    fetchFirstDone,
    fetchMoreDone,
    setInputValue,
    state: skillsOptionsState,
    isCreatingOptionRef,
  } = useSelectOptionsState();

  const [exampleSkills, setExampleSkills] = useState<ReactSelectOption[]>([]);

  const {
    setValue,
    getValues,
  } = (formMethods as unknown) as ProfileFormMethods<Value>;

  const fetchSkillOptions = useCallback(
    async (inputValue: string) => {
      if (!inputValue) return;
      let data;
      startLoading();
      try {
        ({ data } = await apiCommon.getSkills({
          nameStartWith: inputValue,
          skillIds: [],
          limit: 20,
          q: '',
        }));
      } catch (error: unknown) {
        // nothing to do
        endLoading();
        return;
      }
      const options =
        data?.skills.map(skill => ({
          value: skill.id,
          label: skill.name,
        })) ?? [];

      const nextQuery = data.paging?.nextQ ?? '';

      fetchFirstDone({ options, nextQuery });
    },
    [endLoading, fetchFirstDone, startLoading],
  );

  const fetchMoreSkillOptions = useCallback(async () => {
    const hasMore = !!skillsOptionsState.nextQuery;
    if (!hasMore) return;

    let data;
    startLoading();
    try {
      ({ data } = await apiCommon.getSkills({
        nameStartWith: skillsOptionsState.inputValue,
        skillIds: [],
        limit: 20,
        q: skillsOptionsState.nextQuery,
      }));
    } catch (error: unknown) {
      endLoading();
      return;
    }

    const options = data?.skills.map(skill => ({
      value: skill.id,
      label: skill.name,
    }));

    const nextQuery = data.paging?.nextQ ?? '';

    fetchMoreDone({
      options,
      nextQuery,
    });
  }, [
    endLoading,
    fetchMoreDone,
    skillsOptionsState.inputValue,
    skillsOptionsState.nextQuery,
    startLoading,
  ]);

  const handleCreateSkillOption = useCallback(
    async (inputValue: string) => {
      if (!inputValue || isCreatingOptionRef.current) return;
      startCreating();
      let data;
      try {
        ({ data } = await apiCommon.registerSkill({
          name: inputValue,
        }));
      } catch (error: unknown) {
        endCreating();
        if (error instanceof RequestError) {
          handleRequestError(error);
        }
        return;
      }
      setValue(
        'career.skills',
        [
          ...((getValues(
            'career.skills',
          ) as ProfileFormValues['career']['skills']) ?? []),
          { value: data?.skill?.id, label: data?.skill?.name },
        ],
        {
          shouldValidate: true,
          shouldDirty: true,
        },
      );
      endCreating();
    },
    [
      endCreating,
      getValues,
      handleRequestError,
      isCreatingOptionRef,
      setValue,
      startCreating,
    ],
  );

  const { handleInputChange } = useReactSelectInput({
    name: 'career.skills',
    formMethods,
    fetcher: fetchSkillOptions,
    onCreateOption: handleCreateSkillOption,
    initOptionsState: init,
    setInputValue,
    state: skillsOptionsState,
  });

  const fetchExampleSkills = useCallback(
    async (source: CancelTokenSource) => {
      let data;
      try {
        ({ data } = await apiCommon.getExampleSkills(source.token));
      } catch (error: unknown) {
        dispatch(UiActions.setLoading(false));
        if (error instanceof RequestError) {
          handleRequestError(error, MESSAGES.ERROR.FAILED_TO_GET);
        }
        return;
      }
      if (data?.skills) {
        setExampleSkills(
          data?.skills.map(skill => ({
            value: skill.id,
            label: skill.name,
          })),
        );
      }
    },
    [dispatch, handleRequestError],
  );

  const handleClickExampleSkill = useCallback(
    async (targetSkill: ReactSelectOption) => {
      const skills =
        (getValues('career.skills') as ProfileFormValues['career']['skills']) ??
        [];
      const skillIds = skills ? skills.map(s => s.value) : [];
      if (skillIds.includes(targetSkill.value)) {
        return;
      }
      setValue('career.skills', [...skills, targetSkill], {
        shouldValidate: true,
        shouldDirty: true,
      });
    },
    [getValues, setValue],
  );

  // Fetch Example skills
  useEffect(() => {
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    const unsubscribeAuthStateChanged = onAuthStateChanged(
      apiFirebase.auth(),
      user => {
        user ? fetchExampleSkills(source) : source.cancel();
      },
    );

    return () => {
      unsubscribeAuthStateChanged();
      source?.cancel();
    };
  }, [fetchExampleSkills]);

  return {
    skillsOptionsState,
    exampleSkills,
    handleClickExampleSkill,
    fetchMoreSkillOptions,
    handleCreateSkillOption,
    handleInputChangeSkill: handleInputChange,
  };
};

export default useCareerSkillsFormField;
