import { combineReducers } from 'redux';
import uiReducer, { UiState } from './ui';
import accountReducer, { AccountState } from './account';
import errorNotificationReducer, {
  ErrorNotificationState,
} from './errorNotification';
import matterReducer, { MatterState } from './matter';
import notificationReducer, { NotificationState } from './notification';
import configReducer, { ConfigState } from './config';

export type State = {
  ui: UiState;
  account: AccountState;
  matter: MatterState;
  errorNotification: ErrorNotificationState;
  notification: NotificationState;
  config: ConfigState;
};

export default function createReducer() {
  return combineReducers({
    ui: uiReducer,
    account: accountReducer,
    matter: matterReducer,
    errorNotification: errorNotificationReducer,
    notification: notificationReducer,
    config: configReducer,
  });
}
