import React, {
  forwardRef,
  ReactNode,
  HTMLAttributes,
  CSSProperties,
} from 'react';
import styled, { css } from 'styled-components/macro';
import { Check } from 'assets/svg';
import theme from 'styles/theme';

const Wrapper = styled.div`
  position: relative;
  width: auto;
`;

const Label = styled.label`
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`;

const Input = styled.input`
  display: none;
`;

const Shape = styled.div<{ error?: string }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 17.5px;
  height: 17.5px;
  margin: 0.5rem 0.5rem 0.5rem 0;
  transition: background-color 150ms ease-out;
  border: 0.1em solid ${theme.border};
  border-radius: 4px;
  background-color: transparent;
  will-change: background-color;

  /* stylelint-disable selector-type-no-unknown, selector-type-case */
  ${Input}:checked + ${Label} & {
    border-color: ${theme.basePrimary};
    background-color: ${theme.basePrimary};
  }

  ${Input}:disabled + ${Label} & {
    border-color: ${theme.baseDisabled};
    background-color: ${theme.baseDisabled};
  }
  /* stylelint-enable selector-type-no-unknown, selector-type-case */

  ${({ error }) =>
    error &&
    css`
      border-color: ${theme.baseError};
      background-color: ${theme.baseWhite};
    `}
`;

const IconCheck = styled(Check)`
  width: 15px;
  height: 15px;
  stroke-width: 0.1em;
  stroke: currentColor;
  opacity: 0;
  color: ${theme.baseWhite};
  vertical-align: middle;

  /* stylelint-disable selector-type-no-unknown, selector-type-case */
  ${Input}:checked + ${Label} & {
    opacity: 1;
  }

  ${Input}:disabled + ${Label} & {
    opacity: 1;
    color: ${theme.baseWhite};
  }
  /* stylelint-enable selector-type-no-unknown, selector-type-case */
`;

type CheckboxProps = {
  name: string;
  value?: string;
  error?: string;
  disabled?: boolean;
  children?: ReactNode;
  style?: CSSProperties;
} & HTMLAttributes<HTMLInputElement>;

const Checkbox = forwardRef<HTMLInputElement | null, CheckboxProps>(
  (props, ref) => {
    const { children, style, error, ...rest } = props;

    const id = `checkbox_${rest.name}${rest.value ? `_${rest.value}` : ''}`;

    return (
      <Wrapper style={style}>
        <Input type="checkbox" id={id} {...rest} ref={ref} />
        <Label htmlFor={id}>
          <Shape error={error}>
            <IconCheck />
          </Shape>
          {props.children}
        </Label>
      </Wrapper>
    );
  },
);

export default Checkbox;
