import React, { FC } from 'react';
import * as MESSAGES from 'constants/messages';
import { Label, ProfileField, ProfileContent } from 'components/atoms';
import { CareerProfile } from 'proto/v1/apimodel/apimodel';

type CareerMonthlyUnitPriceFieldProps = {
  monthlyUnitPrice: CareerProfile['monthlyUnitPrice'];
};

const CareerMonthlyUnitPriceField: FC<CareerMonthlyUnitPriceFieldProps> = props => {
  const { monthlyUnitPrice } = props;

  return (
    <ProfileField data-testid="career-monthly-unit-price-field">
      <Label>{MESSAGES.PROFILE.FIELD.CAREER_MONTHLY_UNIT_PRICE.LABEL}</Label>
      <ProfileContent>
        {monthlyUnitPrice === undefined
          ? MESSAGES.PROFILE.NONE
          : `${monthlyUnitPrice}${MESSAGES.PROFILE.FIELD.CAREER_MONTHLY_UNIT_PRICE.UNIT}`}
      </ProfileContent>
    </ProfileField>
  );
};

export default CareerMonthlyUnitPriceField;
