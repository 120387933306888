import React, { FC, ComponentProps } from 'react';
import styled, { css } from 'styled-components/macro';
import { Modal } from 'visits-style';
import { FiX } from 'react-icons/fi';
import theme from 'styles/theme';
import media from 'styles/media';

const StyledModal = styled(Modal)<AppModalProps>`
  border-radius: 0;
  background-color: rgba(0, 0, 0, 0.4);
  ${({ size = 'normal' }) =>
    size === 'full'
      ? css`
          padding: 0;
          & div[role='document'] {
            border-radius: 0;
          }
        `
      : css`
          & div[role='document'] {
            min-width: 480px;
            max-width: 748px;
            ${media.mobile} {
              min-width: 100%;
              max-width: 100%;
            }

            div {
              box-shadow: none;
            }
          }
        `}
`;

const ModalBody = styled.div<Pick<AppModalProps, 'size'>>`
  ${({ size = 'normal' }) =>
    size === 'full'
      ? css`
          width: 100vw;
          height: 100vh;
        `
      : css`
          padding: 40px 32px;
          ${media.mobile} {
            padding: 32px 24px;
          }
        `}
  overflow-y: auto;
  border-radius: 0;
  background: ${theme.baseWhite};
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.4);
`;

const StyledIconClose = styled(FiX)`
  position: absolute;
  z-index: 3;
  top: 16px;
  right: 16px;
  width: 24px;
  height: 24px;
  color: #999;
  cursor: pointer;
`;

type AppModalProps = ComponentProps<typeof Modal> & {
  size?: 'normal' | 'full';
  width?: string;
  height?: string;
  closeIcon?: boolean;
};

const AppModal: FC<AppModalProps> = props => {
  const { show, size = 'normal', closeIcon = false } = props;

  document.body.setAttribute('style', show ? 'overflow: hidden;' : '');

  return (
    <StyledModal {...props}>
      <ModalBody size={size}>
        {closeIcon && (
          <StyledIconClose
            onClick={props.closeModal}
            data-testid="modal-close-icon"
          />
        )}
        {props.children}
      </ModalBody>
    </StyledModal>
  );
};

export default AppModal;
