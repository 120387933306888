import React, { FC } from 'react';
import * as MESSAGES from 'constants/messages';
import { PROFILE_FIELDS_MAX_LENGTH } from 'constants/profile';
import { ProfileFormRegister } from 'types/profile';
import { TextInput } from 'components/atoms';
import { Field } from 'components/molecules';

type LastNameKanaFormFieldProps = {
  error: string | undefined;
  register: ProfileFormRegister<'lastNameKana'>;
};

const LastNameKanaFormField: FC<LastNameKanaFormFieldProps> = props => {
  const { error, register } = props;
  return (
    <Field label={MESSAGES.PROFILE.FIELD.LAST_NAME_KANA.LABEL} required locked>
      <TextInput
        enterKeyHint="enter"
        name="lastNameKana"
        error={error}
        maxLength={PROFILE_FIELDS_MAX_LENGTH.LAST_NAME_KANA}
        placeholder={MESSAGES.PROFILE.FIELD.LAST_NAME_KANA.PLACEHOLDER}
        aria-label={MESSAGES.PROFILE.FIELD.LAST_NAME_KANA.LABEL}
        ref={register}
      />
    </Field>
  );
};

export default LastNameKanaFormField;
