import { UseFormMethods } from 'react-hook-form';
import * as MESSAGES from 'constants/messages';
import { ProfileFormMethods, ProfileFormValues } from 'types/profile';

type Payload = {
  formMethods: UseFormMethods<any>;
};

const useDttFormField = ({ formMethods }: Payload) => {
  const { errors } = (formMethods as unknown) as ProfileFormMethods<
    Pick<ProfileFormValues, 'dttScore' | 'dttRankId'>
  >;

  const newGraduateDttScoreError = (() => {
    const error = errors?.dttScore;
    return error?.type !== 'requiredAllDttFields' ? error?.message : undefined;
  })();

  const newGraduateRequiredAllDttFieldsError = (() => {
    const dttScoreErrorType = errors?.dttScore?.type;
    const dttRankIdErrorType = errors?.dttRankId?.type;
    return [dttScoreErrorType, dttRankIdErrorType].includes(
      'requiredAllDttFields',
    )
      ? MESSAGES.ERROR.REQUIRED_INPUT_ALL_DTT_FIELDS
      : undefined;
  })();

  return {
    newGraduateDttScoreError,
    newGraduateRequiredAllDttFieldsError,
  };
};

export default useDttFormField;
