import React, { FC } from 'react';
import styled from 'styled-components/macro';
import media from 'styles/media';
import * as MESSAGES from 'constants/messages';
import { ProfileFormItems } from 'types/profile';
import { isEqual } from 'utils/object';
import {
  Form,
  ButtonM,
  GroupLabel,
  CardInner,
  OptionalLabel,
} from 'components/atoms';
import {
  NewGraduatePastExperienceAchievementFormField,
  NewGraduateInternshipExperiencesFormField,
  NewGraduateStudyAbroadExperiencesFormField,
  NewGraduateClubExperiencesFormField,
  NewGraduateSelfIntroductionFormField,
} from 'components/organisms';
import useNewGraduateProfileExperiencesForm from './useNewGraduateProfileExperiencesForm';

const Buttons = styled.div`
  display: grid;
  grid-column-gap: 16px;
  grid-template-columns: auto auto;

  ${media.smallMobile} {
    grid-row-gap: 16px;
    grid-template-columns: 1fr;
  }
`;

const CancelButton = styled(ButtonM)`
  justify-self: end;

  ${media.smallMobile} {
    order: 2;
    width: 100%;
  }
`;

const SubmitButton = styled(ButtonM)`
  ${media.smallMobile} {
    order: 1;
    width: 100%;
  }
`;

type NewGraduateProfileExperiencesFormProps = {
  formItems: ProfileFormItems;
  onShowContent: () => void;
  onOpenCompletedModal: () => void;
};

const NewGraduateProfileExperiencesForm: FC<NewGraduateProfileExperiencesFormProps> = props => {
  const { formItems, onShowContent, onOpenCompletedModal } = props;

  const {
    formMethods,
    onSubmit,
    handleSelectPastExperienceAchievements,
    handleDeselectPastExperienceAchievements,
  } = useNewGraduateProfileExperiencesForm({
    onShowContent,
    onOpenCompletedModal,
  });

  const {
    handleSubmit,
    errors,
    register,
    formState,
    control,
    trigger,
  } = formMethods;

  const { isValid, isSubmitting, isDirty, touched } = formState;

  const isDisabledSubmitButton = !isValid || isSubmitting || !isDirty;

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      data-testid="new-graduate-profile-experiences-form"
    >
      <div>
        <NewGraduatePastExperienceAchievementFormField
          pastExperienceAchievements={formItems.pastExperienceAchievements}
          control={control}
          onSelect={handleSelectPastExperienceAchievements}
          onDeselect={handleDeselectPastExperienceAchievements}
        />

        <GroupLabel>
          {MESSAGES.PROFILE.FIELD.NEW_GRADUATE_INTERNSHIP_EXPERIENCES.LABEL}
          <OptionalLabel />
        </GroupLabel>
        <CardInner>
          <NewGraduateInternshipExperiencesFormField
            register={register}
            control={control}
            trigger={trigger}
            errors={errors.newGraduate?.internshipExperiences}
            touched={touched.newGraduate?.internshipExperiences}
          />
        </CardInner>

        <GroupLabel>
          {MESSAGES.PROFILE.FIELD.NEW_GRADUATE_STUDY_ABROAD_EXPERIENCES.LABEL}
          <OptionalLabel />
        </GroupLabel>
        <CardInner>
          <NewGraduateStudyAbroadExperiencesFormField
            register={register}
            control={control}
            trigger={trigger}
            errors={errors.newGraduate?.studyAbroadExperiences}
            touched={touched.newGraduate?.studyAbroadExperiences}
          />
        </CardInner>

        <GroupLabel>
          {MESSAGES.PROFILE.FIELD.NEW_GRADUATE_CLUB_EXPERIENCES.LABEL}
          <OptionalLabel />
        </GroupLabel>
        <CardInner>
          <NewGraduateClubExperiencesFormField
            register={register}
            control={control}
          />
        </CardInner>

        <GroupLabel>
          {MESSAGES.PROFILE.FIELD.NEW_GRADUATE_SELF_INTRODUCTION.LABEL}
        </GroupLabel>
        <CardInner>
          <NewGraduateSelfIntroductionFormField
            error={touched.selfIntroduction && errors.selfIntroduction?.message}
            register={register}
            control={control}
          />
        </CardInner>
      </div>

      <Buttons>
        <CancelButton buttonTheme="cancel" onClick={onShowContent}>
          {MESSAGES.PROFILE_SETTING.BUTTON.CANCEL}
        </CancelButton>

        <SubmitButton type="submit" disabled={isDisabledSubmitButton}>
          {MESSAGES.PROFILE_SETTING.BUTTON.SAVE}
        </SubmitButton>
      </Buttons>
    </Form>
  );
};

export default React.memo(NewGraduateProfileExperiencesForm, isEqual);
