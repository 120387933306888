import qs from 'qs';

const hiraganaRegExp = new RegExp(/[ぁ-ん]/g);

// eslint-disable-next-line import/prefer-default-export
export const interpolateMessage = <T extends {}>(message: string, query: T) =>
  Object.keys(query).reduce((a, key) => {
    const regex = new RegExp(`{{${key}}}`, 'gm');
    a = a.replace(regex, `${query[key as keyof T]}`);
    return a;
  }, message);

export const extractHiragana = (str: string): string => {
  const hiragana = str.match(new RegExp(/([ぁ-ん]|[ー])/g));
  if (!hiragana) return '';
  return hiragana.join('');
};

export const hiraganaToKatakana = (hiragana: string): string => {
  if (!hiraganaRegExp.test(hiragana)) return '';
  return hiragana.replace(hiraganaRegExp, match => {
    const chr = match.charCodeAt(0) + 0x60;
    return String.fromCharCode(chr);
  });
};

export const removeQueryStringProps = (
  queryString: string,
  ...deleteKeys: string[]
) => {
  const query = qs.parse(queryString);
  const removedPropsQuery = Object.keys(query).reduce<{ [key: string]: any }>(
    (a, c) => {
      if (!deleteKeys.includes(c)) a[c] = query[c];
      return a;
    },
    {},
  );
  return qs.stringify(removedPropsQuery);
};
