import { useState, useCallback, useEffect, useMemo } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { useDispatch } from 'react-redux';
import axios, { CancelTokenSource } from 'axios';
import { UiActions } from 'modules/ui';
import * as MESSAGES from 'constants/messages';
import apiInnovator from 'external/api/innovator';
import apiFirebase from 'external/firebase/firebase';
import RequestError from 'classes/RequestError';
import convertToInnovatorToUpdate from 'utils/profile/convertToInnovatorToUpdate';
import { Innovator } from 'proto/v1/apimodel/apimodel';
import { ProfileFormItems } from 'types/profile';
import useErrorNotification from 'hooks/useErrorNotification';

const defaultFormItems: ProfileFormItems = {
  countries: [],
  japanPrefectures: [],
  employmentTypes: [],
  occupations: [],
  englishSkills: [],
  annualIncomes: [],
  numberOfJobChanges: [],
  yearsOfExperiences: [],
  preferredMonthlyUnitPrices: [],
  positions: [],
  departments: [],
  dttRanks: [],
  programmingExperienceLevels: [],
  preferredWorkStyles: [],
  preferredBusinessTypes: [],
  pastExperienceAchievements: [],
  genders: [],
};

const useFetchInnovatorForm = () => {
  const dispatch = useDispatch();
  const { handleRequestError } = useErrorNotification();
  const [formItems, setFormItems] = useState<ProfileFormItems>(
    defaultFormItems,
  );
  const [innovator, setInnovator] = useState<Innovator | undefined>(undefined);

  const fetchFormItems = useCallback(
    async (source: CancelTokenSource) => {
      dispatch(UiActions.setLoading(true));
      let data;
      try {
        ({ data } = await apiInnovator.getInnovatorForm(source.token));
      } catch (error: unknown) {
        dispatch(UiActions.setLoading(false));
        if (error instanceof RequestError) {
          handleRequestError(error, MESSAGES.ERROR.FAILED_TO_GET);
        }
        return;
      }
      if (data.form) setFormItems(data.form);

      if (data.innovator) {
        setInnovator(data.innovator);
      }

      dispatch(UiActions.setLoading(false));
    },
    [dispatch, handleRequestError],
  );

  const innovatorToUpdate = useMemo(
    () => innovator && convertToInnovatorToUpdate(innovator),
    [innovator],
  );

  // Fetch form items
  useEffect(() => {
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    const unsubscribeAuthStateChanged = onAuthStateChanged(
      apiFirebase.auth(),
      user => {
        user ? fetchFormItems(source) : source.cancel();
      },
    );

    return () => {
      unsubscribeAuthStateChanged();
      source?.cancel();
    };
  }, [fetchFormItems]);

  return {
    formItems,
    innovator,
    innovatorToUpdate,
  };
};

export default useFetchInnovatorForm;
