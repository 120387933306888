import React, { FC, ComponentProps } from 'react';
import { Route as RouterRoute } from 'react-router-dom';
import { RouteLayout } from 'components/templates';

const Route: FC<ComponentProps<typeof RouterRoute>> = props => {
  return (
    <RouteLayout>
      <RouterRoute {...props} />
    </RouteLayout>
  );
};

export default Route;
