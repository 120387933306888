import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components/macro';
import * as MESSAGES from 'constants/messages';
import { INNOVATOR_TYPE } from 'constants/innovator';
import media from 'styles/media';
import { Stepper } from 'hooks/useStepper';
import { ButtonM, CardInner, GroupLabel } from 'components/atoms';
import {
  NameField,
  NameKanaField,
  YearMonthOfBirthField,
  PhoneNumberField,
  JapanPrefectureField,
  AcademicBackgroundSchoolField,
  AcademicBackgroundFacultyField,
  AcademicBackgroundDepartmentField,
  AcademicBackgroundYearMonthOfGraduationField,
  CareerAnnualIncomeField,
  CareerMonthlyUnitPriceField,
  CareerNumberOfJobChangesField,
  CareerJobField,
  CareerAchievementField,
  OutputsField,
  CareerSkillsField,
  EnglishSkillField,
  CertificationsField,
  PreferredWorkLocationsField,
  PreferredOccupationsField,
  CareerPreferredEmploymentTypeField,
  CareerPreferredMonthlyUnitPriceField,
  CareerExpectationsField,
  CareerResumeFileField,
  SelfIntroductionField,
} from 'components/organisms';
import useRegisterProfileConfirm from '../useRegisterProfileConfirm';

const Fields = styled.div``;

const CareerHistory = styled.div`
  margin-bottom: -32px;

  ${media.mobile} {
    margin-bottom: -24px;
  }
`;

const Buttons = styled.div`
  display: grid;
  grid-column-gap: 16px;
  grid-template-columns: auto auto;

  ${media.smallMobile} {
    grid-row-gap: 16px;
    grid-template-columns: 1fr;
  }
`;

const FixButton = styled(ButtonM)`
  justify-self: end;

  ${media.smallMobile} {
    order: 2;
    width: 100%;
  }
`;

const RegisterButton = styled(ButtonM)`
  ${media.smallMobile} {
    order: 1;
    width: 100%;
  }
`;

type RegisterProfileConfirmCareerProps = {
  stepper: Stepper;
};

const RegisterProfileConfirmCareer: FC<RegisterProfileConfirmCareerProps> = props => {
  const { stepper } = props;
  const {
    innovator,
    resisterProfile,
    handleClickPrevButton,
  } = useRegisterProfileConfirm({
    stepper,
  });

  if (!innovator) return null;

  return (
    <>
      <Helmet>
        <title>{MESSAGES.HEAD.TITLE.RESISTER_PROFILE.CONFIRM}</title>
      </Helmet>
      <div data-testid="profile-confirm-career">
        <Fields>
          <NameField
            lastName={innovator.lastName}
            firstName={innovator.firstName}
          />

          <NameKanaField
            lastNameKana={innovator.lastNameKana}
            firstNameKana={innovator.firstNameKana}
          />

          <YearMonthOfBirthField
            yearOfBirth={innovator.yearOfBirth}
            monthOfBirth={innovator.monthOfBirth}
          />

          <PhoneNumberField
            phoneCountryCode={innovator.phoneCountryCode}
            phoneNumber={innovator.phoneNumber}
          />

          <JapanPrefectureField japanPrefecture={innovator.japanPrefecture} />

          <AcademicBackgroundSchoolField
            academicBackground={innovator.academicBackground}
          />

          <AcademicBackgroundFacultyField
            academicBackground={innovator.academicBackground}
          />

          <AcademicBackgroundDepartmentField
            academicBackground={innovator.academicBackground}
          />

          <AcademicBackgroundYearMonthOfGraduationField
            yearOfGraduation={innovator.academicBackground?.yearOfGraduation}
            monthOfGraduation={innovator.academicBackground?.monthOfGraduation}
          />

          <CareerAnnualIncomeField
            annualIncome={innovator.career?.annualIncome}
          />

          <CareerMonthlyUnitPriceField
            monthlyUnitPrice={innovator.career?.monthlyUnitPrice}
          />

          <GroupLabel>{MESSAGES.PROFILE.FIELD.CAREER_JOB.LABEL}</GroupLabel>
          <CardInner>
            <CareerHistory>
              <CareerNumberOfJobChangesField
                numberOfJobChanges={innovator.career?.numberOfJobChanges}
              />

              <CareerJobField job={innovator.career?.job} />
            </CareerHistory>
          </CardInner>

          <CareerSkillsField skills={innovator.career?.skills} />

          <EnglishSkillField englishSkill={innovator.englishSkill} />

          <CertificationsField certifications={innovator.certifications} />

          <CareerAchievementField achievement={innovator.career?.achievement} />

          {innovator?.innovatorType === INNOVATOR_TYPE.ENGINEER && (
            <OutputsField outputs={innovator.outputs} />
          )}

          <PreferredWorkLocationsField
            preferredWorkLocations={innovator.preferredWorkLocations}
          />

          <PreferredOccupationsField
            preferredOccupations={innovator.preferredOccupations}
          />

          <CareerPreferredMonthlyUnitPriceField
            preferredMonthlyUnitPrice={
              innovator.career?.preferredMonthlyUnitPrice
            }
          />

          <CareerPreferredEmploymentTypeField
            preferredEmploymentType={innovator.career?.preferredEmploymentType}
          />

          <CareerExpectationsField
            expectations={innovator.career?.expectations}
          />

          <CareerResumeFileField resumeFile={innovator.career?.resumeFile} />

          <SelfIntroductionField
            selfIntroduction={innovator.selfIntroduction}
          />
        </Fields>

        <Buttons>
          <FixButton buttonTheme="cancel" onClick={handleClickPrevButton}>
            {MESSAGES.REGISTER_PROFILE.BUTTON.FIX}
          </FixButton>

          <RegisterButton onClick={resisterProfile}>
            {MESSAGES.REGISTER_PROFILE.BUTTON.REGISTER}
          </RegisterButton>
        </Buttons>
      </div>
    </>
  );
};

export default RegisterProfileConfirmCareer;
