import React, { FC } from 'react';
import styled, { css } from 'styled-components/macro';
import { useRouteMatch } from 'react-router-dom';
import media from 'styles/media';
import * as PUBLIC_ROUTES from 'routes/constants/public';
import { InnovatorType } from 'types/innovator';
import { bgBusiness, bgEngineer, bgPublic } from 'assets/jpg';
import { PublicHeader, Footer, ErrorNotification } from 'components/organisms';
import { INNOVATOR_TYPE } from 'constants/innovator';

const getBackgroundImage = (innovatorType: string | undefined) => {
  switch (innovatorType) {
    case INNOVATOR_TYPE.CAREER:
    case INNOVATOR_TYPE.NEW_GRADUATE:
      return bgBusiness;
    case INNOVATOR_TYPE.ENGINEER:
      return bgEngineer;
    default:
      return bgPublic;
  }
};

const Container = styled.div<{
  innovatorType: InnovatorType | undefined;
}>`
  display: flex;
  flex-direction: column;
  height: 100vh;
  ${({ innovatorType }) => css`
    background-image: url(${getBackgroundImage(innovatorType)});
  `}
  background-size: cover;

  ${media.mobile} {
    min-height: 812px;
  }

  ${media.smallMobile} {
    height: auto;
  }
`;

const Content = styled.div`
  flex: 1 0 auto;
  padding-top: 36px;

  ${media.mobile} {
    padding-top: 44px;
    padding-right: 12px;
    padding-left: 12px;
  }
`;

const PublicRouteLayout: FC = props => {
  const match = useRouteMatch<{ type: InnovatorType | undefined }>(
    `${PUBLIC_ROUTES.CREATE_ACCOUNT}/:type`,
  );
  const type = match?.params.type;

  return (
    <Container innovatorType={type}>
      <PublicHeader innovatorType={type} />
      <ErrorNotification />
      <Content>{props.children}</Content>
      <Footer mode="public" />
    </Container>
  );
};

export default PublicRouteLayout;
