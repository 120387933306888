import React, { ChangeEvent, FC } from 'react';
import styled from 'styled-components';
import { range } from 'utils/array';
import {
  getCareerJobHistoryLabel,
  getWorkingPeriodErrorMessage,
} from 'utils/profile/fields';
import {
  ProfileFormControl,
  ProfileFormRegister,
  ProfileFormMethods,
  ProfileFormValues,
  ProfileFormItems,
} from 'types/profile';
import { LabelBar } from 'components/atoms';
import {
  NameFormField,
  WorkingPeriodFormField,
  OccupationsFormField,
  PositionFormField,
} from './SubFields';

type Value = {
  career: Pick<ProfileFormValues['career'], 'job'>;
};

type CareerJobFormFieldProps = {
  errors: NonNullable<ProfileFormMethods<Value>['errors']['career']>['job'];
  touched: NonNullable<
    ProfileFormMethods<Value>['formState']['touched']['career']
  >['job'];
  numberOfJobChanges: number;
  occupations: ProfileFormItems['occupations'];
  positions: ProfileFormItems['positions'];
  yearsOfExperiences: ProfileFormItems['yearsOfExperiences'];
  onChangeEndWorkingPeriod: (
    e: ChangeEvent<HTMLSelectElement>,
    index: number,
  ) => void;
  register: ProfileFormRegister<{
    career: Pick<ProfileFormValues['career'], 'job'>;
  }>;
  control: ProfileFormControl<{
    career: Pick<ProfileFormValues['career'], 'job'>;
  }>;
  trigger: ProfileFormMethods<Value>['trigger'];
};

const Fields = styled.div``;

const CareerJobFormField: FC<CareerJobFormFieldProps> = props => {
  const {
    errors,
    touched,
    numberOfJobChanges,
    occupations,
    positions,
    yearsOfExperiences,
    onChangeEndWorkingPeriod,
    control,
    register,
    trigger,
  } = props;

  return (
    <>
      {range(0, numberOfJobChanges - 1).map(jobIndex => (
        <Fields key={jobIndex} data-testid="career-job-field">
          {numberOfJobChanges > 1 && (
            <LabelBar
              data-testid="job-history-label"
              style={{ marginBottom: '24px' }}
            >
              {getCareerJobHistoryLabel(jobIndex)}
            </LabelBar>
          )}

          <NameFormField
            error={
              touched?.[jobIndex]?.name && errors?.[jobIndex]?.name?.message
            }
            jobIndex={jobIndex}
            register={register}
          />

          <WorkingPeriodFormField
            error={getWorkingPeriodErrorMessage(errors?.[jobIndex], {
              startYear: touched?.[jobIndex]?.startYear,
              startMonth: touched?.[jobIndex]?.startMonth,
              endYear: touched?.[jobIndex]?.endYear,
              endMonth: touched?.[jobIndex]?.endMonth,
            })}
            trigger={trigger}
            onChangeEnd={onChangeEndWorkingPeriod}
            jobIndex={jobIndex}
            control={control}
          />

          <OccupationsFormField
            errors={
              errors &&
              touched &&
              errors.map((error, index) => ({
                occupationId:
                  touched[jobIndex]?.occupations?.[index]?.occupationId &&
                  error?.occupations?.[index]?.occupationId?.message,
                yearsOfExperienceId:
                  touched[jobIndex]?.occupations?.[index]
                    ?.yearsOfExperienceId &&
                  error?.occupations?.[index]?.yearsOfExperienceId?.message,
              }))
            }
            jobIndex={jobIndex}
            control={control}
            occupations={occupations}
            yearsOfExperiences={yearsOfExperiences}
          />

          <PositionFormField
            error={
              touched?.[jobIndex]?.positionId &&
              errors?.[jobIndex]?.positionId?.message
            }
            positions={positions}
            jobIndex={jobIndex}
            control={control}
          />
        </Fields>
      ))}
    </>
  );
};

export default CareerJobFormField;
