import React, { FC } from 'react';
import * as MESSAGES from 'constants/messages';
import { Label, ProfileField, ProfileContent } from 'components/atoms';
import { NewGraduateProfile } from 'proto/v1/apimodel/apimodel';

type NewGraduatePastExperienceAchievementsFieldProps = {
  pastExperienceAchievements:
    | NewGraduateProfile['pastExperienceAchievements']
    | undefined;
};

const NewGraduatePastExperienceAchievementsField: FC<NewGraduatePastExperienceAchievementsFieldProps> = props => {
  const { pastExperienceAchievements } = props;

  return (
    <ProfileField data-testid="new-graduate-past-experience-achievements-field">
      <Label>
        {MESSAGES.PROFILE.FIELD.NEW_GRADUATE_PAST_EXPERIENCE_ACHIEVEMENTS.LABEL}
      </Label>
      <ProfileContent>
        {pastExperienceAchievements && pastExperienceAchievements.length > 0
          ? pastExperienceAchievements
              .map(pastExperienceAchievement => pastExperienceAchievement.name)
              .join('、')
          : '-'}
      </ProfileContent>
    </ProfileField>
  );
};

export default NewGraduatePastExperienceAchievementsField;
