import React, { FC } from 'react';
import { Controller } from 'react-hook-form';
import media from 'styles/media';
import {
  ProfileFormItems,
  ProfileFormValues,
  ProfileFormControl,
} from 'types/profile';
import * as MESSAGES from 'constants/messages';
import { NO_SELECT_NUMBER } from 'constants/form';
import styled from 'styled-components/macro';
import { Selectbox } from 'components/atoms';
import { Field } from 'components/molecules';

type CareerNumberOfJobChangesFormFieldProps = {
  error: string | undefined;
  numberOfJobChanges: ProfileFormItems['numberOfJobChanges'];
  control: ProfileFormControl<{
    career: Pick<ProfileFormValues['career'], 'numberOfJobChangesId'>;
  }>;
};

const StyledSelectbox = styled(Selectbox)`
  width: 176px;

  ${media.mobile} {
    width: 100%;
  }
`;

const CareerNumberOfJobChangesFormField: FC<CareerNumberOfJobChangesFormFieldProps> = props => {
  const { error, numberOfJobChanges, control } = props;

  return (
    <Field
      label={MESSAGES.PROFILE.FIELD.CAREER_NUMBER_OF_JOB_CHANGES.LABEL}
      required
    >
      <Controller
        control={control}
        name="career.numberOfJobChangesId"
        render={innerProps => (
          <StyledSelectbox
            value={innerProps.value}
            error={error}
            options={numberOfJobChanges}
            noSelectValue={NO_SELECT_NUMBER}
            aria-label={
              MESSAGES.PROFILE.FIELD.CAREER_NUMBER_OF_JOB_CHANGES.LABEL
            }
            onChange={innerProps.onChange}
            onBlur={innerProps.onBlur}
          />
        )}
      />
    </Field>
  );
};

export default CareerNumberOfJobChangesFormField;
