import axios, { AxiosInstance } from 'axios';
import store from 'store';
import { ErrorNotificationActions } from 'modules/errorNotification';
import handleError from './handleError';

const client = (token?: string): AxiosInstance => {
  const axiosClient = axios.create();
  axiosClient.defaults.timeout = 20000; // ms
  axiosClient.defaults.headers = {
    'Content-Type': 'application/json',
    'X-Locale': 'ja',
  };
  if (token) axiosClient.defaults.headers.Authorization = `Bearer ${token}`;

  axiosClient.interceptors.request.use(request => {
    store.dispatch(ErrorNotificationActions.close());
    return request;
  });

  axiosClient.interceptors.response.use(response => response, handleError);

  return axiosClient;
};

export default client;
