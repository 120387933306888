import React, { FC } from 'react';
import * as MESSAGES from 'constants/messages';
import styled from 'styled-components/macro';
import { Innovator } from 'proto/v1/apimodel/apimodel';
import { Label, ProfileField } from 'components/atoms';

const SelfIntroduction = styled.div`
  font-size: 14px;
  line-height: 1.5;
  word-wrap: break-word;
  white-space: pre-wrap;
`;

type SelfIntroductionFieldProps = {
  selfIntroduction: Innovator['selfIntroduction'];
};

const SelfIntroductionField: FC<SelfIntroductionFieldProps> = props => {
  const { selfIntroduction } = props;

  return (
    <ProfileField data-testid="self-introduction-field">
      <Label>{MESSAGES.PROFILE.FIELD.SELF_INTRODUCTION.LABEL}</Label>
      <SelfIntroduction>{selfIntroduction}</SelfIntroduction>
    </ProfileField>
  );
};

export default SelfIntroductionField;
