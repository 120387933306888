import React, { FC, HTMLAttributes, KeyboardEvent, useCallback } from 'react';

type FormProps = HTMLAttributes<HTMLFormElement>;

const Form: FC<FormProps> = props => {
  // Prevent enter key from submitting
  const handleKeydown = useCallback((e: KeyboardEvent<HTMLFormElement>) => {
    const target = e.target as HTMLElement;
    const tagName = target.tagName.toLowerCase();
    if (tagName !== 'textarea' && e.key === 'Enter') e.preventDefault();
  }, []);
  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
  return <form onKeyDown={handleKeydown} {...props} />;
};

export default Form;
