import React, { VFC, useCallback, useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components/macro';
import { CSSTransition } from 'react-transition-group';
import theme from 'styles/theme';
import media from 'styles/media';
import * as MESSAGES from 'constants/messages';
import { isCompanyCorporateAttribute } from 'utils/mission';
import { CrossWhite } from 'assets/svg';
import { Tabs, Tab, TabList, TabPanel } from 'components/molecules';
import {
  StrengthPointField,
  MissionDescriptionField,
  CoverImageField,
  IconImageField,
  CompanyNameField,
  CompanyUrlField,
  MissionCategoriesField,
  CorporateAttributesField,
  CompanyEstablishedDateField,
  AddressField,
  FundField,
  MemberRangeField,
  MissionMembersField,
  MissionProductSummariesField,
  MissionProductsField,
  MissionOfficeImagesField,
  MissionJobOffersField,
} from './Fields';
import { Props } from '.';

const TOP_TAB_INDEX = 0;
const MISSION_PRODUCT_TAB_INDEX = 1;

const MISSION_PRODUCTS_MAX_COUNT = 3;

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 864px;
  height: 100%;
  overflow-y: auto;
  transform: translateX(864px);
  transition: transform 500ms;
  background-color: ${theme.baseWhite};
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);

  &.enter {
    transform: translateX(0);
  }

  &.enter-done {
    transform: translateX(0);
  }

  &.exit {
    transform: translateX(864px);
  }

  &.exit-done {
    transform: translateX(864px);
  }

  ${media.tablet} {
    display: none;
  }
`;

const Layout = styled.div`
  display: grid;
  position: relative;
  grid-template:
    'cover  cover  ' 440px
    'main   company' auto
    / 1fr 324px;
`;

const CoverContents = styled.div`
  grid-area: cover;
`;

const MainContents = styled.div`
  grid-area: main;
  border-right: 1px solid ${theme.borderDefault};
`;

const ContentLeft = styled.div`
  padding: 0 24px 32px 32px;
`;

const ContentRight = styled.div`
  padding: 32px 32px 32px 24px;
`;

const CompanyContents = styled.div`
  grid-area: company;
`;

const CloseIcon = styled(CrossWhite)`
  position: absolute;
  top: 12px;
  left: 12px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const Divider = styled.div`
  border-bottom: 1px solid ${theme.borderDefault};
`;

const TopContent = styled(ContentLeft)`
  display: grid;
  grid-template:
    'strength-point     ' auto
    '...................' 56px
    'mission-description' auto
    / auto;
`;

const MissionProductsContent = styled(ContentLeft)`
  padding-left: 0;
  padding-right: 0;
`;

const CompanyContent = styled(ContentRight)<{
  isCompany: boolean;
}>`
  display: grid;
  grid-template:
    'icon-image          ' auto
    '....................' 8px
    'company-name        ' auto
    '....................' 8px
    'company-url         ' auto
    '....................' 24px
    'mission-categories  ' auto
    '....................' 24px
    'corporate-attributes' auto
    '....................' 24px
    ${({ isCompany }) =>
      isCompany &&
      css`
        'company-established-date' auto
        '........................' 24px
        `}
    'address             ' auto
    '....................' 24px
    'member-range        ' auto
    '....................' 24px
    'fund                ' auto
    / auto;
`;

const MissionCard: VFC<Props> = ({ show, onExited, onClose, mission }) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const [
    scrollToMissionProductIndex,
    setScrollToMissionProductIndex,
  ] = useState<number | undefined>(undefined);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const missionProductRefs = useRef<React.RefObject<HTMLDivElement>[]>(
    [...Array(MISSION_PRODUCTS_MAX_COUNT)].map(() => React.createRef()),
  ).current;

  const handleSelectTab = useCallback((tabIndex: number) => {
    setSelectedTabIndex(tabIndex);
  }, []);

  const handleClickMissionProductSummaryCard = useCallback((index: number) => {
    setSelectedTabIndex(MISSION_PRODUCT_TAB_INDEX);
    setScrollToMissionProductIndex(index);
  }, []);

  // Scroll to target mission product
  useEffect(() => {
    if (scrollToMissionProductIndex === undefined) {
      return;
    }
    const missionProductEl =
      missionProductRefs[scrollToMissionProductIndex].current;
    missionProductEl?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
    setScrollToMissionProductIndex(undefined);
  }, [missionProductRefs, scrollToMissionProductIndex]);

  // Init display when the selected mission is changed.
  useEffect(() => {
    if (wrapperRef.current) {
      wrapperRef.current.scrollTop = 0;
      setSelectedTabIndex(TOP_TAB_INDEX);
    }
  }, [mission]);

  return (
    <CSSTransition
      in={show && !!mission}
      appear={show && !!mission}
      timeout={500}
      onExited={onExited}
    >
      <Wrapper data-testid="mission-card" ref={wrapperRef}>
        {mission && (
          <Layout>
            <CoverContents>
              <CoverImageField
                coverImage={mission?.coverImage}
                name={mission?.name}
                data-testid="cover-image-field"
              />
              <CloseIcon onClick={onClose} />
            </CoverContents>

            <MainContents>
              <Tabs selectedIndex={selectedTabIndex} onSelect={handleSelectTab}>
                <TabList
                  style={{
                    padding: '40px 24px 0 32px',
                    marginBottom: '40px',
                  }}
                >
                  <Tab>{MESSAGES.MISSION_CARD.TAB.TOP}</Tab>
                  <Tab
                    disabled={mission?.missionProducts.length === 0}
                    data-testid="tab-mission-products"
                  >
                    {MESSAGES.MISSION_CARD.TAB.MISSION_PRODUCTS}
                  </Tab>
                  <Tab
                    disabled={mission?.missionJobOffers.length === 0}
                    data-testid="tab-mission-job-offers"
                  >
                    {MESSAGES.MISSION_CARD.TAB.MISSION_JOB_OFFERS}
                  </Tab>
                </TabList>

                <TabPanel>
                  <TopContent>
                    <StrengthPointField
                      strengthPoint={mission?.strengthPoint}
                      style={{ gridArea: 'strength-point' }}
                      data-testid="strength-point-field"
                    />
                    <MissionDescriptionField
                      missionDescription={mission?.missionDescription}
                      style={{ gridArea: 'mission-description' }}
                      data-testid="mission-description-field"
                    />
                  </TopContent>

                  {((mission?.missionMembers &&
                    mission?.missionMembers.length > 0) ||
                    (mission?.missionOfficeImages &&
                      mission?.missionOfficeImages.length > 0)) && (
                    <Divider style={{ marginBottom: '32px' }} />
                  )}

                  {mission?.missionProducts &&
                    mission.missionProducts.length > 0 && (
                      <ContentLeft>
                        <MissionProductSummariesField
                          missionProducts={mission?.missionProducts}
                          onClickCard={handleClickMissionProductSummaryCard}
                          data-testid="mission-product-summaries-field"
                        />
                      </ContentLeft>
                    )}

                  {mission?.missionOfficeImages &&
                    mission?.missionOfficeImages.length > 0 && (
                      <ContentLeft>
                        <MissionOfficeImagesField
                          missionOfficeImages={mission?.missionOfficeImages}
                          data-testid="mission-office-images-field"
                        />
                      </ContentLeft>
                    )}
                </TabPanel>

                <TabPanel>
                  <MissionProductsContent>
                    <MissionProductsField
                      missionProducts={mission?.missionProducts}
                      missionProductRefs={missionProductRefs}
                      data-testid="mission-products-field"
                    />
                  </MissionProductsContent>
                </TabPanel>

                <TabPanel>
                  <ContentLeft>
                    <MissionJobOffersField
                      missionJobOffers={mission?.missionJobOffers}
                      data-testid="mission-job-offers-field"
                    />
                  </ContentLeft>
                </TabPanel>
              </Tabs>
            </MainContents>

            <CompanyContents>
              <CompanyContent
                isCompany={isCompanyCorporateAttribute(
                  mission?.corporateAttributes,
                )}
              >
                <IconImageField
                  iconImage={mission?.iconImage}
                  style={{ gridArea: 'icon-image' }}
                  data-testid="icon-image-field"
                />
                <CompanyNameField
                  companyName={mission?.companyName}
                  style={{ gridArea: 'company-name' }}
                  data-testid="company-name-field"
                />
                <CompanyUrlField
                  companyUrl={mission?.companyUrl}
                  style={{ gridArea: 'company-url' }}
                  data-testid="company-url-field"
                />
                <MissionCategoriesField
                  missionCategories={mission?.missionCategories}
                  style={{ gridArea: 'mission-categories' }}
                  data-testid="mission-categories-field"
                />
                <CorporateAttributesField
                  corporateAttributes={mission?.corporateAttributes}
                  style={{ gridArea: 'corporate-attributes' }}
                  data-testid="corporate-attributes-field"
                />
                {isCompanyCorporateAttribute(mission?.corporateAttributes) && (
                  <CompanyEstablishedDateField
                    companyEstablishedDate={mission?.companyEstablishedDate}
                    style={{ gridArea: 'company-established-date' }}
                    data-testid="company-established-date-field"
                  />
                )}
                <AddressField
                  japanPrefecture={mission?.japanPrefecture}
                  otherAddress={mission?.otherAddress}
                  style={{ gridArea: 'address' }}
                  data-testid="address-field"
                />
                <MemberRangeField
                  memberRange={mission?.memberRange}
                  style={{ gridArea: 'member-range' }}
                  data-testid="member-range-field"
                />
                <FundField
                  fund={mission?.fund}
                  style={{ gridArea: 'fund' }}
                  data-testid="fund-field"
                />
              </CompanyContent>

              {mission?.missionMembers && mission?.missionMembers.length > 0 && (
                <>
                  <Divider />

                  <ContentRight>
                    <MissionMembersField
                      missionMembers={mission?.missionMembers}
                      data-testid="mission-members-field"
                    />
                  </ContentRight>
                </>
              )}
            </CompanyContents>
          </Layout>
        )}
      </Wrapper>
    </CSSTransition>
  );
};

export default MissionCard;
