import React, { FC, HTMLAttributes } from 'react';
import styled from 'styled-components/macro';
import media from 'styles/media';
import theme from 'styles/theme';
import * as MESSAGES from 'constants/messages';
import { MissionCategory } from 'proto/v1/apimodel/apimodel';
import { MissionForInnovator } from 'types/mission';
import { SubTitle } from '../../Typography';

const FieldTitle = styled(SubTitle)`
  ${media.tablet} {
    display: none;
  }
`;

const MissionCategoryTags = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`;

const MissionCategoryTag = styled.div<{ backgroundColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100px;
  height: 24px;
  padding: 4px 8px;
  border-radius: 16px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${theme.baseWhite};
  font-size: 10px;
  font-weight: bold;
  line-height: 14px;
`;

const sortedMissionCategories = (missionCategories: MissionCategory[]) =>
  [...missionCategories].sort((a, b) => a.displayOrder - b.displayOrder);

type Props = {
  missionCategories: MissionForInnovator['missionCategories'] | undefined;
} & HTMLAttributes<HTMLDivElement>;

const MissionCategoriesField: FC<Props> = ({ missionCategories, ...rest }) => {
  return (
    <div {...rest}>
      <FieldTitle>
        {MESSAGES.MISSION_CARD.FIELD.MISSION_CATEGORIES.LABEL}
      </FieldTitle>
      <MissionCategoryTags>
        {sortedMissionCategories(missionCategories ?? []).map(
          missionCategory => (
            <MissionCategoryTag
              key={missionCategory.categoryId}
              backgroundColor={missionCategory.backgroundColor}
              data-testid="mission-category-tag"
            >
              {missionCategory.name}
            </MissionCategoryTag>
          ),
        )}
      </MissionCategoryTags>
    </div>
  );
};

export default MissionCategoriesField;
