import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { Helmet } from 'react-helmet';
import { FieldError } from 'react-hook-form';
import * as MESSAGES from 'constants/messages';
import media from 'styles/media';
import { Stepper } from 'hooks/useStepper';
import { Form, ButtonM } from 'components/atoms';
import {
  EnglishSkillFormField,
  PreferredWorkLocationsFormField,
  CertificationsFormField,
  PreferredOccupationsFormField,
  NewGraduateDttFormField,
  OutputsFormField,
  NewGraduateProgrammingExperiencesFormField,
  NewGraduatePreferredWorkingStyleFormField,
  NewGraduatePreferredBusinessTypesFormField,
} from 'components/organisms';
import useRegisterProfileFormNewGraduateSecond from './useRegisterProfileFormNewGraduateSecond';

const Buttons = styled.div`
  display: grid;
  grid-column-gap: 16px;
  grid-template-columns: auto auto;

  ${media.smallMobile} {
    grid-row-gap: 16px;
    grid-template-columns: 1fr;
  }
`;

const GoToPrevButton = styled(ButtonM)`
  justify-self: end;

  ${media.smallMobile} {
    order: 2;
    width: 100%;
  }
`;

const SubmitButton = styled(ButtonM)`
  ${media.smallMobile} {
    order: 1;
    width: 100%;
  }
`;

type RegisterProfileFormNewGraduateSecondProps = {
  stepper: Stepper;
};

const RegisterProfileFormNewGraduateSecond: FC<RegisterProfileFormNewGraduateSecondProps> = props => {
  const { stepper } = props;
  const {
    formMethods,
    onSubmit,
    formItems,
    preferredOccupationsError,
    newGraduateDttScoreError,
    newGraduateRequiredAllDttFieldsError,
    newGraduateRequiredAllProgrammingExperienceFieldsErrors,
    newGraduatePreferredBusinessTypesError,
    isValidDefault,
    handleClickPrevButton,
  } = useRegisterProfileFormNewGraduateSecond({
    stepper,
  });

  const {
    handleSubmit,
    errors,
    register,
    formState,
    control,
    trigger,
  } = formMethods;

  const { isValid, isSubmitting, isDirty, touched } = formState;
  const isDisabledSubmitButton = isDirty
    ? !isValid || isSubmitting
    : !isValidDefault;

  return (
    <>
      <Helmet>
        <title>{MESSAGES.HEAD.TITLE.RESISTER_PROFILE.FORM_SECOND}</title>
      </Helmet>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        data-testid="profile-form-new-graduate-second"
      >
        <EnglishSkillFormField
          error={touched.englishSkillId && errors.englishSkillId?.message}
          englishSkills={formItems.englishSkills}
          control={control}
        />

        <CertificationsFormField control={control} register={register} />

        <NewGraduateDttFormField
          dttRanks={formItems.dttRanks}
          trigger={trigger}
          control={control}
          errors={{
            dttScore: touched.dttScore && newGraduateDttScoreError,
            requiredInputAll:
              touched.dttScore &&
              touched.dttRankId &&
              newGraduateRequiredAllDttFieldsError,
          }}
          register={register}
        />

        <NewGraduateProgrammingExperiencesFormField
          register={register}
          control={control}
          trigger={trigger}
          errors={newGraduateRequiredAllProgrammingExperienceFieldsErrors}
          touched={touched.newGraduate?.programmingExperiences}
          programmingExperienceLevels={formItems.programmingExperienceLevels}
        />

        <OutputsFormField
          register={register}
          control={control}
          errors={errors.outputs}
          touched={touched.outputs}
        />

        <NewGraduatePreferredWorkingStyleFormField
          error={
            touched.newGraduate?.preferredWorkStyleId &&
            errors?.newGraduate?.preferredWorkStyleId?.message
          }
          preferredWorkStyles={formItems.preferredWorkStyles}
          control={control}
        />

        <NewGraduatePreferredBusinessTypesFormField
          error={
            touched.newGraduate?.preferredBusinessTypes &&
            newGraduatePreferredBusinessTypesError
          }
          preferredBusinessTypes={formItems.preferredBusinessTypes}
          control={control}
          trigger={trigger}
        />

        <PreferredOccupationsFormField
          error={touched.preferredOccupations && preferredOccupationsError}
          occupations={formItems.occupations}
          control={control}
          trigger={trigger}
        />

        <PreferredWorkLocationsFormField
          error={
            touched.preferredWorkLocationJapanPrefectureIds &&
            (errors.preferredWorkLocationJapanPrefectureIds as
              | FieldError
              | undefined)?.message
          }
          japanPrefectures={formItems.japanPrefectures}
          control={control}
          trigger={trigger}
        />

        <Buttons>
          <GoToPrevButton buttonTheme="cancel" onClick={handleClickPrevButton}>
            {MESSAGES.REGISTER_PROFILE.BUTTON.GO_TO_PREV}
          </GoToPrevButton>

          <SubmitButton type="submit" disabled={isDisabledSubmitButton}>
            {MESSAGES.REGISTER_PROFILE.BUTTON.SAVE_AND_GO_TO_NEXT}
          </SubmitButton>
        </Buttons>
      </Form>
    </>
  );
};

export default RegisterProfileFormNewGraduateSecond;
