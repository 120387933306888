import styled from 'styled-components/macro';
import theme from 'styles/theme';

const Tag = styled.div`
  display: inline-block;
  margin-right: 4px;
  margin-bottom: 4px;
  padding: 4px 8px;
  border-radius: 5px;
  background-color: ${theme.labelTag};
  color: ${theme.baseWhite};
  font-size: 14px;

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
`;

export default Tag;
