import React, { FC } from 'react';
import * as MESSAGES from 'constants/messages';
import { Label, ProfileField, ProfileContent } from 'components/atoms';
import { CareerProfile } from 'proto/v1/apimodel/apimodel';

type CareerAnnualIncomeFieldProps = {
  annualIncome: CareerProfile['annualIncome'];
};

const CareerAnnualIncomeField: FC<CareerAnnualIncomeFieldProps> = props => {
  const { annualIncome } = props;

  return (
    <ProfileField data-testid="career-annual-income-field">
      <Label>{MESSAGES.PROFILE.FIELD.CAREER_ANNUAL_INCOME.LABEL}</Label>
      <ProfileContent>{annualIncome?.name}</ProfileContent>
    </ProfileField>
  );
};

export default CareerAnnualIncomeField;
