import React, { FC } from 'react';
import * as MESSAGES from 'constants/messages';
import { Label, ProfileField, ProfileContent } from 'components/atoms';
import { Innovator } from 'proto/v1/apimodel/apimodel';

type EnglishSkillFieldProps = {
  englishSkill: Innovator['englishSkill'];
};

const EnglishSkillField: FC<EnglishSkillFieldProps> = props => {
  const { englishSkill } = props;

  return (
    <ProfileField data-testid="english-skill-field">
      <Label>{MESSAGES.PROFILE.FIELD.ENGLISH_SKILL.LABEL}</Label>
      <ProfileContent>{englishSkill?.name}</ProfileContent>
    </ProfileField>
  );
};

export default EnglishSkillField;
