import React, { FC, useEffect } from 'react';
import * as MESSAGES from 'constants/messages';
import * as PROTECTED_ROUTES from 'routes/constants/protected';
import * as ROUTES from 'routes/constants/routes';
import * as EXTERNAL_LINK from 'constants/externalLink';
import { MATTER_STATUS_ID } from 'constants/matter';
import styled, { css } from 'styled-components/macro';
import { useSelector } from 'react-redux';
import { interpolateMessage } from 'utils/string';
import theme from 'styles/theme';
import { getMatterStatus } from 'modules/matter';
import { getChatRoomId } from 'modules/account';
import media from 'styles/media';
import useLogout from 'hooks/useLogout';
import { UserBlue, MessageBlue, SettingsBlue, LogoutBlue } from 'assets/svg';
import { getMessageNotificationsTotalCount } from 'modules/notification';
import { NotificationBadge } from 'components/atoms';
import { NavLink } from 'components/molecules';

const iconStyle = css`
  margin-right: 14px;
`;

const Wrapper = styled.div<{ windowHeight: number }>`
  display: none;
  position: absolute;
  z-index: 10;
  top: 64px;
  left: 0;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: ${({ windowHeight }) => `${windowHeight - 64}px`};
  background: #eee;

  ${media.tablet} {
    display: flex;
  }

  ${media.mobile} {
    top: 54px;
    height: ${({ windowHeight }) => `${windowHeight - 54}px`};
  }
`;

const LogoutMenuItem = styled.li`
  padding: 14px 24px;
  color: ${theme.textBody};
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;

  &:hover {
    background-color: ${theme.borderDefault};
    color: ${theme.textBody};
  }
`;

const ProfileIcon = styled(UserBlue)`
  position: relative;
  bottom: 1px;
  ${iconStyle}
`;

const MessagesIcon = styled(MessageBlue)<{ disabled: boolean }>`
  ${iconStyle}
  ${({ disabled }) =>
    disabled &&
    css`
      path {
        fill: ${theme.textSecondary};
      }
    `}
`;

const SettingsIcon = styled(SettingsBlue)`
  ${iconStyle}
`;

const LogoutIcon = styled(LogoutBlue)`
  ${iconStyle}
`;

const Footer = styled.div`
  padding: 0 24px;
`;

const FooterItem = styled.div`
  margin-bottom: 16px;
  color: ${theme.textSecondary};
  font-size: 12px;
  line-height: 17px;
`;

const FooterItemLink = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})`
  color: ${theme.textSecondary};
  text-decoration: none;

  &:hover {
    color: ${theme.textSecondaryHover};
  }
`;

const CopyRight = styled.div`
  margin-bottom: 32px;
  color: ${theme.textSecondary};
  font-size: 10px;
  line-height: 12px;
`;

type Props = {
  onClose: () => void;
};

const MobileMenu: FC<Props> = ({ onClose }) => {
  const { logout } = useLogout();
  const { statusId } = useSelector(getMatterStatus);
  const chatRoomId = useSelector(getChatRoomId);
  const unreadMessageCount = useSelector(getMessageNotificationsTotalCount);

  // Fix scroll
  useEffect(() => {
    document.documentElement.style.overflow = 'hidden';
    return () => {
      document.documentElement.style.overflow = 'auto';
    };
  }, []);

  return (
    <Wrapper data-testid="mobile-menu" windowHeight={window.innerHeight}>
      <ul>
        <li>
          <NavLink
            to={
              statusId === MATTER_STATUS_ID.NONE ||
              statusId === MATTER_STATUS_ID.SEND_BACK
                ? PROTECTED_ROUTES.REGISTER_PROFILE
                : PROTECTED_ROUTES.PROFILE
            }
            isActive={(_, location) =>
              [
                PROTECTED_ROUTES.REGISTER_PROFILE,
                PROTECTED_ROUTES.PROFILE,
                PROTECTED_ROUTES.PUBLISH_SETTING,
              ].includes(location.pathname)
            }
            onClick={onClose}
          >
            <ProfileIcon />
            {MESSAGES.MENU.MENU.PROFILE}
          </NavLink>
        </li>
        <li>
          <NavLink
            to={PROTECTED_ROUTES.MESSAGES}
            onClick={onClose}
            disabled={!chatRoomId}
          >
            <MessagesIcon disabled={!chatRoomId} />
            {MESSAGES.MENU.MENU.MESSAGES}
            {unreadMessageCount !== undefined && unreadMessageCount > 0 && (
              <NotificationBadge
                count={unreadMessageCount}
                style={{ marginLeft: 'auto' }}
              />
            )}
          </NavLink>
        </li>
        <li>
          <NavLink to={PROTECTED_ROUTES.ACCOUNT_SETTING} onClick={onClose}>
            <SettingsIcon />
            {MESSAGES.MENU.MENU.SETTINGS}
          </NavLink>
        </li>
        <LogoutMenuItem onClick={logout}>
          <LogoutIcon />
          {MESSAGES.MENU.MENU.LOGOUT}
        </LogoutMenuItem>
      </ul>

      <Footer>
        <FooterItem>
          <FooterItemLink href={EXTERNAL_LINK.COMPANY}>
            {MESSAGES.MENU.FOOTER.COMPANY}
          </FooterItemLink>
        </FooterItem>
        <FooterItem>
          <FooterItemLink href={ROUTES.TERMS}>
            {MESSAGES.MENU.FOOTER.TERMS_OF_SERVICE}
          </FooterItemLink>
        </FooterItem>
        <FooterItem>
          <FooterItemLink href={EXTERNAL_LINK.PRIVACY_POLICY}>
            {MESSAGES.MENU.FOOTER.PRIVACY_POLICY}
          </FooterItemLink>
        </FooterItem>
        <CopyRight>
          {interpolateMessage(MESSAGES.MENU.FOOTER.COPYRIGHT, {
            year: new Date().getFullYear(),
          })}
        </CopyRight>
      </Footer>
    </Wrapper>
  );
};

export default MobileMenu;
