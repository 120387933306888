import React, { FC } from 'react';
import * as MESSAGES from 'constants/messages';
import { Label, ProfileField, ProfileContent } from 'components/atoms';
import { CareerProfile } from 'proto/v1/apimodel/apimodel';

type CareerNumberOfJobChangesFieldProps = {
  numberOfJobChanges: CareerProfile['numberOfJobChanges'];
};

const CareerNumberOfJobChangesField: FC<CareerNumberOfJobChangesFieldProps> = props => {
  const { numberOfJobChanges } = props;

  return (
    <ProfileField data-testid="career-number-of-job-changes-field">
      <Label>{MESSAGES.PROFILE.FIELD.CAREER_NUMBER_OF_JOB_CHANGES.LABEL}</Label>
      <ProfileContent>{numberOfJobChanges?.name}</ProfileContent>
    </ProfileField>
  );
};

export default CareerNumberOfJobChangesField;
