import React, { FC } from 'react';
import * as MESSAGES from 'constants/messages';
import { Label, ProfileField, ProfileContent } from 'components/atoms';
import { Innovator } from 'proto/v1/apimodel/apimodel';

type CertificationsFieldProps = {
  certifications: Innovator['certifications'];
};

const CertificationsField: FC<CertificationsFieldProps> = props => {
  const { certifications } = props;

  return (
    <ProfileField data-testid="certifications-field">
      <Label>{MESSAGES.PROFILE.FIELD.CERTIFICATIONS.LABEL}</Label>
      <ProfileContent>
        {certifications.length > 0 ? certifications.join('、') : '-'}
      </ProfileContent>
    </ProfileField>
  );
};

export default CertificationsField;
