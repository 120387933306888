export default {
  TITLE: 'メール配信停止',
  FORM: {
    DESCRIPTION:
      '配信停止をご希望の方は、下記フォームにメールアドレスをご入力ください。',
    FIELD: {
      EMAIL: 'メールアドレス',
    },
    BUTTON: {
      SUBMIT: '送信',
    },
  },
  COMPLETED: {
    DESCRIPTION:
      'メールの配信停止を受付けました。\n配信停止がシステムに反映されるまで、お時間をいただく場合がございます。ご了承ください。',
  },
};
