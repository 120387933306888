import React, { FC, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as MESSAGES from 'constants/messages';
import { Link } from 'react-router-dom';
import { getIsCareer, getIsNewGraduate } from 'modules/account';
import { getShowMatterClosedDialog, MatterActions } from 'modules/matter';
import * as PROTECTED_ROUTES from 'routes/constants/protected';
import { ModalConfirm } from 'components/molecules';

const MatterClosedDialog: FC = () => {
  const show = useSelector(getShowMatterClosedDialog);
  const isNewGraduate = useSelector(getIsNewGraduate);
  const isCareer = useSelector(getIsCareer);
  const dispatch = useDispatch();
  const closeModal = useCallback(
    () => dispatch(MatterActions.closeMatterClosedDialog()),
    [dispatch],
  );

  return (
    <ModalConfirm
      show={show}
      title={MESSAGES.PUBLISH_SETTING.DIALOG.CLOSED.TITLE}
      closeModal={closeModal}
      cancelCaption={MESSAGES.COMMON.BUTTON.CLOSE}
    >
      {isCareer && (
        <p>
          {MESSAGES.PUBLISH_SETTING.DIALOG.CLOSED.CONTENT_1}
          <Link to={PROTECTED_ROUTES.PUBLISH_SETTING} onClick={closeModal}>
            {
              MESSAGES.PUBLISH_SETTING.DIALOG.CLOSED
                .CONTENT_PROFILE_PUBLISH_SETTING_LINK
            }
          </Link>
          {MESSAGES.PUBLISH_SETTING.DIALOG.CLOSED.CONTENT_2}
        </p>
      )}
      {isNewGraduate && (
        <p>
          {MESSAGES.PUBLISH_SETTING.DIALOG.CLOSED.CONTENT_NEW_GRADUATE_1}
          <Link to={PROTECTED_ROUTES.ACCOUNT_SETTING} onClick={closeModal}>
            {
              MESSAGES.PUBLISH_SETTING.DIALOG.CLOSED
                .CONTENT_PROFILE_PUBLISH_SETTING_LINK
            }
          </Link>
          {MESSAGES.PUBLISH_SETTING.DIALOG.CLOSED.CONTENT_NEW_GRADUATE_2}
        </p>
      )}
    </ModalConfirm>
  );
};

export default MatterClosedDialog;
