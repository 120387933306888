import styled from 'styled-components/macro';
import media from 'styles/media';
import theme from 'styles/theme';

const Label = styled.p`
  margin-bottom: 4px;
  color: ${theme.textSecondary};
  font-size: 16px;
  line-height: 1.5;

  ${media.mobile} {
    font-size: 14px;
  }
`;

export default Label;
