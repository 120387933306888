import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { useFieldArray } from 'react-hook-form';
import * as MESSAGES from 'constants/messages';
import theme from 'styles/theme';
import { MAX_NEW_GRADUATE_INTERNSHIP_EXPERIENCES } from 'constants/profile';
import {
  ProfileFormControl,
  ProfileFormRegister,
  ProfileFormValues,
  ProfileFormMethods,
} from 'types/profile';
import { AddField } from 'components/atoms';
import { getPeriodErrorMessage } from 'utils/profile/fields';
import { CompanyNameFormField, InternshipPeriodFormField } from './SubFields';

const Wrapper = styled.div``;

const FieldsContainer = styled.div<{ isLast: boolean }>`
  display: flex;
  margin-bottom: ${({ isLast }) => (isLast ? 0 : '24px')};
  padding-bottom: ${({ isLast }) => (isLast ? 0 : '16px')};
  border-bottom: ${({ isLast }) =>
    isLast ? 'none' : `1px solid ${theme.borderDefault}`};
`;

const Fields = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const RemoveInternshipExperience = styled.div`
  align-self: flex-end;
  margin-top: 6px;
  color: ${theme.basePrimary};
  font-size: 12px;
  line-height: 1.5;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

type Value = {
  newGraduate: Pick<ProfileFormValues['newGraduate'], 'internshipExperiences'>;
};

type NewGraduateInternshipExperiencesFormFieldProps = {
  errors: NonNullable<
    ProfileFormMethods<Value>['errors']['newGraduate']
  >['internshipExperiences'];
  register: ProfileFormRegister<Value>;
  control: ProfileFormControl<Value>;
  trigger: ProfileFormMethods<Value>['trigger'];
  touched: NonNullable<
    ProfileFormMethods<Value>['formState']['touched']['newGraduate']
  >['internshipExperiences'];
};

const NewGraduateInternshipExperiencesFormField: FC<NewGraduateInternshipExperiencesFormFieldProps> = props => {
  const { register, control, trigger, errors, touched } = props;

  const { fields, append, remove } = useFieldArray<
    ProfileFormValues['newGraduate']['internshipExperiences'][0]
  >({
    control,
    name: 'newGraduate.internshipExperiences',
  });

  return (
    <Wrapper>
      {fields.map((field, index) => (
        <FieldsContainer
          key={field?.id ?? index}
          isLast={index === MAX_NEW_GRADUATE_INTERNSHIP_EXPERIENCES - 1}
        >
          <Fields data-testid="new-graduate-internship-experience-fields">
            <CompanyNameFormField
              register={register}
              index={index}
              defaultValue={field.companyName}
            />

            <InternshipPeriodFormField
              control={control}
              trigger={trigger}
              index={index}
              error={getPeriodErrorMessage(
                {
                  startYear: errors?.[index]?.startYear,
                  startMonth: errors?.[index]?.startMonth,
                  endYear: errors?.[index]?.endYear,
                  endMonth: errors?.[index]?.endMonth,
                },
                {
                  startYear: touched?.[index]?.startYear,
                  startMonth: touched?.[index]?.startMonth,
                  endYear: touched?.[index]?.endYear,
                  endMonth: touched?.[index]?.endMonth,
                },
              )}
              defaultValues={{
                startYear: field.startYear,
                startMonth: field.startMonth,
                endYear: field.endYear,
                endMonth: field.endMonth,
              }}
            />

            {index > 0 && (
              <RemoveInternshipExperience
                onClick={() => remove(index)}
                data-testid="remove-internship-experience"
              >
                {MESSAGES.COMMON.BUTTON.REMOVE_FIELD}
              </RemoveInternshipExperience>
            )}
          </Fields>
        </FieldsContainer>
      ))}

      {fields.length < MAX_NEW_GRADUATE_INTERNSHIP_EXPERIENCES && (
        <AddField
          style={{ marginTop: '-8px' }}
          onClick={() => append({})}
          data-testid="add-internship-experience"
        />
      )}
    </Wrapper>
  );
};

export default NewGraduateInternshipExperiencesFormField;
