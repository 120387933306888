export const PROFILE_FIELDS_MAX_LENGTH = {
  LAST_NAME: 20,
  FIRST_NAME: 20,
  LAST_NAME_KANA: 20,
  FIRST_NAME_KANA: 20,
  PHONE_NUMBER: 15,
  ACADEMIC_BACKGROUND_FACULTY: 35,
  CAREER_JOB_NAME: 50,
  CAREER_ACHIEVEMENT: 200,
  OUTPUT: 2048,
  CAREER_MONTHLY_UNIT_PRICE: 3,
  SELF_INTRODUCTION: 1000,
  CERTIFICATION: 40,
  NEW_GRADUATE_RESEARCH_ROOM_NAME: 30,
  NEW_GRADUATE_RESEARCH_ROOM_CONTENT: 400,
  NEW_GRADUATE_DTT_SCORE: 3,
  NEW_GRADUATE_PROGRAMMING_EXPERIENCE_LANGUAGE_NAME: 30,
  NEW_GRADUATE_INTERNSHIP_EXPERIENCES_COMPANY_NAME: 30,
  NEW_GRADUATE_STUDY_ABROAD_EXPERIENCES_COUNTRY_NAME: 30,
  NEW_GRADUATE_CLUB_EXPERIENCES_NAME: 30,
  NEW_GRADUATE_CLUB_EXPERIENCES_CONTENT: 400,
} as const;

export const PROFILE_FIELDS_MIN_LENGTH = {
  SELF_INTRODUCTION: 150,
} as const;

export const BIRTH_MIN_YEAR = 1970;
export const ACADEMIC_BACKGROUND_GRADUATION_MIN_YEAR = 1990;
export const WORKING_PERIOD_MIN_YEAR = 1990;
export const INTERNSHIP_PERIOD_MIN_YEAR = 2015;
export const STUDY_ABROAD_PERIOD_MIN_YEAR = 2000;

export const ADDRESS_OVERSEAS_ID = 99;

export const MAX_CAREER_JOB_OCCUPATIONS = 3;

export const MAX_PREFERRED_WORK_LOCATIONS = 3;

export const MAX_PREFERRED_OCCUPATIONS = 3;

export const MAX_OUTPUTS = 5;

export const MAX_CERTIFICATIONS = 10;

export const MAX_SKILLS = 5;

export const MAX_EXPECTATIONS = 5;

export const MAX_NEW_GRADUATE_RESEARCH_ROOMS = 3;

export const MAX_NEW_GRADUATE_PROGRAMMING_EXPERIENCES = 10;

export const MAX_NEW_GRADUATE_PREFERRED_BUSINESS_TYPES = 3;

export const MAX_NEW_GRADUATE_INTERNSHIP_EXPERIENCES = 5;

export const MAX_NEW_GRADUATE_STUDY_ABROAD_EXPERIENCES = 5;

export const MAX_NEW_GRADUATE_CLUB_EXPERIENCES = 5;

export const RESUME_FILE_ACCEPTS = '.xls,.xlsx,.doc,.docx,.pdf';

export const MAX_RESUME_FILE_SIZE_BYTES = 26214400; // 25MB

export const PREFERRED_WORK_LOCATIONS_CIRCLE_NUMBERS = ['①', '②', '③'];

export const END_YEAR_MONTH_CURRENTLY_ENROLLED_ID = 9999;

export const YEAR_OF_BIRTH_DEFAULT = 2010;
export const MONTH_OF_BIRTH_DEFAULT = 1;
export const JAPAN_PREFECTURE_ID_DEFAULT = 1;
export const PHONE_COUNTRY_ID_DEFAULT = 'JPN';
export const ACADEMIC_BACKGROUND_YEAR_OF_GRADUATION_DEFAULT = 2010;
export const ACADEMIC_BACKGROUND_MONTH_OF_GRADUATION_DEFAULT = 1;
export const WORKING_PERIOD_YEAR_DEFAULT = 1990;
export const WORKING_PERIOD_MONTH_DEFAULT = 1;
export const PHONE_COUNTRY_CODE_DEFAULT = '81';
export const RESUME_FILE_DEFAULT = {
  name: '',
  size: 0,
  key: '',
  url: '',
};
