import React, { FC } from 'react';
import styled from 'styled-components/macro';
import media from 'styles/media';
import { FieldError } from 'react-hook-form';
import * as MESSAGES from 'constants/messages';
import { INNOVATOR_TYPE } from 'constants/innovator';
import { ProfileFormItems } from 'types/profile';
import { isEqual } from 'utils/object';
import { Form, ButtonM } from 'components/atoms';
import {
  CareerSkillsFormField,
  EnglishSkillFormField,
  PreferredWorkLocationsFormField,
  CertificationsFormField,
  CareerAchievementFormField,
  OutputsFormField,
  PreferredOccupationsFormField,
  CareerPreferredEmploymentTypeFormField,
  CareerPreferredMonthlyUnitPriceFormField,
  CareerExpectationsFormField,
  CareerResumeFileFormField,
  SelfIntroductionFormField,
} from 'components/organisms';
import useCareerProfileSkillsAndPreferredConditionsForm from './useCareerProfileSkillsAndPreferredConditionsForm';

const Fields = styled.div``;

const Buttons = styled.div`
  display: grid;
  grid-column-gap: 16px;
  grid-template-columns: auto auto;

  ${media.smallMobile} {
    grid-row-gap: 16px;
    grid-template-columns: 1fr;
  }
`;

const CancelButton = styled(ButtonM)`
  justify-self: end;

  ${media.smallMobile} {
    order: 2;
    width: 100%;
  }
`;

const SubmitButton = styled(ButtonM)`
  ${media.smallMobile} {
    order: 1;
    width: 100%;
  }
`;

type ProfileSkillsAndPreferredConditionsCareerFormProps = {
  formItems: ProfileFormItems;
  onShowContent: () => void;
  onOpenCompletedModal: () => void;
};

const ProfileSkillsAndPreferredConditionsCareerForm: FC<ProfileSkillsAndPreferredConditionsCareerFormProps> = props => {
  const { formItems, onShowContent, onOpenCompletedModal } = props;

  const {
    innovator,
    formMethods,
    onSubmit,
    exampleSkills,
    skillsOptionsState,
    expectationsOptionsState,
    exampleExpectations,
    resumeFile,
    preferredOccupationsError,
    handleInputChangeSkill,
    handleCreateSkillOption,
    handleClickExampleSkill,
    fetchMoreSkillOptions,
    handleInputChangeExpectation,
    handleClickExampleExpectation,
    handleCreateExpectationOption,
    fetchMoreExpectationOptions,
    handleDropResumeFile,
    handleRemoveResumeFile,
  } = useCareerProfileSkillsAndPreferredConditionsForm({
    formItems,
    onShowContent,
    onOpenCompletedModal,
  });

  const {
    handleSubmit,
    errors,
    register,
    formState,
    control,
    trigger,
  } = formMethods;

  const { isValid, isSubmitting, isDirty, touched } = formState;
  const isDisabledSubmitButton = !isValid || isSubmitting || !isDirty;

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      data-testid="profile-skills-and-preferred-conditions-career-form"
    >
      <Fields>
        <CareerSkillsFormField
          inputValue={skillsOptionsState.inputValue}
          options={skillsOptionsState.options}
          fetchMore={fetchMoreSkillOptions}
          isLoading={skillsOptionsState.isLoading}
          onInputChange={handleInputChangeSkill}
          onCreateOption={handleCreateSkillOption}
          onClickExample={handleClickExampleSkill}
          error={
            !skillsOptionsState.isCreating && touched?.career?.skills
              ? errors?.career?.skills?.message
              : undefined
          }
          control={control}
          exampleSkills={exampleSkills}
        />

        <EnglishSkillFormField
          error={touched.englishSkillId && errors.englishSkillId?.message}
          englishSkills={formItems.englishSkills}
          control={control}
        />

        <CertificationsFormField control={control} register={register} />

        <CareerAchievementFormField
          error={
            touched.career?.achievement && errors.career?.achievement?.message
          }
          register={register}
        />

        {innovator?.innovatorType === INNOVATOR_TYPE.ENGINEER && (
          <OutputsFormField
            register={register}
            control={control}
            errors={errors.outputs}
            touched={touched.outputs}
          />
        )}

        <PreferredWorkLocationsFormField
          error={
            touched.preferredWorkLocationJapanPrefectureIds &&
            (errors.preferredWorkLocationJapanPrefectureIds as
              | FieldError
              | undefined)?.message
          }
          japanPrefectures={formItems.japanPrefectures}
          control={control}
          trigger={trigger}
        />

        <PreferredOccupationsFormField
          error={touched.preferredOccupations && preferredOccupationsError}
          occupations={formItems.occupations}
          control={control}
          trigger={trigger}
        />

        <CareerPreferredEmploymentTypeFormField
          error={
            touched.career?.preferredEmploymentTypeId &&
            errors.career?.preferredEmploymentTypeId?.message
          }
          employmentTypes={formItems.employmentTypes}
          control={control}
        />

        <CareerPreferredMonthlyUnitPriceFormField
          error={
            touched.career?.preferredMonthlyUnitPriceId &&
            errors.career?.preferredMonthlyUnitPriceId?.message
          }
          preferredMonthlyUnitPrices={formItems.preferredMonthlyUnitPrices}
          control={control}
        />

        <CareerExpectationsFormField
          inputValue={expectationsOptionsState.inputValue}
          options={expectationsOptionsState.options}
          fetchMore={fetchMoreExpectationOptions}
          isLoading={expectationsOptionsState.isLoading}
          onInputChange={handleInputChangeExpectation}
          onCreateOption={handleCreateExpectationOption}
          onClickExample={handleClickExampleExpectation}
          error={
            !expectationsOptionsState.isCreating && touched.career?.expectations
              ? errors.career?.expectations?.message
              : undefined
          }
          control={control}
          exampleExpectations={exampleExpectations}
        />

        <CareerResumeFileFormField
          onDrop={handleDropResumeFile}
          onRemove={handleRemoveResumeFile}
          control={control}
          resumeFile={resumeFile}
        />

        <SelfIntroductionFormField
          error={touched.selfIntroduction && errors.selfIntroduction?.message}
          register={register}
          control={control}
        />
      </Fields>

      <Buttons>
        <CancelButton buttonTheme="cancel" onClick={onShowContent}>
          {MESSAGES.PROFILE_SETTING.BUTTON.CANCEL}
        </CancelButton>

        <SubmitButton type="submit" disabled={isDisabledSubmitButton}>
          {MESSAGES.PROFILE_SETTING.BUTTON.SAVE}
        </SubmitButton>
      </Buttons>
    </Form>
  );
};

export default React.memo(
  ProfileSkillsAndPreferredConditionsCareerForm,
  isEqual,
);
