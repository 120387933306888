import { UseFormMethods } from 'react-hook-form';
import { ProfileFormMethods, ProfileFormValues } from 'types/profile';

type Payload = {
  formMethods: UseFormMethods<any>;
};

const useNewGraduatePreferredBusinessTypesFormField = ({
  formMethods,
}: Payload) => {
  const { errors } = (formMethods as unknown) as ProfileFormMethods<{
    newGraduate: Pick<
      ProfileFormValues['newGraduate'],
      'preferredBusinessTypes'
    >;
  }>;

  const newGraduatePreferredBusinessTypesError = (() => {
    const preferredBusinessTypeErrors =
      errors.newGraduate?.preferredBusinessTypes;
    if (!preferredBusinessTypeErrors) return undefined;

    const messages = preferredBusinessTypeErrors
      .filter(error => !!error?.id?.message)
      .map(error => error?.id?.message);

    return [...new Set(messages)][0];
  })();

  return {
    newGraduatePreferredBusinessTypesError,
  };
};

export default useNewGraduatePreferredBusinessTypesFormField;
