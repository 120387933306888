import React, { FC } from 'react';
import styled from 'styled-components/macro';
import media from 'styles/media';
import * as MESSAGES from 'constants/messages';
import { PROFILE_FIELDS_MAX_LENGTH } from 'constants/profile';
import { ProfileFormRegister, ProfileFormValues } from 'types/profile';
import { TextInput } from 'components/atoms';
import { Field } from 'components/molecules';

const StyledTextInput = styled(TextInput)`
  width: 368px;

  ${media.mobile} {
    width: 100%;
  }
`;

type AcademicBackgroundFacultyFormFieldProps = {
  error: string | undefined;
  register: ProfileFormRegister<{
    academicBackground: Pick<
      ProfileFormValues['academicBackground'],
      'faculty'
    >;
  }>;
  label?: string;
};

const AcademicBackgroundFacultyFormField: FC<AcademicBackgroundFacultyFormFieldProps> = props => {
  const {
    error,
    register,
    label = MESSAGES.PROFILE.FIELD.ACADEMIC_BACKGROUND_FACULTY.LABEL,
  } = props;
  return (
    <Field label={label} required>
      <StyledTextInput
        enterKeyHint="enter"
        name="academicBackground.faculty"
        error={error}
        maxLength={PROFILE_FIELDS_MAX_LENGTH.ACADEMIC_BACKGROUND_FACULTY}
        placeholder={
          MESSAGES.PROFILE.FIELD.ACADEMIC_BACKGROUND_FACULTY.PLACEHOLDER
        }
        aria-label={label}
        ref={register}
      />
    </Field>
  );
};

export default AcademicBackgroundFacultyFormField;
