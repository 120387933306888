import styled from 'styled-components/macro';
import media from 'styles/media';

const ProfileField = styled.div`
  margin-bottom: 44px;

  ${media.mobile} {
    margin-bottom: 24px;
  }
`;

export default ProfileField;
