import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { Controller } from 'react-hook-form';
import { InputActionMeta } from 'react-select';
import { MAX_SKILLS } from 'constants/profile';
import * as MESSAGES from 'constants/messages';
import { ProfileFormControl, ProfileFormValues } from 'types/profile';
import theme from 'styles/theme';
import { interpolateMessage } from 'utils/string';
import { ReactSelectOption } from 'types/form';
import { Field, CreatableInputSelect } from 'components/molecules';
import { Tag } from 'components/atoms';

const ExampleSkills = styled.div`
  margin-top: 8px;
`;

const ExampleSkillsDescription = styled.p`
  margin-bottom: 8px;
  color: ${theme.textBody};
  font-size: 12px;
`;

const ExampleSkillTags = styled.div``;

type Value = {
  career: Pick<ProfileFormValues['career'], 'skills'>;
};

type CareerSkillsFormFieldProps = {
  inputValue: string;
  error: string | undefined;
  control: ProfileFormControl<Value>;
  options: ReactSelectOption[];
  exampleSkills: ProfileFormValues['career']['skills'];
  isLoading: boolean;
  onInputChange: (inputValue: string, actionMeta: InputActionMeta) => void;
  onCreateOption: (inputValue: string) => void;
  fetchMore: () => Promise<void>;
  onClickExample: (skill: ReactSelectOption) => void;
};

const CareerSkillsFormField: FC<CareerSkillsFormFieldProps> = props => {
  const {
    inputValue,
    error,
    control,
    exampleSkills,
    options,
    isLoading,
    fetchMore,
    onInputChange,
    onCreateOption,
    onClickExample,
  } = props;

  return (
    <Field
      label={MESSAGES.PROFILE.FIELD.SKILLS.LABEL}
      required
      note={interpolateMessage(MESSAGES.PROFILE.FIELD.SKILLS.NOTE, {
        maxLength: MAX_SKILLS,
      })}
      data-testid="skills-field"
    >
      <Controller
        control={control}
        name="career.skills"
        render={innerProps => (
          <CreatableInputSelect
            enterKeyHint="enter"
            inputValue={inputValue}
            isMulti
            value={innerProps.value}
            error={error}
            options={options}
            onInputChange={onInputChange}
            onCreateOption={onCreateOption}
            isLoading={isLoading}
            onMenuScrollToBottom={fetchMore}
            onChange={innerProps.onChange}
            onBlur={innerProps.onBlur}
            placeholder={MESSAGES.PROFILE.FIELD.SKILLS.PLACEHOLDER}
            aria-label={MESSAGES.PROFILE.FIELD.SKILLS.LABEL}
          />
        )}
      />
      <ExampleSkills>
        <ExampleSkillsDescription>
          {MESSAGES.PROFILE.FIELD.SKILLS.DESCRIPTION.EXAMPLE}
        </ExampleSkillsDescription>
        <ExampleSkillTags>
          {exampleSkills &&
            exampleSkills.map(skill => (
              <Tag onClick={() => onClickExample(skill)} key={skill.value}>
                {skill.label}
              </Tag>
            ))}
        </ExampleSkillTags>
      </ExampleSkills>
    </Field>
  );
};

export default CareerSkillsFormField;
