import React, { FC } from 'react';
import * as MESSAGES from 'constants/messages';
import { PROFILE_FIELDS_MAX_LENGTH } from 'constants/profile';
import { ProfileFormRegister, ProfileFormValues } from 'types/profile';
import { TextInput } from 'components/atoms';
import { Field } from 'components/molecules';

type CareerAchievementFormFieldProps = {
  error: string | undefined;
  register: ProfileFormRegister<{
    career: Pick<ProfileFormValues['career'], 'achievement'>;
  }>;
};

const CareerAchievementFormField: FC<CareerAchievementFormFieldProps> = props => {
  const { error, register } = props;
  return (
    <Field label={MESSAGES.PROFILE.FIELD.CAREER_ACHIEVEMENT.LABEL}>
      <TextInput
        enterKeyHint="enter"
        name="career.achievement"
        error={error}
        maxLength={PROFILE_FIELDS_MAX_LENGTH.CAREER_ACHIEVEMENT}
        placeholder={MESSAGES.PROFILE.FIELD.CAREER_ACHIEVEMENT.PLACEHOLDER}
        aria-label={MESSAGES.PROFILE.FIELD.CAREER_ACHIEVEMENT.LABEL}
        ref={register}
      />
    </Field>
  );
};

export default CareerAchievementFormField;
