import React, { FC, HTMLAttributes } from 'react';
import styled, { css } from 'styled-components/macro';
import theme from 'styles/theme';
import media from 'styles/media';

const ModalTitle = styled.p`
  margin-bottom: 26px;
  color: ${theme.basePrimary};
  font-size: 20px;
  line-height: 1.5;

  ${media.mobile} {
    margin-bottom: 24px;
    font-size: 16px;
  }
`;

const Content = styled.div<{ hasTitle: boolean }>`
  ${({ hasTitle }) =>
    hasTitle
      ? css`
          padding-bottom: 48px;
          ${media.mobile} {
            padding-bottom: 40px;
          }
        `
      : css`
          display: flex;
          padding: 24px 0 64px;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          text-align: center;
        `}

  width: 100%;
  font-size: 16px;
  line-height: 1.5;
  word-wrap: break-word;
  white-space: pre-wrap;

  ${media.mobile} {
    font-size: 14px;
  }
`;

type ModalContentProps = HTMLAttributes<HTMLDivElement> & { title?: string };

const ModalContent: FC<ModalContentProps> = ({ title, ...props }) => (
  <>
    {title && <ModalTitle>{title}</ModalTitle>}
    <Content hasTitle={!!title} {...props}>
      {props.children}
    </Content>
  </>
);

export default ModalContent;
