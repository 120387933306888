import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';
import { UiActions } from 'modules/ui';
import { AccountActions } from 'modules/account';
import apiInnovator from 'external/api/innovator';
import { RegisterProfileFormNewGraduateThirdValues } from 'types/profile';
import RequestError from 'classes/RequestError';
import { UpdateInnovatorRequest } from 'proto/v1/innovatorservice/innovatorservice';
import { scrollLayoutBodyToTop } from 'utils/layout';
import {
  getDefaultNewGraduateInternshipExperiencesValue,
  getDefaultNewGraduateStudyAbroadExperiencesValue,
  getDefaultNewGraduateClubExperiencesValue,
} from 'utils/profile/defaultFormValues';
import {
  getNewGraduateInternshipExperiencesValueToUpdate,
  getNewGraduateStudyAbroadExperiencesValueToUpdate,
  getNewGraduateClubExperiencesValueToUpdate,
} from 'utils/profile/formValuesToUpdate';
import {
  newGraduateInternshipExperiencesSchema,
  newGraduateStudyAbroadExperiencesSchema,
  newGraduateClubExperiencesSchema,
  newGraduatePastExperienceAchievementIdsSchema,
  selfIntroductionSchema,
} from 'utils/profile/schema';
import { Stepper } from 'hooks/useStepper';
import useErrorNotification from 'hooks/useErrorNotification';
import useFetchInnovatorForm from 'hooks/profile/useFetchInnovatorForm';
import useNewGraduatePastExperienceAchievementsFormField from 'hooks/profile/useNewGraduatePastExperienceAchievementsFormField';
import { stepsNewGraduate } from '../../steps';

const schema = object().shape({
  newGraduate: object().shape({
    pastExperienceAchievementIds: newGraduatePastExperienceAchievementIdsSchema,
    internshipExperiences: newGraduateInternshipExperiencesSchema,
    studyAbroadExperiences: newGraduateStudyAbroadExperiencesSchema,
    clubExperiences: newGraduateClubExperiencesSchema,
  }),
  selfIntroduction: selfIntroductionSchema,
});

const defaultValues: RegisterProfileFormNewGraduateThirdValues = {
  selfIntroduction: '',
  newGraduate: {
    pastExperienceAchievementIds: [],
    internshipExperiences: [],
    studyAbroadExperiences: [],
    clubExperiences: [],
  },
};

const useRegisterProfileFormNewGraduateThird = ({
  stepper,
}: {
  stepper: Stepper;
}) => {
  const dispatch = useDispatch();
  const { handleRequestError } = useErrorNotification();
  const { formItems, innovator, innovatorToUpdate } = useFetchInnovatorForm();

  const [isValidDefault, setIsValidDefault] = useState<boolean>(false);

  const formMethods = useForm<RegisterProfileFormNewGraduateThirdValues>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues,
  });

  const { reset } = formMethods;

  const {
    handleSelectPastExperienceAchievements,
    handleDeselectPastExperienceAchievements,
  } = useNewGraduatePastExperienceAchievementsFormField({ formMethods });

  const handleClickPrevButton = useCallback(() => {
    scrollLayoutBodyToTop();
    stepper.handlePrev();
  }, [stepper]);

  const onSubmit = useCallback(
    async (values: RegisterProfileFormNewGraduateThirdValues) => {
      const valuesToUpdate: UpdateInnovatorRequest = {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        ...innovatorToUpdate!,
        selfIntroduction: values.selfIntroduction,
        newGraduate: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          ...innovatorToUpdate!.newGraduate!,
          pastExperienceAchievementIds:
            values.newGraduate.pastExperienceAchievementIds,
          internshipExperiences: getNewGraduateInternshipExperiencesValueToUpdate(
            values.newGraduate.internshipExperiences,
          ),
          studyAbroadExperiences: getNewGraduateStudyAbroadExperiencesValueToUpdate(
            values.newGraduate.studyAbroadExperiences,
          ),
          clubExperiences: getNewGraduateClubExperiencesValueToUpdate(
            values.newGraduate.clubExperiences,
          ),
        },
      };
      let data;
      try {
        ({ data } = await apiInnovator.updateInnovator(valuesToUpdate));
      } catch (error: unknown) {
        if (error instanceof RequestError) {
          dispatch(UiActions.setLoading(false));
          handleRequestError(error);
        }
        return;
      }
      dispatch(AccountActions.setInnovator(data.innovator));
      dispatch(UiActions.setLoading(false));
      scrollLayoutBodyToTop();
      stepsNewGraduate.formThird.completed = true;
      stepper.handleNext();
    },
    [dispatch, handleRequestError, innovatorToUpdate, stepper],
  );

  // Set default values by the fetched innovator
  useEffect(() => {
    if (!innovatorToUpdate || !innovator) return;

    const values: RegisterProfileFormNewGraduateThirdValues = {
      selfIntroduction: innovatorToUpdate.selfIntroduction,
      newGraduate: {
        pastExperienceAchievementIds:
          innovatorToUpdate.newGraduate?.pastExperienceAchievementIds ?? [],
        internshipExperiences: getDefaultNewGraduateInternshipExperiencesValue(
          innovatorToUpdate.newGraduate?.internshipExperiences,
        ),
        studyAbroadExperiences: getDefaultNewGraduateStudyAbroadExperiencesValue(
          innovatorToUpdate.newGraduate?.studyAbroadExperiences,
        ),
        clubExperiences: getDefaultNewGraduateClubExperiencesValue(
          innovatorToUpdate.newGraduate?.clubExperiences,
        ),
      },
    };

    setIsValidDefault(schema.isValidSync(values));

    reset(values);
  }, [innovator, innovatorToUpdate, reset]);

  return {
    innovator,
    onSubmit,
    formMethods,
    formItems,
    isValidDefault,
    handleClickPrevButton,
    handleSelectPastExperienceAchievements,
    handleDeselectPastExperienceAchievements,
  };
};

export default useRegisterProfileFormNewGraduateThird;
