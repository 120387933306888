import { number, object, string, array } from 'yup';
import { interpolateMessage } from 'utils/string';
import * as schema from 'utils/schema';
import { isUnique } from 'utils/array';
import { NO_SELECT_NUMBER } from 'constants/form';
import {
  MAX_EXPECTATIONS,
  MAX_SKILLS,
  PROFILE_FIELDS_MIN_LENGTH,
} from 'constants/profile';
import * as MESSAGES from 'constants/messages';
import { parseNumber } from 'utils/number';
import {
  isValidPeriodOrder,
  isWorkingPeriodStartDateBeforeEndDate,
} from './fields';

export const lastNameSchema = string().required(MESSAGES.ERROR.REQUIRED_INPUT);

export const lastNameKanaSchema = string().required(
  MESSAGES.ERROR.REQUIRED_INPUT,
);

export const firstNameSchema = string().required(MESSAGES.ERROR.REQUIRED_INPUT);

export const firstNameKanaSchema = string().required(
  MESSAGES.ERROR.REQUIRED_INPUT,
);

export const yearOfBirthSchema = number().required(
  MESSAGES.ERROR.REQUIRED_SELECT,
);

export const monthOfBirthSchema = number().required(
  MESSAGES.ERROR.REQUIRED_SELECT,
);

export const phoneNumberSchema = schema.phoneNumberSchema.required(
  MESSAGES.ERROR.REQUIRED_INPUT,
);

export const phoneCountryIdSchema = string().required(
  MESSAGES.ERROR.REQUIRED_SELECT,
);

export const japanPrefectureIdSchema = number().required(
  MESSAGES.ERROR.REQUIRED_SELECT,
);

export const academicBackgroundSchema = object().shape({
  school: object().nullable().required(MESSAGES.ERROR.REQUIRED_SELECT),
  faculty: string().required(MESSAGES.ERROR.REQUIRED_INPUT),
  departmentId: number().positive(MESSAGES.ERROR.REQUIRED_SELECT),
  yearOfGraduation: number().required(MESSAGES.ERROR.REQUIRED_SELECT),
  monthOfGraduation: number().required(MESSAGES.ERROR.REQUIRED_SELECT),
});

export const careerSkillsSchema = array()
  .nullable()
  .required(MESSAGES.ERROR.REQUIRED_SELECT)
  .max(
    MAX_SKILLS,
    interpolateMessage(MESSAGES.ERROR.MAX_REGISTER_COUNT, {
      maxCount: MAX_SKILLS,
    }),
  );

export const englishSkillIdSchema = number().positive(
  MESSAGES.ERROR.REQUIRED_SELECT,
);

export const preferredWorkLocationJapanPrefectureIdSchema = array()
  .of(number())
  .test(
    'requiredWorkLocationJapanPrefectureIds',
    MESSAGES.ERROR.SELECT_ONE_OR_MORE,
    ids => !!ids && ids.some(id => id !== NO_SELECT_NUMBER),
  )
  .test(
    'duplicatedWorkLocationJapanPrefectureIds',
    interpolateMessage(MESSAGES.ERROR.DUPLICATED_SELECT, {
      label: MESSAGES.PROFILE.FIELD.PREFERRED_WORK_LOCATIONS.LABEL,
    }),
    ids => {
      const selectedIds = ids ? ids.filter(id => id !== NO_SELECT_NUMBER) : [];
      return isUnique(selectedIds);
    },
  );

export const outputsSchema = array().of(
  object().shape({
    value: string().url(MESSAGES.ERROR.INVALID_URL),
  }),
);

export const preferredOccupationsSchema = array().of(
  object().shape({
    id: number()
      .test(
        'requiredPreferredOccupations',
        MESSAGES.ERROR.SELECT_ONE_OR_MORE,
        function validateRequiredPreferredOccupations() {
          const preferredOccupations: { id: string | number }[] | undefined =
            // @ts-ignore "this.from" does not defined in @types/yup
            this?.from[1]?.value.preferredOccupations;

          return (
            !!preferredOccupations &&
            preferredOccupations.some(
              ({ id }) => parseNumber(id) !== NO_SELECT_NUMBER,
            )
          );
        },
      )
      .test(
        'duplicatedPreferredOccupations',
        interpolateMessage(MESSAGES.ERROR.DUPLICATED_SELECT, {
          label: MESSAGES.PROFILE.FIELD.PREFERRED_OCCUPATIONS.LABEL,
        }),
        function validateDuplicatedPreferredOccupations() {
          const preferredOccupations: { id: string | number }[] | undefined =
            // @ts-ignore "this.from" does not defined in @types/yup
            this?.from[1]?.value.preferredOccupations;

          const selectedIds = preferredOccupations
            ? preferredOccupations
                .map(({ id }) => parseNumber(id))
                .filter(id => id !== NO_SELECT_NUMBER)
            : [];

          return isUnique(selectedIds);
        },
      ),
  }),
);

export const careerExpectationsSchema = array()
  .nullable()
  .required(MESSAGES.ERROR.REQUIRED_SELECT)
  .max(
    MAX_EXPECTATIONS,
    interpolateMessage(MESSAGES.ERROR.MAX_REGISTER_COUNT, {
      maxCount: MAX_EXPECTATIONS,
    }),
  );

export const selfIntroductionSchema = string()
  .required(MESSAGES.ERROR.REQUIRED_INPUT)
  .min(
    PROFILE_FIELDS_MIN_LENGTH.SELF_INTRODUCTION,
    interpolateMessage(MESSAGES.ERROR.MIN_LENGTH, {
      minLength: PROFILE_FIELDS_MIN_LENGTH.SELF_INTRODUCTION,
    }),
  )
  .matches(
    RegExp(/^(?!.*(\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$)).*/is),
    MESSAGES.ERROR.URL_AND_EMAIL_NOT_ALLOWED,
  )
  .matches(
    RegExp(
      /^(?!.*(\b(?:(?:https?|http|ftps?|ftp):\/\/|www\.|([a-z0-9]?\.[a-z0-9]?))[-a-z0-9+&@#/%?=~_|!:,.;]*[-a-z0-9+&@#/%=~_|])).*/is,
    ),
    MESSAGES.ERROR.URL_AND_EMAIL_NOT_ALLOWED,
  );

export const careerPreferredEmploymentTypeIdSchema = number().positive(
  MESSAGES.ERROR.REQUIRED_SELECT,
);

export const careerAnnualIncomeIdSchema = number().positive(
  MESSAGES.ERROR.REQUIRED_SELECT,
);

export const careerNumberOfJobChangesIdSchema = number().positive(
  MESSAGES.ERROR.REQUIRED_SELECT,
);

export const careerMonthlyUnitPriceSchema = number()
  .typeError(MESSAGES.ERROR.INVALID_MONTHLY_UNIT_PRICE)
  .integer(MESSAGES.ERROR.INVALID_MONTHLY_UNIT_PRICE)
  .positive(MESSAGES.ERROR.INVALID_MONTHLY_UNIT_PRICE)
  // Allow empty string
  .nullable()
  .transform((value: string, originalValue: string) =>
    originalValue.trim() === '' ? null : value,
  );

export const careerPreferredMonthlyUnitPriceIdSchema = number();

const workingPeriodSchema = number()
  .positive(MESSAGES.ERROR.REQUIRED_SELECT)
  .test(
    'invalidDateOrder',
    MESSAGES.ERROR.INVALID_WORKING_PERIOD,
    function validateWorkingPeriodOrder() {
      return isWorkingPeriodStartDateBeforeEndDate({
        startYear: this.parent.startYear,
        startMonth: this.parent.startMonth,
        endYear: this.parent.endYear,
        endMonth: this.parent.endMonth,
      });
    },
  );

export const careerJobSchema = array().of(
  object().shape({
    name: string().required(MESSAGES.ERROR.REQUIRED_INPUT),
    startYear: workingPeriodSchema,
    startMonth: workingPeriodSchema,
    endYear: workingPeriodSchema,
    endMonth: workingPeriodSchema,
    occupations: array().of(
      object().shape({
        occupationId: number().positive(MESSAGES.ERROR.REQUIRED_SELECT),
        yearsOfExperienceId: number().positive(MESSAGES.ERROR.REQUIRED_SELECT),
      }),
    ),
    positionId: number().positive(MESSAGES.ERROR.REQUIRED_SELECT),
  }),
);

export const newGraduateResearchRoomsSchema = array().of(
  object().shape({
    name: string(),
    content: string(),
  }),
);

export const dttScoreSchema = number()
  .typeError(MESSAGES.ERROR.INVALID_DTT_SCORE)
  .integer(MESSAGES.ERROR.INVALID_DTT_SCORE)
  .positive(MESSAGES.ERROR.INVALID_DTT_SCORE)
  // Allow empty string
  .nullable()
  .transform((value: string, originalValue: string) =>
    originalValue.trim() === '' ? null : value,
  )
  .test(
    'requiredAllDttFields',
    MESSAGES.ERROR.REQUIRED_INPUT_ALL_DTT_FIELDS,
    function validateRequiredAllDttFields() {
      if (!this.parent.dttRankId) return true;
      return !!this.parent.dttScore;
    },
  );

export const dttRankIdSchema = number().test(
  'requiredAllDttFields',
  MESSAGES.ERROR.REQUIRED_INPUT_ALL_DTT_FIELDS,
  function validateRequiredAllDttFields() {
    if (!this.parent.dttScore) return true;
    return !!this.parent.dttRankId;
  },
);

export const newGraduateProgrammingExperiencesSchema = array().of(
  object().shape({
    languageName: string().test(
      'requiredAllProgrammingExperiencesFields',
      MESSAGES.ERROR.REQUIRED_INPUT_ALL_PROGRAMMING_EXPERIENCES_FIELDS,
      function validateRequiredAllProgrammingExperiencesFields() {
        if (!this.parent.levelId) return true;
        return !!this.parent.languageName;
      },
    ),
    levelId: number().test(
      'requiredAllProgrammingExperiencesFields',
      MESSAGES.ERROR.REQUIRED_INPUT_ALL_PROGRAMMING_EXPERIENCES_FIELDS,
      function validateRequiredAllProgrammingExperiencesFields() {
        if (!this.parent.languageName) return true;
        return !!this.parent.levelId;
      },
    ),
  }),
);

export const newGraduatePreferredWorkStyleIdSchema = number().positive(
  MESSAGES.ERROR.REQUIRED_SELECT,
);

export const newGraduatePreferredBusinessTypesSchema = array().of(
  object().shape({
    id: number()
      .test(
        'requiredNewGraduatePreferredBusinessTypes',
        MESSAGES.ERROR.SELECT_ONE_OR_MORE,
        function validateRequiredPreferredBusinessTypes() {
          const preferredBusinessTypes: { id: string | number }[] | undefined =
            // @ts-ignore "this.from" does not defined in @types/yup
            this?.from[1]?.value.preferredBusinessTypes;

          return (
            !!preferredBusinessTypes &&
            preferredBusinessTypes.some(
              ({ id }) => parseNumber(id) !== NO_SELECT_NUMBER,
            )
          );
        },
      )
      .test(
        'duplicatedNewGraduatePreferredBusinessTypes',
        interpolateMessage(MESSAGES.ERROR.DUPLICATED_SELECT, {
          label:
            MESSAGES.PROFILE.FIELD.NEW_GRADUATE_PREFERRED_BUSINESS_TYPES.LABEL,
        }),
        function validateDuplicatedPreferredOccupations() {
          const preferredBusinessTypes: { id: string | number }[] | undefined =
            // @ts-ignore "this.from" does not defined in @types/yup
            this?.from[1]?.value.preferredBusinessTypes;

          const selectedIds = preferredBusinessTypes
            ? preferredBusinessTypes
                .map(({ id }) => parseNumber(id))
                .filter(id => id !== NO_SELECT_NUMBER)
            : [];

          return isUnique(selectedIds);
        },
      ),
  }),
);

export const periodSchema = number().test(
  'invalidPeriodOrder',
  MESSAGES.ERROR.INVALID_PERIOD_ORDER,
  function validateWorkingPeriodOrder() {
    return isValidPeriodOrder({
      startYear: this.parent.startYear,
      startMonth: this.parent.startMonth,
      endYear: this.parent.endYear,
      endMonth: this.parent.endMonth,
    });
  },
);

export const newGraduatePastExperienceAchievementIdsSchema = array().of(
  number(),
);

export const newGraduateInternshipExperiencesSchema = array().of(
  object().shape({
    companyName: string(),
    startYear: periodSchema,
    startMonth: periodSchema,
    endYear: periodSchema,
    endMonth: periodSchema,
  }),
);

export const newGraduateStudyAbroadExperiencesSchema = array().of(
  object().shape({
    countryName: string(),
    startYear: periodSchema,
    startMonth: periodSchema,
    endYear: periodSchema,
    endMonth: periodSchema,
  }),
);

export const newGraduateClubExperiencesSchema = array().of(
  object().shape({
    name: string(),
    content: string(),
  }),
);
