import { css, FlattenSimpleInterpolation } from 'styled-components/macro';
import theme from 'styles/theme';

const themeDefault = css`
  background: ${theme.basePrimary};
  color: ${theme.white};

  &:hover {
    background: ${theme.buttonDefaultHover};
  }

  &:disabled {
    background: ${theme.baseDisabled};
  }
`;

const themeCancel = css`
  border: 1px solid ${theme.basePrimary};
  background: ${theme.white};
  color: ${theme.basePrimary};

  &:hover {
    background: ${theme.buttonCancelHover};
  }

  &:disabled {
    background: ${theme.baseDisabled};
  }
`;

export type ThemesType = 'default' | 'cancel';

const buttonThemes: { [key in ThemesType]: FlattenSimpleInterpolation } = {
  default: themeDefault,
  cancel: themeCancel,
};

export default buttonThemes;
