import React, { FC, ComponentProps } from 'react';
import styled from 'styled-components/macro';
import BaseButton from './BaseButton';

const StyledButtonM = styled(BaseButton)<ComponentProps<typeof BaseButton>>`
  width: 200px;
  height: 40px;
  padding: 6px 16px;
  font-size: 14px;
  text-align: center;
`;

const ButtonM: FC<ComponentProps<typeof BaseButton>> = props => (
  <StyledButtonM {...props}>{props.children}</StyledButtonM>
);
export default ButtonM;
