import React, { FC, HTMLAttributes } from 'react';
import styled, { css } from 'styled-components/macro';
import media from 'styles/media';

type InformationSize = 'small' | 'medium';

const Wrapper = styled.div<{ size: InformationSize }>`
  width: 100%;
  margin-bottom: 16px;
  border-radius: 4px;
  background-color: #eae7cb;
  color: #746800;
  line-height: 1.5;
  ${({ size }) =>
    size === 'small' &&
    css`
      font-size: 12px;
      padding: 8px;

      ${media.mobile} {
        padding: 6px;
      }
    `}
  ${({ size }) =>
    size === 'medium' &&
    css`
      font-size: 16px;
      padding: 12px 16px;

      ${media.mobile} {
        font-size: 14px;
        padding: 8px 12px;
      }
    `}
`;

type InformationProps = {
  size?: InformationSize;
} & HTMLAttributes<HTMLDivElement>;

const Information: FC<InformationProps> = ({
  children,
  size = 'medium',
  ...rest
}) => (
  <Wrapper size={size} {...rest}>
    {children}
  </Wrapper>
);

export default Information;
