import React, { FC, useState, useCallback } from 'react';
import { GroupLabel } from 'components/atoms';
import { FormFieldMarkDescription } from 'components/molecules';
import { Information as InformationIcon } from 'assets/svg';
import styled from 'styled-components/macro';
import media from 'styles/media';
import theme from 'styles/theme';

const Wrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
`;

const Label = styled(GroupLabel)`
  margin-right: 24px;
  margin-bottom: 0;

  ${media.mobile} {
    margin-right: 16px;
  }
`;

const Information = styled.div``;

const StyledInformationIcon = styled(InformationIcon)`
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const Tooltip = styled.div`
  position: absolute;
  z-index: 1;
  top: 32px;
  left: 0;
  width: 488px;
  padding: 12px;
  border-radius: 4px;
  background-color: ${theme.baseWhite};
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.12);
  color: ${theme.textPrimary};
  text-align: left;
  white-space: pre-wrap;

  ${media.mobile} {
    left: 0;
    max-width: 284px;
  }
`;

type Props = {
  label: string;
  showInformation: boolean;
};

const ProfileGroupLabel: FC<Props> = ({ label, showInformation }) => {
  const [showInformationTooltip, setShowInformationTooltip] = useState<boolean>(
    false,
  );

  const openInformationTooltip = useCallback(() => {
    setShowInformationTooltip(true);
  }, []);

  const closeInformationTooltip = useCallback(() => {
    setShowInformationTooltip(false);
  }, []);

  const toggleInformationTooltip = useCallback(() => {
    setShowInformationTooltip(prev => !prev);
  }, []);

  return (
    <Wrapper>
      <Label>{label}</Label>
      {showInformation && (
        <Information
          onMouseEnter={openInformationTooltip}
          onMouseLeave={closeInformationTooltip}
          onClick={toggleInformationTooltip}
        >
          <StyledInformationIcon />
          {showInformationTooltip && (
            <Tooltip>
              <FormFieldMarkDescription />
            </Tooltip>
          )}
        </Information>
      )}
    </Wrapper>
  );
};

export default ProfileGroupLabel;
