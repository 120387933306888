import { ComponentProps } from 'react';
import styled from 'styled-components/macro';
import BaseTextareaAutosize from 'react-textarea-autosize';
import theme from 'styles/theme';
import media from 'styles/media';

type Props = ComponentProps<typeof BaseTextareaAutosize> & {
  error?: string;
};

const TextareaAutosize = styled(BaseTextareaAutosize)<Props>`
  width: 100%;
  padding: 8px 12px;
  border: 1px solid
    ${({ error }) => (error ? theme.baseError : theme.borderDefault)};
  border-radius: 3px;
  outline: none;
  font-size: 14px;
  line-height: 1.5;
  appearance: none;

  &:hover,
  &:focus,
  &:active {
    border-color: ${({ error }) =>
      error ? theme.baseError : theme.borderDefault};
    box-shadow: none;
  }

  &::placeholder {
    color: ${theme.borderDefault};
  }

  ${media.mobile} {
    font-size: 16px;
  }

  &:hover:not(:disabled):not(:focus) {
    border-color: ${({ error }) =>
      error ? theme.baseError : theme.borderDefault};
  }

  &:hover:disabled {
    border-color: ${theme.borderDefault};
  }
`;

export default TextareaAutosize;
