import React, { FC } from 'react';
import styled from 'styled-components/macro';
import theme from 'styles/theme';
import media from 'styles/media';
import { Header, Footer, ErrorNotification } from 'components/organisms';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
`;

const Content = styled.div`
  display: flex;
  flex: 1 0 auto;
  justify-content: center;
  padding-bottom: 80px;
  background-color: ${theme.bgDefault};

  ${media.tablet} {
    padding-right: 12px;
    padding-bottom: 40px;
    padding-left: 12px;
  }
`;

const ContentInner = styled.div`
  width: 100%;
  max-width: 900px;
`;

const RouteLayout: FC = props => {
  return (
    <Container>
      <Header />
      <ErrorNotification />
      <Body>
        <Content>
          <ContentInner>{props.children}</ContentInner>
        </Content>
        <Footer />
      </Body>
    </Container>
  );
};

export default RouteLayout;
