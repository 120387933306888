import React, { forwardRef, useState, ComponentProps } from 'react';
import { TextInput } from 'components/atoms';
import styled from 'styled-components/macro';
import { FiEye, FiEyeOff } from 'react-icons/fi';

const StyledTextInput = styled(TextInput)`
  input {
    padding-right: 40px;
  }
`;

const WithIconInput = styled.div`
  position: relative;
  z-index: 1;
`;

const Icon = styled.div`
  display: flex;
  position: absolute;
  z-index: 2;
  top: 4px;
  right: 4px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
`;

type InputPasswordProps = ComponentProps<typeof TextInput>;

const InputPassword = forwardRef<HTMLInputElement | null, InputPasswordProps>(
  (props, ref) => {
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const togglePassword = () =>
      setShowPassword(prevShowPassword => !prevShowPassword);

    return (
      <WithIconInput>
        <StyledTextInput
          enterKeyHint="enter"
          type={showPassword ? 'text' : 'password'}
          ref={ref}
          {...props}
        />
        <Icon>
          {showPassword ? (
            <FiEye onClick={togglePassword} data-testid="show-password" />
          ) : (
            <FiEyeOff onClick={togglePassword} data-testid="hide-password" />
          )}
        </Icon>
      </WithIconInput>
    );
  },
);

export default InputPassword;
