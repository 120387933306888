import * as Long from 'long';
import { Writer, Reader } from 'protobufjs/minimal';
import { Int32Value, StringValue, BoolValue } from '../../../proto/google/protobuf/wrappers';


export interface MissionAccount {
  accountId: string;
  missionId: string;
  missionName: string;
  coverImage: Images | undefined;
  published: boolean;
}

export interface SupporterAccount {
  accountId: string;
  supporterId: string;
  supporterName: string;
  coverImage: Images | undefined;
  published: boolean;
}

export interface AdminAccount {
  accountId: string;
  adminId: string;
}

export interface InnovatorAccount {
  accountId: string;
  innovatorId: string;
}

export interface Innovator {
  innovatorId: string;
  innovatorType: string;
  lastName: string;
  firstName: string;
  lastNameKana: string;
  firstNameKana: string;
  yearOfBirth: number;
  monthOfBirth: number;
  phoneCountryCode: string;
  phoneNumber: string;
  japanPrefecture: JapanPrefecture | undefined;
  career: CareerProfile | undefined;
  newGraduate: NewGraduateProfile | undefined;
  preferredWorkLocations: WorkLocation[];
  preferredOccupations: Occupation[];
  academicBackground: AcademicBackground | undefined;
  englishSkill: EnglishSkill | undefined;
  selfIntroduction: string;
  certifications: string[];
  outputs: string[];
  dttScore: number | undefined;
  dttRank: DttRank | undefined;
  dateOfBirth: number;
  gender: Gender | undefined;
}

export interface CareerProfile {
  job: CareerProfile_JobHistory[];
  annualIncome: AnnualIncome | undefined;
  monthlyUnitPrice: number | undefined;
  numberOfJobChanges: NumberOfJobChanges | undefined;
  resumeFile: ResumeFile | undefined;
  preferredMonthlyUnitPrice: PreferredMonthlyUnitPrice | undefined;
  achievement: string;
  preferredEmploymentType: EmploymentType | undefined;
  skills: Skill[];
  expectations: Expectation[];
}

export interface CareerProfile_JobHistory {
  name: string;
  position: Position | undefined;
  startYear: number;
  startMonth: number;
  endYear: number | undefined;
  endMonth: number | undefined;
  occupations: CareerProfile_ExperiencedOccupation[];
}

export interface CareerProfile_ExperiencedOccupation {
  occupation: Occupation | undefined;
  yearsOfExperience: YearsOfExperience | undefined;
}

export interface NewGraduateProfile {
  researchRooms: ResearchRoom[];
  programmingExperiences: ProgrammingExperience[];
  preferredWorkStyle: PreferredWorkStyle | undefined;
  preferredBusinessTypes: PreferredBusinessType[];
  pastExperienceAchievements: PastExperienceAchievement[];
  internshipExperiences: InternshipExperience[];
  studyAbroadExperiences: StudyAbroadExperience[];
  clubExperiences: ClubExperience[];
}

export interface ResearchRoom {
  name: string;
  content: string;
}

export interface DttRank {
  id: number;
  name: string;
}

export interface Gender {
  id: number;
  name: string;
  nameEn: string;
}

export interface ProgrammingExperience {
  languageName: string;
  level: ProgrammingExperienceLevel | undefined;
}

export interface ProgrammingExperienceLevel {
  id: number;
  name: string;
}

export interface PreferredWorkStyle {
  id: number;
  name: string;
}

export interface PreferredBusinessType {
  id: number;
  name: string;
}

export interface PastExperienceAchievement {
  id: number;
  name: string;
}

export interface InternshipExperience {
  companyName: string;
  startYear: number | undefined;
  startMonth: number | undefined;
  endYear: number | undefined;
  endMonth: number | undefined;
}

export interface StudyAbroadExperience {
  countryName: string;
  startYear: number | undefined;
  startMonth: number | undefined;
  endYear: number | undefined;
  endMonth: number | undefined;
}

export interface ClubExperience {
  name: string;
  content: string;
}

export interface WorkLocation {
  japanPrefecture: JapanPrefecture | undefined;
}

export interface School {
  id: number;
  name: string;
}

export interface Department {
  id: number;
  name: string;
}

export interface EmploymentType {
  id: number;
  name: string;
}

export interface EnglishSkill {
  id: number;
  name: string;
}

export interface NumberOfJobChanges {
  id: number;
  name: string;
}

export interface AnnualIncome {
  id: number;
  name: string;
}

export interface PreferredMonthlyUnitPrice {
  id: number;
  name: string;
}

export interface Occupation {
  id: number;
  name: string;
}

export interface YearsOfExperience {
  id: number;
  name: string;
}

export interface Position {
  id: number;
  name: string;
}

export interface Skill {
  id: number;
  name: string;
}

export interface Expectation {
  id: number;
  name: string;
}

export interface SelectionStep {
  id: number;
  name: string;
}

export interface ResumeFile {
  url: string;
  name: string;
  size: number;
  key: string;
}

export interface MissionFollower {
  accountId: string;
  mission: Mission | undefined;
  followedAt: number;
  interests: Interest[];
  favorite: boolean;
  chatRoomId: string;
  messageLastSentAt: number;
  isFollowing: boolean;
}

export interface MissionFollow {
  followeeAccountId: string;
  mission: Mission | undefined;
  followedAt: number;
  interests: Interest[];
  isFollowed: boolean;
  chatRoomId: string;
}

export interface AccountFollowee {
  followeeAccountId: string;
  followedAt: number;
  isFollowed: boolean;
  chatRoomId: string;
  mission: AccountFollowee_MissionAccountFollowee | undefined;
  supporter: AccountFollowee_SupporterAccountFollowee | undefined;
}

export interface AccountFollowee_MissionAccountFollowee {
  mission: Mission | undefined;
  interests: Interest[];
  supportAreas: SupportArea[];
  contactMessage: string;
}

export interface AccountFollowee_SupporterAccountFollowee {
  supporter: Supporter | undefined;
  supportAreas: SupportArea[];
  contactMessage: string;
}

export interface AccountFollower {
  followerAccountId: string;
  followedAt: number;
  isFollowing: boolean;
  chatRoomId: string;
  mission: AccountFollower_MissionAccountFollower | undefined;
  supporter: AccountFollower_SupporterAccountFollower | undefined;
}

export interface AccountFollower_MissionAccountFollower {
  mission: Mission | undefined;
  interests: Interest[];
  supportAreas: SupportArea[];
}

export interface AccountFollower_SupporterAccountFollower {
  supporter: Supporter | undefined;
  supportAreas: SupportArea[];
  contactMessage: string;
}

export interface Interest {
  id: number;
  name: string;
}

export interface Language {
  id: number;
  name: string;
}

export interface Degree {
  id: number;
  name: string;
}

export interface Images {
  original: Images_Image | undefined;
  small: Images_Image | undefined;
  large: Images_Image | undefined;
}

export interface Images_Image {
  url: string;
  width: number;
  height: number;
}

export interface ImageToUpdate {
  data: string;
  delete: boolean;
  key: string;
}

export interface Mission {
  missionId: string;
  name: string;
  iconImage: Images | undefined;
  coverImage: Images | undefined;
  missionDescription: string;
  strengthPoint: string;
  companyName: string;
  country: Country | undefined;
  japanPrefecture: JapanPrefecture | undefined;
  otherAddress: string;
  memberRange: MemberRange | undefined;
  fund: Fund | undefined;
  missionCategories: MissionCategory[];
  missionMembers: MissionMember[];
  missionDemand: MissionDemand | undefined;
  matchPercentage: number;
  companyUrl: string;
  degreeOfAttention: number;
  missionOfficeImages: MissionOfficeImage[];
  missionProducts: MissionProduct[];
  corporateAttributes: CorporateAttribute[];
  companyEstablishedDate: CompanyEstablishedDate | undefined;
  language: string;
  missionJobOffers: MissionJobOffer[];
}

export interface MissionDemand {
  collaboration: MissionDemandCollaboration | undefined;
  invest: MissionDemandInvest | undefined;
  fundraising: MissionDemandFundraising | undefined;
  serviceInformation: MissionDemandServiceInformation | undefined;
  serviceSuggestion: MissionDemandServiceSuggestion | undefined;
  transferOfBusiness: MissionDemandTransferOfBusiness | undefined;
  transferredOfBusiness: MissionDemandTransferredOfBusiness | undefined;
  acceptSecondment: MissionDemandAcceptSecondment | undefined;
  sendEmployee: MissionDemandSendEmployee | undefined;
  furnishedOfficeRentalAvailability: MissionDemandFurnishedOfficeRentalAvailability | undefined;
  furnishedOfficeRentalRequest: MissionDemandFurnishedOfficeRentalRequest | undefined;
}

export interface MissionDemandCollaboration {
  assets: MissionDemandCollaborationAsset[];
}

export interface MissionDemandCollaborationAsset {
  give: string | undefined;
  want: string | undefined;
}

export interface MissionDemandInvest {
  financingSeries: FinancingSeries[];
}

export interface MissionDemandFundraising {
  financingSeries: FinancingSeries | undefined;
}

export interface MissionDemandServiceInformation {
  issues: string[];
}

export interface MissionDemandServiceSuggestion {
}

export interface MissionDemandTransferOfBusiness {
}

export interface MissionDemandTransferredOfBusiness {
  fund: Fund | undefined;
}

export interface MissionDemandAcceptSecondment {
  work: string | undefined;
}

export interface MissionDemandSendEmployee {
  work: string | undefined;
}

export interface MissionDemandFurnishedOfficeRentalAvailability {
}

export interface MissionDemandFurnishedOfficeRentalRequest {
}

export interface MissionDemandToUpdate {
  collaboration: MissionDemandCollaborationToUpdate | undefined;
  invest: MissionDemandInvestToUpdate | undefined;
  fundraising: MissionDemandFundraisingToUpdate | undefined;
  serviceInformation: MissionDemandServiceInformationToUpdate | undefined;
  serviceSuggestion: MissionDemandServiceSuggestionToUpdate | undefined;
  transferOfBusiness: MissionDemandTransferOfBusinessToUpdate | undefined;
  transferredOfBusiness: MissionDemandTransferredOfBusinessToUpdate | undefined;
  acceptSecondment: MissionDemandAcceptSecondmentToUpdate | undefined;
  sendEmployee: MissionDemandSendEmployeeToUpdate | undefined;
  furnishedOfficeRentalAvailability: MissionDemandFurnishedOfficeRentalAvailabilityToUpdate | undefined;
  furnishedOfficeRentalRequest: MissionDemandFurnishedOfficeRentalRequestToUpdate | undefined;
}

export interface MissionDemandCollaborationToUpdate {
  assets: MissionDemandCollaborationAsset[];
}

export interface MissionDemandInvestToUpdate {
  financingSeriesId: number[];
}

export interface MissionDemandFundraisingToUpdate {
  financingSeriesId: number;
}

export interface MissionDemandServiceInformationToUpdate {
  issues: string[];
}

export interface MissionDemandServiceSuggestionToUpdate {
}

export interface MissionDemandTransferOfBusinessToUpdate {
}

export interface MissionDemandTransferredOfBusinessToUpdate {
  fundId: number;
}

export interface MissionDemandAcceptSecondmentToUpdate {
  work: string | undefined;
}

export interface MissionDemandSendEmployeeToUpdate {
  work: string | undefined;
}

export interface MissionDemandFurnishedOfficeRentalAvailabilityToUpdate {
}

export interface MissionDemandFurnishedOfficeRentalRequestToUpdate {
}

export interface MissionCategory {
  categoryId: number;
  name: string;
  backgroundColor: string;
  inactiveTextColor: string;
  inactiveBackgroundColor: string;
  displayOrder: number;
}

export interface MissionMember {
  missionMemberId: number;
  name: string;
  career: string;
  iconImage: Images | undefined;
}

export interface MissionMemberToUpdate {
  missionMemberId: number | undefined;
  name: string;
  career: string;
  iconImage: ImageToUpdate | undefined;
}

export interface MissionOfficeImage {
  missionOfficeImageId: number;
  image: Images | undefined;
}

export interface MissionOfficeImageToUpdate {
  missionOfficeImageId: number | undefined;
  image: ImageToUpdate | undefined;
}

export interface MissionProduct {
  missionProductId: number;
  name: string;
  overview: string;
  url: string;
  problem: string;
  solution: string;
  memberBenefit: string | undefined;
  image: Images | undefined;
  productTarget: MissionProductTarget | undefined;
  tags: string[];
}

export interface MissionProductToUpdate {
  missionProductId: number | undefined;
  name: string;
  overview: string;
  url: string;
  problem: string;
  solution: string;
  memberBenefit: string;
  image: ImageToUpdate | undefined;
  productTarget: MissionProductTarget | undefined;
  tags: string[];
}

export interface MissionProductTag {
  missionProductId: number;
  displayOrder: number;
  tag: string;
}

export interface MissionProductTarget {
  isForBusiness: boolean;
  isForCustomer: boolean;
}

export interface MissionUsePlan {
  usePlanId: number;
  name: string;
}

export interface Supporter {
  supporterId: string;
  name: string;
  iconImage: Images | undefined;
  coverImage: Images | undefined;
  supportDescription: string;
  strengthPoint: string;
  companyName: string;
  country: Country | undefined;
  japanPrefecture: JapanPrefecture | undefined;
  otherAddress: string;
  memberRange: MemberRange | undefined;
  supportAreas: SupportArea[];
  companyUrl: string;
  companyEstablishedDate: CompanyEstablishedDate | undefined;
  supporterMembers: SupporterMember[];
  galleryImages: GalleryImage[];
  degreeOfAttention: number;
  language: string;
}

export interface SupportArea {
  supportAreaId: number;
  name: string;
  displayOrder: number;
}

export interface SupporterMember {
  supporterMemberId: number;
  name: string;
  career: string;
  iconImage: Images | undefined;
}

export interface SupporterMemberToUpdate {
  supporterMemberId: number | undefined;
  name: string;
  career: string;
  iconImage: ImageToUpdate | undefined;
}

export interface GalleryImage {
  galleryImageId: number;
  image: Images | undefined;
}

export interface CompanyEstablishedDate {
  year: number;
  month: number;
}

export interface GalleryImageToUpdate {
  galleryImageId: number | undefined;
  image: ImageToUpdate | undefined;
}

export interface MissionSummary {
  missionId: string;
  users: UserSummary[];
  companyName: string;
  missionName: string;
  missionDescription: string;
  createdAt: number;
  updatedAt: number;
  iconImageKey: string;
  coverImageKey: string;
  publishedToMission: boolean;
  firstPublishedAt: number;
  lastPublisehdAt: number;
}

export interface UserSummary {
  userId: string;
  email: string;
  activatedAt: number;
}

export interface Country {
  countryId: string;
  name: string;
  phoneCountryCode: string;
}

export interface JapanPrefecture {
  japanPrefectureId: number;
  name: string;
}

export interface MemberRange {
  memberRangeId: number;
  displayText: string;
}

export interface Fund {
  fundId: number;
  displayText: string;
}

export interface FinancingSeries {
  seriesId: number;
  name: string;
}

export interface ChatMessage {
  messageId: number;
  senderAccountId: string;
  sentAt: number;
  message: string;
  unread: boolean;
  file: ChatFile | undefined;
  deleted: boolean;
}

export interface ChatRoom {
  chatRoomId: string;
  updatedAt: number;
  latestMessage: string;
  unreadCount: number;
  chatUser: ChatUser | undefined;
  unpublished: boolean;
  admin: boolean;
}

export interface ChatUser {
  accountId: string;
  name: string;
  iconImageUrl: string;
  affiliation: string;
  uncontactable: boolean;
  mission: ChatUser_Mission | undefined;
  supporter: ChatUser_Supporter | undefined;
  innovator: ChatUser_Innovator | undefined;
}

export interface ChatUser_Mission {
  missionId: string;
  companyName: string;
  unpublished: boolean;
}

export interface ChatUser_Supporter {
  supporterId: string;
  companyName: string;
  unpublished: boolean;
}

export interface ChatUser_Innovator {
  innovatorId: string;
  matterId: string;
  career: ChatUser_CareerProfile | undefined;
  newGraduate: ChatUser_NewGraduateProfile | undefined;
  unpublished: boolean;
  deleted: boolean;
}

export interface ChatUser_CareerProfile {
  companyName: string;
  preferredEmploymentType: EmploymentType | undefined;
  academicBackground: AcademicBackground | undefined;
  lastName: string;
  firstName: string;
}

export interface ChatUser_NewGraduateProfile {
  academicBackground: AcademicBackground | undefined;
  lastName: string;
  firstName: string;
}

export interface ChatFile {
  url: string;
  size: number;
}

export interface InnovatorChatRoom {
  chatRoomId: string;
  updatedAt: number;
  latestMessage: string;
  unreadCount: number;
  chatUser: InnovatorChatUser | undefined;
}

export interface InnovatorChatUser {
  accountId: string;
  iconImageUrl: string;
  mission: InnovatorChatUser_Mission | undefined;
  consultant: InnovatorChatUser_Consultant | undefined;
}

export interface InnovatorChatUser_Mission {
  missionId: string;
  name: string;
  companyName: string;
  unpublished: boolean;
}

export interface InnovatorChatUser_Consultant {
  consultantId: string;
}

export interface ConsultantChatRoom {
  chatRoomId: string;
  updatedAt: number;
  latestMessage: string;
  unreadCount: number;
  chatUser: ConsultantChatUser | undefined;
  handled: boolean;
  staffName: string;
}

export interface ConsultantChatUser {
  accountId: string;
  matterId: string;
  matterPublishId: number;
  career: ConsultantChatUser_CareerProfile | undefined;
  newGraduate: ConsultantChatUser_NewGraduateProfile | undefined;
  status: MatterPublishStatus | undefined;
}

export interface ConsultantChatUser_CareerProfile {
  name: string;
  companyName: string;
}

export interface ConsultantChatUser_NewGraduateProfile {
  name: string;
  academicBackground: ConsultantChatUser_AcademicBackground | undefined;
}

export interface ConsultantChatUser_AcademicBackground {
  school: School | undefined;
  faculty: string;
  yearOfGraduation: number;
  monthOfGraduation: number;
}

export interface InnovatorChatMessage {
  messageId: number;
  senderAccountId: string;
  sentAt: number;
  message: string;
  unread: boolean;
  file: ChatFile | undefined;
  deleted: boolean;
  information: boolean;
}

export interface ConsultantChatMessage {
  messageId: number;
  senderAccountId: string;
  sentAt: number;
  message: string;
  unread: boolean;
  file: ChatFile | undefined;
  deleted: boolean;
}

export interface CorporateAttribute {
  id: number;
  name: string;
  nameShort: string;
}

export interface Feature {
  id: string;
  value: string;
  description: string;
}

export interface ScreeningStatus {
  id: number;
  name: string;
}

export interface ScreeningCompany {
  applicationId: number;
  companyName: string;
  companyUrl: string;
  country: Country | undefined;
  corporateAttribute: CorporateAttribute | undefined;
  fund: Fund | undefined;
  applicantName: string;
  email: string;
  reasonForApplication: string;
  screeningStatus: ScreeningStatus | undefined;
  phoneCountryCode: string;
  phoneNumber: string;
}

export interface Demand {
  id: number;
  demandName: string;
  interestName: string;
}

export interface Matter {
  matterId: string;
  publishedAt: number;
  humanResource: HumanResourceMatter | undefined;
  matterPublishId: number;
  viewed: boolean;
  scouted: boolean;
  accountsInScouted: number;
  chatRoomId: string;
  accountId: string;
  latestMessageSentAt: number;
  favorite: boolean;
}

export interface HumanResourceMatter {
  displayId: string;
  career: CareerProfileForMatter | undefined;
  newGraduate: NewGraduateProfileForMatter | undefined;
  preferredWorkLocations: WorkLocation[];
  preferredOccupations: Occupation[];
  academicBackground: AcademicBackground | undefined;
  englishSkill: EnglishSkill | undefined;
  selfIntroduction: string;
  certifications: string[];
  dttScore: number | undefined;
  dttRank: DttRank | undefined;
  lastName: string;
  firstName: string;
  japanPrefecture: JapanPrefecture | undefined;
  lastNameKana: string;
  firstNameKana: string;
  yearOfBirth: number;
  monthOfBirth: number;
  outputs: string[];
}

export interface CareerProfileForMatter {
  job: CareerProfileForMatter_JobHistory[];
  numberOfJobChanges: NumberOfJobChanges | undefined;
  preferredEmploymentType: EmploymentType | undefined;
  skills: Skill[];
  expectations: Expectation[];
  annualIncome: AnnualIncome | undefined;
  achievement: string;
}

export interface CareerProfileForMatter_JobHistory {
  name: string;
  position: Position | undefined;
  startYear: number;
  startMonth: number;
  endYear: number | undefined;
  endMonth: number | undefined;
  occupations: CareerProfileForMatter_ExperiencedOccupation[];
}

export interface CareerProfileForMatter_ExperiencedOccupation {
  occupation: Occupation | undefined;
  yearsOfExperience: YearsOfExperience | undefined;
}

export interface NewGraduateProfileForMatter {
  researchRooms: ResearchRoom[];
  programmingExperiences: ProgrammingExperience[];
  preferredWorkStyle: PreferredWorkStyle | undefined;
  preferredBusinessTypes: PreferredBusinessType[];
  pastExperienceAchievements: PastExperienceAchievement[];
  internshipExperiences: InternshipExperience[];
  studyAbroadExperiences: StudyAbroadExperience[];
  clubExperiences: ClubExperience[];
}

export interface CareerMattersFilter {
  employmentTypeIds: number[];
  occupationIds: number[];
  skillIds: number[];
  workLocations: WorkLocation[];
  schoolIds: number[];
  yearOfGraduationFrom: number | undefined;
  yearOfGraduationTo: number | undefined;
  excludeLatestMessageWithinDays: number | undefined;
  favorite: boolean | undefined;
}

export interface NewGraduateMattersFilter {
  preferredWorkStyleIds: number[];
  yearOfGraduation: number[];
  schoolIds: number[];
  departmentIds: number[];
  pastExperienceAchievementIds: number[];
  preferredBusinessTypeIds: number[];
  occupationIds: number[];
  workLocations: WorkLocation[];
  dttRankIds: number[];
  excludeLatestMessageWithinDays: number | undefined;
  messageReplied: boolean | undefined;
  favorite: boolean | undefined;
}

export interface ReasonForClosingHumanResourcesMatter {
  id: number;
  name: string;
}

export interface Company {
  id: string;
  name: string;
}

export interface BlockCompany {
  nameLike: string;
}

export interface Location {
  japanPrefecture: JapanPrefecture | undefined;
}

export interface AcademicBackground {
  school: School | undefined;
  faculty: string;
  department: Department | undefined;
  yearOfGraduation: number;
  monthOfGraduation: number;
}

export interface ScoutCompany {
  missionId: string;
  companyName: string;
  scoutContractStatus: ScoutContractStatus | undefined;
  numberOfViewsForUseExplanation: number;
  numberOfSending: number;
  staffName: string;
}

export interface ScoutCompanyBillingAddress {
  lastName: string;
  firstName: string;
  officialPosition: string;
  email: string;
  phoneNumber: string;
  address: string;
}

export interface ScoutBillingStatus {
  id: number;
  name: string;
}

export interface ScoutContractStatus {
  id: number;
  name: string;
}

export interface MessageTemplate {
  id: number;
  name: string;
  template: string;
  updatedAt: number;
}

export interface MissionJobOffer {
  id: string;
  occupation: Occupation | undefined;
  jobDescription: string;
  published: boolean;
}

export interface MissionJobOfferToUpdate {
  occupationId: number;
  jobDescription: string;
  published: boolean;
}

export interface MatterPublishStatus {
  id: number;
  name: string;
}

const baseMissionAccount: object = {
  accountId: "",
  missionId: "",
  missionName: "",
  coverImage: undefined,
  published: false,
};

const baseSupporterAccount: object = {
  accountId: "",
  supporterId: "",
  supporterName: "",
  coverImage: undefined,
  published: false,
};

const baseAdminAccount: object = {
  accountId: "",
  adminId: "",
};

const baseInnovatorAccount: object = {
  accountId: "",
  innovatorId: "",
};

const baseInnovator: object = {
  innovatorId: "",
  innovatorType: "",
  lastName: "",
  firstName: "",
  lastNameKana: "",
  firstNameKana: "",
  yearOfBirth: 0,
  monthOfBirth: 0,
  phoneCountryCode: "",
  phoneNumber: "",
  japanPrefecture: undefined,
  preferredWorkLocations: undefined,
  preferredOccupations: undefined,
  academicBackground: undefined,
  englishSkill: undefined,
  selfIntroduction: "",
  certifications: "",
  outputs: "",
  dttScore: undefined,
  dttRank: undefined,
  dateOfBirth: 0,
  gender: undefined,
};

const baseCareerProfile: object = {
  job: undefined,
  annualIncome: undefined,
  monthlyUnitPrice: undefined,
  numberOfJobChanges: undefined,
  resumeFile: undefined,
  preferredMonthlyUnitPrice: undefined,
  achievement: "",
  preferredEmploymentType: undefined,
  skills: undefined,
  expectations: undefined,
};

const baseCareerProfile_JobHistory: object = {
  name: "",
  position: undefined,
  startYear: 0,
  startMonth: 0,
  endYear: undefined,
  endMonth: undefined,
  occupations: undefined,
};

const baseCareerProfile_ExperiencedOccupation: object = {
  occupation: undefined,
  yearsOfExperience: undefined,
};

const baseNewGraduateProfile: object = {
  researchRooms: undefined,
  programmingExperiences: undefined,
  preferredWorkStyle: undefined,
  preferredBusinessTypes: undefined,
  pastExperienceAchievements: undefined,
  internshipExperiences: undefined,
  studyAbroadExperiences: undefined,
  clubExperiences: undefined,
};

const baseResearchRoom: object = {
  name: "",
  content: "",
};

const baseDttRank: object = {
  id: 0,
  name: "",
};

const baseGender: object = {
  id: 0,
  name: "",
  nameEn: "",
};

const baseProgrammingExperience: object = {
  languageName: "",
  level: undefined,
};

const baseProgrammingExperienceLevel: object = {
  id: 0,
  name: "",
};

const basePreferredWorkStyle: object = {
  id: 0,
  name: "",
};

const basePreferredBusinessType: object = {
  id: 0,
  name: "",
};

const basePastExperienceAchievement: object = {
  id: 0,
  name: "",
};

const baseInternshipExperience: object = {
  companyName: "",
  startYear: undefined,
  startMonth: undefined,
  endYear: undefined,
  endMonth: undefined,
};

const baseStudyAbroadExperience: object = {
  countryName: "",
  startYear: undefined,
  startMonth: undefined,
  endYear: undefined,
  endMonth: undefined,
};

const baseClubExperience: object = {
  name: "",
  content: "",
};

const baseWorkLocation: object = {
  japanPrefecture: undefined,
};

const baseSchool: object = {
  id: 0,
  name: "",
};

const baseDepartment: object = {
  id: 0,
  name: "",
};

const baseEmploymentType: object = {
  id: 0,
  name: "",
};

const baseEnglishSkill: object = {
  id: 0,
  name: "",
};

const baseNumberOfJobChanges: object = {
  id: 0,
  name: "",
};

const baseAnnualIncome: object = {
  id: 0,
  name: "",
};

const basePreferredMonthlyUnitPrice: object = {
  id: 0,
  name: "",
};

const baseOccupation: object = {
  id: 0,
  name: "",
};

const baseYearsOfExperience: object = {
  id: 0,
  name: "",
};

const basePosition: object = {
  id: 0,
  name: "",
};

const baseSkill: object = {
  id: 0,
  name: "",
};

const baseExpectation: object = {
  id: 0,
  name: "",
};

const baseSelectionStep: object = {
  id: 0,
  name: "",
};

const baseResumeFile: object = {
  url: "",
  name: "",
  size: 0,
  key: "",
};

const baseMissionFollower: object = {
  accountId: "",
  mission: undefined,
  followedAt: 0,
  interests: undefined,
  favorite: false,
  chatRoomId: "",
  messageLastSentAt: 0,
  isFollowing: false,
};

const baseMissionFollow: object = {
  followeeAccountId: "",
  mission: undefined,
  followedAt: 0,
  interests: undefined,
  isFollowed: false,
  chatRoomId: "",
};

const baseAccountFollowee: object = {
  followeeAccountId: "",
  followedAt: 0,
  isFollowed: false,
  chatRoomId: "",
};

const baseAccountFollowee_MissionAccountFollowee: object = {
  mission: undefined,
  interests: undefined,
  supportAreas: undefined,
  contactMessage: "",
};

const baseAccountFollowee_SupporterAccountFollowee: object = {
  supporter: undefined,
  supportAreas: undefined,
  contactMessage: "",
};

const baseAccountFollower: object = {
  followerAccountId: "",
  followedAt: 0,
  isFollowing: false,
  chatRoomId: "",
};

const baseAccountFollower_MissionAccountFollower: object = {
  mission: undefined,
  interests: undefined,
  supportAreas: undefined,
};

const baseAccountFollower_SupporterAccountFollower: object = {
  supporter: undefined,
  supportAreas: undefined,
  contactMessage: "",
};

const baseInterest: object = {
  id: 0,
  name: "",
};

const baseLanguage: object = {
  id: 0,
  name: "",
};

const baseDegree: object = {
  id: 0,
  name: "",
};

const baseImages: object = {
  original: undefined,
  small: undefined,
  large: undefined,
};

const baseImages_Image: object = {
  url: "",
  width: 0,
  height: 0,
};

const baseImageToUpdate: object = {
  data: "",
  delete: false,
  key: "",
};

const baseMission: object = {
  missionId: "",
  name: "",
  iconImage: undefined,
  coverImage: undefined,
  missionDescription: "",
  strengthPoint: "",
  companyName: "",
  country: undefined,
  japanPrefecture: undefined,
  otherAddress: "",
  memberRange: undefined,
  fund: undefined,
  missionCategories: undefined,
  missionMembers: undefined,
  missionDemand: undefined,
  matchPercentage: 0,
  companyUrl: "",
  degreeOfAttention: 0,
  missionOfficeImages: undefined,
  missionProducts: undefined,
  corporateAttributes: undefined,
  companyEstablishedDate: undefined,
  language: "",
  missionJobOffers: undefined,
};

const baseMissionDemand: object = {
  collaboration: undefined,
  invest: undefined,
  fundraising: undefined,
  serviceInformation: undefined,
  serviceSuggestion: undefined,
  transferOfBusiness: undefined,
  transferredOfBusiness: undefined,
  acceptSecondment: undefined,
  sendEmployee: undefined,
  furnishedOfficeRentalAvailability: undefined,
  furnishedOfficeRentalRequest: undefined,
};

const baseMissionDemandCollaboration: object = {
  assets: undefined,
};

const baseMissionDemandCollaborationAsset: object = {
  give: undefined,
  want: undefined,
};

const baseMissionDemandInvest: object = {
  financingSeries: undefined,
};

const baseMissionDemandFundraising: object = {
  financingSeries: undefined,
};

const baseMissionDemandServiceInformation: object = {
  issues: "",
};

const baseMissionDemandServiceSuggestion: object = {
};

const baseMissionDemandTransferOfBusiness: object = {
};

const baseMissionDemandTransferredOfBusiness: object = {
  fund: undefined,
};

const baseMissionDemandAcceptSecondment: object = {
  work: undefined,
};

const baseMissionDemandSendEmployee: object = {
  work: undefined,
};

const baseMissionDemandFurnishedOfficeRentalAvailability: object = {
};

const baseMissionDemandFurnishedOfficeRentalRequest: object = {
};

const baseMissionDemandToUpdate: object = {
  collaboration: undefined,
  invest: undefined,
  fundraising: undefined,
  serviceInformation: undefined,
  serviceSuggestion: undefined,
  transferOfBusiness: undefined,
  transferredOfBusiness: undefined,
  acceptSecondment: undefined,
  sendEmployee: undefined,
  furnishedOfficeRentalAvailability: undefined,
  furnishedOfficeRentalRequest: undefined,
};

const baseMissionDemandCollaborationToUpdate: object = {
  assets: undefined,
};

const baseMissionDemandInvestToUpdate: object = {
  financingSeriesId: 0,
};

const baseMissionDemandFundraisingToUpdate: object = {
  financingSeriesId: 0,
};

const baseMissionDemandServiceInformationToUpdate: object = {
  issues: "",
};

const baseMissionDemandServiceSuggestionToUpdate: object = {
};

const baseMissionDemandTransferOfBusinessToUpdate: object = {
};

const baseMissionDemandTransferredOfBusinessToUpdate: object = {
  fundId: 0,
};

const baseMissionDemandAcceptSecondmentToUpdate: object = {
  work: undefined,
};

const baseMissionDemandSendEmployeeToUpdate: object = {
  work: undefined,
};

const baseMissionDemandFurnishedOfficeRentalAvailabilityToUpdate: object = {
};

const baseMissionDemandFurnishedOfficeRentalRequestToUpdate: object = {
};

const baseMissionCategory: object = {
  categoryId: 0,
  name: "",
  backgroundColor: "",
  inactiveTextColor: "",
  inactiveBackgroundColor: "",
  displayOrder: 0,
};

const baseMissionMember: object = {
  missionMemberId: 0,
  name: "",
  career: "",
  iconImage: undefined,
};

const baseMissionMemberToUpdate: object = {
  missionMemberId: undefined,
  name: "",
  career: "",
  iconImage: undefined,
};

const baseMissionOfficeImage: object = {
  missionOfficeImageId: 0,
  image: undefined,
};

const baseMissionOfficeImageToUpdate: object = {
  missionOfficeImageId: undefined,
  image: undefined,
};

const baseMissionProduct: object = {
  missionProductId: 0,
  name: "",
  overview: "",
  url: "",
  problem: "",
  solution: "",
  memberBenefit: undefined,
  image: undefined,
  productTarget: undefined,
  tags: "",
};

const baseMissionProductToUpdate: object = {
  missionProductId: undefined,
  name: "",
  overview: "",
  url: "",
  problem: "",
  solution: "",
  memberBenefit: "",
  image: undefined,
  productTarget: undefined,
  tags: "",
};

const baseMissionProductTag: object = {
  missionProductId: 0,
  displayOrder: 0,
  tag: "",
};

const baseMissionProductTarget: object = {
  isForBusiness: false,
  isForCustomer: false,
};

const baseMissionUsePlan: object = {
  usePlanId: 0,
  name: "",
};

const baseSupporter: object = {
  supporterId: "",
  name: "",
  iconImage: undefined,
  coverImage: undefined,
  supportDescription: "",
  strengthPoint: "",
  companyName: "",
  country: undefined,
  japanPrefecture: undefined,
  otherAddress: "",
  memberRange: undefined,
  supportAreas: undefined,
  companyUrl: "",
  companyEstablishedDate: undefined,
  supporterMembers: undefined,
  galleryImages: undefined,
  degreeOfAttention: 0,
  language: "",
};

const baseSupportArea: object = {
  supportAreaId: 0,
  name: "",
  displayOrder: 0,
};

const baseSupporterMember: object = {
  supporterMemberId: 0,
  name: "",
  career: "",
  iconImage: undefined,
};

const baseSupporterMemberToUpdate: object = {
  supporterMemberId: undefined,
  name: "",
  career: "",
  iconImage: undefined,
};

const baseGalleryImage: object = {
  galleryImageId: 0,
  image: undefined,
};

const baseCompanyEstablishedDate: object = {
  year: 0,
  month: 0,
};

const baseGalleryImageToUpdate: object = {
  galleryImageId: undefined,
  image: undefined,
};

const baseMissionSummary: object = {
  missionId: "",
  users: undefined,
  companyName: "",
  missionName: "",
  missionDescription: "",
  createdAt: 0,
  updatedAt: 0,
  iconImageKey: "",
  coverImageKey: "",
  publishedToMission: false,
  firstPublishedAt: 0,
  lastPublisehdAt: 0,
};

const baseUserSummary: object = {
  userId: "",
  email: "",
  activatedAt: 0,
};

const baseCountry: object = {
  countryId: "",
  name: "",
  phoneCountryCode: "",
};

const baseJapanPrefecture: object = {
  japanPrefectureId: 0,
  name: "",
};

const baseMemberRange: object = {
  memberRangeId: 0,
  displayText: "",
};

const baseFund: object = {
  fundId: 0,
  displayText: "",
};

const baseFinancingSeries: object = {
  seriesId: 0,
  name: "",
};

const baseChatMessage: object = {
  messageId: 0,
  senderAccountId: "",
  sentAt: 0,
  message: "",
  unread: false,
  file: undefined,
  deleted: false,
};

const baseChatRoom: object = {
  chatRoomId: "",
  updatedAt: 0,
  latestMessage: "",
  unreadCount: 0,
  chatUser: undefined,
  unpublished: false,
  admin: false,
};

const baseChatUser: object = {
  accountId: "",
  name: "",
  iconImageUrl: "",
  affiliation: "",
  uncontactable: false,
  mission: undefined,
  supporter: undefined,
  innovator: undefined,
};

const baseChatUser_Mission: object = {
  missionId: "",
  companyName: "",
  unpublished: false,
};

const baseChatUser_Supporter: object = {
  supporterId: "",
  companyName: "",
  unpublished: false,
};

const baseChatUser_Innovator: object = {
  innovatorId: "",
  matterId: "",
  unpublished: false,
  deleted: false,
};

const baseChatUser_CareerProfile: object = {
  companyName: "",
  preferredEmploymentType: undefined,
  academicBackground: undefined,
  lastName: "",
  firstName: "",
};

const baseChatUser_NewGraduateProfile: object = {
  academicBackground: undefined,
  lastName: "",
  firstName: "",
};

const baseChatFile: object = {
  url: "",
  size: 0,
};

const baseInnovatorChatRoom: object = {
  chatRoomId: "",
  updatedAt: 0,
  latestMessage: "",
  unreadCount: 0,
  chatUser: undefined,
};

const baseInnovatorChatUser: object = {
  accountId: "",
  iconImageUrl: "",
  mission: undefined,
  consultant: undefined,
};

const baseInnovatorChatUser_Mission: object = {
  missionId: "",
  name: "",
  companyName: "",
  unpublished: false,
};

const baseInnovatorChatUser_Consultant: object = {
  consultantId: "",
};

const baseConsultantChatRoom: object = {
  chatRoomId: "",
  updatedAt: 0,
  latestMessage: "",
  unreadCount: 0,
  chatUser: undefined,
  handled: false,
  staffName: "",
};

const baseConsultantChatUser: object = {
  accountId: "",
  matterId: "",
  matterPublishId: 0,
  status: undefined,
};

const baseConsultantChatUser_CareerProfile: object = {
  name: "",
  companyName: "",
};

const baseConsultantChatUser_NewGraduateProfile: object = {
  name: "",
  academicBackground: undefined,
};

const baseConsultantChatUser_AcademicBackground: object = {
  school: undefined,
  faculty: "",
  yearOfGraduation: 0,
  monthOfGraduation: 0,
};

const baseInnovatorChatMessage: object = {
  messageId: 0,
  senderAccountId: "",
  sentAt: 0,
  message: "",
  unread: false,
  file: undefined,
  deleted: false,
  information: false,
};

const baseConsultantChatMessage: object = {
  messageId: 0,
  senderAccountId: "",
  sentAt: 0,
  message: "",
  unread: false,
  file: undefined,
  deleted: false,
};

const baseCorporateAttribute: object = {
  id: 0,
  name: "",
  nameShort: "",
};

const baseFeature: object = {
  id: "",
  value: "",
  description: "",
};

const baseScreeningStatus: object = {
  id: 0,
  name: "",
};

const baseScreeningCompany: object = {
  applicationId: 0,
  companyName: "",
  companyUrl: "",
  country: undefined,
  corporateAttribute: undefined,
  fund: undefined,
  applicantName: "",
  email: "",
  reasonForApplication: "",
  screeningStatus: undefined,
  phoneCountryCode: "",
  phoneNumber: "",
};

const baseDemand: object = {
  id: 0,
  demandName: "",
  interestName: "",
};

const baseMatter: object = {
  matterId: "",
  publishedAt: 0,
  matterPublishId: 0,
  viewed: false,
  scouted: false,
  accountsInScouted: 0,
  chatRoomId: "",
  accountId: "",
  latestMessageSentAt: 0,
  favorite: false,
};

const baseHumanResourceMatter: object = {
  displayId: "",
  preferredWorkLocations: undefined,
  preferredOccupations: undefined,
  academicBackground: undefined,
  englishSkill: undefined,
  selfIntroduction: "",
  certifications: "",
  dttScore: undefined,
  dttRank: undefined,
  lastName: "",
  firstName: "",
  japanPrefecture: undefined,
  lastNameKana: "",
  firstNameKana: "",
  yearOfBirth: 0,
  monthOfBirth: 0,
  outputs: "",
};

const baseCareerProfileForMatter: object = {
  job: undefined,
  numberOfJobChanges: undefined,
  preferredEmploymentType: undefined,
  skills: undefined,
  expectations: undefined,
  annualIncome: undefined,
  achievement: "",
};

const baseCareerProfileForMatter_JobHistory: object = {
  name: "",
  position: undefined,
  startYear: 0,
  startMonth: 0,
  endYear: undefined,
  endMonth: undefined,
  occupations: undefined,
};

const baseCareerProfileForMatter_ExperiencedOccupation: object = {
  occupation: undefined,
  yearsOfExperience: undefined,
};

const baseNewGraduateProfileForMatter: object = {
  researchRooms: undefined,
  programmingExperiences: undefined,
  preferredWorkStyle: undefined,
  preferredBusinessTypes: undefined,
  pastExperienceAchievements: undefined,
  internshipExperiences: undefined,
  studyAbroadExperiences: undefined,
  clubExperiences: undefined,
};

const baseCareerMattersFilter: object = {
  employmentTypeIds: 0,
  occupationIds: 0,
  skillIds: 0,
  workLocations: undefined,
  schoolIds: 0,
  yearOfGraduationFrom: undefined,
  yearOfGraduationTo: undefined,
  excludeLatestMessageWithinDays: undefined,
  favorite: undefined,
};

const baseNewGraduateMattersFilter: object = {
  preferredWorkStyleIds: 0,
  yearOfGraduation: 0,
  schoolIds: 0,
  departmentIds: 0,
  pastExperienceAchievementIds: 0,
  preferredBusinessTypeIds: 0,
  occupationIds: 0,
  workLocations: undefined,
  dttRankIds: 0,
  excludeLatestMessageWithinDays: undefined,
  messageReplied: undefined,
  favorite: undefined,
};

const baseReasonForClosingHumanResourcesMatter: object = {
  id: 0,
  name: "",
};

const baseCompany: object = {
  id: "",
  name: "",
};

const baseBlockCompany: object = {
  nameLike: "",
};

const baseLocation: object = {
  japanPrefecture: undefined,
};

const baseAcademicBackground: object = {
  school: undefined,
  faculty: "",
  department: undefined,
  yearOfGraduation: 0,
  monthOfGraduation: 0,
};

const baseScoutCompany: object = {
  missionId: "",
  companyName: "",
  scoutContractStatus: undefined,
  numberOfViewsForUseExplanation: 0,
  numberOfSending: 0,
  staffName: "",
};

const baseScoutCompanyBillingAddress: object = {
  lastName: "",
  firstName: "",
  officialPosition: "",
  email: "",
  phoneNumber: "",
  address: "",
};

const baseScoutBillingStatus: object = {
  id: 0,
  name: "",
};

const baseScoutContractStatus: object = {
  id: 0,
  name: "",
};

const baseMessageTemplate: object = {
  id: 0,
  name: "",
  template: "",
  updatedAt: 0,
};

const baseMissionJobOffer: object = {
  id: "",
  occupation: undefined,
  jobDescription: "",
  published: false,
};

const baseMissionJobOfferToUpdate: object = {
  occupationId: 0,
  jobDescription: "",
  published: false,
};

const baseMatterPublishStatus: object = {
  id: 0,
  name: "",
};

function longToNumber(long: Long) {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new global.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

export const MissionAccount = {
  encode(message: MissionAccount, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.accountId);
    writer.uint32(18).string(message.missionId);
    writer.uint32(26).string(message.missionName);
    if (message.coverImage !== undefined && message.coverImage !== undefined) {
      Images.encode(message.coverImage, writer.uint32(34).fork()).ldelim();
    }
    writer.uint32(40).bool(message.published);
    return writer;
  },
  decode(reader: Reader, length?: number): MissionAccount {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseMissionAccount) as MissionAccount;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.accountId = reader.string();
          break;
        case 2:
          message.missionId = reader.string();
          break;
        case 3:
          message.missionName = reader.string();
          break;
        case 4:
          message.coverImage = Images.decode(reader, reader.uint32());
          break;
        case 5:
          message.published = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): MissionAccount {
    const message = Object.create(baseMissionAccount) as MissionAccount;
    if (object.accountId) {
      message.accountId = String(object.accountId);
    }
    if (object.missionId) {
      message.missionId = String(object.missionId);
    }
    if (object.missionName) {
      message.missionName = String(object.missionName);
    }
    if (object.coverImage) {
      message.coverImage = Images.fromJSON(object.coverImage);
    }
    if (object.published) {
      message.published = Boolean(object.published);
    }
    return message;
  },
  fromPartial(object: DeepPartial<MissionAccount>): MissionAccount {
    const message = Object.create(baseMissionAccount) as MissionAccount;
    if (object.accountId) {
      message.accountId = object.accountId;
    }
    if (object.missionId) {
      message.missionId = object.missionId;
    }
    if (object.missionName) {
      message.missionName = object.missionName;
    }
    if (object.coverImage) {
      message.coverImage = Images.fromPartial(object.coverImage);
    }
    if (object.published) {
      message.published = object.published;
    }
    return message;
  },
  toJSON(message: MissionAccount): unknown {
    const obj: any = {};
    obj.accountId = message.accountId || "";
    obj.missionId = message.missionId || "";
    obj.missionName = message.missionName || "";
    obj.coverImage = message.coverImage ? Images.toJSON(message.coverImage) : undefined;
    obj.published = message.published || false;
    return obj;
  },
};

export const SupporterAccount = {
  encode(message: SupporterAccount, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.accountId);
    writer.uint32(18).string(message.supporterId);
    writer.uint32(26).string(message.supporterName);
    if (message.coverImage !== undefined && message.coverImage !== undefined) {
      Images.encode(message.coverImage, writer.uint32(34).fork()).ldelim();
    }
    writer.uint32(40).bool(message.published);
    return writer;
  },
  decode(reader: Reader, length?: number): SupporterAccount {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseSupporterAccount) as SupporterAccount;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.accountId = reader.string();
          break;
        case 2:
          message.supporterId = reader.string();
          break;
        case 3:
          message.supporterName = reader.string();
          break;
        case 4:
          message.coverImage = Images.decode(reader, reader.uint32());
          break;
        case 5:
          message.published = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): SupporterAccount {
    const message = Object.create(baseSupporterAccount) as SupporterAccount;
    if (object.accountId) {
      message.accountId = String(object.accountId);
    }
    if (object.supporterId) {
      message.supporterId = String(object.supporterId);
    }
    if (object.supporterName) {
      message.supporterName = String(object.supporterName);
    }
    if (object.coverImage) {
      message.coverImage = Images.fromJSON(object.coverImage);
    }
    if (object.published) {
      message.published = Boolean(object.published);
    }
    return message;
  },
  fromPartial(object: DeepPartial<SupporterAccount>): SupporterAccount {
    const message = Object.create(baseSupporterAccount) as SupporterAccount;
    if (object.accountId) {
      message.accountId = object.accountId;
    }
    if (object.supporterId) {
      message.supporterId = object.supporterId;
    }
    if (object.supporterName) {
      message.supporterName = object.supporterName;
    }
    if (object.coverImage) {
      message.coverImage = Images.fromPartial(object.coverImage);
    }
    if (object.published) {
      message.published = object.published;
    }
    return message;
  },
  toJSON(message: SupporterAccount): unknown {
    const obj: any = {};
    obj.accountId = message.accountId || "";
    obj.supporterId = message.supporterId || "";
    obj.supporterName = message.supporterName || "";
    obj.coverImage = message.coverImage ? Images.toJSON(message.coverImage) : undefined;
    obj.published = message.published || false;
    return obj;
  },
};

export const AdminAccount = {
  encode(message: AdminAccount, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.accountId);
    writer.uint32(18).string(message.adminId);
    return writer;
  },
  decode(reader: Reader, length?: number): AdminAccount {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseAdminAccount) as AdminAccount;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.accountId = reader.string();
          break;
        case 2:
          message.adminId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): AdminAccount {
    const message = Object.create(baseAdminAccount) as AdminAccount;
    if (object.accountId) {
      message.accountId = String(object.accountId);
    }
    if (object.adminId) {
      message.adminId = String(object.adminId);
    }
    return message;
  },
  fromPartial(object: DeepPartial<AdminAccount>): AdminAccount {
    const message = Object.create(baseAdminAccount) as AdminAccount;
    if (object.accountId) {
      message.accountId = object.accountId;
    }
    if (object.adminId) {
      message.adminId = object.adminId;
    }
    return message;
  },
  toJSON(message: AdminAccount): unknown {
    const obj: any = {};
    obj.accountId = message.accountId || "";
    obj.adminId = message.adminId || "";
    return obj;
  },
};

export const InnovatorAccount = {
  encode(message: InnovatorAccount, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.accountId);
    writer.uint32(18).string(message.innovatorId);
    return writer;
  },
  decode(reader: Reader, length?: number): InnovatorAccount {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseInnovatorAccount) as InnovatorAccount;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.accountId = reader.string();
          break;
        case 2:
          message.innovatorId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): InnovatorAccount {
    const message = Object.create(baseInnovatorAccount) as InnovatorAccount;
    if (object.accountId) {
      message.accountId = String(object.accountId);
    }
    if (object.innovatorId) {
      message.innovatorId = String(object.innovatorId);
    }
    return message;
  },
  fromPartial(object: DeepPartial<InnovatorAccount>): InnovatorAccount {
    const message = Object.create(baseInnovatorAccount) as InnovatorAccount;
    if (object.accountId) {
      message.accountId = object.accountId;
    }
    if (object.innovatorId) {
      message.innovatorId = object.innovatorId;
    }
    return message;
  },
  toJSON(message: InnovatorAccount): unknown {
    const obj: any = {};
    obj.accountId = message.accountId || "";
    obj.innovatorId = message.innovatorId || "";
    return obj;
  },
};

export const Innovator = {
  encode(message: Innovator, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.innovatorId);
    writer.uint32(18).string(message.innovatorType);
    writer.uint32(26).string(message.lastName);
    writer.uint32(34).string(message.firstName);
    writer.uint32(42).string(message.lastNameKana);
    writer.uint32(50).string(message.firstNameKana);
    writer.uint32(56).uint32(message.yearOfBirth);
    writer.uint32(64).uint32(message.monthOfBirth);
    writer.uint32(74).string(message.phoneCountryCode);
    writer.uint32(82).string(message.phoneNumber);
    if (message.japanPrefecture !== undefined && message.japanPrefecture !== undefined) {
      JapanPrefecture.encode(message.japanPrefecture, writer.uint32(90).fork()).ldelim();
    }
    if (message.career !== undefined && message.career !== undefined) {
      CareerProfile.encode(message.career, writer.uint32(98).fork()).ldelim();
    }
    if (message.newGraduate !== undefined && message.newGraduate !== undefined) {
      NewGraduateProfile.encode(message.newGraduate, writer.uint32(106).fork()).ldelim();
    }
    for (const v of message.preferredWorkLocations) {
      WorkLocation.encode(v!, writer.uint32(122).fork()).ldelim();
    }
    for (const v of message.preferredOccupations) {
      Occupation.encode(v!, writer.uint32(130).fork()).ldelim();
    }
    if (message.academicBackground !== undefined && message.academicBackground !== undefined) {
      AcademicBackground.encode(message.academicBackground, writer.uint32(146).fork()).ldelim();
    }
    if (message.englishSkill !== undefined && message.englishSkill !== undefined) {
      EnglishSkill.encode(message.englishSkill, writer.uint32(162).fork()).ldelim();
    }
    writer.uint32(178).string(message.selfIntroduction);
    for (const v of message.certifications) {
      writer.uint32(186).string(v!);
    }
    for (const v of message.outputs) {
      writer.uint32(194).string(v!);
    }
    if (message.dttScore !== undefined && message.dttScore !== undefined) {
      Int32Value.encode({ value: message.dttScore! }, writer.uint32(202).fork()).ldelim();
    }
    if (message.dttRank !== undefined && message.dttRank !== undefined) {
      DttRank.encode(message.dttRank, writer.uint32(210).fork()).ldelim();
    }
    writer.uint32(216).int64(message.dateOfBirth);
    if (message.gender !== undefined && message.gender !== undefined) {
      Gender.encode(message.gender, writer.uint32(226).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): Innovator {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseInnovator) as Innovator;
    message.preferredWorkLocations = [];
    message.preferredOccupations = [];
    message.certifications = [];
    message.outputs = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.innovatorId = reader.string();
          break;
        case 2:
          message.innovatorType = reader.string();
          break;
        case 3:
          message.lastName = reader.string();
          break;
        case 4:
          message.firstName = reader.string();
          break;
        case 5:
          message.lastNameKana = reader.string();
          break;
        case 6:
          message.firstNameKana = reader.string();
          break;
        case 7:
          message.yearOfBirth = reader.uint32();
          break;
        case 8:
          message.monthOfBirth = reader.uint32();
          break;
        case 9:
          message.phoneCountryCode = reader.string();
          break;
        case 10:
          message.phoneNumber = reader.string();
          break;
        case 11:
          message.japanPrefecture = JapanPrefecture.decode(reader, reader.uint32());
          break;
        case 12:
          message.career = CareerProfile.decode(reader, reader.uint32());
          break;
        case 13:
          message.newGraduate = NewGraduateProfile.decode(reader, reader.uint32());
          break;
        case 15:
          message.preferredWorkLocations.push(WorkLocation.decode(reader, reader.uint32()));
          break;
        case 16:
          message.preferredOccupations.push(Occupation.decode(reader, reader.uint32()));
          break;
        case 18:
          message.academicBackground = AcademicBackground.decode(reader, reader.uint32());
          break;
        case 20:
          message.englishSkill = EnglishSkill.decode(reader, reader.uint32());
          break;
        case 22:
          message.selfIntroduction = reader.string();
          break;
        case 23:
          message.certifications.push(reader.string());
          break;
        case 24:
          message.outputs.push(reader.string());
          break;
        case 25:
          message.dttScore = Int32Value.decode(reader, reader.uint32()).value;
          break;
        case 26:
          message.dttRank = DttRank.decode(reader, reader.uint32());
          break;
        case 27:
          message.dateOfBirth = longToNumber(reader.int64() as Long);
          break;
        case 28:
          message.gender = Gender.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): Innovator {
    const message = Object.create(baseInnovator) as Innovator;
    message.preferredWorkLocations = [];
    message.preferredOccupations = [];
    message.certifications = [];
    message.outputs = [];
    if (object.innovatorId) {
      message.innovatorId = String(object.innovatorId);
    }
    if (object.innovatorType) {
      message.innovatorType = String(object.innovatorType);
    }
    if (object.lastName) {
      message.lastName = String(object.lastName);
    }
    if (object.firstName) {
      message.firstName = String(object.firstName);
    }
    if (object.lastNameKana) {
      message.lastNameKana = String(object.lastNameKana);
    }
    if (object.firstNameKana) {
      message.firstNameKana = String(object.firstNameKana);
    }
    if (object.yearOfBirth) {
      message.yearOfBirth = Number(object.yearOfBirth);
    }
    if (object.monthOfBirth) {
      message.monthOfBirth = Number(object.monthOfBirth);
    }
    if (object.phoneCountryCode) {
      message.phoneCountryCode = String(object.phoneCountryCode);
    }
    if (object.phoneNumber) {
      message.phoneNumber = String(object.phoneNumber);
    }
    if (object.japanPrefecture) {
      message.japanPrefecture = JapanPrefecture.fromJSON(object.japanPrefecture);
    }
    if (object.career) {
      message.career = CareerProfile.fromJSON(object.career);
    }
    if (object.newGraduate) {
      message.newGraduate = NewGraduateProfile.fromJSON(object.newGraduate);
    }
    if (object.preferredWorkLocations) {
      for (const e of object.preferredWorkLocations) {
        message.preferredWorkLocations.push(WorkLocation.fromJSON(e));
      }
    }
    if (object.preferredOccupations) {
      for (const e of object.preferredOccupations) {
        message.preferredOccupations.push(Occupation.fromJSON(e));
      }
    }
    if (object.academicBackground) {
      message.academicBackground = AcademicBackground.fromJSON(object.academicBackground);
    }
    if (object.englishSkill) {
      message.englishSkill = EnglishSkill.fromJSON(object.englishSkill);
    }
    if (object.selfIntroduction) {
      message.selfIntroduction = String(object.selfIntroduction);
    }
    if (object.certifications) {
      for (const e of object.certifications) {
        message.certifications.push(String(e));
      }
    }
    if (object.outputs) {
      for (const e of object.outputs) {
        message.outputs.push(String(e));
      }
    }
    if (object.dttScore) {
      message.dttScore = Number(object.dttScore);
    }
    if (object.dttRank) {
      message.dttRank = DttRank.fromJSON(object.dttRank);
    }
    if (object.dateOfBirth) {
      message.dateOfBirth = Number(object.dateOfBirth);
    }
    if (object.gender) {
      message.gender = Gender.fromJSON(object.gender);
    }
    return message;
  },
  fromPartial(object: DeepPartial<Innovator>): Innovator {
    const message = Object.create(baseInnovator) as Innovator;
    message.preferredWorkLocations = [];
    message.preferredOccupations = [];
    message.certifications = [];
    message.outputs = [];
    if (object.innovatorId) {
      message.innovatorId = object.innovatorId;
    }
    if (object.innovatorType) {
      message.innovatorType = object.innovatorType;
    }
    if (object.lastName) {
      message.lastName = object.lastName;
    }
    if (object.firstName) {
      message.firstName = object.firstName;
    }
    if (object.lastNameKana) {
      message.lastNameKana = object.lastNameKana;
    }
    if (object.firstNameKana) {
      message.firstNameKana = object.firstNameKana;
    }
    if (object.yearOfBirth) {
      message.yearOfBirth = object.yearOfBirth;
    }
    if (object.monthOfBirth) {
      message.monthOfBirth = object.monthOfBirth;
    }
    if (object.phoneCountryCode) {
      message.phoneCountryCode = object.phoneCountryCode;
    }
    if (object.phoneNumber) {
      message.phoneNumber = object.phoneNumber;
    }
    if (object.japanPrefecture) {
      message.japanPrefecture = JapanPrefecture.fromPartial(object.japanPrefecture);
    }
    if (object.career) {
      message.career = CareerProfile.fromPartial(object.career);
    }
    if (object.newGraduate) {
      message.newGraduate = NewGraduateProfile.fromPartial(object.newGraduate);
    }
    if (object.preferredWorkLocations) {
      for (const e of object.preferredWorkLocations) {
        message.preferredWorkLocations.push(WorkLocation.fromPartial(e));
      }
    }
    if (object.preferredOccupations) {
      for (const e of object.preferredOccupations) {
        message.preferredOccupations.push(Occupation.fromPartial(e));
      }
    }
    if (object.academicBackground) {
      message.academicBackground = AcademicBackground.fromPartial(object.academicBackground);
    }
    if (object.englishSkill) {
      message.englishSkill = EnglishSkill.fromPartial(object.englishSkill);
    }
    if (object.selfIntroduction) {
      message.selfIntroduction = object.selfIntroduction;
    }
    if (object.certifications) {
      for (const e of object.certifications) {
        message.certifications.push(e);
      }
    }
    if (object.outputs) {
      for (const e of object.outputs) {
        message.outputs.push(e);
      }
    }
    if (object.dttScore) {
      message.dttScore = object.dttScore;
    }
    if (object.dttRank) {
      message.dttRank = DttRank.fromPartial(object.dttRank);
    }
    if (object.dateOfBirth) {
      message.dateOfBirth = object.dateOfBirth;
    }
    if (object.gender) {
      message.gender = Gender.fromPartial(object.gender);
    }
    return message;
  },
  toJSON(message: Innovator): unknown {
    const obj: any = {};
    obj.innovatorId = message.innovatorId || "";
    obj.innovatorType = message.innovatorType || "";
    obj.lastName = message.lastName || "";
    obj.firstName = message.firstName || "";
    obj.lastNameKana = message.lastNameKana || "";
    obj.firstNameKana = message.firstNameKana || "";
    obj.yearOfBirth = message.yearOfBirth || 0;
    obj.monthOfBirth = message.monthOfBirth || 0;
    obj.phoneCountryCode = message.phoneCountryCode || "";
    obj.phoneNumber = message.phoneNumber || "";
    obj.japanPrefecture = message.japanPrefecture ? JapanPrefecture.toJSON(message.japanPrefecture) : undefined;
    obj.career = message.career ? CareerProfile.toJSON(message.career) : undefined;
    obj.newGraduate = message.newGraduate ? NewGraduateProfile.toJSON(message.newGraduate) : undefined;
    if (message.preferredWorkLocations) {
      obj.preferredWorkLocations = message.preferredWorkLocations.map(e => e ? WorkLocation.toJSON(e) : undefined);
    } else {
      obj.preferredWorkLocations = [];
    }
    if (message.preferredOccupations) {
      obj.preferredOccupations = message.preferredOccupations.map(e => e ? Occupation.toJSON(e) : undefined);
    } else {
      obj.preferredOccupations = [];
    }
    obj.academicBackground = message.academicBackground ? AcademicBackground.toJSON(message.academicBackground) : undefined;
    obj.englishSkill = message.englishSkill ? EnglishSkill.toJSON(message.englishSkill) : undefined;
    obj.selfIntroduction = message.selfIntroduction || "";
    if (message.certifications) {
      obj.certifications = message.certifications.map(e => e || "");
    } else {
      obj.certifications = [];
    }
    if (message.outputs) {
      obj.outputs = message.outputs.map(e => e || "");
    } else {
      obj.outputs = [];
    }
    obj.dttScore = message.dttScore || undefined;
    obj.dttRank = message.dttRank ? DttRank.toJSON(message.dttRank) : undefined;
    obj.dateOfBirth = message.dateOfBirth || 0;
    obj.gender = message.gender ? Gender.toJSON(message.gender) : undefined;
    return obj;
  },
};

export const CareerProfile = {
  encode(message: CareerProfile, writer: Writer = Writer.create()): Writer {
    for (const v of message.job) {
      CareerProfile_JobHistory.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.annualIncome !== undefined && message.annualIncome !== undefined) {
      AnnualIncome.encode(message.annualIncome, writer.uint32(18).fork()).ldelim();
    }
    if (message.monthlyUnitPrice !== undefined && message.monthlyUnitPrice !== undefined) {
      Int32Value.encode({ value: message.monthlyUnitPrice! }, writer.uint32(26).fork()).ldelim();
    }
    if (message.numberOfJobChanges !== undefined && message.numberOfJobChanges !== undefined) {
      NumberOfJobChanges.encode(message.numberOfJobChanges, writer.uint32(34).fork()).ldelim();
    }
    if (message.resumeFile !== undefined && message.resumeFile !== undefined) {
      ResumeFile.encode(message.resumeFile, writer.uint32(42).fork()).ldelim();
    }
    if (message.preferredMonthlyUnitPrice !== undefined && message.preferredMonthlyUnitPrice !== undefined) {
      PreferredMonthlyUnitPrice.encode(message.preferredMonthlyUnitPrice, writer.uint32(50).fork()).ldelim();
    }
    writer.uint32(58).string(message.achievement);
    if (message.preferredEmploymentType !== undefined && message.preferredEmploymentType !== undefined) {
      EmploymentType.encode(message.preferredEmploymentType, writer.uint32(74).fork()).ldelim();
    }
    for (const v of message.skills) {
      Skill.encode(v!, writer.uint32(82).fork()).ldelim();
    }
    for (const v of message.expectations) {
      Expectation.encode(v!, writer.uint32(90).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): CareerProfile {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseCareerProfile) as CareerProfile;
    message.job = [];
    message.skills = [];
    message.expectations = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.job.push(CareerProfile_JobHistory.decode(reader, reader.uint32()));
          break;
        case 2:
          message.annualIncome = AnnualIncome.decode(reader, reader.uint32());
          break;
        case 3:
          message.monthlyUnitPrice = Int32Value.decode(reader, reader.uint32()).value;
          break;
        case 4:
          message.numberOfJobChanges = NumberOfJobChanges.decode(reader, reader.uint32());
          break;
        case 5:
          message.resumeFile = ResumeFile.decode(reader, reader.uint32());
          break;
        case 6:
          message.preferredMonthlyUnitPrice = PreferredMonthlyUnitPrice.decode(reader, reader.uint32());
          break;
        case 7:
          message.achievement = reader.string();
          break;
        case 9:
          message.preferredEmploymentType = EmploymentType.decode(reader, reader.uint32());
          break;
        case 10:
          message.skills.push(Skill.decode(reader, reader.uint32()));
          break;
        case 11:
          message.expectations.push(Expectation.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): CareerProfile {
    const message = Object.create(baseCareerProfile) as CareerProfile;
    message.job = [];
    message.skills = [];
    message.expectations = [];
    if (object.job) {
      for (const e of object.job) {
        message.job.push(CareerProfile_JobHistory.fromJSON(e));
      }
    }
    if (object.annualIncome) {
      message.annualIncome = AnnualIncome.fromJSON(object.annualIncome);
    }
    if (object.monthlyUnitPrice) {
      message.monthlyUnitPrice = Number(object.monthlyUnitPrice);
    }
    if (object.numberOfJobChanges) {
      message.numberOfJobChanges = NumberOfJobChanges.fromJSON(object.numberOfJobChanges);
    }
    if (object.resumeFile) {
      message.resumeFile = ResumeFile.fromJSON(object.resumeFile);
    }
    if (object.preferredMonthlyUnitPrice) {
      message.preferredMonthlyUnitPrice = PreferredMonthlyUnitPrice.fromJSON(object.preferredMonthlyUnitPrice);
    }
    if (object.achievement) {
      message.achievement = String(object.achievement);
    }
    if (object.preferredEmploymentType) {
      message.preferredEmploymentType = EmploymentType.fromJSON(object.preferredEmploymentType);
    }
    if (object.skills) {
      for (const e of object.skills) {
        message.skills.push(Skill.fromJSON(e));
      }
    }
    if (object.expectations) {
      for (const e of object.expectations) {
        message.expectations.push(Expectation.fromJSON(e));
      }
    }
    return message;
  },
  fromPartial(object: DeepPartial<CareerProfile>): CareerProfile {
    const message = Object.create(baseCareerProfile) as CareerProfile;
    message.job = [];
    message.skills = [];
    message.expectations = [];
    if (object.job) {
      for (const e of object.job) {
        message.job.push(CareerProfile_JobHistory.fromPartial(e));
      }
    }
    if (object.annualIncome) {
      message.annualIncome = AnnualIncome.fromPartial(object.annualIncome);
    }
    if (object.monthlyUnitPrice) {
      message.monthlyUnitPrice = object.monthlyUnitPrice;
    }
    if (object.numberOfJobChanges) {
      message.numberOfJobChanges = NumberOfJobChanges.fromPartial(object.numberOfJobChanges);
    }
    if (object.resumeFile) {
      message.resumeFile = ResumeFile.fromPartial(object.resumeFile);
    }
    if (object.preferredMonthlyUnitPrice) {
      message.preferredMonthlyUnitPrice = PreferredMonthlyUnitPrice.fromPartial(object.preferredMonthlyUnitPrice);
    }
    if (object.achievement) {
      message.achievement = object.achievement;
    }
    if (object.preferredEmploymentType) {
      message.preferredEmploymentType = EmploymentType.fromPartial(object.preferredEmploymentType);
    }
    if (object.skills) {
      for (const e of object.skills) {
        message.skills.push(Skill.fromPartial(e));
      }
    }
    if (object.expectations) {
      for (const e of object.expectations) {
        message.expectations.push(Expectation.fromPartial(e));
      }
    }
    return message;
  },
  toJSON(message: CareerProfile): unknown {
    const obj: any = {};
    if (message.job) {
      obj.job = message.job.map(e => e ? CareerProfile_JobHistory.toJSON(e) : undefined);
    } else {
      obj.job = [];
    }
    obj.annualIncome = message.annualIncome ? AnnualIncome.toJSON(message.annualIncome) : undefined;
    obj.monthlyUnitPrice = message.monthlyUnitPrice || undefined;
    obj.numberOfJobChanges = message.numberOfJobChanges ? NumberOfJobChanges.toJSON(message.numberOfJobChanges) : undefined;
    obj.resumeFile = message.resumeFile ? ResumeFile.toJSON(message.resumeFile) : undefined;
    obj.preferredMonthlyUnitPrice = message.preferredMonthlyUnitPrice ? PreferredMonthlyUnitPrice.toJSON(message.preferredMonthlyUnitPrice) : undefined;
    obj.achievement = message.achievement || "";
    obj.preferredEmploymentType = message.preferredEmploymentType ? EmploymentType.toJSON(message.preferredEmploymentType) : undefined;
    if (message.skills) {
      obj.skills = message.skills.map(e => e ? Skill.toJSON(e) : undefined);
    } else {
      obj.skills = [];
    }
    if (message.expectations) {
      obj.expectations = message.expectations.map(e => e ? Expectation.toJSON(e) : undefined);
    } else {
      obj.expectations = [];
    }
    return obj;
  },
};

export const CareerProfile_JobHistory = {
  encode(message: CareerProfile_JobHistory, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.name);
    if (message.position !== undefined && message.position !== undefined) {
      Position.encode(message.position, writer.uint32(18).fork()).ldelim();
    }
    writer.uint32(24).uint32(message.startYear);
    writer.uint32(32).uint32(message.startMonth);
    if (message.endYear !== undefined && message.endYear !== undefined) {
      Int32Value.encode({ value: message.endYear! }, writer.uint32(42).fork()).ldelim();
    }
    if (message.endMonth !== undefined && message.endMonth !== undefined) {
      Int32Value.encode({ value: message.endMonth! }, writer.uint32(50).fork()).ldelim();
    }
    for (const v of message.occupations) {
      CareerProfile_ExperiencedOccupation.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): CareerProfile_JobHistory {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseCareerProfile_JobHistory) as CareerProfile_JobHistory;
    message.occupations = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.name = reader.string();
          break;
        case 2:
          message.position = Position.decode(reader, reader.uint32());
          break;
        case 3:
          message.startYear = reader.uint32();
          break;
        case 4:
          message.startMonth = reader.uint32();
          break;
        case 5:
          message.endYear = Int32Value.decode(reader, reader.uint32()).value;
          break;
        case 6:
          message.endMonth = Int32Value.decode(reader, reader.uint32()).value;
          break;
        case 7:
          message.occupations.push(CareerProfile_ExperiencedOccupation.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): CareerProfile_JobHistory {
    const message = Object.create(baseCareerProfile_JobHistory) as CareerProfile_JobHistory;
    message.occupations = [];
    if (object.name) {
      message.name = String(object.name);
    }
    if (object.position) {
      message.position = Position.fromJSON(object.position);
    }
    if (object.startYear) {
      message.startYear = Number(object.startYear);
    }
    if (object.startMonth) {
      message.startMonth = Number(object.startMonth);
    }
    if (object.endYear) {
      message.endYear = Number(object.endYear);
    }
    if (object.endMonth) {
      message.endMonth = Number(object.endMonth);
    }
    if (object.occupations) {
      for (const e of object.occupations) {
        message.occupations.push(CareerProfile_ExperiencedOccupation.fromJSON(e));
      }
    }
    return message;
  },
  fromPartial(object: DeepPartial<CareerProfile_JobHistory>): CareerProfile_JobHistory {
    const message = Object.create(baseCareerProfile_JobHistory) as CareerProfile_JobHistory;
    message.occupations = [];
    if (object.name) {
      message.name = object.name;
    }
    if (object.position) {
      message.position = Position.fromPartial(object.position);
    }
    if (object.startYear) {
      message.startYear = object.startYear;
    }
    if (object.startMonth) {
      message.startMonth = object.startMonth;
    }
    if (object.endYear) {
      message.endYear = object.endYear;
    }
    if (object.endMonth) {
      message.endMonth = object.endMonth;
    }
    if (object.occupations) {
      for (const e of object.occupations) {
        message.occupations.push(CareerProfile_ExperiencedOccupation.fromPartial(e));
      }
    }
    return message;
  },
  toJSON(message: CareerProfile_JobHistory): unknown {
    const obj: any = {};
    obj.name = message.name || "";
    obj.position = message.position ? Position.toJSON(message.position) : undefined;
    obj.startYear = message.startYear || 0;
    obj.startMonth = message.startMonth || 0;
    obj.endYear = message.endYear || undefined;
    obj.endMonth = message.endMonth || undefined;
    if (message.occupations) {
      obj.occupations = message.occupations.map(e => e ? CareerProfile_ExperiencedOccupation.toJSON(e) : undefined);
    } else {
      obj.occupations = [];
    }
    return obj;
  },
};

export const CareerProfile_ExperiencedOccupation = {
  encode(message: CareerProfile_ExperiencedOccupation, writer: Writer = Writer.create()): Writer {
    if (message.occupation !== undefined && message.occupation !== undefined) {
      Occupation.encode(message.occupation, writer.uint32(10).fork()).ldelim();
    }
    if (message.yearsOfExperience !== undefined && message.yearsOfExperience !== undefined) {
      YearsOfExperience.encode(message.yearsOfExperience, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): CareerProfile_ExperiencedOccupation {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseCareerProfile_ExperiencedOccupation) as CareerProfile_ExperiencedOccupation;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.occupation = Occupation.decode(reader, reader.uint32());
          break;
        case 2:
          message.yearsOfExperience = YearsOfExperience.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): CareerProfile_ExperiencedOccupation {
    const message = Object.create(baseCareerProfile_ExperiencedOccupation) as CareerProfile_ExperiencedOccupation;
    if (object.occupation) {
      message.occupation = Occupation.fromJSON(object.occupation);
    }
    if (object.yearsOfExperience) {
      message.yearsOfExperience = YearsOfExperience.fromJSON(object.yearsOfExperience);
    }
    return message;
  },
  fromPartial(object: DeepPartial<CareerProfile_ExperiencedOccupation>): CareerProfile_ExperiencedOccupation {
    const message = Object.create(baseCareerProfile_ExperiencedOccupation) as CareerProfile_ExperiencedOccupation;
    if (object.occupation) {
      message.occupation = Occupation.fromPartial(object.occupation);
    }
    if (object.yearsOfExperience) {
      message.yearsOfExperience = YearsOfExperience.fromPartial(object.yearsOfExperience);
    }
    return message;
  },
  toJSON(message: CareerProfile_ExperiencedOccupation): unknown {
    const obj: any = {};
    obj.occupation = message.occupation ? Occupation.toJSON(message.occupation) : undefined;
    obj.yearsOfExperience = message.yearsOfExperience ? YearsOfExperience.toJSON(message.yearsOfExperience) : undefined;
    return obj;
  },
};

export const NewGraduateProfile = {
  encode(message: NewGraduateProfile, writer: Writer = Writer.create()): Writer {
    for (const v of message.researchRooms) {
      ResearchRoom.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.programmingExperiences) {
      ProgrammingExperience.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    if (message.preferredWorkStyle !== undefined && message.preferredWorkStyle !== undefined) {
      PreferredWorkStyle.encode(message.preferredWorkStyle, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.preferredBusinessTypes) {
      PreferredBusinessType.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.pastExperienceAchievements) {
      PastExperienceAchievement.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    for (const v of message.internshipExperiences) {
      InternshipExperience.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    for (const v of message.studyAbroadExperiences) {
      StudyAbroadExperience.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    for (const v of message.clubExperiences) {
      ClubExperience.encode(v!, writer.uint32(66).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): NewGraduateProfile {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseNewGraduateProfile) as NewGraduateProfile;
    message.researchRooms = [];
    message.programmingExperiences = [];
    message.preferredBusinessTypes = [];
    message.pastExperienceAchievements = [];
    message.internshipExperiences = [];
    message.studyAbroadExperiences = [];
    message.clubExperiences = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.researchRooms.push(ResearchRoom.decode(reader, reader.uint32()));
          break;
        case 2:
          message.programmingExperiences.push(ProgrammingExperience.decode(reader, reader.uint32()));
          break;
        case 3:
          message.preferredWorkStyle = PreferredWorkStyle.decode(reader, reader.uint32());
          break;
        case 4:
          message.preferredBusinessTypes.push(PreferredBusinessType.decode(reader, reader.uint32()));
          break;
        case 5:
          message.pastExperienceAchievements.push(PastExperienceAchievement.decode(reader, reader.uint32()));
          break;
        case 6:
          message.internshipExperiences.push(InternshipExperience.decode(reader, reader.uint32()));
          break;
        case 7:
          message.studyAbroadExperiences.push(StudyAbroadExperience.decode(reader, reader.uint32()));
          break;
        case 8:
          message.clubExperiences.push(ClubExperience.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): NewGraduateProfile {
    const message = Object.create(baseNewGraduateProfile) as NewGraduateProfile;
    message.researchRooms = [];
    message.programmingExperiences = [];
    message.preferredBusinessTypes = [];
    message.pastExperienceAchievements = [];
    message.internshipExperiences = [];
    message.studyAbroadExperiences = [];
    message.clubExperiences = [];
    if (object.researchRooms) {
      for (const e of object.researchRooms) {
        message.researchRooms.push(ResearchRoom.fromJSON(e));
      }
    }
    if (object.programmingExperiences) {
      for (const e of object.programmingExperiences) {
        message.programmingExperiences.push(ProgrammingExperience.fromJSON(e));
      }
    }
    if (object.preferredWorkStyle) {
      message.preferredWorkStyle = PreferredWorkStyle.fromJSON(object.preferredWorkStyle);
    }
    if (object.preferredBusinessTypes) {
      for (const e of object.preferredBusinessTypes) {
        message.preferredBusinessTypes.push(PreferredBusinessType.fromJSON(e));
      }
    }
    if (object.pastExperienceAchievements) {
      for (const e of object.pastExperienceAchievements) {
        message.pastExperienceAchievements.push(PastExperienceAchievement.fromJSON(e));
      }
    }
    if (object.internshipExperiences) {
      for (const e of object.internshipExperiences) {
        message.internshipExperiences.push(InternshipExperience.fromJSON(e));
      }
    }
    if (object.studyAbroadExperiences) {
      for (const e of object.studyAbroadExperiences) {
        message.studyAbroadExperiences.push(StudyAbroadExperience.fromJSON(e));
      }
    }
    if (object.clubExperiences) {
      for (const e of object.clubExperiences) {
        message.clubExperiences.push(ClubExperience.fromJSON(e));
      }
    }
    return message;
  },
  fromPartial(object: DeepPartial<NewGraduateProfile>): NewGraduateProfile {
    const message = Object.create(baseNewGraduateProfile) as NewGraduateProfile;
    message.researchRooms = [];
    message.programmingExperiences = [];
    message.preferredBusinessTypes = [];
    message.pastExperienceAchievements = [];
    message.internshipExperiences = [];
    message.studyAbroadExperiences = [];
    message.clubExperiences = [];
    if (object.researchRooms) {
      for (const e of object.researchRooms) {
        message.researchRooms.push(ResearchRoom.fromPartial(e));
      }
    }
    if (object.programmingExperiences) {
      for (const e of object.programmingExperiences) {
        message.programmingExperiences.push(ProgrammingExperience.fromPartial(e));
      }
    }
    if (object.preferredWorkStyle) {
      message.preferredWorkStyle = PreferredWorkStyle.fromPartial(object.preferredWorkStyle);
    }
    if (object.preferredBusinessTypes) {
      for (const e of object.preferredBusinessTypes) {
        message.preferredBusinessTypes.push(PreferredBusinessType.fromPartial(e));
      }
    }
    if (object.pastExperienceAchievements) {
      for (const e of object.pastExperienceAchievements) {
        message.pastExperienceAchievements.push(PastExperienceAchievement.fromPartial(e));
      }
    }
    if (object.internshipExperiences) {
      for (const e of object.internshipExperiences) {
        message.internshipExperiences.push(InternshipExperience.fromPartial(e));
      }
    }
    if (object.studyAbroadExperiences) {
      for (const e of object.studyAbroadExperiences) {
        message.studyAbroadExperiences.push(StudyAbroadExperience.fromPartial(e));
      }
    }
    if (object.clubExperiences) {
      for (const e of object.clubExperiences) {
        message.clubExperiences.push(ClubExperience.fromPartial(e));
      }
    }
    return message;
  },
  toJSON(message: NewGraduateProfile): unknown {
    const obj: any = {};
    if (message.researchRooms) {
      obj.researchRooms = message.researchRooms.map(e => e ? ResearchRoom.toJSON(e) : undefined);
    } else {
      obj.researchRooms = [];
    }
    if (message.programmingExperiences) {
      obj.programmingExperiences = message.programmingExperiences.map(e => e ? ProgrammingExperience.toJSON(e) : undefined);
    } else {
      obj.programmingExperiences = [];
    }
    obj.preferredWorkStyle = message.preferredWorkStyle ? PreferredWorkStyle.toJSON(message.preferredWorkStyle) : undefined;
    if (message.preferredBusinessTypes) {
      obj.preferredBusinessTypes = message.preferredBusinessTypes.map(e => e ? PreferredBusinessType.toJSON(e) : undefined);
    } else {
      obj.preferredBusinessTypes = [];
    }
    if (message.pastExperienceAchievements) {
      obj.pastExperienceAchievements = message.pastExperienceAchievements.map(e => e ? PastExperienceAchievement.toJSON(e) : undefined);
    } else {
      obj.pastExperienceAchievements = [];
    }
    if (message.internshipExperiences) {
      obj.internshipExperiences = message.internshipExperiences.map(e => e ? InternshipExperience.toJSON(e) : undefined);
    } else {
      obj.internshipExperiences = [];
    }
    if (message.studyAbroadExperiences) {
      obj.studyAbroadExperiences = message.studyAbroadExperiences.map(e => e ? StudyAbroadExperience.toJSON(e) : undefined);
    } else {
      obj.studyAbroadExperiences = [];
    }
    if (message.clubExperiences) {
      obj.clubExperiences = message.clubExperiences.map(e => e ? ClubExperience.toJSON(e) : undefined);
    } else {
      obj.clubExperiences = [];
    }
    return obj;
  },
};

export const ResearchRoom = {
  encode(message: ResearchRoom, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.name);
    writer.uint32(18).string(message.content);
    return writer;
  },
  decode(reader: Reader, length?: number): ResearchRoom {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseResearchRoom) as ResearchRoom;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.name = reader.string();
          break;
        case 2:
          message.content = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): ResearchRoom {
    const message = Object.create(baseResearchRoom) as ResearchRoom;
    if (object.name) {
      message.name = String(object.name);
    }
    if (object.content) {
      message.content = String(object.content);
    }
    return message;
  },
  fromPartial(object: DeepPartial<ResearchRoom>): ResearchRoom {
    const message = Object.create(baseResearchRoom) as ResearchRoom;
    if (object.name) {
      message.name = object.name;
    }
    if (object.content) {
      message.content = object.content;
    }
    return message;
  },
  toJSON(message: ResearchRoom): unknown {
    const obj: any = {};
    obj.name = message.name || "";
    obj.content = message.content || "";
    return obj;
  },
};

export const DttRank = {
  encode(message: DttRank, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).uint32(message.id);
    writer.uint32(18).string(message.name);
    return writer;
  },
  decode(reader: Reader, length?: number): DttRank {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseDttRank) as DttRank;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.uint32();
          break;
        case 2:
          message.name = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): DttRank {
    const message = Object.create(baseDttRank) as DttRank;
    if (object.id) {
      message.id = Number(object.id);
    }
    if (object.name) {
      message.name = String(object.name);
    }
    return message;
  },
  fromPartial(object: DeepPartial<DttRank>): DttRank {
    const message = Object.create(baseDttRank) as DttRank;
    if (object.id) {
      message.id = object.id;
    }
    if (object.name) {
      message.name = object.name;
    }
    return message;
  },
  toJSON(message: DttRank): unknown {
    const obj: any = {};
    obj.id = message.id || 0;
    obj.name = message.name || "";
    return obj;
  },
};

export const Gender = {
  encode(message: Gender, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).uint32(message.id);
    writer.uint32(18).string(message.name);
    writer.uint32(26).string(message.nameEn);
    return writer;
  },
  decode(reader: Reader, length?: number): Gender {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGender) as Gender;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.uint32();
          break;
        case 2:
          message.name = reader.string();
          break;
        case 3:
          message.nameEn = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): Gender {
    const message = Object.create(baseGender) as Gender;
    if (object.id) {
      message.id = Number(object.id);
    }
    if (object.name) {
      message.name = String(object.name);
    }
    if (object.nameEn) {
      message.nameEn = String(object.nameEn);
    }
    return message;
  },
  fromPartial(object: DeepPartial<Gender>): Gender {
    const message = Object.create(baseGender) as Gender;
    if (object.id) {
      message.id = object.id;
    }
    if (object.name) {
      message.name = object.name;
    }
    if (object.nameEn) {
      message.nameEn = object.nameEn;
    }
    return message;
  },
  toJSON(message: Gender): unknown {
    const obj: any = {};
    obj.id = message.id || 0;
    obj.name = message.name || "";
    obj.nameEn = message.nameEn || "";
    return obj;
  },
};

export const ProgrammingExperience = {
  encode(message: ProgrammingExperience, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.languageName);
    if (message.level !== undefined && message.level !== undefined) {
      ProgrammingExperienceLevel.encode(message.level, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): ProgrammingExperience {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseProgrammingExperience) as ProgrammingExperience;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.languageName = reader.string();
          break;
        case 2:
          message.level = ProgrammingExperienceLevel.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): ProgrammingExperience {
    const message = Object.create(baseProgrammingExperience) as ProgrammingExperience;
    if (object.languageName) {
      message.languageName = String(object.languageName);
    }
    if (object.level) {
      message.level = ProgrammingExperienceLevel.fromJSON(object.level);
    }
    return message;
  },
  fromPartial(object: DeepPartial<ProgrammingExperience>): ProgrammingExperience {
    const message = Object.create(baseProgrammingExperience) as ProgrammingExperience;
    if (object.languageName) {
      message.languageName = object.languageName;
    }
    if (object.level) {
      message.level = ProgrammingExperienceLevel.fromPartial(object.level);
    }
    return message;
  },
  toJSON(message: ProgrammingExperience): unknown {
    const obj: any = {};
    obj.languageName = message.languageName || "";
    obj.level = message.level ? ProgrammingExperienceLevel.toJSON(message.level) : undefined;
    return obj;
  },
};

export const ProgrammingExperienceLevel = {
  encode(message: ProgrammingExperienceLevel, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).uint32(message.id);
    writer.uint32(18).string(message.name);
    return writer;
  },
  decode(reader: Reader, length?: number): ProgrammingExperienceLevel {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseProgrammingExperienceLevel) as ProgrammingExperienceLevel;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.uint32();
          break;
        case 2:
          message.name = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): ProgrammingExperienceLevel {
    const message = Object.create(baseProgrammingExperienceLevel) as ProgrammingExperienceLevel;
    if (object.id) {
      message.id = Number(object.id);
    }
    if (object.name) {
      message.name = String(object.name);
    }
    return message;
  },
  fromPartial(object: DeepPartial<ProgrammingExperienceLevel>): ProgrammingExperienceLevel {
    const message = Object.create(baseProgrammingExperienceLevel) as ProgrammingExperienceLevel;
    if (object.id) {
      message.id = object.id;
    }
    if (object.name) {
      message.name = object.name;
    }
    return message;
  },
  toJSON(message: ProgrammingExperienceLevel): unknown {
    const obj: any = {};
    obj.id = message.id || 0;
    obj.name = message.name || "";
    return obj;
  },
};

export const PreferredWorkStyle = {
  encode(message: PreferredWorkStyle, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).uint32(message.id);
    writer.uint32(18).string(message.name);
    return writer;
  },
  decode(reader: Reader, length?: number): PreferredWorkStyle {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(basePreferredWorkStyle) as PreferredWorkStyle;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.uint32();
          break;
        case 2:
          message.name = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): PreferredWorkStyle {
    const message = Object.create(basePreferredWorkStyle) as PreferredWorkStyle;
    if (object.id) {
      message.id = Number(object.id);
    }
    if (object.name) {
      message.name = String(object.name);
    }
    return message;
  },
  fromPartial(object: DeepPartial<PreferredWorkStyle>): PreferredWorkStyle {
    const message = Object.create(basePreferredWorkStyle) as PreferredWorkStyle;
    if (object.id) {
      message.id = object.id;
    }
    if (object.name) {
      message.name = object.name;
    }
    return message;
  },
  toJSON(message: PreferredWorkStyle): unknown {
    const obj: any = {};
    obj.id = message.id || 0;
    obj.name = message.name || "";
    return obj;
  },
};

export const PreferredBusinessType = {
  encode(message: PreferredBusinessType, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).uint32(message.id);
    writer.uint32(18).string(message.name);
    return writer;
  },
  decode(reader: Reader, length?: number): PreferredBusinessType {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(basePreferredBusinessType) as PreferredBusinessType;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.uint32();
          break;
        case 2:
          message.name = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): PreferredBusinessType {
    const message = Object.create(basePreferredBusinessType) as PreferredBusinessType;
    if (object.id) {
      message.id = Number(object.id);
    }
    if (object.name) {
      message.name = String(object.name);
    }
    return message;
  },
  fromPartial(object: DeepPartial<PreferredBusinessType>): PreferredBusinessType {
    const message = Object.create(basePreferredBusinessType) as PreferredBusinessType;
    if (object.id) {
      message.id = object.id;
    }
    if (object.name) {
      message.name = object.name;
    }
    return message;
  },
  toJSON(message: PreferredBusinessType): unknown {
    const obj: any = {};
    obj.id = message.id || 0;
    obj.name = message.name || "";
    return obj;
  },
};

export const PastExperienceAchievement = {
  encode(message: PastExperienceAchievement, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).uint32(message.id);
    writer.uint32(18).string(message.name);
    return writer;
  },
  decode(reader: Reader, length?: number): PastExperienceAchievement {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(basePastExperienceAchievement) as PastExperienceAchievement;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.uint32();
          break;
        case 2:
          message.name = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): PastExperienceAchievement {
    const message = Object.create(basePastExperienceAchievement) as PastExperienceAchievement;
    if (object.id) {
      message.id = Number(object.id);
    }
    if (object.name) {
      message.name = String(object.name);
    }
    return message;
  },
  fromPartial(object: DeepPartial<PastExperienceAchievement>): PastExperienceAchievement {
    const message = Object.create(basePastExperienceAchievement) as PastExperienceAchievement;
    if (object.id) {
      message.id = object.id;
    }
    if (object.name) {
      message.name = object.name;
    }
    return message;
  },
  toJSON(message: PastExperienceAchievement): unknown {
    const obj: any = {};
    obj.id = message.id || 0;
    obj.name = message.name || "";
    return obj;
  },
};

export const InternshipExperience = {
  encode(message: InternshipExperience, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.companyName);
    if (message.startYear !== undefined && message.startYear !== undefined) {
      Int32Value.encode({ value: message.startYear! }, writer.uint32(18).fork()).ldelim();
    }
    if (message.startMonth !== undefined && message.startMonth !== undefined) {
      Int32Value.encode({ value: message.startMonth! }, writer.uint32(26).fork()).ldelim();
    }
    if (message.endYear !== undefined && message.endYear !== undefined) {
      Int32Value.encode({ value: message.endYear! }, writer.uint32(34).fork()).ldelim();
    }
    if (message.endMonth !== undefined && message.endMonth !== undefined) {
      Int32Value.encode({ value: message.endMonth! }, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): InternshipExperience {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseInternshipExperience) as InternshipExperience;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.companyName = reader.string();
          break;
        case 2:
          message.startYear = Int32Value.decode(reader, reader.uint32()).value;
          break;
        case 3:
          message.startMonth = Int32Value.decode(reader, reader.uint32()).value;
          break;
        case 4:
          message.endYear = Int32Value.decode(reader, reader.uint32()).value;
          break;
        case 5:
          message.endMonth = Int32Value.decode(reader, reader.uint32()).value;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): InternshipExperience {
    const message = Object.create(baseInternshipExperience) as InternshipExperience;
    if (object.companyName) {
      message.companyName = String(object.companyName);
    }
    if (object.startYear) {
      message.startYear = Number(object.startYear);
    }
    if (object.startMonth) {
      message.startMonth = Number(object.startMonth);
    }
    if (object.endYear) {
      message.endYear = Number(object.endYear);
    }
    if (object.endMonth) {
      message.endMonth = Number(object.endMonth);
    }
    return message;
  },
  fromPartial(object: DeepPartial<InternshipExperience>): InternshipExperience {
    const message = Object.create(baseInternshipExperience) as InternshipExperience;
    if (object.companyName) {
      message.companyName = object.companyName;
    }
    if (object.startYear) {
      message.startYear = object.startYear;
    }
    if (object.startMonth) {
      message.startMonth = object.startMonth;
    }
    if (object.endYear) {
      message.endYear = object.endYear;
    }
    if (object.endMonth) {
      message.endMonth = object.endMonth;
    }
    return message;
  },
  toJSON(message: InternshipExperience): unknown {
    const obj: any = {};
    obj.companyName = message.companyName || "";
    obj.startYear = message.startYear || undefined;
    obj.startMonth = message.startMonth || undefined;
    obj.endYear = message.endYear || undefined;
    obj.endMonth = message.endMonth || undefined;
    return obj;
  },
};

export const StudyAbroadExperience = {
  encode(message: StudyAbroadExperience, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.countryName);
    if (message.startYear !== undefined && message.startYear !== undefined) {
      Int32Value.encode({ value: message.startYear! }, writer.uint32(18).fork()).ldelim();
    }
    if (message.startMonth !== undefined && message.startMonth !== undefined) {
      Int32Value.encode({ value: message.startMonth! }, writer.uint32(26).fork()).ldelim();
    }
    if (message.endYear !== undefined && message.endYear !== undefined) {
      Int32Value.encode({ value: message.endYear! }, writer.uint32(34).fork()).ldelim();
    }
    if (message.endMonth !== undefined && message.endMonth !== undefined) {
      Int32Value.encode({ value: message.endMonth! }, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): StudyAbroadExperience {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseStudyAbroadExperience) as StudyAbroadExperience;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.countryName = reader.string();
          break;
        case 2:
          message.startYear = Int32Value.decode(reader, reader.uint32()).value;
          break;
        case 3:
          message.startMonth = Int32Value.decode(reader, reader.uint32()).value;
          break;
        case 4:
          message.endYear = Int32Value.decode(reader, reader.uint32()).value;
          break;
        case 5:
          message.endMonth = Int32Value.decode(reader, reader.uint32()).value;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): StudyAbroadExperience {
    const message = Object.create(baseStudyAbroadExperience) as StudyAbroadExperience;
    if (object.countryName) {
      message.countryName = String(object.countryName);
    }
    if (object.startYear) {
      message.startYear = Number(object.startYear);
    }
    if (object.startMonth) {
      message.startMonth = Number(object.startMonth);
    }
    if (object.endYear) {
      message.endYear = Number(object.endYear);
    }
    if (object.endMonth) {
      message.endMonth = Number(object.endMonth);
    }
    return message;
  },
  fromPartial(object: DeepPartial<StudyAbroadExperience>): StudyAbroadExperience {
    const message = Object.create(baseStudyAbroadExperience) as StudyAbroadExperience;
    if (object.countryName) {
      message.countryName = object.countryName;
    }
    if (object.startYear) {
      message.startYear = object.startYear;
    }
    if (object.startMonth) {
      message.startMonth = object.startMonth;
    }
    if (object.endYear) {
      message.endYear = object.endYear;
    }
    if (object.endMonth) {
      message.endMonth = object.endMonth;
    }
    return message;
  },
  toJSON(message: StudyAbroadExperience): unknown {
    const obj: any = {};
    obj.countryName = message.countryName || "";
    obj.startYear = message.startYear || undefined;
    obj.startMonth = message.startMonth || undefined;
    obj.endYear = message.endYear || undefined;
    obj.endMonth = message.endMonth || undefined;
    return obj;
  },
};

export const ClubExperience = {
  encode(message: ClubExperience, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.name);
    writer.uint32(18).string(message.content);
    return writer;
  },
  decode(reader: Reader, length?: number): ClubExperience {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseClubExperience) as ClubExperience;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.name = reader.string();
          break;
        case 2:
          message.content = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): ClubExperience {
    const message = Object.create(baseClubExperience) as ClubExperience;
    if (object.name) {
      message.name = String(object.name);
    }
    if (object.content) {
      message.content = String(object.content);
    }
    return message;
  },
  fromPartial(object: DeepPartial<ClubExperience>): ClubExperience {
    const message = Object.create(baseClubExperience) as ClubExperience;
    if (object.name) {
      message.name = object.name;
    }
    if (object.content) {
      message.content = object.content;
    }
    return message;
  },
  toJSON(message: ClubExperience): unknown {
    const obj: any = {};
    obj.name = message.name || "";
    obj.content = message.content || "";
    return obj;
  },
};

export const WorkLocation = {
  encode(message: WorkLocation, writer: Writer = Writer.create()): Writer {
    if (message.japanPrefecture !== undefined && message.japanPrefecture !== undefined) {
      JapanPrefecture.encode(message.japanPrefecture, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): WorkLocation {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseWorkLocation) as WorkLocation;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.japanPrefecture = JapanPrefecture.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): WorkLocation {
    const message = Object.create(baseWorkLocation) as WorkLocation;
    if (object.japanPrefecture) {
      message.japanPrefecture = JapanPrefecture.fromJSON(object.japanPrefecture);
    }
    return message;
  },
  fromPartial(object: DeepPartial<WorkLocation>): WorkLocation {
    const message = Object.create(baseWorkLocation) as WorkLocation;
    if (object.japanPrefecture) {
      message.japanPrefecture = JapanPrefecture.fromPartial(object.japanPrefecture);
    }
    return message;
  },
  toJSON(message: WorkLocation): unknown {
    const obj: any = {};
    obj.japanPrefecture = message.japanPrefecture ? JapanPrefecture.toJSON(message.japanPrefecture) : undefined;
    return obj;
  },
};

export const School = {
  encode(message: School, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).uint32(message.id);
    writer.uint32(18).string(message.name);
    return writer;
  },
  decode(reader: Reader, length?: number): School {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseSchool) as School;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.uint32();
          break;
        case 2:
          message.name = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): School {
    const message = Object.create(baseSchool) as School;
    if (object.id) {
      message.id = Number(object.id);
    }
    if (object.name) {
      message.name = String(object.name);
    }
    return message;
  },
  fromPartial(object: DeepPartial<School>): School {
    const message = Object.create(baseSchool) as School;
    if (object.id) {
      message.id = object.id;
    }
    if (object.name) {
      message.name = object.name;
    }
    return message;
  },
  toJSON(message: School): unknown {
    const obj: any = {};
    obj.id = message.id || 0;
    obj.name = message.name || "";
    return obj;
  },
};

export const Department = {
  encode(message: Department, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).uint32(message.id);
    writer.uint32(18).string(message.name);
    return writer;
  },
  decode(reader: Reader, length?: number): Department {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseDepartment) as Department;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.uint32();
          break;
        case 2:
          message.name = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): Department {
    const message = Object.create(baseDepartment) as Department;
    if (object.id) {
      message.id = Number(object.id);
    }
    if (object.name) {
      message.name = String(object.name);
    }
    return message;
  },
  fromPartial(object: DeepPartial<Department>): Department {
    const message = Object.create(baseDepartment) as Department;
    if (object.id) {
      message.id = object.id;
    }
    if (object.name) {
      message.name = object.name;
    }
    return message;
  },
  toJSON(message: Department): unknown {
    const obj: any = {};
    obj.id = message.id || 0;
    obj.name = message.name || "";
    return obj;
  },
};

export const EmploymentType = {
  encode(message: EmploymentType, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).uint32(message.id);
    writer.uint32(18).string(message.name);
    return writer;
  },
  decode(reader: Reader, length?: number): EmploymentType {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseEmploymentType) as EmploymentType;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.uint32();
          break;
        case 2:
          message.name = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): EmploymentType {
    const message = Object.create(baseEmploymentType) as EmploymentType;
    if (object.id) {
      message.id = Number(object.id);
    }
    if (object.name) {
      message.name = String(object.name);
    }
    return message;
  },
  fromPartial(object: DeepPartial<EmploymentType>): EmploymentType {
    const message = Object.create(baseEmploymentType) as EmploymentType;
    if (object.id) {
      message.id = object.id;
    }
    if (object.name) {
      message.name = object.name;
    }
    return message;
  },
  toJSON(message: EmploymentType): unknown {
    const obj: any = {};
    obj.id = message.id || 0;
    obj.name = message.name || "";
    return obj;
  },
};

export const EnglishSkill = {
  encode(message: EnglishSkill, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).uint32(message.id);
    writer.uint32(18).string(message.name);
    return writer;
  },
  decode(reader: Reader, length?: number): EnglishSkill {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseEnglishSkill) as EnglishSkill;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.uint32();
          break;
        case 2:
          message.name = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): EnglishSkill {
    const message = Object.create(baseEnglishSkill) as EnglishSkill;
    if (object.id) {
      message.id = Number(object.id);
    }
    if (object.name) {
      message.name = String(object.name);
    }
    return message;
  },
  fromPartial(object: DeepPartial<EnglishSkill>): EnglishSkill {
    const message = Object.create(baseEnglishSkill) as EnglishSkill;
    if (object.id) {
      message.id = object.id;
    }
    if (object.name) {
      message.name = object.name;
    }
    return message;
  },
  toJSON(message: EnglishSkill): unknown {
    const obj: any = {};
    obj.id = message.id || 0;
    obj.name = message.name || "";
    return obj;
  },
};

export const NumberOfJobChanges = {
  encode(message: NumberOfJobChanges, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).uint32(message.id);
    writer.uint32(18).string(message.name);
    return writer;
  },
  decode(reader: Reader, length?: number): NumberOfJobChanges {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseNumberOfJobChanges) as NumberOfJobChanges;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.uint32();
          break;
        case 2:
          message.name = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): NumberOfJobChanges {
    const message = Object.create(baseNumberOfJobChanges) as NumberOfJobChanges;
    if (object.id) {
      message.id = Number(object.id);
    }
    if (object.name) {
      message.name = String(object.name);
    }
    return message;
  },
  fromPartial(object: DeepPartial<NumberOfJobChanges>): NumberOfJobChanges {
    const message = Object.create(baseNumberOfJobChanges) as NumberOfJobChanges;
    if (object.id) {
      message.id = object.id;
    }
    if (object.name) {
      message.name = object.name;
    }
    return message;
  },
  toJSON(message: NumberOfJobChanges): unknown {
    const obj: any = {};
    obj.id = message.id || 0;
    obj.name = message.name || "";
    return obj;
  },
};

export const AnnualIncome = {
  encode(message: AnnualIncome, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).uint32(message.id);
    writer.uint32(18).string(message.name);
    return writer;
  },
  decode(reader: Reader, length?: number): AnnualIncome {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseAnnualIncome) as AnnualIncome;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.uint32();
          break;
        case 2:
          message.name = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): AnnualIncome {
    const message = Object.create(baseAnnualIncome) as AnnualIncome;
    if (object.id) {
      message.id = Number(object.id);
    }
    if (object.name) {
      message.name = String(object.name);
    }
    return message;
  },
  fromPartial(object: DeepPartial<AnnualIncome>): AnnualIncome {
    const message = Object.create(baseAnnualIncome) as AnnualIncome;
    if (object.id) {
      message.id = object.id;
    }
    if (object.name) {
      message.name = object.name;
    }
    return message;
  },
  toJSON(message: AnnualIncome): unknown {
    const obj: any = {};
    obj.id = message.id || 0;
    obj.name = message.name || "";
    return obj;
  },
};

export const PreferredMonthlyUnitPrice = {
  encode(message: PreferredMonthlyUnitPrice, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).uint32(message.id);
    writer.uint32(18).string(message.name);
    return writer;
  },
  decode(reader: Reader, length?: number): PreferredMonthlyUnitPrice {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(basePreferredMonthlyUnitPrice) as PreferredMonthlyUnitPrice;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.uint32();
          break;
        case 2:
          message.name = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): PreferredMonthlyUnitPrice {
    const message = Object.create(basePreferredMonthlyUnitPrice) as PreferredMonthlyUnitPrice;
    if (object.id) {
      message.id = Number(object.id);
    }
    if (object.name) {
      message.name = String(object.name);
    }
    return message;
  },
  fromPartial(object: DeepPartial<PreferredMonthlyUnitPrice>): PreferredMonthlyUnitPrice {
    const message = Object.create(basePreferredMonthlyUnitPrice) as PreferredMonthlyUnitPrice;
    if (object.id) {
      message.id = object.id;
    }
    if (object.name) {
      message.name = object.name;
    }
    return message;
  },
  toJSON(message: PreferredMonthlyUnitPrice): unknown {
    const obj: any = {};
    obj.id = message.id || 0;
    obj.name = message.name || "";
    return obj;
  },
};

export const Occupation = {
  encode(message: Occupation, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).uint32(message.id);
    writer.uint32(18).string(message.name);
    return writer;
  },
  decode(reader: Reader, length?: number): Occupation {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseOccupation) as Occupation;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.uint32();
          break;
        case 2:
          message.name = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): Occupation {
    const message = Object.create(baseOccupation) as Occupation;
    if (object.id) {
      message.id = Number(object.id);
    }
    if (object.name) {
      message.name = String(object.name);
    }
    return message;
  },
  fromPartial(object: DeepPartial<Occupation>): Occupation {
    const message = Object.create(baseOccupation) as Occupation;
    if (object.id) {
      message.id = object.id;
    }
    if (object.name) {
      message.name = object.name;
    }
    return message;
  },
  toJSON(message: Occupation): unknown {
    const obj: any = {};
    obj.id = message.id || 0;
    obj.name = message.name || "";
    return obj;
  },
};

export const YearsOfExperience = {
  encode(message: YearsOfExperience, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).uint32(message.id);
    writer.uint32(18).string(message.name);
    return writer;
  },
  decode(reader: Reader, length?: number): YearsOfExperience {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseYearsOfExperience) as YearsOfExperience;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.uint32();
          break;
        case 2:
          message.name = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): YearsOfExperience {
    const message = Object.create(baseYearsOfExperience) as YearsOfExperience;
    if (object.id) {
      message.id = Number(object.id);
    }
    if (object.name) {
      message.name = String(object.name);
    }
    return message;
  },
  fromPartial(object: DeepPartial<YearsOfExperience>): YearsOfExperience {
    const message = Object.create(baseYearsOfExperience) as YearsOfExperience;
    if (object.id) {
      message.id = object.id;
    }
    if (object.name) {
      message.name = object.name;
    }
    return message;
  },
  toJSON(message: YearsOfExperience): unknown {
    const obj: any = {};
    obj.id = message.id || 0;
    obj.name = message.name || "";
    return obj;
  },
};

export const Position = {
  encode(message: Position, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).uint32(message.id);
    writer.uint32(18).string(message.name);
    return writer;
  },
  decode(reader: Reader, length?: number): Position {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(basePosition) as Position;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.uint32();
          break;
        case 2:
          message.name = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): Position {
    const message = Object.create(basePosition) as Position;
    if (object.id) {
      message.id = Number(object.id);
    }
    if (object.name) {
      message.name = String(object.name);
    }
    return message;
  },
  fromPartial(object: DeepPartial<Position>): Position {
    const message = Object.create(basePosition) as Position;
    if (object.id) {
      message.id = object.id;
    }
    if (object.name) {
      message.name = object.name;
    }
    return message;
  },
  toJSON(message: Position): unknown {
    const obj: any = {};
    obj.id = message.id || 0;
    obj.name = message.name || "";
    return obj;
  },
};

export const Skill = {
  encode(message: Skill, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).uint64(message.id);
    writer.uint32(18).string(message.name);
    return writer;
  },
  decode(reader: Reader, length?: number): Skill {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseSkill) as Skill;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = longToNumber(reader.uint64() as Long);
          break;
        case 2:
          message.name = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): Skill {
    const message = Object.create(baseSkill) as Skill;
    if (object.id) {
      message.id = Number(object.id);
    }
    if (object.name) {
      message.name = String(object.name);
    }
    return message;
  },
  fromPartial(object: DeepPartial<Skill>): Skill {
    const message = Object.create(baseSkill) as Skill;
    if (object.id) {
      message.id = object.id;
    }
    if (object.name) {
      message.name = object.name;
    }
    return message;
  },
  toJSON(message: Skill): unknown {
    const obj: any = {};
    obj.id = message.id || 0;
    obj.name = message.name || "";
    return obj;
  },
};

export const Expectation = {
  encode(message: Expectation, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).uint64(message.id);
    writer.uint32(18).string(message.name);
    return writer;
  },
  decode(reader: Reader, length?: number): Expectation {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseExpectation) as Expectation;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = longToNumber(reader.uint64() as Long);
          break;
        case 2:
          message.name = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): Expectation {
    const message = Object.create(baseExpectation) as Expectation;
    if (object.id) {
      message.id = Number(object.id);
    }
    if (object.name) {
      message.name = String(object.name);
    }
    return message;
  },
  fromPartial(object: DeepPartial<Expectation>): Expectation {
    const message = Object.create(baseExpectation) as Expectation;
    if (object.id) {
      message.id = object.id;
    }
    if (object.name) {
      message.name = object.name;
    }
    return message;
  },
  toJSON(message: Expectation): unknown {
    const obj: any = {};
    obj.id = message.id || 0;
    obj.name = message.name || "";
    return obj;
  },
};

export const SelectionStep = {
  encode(message: SelectionStep, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).uint32(message.id);
    writer.uint32(18).string(message.name);
    return writer;
  },
  decode(reader: Reader, length?: number): SelectionStep {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseSelectionStep) as SelectionStep;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.uint32();
          break;
        case 2:
          message.name = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): SelectionStep {
    const message = Object.create(baseSelectionStep) as SelectionStep;
    if (object.id) {
      message.id = Number(object.id);
    }
    if (object.name) {
      message.name = String(object.name);
    }
    return message;
  },
  fromPartial(object: DeepPartial<SelectionStep>): SelectionStep {
    const message = Object.create(baseSelectionStep) as SelectionStep;
    if (object.id) {
      message.id = object.id;
    }
    if (object.name) {
      message.name = object.name;
    }
    return message;
  },
  toJSON(message: SelectionStep): unknown {
    const obj: any = {};
    obj.id = message.id || 0;
    obj.name = message.name || "";
    return obj;
  },
};

export const ResumeFile = {
  encode(message: ResumeFile, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.url);
    writer.uint32(18).string(message.name);
    writer.uint32(24).int64(message.size);
    writer.uint32(34).string(message.key);
    return writer;
  },
  decode(reader: Reader, length?: number): ResumeFile {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseResumeFile) as ResumeFile;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.url = reader.string();
          break;
        case 2:
          message.name = reader.string();
          break;
        case 3:
          message.size = longToNumber(reader.int64() as Long);
          break;
        case 4:
          message.key = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): ResumeFile {
    const message = Object.create(baseResumeFile) as ResumeFile;
    if (object.url) {
      message.url = String(object.url);
    }
    if (object.name) {
      message.name = String(object.name);
    }
    if (object.size) {
      message.size = Number(object.size);
    }
    if (object.key) {
      message.key = String(object.key);
    }
    return message;
  },
  fromPartial(object: DeepPartial<ResumeFile>): ResumeFile {
    const message = Object.create(baseResumeFile) as ResumeFile;
    if (object.url) {
      message.url = object.url;
    }
    if (object.name) {
      message.name = object.name;
    }
    if (object.size) {
      message.size = object.size;
    }
    if (object.key) {
      message.key = object.key;
    }
    return message;
  },
  toJSON(message: ResumeFile): unknown {
    const obj: any = {};
    obj.url = message.url || "";
    obj.name = message.name || "";
    obj.size = message.size || 0;
    obj.key = message.key || "";
    return obj;
  },
};

export const MissionFollower = {
  encode(message: MissionFollower, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.accountId);
    if (message.mission !== undefined && message.mission !== undefined) {
      Mission.encode(message.mission, writer.uint32(18).fork()).ldelim();
    }
    writer.uint32(24).int64(message.followedAt);
    for (const v of message.interests) {
      Interest.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    writer.uint32(40).bool(message.favorite);
    writer.uint32(50).string(message.chatRoomId);
    writer.uint32(56).int64(message.messageLastSentAt);
    writer.uint32(64).bool(message.isFollowing);
    return writer;
  },
  decode(reader: Reader, length?: number): MissionFollower {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseMissionFollower) as MissionFollower;
    message.interests = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.accountId = reader.string();
          break;
        case 2:
          message.mission = Mission.decode(reader, reader.uint32());
          break;
        case 3:
          message.followedAt = longToNumber(reader.int64() as Long);
          break;
        case 4:
          message.interests.push(Interest.decode(reader, reader.uint32()));
          break;
        case 5:
          message.favorite = reader.bool();
          break;
        case 6:
          message.chatRoomId = reader.string();
          break;
        case 7:
          message.messageLastSentAt = longToNumber(reader.int64() as Long);
          break;
        case 8:
          message.isFollowing = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): MissionFollower {
    const message = Object.create(baseMissionFollower) as MissionFollower;
    message.interests = [];
    if (object.accountId) {
      message.accountId = String(object.accountId);
    }
    if (object.mission) {
      message.mission = Mission.fromJSON(object.mission);
    }
    if (object.followedAt) {
      message.followedAt = Number(object.followedAt);
    }
    if (object.interests) {
      for (const e of object.interests) {
        message.interests.push(Interest.fromJSON(e));
      }
    }
    if (object.favorite) {
      message.favorite = Boolean(object.favorite);
    }
    if (object.chatRoomId) {
      message.chatRoomId = String(object.chatRoomId);
    }
    if (object.messageLastSentAt) {
      message.messageLastSentAt = Number(object.messageLastSentAt);
    }
    if (object.isFollowing) {
      message.isFollowing = Boolean(object.isFollowing);
    }
    return message;
  },
  fromPartial(object: DeepPartial<MissionFollower>): MissionFollower {
    const message = Object.create(baseMissionFollower) as MissionFollower;
    message.interests = [];
    if (object.accountId) {
      message.accountId = object.accountId;
    }
    if (object.mission) {
      message.mission = Mission.fromPartial(object.mission);
    }
    if (object.followedAt) {
      message.followedAt = object.followedAt;
    }
    if (object.interests) {
      for (const e of object.interests) {
        message.interests.push(Interest.fromPartial(e));
      }
    }
    if (object.favorite) {
      message.favorite = object.favorite;
    }
    if (object.chatRoomId) {
      message.chatRoomId = object.chatRoomId;
    }
    if (object.messageLastSentAt) {
      message.messageLastSentAt = object.messageLastSentAt;
    }
    if (object.isFollowing) {
      message.isFollowing = object.isFollowing;
    }
    return message;
  },
  toJSON(message: MissionFollower): unknown {
    const obj: any = {};
    obj.accountId = message.accountId || "";
    obj.mission = message.mission ? Mission.toJSON(message.mission) : undefined;
    obj.followedAt = message.followedAt || 0;
    if (message.interests) {
      obj.interests = message.interests.map(e => e ? Interest.toJSON(e) : undefined);
    } else {
      obj.interests = [];
    }
    obj.favorite = message.favorite || false;
    obj.chatRoomId = message.chatRoomId || "";
    obj.messageLastSentAt = message.messageLastSentAt || 0;
    obj.isFollowing = message.isFollowing || false;
    return obj;
  },
};

export const MissionFollow = {
  encode(message: MissionFollow, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.followeeAccountId);
    if (message.mission !== undefined && message.mission !== undefined) {
      Mission.encode(message.mission, writer.uint32(18).fork()).ldelim();
    }
    writer.uint32(24).int64(message.followedAt);
    for (const v of message.interests) {
      Interest.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    writer.uint32(40).bool(message.isFollowed);
    writer.uint32(50).string(message.chatRoomId);
    return writer;
  },
  decode(reader: Reader, length?: number): MissionFollow {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseMissionFollow) as MissionFollow;
    message.interests = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.followeeAccountId = reader.string();
          break;
        case 2:
          message.mission = Mission.decode(reader, reader.uint32());
          break;
        case 3:
          message.followedAt = longToNumber(reader.int64() as Long);
          break;
        case 4:
          message.interests.push(Interest.decode(reader, reader.uint32()));
          break;
        case 5:
          message.isFollowed = reader.bool();
          break;
        case 6:
          message.chatRoomId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): MissionFollow {
    const message = Object.create(baseMissionFollow) as MissionFollow;
    message.interests = [];
    if (object.followeeAccountId) {
      message.followeeAccountId = String(object.followeeAccountId);
    }
    if (object.mission) {
      message.mission = Mission.fromJSON(object.mission);
    }
    if (object.followedAt) {
      message.followedAt = Number(object.followedAt);
    }
    if (object.interests) {
      for (const e of object.interests) {
        message.interests.push(Interest.fromJSON(e));
      }
    }
    if (object.isFollowed) {
      message.isFollowed = Boolean(object.isFollowed);
    }
    if (object.chatRoomId) {
      message.chatRoomId = String(object.chatRoomId);
    }
    return message;
  },
  fromPartial(object: DeepPartial<MissionFollow>): MissionFollow {
    const message = Object.create(baseMissionFollow) as MissionFollow;
    message.interests = [];
    if (object.followeeAccountId) {
      message.followeeAccountId = object.followeeAccountId;
    }
    if (object.mission) {
      message.mission = Mission.fromPartial(object.mission);
    }
    if (object.followedAt) {
      message.followedAt = object.followedAt;
    }
    if (object.interests) {
      for (const e of object.interests) {
        message.interests.push(Interest.fromPartial(e));
      }
    }
    if (object.isFollowed) {
      message.isFollowed = object.isFollowed;
    }
    if (object.chatRoomId) {
      message.chatRoomId = object.chatRoomId;
    }
    return message;
  },
  toJSON(message: MissionFollow): unknown {
    const obj: any = {};
    obj.followeeAccountId = message.followeeAccountId || "";
    obj.mission = message.mission ? Mission.toJSON(message.mission) : undefined;
    obj.followedAt = message.followedAt || 0;
    if (message.interests) {
      obj.interests = message.interests.map(e => e ? Interest.toJSON(e) : undefined);
    } else {
      obj.interests = [];
    }
    obj.isFollowed = message.isFollowed || false;
    obj.chatRoomId = message.chatRoomId || "";
    return obj;
  },
};

export const AccountFollowee = {
  encode(message: AccountFollowee, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.followeeAccountId);
    writer.uint32(16).int64(message.followedAt);
    writer.uint32(24).bool(message.isFollowed);
    writer.uint32(34).string(message.chatRoomId);
    if (message.mission !== undefined && message.mission !== undefined) {
      AccountFollowee_MissionAccountFollowee.encode(message.mission, writer.uint32(42).fork()).ldelim();
    }
    if (message.supporter !== undefined && message.supporter !== undefined) {
      AccountFollowee_SupporterAccountFollowee.encode(message.supporter, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): AccountFollowee {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseAccountFollowee) as AccountFollowee;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.followeeAccountId = reader.string();
          break;
        case 2:
          message.followedAt = longToNumber(reader.int64() as Long);
          break;
        case 3:
          message.isFollowed = reader.bool();
          break;
        case 4:
          message.chatRoomId = reader.string();
          break;
        case 5:
          message.mission = AccountFollowee_MissionAccountFollowee.decode(reader, reader.uint32());
          break;
        case 6:
          message.supporter = AccountFollowee_SupporterAccountFollowee.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): AccountFollowee {
    const message = Object.create(baseAccountFollowee) as AccountFollowee;
    if (object.followeeAccountId) {
      message.followeeAccountId = String(object.followeeAccountId);
    }
    if (object.followedAt) {
      message.followedAt = Number(object.followedAt);
    }
    if (object.isFollowed) {
      message.isFollowed = Boolean(object.isFollowed);
    }
    if (object.chatRoomId) {
      message.chatRoomId = String(object.chatRoomId);
    }
    if (object.mission) {
      message.mission = AccountFollowee_MissionAccountFollowee.fromJSON(object.mission);
    }
    if (object.supporter) {
      message.supporter = AccountFollowee_SupporterAccountFollowee.fromJSON(object.supporter);
    }
    return message;
  },
  fromPartial(object: DeepPartial<AccountFollowee>): AccountFollowee {
    const message = Object.create(baseAccountFollowee) as AccountFollowee;
    if (object.followeeAccountId) {
      message.followeeAccountId = object.followeeAccountId;
    }
    if (object.followedAt) {
      message.followedAt = object.followedAt;
    }
    if (object.isFollowed) {
      message.isFollowed = object.isFollowed;
    }
    if (object.chatRoomId) {
      message.chatRoomId = object.chatRoomId;
    }
    if (object.mission) {
      message.mission = AccountFollowee_MissionAccountFollowee.fromPartial(object.mission);
    }
    if (object.supporter) {
      message.supporter = AccountFollowee_SupporterAccountFollowee.fromPartial(object.supporter);
    }
    return message;
  },
  toJSON(message: AccountFollowee): unknown {
    const obj: any = {};
    obj.followeeAccountId = message.followeeAccountId || "";
    obj.followedAt = message.followedAt || 0;
    obj.isFollowed = message.isFollowed || false;
    obj.chatRoomId = message.chatRoomId || "";
    obj.mission = message.mission ? AccountFollowee_MissionAccountFollowee.toJSON(message.mission) : undefined;
    obj.supporter = message.supporter ? AccountFollowee_SupporterAccountFollowee.toJSON(message.supporter) : undefined;
    return obj;
  },
};

export const AccountFollowee_MissionAccountFollowee = {
  encode(message: AccountFollowee_MissionAccountFollowee, writer: Writer = Writer.create()): Writer {
    if (message.mission !== undefined && message.mission !== undefined) {
      Mission.encode(message.mission, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.interests) {
      Interest.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.supportAreas) {
      SupportArea.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    writer.uint32(34).string(message.contactMessage);
    return writer;
  },
  decode(reader: Reader, length?: number): AccountFollowee_MissionAccountFollowee {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseAccountFollowee_MissionAccountFollowee) as AccountFollowee_MissionAccountFollowee;
    message.interests = [];
    message.supportAreas = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.mission = Mission.decode(reader, reader.uint32());
          break;
        case 2:
          message.interests.push(Interest.decode(reader, reader.uint32()));
          break;
        case 3:
          message.supportAreas.push(SupportArea.decode(reader, reader.uint32()));
          break;
        case 4:
          message.contactMessage = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): AccountFollowee_MissionAccountFollowee {
    const message = Object.create(baseAccountFollowee_MissionAccountFollowee) as AccountFollowee_MissionAccountFollowee;
    message.interests = [];
    message.supportAreas = [];
    if (object.mission) {
      message.mission = Mission.fromJSON(object.mission);
    }
    if (object.interests) {
      for (const e of object.interests) {
        message.interests.push(Interest.fromJSON(e));
      }
    }
    if (object.supportAreas) {
      for (const e of object.supportAreas) {
        message.supportAreas.push(SupportArea.fromJSON(e));
      }
    }
    if (object.contactMessage) {
      message.contactMessage = String(object.contactMessage);
    }
    return message;
  },
  fromPartial(object: DeepPartial<AccountFollowee_MissionAccountFollowee>): AccountFollowee_MissionAccountFollowee {
    const message = Object.create(baseAccountFollowee_MissionAccountFollowee) as AccountFollowee_MissionAccountFollowee;
    message.interests = [];
    message.supportAreas = [];
    if (object.mission) {
      message.mission = Mission.fromPartial(object.mission);
    }
    if (object.interests) {
      for (const e of object.interests) {
        message.interests.push(Interest.fromPartial(e));
      }
    }
    if (object.supportAreas) {
      for (const e of object.supportAreas) {
        message.supportAreas.push(SupportArea.fromPartial(e));
      }
    }
    if (object.contactMessage) {
      message.contactMessage = object.contactMessage;
    }
    return message;
  },
  toJSON(message: AccountFollowee_MissionAccountFollowee): unknown {
    const obj: any = {};
    obj.mission = message.mission ? Mission.toJSON(message.mission) : undefined;
    if (message.interests) {
      obj.interests = message.interests.map(e => e ? Interest.toJSON(e) : undefined);
    } else {
      obj.interests = [];
    }
    if (message.supportAreas) {
      obj.supportAreas = message.supportAreas.map(e => e ? SupportArea.toJSON(e) : undefined);
    } else {
      obj.supportAreas = [];
    }
    obj.contactMessage = message.contactMessage || "";
    return obj;
  },
};

export const AccountFollowee_SupporterAccountFollowee = {
  encode(message: AccountFollowee_SupporterAccountFollowee, writer: Writer = Writer.create()): Writer {
    if (message.supporter !== undefined && message.supporter !== undefined) {
      Supporter.encode(message.supporter, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.supportAreas) {
      SupportArea.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    writer.uint32(26).string(message.contactMessage);
    return writer;
  },
  decode(reader: Reader, length?: number): AccountFollowee_SupporterAccountFollowee {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseAccountFollowee_SupporterAccountFollowee) as AccountFollowee_SupporterAccountFollowee;
    message.supportAreas = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.supporter = Supporter.decode(reader, reader.uint32());
          break;
        case 2:
          message.supportAreas.push(SupportArea.decode(reader, reader.uint32()));
          break;
        case 3:
          message.contactMessage = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): AccountFollowee_SupporterAccountFollowee {
    const message = Object.create(baseAccountFollowee_SupporterAccountFollowee) as AccountFollowee_SupporterAccountFollowee;
    message.supportAreas = [];
    if (object.supporter) {
      message.supporter = Supporter.fromJSON(object.supporter);
    }
    if (object.supportAreas) {
      for (const e of object.supportAreas) {
        message.supportAreas.push(SupportArea.fromJSON(e));
      }
    }
    if (object.contactMessage) {
      message.contactMessage = String(object.contactMessage);
    }
    return message;
  },
  fromPartial(object: DeepPartial<AccountFollowee_SupporterAccountFollowee>): AccountFollowee_SupporterAccountFollowee {
    const message = Object.create(baseAccountFollowee_SupporterAccountFollowee) as AccountFollowee_SupporterAccountFollowee;
    message.supportAreas = [];
    if (object.supporter) {
      message.supporter = Supporter.fromPartial(object.supporter);
    }
    if (object.supportAreas) {
      for (const e of object.supportAreas) {
        message.supportAreas.push(SupportArea.fromPartial(e));
      }
    }
    if (object.contactMessage) {
      message.contactMessage = object.contactMessage;
    }
    return message;
  },
  toJSON(message: AccountFollowee_SupporterAccountFollowee): unknown {
    const obj: any = {};
    obj.supporter = message.supporter ? Supporter.toJSON(message.supporter) : undefined;
    if (message.supportAreas) {
      obj.supportAreas = message.supportAreas.map(e => e ? SupportArea.toJSON(e) : undefined);
    } else {
      obj.supportAreas = [];
    }
    obj.contactMessage = message.contactMessage || "";
    return obj;
  },
};

export const AccountFollower = {
  encode(message: AccountFollower, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.followerAccountId);
    writer.uint32(16).int64(message.followedAt);
    writer.uint32(24).bool(message.isFollowing);
    writer.uint32(34).string(message.chatRoomId);
    if (message.mission !== undefined && message.mission !== undefined) {
      AccountFollower_MissionAccountFollower.encode(message.mission, writer.uint32(42).fork()).ldelim();
    }
    if (message.supporter !== undefined && message.supporter !== undefined) {
      AccountFollower_SupporterAccountFollower.encode(message.supporter, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): AccountFollower {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseAccountFollower) as AccountFollower;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.followerAccountId = reader.string();
          break;
        case 2:
          message.followedAt = longToNumber(reader.int64() as Long);
          break;
        case 3:
          message.isFollowing = reader.bool();
          break;
        case 4:
          message.chatRoomId = reader.string();
          break;
        case 5:
          message.mission = AccountFollower_MissionAccountFollower.decode(reader, reader.uint32());
          break;
        case 6:
          message.supporter = AccountFollower_SupporterAccountFollower.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): AccountFollower {
    const message = Object.create(baseAccountFollower) as AccountFollower;
    if (object.followerAccountId) {
      message.followerAccountId = String(object.followerAccountId);
    }
    if (object.followedAt) {
      message.followedAt = Number(object.followedAt);
    }
    if (object.isFollowing) {
      message.isFollowing = Boolean(object.isFollowing);
    }
    if (object.chatRoomId) {
      message.chatRoomId = String(object.chatRoomId);
    }
    if (object.mission) {
      message.mission = AccountFollower_MissionAccountFollower.fromJSON(object.mission);
    }
    if (object.supporter) {
      message.supporter = AccountFollower_SupporterAccountFollower.fromJSON(object.supporter);
    }
    return message;
  },
  fromPartial(object: DeepPartial<AccountFollower>): AccountFollower {
    const message = Object.create(baseAccountFollower) as AccountFollower;
    if (object.followerAccountId) {
      message.followerAccountId = object.followerAccountId;
    }
    if (object.followedAt) {
      message.followedAt = object.followedAt;
    }
    if (object.isFollowing) {
      message.isFollowing = object.isFollowing;
    }
    if (object.chatRoomId) {
      message.chatRoomId = object.chatRoomId;
    }
    if (object.mission) {
      message.mission = AccountFollower_MissionAccountFollower.fromPartial(object.mission);
    }
    if (object.supporter) {
      message.supporter = AccountFollower_SupporterAccountFollower.fromPartial(object.supporter);
    }
    return message;
  },
  toJSON(message: AccountFollower): unknown {
    const obj: any = {};
    obj.followerAccountId = message.followerAccountId || "";
    obj.followedAt = message.followedAt || 0;
    obj.isFollowing = message.isFollowing || false;
    obj.chatRoomId = message.chatRoomId || "";
    obj.mission = message.mission ? AccountFollower_MissionAccountFollower.toJSON(message.mission) : undefined;
    obj.supporter = message.supporter ? AccountFollower_SupporterAccountFollower.toJSON(message.supporter) : undefined;
    return obj;
  },
};

export const AccountFollower_MissionAccountFollower = {
  encode(message: AccountFollower_MissionAccountFollower, writer: Writer = Writer.create()): Writer {
    if (message.mission !== undefined && message.mission !== undefined) {
      Mission.encode(message.mission, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.interests) {
      Interest.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.supportAreas) {
      SupportArea.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): AccountFollower_MissionAccountFollower {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseAccountFollower_MissionAccountFollower) as AccountFollower_MissionAccountFollower;
    message.interests = [];
    message.supportAreas = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.mission = Mission.decode(reader, reader.uint32());
          break;
        case 2:
          message.interests.push(Interest.decode(reader, reader.uint32()));
          break;
        case 3:
          message.supportAreas.push(SupportArea.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): AccountFollower_MissionAccountFollower {
    const message = Object.create(baseAccountFollower_MissionAccountFollower) as AccountFollower_MissionAccountFollower;
    message.interests = [];
    message.supportAreas = [];
    if (object.mission) {
      message.mission = Mission.fromJSON(object.mission);
    }
    if (object.interests) {
      for (const e of object.interests) {
        message.interests.push(Interest.fromJSON(e));
      }
    }
    if (object.supportAreas) {
      for (const e of object.supportAreas) {
        message.supportAreas.push(SupportArea.fromJSON(e));
      }
    }
    return message;
  },
  fromPartial(object: DeepPartial<AccountFollower_MissionAccountFollower>): AccountFollower_MissionAccountFollower {
    const message = Object.create(baseAccountFollower_MissionAccountFollower) as AccountFollower_MissionAccountFollower;
    message.interests = [];
    message.supportAreas = [];
    if (object.mission) {
      message.mission = Mission.fromPartial(object.mission);
    }
    if (object.interests) {
      for (const e of object.interests) {
        message.interests.push(Interest.fromPartial(e));
      }
    }
    if (object.supportAreas) {
      for (const e of object.supportAreas) {
        message.supportAreas.push(SupportArea.fromPartial(e));
      }
    }
    return message;
  },
  toJSON(message: AccountFollower_MissionAccountFollower): unknown {
    const obj: any = {};
    obj.mission = message.mission ? Mission.toJSON(message.mission) : undefined;
    if (message.interests) {
      obj.interests = message.interests.map(e => e ? Interest.toJSON(e) : undefined);
    } else {
      obj.interests = [];
    }
    if (message.supportAreas) {
      obj.supportAreas = message.supportAreas.map(e => e ? SupportArea.toJSON(e) : undefined);
    } else {
      obj.supportAreas = [];
    }
    return obj;
  },
};

export const AccountFollower_SupporterAccountFollower = {
  encode(message: AccountFollower_SupporterAccountFollower, writer: Writer = Writer.create()): Writer {
    if (message.supporter !== undefined && message.supporter !== undefined) {
      Supporter.encode(message.supporter, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.supportAreas) {
      SupportArea.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    writer.uint32(26).string(message.contactMessage);
    return writer;
  },
  decode(reader: Reader, length?: number): AccountFollower_SupporterAccountFollower {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseAccountFollower_SupporterAccountFollower) as AccountFollower_SupporterAccountFollower;
    message.supportAreas = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.supporter = Supporter.decode(reader, reader.uint32());
          break;
        case 2:
          message.supportAreas.push(SupportArea.decode(reader, reader.uint32()));
          break;
        case 3:
          message.contactMessage = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): AccountFollower_SupporterAccountFollower {
    const message = Object.create(baseAccountFollower_SupporterAccountFollower) as AccountFollower_SupporterAccountFollower;
    message.supportAreas = [];
    if (object.supporter) {
      message.supporter = Supporter.fromJSON(object.supporter);
    }
    if (object.supportAreas) {
      for (const e of object.supportAreas) {
        message.supportAreas.push(SupportArea.fromJSON(e));
      }
    }
    if (object.contactMessage) {
      message.contactMessage = String(object.contactMessage);
    }
    return message;
  },
  fromPartial(object: DeepPartial<AccountFollower_SupporterAccountFollower>): AccountFollower_SupporterAccountFollower {
    const message = Object.create(baseAccountFollower_SupporterAccountFollower) as AccountFollower_SupporterAccountFollower;
    message.supportAreas = [];
    if (object.supporter) {
      message.supporter = Supporter.fromPartial(object.supporter);
    }
    if (object.supportAreas) {
      for (const e of object.supportAreas) {
        message.supportAreas.push(SupportArea.fromPartial(e));
      }
    }
    if (object.contactMessage) {
      message.contactMessage = object.contactMessage;
    }
    return message;
  },
  toJSON(message: AccountFollower_SupporterAccountFollower): unknown {
    const obj: any = {};
    obj.supporter = message.supporter ? Supporter.toJSON(message.supporter) : undefined;
    if (message.supportAreas) {
      obj.supportAreas = message.supportAreas.map(e => e ? SupportArea.toJSON(e) : undefined);
    } else {
      obj.supportAreas = [];
    }
    obj.contactMessage = message.contactMessage || "";
    return obj;
  },
};

export const Interest = {
  encode(message: Interest, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).uint32(message.id);
    writer.uint32(18).string(message.name);
    return writer;
  },
  decode(reader: Reader, length?: number): Interest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseInterest) as Interest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.uint32();
          break;
        case 2:
          message.name = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): Interest {
    const message = Object.create(baseInterest) as Interest;
    if (object.id) {
      message.id = Number(object.id);
    }
    if (object.name) {
      message.name = String(object.name);
    }
    return message;
  },
  fromPartial(object: DeepPartial<Interest>): Interest {
    const message = Object.create(baseInterest) as Interest;
    if (object.id) {
      message.id = object.id;
    }
    if (object.name) {
      message.name = object.name;
    }
    return message;
  },
  toJSON(message: Interest): unknown {
    const obj: any = {};
    obj.id = message.id || 0;
    obj.name = message.name || "";
    return obj;
  },
};

export const Language = {
  encode(message: Language, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).int64(message.id);
    writer.uint32(18).string(message.name);
    return writer;
  },
  decode(reader: Reader, length?: number): Language {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseLanguage) as Language;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = longToNumber(reader.int64() as Long);
          break;
        case 2:
          message.name = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): Language {
    const message = Object.create(baseLanguage) as Language;
    if (object.id) {
      message.id = Number(object.id);
    }
    if (object.name) {
      message.name = String(object.name);
    }
    return message;
  },
  fromPartial(object: DeepPartial<Language>): Language {
    const message = Object.create(baseLanguage) as Language;
    if (object.id) {
      message.id = object.id;
    }
    if (object.name) {
      message.name = object.name;
    }
    return message;
  },
  toJSON(message: Language): unknown {
    const obj: any = {};
    obj.id = message.id || 0;
    obj.name = message.name || "";
    return obj;
  },
};

export const Degree = {
  encode(message: Degree, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).int64(message.id);
    writer.uint32(18).string(message.name);
    return writer;
  },
  decode(reader: Reader, length?: number): Degree {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseDegree) as Degree;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = longToNumber(reader.int64() as Long);
          break;
        case 2:
          message.name = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): Degree {
    const message = Object.create(baseDegree) as Degree;
    if (object.id) {
      message.id = Number(object.id);
    }
    if (object.name) {
      message.name = String(object.name);
    }
    return message;
  },
  fromPartial(object: DeepPartial<Degree>): Degree {
    const message = Object.create(baseDegree) as Degree;
    if (object.id) {
      message.id = object.id;
    }
    if (object.name) {
      message.name = object.name;
    }
    return message;
  },
  toJSON(message: Degree): unknown {
    const obj: any = {};
    obj.id = message.id || 0;
    obj.name = message.name || "";
    return obj;
  },
};

export const Images = {
  encode(message: Images, writer: Writer = Writer.create()): Writer {
    if (message.original !== undefined && message.original !== undefined) {
      Images_Image.encode(message.original, writer.uint32(10).fork()).ldelim();
    }
    if (message.small !== undefined && message.small !== undefined) {
      Images_Image.encode(message.small, writer.uint32(18).fork()).ldelim();
    }
    if (message.large !== undefined && message.large !== undefined) {
      Images_Image.encode(message.large, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): Images {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseImages) as Images;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.original = Images_Image.decode(reader, reader.uint32());
          break;
        case 2:
          message.small = Images_Image.decode(reader, reader.uint32());
          break;
        case 3:
          message.large = Images_Image.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): Images {
    const message = Object.create(baseImages) as Images;
    if (object.original) {
      message.original = Images_Image.fromJSON(object.original);
    }
    if (object.small) {
      message.small = Images_Image.fromJSON(object.small);
    }
    if (object.large) {
      message.large = Images_Image.fromJSON(object.large);
    }
    return message;
  },
  fromPartial(object: DeepPartial<Images>): Images {
    const message = Object.create(baseImages) as Images;
    if (object.original) {
      message.original = Images_Image.fromPartial(object.original);
    }
    if (object.small) {
      message.small = Images_Image.fromPartial(object.small);
    }
    if (object.large) {
      message.large = Images_Image.fromPartial(object.large);
    }
    return message;
  },
  toJSON(message: Images): unknown {
    const obj: any = {};
    obj.original = message.original ? Images_Image.toJSON(message.original) : undefined;
    obj.small = message.small ? Images_Image.toJSON(message.small) : undefined;
    obj.large = message.large ? Images_Image.toJSON(message.large) : undefined;
    return obj;
  },
};

export const Images_Image = {
  encode(message: Images_Image, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.url);
    writer.uint32(16).uint32(message.width);
    writer.uint32(24).uint32(message.height);
    return writer;
  },
  decode(reader: Reader, length?: number): Images_Image {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseImages_Image) as Images_Image;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.url = reader.string();
          break;
        case 2:
          message.width = reader.uint32();
          break;
        case 3:
          message.height = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): Images_Image {
    const message = Object.create(baseImages_Image) as Images_Image;
    if (object.url) {
      message.url = String(object.url);
    }
    if (object.width) {
      message.width = Number(object.width);
    }
    if (object.height) {
      message.height = Number(object.height);
    }
    return message;
  },
  fromPartial(object: DeepPartial<Images_Image>): Images_Image {
    const message = Object.create(baseImages_Image) as Images_Image;
    if (object.url) {
      message.url = object.url;
    }
    if (object.width) {
      message.width = object.width;
    }
    if (object.height) {
      message.height = object.height;
    }
    return message;
  },
  toJSON(message: Images_Image): unknown {
    const obj: any = {};
    obj.url = message.url || "";
    obj.width = message.width || 0;
    obj.height = message.height || 0;
    return obj;
  },
};

export const ImageToUpdate = {
  encode(message: ImageToUpdate, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.data);
    writer.uint32(16).bool(message.delete);
    writer.uint32(26).string(message.key);
    return writer;
  },
  decode(reader: Reader, length?: number): ImageToUpdate {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseImageToUpdate) as ImageToUpdate;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.data = reader.string();
          break;
        case 2:
          message.delete = reader.bool();
          break;
        case 3:
          message.key = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): ImageToUpdate {
    const message = Object.create(baseImageToUpdate) as ImageToUpdate;
    if (object.data) {
      message.data = String(object.data);
    }
    if (object.delete) {
      message.delete = Boolean(object.delete);
    }
    if (object.key) {
      message.key = String(object.key);
    }
    return message;
  },
  fromPartial(object: DeepPartial<ImageToUpdate>): ImageToUpdate {
    const message = Object.create(baseImageToUpdate) as ImageToUpdate;
    if (object.data) {
      message.data = object.data;
    }
    if (object.delete) {
      message.delete = object.delete;
    }
    if (object.key) {
      message.key = object.key;
    }
    return message;
  },
  toJSON(message: ImageToUpdate): unknown {
    const obj: any = {};
    obj.data = message.data || "";
    obj.delete = message.delete || false;
    obj.key = message.key || "";
    return obj;
  },
};

export const Mission = {
  encode(message: Mission, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.missionId);
    writer.uint32(18).string(message.name);
    if (message.iconImage !== undefined && message.iconImage !== undefined) {
      Images.encode(message.iconImage, writer.uint32(26).fork()).ldelim();
    }
    if (message.coverImage !== undefined && message.coverImage !== undefined) {
      Images.encode(message.coverImage, writer.uint32(34).fork()).ldelim();
    }
    writer.uint32(42).string(message.missionDescription);
    writer.uint32(50).string(message.strengthPoint);
    writer.uint32(58).string(message.companyName);
    if (message.country !== undefined && message.country !== undefined) {
      Country.encode(message.country, writer.uint32(66).fork()).ldelim();
    }
    if (message.japanPrefecture !== undefined && message.japanPrefecture !== undefined) {
      JapanPrefecture.encode(message.japanPrefecture, writer.uint32(74).fork()).ldelim();
    }
    writer.uint32(82).string(message.otherAddress);
    if (message.memberRange !== undefined && message.memberRange !== undefined) {
      MemberRange.encode(message.memberRange, writer.uint32(90).fork()).ldelim();
    }
    if (message.fund !== undefined && message.fund !== undefined) {
      Fund.encode(message.fund, writer.uint32(98).fork()).ldelim();
    }
    for (const v of message.missionCategories) {
      MissionCategory.encode(v!, writer.uint32(114).fork()).ldelim();
    }
    for (const v of message.missionMembers) {
      MissionMember.encode(v!, writer.uint32(122).fork()).ldelim();
    }
    if (message.missionDemand !== undefined && message.missionDemand !== undefined) {
      MissionDemand.encode(message.missionDemand, writer.uint32(130).fork()).ldelim();
    }
    writer.uint32(136).uint32(message.matchPercentage);
    writer.uint32(146).string(message.companyUrl);
    writer.uint32(152).int32(message.degreeOfAttention);
    for (const v of message.missionOfficeImages) {
      MissionOfficeImage.encode(v!, writer.uint32(162).fork()).ldelim();
    }
    for (const v of message.missionProducts) {
      MissionProduct.encode(v!, writer.uint32(170).fork()).ldelim();
    }
    for (const v of message.corporateAttributes) {
      CorporateAttribute.encode(v!, writer.uint32(178).fork()).ldelim();
    }
    if (message.companyEstablishedDate !== undefined && message.companyEstablishedDate !== undefined) {
      CompanyEstablishedDate.encode(message.companyEstablishedDate, writer.uint32(186).fork()).ldelim();
    }
    writer.uint32(194).string(message.language);
    for (const v of message.missionJobOffers) {
      MissionJobOffer.encode(v!, writer.uint32(202).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): Mission {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseMission) as Mission;
    message.missionCategories = [];
    message.missionMembers = [];
    message.missionOfficeImages = [];
    message.missionProducts = [];
    message.corporateAttributes = [];
    message.missionJobOffers = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.missionId = reader.string();
          break;
        case 2:
          message.name = reader.string();
          break;
        case 3:
          message.iconImage = Images.decode(reader, reader.uint32());
          break;
        case 4:
          message.coverImage = Images.decode(reader, reader.uint32());
          break;
        case 5:
          message.missionDescription = reader.string();
          break;
        case 6:
          message.strengthPoint = reader.string();
          break;
        case 7:
          message.companyName = reader.string();
          break;
        case 8:
          message.country = Country.decode(reader, reader.uint32());
          break;
        case 9:
          message.japanPrefecture = JapanPrefecture.decode(reader, reader.uint32());
          break;
        case 10:
          message.otherAddress = reader.string();
          break;
        case 11:
          message.memberRange = MemberRange.decode(reader, reader.uint32());
          break;
        case 12:
          message.fund = Fund.decode(reader, reader.uint32());
          break;
        case 14:
          message.missionCategories.push(MissionCategory.decode(reader, reader.uint32()));
          break;
        case 15:
          message.missionMembers.push(MissionMember.decode(reader, reader.uint32()));
          break;
        case 16:
          message.missionDemand = MissionDemand.decode(reader, reader.uint32());
          break;
        case 17:
          message.matchPercentage = reader.uint32();
          break;
        case 18:
          message.companyUrl = reader.string();
          break;
        case 19:
          message.degreeOfAttention = reader.int32();
          break;
        case 20:
          message.missionOfficeImages.push(MissionOfficeImage.decode(reader, reader.uint32()));
          break;
        case 21:
          message.missionProducts.push(MissionProduct.decode(reader, reader.uint32()));
          break;
        case 22:
          message.corporateAttributes.push(CorporateAttribute.decode(reader, reader.uint32()));
          break;
        case 23:
          message.companyEstablishedDate = CompanyEstablishedDate.decode(reader, reader.uint32());
          break;
        case 24:
          message.language = reader.string();
          break;
        case 25:
          message.missionJobOffers.push(MissionJobOffer.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): Mission {
    const message = Object.create(baseMission) as Mission;
    message.missionCategories = [];
    message.missionMembers = [];
    message.missionOfficeImages = [];
    message.missionProducts = [];
    message.corporateAttributes = [];
    message.missionJobOffers = [];
    if (object.missionId) {
      message.missionId = String(object.missionId);
    }
    if (object.name) {
      message.name = String(object.name);
    }
    if (object.iconImage) {
      message.iconImage = Images.fromJSON(object.iconImage);
    }
    if (object.coverImage) {
      message.coverImage = Images.fromJSON(object.coverImage);
    }
    if (object.missionDescription) {
      message.missionDescription = String(object.missionDescription);
    }
    if (object.strengthPoint) {
      message.strengthPoint = String(object.strengthPoint);
    }
    if (object.companyName) {
      message.companyName = String(object.companyName);
    }
    if (object.country) {
      message.country = Country.fromJSON(object.country);
    }
    if (object.japanPrefecture) {
      message.japanPrefecture = JapanPrefecture.fromJSON(object.japanPrefecture);
    }
    if (object.otherAddress) {
      message.otherAddress = String(object.otherAddress);
    }
    if (object.memberRange) {
      message.memberRange = MemberRange.fromJSON(object.memberRange);
    }
    if (object.fund) {
      message.fund = Fund.fromJSON(object.fund);
    }
    if (object.missionCategories) {
      for (const e of object.missionCategories) {
        message.missionCategories.push(MissionCategory.fromJSON(e));
      }
    }
    if (object.missionMembers) {
      for (const e of object.missionMembers) {
        message.missionMembers.push(MissionMember.fromJSON(e));
      }
    }
    if (object.missionDemand) {
      message.missionDemand = MissionDemand.fromJSON(object.missionDemand);
    }
    if (object.matchPercentage) {
      message.matchPercentage = Number(object.matchPercentage);
    }
    if (object.companyUrl) {
      message.companyUrl = String(object.companyUrl);
    }
    if (object.degreeOfAttention) {
      message.degreeOfAttention = Number(object.degreeOfAttention);
    }
    if (object.missionOfficeImages) {
      for (const e of object.missionOfficeImages) {
        message.missionOfficeImages.push(MissionOfficeImage.fromJSON(e));
      }
    }
    if (object.missionProducts) {
      for (const e of object.missionProducts) {
        message.missionProducts.push(MissionProduct.fromJSON(e));
      }
    }
    if (object.corporateAttributes) {
      for (const e of object.corporateAttributes) {
        message.corporateAttributes.push(CorporateAttribute.fromJSON(e));
      }
    }
    if (object.companyEstablishedDate) {
      message.companyEstablishedDate = CompanyEstablishedDate.fromJSON(object.companyEstablishedDate);
    }
    if (object.language) {
      message.language = String(object.language);
    }
    if (object.missionJobOffers) {
      for (const e of object.missionJobOffers) {
        message.missionJobOffers.push(MissionJobOffer.fromJSON(e));
      }
    }
    return message;
  },
  fromPartial(object: DeepPartial<Mission>): Mission {
    const message = Object.create(baseMission) as Mission;
    message.missionCategories = [];
    message.missionMembers = [];
    message.missionOfficeImages = [];
    message.missionProducts = [];
    message.corporateAttributes = [];
    message.missionJobOffers = [];
    if (object.missionId) {
      message.missionId = object.missionId;
    }
    if (object.name) {
      message.name = object.name;
    }
    if (object.iconImage) {
      message.iconImage = Images.fromPartial(object.iconImage);
    }
    if (object.coverImage) {
      message.coverImage = Images.fromPartial(object.coverImage);
    }
    if (object.missionDescription) {
      message.missionDescription = object.missionDescription;
    }
    if (object.strengthPoint) {
      message.strengthPoint = object.strengthPoint;
    }
    if (object.companyName) {
      message.companyName = object.companyName;
    }
    if (object.country) {
      message.country = Country.fromPartial(object.country);
    }
    if (object.japanPrefecture) {
      message.japanPrefecture = JapanPrefecture.fromPartial(object.japanPrefecture);
    }
    if (object.otherAddress) {
      message.otherAddress = object.otherAddress;
    }
    if (object.memberRange) {
      message.memberRange = MemberRange.fromPartial(object.memberRange);
    }
    if (object.fund) {
      message.fund = Fund.fromPartial(object.fund);
    }
    if (object.missionCategories) {
      for (const e of object.missionCategories) {
        message.missionCategories.push(MissionCategory.fromPartial(e));
      }
    }
    if (object.missionMembers) {
      for (const e of object.missionMembers) {
        message.missionMembers.push(MissionMember.fromPartial(e));
      }
    }
    if (object.missionDemand) {
      message.missionDemand = MissionDemand.fromPartial(object.missionDemand);
    }
    if (object.matchPercentage) {
      message.matchPercentage = object.matchPercentage;
    }
    if (object.companyUrl) {
      message.companyUrl = object.companyUrl;
    }
    if (object.degreeOfAttention) {
      message.degreeOfAttention = object.degreeOfAttention;
    }
    if (object.missionOfficeImages) {
      for (const e of object.missionOfficeImages) {
        message.missionOfficeImages.push(MissionOfficeImage.fromPartial(e));
      }
    }
    if (object.missionProducts) {
      for (const e of object.missionProducts) {
        message.missionProducts.push(MissionProduct.fromPartial(e));
      }
    }
    if (object.corporateAttributes) {
      for (const e of object.corporateAttributes) {
        message.corporateAttributes.push(CorporateAttribute.fromPartial(e));
      }
    }
    if (object.companyEstablishedDate) {
      message.companyEstablishedDate = CompanyEstablishedDate.fromPartial(object.companyEstablishedDate);
    }
    if (object.language) {
      message.language = object.language;
    }
    if (object.missionJobOffers) {
      for (const e of object.missionJobOffers) {
        message.missionJobOffers.push(MissionJobOffer.fromPartial(e));
      }
    }
    return message;
  },
  toJSON(message: Mission): unknown {
    const obj: any = {};
    obj.missionId = message.missionId || "";
    obj.name = message.name || "";
    obj.iconImage = message.iconImage ? Images.toJSON(message.iconImage) : undefined;
    obj.coverImage = message.coverImage ? Images.toJSON(message.coverImage) : undefined;
    obj.missionDescription = message.missionDescription || "";
    obj.strengthPoint = message.strengthPoint || "";
    obj.companyName = message.companyName || "";
    obj.country = message.country ? Country.toJSON(message.country) : undefined;
    obj.japanPrefecture = message.japanPrefecture ? JapanPrefecture.toJSON(message.japanPrefecture) : undefined;
    obj.otherAddress = message.otherAddress || "";
    obj.memberRange = message.memberRange ? MemberRange.toJSON(message.memberRange) : undefined;
    obj.fund = message.fund ? Fund.toJSON(message.fund) : undefined;
    if (message.missionCategories) {
      obj.missionCategories = message.missionCategories.map(e => e ? MissionCategory.toJSON(e) : undefined);
    } else {
      obj.missionCategories = [];
    }
    if (message.missionMembers) {
      obj.missionMembers = message.missionMembers.map(e => e ? MissionMember.toJSON(e) : undefined);
    } else {
      obj.missionMembers = [];
    }
    obj.missionDemand = message.missionDemand ? MissionDemand.toJSON(message.missionDemand) : undefined;
    obj.matchPercentage = message.matchPercentage || 0;
    obj.companyUrl = message.companyUrl || "";
    obj.degreeOfAttention = message.degreeOfAttention || 0;
    if (message.missionOfficeImages) {
      obj.missionOfficeImages = message.missionOfficeImages.map(e => e ? MissionOfficeImage.toJSON(e) : undefined);
    } else {
      obj.missionOfficeImages = [];
    }
    if (message.missionProducts) {
      obj.missionProducts = message.missionProducts.map(e => e ? MissionProduct.toJSON(e) : undefined);
    } else {
      obj.missionProducts = [];
    }
    if (message.corporateAttributes) {
      obj.corporateAttributes = message.corporateAttributes.map(e => e ? CorporateAttribute.toJSON(e) : undefined);
    } else {
      obj.corporateAttributes = [];
    }
    obj.companyEstablishedDate = message.companyEstablishedDate ? CompanyEstablishedDate.toJSON(message.companyEstablishedDate) : undefined;
    obj.language = message.language || "";
    if (message.missionJobOffers) {
      obj.missionJobOffers = message.missionJobOffers.map(e => e ? MissionJobOffer.toJSON(e) : undefined);
    } else {
      obj.missionJobOffers = [];
    }
    return obj;
  },
};

export const MissionDemand = {
  encode(message: MissionDemand, writer: Writer = Writer.create()): Writer {
    if (message.collaboration !== undefined && message.collaboration !== undefined) {
      MissionDemandCollaboration.encode(message.collaboration, writer.uint32(66).fork()).ldelim();
    }
    if (message.invest !== undefined && message.invest !== undefined) {
      MissionDemandInvest.encode(message.invest, writer.uint32(74).fork()).ldelim();
    }
    if (message.fundraising !== undefined && message.fundraising !== undefined) {
      MissionDemandFundraising.encode(message.fundraising, writer.uint32(82).fork()).ldelim();
    }
    if (message.serviceInformation !== undefined && message.serviceInformation !== undefined) {
      MissionDemandServiceInformation.encode(message.serviceInformation, writer.uint32(90).fork()).ldelim();
    }
    if (message.serviceSuggestion !== undefined && message.serviceSuggestion !== undefined) {
      MissionDemandServiceSuggestion.encode(message.serviceSuggestion, writer.uint32(98).fork()).ldelim();
    }
    if (message.transferOfBusiness !== undefined && message.transferOfBusiness !== undefined) {
      MissionDemandTransferOfBusiness.encode(message.transferOfBusiness, writer.uint32(106).fork()).ldelim();
    }
    if (message.transferredOfBusiness !== undefined && message.transferredOfBusiness !== undefined) {
      MissionDemandTransferredOfBusiness.encode(message.transferredOfBusiness, writer.uint32(114).fork()).ldelim();
    }
    if (message.acceptSecondment !== undefined && message.acceptSecondment !== undefined) {
      MissionDemandAcceptSecondment.encode(message.acceptSecondment, writer.uint32(122).fork()).ldelim();
    }
    if (message.sendEmployee !== undefined && message.sendEmployee !== undefined) {
      MissionDemandSendEmployee.encode(message.sendEmployee, writer.uint32(130).fork()).ldelim();
    }
    if (message.furnishedOfficeRentalAvailability !== undefined && message.furnishedOfficeRentalAvailability !== undefined) {
      MissionDemandFurnishedOfficeRentalAvailability.encode(message.furnishedOfficeRentalAvailability, writer.uint32(138).fork()).ldelim();
    }
    if (message.furnishedOfficeRentalRequest !== undefined && message.furnishedOfficeRentalRequest !== undefined) {
      MissionDemandFurnishedOfficeRentalRequest.encode(message.furnishedOfficeRentalRequest, writer.uint32(146).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): MissionDemand {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseMissionDemand) as MissionDemand;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 8:
          message.collaboration = MissionDemandCollaboration.decode(reader, reader.uint32());
          break;
        case 9:
          message.invest = MissionDemandInvest.decode(reader, reader.uint32());
          break;
        case 10:
          message.fundraising = MissionDemandFundraising.decode(reader, reader.uint32());
          break;
        case 11:
          message.serviceInformation = MissionDemandServiceInformation.decode(reader, reader.uint32());
          break;
        case 12:
          message.serviceSuggestion = MissionDemandServiceSuggestion.decode(reader, reader.uint32());
          break;
        case 13:
          message.transferOfBusiness = MissionDemandTransferOfBusiness.decode(reader, reader.uint32());
          break;
        case 14:
          message.transferredOfBusiness = MissionDemandTransferredOfBusiness.decode(reader, reader.uint32());
          break;
        case 15:
          message.acceptSecondment = MissionDemandAcceptSecondment.decode(reader, reader.uint32());
          break;
        case 16:
          message.sendEmployee = MissionDemandSendEmployee.decode(reader, reader.uint32());
          break;
        case 17:
          message.furnishedOfficeRentalAvailability = MissionDemandFurnishedOfficeRentalAvailability.decode(reader, reader.uint32());
          break;
        case 18:
          message.furnishedOfficeRentalRequest = MissionDemandFurnishedOfficeRentalRequest.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): MissionDemand {
    const message = Object.create(baseMissionDemand) as MissionDemand;
    if (object.collaboration) {
      message.collaboration = MissionDemandCollaboration.fromJSON(object.collaboration);
    }
    if (object.invest) {
      message.invest = MissionDemandInvest.fromJSON(object.invest);
    }
    if (object.fundraising) {
      message.fundraising = MissionDemandFundraising.fromJSON(object.fundraising);
    }
    if (object.serviceInformation) {
      message.serviceInformation = MissionDemandServiceInformation.fromJSON(object.serviceInformation);
    }
    if (object.serviceSuggestion) {
      message.serviceSuggestion = MissionDemandServiceSuggestion.fromJSON(object.serviceSuggestion);
    }
    if (object.transferOfBusiness) {
      message.transferOfBusiness = MissionDemandTransferOfBusiness.fromJSON(object.transferOfBusiness);
    }
    if (object.transferredOfBusiness) {
      message.transferredOfBusiness = MissionDemandTransferredOfBusiness.fromJSON(object.transferredOfBusiness);
    }
    if (object.acceptSecondment) {
      message.acceptSecondment = MissionDemandAcceptSecondment.fromJSON(object.acceptSecondment);
    }
    if (object.sendEmployee) {
      message.sendEmployee = MissionDemandSendEmployee.fromJSON(object.sendEmployee);
    }
    if (object.furnishedOfficeRentalAvailability) {
      message.furnishedOfficeRentalAvailability = MissionDemandFurnishedOfficeRentalAvailability.fromJSON(object.furnishedOfficeRentalAvailability);
    }
    if (object.furnishedOfficeRentalRequest) {
      message.furnishedOfficeRentalRequest = MissionDemandFurnishedOfficeRentalRequest.fromJSON(object.furnishedOfficeRentalRequest);
    }
    return message;
  },
  fromPartial(object: DeepPartial<MissionDemand>): MissionDemand {
    const message = Object.create(baseMissionDemand) as MissionDemand;
    if (object.collaboration) {
      message.collaboration = MissionDemandCollaboration.fromPartial(object.collaboration);
    }
    if (object.invest) {
      message.invest = MissionDemandInvest.fromPartial(object.invest);
    }
    if (object.fundraising) {
      message.fundraising = MissionDemandFundraising.fromPartial(object.fundraising);
    }
    if (object.serviceInformation) {
      message.serviceInformation = MissionDemandServiceInformation.fromPartial(object.serviceInformation);
    }
    if (object.serviceSuggestion) {
      message.serviceSuggestion = MissionDemandServiceSuggestion.fromPartial(object.serviceSuggestion);
    }
    if (object.transferOfBusiness) {
      message.transferOfBusiness = MissionDemandTransferOfBusiness.fromPartial(object.transferOfBusiness);
    }
    if (object.transferredOfBusiness) {
      message.transferredOfBusiness = MissionDemandTransferredOfBusiness.fromPartial(object.transferredOfBusiness);
    }
    if (object.acceptSecondment) {
      message.acceptSecondment = MissionDemandAcceptSecondment.fromPartial(object.acceptSecondment);
    }
    if (object.sendEmployee) {
      message.sendEmployee = MissionDemandSendEmployee.fromPartial(object.sendEmployee);
    }
    if (object.furnishedOfficeRentalAvailability) {
      message.furnishedOfficeRentalAvailability = MissionDemandFurnishedOfficeRentalAvailability.fromPartial(object.furnishedOfficeRentalAvailability);
    }
    if (object.furnishedOfficeRentalRequest) {
      message.furnishedOfficeRentalRequest = MissionDemandFurnishedOfficeRentalRequest.fromPartial(object.furnishedOfficeRentalRequest);
    }
    return message;
  },
  toJSON(message: MissionDemand): unknown {
    const obj: any = {};
    obj.collaboration = message.collaboration ? MissionDemandCollaboration.toJSON(message.collaboration) : undefined;
    obj.invest = message.invest ? MissionDemandInvest.toJSON(message.invest) : undefined;
    obj.fundraising = message.fundraising ? MissionDemandFundraising.toJSON(message.fundraising) : undefined;
    obj.serviceInformation = message.serviceInformation ? MissionDemandServiceInformation.toJSON(message.serviceInformation) : undefined;
    obj.serviceSuggestion = message.serviceSuggestion ? MissionDemandServiceSuggestion.toJSON(message.serviceSuggestion) : undefined;
    obj.transferOfBusiness = message.transferOfBusiness ? MissionDemandTransferOfBusiness.toJSON(message.transferOfBusiness) : undefined;
    obj.transferredOfBusiness = message.transferredOfBusiness ? MissionDemandTransferredOfBusiness.toJSON(message.transferredOfBusiness) : undefined;
    obj.acceptSecondment = message.acceptSecondment ? MissionDemandAcceptSecondment.toJSON(message.acceptSecondment) : undefined;
    obj.sendEmployee = message.sendEmployee ? MissionDemandSendEmployee.toJSON(message.sendEmployee) : undefined;
    obj.furnishedOfficeRentalAvailability = message.furnishedOfficeRentalAvailability ? MissionDemandFurnishedOfficeRentalAvailability.toJSON(message.furnishedOfficeRentalAvailability) : undefined;
    obj.furnishedOfficeRentalRequest = message.furnishedOfficeRentalRequest ? MissionDemandFurnishedOfficeRentalRequest.toJSON(message.furnishedOfficeRentalRequest) : undefined;
    return obj;
  },
};

export const MissionDemandCollaboration = {
  encode(message: MissionDemandCollaboration, writer: Writer = Writer.create()): Writer {
    for (const v of message.assets) {
      MissionDemandCollaborationAsset.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): MissionDemandCollaboration {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseMissionDemandCollaboration) as MissionDemandCollaboration;
    message.assets = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.assets.push(MissionDemandCollaborationAsset.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): MissionDemandCollaboration {
    const message = Object.create(baseMissionDemandCollaboration) as MissionDemandCollaboration;
    message.assets = [];
    if (object.assets) {
      for (const e of object.assets) {
        message.assets.push(MissionDemandCollaborationAsset.fromJSON(e));
      }
    }
    return message;
  },
  fromPartial(object: DeepPartial<MissionDemandCollaboration>): MissionDemandCollaboration {
    const message = Object.create(baseMissionDemandCollaboration) as MissionDemandCollaboration;
    message.assets = [];
    if (object.assets) {
      for (const e of object.assets) {
        message.assets.push(MissionDemandCollaborationAsset.fromPartial(e));
      }
    }
    return message;
  },
  toJSON(message: MissionDemandCollaboration): unknown {
    const obj: any = {};
    if (message.assets) {
      obj.assets = message.assets.map(e => e ? MissionDemandCollaborationAsset.toJSON(e) : undefined);
    } else {
      obj.assets = [];
    }
    return obj;
  },
};

export const MissionDemandCollaborationAsset = {
  encode(message: MissionDemandCollaborationAsset, writer: Writer = Writer.create()): Writer {
    if (message.give !== undefined && message.give !== undefined) {
      StringValue.encode({ value: message.give! }, writer.uint32(10).fork()).ldelim();
    }
    if (message.want !== undefined && message.want !== undefined) {
      StringValue.encode({ value: message.want! }, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): MissionDemandCollaborationAsset {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseMissionDemandCollaborationAsset) as MissionDemandCollaborationAsset;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.give = StringValue.decode(reader, reader.uint32()).value;
          break;
        case 2:
          message.want = StringValue.decode(reader, reader.uint32()).value;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): MissionDemandCollaborationAsset {
    const message = Object.create(baseMissionDemandCollaborationAsset) as MissionDemandCollaborationAsset;
    if (object.give) {
      message.give = String(object.give);
    }
    if (object.want) {
      message.want = String(object.want);
    }
    return message;
  },
  fromPartial(object: DeepPartial<MissionDemandCollaborationAsset>): MissionDemandCollaborationAsset {
    const message = Object.create(baseMissionDemandCollaborationAsset) as MissionDemandCollaborationAsset;
    if (object.give) {
      message.give = object.give;
    }
    if (object.want) {
      message.want = object.want;
    }
    return message;
  },
  toJSON(message: MissionDemandCollaborationAsset): unknown {
    const obj: any = {};
    obj.give = message.give || undefined;
    obj.want = message.want || undefined;
    return obj;
  },
};

export const MissionDemandInvest = {
  encode(message: MissionDemandInvest, writer: Writer = Writer.create()): Writer {
    for (const v of message.financingSeries) {
      FinancingSeries.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): MissionDemandInvest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseMissionDemandInvest) as MissionDemandInvest;
    message.financingSeries = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.financingSeries.push(FinancingSeries.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): MissionDemandInvest {
    const message = Object.create(baseMissionDemandInvest) as MissionDemandInvest;
    message.financingSeries = [];
    if (object.financingSeries) {
      for (const e of object.financingSeries) {
        message.financingSeries.push(FinancingSeries.fromJSON(e));
      }
    }
    return message;
  },
  fromPartial(object: DeepPartial<MissionDemandInvest>): MissionDemandInvest {
    const message = Object.create(baseMissionDemandInvest) as MissionDemandInvest;
    message.financingSeries = [];
    if (object.financingSeries) {
      for (const e of object.financingSeries) {
        message.financingSeries.push(FinancingSeries.fromPartial(e));
      }
    }
    return message;
  },
  toJSON(message: MissionDemandInvest): unknown {
    const obj: any = {};
    if (message.financingSeries) {
      obj.financingSeries = message.financingSeries.map(e => e ? FinancingSeries.toJSON(e) : undefined);
    } else {
      obj.financingSeries = [];
    }
    return obj;
  },
};

export const MissionDemandFundraising = {
  encode(message: MissionDemandFundraising, writer: Writer = Writer.create()): Writer {
    if (message.financingSeries !== undefined && message.financingSeries !== undefined) {
      FinancingSeries.encode(message.financingSeries, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): MissionDemandFundraising {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseMissionDemandFundraising) as MissionDemandFundraising;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.financingSeries = FinancingSeries.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): MissionDemandFundraising {
    const message = Object.create(baseMissionDemandFundraising) as MissionDemandFundraising;
    if (object.financingSeries) {
      message.financingSeries = FinancingSeries.fromJSON(object.financingSeries);
    }
    return message;
  },
  fromPartial(object: DeepPartial<MissionDemandFundraising>): MissionDemandFundraising {
    const message = Object.create(baseMissionDemandFundraising) as MissionDemandFundraising;
    if (object.financingSeries) {
      message.financingSeries = FinancingSeries.fromPartial(object.financingSeries);
    }
    return message;
  },
  toJSON(message: MissionDemandFundraising): unknown {
    const obj: any = {};
    obj.financingSeries = message.financingSeries ? FinancingSeries.toJSON(message.financingSeries) : undefined;
    return obj;
  },
};

export const MissionDemandServiceInformation = {
  encode(message: MissionDemandServiceInformation, writer: Writer = Writer.create()): Writer {
    for (const v of message.issues) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },
  decode(reader: Reader, length?: number): MissionDemandServiceInformation {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseMissionDemandServiceInformation) as MissionDemandServiceInformation;
    message.issues = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.issues.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): MissionDemandServiceInformation {
    const message = Object.create(baseMissionDemandServiceInformation) as MissionDemandServiceInformation;
    message.issues = [];
    if (object.issues) {
      for (const e of object.issues) {
        message.issues.push(String(e));
      }
    }
    return message;
  },
  fromPartial(object: DeepPartial<MissionDemandServiceInformation>): MissionDemandServiceInformation {
    const message = Object.create(baseMissionDemandServiceInformation) as MissionDemandServiceInformation;
    message.issues = [];
    if (object.issues) {
      for (const e of object.issues) {
        message.issues.push(e);
      }
    }
    return message;
  },
  toJSON(message: MissionDemandServiceInformation): unknown {
    const obj: any = {};
    if (message.issues) {
      obj.issues = message.issues.map(e => e || "");
    } else {
      obj.issues = [];
    }
    return obj;
  },
};

export const MissionDemandServiceSuggestion = {
  encode(message: MissionDemandServiceSuggestion, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): MissionDemandServiceSuggestion {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseMissionDemandServiceSuggestion) as MissionDemandServiceSuggestion;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): MissionDemandServiceSuggestion {
    const message = Object.create(baseMissionDemandServiceSuggestion) as MissionDemandServiceSuggestion;
    return message;
  },
  fromPartial(object: DeepPartial<MissionDemandServiceSuggestion>): MissionDemandServiceSuggestion {
    const message = Object.create(baseMissionDemandServiceSuggestion) as MissionDemandServiceSuggestion;
    return message;
  },
  toJSON(message: MissionDemandServiceSuggestion): unknown {
    const obj: any = {};
    return obj;
  },
};

export const MissionDemandTransferOfBusiness = {
  encode(message: MissionDemandTransferOfBusiness, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): MissionDemandTransferOfBusiness {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseMissionDemandTransferOfBusiness) as MissionDemandTransferOfBusiness;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): MissionDemandTransferOfBusiness {
    const message = Object.create(baseMissionDemandTransferOfBusiness) as MissionDemandTransferOfBusiness;
    return message;
  },
  fromPartial(object: DeepPartial<MissionDemandTransferOfBusiness>): MissionDemandTransferOfBusiness {
    const message = Object.create(baseMissionDemandTransferOfBusiness) as MissionDemandTransferOfBusiness;
    return message;
  },
  toJSON(message: MissionDemandTransferOfBusiness): unknown {
    const obj: any = {};
    return obj;
  },
};

export const MissionDemandTransferredOfBusiness = {
  encode(message: MissionDemandTransferredOfBusiness, writer: Writer = Writer.create()): Writer {
    if (message.fund !== undefined && message.fund !== undefined) {
      Fund.encode(message.fund, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): MissionDemandTransferredOfBusiness {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseMissionDemandTransferredOfBusiness) as MissionDemandTransferredOfBusiness;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.fund = Fund.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): MissionDemandTransferredOfBusiness {
    const message = Object.create(baseMissionDemandTransferredOfBusiness) as MissionDemandTransferredOfBusiness;
    if (object.fund) {
      message.fund = Fund.fromJSON(object.fund);
    }
    return message;
  },
  fromPartial(object: DeepPartial<MissionDemandTransferredOfBusiness>): MissionDemandTransferredOfBusiness {
    const message = Object.create(baseMissionDemandTransferredOfBusiness) as MissionDemandTransferredOfBusiness;
    if (object.fund) {
      message.fund = Fund.fromPartial(object.fund);
    }
    return message;
  },
  toJSON(message: MissionDemandTransferredOfBusiness): unknown {
    const obj: any = {};
    obj.fund = message.fund ? Fund.toJSON(message.fund) : undefined;
    return obj;
  },
};

export const MissionDemandAcceptSecondment = {
  encode(message: MissionDemandAcceptSecondment, writer: Writer = Writer.create()): Writer {
    if (message.work !== undefined && message.work !== undefined) {
      StringValue.encode({ value: message.work! }, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): MissionDemandAcceptSecondment {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseMissionDemandAcceptSecondment) as MissionDemandAcceptSecondment;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.work = StringValue.decode(reader, reader.uint32()).value;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): MissionDemandAcceptSecondment {
    const message = Object.create(baseMissionDemandAcceptSecondment) as MissionDemandAcceptSecondment;
    if (object.work) {
      message.work = String(object.work);
    }
    return message;
  },
  fromPartial(object: DeepPartial<MissionDemandAcceptSecondment>): MissionDemandAcceptSecondment {
    const message = Object.create(baseMissionDemandAcceptSecondment) as MissionDemandAcceptSecondment;
    if (object.work) {
      message.work = object.work;
    }
    return message;
  },
  toJSON(message: MissionDemandAcceptSecondment): unknown {
    const obj: any = {};
    obj.work = message.work || undefined;
    return obj;
  },
};

export const MissionDemandSendEmployee = {
  encode(message: MissionDemandSendEmployee, writer: Writer = Writer.create()): Writer {
    if (message.work !== undefined && message.work !== undefined) {
      StringValue.encode({ value: message.work! }, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): MissionDemandSendEmployee {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseMissionDemandSendEmployee) as MissionDemandSendEmployee;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.work = StringValue.decode(reader, reader.uint32()).value;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): MissionDemandSendEmployee {
    const message = Object.create(baseMissionDemandSendEmployee) as MissionDemandSendEmployee;
    if (object.work) {
      message.work = String(object.work);
    }
    return message;
  },
  fromPartial(object: DeepPartial<MissionDemandSendEmployee>): MissionDemandSendEmployee {
    const message = Object.create(baseMissionDemandSendEmployee) as MissionDemandSendEmployee;
    if (object.work) {
      message.work = object.work;
    }
    return message;
  },
  toJSON(message: MissionDemandSendEmployee): unknown {
    const obj: any = {};
    obj.work = message.work || undefined;
    return obj;
  },
};

export const MissionDemandFurnishedOfficeRentalAvailability = {
  encode(message: MissionDemandFurnishedOfficeRentalAvailability, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): MissionDemandFurnishedOfficeRentalAvailability {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseMissionDemandFurnishedOfficeRentalAvailability) as MissionDemandFurnishedOfficeRentalAvailability;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): MissionDemandFurnishedOfficeRentalAvailability {
    const message = Object.create(baseMissionDemandFurnishedOfficeRentalAvailability) as MissionDemandFurnishedOfficeRentalAvailability;
    return message;
  },
  fromPartial(object: DeepPartial<MissionDemandFurnishedOfficeRentalAvailability>): MissionDemandFurnishedOfficeRentalAvailability {
    const message = Object.create(baseMissionDemandFurnishedOfficeRentalAvailability) as MissionDemandFurnishedOfficeRentalAvailability;
    return message;
  },
  toJSON(message: MissionDemandFurnishedOfficeRentalAvailability): unknown {
    const obj: any = {};
    return obj;
  },
};

export const MissionDemandFurnishedOfficeRentalRequest = {
  encode(message: MissionDemandFurnishedOfficeRentalRequest, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): MissionDemandFurnishedOfficeRentalRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseMissionDemandFurnishedOfficeRentalRequest) as MissionDemandFurnishedOfficeRentalRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): MissionDemandFurnishedOfficeRentalRequest {
    const message = Object.create(baseMissionDemandFurnishedOfficeRentalRequest) as MissionDemandFurnishedOfficeRentalRequest;
    return message;
  },
  fromPartial(object: DeepPartial<MissionDemandFurnishedOfficeRentalRequest>): MissionDemandFurnishedOfficeRentalRequest {
    const message = Object.create(baseMissionDemandFurnishedOfficeRentalRequest) as MissionDemandFurnishedOfficeRentalRequest;
    return message;
  },
  toJSON(message: MissionDemandFurnishedOfficeRentalRequest): unknown {
    const obj: any = {};
    return obj;
  },
};

export const MissionDemandToUpdate = {
  encode(message: MissionDemandToUpdate, writer: Writer = Writer.create()): Writer {
    if (message.collaboration !== undefined && message.collaboration !== undefined) {
      MissionDemandCollaborationToUpdate.encode(message.collaboration, writer.uint32(66).fork()).ldelim();
    }
    if (message.invest !== undefined && message.invest !== undefined) {
      MissionDemandInvestToUpdate.encode(message.invest, writer.uint32(74).fork()).ldelim();
    }
    if (message.fundraising !== undefined && message.fundraising !== undefined) {
      MissionDemandFundraisingToUpdate.encode(message.fundraising, writer.uint32(82).fork()).ldelim();
    }
    if (message.serviceInformation !== undefined && message.serviceInformation !== undefined) {
      MissionDemandServiceInformationToUpdate.encode(message.serviceInformation, writer.uint32(90).fork()).ldelim();
    }
    if (message.serviceSuggestion !== undefined && message.serviceSuggestion !== undefined) {
      MissionDemandServiceSuggestionToUpdate.encode(message.serviceSuggestion, writer.uint32(98).fork()).ldelim();
    }
    if (message.transferOfBusiness !== undefined && message.transferOfBusiness !== undefined) {
      MissionDemandTransferOfBusinessToUpdate.encode(message.transferOfBusiness, writer.uint32(106).fork()).ldelim();
    }
    if (message.transferredOfBusiness !== undefined && message.transferredOfBusiness !== undefined) {
      MissionDemandTransferredOfBusinessToUpdate.encode(message.transferredOfBusiness, writer.uint32(114).fork()).ldelim();
    }
    if (message.acceptSecondment !== undefined && message.acceptSecondment !== undefined) {
      MissionDemandAcceptSecondmentToUpdate.encode(message.acceptSecondment, writer.uint32(122).fork()).ldelim();
    }
    if (message.sendEmployee !== undefined && message.sendEmployee !== undefined) {
      MissionDemandSendEmployeeToUpdate.encode(message.sendEmployee, writer.uint32(130).fork()).ldelim();
    }
    if (message.furnishedOfficeRentalAvailability !== undefined && message.furnishedOfficeRentalAvailability !== undefined) {
      MissionDemandFurnishedOfficeRentalAvailabilityToUpdate.encode(message.furnishedOfficeRentalAvailability, writer.uint32(138).fork()).ldelim();
    }
    if (message.furnishedOfficeRentalRequest !== undefined && message.furnishedOfficeRentalRequest !== undefined) {
      MissionDemandFurnishedOfficeRentalRequestToUpdate.encode(message.furnishedOfficeRentalRequest, writer.uint32(146).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): MissionDemandToUpdate {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseMissionDemandToUpdate) as MissionDemandToUpdate;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 8:
          message.collaboration = MissionDemandCollaborationToUpdate.decode(reader, reader.uint32());
          break;
        case 9:
          message.invest = MissionDemandInvestToUpdate.decode(reader, reader.uint32());
          break;
        case 10:
          message.fundraising = MissionDemandFundraisingToUpdate.decode(reader, reader.uint32());
          break;
        case 11:
          message.serviceInformation = MissionDemandServiceInformationToUpdate.decode(reader, reader.uint32());
          break;
        case 12:
          message.serviceSuggestion = MissionDemandServiceSuggestionToUpdate.decode(reader, reader.uint32());
          break;
        case 13:
          message.transferOfBusiness = MissionDemandTransferOfBusinessToUpdate.decode(reader, reader.uint32());
          break;
        case 14:
          message.transferredOfBusiness = MissionDemandTransferredOfBusinessToUpdate.decode(reader, reader.uint32());
          break;
        case 15:
          message.acceptSecondment = MissionDemandAcceptSecondmentToUpdate.decode(reader, reader.uint32());
          break;
        case 16:
          message.sendEmployee = MissionDemandSendEmployeeToUpdate.decode(reader, reader.uint32());
          break;
        case 17:
          message.furnishedOfficeRentalAvailability = MissionDemandFurnishedOfficeRentalAvailabilityToUpdate.decode(reader, reader.uint32());
          break;
        case 18:
          message.furnishedOfficeRentalRequest = MissionDemandFurnishedOfficeRentalRequestToUpdate.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): MissionDemandToUpdate {
    const message = Object.create(baseMissionDemandToUpdate) as MissionDemandToUpdate;
    if (object.collaboration) {
      message.collaboration = MissionDemandCollaborationToUpdate.fromJSON(object.collaboration);
    }
    if (object.invest) {
      message.invest = MissionDemandInvestToUpdate.fromJSON(object.invest);
    }
    if (object.fundraising) {
      message.fundraising = MissionDemandFundraisingToUpdate.fromJSON(object.fundraising);
    }
    if (object.serviceInformation) {
      message.serviceInformation = MissionDemandServiceInformationToUpdate.fromJSON(object.serviceInformation);
    }
    if (object.serviceSuggestion) {
      message.serviceSuggestion = MissionDemandServiceSuggestionToUpdate.fromJSON(object.serviceSuggestion);
    }
    if (object.transferOfBusiness) {
      message.transferOfBusiness = MissionDemandTransferOfBusinessToUpdate.fromJSON(object.transferOfBusiness);
    }
    if (object.transferredOfBusiness) {
      message.transferredOfBusiness = MissionDemandTransferredOfBusinessToUpdate.fromJSON(object.transferredOfBusiness);
    }
    if (object.acceptSecondment) {
      message.acceptSecondment = MissionDemandAcceptSecondmentToUpdate.fromJSON(object.acceptSecondment);
    }
    if (object.sendEmployee) {
      message.sendEmployee = MissionDemandSendEmployeeToUpdate.fromJSON(object.sendEmployee);
    }
    if (object.furnishedOfficeRentalAvailability) {
      message.furnishedOfficeRentalAvailability = MissionDemandFurnishedOfficeRentalAvailabilityToUpdate.fromJSON(object.furnishedOfficeRentalAvailability);
    }
    if (object.furnishedOfficeRentalRequest) {
      message.furnishedOfficeRentalRequest = MissionDemandFurnishedOfficeRentalRequestToUpdate.fromJSON(object.furnishedOfficeRentalRequest);
    }
    return message;
  },
  fromPartial(object: DeepPartial<MissionDemandToUpdate>): MissionDemandToUpdate {
    const message = Object.create(baseMissionDemandToUpdate) as MissionDemandToUpdate;
    if (object.collaboration) {
      message.collaboration = MissionDemandCollaborationToUpdate.fromPartial(object.collaboration);
    }
    if (object.invest) {
      message.invest = MissionDemandInvestToUpdate.fromPartial(object.invest);
    }
    if (object.fundraising) {
      message.fundraising = MissionDemandFundraisingToUpdate.fromPartial(object.fundraising);
    }
    if (object.serviceInformation) {
      message.serviceInformation = MissionDemandServiceInformationToUpdate.fromPartial(object.serviceInformation);
    }
    if (object.serviceSuggestion) {
      message.serviceSuggestion = MissionDemandServiceSuggestionToUpdate.fromPartial(object.serviceSuggestion);
    }
    if (object.transferOfBusiness) {
      message.transferOfBusiness = MissionDemandTransferOfBusinessToUpdate.fromPartial(object.transferOfBusiness);
    }
    if (object.transferredOfBusiness) {
      message.transferredOfBusiness = MissionDemandTransferredOfBusinessToUpdate.fromPartial(object.transferredOfBusiness);
    }
    if (object.acceptSecondment) {
      message.acceptSecondment = MissionDemandAcceptSecondmentToUpdate.fromPartial(object.acceptSecondment);
    }
    if (object.sendEmployee) {
      message.sendEmployee = MissionDemandSendEmployeeToUpdate.fromPartial(object.sendEmployee);
    }
    if (object.furnishedOfficeRentalAvailability) {
      message.furnishedOfficeRentalAvailability = MissionDemandFurnishedOfficeRentalAvailabilityToUpdate.fromPartial(object.furnishedOfficeRentalAvailability);
    }
    if (object.furnishedOfficeRentalRequest) {
      message.furnishedOfficeRentalRequest = MissionDemandFurnishedOfficeRentalRequestToUpdate.fromPartial(object.furnishedOfficeRentalRequest);
    }
    return message;
  },
  toJSON(message: MissionDemandToUpdate): unknown {
    const obj: any = {};
    obj.collaboration = message.collaboration ? MissionDemandCollaborationToUpdate.toJSON(message.collaboration) : undefined;
    obj.invest = message.invest ? MissionDemandInvestToUpdate.toJSON(message.invest) : undefined;
    obj.fundraising = message.fundraising ? MissionDemandFundraisingToUpdate.toJSON(message.fundraising) : undefined;
    obj.serviceInformation = message.serviceInformation ? MissionDemandServiceInformationToUpdate.toJSON(message.serviceInformation) : undefined;
    obj.serviceSuggestion = message.serviceSuggestion ? MissionDemandServiceSuggestionToUpdate.toJSON(message.serviceSuggestion) : undefined;
    obj.transferOfBusiness = message.transferOfBusiness ? MissionDemandTransferOfBusinessToUpdate.toJSON(message.transferOfBusiness) : undefined;
    obj.transferredOfBusiness = message.transferredOfBusiness ? MissionDemandTransferredOfBusinessToUpdate.toJSON(message.transferredOfBusiness) : undefined;
    obj.acceptSecondment = message.acceptSecondment ? MissionDemandAcceptSecondmentToUpdate.toJSON(message.acceptSecondment) : undefined;
    obj.sendEmployee = message.sendEmployee ? MissionDemandSendEmployeeToUpdate.toJSON(message.sendEmployee) : undefined;
    obj.furnishedOfficeRentalAvailability = message.furnishedOfficeRentalAvailability ? MissionDemandFurnishedOfficeRentalAvailabilityToUpdate.toJSON(message.furnishedOfficeRentalAvailability) : undefined;
    obj.furnishedOfficeRentalRequest = message.furnishedOfficeRentalRequest ? MissionDemandFurnishedOfficeRentalRequestToUpdate.toJSON(message.furnishedOfficeRentalRequest) : undefined;
    return obj;
  },
};

export const MissionDemandCollaborationToUpdate = {
  encode(message: MissionDemandCollaborationToUpdate, writer: Writer = Writer.create()): Writer {
    for (const v of message.assets) {
      MissionDemandCollaborationAsset.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): MissionDemandCollaborationToUpdate {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseMissionDemandCollaborationToUpdate) as MissionDemandCollaborationToUpdate;
    message.assets = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.assets.push(MissionDemandCollaborationAsset.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): MissionDemandCollaborationToUpdate {
    const message = Object.create(baseMissionDemandCollaborationToUpdate) as MissionDemandCollaborationToUpdate;
    message.assets = [];
    if (object.assets) {
      for (const e of object.assets) {
        message.assets.push(MissionDemandCollaborationAsset.fromJSON(e));
      }
    }
    return message;
  },
  fromPartial(object: DeepPartial<MissionDemandCollaborationToUpdate>): MissionDemandCollaborationToUpdate {
    const message = Object.create(baseMissionDemandCollaborationToUpdate) as MissionDemandCollaborationToUpdate;
    message.assets = [];
    if (object.assets) {
      for (const e of object.assets) {
        message.assets.push(MissionDemandCollaborationAsset.fromPartial(e));
      }
    }
    return message;
  },
  toJSON(message: MissionDemandCollaborationToUpdate): unknown {
    const obj: any = {};
    if (message.assets) {
      obj.assets = message.assets.map(e => e ? MissionDemandCollaborationAsset.toJSON(e) : undefined);
    } else {
      obj.assets = [];
    }
    return obj;
  },
};

export const MissionDemandInvestToUpdate = {
  encode(message: MissionDemandInvestToUpdate, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).fork();
    for (const v of message.financingSeriesId) {
      writer.uint32(v);
    }
    writer.ldelim();
    return writer;
  },
  decode(reader: Reader, length?: number): MissionDemandInvestToUpdate {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseMissionDemandInvestToUpdate) as MissionDemandInvestToUpdate;
    message.financingSeriesId = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.financingSeriesId.push(reader.uint32());
            }
          } else {
            message.financingSeriesId.push(reader.uint32());
          }
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): MissionDemandInvestToUpdate {
    const message = Object.create(baseMissionDemandInvestToUpdate) as MissionDemandInvestToUpdate;
    message.financingSeriesId = [];
    if (object.financingSeriesId) {
      for (const e of object.financingSeriesId) {
        message.financingSeriesId.push(Number(e));
      }
    }
    return message;
  },
  fromPartial(object: DeepPartial<MissionDemandInvestToUpdate>): MissionDemandInvestToUpdate {
    const message = Object.create(baseMissionDemandInvestToUpdate) as MissionDemandInvestToUpdate;
    message.financingSeriesId = [];
    if (object.financingSeriesId) {
      for (const e of object.financingSeriesId) {
        message.financingSeriesId.push(e);
      }
    }
    return message;
  },
  toJSON(message: MissionDemandInvestToUpdate): unknown {
    const obj: any = {};
    if (message.financingSeriesId) {
      obj.financingSeriesId = message.financingSeriesId.map(e => e || 0);
    } else {
      obj.financingSeriesId = [];
    }
    return obj;
  },
};

export const MissionDemandFundraisingToUpdate = {
  encode(message: MissionDemandFundraisingToUpdate, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).uint32(message.financingSeriesId);
    return writer;
  },
  decode(reader: Reader, length?: number): MissionDemandFundraisingToUpdate {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseMissionDemandFundraisingToUpdate) as MissionDemandFundraisingToUpdate;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.financingSeriesId = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): MissionDemandFundraisingToUpdate {
    const message = Object.create(baseMissionDemandFundraisingToUpdate) as MissionDemandFundraisingToUpdate;
    if (object.financingSeriesId) {
      message.financingSeriesId = Number(object.financingSeriesId);
    }
    return message;
  },
  fromPartial(object: DeepPartial<MissionDemandFundraisingToUpdate>): MissionDemandFundraisingToUpdate {
    const message = Object.create(baseMissionDemandFundraisingToUpdate) as MissionDemandFundraisingToUpdate;
    if (object.financingSeriesId) {
      message.financingSeriesId = object.financingSeriesId;
    }
    return message;
  },
  toJSON(message: MissionDemandFundraisingToUpdate): unknown {
    const obj: any = {};
    obj.financingSeriesId = message.financingSeriesId || 0;
    return obj;
  },
};

export const MissionDemandServiceInformationToUpdate = {
  encode(message: MissionDemandServiceInformationToUpdate, writer: Writer = Writer.create()): Writer {
    for (const v of message.issues) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },
  decode(reader: Reader, length?: number): MissionDemandServiceInformationToUpdate {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseMissionDemandServiceInformationToUpdate) as MissionDemandServiceInformationToUpdate;
    message.issues = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.issues.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): MissionDemandServiceInformationToUpdate {
    const message = Object.create(baseMissionDemandServiceInformationToUpdate) as MissionDemandServiceInformationToUpdate;
    message.issues = [];
    if (object.issues) {
      for (const e of object.issues) {
        message.issues.push(String(e));
      }
    }
    return message;
  },
  fromPartial(object: DeepPartial<MissionDemandServiceInformationToUpdate>): MissionDemandServiceInformationToUpdate {
    const message = Object.create(baseMissionDemandServiceInformationToUpdate) as MissionDemandServiceInformationToUpdate;
    message.issues = [];
    if (object.issues) {
      for (const e of object.issues) {
        message.issues.push(e);
      }
    }
    return message;
  },
  toJSON(message: MissionDemandServiceInformationToUpdate): unknown {
    const obj: any = {};
    if (message.issues) {
      obj.issues = message.issues.map(e => e || "");
    } else {
      obj.issues = [];
    }
    return obj;
  },
};

export const MissionDemandServiceSuggestionToUpdate = {
  encode(message: MissionDemandServiceSuggestionToUpdate, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): MissionDemandServiceSuggestionToUpdate {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseMissionDemandServiceSuggestionToUpdate) as MissionDemandServiceSuggestionToUpdate;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): MissionDemandServiceSuggestionToUpdate {
    const message = Object.create(baseMissionDemandServiceSuggestionToUpdate) as MissionDemandServiceSuggestionToUpdate;
    return message;
  },
  fromPartial(object: DeepPartial<MissionDemandServiceSuggestionToUpdate>): MissionDemandServiceSuggestionToUpdate {
    const message = Object.create(baseMissionDemandServiceSuggestionToUpdate) as MissionDemandServiceSuggestionToUpdate;
    return message;
  },
  toJSON(message: MissionDemandServiceSuggestionToUpdate): unknown {
    const obj: any = {};
    return obj;
  },
};

export const MissionDemandTransferOfBusinessToUpdate = {
  encode(message: MissionDemandTransferOfBusinessToUpdate, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): MissionDemandTransferOfBusinessToUpdate {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseMissionDemandTransferOfBusinessToUpdate) as MissionDemandTransferOfBusinessToUpdate;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): MissionDemandTransferOfBusinessToUpdate {
    const message = Object.create(baseMissionDemandTransferOfBusinessToUpdate) as MissionDemandTransferOfBusinessToUpdate;
    return message;
  },
  fromPartial(object: DeepPartial<MissionDemandTransferOfBusinessToUpdate>): MissionDemandTransferOfBusinessToUpdate {
    const message = Object.create(baseMissionDemandTransferOfBusinessToUpdate) as MissionDemandTransferOfBusinessToUpdate;
    return message;
  },
  toJSON(message: MissionDemandTransferOfBusinessToUpdate): unknown {
    const obj: any = {};
    return obj;
  },
};

export const MissionDemandTransferredOfBusinessToUpdate = {
  encode(message: MissionDemandTransferredOfBusinessToUpdate, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).uint32(message.fundId);
    return writer;
  },
  decode(reader: Reader, length?: number): MissionDemandTransferredOfBusinessToUpdate {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseMissionDemandTransferredOfBusinessToUpdate) as MissionDemandTransferredOfBusinessToUpdate;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.fundId = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): MissionDemandTransferredOfBusinessToUpdate {
    const message = Object.create(baseMissionDemandTransferredOfBusinessToUpdate) as MissionDemandTransferredOfBusinessToUpdate;
    if (object.fundId) {
      message.fundId = Number(object.fundId);
    }
    return message;
  },
  fromPartial(object: DeepPartial<MissionDemandTransferredOfBusinessToUpdate>): MissionDemandTransferredOfBusinessToUpdate {
    const message = Object.create(baseMissionDemandTransferredOfBusinessToUpdate) as MissionDemandTransferredOfBusinessToUpdate;
    if (object.fundId) {
      message.fundId = object.fundId;
    }
    return message;
  },
  toJSON(message: MissionDemandTransferredOfBusinessToUpdate): unknown {
    const obj: any = {};
    obj.fundId = message.fundId || 0;
    return obj;
  },
};

export const MissionDemandAcceptSecondmentToUpdate = {
  encode(message: MissionDemandAcceptSecondmentToUpdate, writer: Writer = Writer.create()): Writer {
    if (message.work !== undefined && message.work !== undefined) {
      StringValue.encode({ value: message.work! }, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): MissionDemandAcceptSecondmentToUpdate {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseMissionDemandAcceptSecondmentToUpdate) as MissionDemandAcceptSecondmentToUpdate;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.work = StringValue.decode(reader, reader.uint32()).value;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): MissionDemandAcceptSecondmentToUpdate {
    const message = Object.create(baseMissionDemandAcceptSecondmentToUpdate) as MissionDemandAcceptSecondmentToUpdate;
    if (object.work) {
      message.work = String(object.work);
    }
    return message;
  },
  fromPartial(object: DeepPartial<MissionDemandAcceptSecondmentToUpdate>): MissionDemandAcceptSecondmentToUpdate {
    const message = Object.create(baseMissionDemandAcceptSecondmentToUpdate) as MissionDemandAcceptSecondmentToUpdate;
    if (object.work) {
      message.work = object.work;
    }
    return message;
  },
  toJSON(message: MissionDemandAcceptSecondmentToUpdate): unknown {
    const obj: any = {};
    obj.work = message.work || undefined;
    return obj;
  },
};

export const MissionDemandSendEmployeeToUpdate = {
  encode(message: MissionDemandSendEmployeeToUpdate, writer: Writer = Writer.create()): Writer {
    if (message.work !== undefined && message.work !== undefined) {
      StringValue.encode({ value: message.work! }, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): MissionDemandSendEmployeeToUpdate {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseMissionDemandSendEmployeeToUpdate) as MissionDemandSendEmployeeToUpdate;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.work = StringValue.decode(reader, reader.uint32()).value;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): MissionDemandSendEmployeeToUpdate {
    const message = Object.create(baseMissionDemandSendEmployeeToUpdate) as MissionDemandSendEmployeeToUpdate;
    if (object.work) {
      message.work = String(object.work);
    }
    return message;
  },
  fromPartial(object: DeepPartial<MissionDemandSendEmployeeToUpdate>): MissionDemandSendEmployeeToUpdate {
    const message = Object.create(baseMissionDemandSendEmployeeToUpdate) as MissionDemandSendEmployeeToUpdate;
    if (object.work) {
      message.work = object.work;
    }
    return message;
  },
  toJSON(message: MissionDemandSendEmployeeToUpdate): unknown {
    const obj: any = {};
    obj.work = message.work || undefined;
    return obj;
  },
};

export const MissionDemandFurnishedOfficeRentalAvailabilityToUpdate = {
  encode(message: MissionDemandFurnishedOfficeRentalAvailabilityToUpdate, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): MissionDemandFurnishedOfficeRentalAvailabilityToUpdate {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseMissionDemandFurnishedOfficeRentalAvailabilityToUpdate) as MissionDemandFurnishedOfficeRentalAvailabilityToUpdate;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): MissionDemandFurnishedOfficeRentalAvailabilityToUpdate {
    const message = Object.create(baseMissionDemandFurnishedOfficeRentalAvailabilityToUpdate) as MissionDemandFurnishedOfficeRentalAvailabilityToUpdate;
    return message;
  },
  fromPartial(object: DeepPartial<MissionDemandFurnishedOfficeRentalAvailabilityToUpdate>): MissionDemandFurnishedOfficeRentalAvailabilityToUpdate {
    const message = Object.create(baseMissionDemandFurnishedOfficeRentalAvailabilityToUpdate) as MissionDemandFurnishedOfficeRentalAvailabilityToUpdate;
    return message;
  },
  toJSON(message: MissionDemandFurnishedOfficeRentalAvailabilityToUpdate): unknown {
    const obj: any = {};
    return obj;
  },
};

export const MissionDemandFurnishedOfficeRentalRequestToUpdate = {
  encode(message: MissionDemandFurnishedOfficeRentalRequestToUpdate, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): MissionDemandFurnishedOfficeRentalRequestToUpdate {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseMissionDemandFurnishedOfficeRentalRequestToUpdate) as MissionDemandFurnishedOfficeRentalRequestToUpdate;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): MissionDemandFurnishedOfficeRentalRequestToUpdate {
    const message = Object.create(baseMissionDemandFurnishedOfficeRentalRequestToUpdate) as MissionDemandFurnishedOfficeRentalRequestToUpdate;
    return message;
  },
  fromPartial(object: DeepPartial<MissionDemandFurnishedOfficeRentalRequestToUpdate>): MissionDemandFurnishedOfficeRentalRequestToUpdate {
    const message = Object.create(baseMissionDemandFurnishedOfficeRentalRequestToUpdate) as MissionDemandFurnishedOfficeRentalRequestToUpdate;
    return message;
  },
  toJSON(message: MissionDemandFurnishedOfficeRentalRequestToUpdate): unknown {
    const obj: any = {};
    return obj;
  },
};

export const MissionCategory = {
  encode(message: MissionCategory, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).uint32(message.categoryId);
    writer.uint32(18).string(message.name);
    writer.uint32(26).string(message.backgroundColor);
    writer.uint32(34).string(message.inactiveTextColor);
    writer.uint32(42).string(message.inactiveBackgroundColor);
    writer.uint32(48).uint32(message.displayOrder);
    return writer;
  },
  decode(reader: Reader, length?: number): MissionCategory {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseMissionCategory) as MissionCategory;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.categoryId = reader.uint32();
          break;
        case 2:
          message.name = reader.string();
          break;
        case 3:
          message.backgroundColor = reader.string();
          break;
        case 4:
          message.inactiveTextColor = reader.string();
          break;
        case 5:
          message.inactiveBackgroundColor = reader.string();
          break;
        case 6:
          message.displayOrder = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): MissionCategory {
    const message = Object.create(baseMissionCategory) as MissionCategory;
    if (object.categoryId) {
      message.categoryId = Number(object.categoryId);
    }
    if (object.name) {
      message.name = String(object.name);
    }
    if (object.backgroundColor) {
      message.backgroundColor = String(object.backgroundColor);
    }
    if (object.inactiveTextColor) {
      message.inactiveTextColor = String(object.inactiveTextColor);
    }
    if (object.inactiveBackgroundColor) {
      message.inactiveBackgroundColor = String(object.inactiveBackgroundColor);
    }
    if (object.displayOrder) {
      message.displayOrder = Number(object.displayOrder);
    }
    return message;
  },
  fromPartial(object: DeepPartial<MissionCategory>): MissionCategory {
    const message = Object.create(baseMissionCategory) as MissionCategory;
    if (object.categoryId) {
      message.categoryId = object.categoryId;
    }
    if (object.name) {
      message.name = object.name;
    }
    if (object.backgroundColor) {
      message.backgroundColor = object.backgroundColor;
    }
    if (object.inactiveTextColor) {
      message.inactiveTextColor = object.inactiveTextColor;
    }
    if (object.inactiveBackgroundColor) {
      message.inactiveBackgroundColor = object.inactiveBackgroundColor;
    }
    if (object.displayOrder) {
      message.displayOrder = object.displayOrder;
    }
    return message;
  },
  toJSON(message: MissionCategory): unknown {
    const obj: any = {};
    obj.categoryId = message.categoryId || 0;
    obj.name = message.name || "";
    obj.backgroundColor = message.backgroundColor || "";
    obj.inactiveTextColor = message.inactiveTextColor || "";
    obj.inactiveBackgroundColor = message.inactiveBackgroundColor || "";
    obj.displayOrder = message.displayOrder || 0;
    return obj;
  },
};

export const MissionMember = {
  encode(message: MissionMember, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).uint32(message.missionMemberId);
    writer.uint32(18).string(message.name);
    writer.uint32(26).string(message.career);
    if (message.iconImage !== undefined && message.iconImage !== undefined) {
      Images.encode(message.iconImage, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): MissionMember {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseMissionMember) as MissionMember;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.missionMemberId = reader.uint32();
          break;
        case 2:
          message.name = reader.string();
          break;
        case 3:
          message.career = reader.string();
          break;
        case 4:
          message.iconImage = Images.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): MissionMember {
    const message = Object.create(baseMissionMember) as MissionMember;
    if (object.missionMemberId) {
      message.missionMemberId = Number(object.missionMemberId);
    }
    if (object.name) {
      message.name = String(object.name);
    }
    if (object.career) {
      message.career = String(object.career);
    }
    if (object.iconImage) {
      message.iconImage = Images.fromJSON(object.iconImage);
    }
    return message;
  },
  fromPartial(object: DeepPartial<MissionMember>): MissionMember {
    const message = Object.create(baseMissionMember) as MissionMember;
    if (object.missionMemberId) {
      message.missionMemberId = object.missionMemberId;
    }
    if (object.name) {
      message.name = object.name;
    }
    if (object.career) {
      message.career = object.career;
    }
    if (object.iconImage) {
      message.iconImage = Images.fromPartial(object.iconImage);
    }
    return message;
  },
  toJSON(message: MissionMember): unknown {
    const obj: any = {};
    obj.missionMemberId = message.missionMemberId || 0;
    obj.name = message.name || "";
    obj.career = message.career || "";
    obj.iconImage = message.iconImage ? Images.toJSON(message.iconImage) : undefined;
    return obj;
  },
};

export const MissionMemberToUpdate = {
  encode(message: MissionMemberToUpdate, writer: Writer = Writer.create()): Writer {
    if (message.missionMemberId !== undefined && message.missionMemberId !== undefined) {
      Int32Value.encode({ value: message.missionMemberId! }, writer.uint32(10).fork()).ldelim();
    }
    writer.uint32(18).string(message.name);
    writer.uint32(26).string(message.career);
    if (message.iconImage !== undefined && message.iconImage !== undefined) {
      ImageToUpdate.encode(message.iconImage, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): MissionMemberToUpdate {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseMissionMemberToUpdate) as MissionMemberToUpdate;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.missionMemberId = Int32Value.decode(reader, reader.uint32()).value;
          break;
        case 2:
          message.name = reader.string();
          break;
        case 3:
          message.career = reader.string();
          break;
        case 4:
          message.iconImage = ImageToUpdate.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): MissionMemberToUpdate {
    const message = Object.create(baseMissionMemberToUpdate) as MissionMemberToUpdate;
    if (object.missionMemberId) {
      message.missionMemberId = Number(object.missionMemberId);
    }
    if (object.name) {
      message.name = String(object.name);
    }
    if (object.career) {
      message.career = String(object.career);
    }
    if (object.iconImage) {
      message.iconImage = ImageToUpdate.fromJSON(object.iconImage);
    }
    return message;
  },
  fromPartial(object: DeepPartial<MissionMemberToUpdate>): MissionMemberToUpdate {
    const message = Object.create(baseMissionMemberToUpdate) as MissionMemberToUpdate;
    if (object.missionMemberId) {
      message.missionMemberId = object.missionMemberId;
    }
    if (object.name) {
      message.name = object.name;
    }
    if (object.career) {
      message.career = object.career;
    }
    if (object.iconImage) {
      message.iconImage = ImageToUpdate.fromPartial(object.iconImage);
    }
    return message;
  },
  toJSON(message: MissionMemberToUpdate): unknown {
    const obj: any = {};
    obj.missionMemberId = message.missionMemberId || undefined;
    obj.name = message.name || "";
    obj.career = message.career || "";
    obj.iconImage = message.iconImage ? ImageToUpdate.toJSON(message.iconImage) : undefined;
    return obj;
  },
};

export const MissionOfficeImage = {
  encode(message: MissionOfficeImage, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).uint64(message.missionOfficeImageId);
    if (message.image !== undefined && message.image !== undefined) {
      Images.encode(message.image, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): MissionOfficeImage {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseMissionOfficeImage) as MissionOfficeImage;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.missionOfficeImageId = longToNumber(reader.uint64() as Long);
          break;
        case 2:
          message.image = Images.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): MissionOfficeImage {
    const message = Object.create(baseMissionOfficeImage) as MissionOfficeImage;
    if (object.missionOfficeImageId) {
      message.missionOfficeImageId = Number(object.missionOfficeImageId);
    }
    if (object.image) {
      message.image = Images.fromJSON(object.image);
    }
    return message;
  },
  fromPartial(object: DeepPartial<MissionOfficeImage>): MissionOfficeImage {
    const message = Object.create(baseMissionOfficeImage) as MissionOfficeImage;
    if (object.missionOfficeImageId) {
      message.missionOfficeImageId = object.missionOfficeImageId;
    }
    if (object.image) {
      message.image = Images.fromPartial(object.image);
    }
    return message;
  },
  toJSON(message: MissionOfficeImage): unknown {
    const obj: any = {};
    obj.missionOfficeImageId = message.missionOfficeImageId || 0;
    obj.image = message.image ? Images.toJSON(message.image) : undefined;
    return obj;
  },
};

export const MissionOfficeImageToUpdate = {
  encode(message: MissionOfficeImageToUpdate, writer: Writer = Writer.create()): Writer {
    if (message.missionOfficeImageId !== undefined && message.missionOfficeImageId !== undefined) {
      Int32Value.encode({ value: message.missionOfficeImageId! }, writer.uint32(10).fork()).ldelim();
    }
    if (message.image !== undefined && message.image !== undefined) {
      ImageToUpdate.encode(message.image, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): MissionOfficeImageToUpdate {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseMissionOfficeImageToUpdate) as MissionOfficeImageToUpdate;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.missionOfficeImageId = Int32Value.decode(reader, reader.uint32()).value;
          break;
        case 2:
          message.image = ImageToUpdate.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): MissionOfficeImageToUpdate {
    const message = Object.create(baseMissionOfficeImageToUpdate) as MissionOfficeImageToUpdate;
    if (object.missionOfficeImageId) {
      message.missionOfficeImageId = Number(object.missionOfficeImageId);
    }
    if (object.image) {
      message.image = ImageToUpdate.fromJSON(object.image);
    }
    return message;
  },
  fromPartial(object: DeepPartial<MissionOfficeImageToUpdate>): MissionOfficeImageToUpdate {
    const message = Object.create(baseMissionOfficeImageToUpdate) as MissionOfficeImageToUpdate;
    if (object.missionOfficeImageId) {
      message.missionOfficeImageId = object.missionOfficeImageId;
    }
    if (object.image) {
      message.image = ImageToUpdate.fromPartial(object.image);
    }
    return message;
  },
  toJSON(message: MissionOfficeImageToUpdate): unknown {
    const obj: any = {};
    obj.missionOfficeImageId = message.missionOfficeImageId || undefined;
    obj.image = message.image ? ImageToUpdate.toJSON(message.image) : undefined;
    return obj;
  },
};

export const MissionProduct = {
  encode(message: MissionProduct, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).uint32(message.missionProductId);
    writer.uint32(18).string(message.name);
    writer.uint32(26).string(message.overview);
    writer.uint32(34).string(message.url);
    writer.uint32(42).string(message.problem);
    writer.uint32(50).string(message.solution);
    if (message.memberBenefit !== undefined && message.memberBenefit !== undefined) {
      StringValue.encode({ value: message.memberBenefit! }, writer.uint32(58).fork()).ldelim();
    }
    if (message.image !== undefined && message.image !== undefined) {
      Images.encode(message.image, writer.uint32(66).fork()).ldelim();
    }
    if (message.productTarget !== undefined && message.productTarget !== undefined) {
      MissionProductTarget.encode(message.productTarget, writer.uint32(74).fork()).ldelim();
    }
    for (const v of message.tags) {
      writer.uint32(82).string(v!);
    }
    return writer;
  },
  decode(reader: Reader, length?: number): MissionProduct {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseMissionProduct) as MissionProduct;
    message.tags = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.missionProductId = reader.uint32();
          break;
        case 2:
          message.name = reader.string();
          break;
        case 3:
          message.overview = reader.string();
          break;
        case 4:
          message.url = reader.string();
          break;
        case 5:
          message.problem = reader.string();
          break;
        case 6:
          message.solution = reader.string();
          break;
        case 7:
          message.memberBenefit = StringValue.decode(reader, reader.uint32()).value;
          break;
        case 8:
          message.image = Images.decode(reader, reader.uint32());
          break;
        case 9:
          message.productTarget = MissionProductTarget.decode(reader, reader.uint32());
          break;
        case 10:
          message.tags.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): MissionProduct {
    const message = Object.create(baseMissionProduct) as MissionProduct;
    message.tags = [];
    if (object.missionProductId) {
      message.missionProductId = Number(object.missionProductId);
    }
    if (object.name) {
      message.name = String(object.name);
    }
    if (object.overview) {
      message.overview = String(object.overview);
    }
    if (object.url) {
      message.url = String(object.url);
    }
    if (object.problem) {
      message.problem = String(object.problem);
    }
    if (object.solution) {
      message.solution = String(object.solution);
    }
    if (object.memberBenefit) {
      message.memberBenefit = String(object.memberBenefit);
    }
    if (object.image) {
      message.image = Images.fromJSON(object.image);
    }
    if (object.productTarget) {
      message.productTarget = MissionProductTarget.fromJSON(object.productTarget);
    }
    if (object.tags) {
      for (const e of object.tags) {
        message.tags.push(String(e));
      }
    }
    return message;
  },
  fromPartial(object: DeepPartial<MissionProduct>): MissionProduct {
    const message = Object.create(baseMissionProduct) as MissionProduct;
    message.tags = [];
    if (object.missionProductId) {
      message.missionProductId = object.missionProductId;
    }
    if (object.name) {
      message.name = object.name;
    }
    if (object.overview) {
      message.overview = object.overview;
    }
    if (object.url) {
      message.url = object.url;
    }
    if (object.problem) {
      message.problem = object.problem;
    }
    if (object.solution) {
      message.solution = object.solution;
    }
    if (object.memberBenefit) {
      message.memberBenefit = object.memberBenefit;
    }
    if (object.image) {
      message.image = Images.fromPartial(object.image);
    }
    if (object.productTarget) {
      message.productTarget = MissionProductTarget.fromPartial(object.productTarget);
    }
    if (object.tags) {
      for (const e of object.tags) {
        message.tags.push(e);
      }
    }
    return message;
  },
  toJSON(message: MissionProduct): unknown {
    const obj: any = {};
    obj.missionProductId = message.missionProductId || 0;
    obj.name = message.name || "";
    obj.overview = message.overview || "";
    obj.url = message.url || "";
    obj.problem = message.problem || "";
    obj.solution = message.solution || "";
    obj.memberBenefit = message.memberBenefit || undefined;
    obj.image = message.image ? Images.toJSON(message.image) : undefined;
    obj.productTarget = message.productTarget ? MissionProductTarget.toJSON(message.productTarget) : undefined;
    if (message.tags) {
      obj.tags = message.tags.map(e => e || "");
    } else {
      obj.tags = [];
    }
    return obj;
  },
};

export const MissionProductToUpdate = {
  encode(message: MissionProductToUpdate, writer: Writer = Writer.create()): Writer {
    if (message.missionProductId !== undefined && message.missionProductId !== undefined) {
      Int32Value.encode({ value: message.missionProductId! }, writer.uint32(10).fork()).ldelim();
    }
    writer.uint32(18).string(message.name);
    writer.uint32(26).string(message.overview);
    writer.uint32(34).string(message.url);
    writer.uint32(42).string(message.problem);
    writer.uint32(50).string(message.solution);
    writer.uint32(58).string(message.memberBenefit);
    if (message.image !== undefined && message.image !== undefined) {
      ImageToUpdate.encode(message.image, writer.uint32(66).fork()).ldelim();
    }
    if (message.productTarget !== undefined && message.productTarget !== undefined) {
      MissionProductTarget.encode(message.productTarget, writer.uint32(74).fork()).ldelim();
    }
    for (const v of message.tags) {
      writer.uint32(82).string(v!);
    }
    return writer;
  },
  decode(reader: Reader, length?: number): MissionProductToUpdate {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseMissionProductToUpdate) as MissionProductToUpdate;
    message.tags = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.missionProductId = Int32Value.decode(reader, reader.uint32()).value;
          break;
        case 2:
          message.name = reader.string();
          break;
        case 3:
          message.overview = reader.string();
          break;
        case 4:
          message.url = reader.string();
          break;
        case 5:
          message.problem = reader.string();
          break;
        case 6:
          message.solution = reader.string();
          break;
        case 7:
          message.memberBenefit = reader.string();
          break;
        case 8:
          message.image = ImageToUpdate.decode(reader, reader.uint32());
          break;
        case 9:
          message.productTarget = MissionProductTarget.decode(reader, reader.uint32());
          break;
        case 10:
          message.tags.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): MissionProductToUpdate {
    const message = Object.create(baseMissionProductToUpdate) as MissionProductToUpdate;
    message.tags = [];
    if (object.missionProductId) {
      message.missionProductId = Number(object.missionProductId);
    }
    if (object.name) {
      message.name = String(object.name);
    }
    if (object.overview) {
      message.overview = String(object.overview);
    }
    if (object.url) {
      message.url = String(object.url);
    }
    if (object.problem) {
      message.problem = String(object.problem);
    }
    if (object.solution) {
      message.solution = String(object.solution);
    }
    if (object.memberBenefit) {
      message.memberBenefit = String(object.memberBenefit);
    }
    if (object.image) {
      message.image = ImageToUpdate.fromJSON(object.image);
    }
    if (object.productTarget) {
      message.productTarget = MissionProductTarget.fromJSON(object.productTarget);
    }
    if (object.tags) {
      for (const e of object.tags) {
        message.tags.push(String(e));
      }
    }
    return message;
  },
  fromPartial(object: DeepPartial<MissionProductToUpdate>): MissionProductToUpdate {
    const message = Object.create(baseMissionProductToUpdate) as MissionProductToUpdate;
    message.tags = [];
    if (object.missionProductId) {
      message.missionProductId = object.missionProductId;
    }
    if (object.name) {
      message.name = object.name;
    }
    if (object.overview) {
      message.overview = object.overview;
    }
    if (object.url) {
      message.url = object.url;
    }
    if (object.problem) {
      message.problem = object.problem;
    }
    if (object.solution) {
      message.solution = object.solution;
    }
    if (object.memberBenefit) {
      message.memberBenefit = object.memberBenefit;
    }
    if (object.image) {
      message.image = ImageToUpdate.fromPartial(object.image);
    }
    if (object.productTarget) {
      message.productTarget = MissionProductTarget.fromPartial(object.productTarget);
    }
    if (object.tags) {
      for (const e of object.tags) {
        message.tags.push(e);
      }
    }
    return message;
  },
  toJSON(message: MissionProductToUpdate): unknown {
    const obj: any = {};
    obj.missionProductId = message.missionProductId || undefined;
    obj.name = message.name || "";
    obj.overview = message.overview || "";
    obj.url = message.url || "";
    obj.problem = message.problem || "";
    obj.solution = message.solution || "";
    obj.memberBenefit = message.memberBenefit || "";
    obj.image = message.image ? ImageToUpdate.toJSON(message.image) : undefined;
    obj.productTarget = message.productTarget ? MissionProductTarget.toJSON(message.productTarget) : undefined;
    if (message.tags) {
      obj.tags = message.tags.map(e => e || "");
    } else {
      obj.tags = [];
    }
    return obj;
  },
};

export const MissionProductTag = {
  encode(message: MissionProductTag, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).uint64(message.missionProductId);
    writer.uint32(16).uint64(message.displayOrder);
    writer.uint32(26).string(message.tag);
    return writer;
  },
  decode(reader: Reader, length?: number): MissionProductTag {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseMissionProductTag) as MissionProductTag;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.missionProductId = longToNumber(reader.uint64() as Long);
          break;
        case 2:
          message.displayOrder = longToNumber(reader.uint64() as Long);
          break;
        case 3:
          message.tag = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): MissionProductTag {
    const message = Object.create(baseMissionProductTag) as MissionProductTag;
    if (object.missionProductId) {
      message.missionProductId = Number(object.missionProductId);
    }
    if (object.displayOrder) {
      message.displayOrder = Number(object.displayOrder);
    }
    if (object.tag) {
      message.tag = String(object.tag);
    }
    return message;
  },
  fromPartial(object: DeepPartial<MissionProductTag>): MissionProductTag {
    const message = Object.create(baseMissionProductTag) as MissionProductTag;
    if (object.missionProductId) {
      message.missionProductId = object.missionProductId;
    }
    if (object.displayOrder) {
      message.displayOrder = object.displayOrder;
    }
    if (object.tag) {
      message.tag = object.tag;
    }
    return message;
  },
  toJSON(message: MissionProductTag): unknown {
    const obj: any = {};
    obj.missionProductId = message.missionProductId || 0;
    obj.displayOrder = message.displayOrder || 0;
    obj.tag = message.tag || "";
    return obj;
  },
};

export const MissionProductTarget = {
  encode(message: MissionProductTarget, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).bool(message.isForBusiness);
    writer.uint32(16).bool(message.isForCustomer);
    return writer;
  },
  decode(reader: Reader, length?: number): MissionProductTarget {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseMissionProductTarget) as MissionProductTarget;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.isForBusiness = reader.bool();
          break;
        case 2:
          message.isForCustomer = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): MissionProductTarget {
    const message = Object.create(baseMissionProductTarget) as MissionProductTarget;
    if (object.isForBusiness) {
      message.isForBusiness = Boolean(object.isForBusiness);
    }
    if (object.isForCustomer) {
      message.isForCustomer = Boolean(object.isForCustomer);
    }
    return message;
  },
  fromPartial(object: DeepPartial<MissionProductTarget>): MissionProductTarget {
    const message = Object.create(baseMissionProductTarget) as MissionProductTarget;
    if (object.isForBusiness) {
      message.isForBusiness = object.isForBusiness;
    }
    if (object.isForCustomer) {
      message.isForCustomer = object.isForCustomer;
    }
    return message;
  },
  toJSON(message: MissionProductTarget): unknown {
    const obj: any = {};
    obj.isForBusiness = message.isForBusiness || false;
    obj.isForCustomer = message.isForCustomer || false;
    return obj;
  },
};

export const MissionUsePlan = {
  encode(message: MissionUsePlan, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).uint32(message.usePlanId);
    writer.uint32(18).string(message.name);
    return writer;
  },
  decode(reader: Reader, length?: number): MissionUsePlan {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseMissionUsePlan) as MissionUsePlan;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.usePlanId = reader.uint32();
          break;
        case 2:
          message.name = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): MissionUsePlan {
    const message = Object.create(baseMissionUsePlan) as MissionUsePlan;
    if (object.usePlanId) {
      message.usePlanId = Number(object.usePlanId);
    }
    if (object.name) {
      message.name = String(object.name);
    }
    return message;
  },
  fromPartial(object: DeepPartial<MissionUsePlan>): MissionUsePlan {
    const message = Object.create(baseMissionUsePlan) as MissionUsePlan;
    if (object.usePlanId) {
      message.usePlanId = object.usePlanId;
    }
    if (object.name) {
      message.name = object.name;
    }
    return message;
  },
  toJSON(message: MissionUsePlan): unknown {
    const obj: any = {};
    obj.usePlanId = message.usePlanId || 0;
    obj.name = message.name || "";
    return obj;
  },
};

export const Supporter = {
  encode(message: Supporter, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.supporterId);
    writer.uint32(18).string(message.name);
    if (message.iconImage !== undefined && message.iconImage !== undefined) {
      Images.encode(message.iconImage, writer.uint32(26).fork()).ldelim();
    }
    if (message.coverImage !== undefined && message.coverImage !== undefined) {
      Images.encode(message.coverImage, writer.uint32(34).fork()).ldelim();
    }
    writer.uint32(42).string(message.supportDescription);
    writer.uint32(50).string(message.strengthPoint);
    writer.uint32(58).string(message.companyName);
    if (message.country !== undefined && message.country !== undefined) {
      Country.encode(message.country, writer.uint32(66).fork()).ldelim();
    }
    if (message.japanPrefecture !== undefined && message.japanPrefecture !== undefined) {
      JapanPrefecture.encode(message.japanPrefecture, writer.uint32(74).fork()).ldelim();
    }
    writer.uint32(82).string(message.otherAddress);
    if (message.memberRange !== undefined && message.memberRange !== undefined) {
      MemberRange.encode(message.memberRange, writer.uint32(90).fork()).ldelim();
    }
    for (const v of message.supportAreas) {
      SupportArea.encode(v!, writer.uint32(98).fork()).ldelim();
    }
    writer.uint32(106).string(message.companyUrl);
    if (message.companyEstablishedDate !== undefined && message.companyEstablishedDate !== undefined) {
      CompanyEstablishedDate.encode(message.companyEstablishedDate, writer.uint32(114).fork()).ldelim();
    }
    for (const v of message.supporterMembers) {
      SupporterMember.encode(v!, writer.uint32(122).fork()).ldelim();
    }
    for (const v of message.galleryImages) {
      GalleryImage.encode(v!, writer.uint32(130).fork()).ldelim();
    }
    writer.uint32(136).int32(message.degreeOfAttention);
    writer.uint32(146).string(message.language);
    return writer;
  },
  decode(reader: Reader, length?: number): Supporter {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseSupporter) as Supporter;
    message.supportAreas = [];
    message.supporterMembers = [];
    message.galleryImages = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.supporterId = reader.string();
          break;
        case 2:
          message.name = reader.string();
          break;
        case 3:
          message.iconImage = Images.decode(reader, reader.uint32());
          break;
        case 4:
          message.coverImage = Images.decode(reader, reader.uint32());
          break;
        case 5:
          message.supportDescription = reader.string();
          break;
        case 6:
          message.strengthPoint = reader.string();
          break;
        case 7:
          message.companyName = reader.string();
          break;
        case 8:
          message.country = Country.decode(reader, reader.uint32());
          break;
        case 9:
          message.japanPrefecture = JapanPrefecture.decode(reader, reader.uint32());
          break;
        case 10:
          message.otherAddress = reader.string();
          break;
        case 11:
          message.memberRange = MemberRange.decode(reader, reader.uint32());
          break;
        case 12:
          message.supportAreas.push(SupportArea.decode(reader, reader.uint32()));
          break;
        case 13:
          message.companyUrl = reader.string();
          break;
        case 14:
          message.companyEstablishedDate = CompanyEstablishedDate.decode(reader, reader.uint32());
          break;
        case 15:
          message.supporterMembers.push(SupporterMember.decode(reader, reader.uint32()));
          break;
        case 16:
          message.galleryImages.push(GalleryImage.decode(reader, reader.uint32()));
          break;
        case 17:
          message.degreeOfAttention = reader.int32();
          break;
        case 18:
          message.language = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): Supporter {
    const message = Object.create(baseSupporter) as Supporter;
    message.supportAreas = [];
    message.supporterMembers = [];
    message.galleryImages = [];
    if (object.supporterId) {
      message.supporterId = String(object.supporterId);
    }
    if (object.name) {
      message.name = String(object.name);
    }
    if (object.iconImage) {
      message.iconImage = Images.fromJSON(object.iconImage);
    }
    if (object.coverImage) {
      message.coverImage = Images.fromJSON(object.coverImage);
    }
    if (object.supportDescription) {
      message.supportDescription = String(object.supportDescription);
    }
    if (object.strengthPoint) {
      message.strengthPoint = String(object.strengthPoint);
    }
    if (object.companyName) {
      message.companyName = String(object.companyName);
    }
    if (object.country) {
      message.country = Country.fromJSON(object.country);
    }
    if (object.japanPrefecture) {
      message.japanPrefecture = JapanPrefecture.fromJSON(object.japanPrefecture);
    }
    if (object.otherAddress) {
      message.otherAddress = String(object.otherAddress);
    }
    if (object.memberRange) {
      message.memberRange = MemberRange.fromJSON(object.memberRange);
    }
    if (object.supportAreas) {
      for (const e of object.supportAreas) {
        message.supportAreas.push(SupportArea.fromJSON(e));
      }
    }
    if (object.companyUrl) {
      message.companyUrl = String(object.companyUrl);
    }
    if (object.companyEstablishedDate) {
      message.companyEstablishedDate = CompanyEstablishedDate.fromJSON(object.companyEstablishedDate);
    }
    if (object.supporterMembers) {
      for (const e of object.supporterMembers) {
        message.supporterMembers.push(SupporterMember.fromJSON(e));
      }
    }
    if (object.galleryImages) {
      for (const e of object.galleryImages) {
        message.galleryImages.push(GalleryImage.fromJSON(e));
      }
    }
    if (object.degreeOfAttention) {
      message.degreeOfAttention = Number(object.degreeOfAttention);
    }
    if (object.language) {
      message.language = String(object.language);
    }
    return message;
  },
  fromPartial(object: DeepPartial<Supporter>): Supporter {
    const message = Object.create(baseSupporter) as Supporter;
    message.supportAreas = [];
    message.supporterMembers = [];
    message.galleryImages = [];
    if (object.supporterId) {
      message.supporterId = object.supporterId;
    }
    if (object.name) {
      message.name = object.name;
    }
    if (object.iconImage) {
      message.iconImage = Images.fromPartial(object.iconImage);
    }
    if (object.coverImage) {
      message.coverImage = Images.fromPartial(object.coverImage);
    }
    if (object.supportDescription) {
      message.supportDescription = object.supportDescription;
    }
    if (object.strengthPoint) {
      message.strengthPoint = object.strengthPoint;
    }
    if (object.companyName) {
      message.companyName = object.companyName;
    }
    if (object.country) {
      message.country = Country.fromPartial(object.country);
    }
    if (object.japanPrefecture) {
      message.japanPrefecture = JapanPrefecture.fromPartial(object.japanPrefecture);
    }
    if (object.otherAddress) {
      message.otherAddress = object.otherAddress;
    }
    if (object.memberRange) {
      message.memberRange = MemberRange.fromPartial(object.memberRange);
    }
    if (object.supportAreas) {
      for (const e of object.supportAreas) {
        message.supportAreas.push(SupportArea.fromPartial(e));
      }
    }
    if (object.companyUrl) {
      message.companyUrl = object.companyUrl;
    }
    if (object.companyEstablishedDate) {
      message.companyEstablishedDate = CompanyEstablishedDate.fromPartial(object.companyEstablishedDate);
    }
    if (object.supporterMembers) {
      for (const e of object.supporterMembers) {
        message.supporterMembers.push(SupporterMember.fromPartial(e));
      }
    }
    if (object.galleryImages) {
      for (const e of object.galleryImages) {
        message.galleryImages.push(GalleryImage.fromPartial(e));
      }
    }
    if (object.degreeOfAttention) {
      message.degreeOfAttention = object.degreeOfAttention;
    }
    if (object.language) {
      message.language = object.language;
    }
    return message;
  },
  toJSON(message: Supporter): unknown {
    const obj: any = {};
    obj.supporterId = message.supporterId || "";
    obj.name = message.name || "";
    obj.iconImage = message.iconImage ? Images.toJSON(message.iconImage) : undefined;
    obj.coverImage = message.coverImage ? Images.toJSON(message.coverImage) : undefined;
    obj.supportDescription = message.supportDescription || "";
    obj.strengthPoint = message.strengthPoint || "";
    obj.companyName = message.companyName || "";
    obj.country = message.country ? Country.toJSON(message.country) : undefined;
    obj.japanPrefecture = message.japanPrefecture ? JapanPrefecture.toJSON(message.japanPrefecture) : undefined;
    obj.otherAddress = message.otherAddress || "";
    obj.memberRange = message.memberRange ? MemberRange.toJSON(message.memberRange) : undefined;
    if (message.supportAreas) {
      obj.supportAreas = message.supportAreas.map(e => e ? SupportArea.toJSON(e) : undefined);
    } else {
      obj.supportAreas = [];
    }
    obj.companyUrl = message.companyUrl || "";
    obj.companyEstablishedDate = message.companyEstablishedDate ? CompanyEstablishedDate.toJSON(message.companyEstablishedDate) : undefined;
    if (message.supporterMembers) {
      obj.supporterMembers = message.supporterMembers.map(e => e ? SupporterMember.toJSON(e) : undefined);
    } else {
      obj.supporterMembers = [];
    }
    if (message.galleryImages) {
      obj.galleryImages = message.galleryImages.map(e => e ? GalleryImage.toJSON(e) : undefined);
    } else {
      obj.galleryImages = [];
    }
    obj.degreeOfAttention = message.degreeOfAttention || 0;
    obj.language = message.language || "";
    return obj;
  },
};

export const SupportArea = {
  encode(message: SupportArea, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).uint32(message.supportAreaId);
    writer.uint32(18).string(message.name);
    writer.uint32(24).uint32(message.displayOrder);
    return writer;
  },
  decode(reader: Reader, length?: number): SupportArea {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseSupportArea) as SupportArea;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.supportAreaId = reader.uint32();
          break;
        case 2:
          message.name = reader.string();
          break;
        case 3:
          message.displayOrder = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): SupportArea {
    const message = Object.create(baseSupportArea) as SupportArea;
    if (object.supportAreaId) {
      message.supportAreaId = Number(object.supportAreaId);
    }
    if (object.name) {
      message.name = String(object.name);
    }
    if (object.displayOrder) {
      message.displayOrder = Number(object.displayOrder);
    }
    return message;
  },
  fromPartial(object: DeepPartial<SupportArea>): SupportArea {
    const message = Object.create(baseSupportArea) as SupportArea;
    if (object.supportAreaId) {
      message.supportAreaId = object.supportAreaId;
    }
    if (object.name) {
      message.name = object.name;
    }
    if (object.displayOrder) {
      message.displayOrder = object.displayOrder;
    }
    return message;
  },
  toJSON(message: SupportArea): unknown {
    const obj: any = {};
    obj.supportAreaId = message.supportAreaId || 0;
    obj.name = message.name || "";
    obj.displayOrder = message.displayOrder || 0;
    return obj;
  },
};

export const SupporterMember = {
  encode(message: SupporterMember, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).uint32(message.supporterMemberId);
    writer.uint32(18).string(message.name);
    writer.uint32(26).string(message.career);
    if (message.iconImage !== undefined && message.iconImage !== undefined) {
      Images.encode(message.iconImage, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): SupporterMember {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseSupporterMember) as SupporterMember;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.supporterMemberId = reader.uint32();
          break;
        case 2:
          message.name = reader.string();
          break;
        case 3:
          message.career = reader.string();
          break;
        case 4:
          message.iconImage = Images.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): SupporterMember {
    const message = Object.create(baseSupporterMember) as SupporterMember;
    if (object.supporterMemberId) {
      message.supporterMemberId = Number(object.supporterMemberId);
    }
    if (object.name) {
      message.name = String(object.name);
    }
    if (object.career) {
      message.career = String(object.career);
    }
    if (object.iconImage) {
      message.iconImage = Images.fromJSON(object.iconImage);
    }
    return message;
  },
  fromPartial(object: DeepPartial<SupporterMember>): SupporterMember {
    const message = Object.create(baseSupporterMember) as SupporterMember;
    if (object.supporterMemberId) {
      message.supporterMemberId = object.supporterMemberId;
    }
    if (object.name) {
      message.name = object.name;
    }
    if (object.career) {
      message.career = object.career;
    }
    if (object.iconImage) {
      message.iconImage = Images.fromPartial(object.iconImage);
    }
    return message;
  },
  toJSON(message: SupporterMember): unknown {
    const obj: any = {};
    obj.supporterMemberId = message.supporterMemberId || 0;
    obj.name = message.name || "";
    obj.career = message.career || "";
    obj.iconImage = message.iconImage ? Images.toJSON(message.iconImage) : undefined;
    return obj;
  },
};

export const SupporterMemberToUpdate = {
  encode(message: SupporterMemberToUpdate, writer: Writer = Writer.create()): Writer {
    if (message.supporterMemberId !== undefined && message.supporterMemberId !== undefined) {
      Int32Value.encode({ value: message.supporterMemberId! }, writer.uint32(10).fork()).ldelim();
    }
    writer.uint32(18).string(message.name);
    writer.uint32(26).string(message.career);
    if (message.iconImage !== undefined && message.iconImage !== undefined) {
      ImageToUpdate.encode(message.iconImage, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): SupporterMemberToUpdate {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseSupporterMemberToUpdate) as SupporterMemberToUpdate;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.supporterMemberId = Int32Value.decode(reader, reader.uint32()).value;
          break;
        case 2:
          message.name = reader.string();
          break;
        case 3:
          message.career = reader.string();
          break;
        case 4:
          message.iconImage = ImageToUpdate.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): SupporterMemberToUpdate {
    const message = Object.create(baseSupporterMemberToUpdate) as SupporterMemberToUpdate;
    if (object.supporterMemberId) {
      message.supporterMemberId = Number(object.supporterMemberId);
    }
    if (object.name) {
      message.name = String(object.name);
    }
    if (object.career) {
      message.career = String(object.career);
    }
    if (object.iconImage) {
      message.iconImage = ImageToUpdate.fromJSON(object.iconImage);
    }
    return message;
  },
  fromPartial(object: DeepPartial<SupporterMemberToUpdate>): SupporterMemberToUpdate {
    const message = Object.create(baseSupporterMemberToUpdate) as SupporterMemberToUpdate;
    if (object.supporterMemberId) {
      message.supporterMemberId = object.supporterMemberId;
    }
    if (object.name) {
      message.name = object.name;
    }
    if (object.career) {
      message.career = object.career;
    }
    if (object.iconImage) {
      message.iconImage = ImageToUpdate.fromPartial(object.iconImage);
    }
    return message;
  },
  toJSON(message: SupporterMemberToUpdate): unknown {
    const obj: any = {};
    obj.supporterMemberId = message.supporterMemberId || undefined;
    obj.name = message.name || "";
    obj.career = message.career || "";
    obj.iconImage = message.iconImage ? ImageToUpdate.toJSON(message.iconImage) : undefined;
    return obj;
  },
};

export const GalleryImage = {
  encode(message: GalleryImage, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).uint64(message.galleryImageId);
    if (message.image !== undefined && message.image !== undefined) {
      Images.encode(message.image, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): GalleryImage {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGalleryImage) as GalleryImage;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.galleryImageId = longToNumber(reader.uint64() as Long);
          break;
        case 2:
          message.image = Images.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GalleryImage {
    const message = Object.create(baseGalleryImage) as GalleryImage;
    if (object.galleryImageId) {
      message.galleryImageId = Number(object.galleryImageId);
    }
    if (object.image) {
      message.image = Images.fromJSON(object.image);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GalleryImage>): GalleryImage {
    const message = Object.create(baseGalleryImage) as GalleryImage;
    if (object.galleryImageId) {
      message.galleryImageId = object.galleryImageId;
    }
    if (object.image) {
      message.image = Images.fromPartial(object.image);
    }
    return message;
  },
  toJSON(message: GalleryImage): unknown {
    const obj: any = {};
    obj.galleryImageId = message.galleryImageId || 0;
    obj.image = message.image ? Images.toJSON(message.image) : undefined;
    return obj;
  },
};

export const CompanyEstablishedDate = {
  encode(message: CompanyEstablishedDate, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).uint32(message.year);
    writer.uint32(16).uint32(message.month);
    return writer;
  },
  decode(reader: Reader, length?: number): CompanyEstablishedDate {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseCompanyEstablishedDate) as CompanyEstablishedDate;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.year = reader.uint32();
          break;
        case 2:
          message.month = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): CompanyEstablishedDate {
    const message = Object.create(baseCompanyEstablishedDate) as CompanyEstablishedDate;
    if (object.year) {
      message.year = Number(object.year);
    }
    if (object.month) {
      message.month = Number(object.month);
    }
    return message;
  },
  fromPartial(object: DeepPartial<CompanyEstablishedDate>): CompanyEstablishedDate {
    const message = Object.create(baseCompanyEstablishedDate) as CompanyEstablishedDate;
    if (object.year) {
      message.year = object.year;
    }
    if (object.month) {
      message.month = object.month;
    }
    return message;
  },
  toJSON(message: CompanyEstablishedDate): unknown {
    const obj: any = {};
    obj.year = message.year || 0;
    obj.month = message.month || 0;
    return obj;
  },
};

export const GalleryImageToUpdate = {
  encode(message: GalleryImageToUpdate, writer: Writer = Writer.create()): Writer {
    if (message.galleryImageId !== undefined && message.galleryImageId !== undefined) {
      Int32Value.encode({ value: message.galleryImageId! }, writer.uint32(10).fork()).ldelim();
    }
    if (message.image !== undefined && message.image !== undefined) {
      ImageToUpdate.encode(message.image, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): GalleryImageToUpdate {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseGalleryImageToUpdate) as GalleryImageToUpdate;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.galleryImageId = Int32Value.decode(reader, reader.uint32()).value;
          break;
        case 2:
          message.image = ImageToUpdate.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): GalleryImageToUpdate {
    const message = Object.create(baseGalleryImageToUpdate) as GalleryImageToUpdate;
    if (object.galleryImageId) {
      message.galleryImageId = Number(object.galleryImageId);
    }
    if (object.image) {
      message.image = ImageToUpdate.fromJSON(object.image);
    }
    return message;
  },
  fromPartial(object: DeepPartial<GalleryImageToUpdate>): GalleryImageToUpdate {
    const message = Object.create(baseGalleryImageToUpdate) as GalleryImageToUpdate;
    if (object.galleryImageId) {
      message.galleryImageId = object.galleryImageId;
    }
    if (object.image) {
      message.image = ImageToUpdate.fromPartial(object.image);
    }
    return message;
  },
  toJSON(message: GalleryImageToUpdate): unknown {
    const obj: any = {};
    obj.galleryImageId = message.galleryImageId || undefined;
    obj.image = message.image ? ImageToUpdate.toJSON(message.image) : undefined;
    return obj;
  },
};

export const MissionSummary = {
  encode(message: MissionSummary, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.missionId);
    for (const v of message.users) {
      UserSummary.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    writer.uint32(26).string(message.companyName);
    writer.uint32(34).string(message.missionName);
    writer.uint32(42).string(message.missionDescription);
    writer.uint32(48).int64(message.createdAt);
    writer.uint32(56).int64(message.updatedAt);
    writer.uint32(66).string(message.iconImageKey);
    writer.uint32(74).string(message.coverImageKey);
    writer.uint32(80).bool(message.publishedToMission);
    writer.uint32(88).int64(message.firstPublishedAt);
    writer.uint32(96).int64(message.lastPublisehdAt);
    return writer;
  },
  decode(reader: Reader, length?: number): MissionSummary {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseMissionSummary) as MissionSummary;
    message.users = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.missionId = reader.string();
          break;
        case 2:
          message.users.push(UserSummary.decode(reader, reader.uint32()));
          break;
        case 3:
          message.companyName = reader.string();
          break;
        case 4:
          message.missionName = reader.string();
          break;
        case 5:
          message.missionDescription = reader.string();
          break;
        case 6:
          message.createdAt = longToNumber(reader.int64() as Long);
          break;
        case 7:
          message.updatedAt = longToNumber(reader.int64() as Long);
          break;
        case 8:
          message.iconImageKey = reader.string();
          break;
        case 9:
          message.coverImageKey = reader.string();
          break;
        case 10:
          message.publishedToMission = reader.bool();
          break;
        case 11:
          message.firstPublishedAt = longToNumber(reader.int64() as Long);
          break;
        case 12:
          message.lastPublisehdAt = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): MissionSummary {
    const message = Object.create(baseMissionSummary) as MissionSummary;
    message.users = [];
    if (object.missionId) {
      message.missionId = String(object.missionId);
    }
    if (object.users) {
      for (const e of object.users) {
        message.users.push(UserSummary.fromJSON(e));
      }
    }
    if (object.companyName) {
      message.companyName = String(object.companyName);
    }
    if (object.missionName) {
      message.missionName = String(object.missionName);
    }
    if (object.missionDescription) {
      message.missionDescription = String(object.missionDescription);
    }
    if (object.createdAt) {
      message.createdAt = Number(object.createdAt);
    }
    if (object.updatedAt) {
      message.updatedAt = Number(object.updatedAt);
    }
    if (object.iconImageKey) {
      message.iconImageKey = String(object.iconImageKey);
    }
    if (object.coverImageKey) {
      message.coverImageKey = String(object.coverImageKey);
    }
    if (object.publishedToMission) {
      message.publishedToMission = Boolean(object.publishedToMission);
    }
    if (object.firstPublishedAt) {
      message.firstPublishedAt = Number(object.firstPublishedAt);
    }
    if (object.lastPublisehdAt) {
      message.lastPublisehdAt = Number(object.lastPublisehdAt);
    }
    return message;
  },
  fromPartial(object: DeepPartial<MissionSummary>): MissionSummary {
    const message = Object.create(baseMissionSummary) as MissionSummary;
    message.users = [];
    if (object.missionId) {
      message.missionId = object.missionId;
    }
    if (object.users) {
      for (const e of object.users) {
        message.users.push(UserSummary.fromPartial(e));
      }
    }
    if (object.companyName) {
      message.companyName = object.companyName;
    }
    if (object.missionName) {
      message.missionName = object.missionName;
    }
    if (object.missionDescription) {
      message.missionDescription = object.missionDescription;
    }
    if (object.createdAt) {
      message.createdAt = object.createdAt;
    }
    if (object.updatedAt) {
      message.updatedAt = object.updatedAt;
    }
    if (object.iconImageKey) {
      message.iconImageKey = object.iconImageKey;
    }
    if (object.coverImageKey) {
      message.coverImageKey = object.coverImageKey;
    }
    if (object.publishedToMission) {
      message.publishedToMission = object.publishedToMission;
    }
    if (object.firstPublishedAt) {
      message.firstPublishedAt = object.firstPublishedAt;
    }
    if (object.lastPublisehdAt) {
      message.lastPublisehdAt = object.lastPublisehdAt;
    }
    return message;
  },
  toJSON(message: MissionSummary): unknown {
    const obj: any = {};
    obj.missionId = message.missionId || "";
    if (message.users) {
      obj.users = message.users.map(e => e ? UserSummary.toJSON(e) : undefined);
    } else {
      obj.users = [];
    }
    obj.companyName = message.companyName || "";
    obj.missionName = message.missionName || "";
    obj.missionDescription = message.missionDescription || "";
    obj.createdAt = message.createdAt || 0;
    obj.updatedAt = message.updatedAt || 0;
    obj.iconImageKey = message.iconImageKey || "";
    obj.coverImageKey = message.coverImageKey || "";
    obj.publishedToMission = message.publishedToMission || false;
    obj.firstPublishedAt = message.firstPublishedAt || 0;
    obj.lastPublisehdAt = message.lastPublisehdAt || 0;
    return obj;
  },
};

export const UserSummary = {
  encode(message: UserSummary, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.userId);
    writer.uint32(18).string(message.email);
    writer.uint32(24).int64(message.activatedAt);
    return writer;
  },
  decode(reader: Reader, length?: number): UserSummary {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseUserSummary) as UserSummary;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.userId = reader.string();
          break;
        case 2:
          message.email = reader.string();
          break;
        case 3:
          message.activatedAt = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): UserSummary {
    const message = Object.create(baseUserSummary) as UserSummary;
    if (object.userId) {
      message.userId = String(object.userId);
    }
    if (object.email) {
      message.email = String(object.email);
    }
    if (object.activatedAt) {
      message.activatedAt = Number(object.activatedAt);
    }
    return message;
  },
  fromPartial(object: DeepPartial<UserSummary>): UserSummary {
    const message = Object.create(baseUserSummary) as UserSummary;
    if (object.userId) {
      message.userId = object.userId;
    }
    if (object.email) {
      message.email = object.email;
    }
    if (object.activatedAt) {
      message.activatedAt = object.activatedAt;
    }
    return message;
  },
  toJSON(message: UserSummary): unknown {
    const obj: any = {};
    obj.userId = message.userId || "";
    obj.email = message.email || "";
    obj.activatedAt = message.activatedAt || 0;
    return obj;
  },
};

export const Country = {
  encode(message: Country, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.countryId);
    writer.uint32(18).string(message.name);
    writer.uint32(26).string(message.phoneCountryCode);
    return writer;
  },
  decode(reader: Reader, length?: number): Country {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseCountry) as Country;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.countryId = reader.string();
          break;
        case 2:
          message.name = reader.string();
          break;
        case 3:
          message.phoneCountryCode = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): Country {
    const message = Object.create(baseCountry) as Country;
    if (object.countryId) {
      message.countryId = String(object.countryId);
    }
    if (object.name) {
      message.name = String(object.name);
    }
    if (object.phoneCountryCode) {
      message.phoneCountryCode = String(object.phoneCountryCode);
    }
    return message;
  },
  fromPartial(object: DeepPartial<Country>): Country {
    const message = Object.create(baseCountry) as Country;
    if (object.countryId) {
      message.countryId = object.countryId;
    }
    if (object.name) {
      message.name = object.name;
    }
    if (object.phoneCountryCode) {
      message.phoneCountryCode = object.phoneCountryCode;
    }
    return message;
  },
  toJSON(message: Country): unknown {
    const obj: any = {};
    obj.countryId = message.countryId || "";
    obj.name = message.name || "";
    obj.phoneCountryCode = message.phoneCountryCode || "";
    return obj;
  },
};

export const JapanPrefecture = {
  encode(message: JapanPrefecture, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).uint32(message.japanPrefectureId);
    writer.uint32(18).string(message.name);
    return writer;
  },
  decode(reader: Reader, length?: number): JapanPrefecture {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseJapanPrefecture) as JapanPrefecture;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.japanPrefectureId = reader.uint32();
          break;
        case 2:
          message.name = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): JapanPrefecture {
    const message = Object.create(baseJapanPrefecture) as JapanPrefecture;
    if (object.japanPrefectureId) {
      message.japanPrefectureId = Number(object.japanPrefectureId);
    }
    if (object.name) {
      message.name = String(object.name);
    }
    return message;
  },
  fromPartial(object: DeepPartial<JapanPrefecture>): JapanPrefecture {
    const message = Object.create(baseJapanPrefecture) as JapanPrefecture;
    if (object.japanPrefectureId) {
      message.japanPrefectureId = object.japanPrefectureId;
    }
    if (object.name) {
      message.name = object.name;
    }
    return message;
  },
  toJSON(message: JapanPrefecture): unknown {
    const obj: any = {};
    obj.japanPrefectureId = message.japanPrefectureId || 0;
    obj.name = message.name || "";
    return obj;
  },
};

export const MemberRange = {
  encode(message: MemberRange, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).uint32(message.memberRangeId);
    writer.uint32(18).string(message.displayText);
    return writer;
  },
  decode(reader: Reader, length?: number): MemberRange {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseMemberRange) as MemberRange;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.memberRangeId = reader.uint32();
          break;
        case 2:
          message.displayText = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): MemberRange {
    const message = Object.create(baseMemberRange) as MemberRange;
    if (object.memberRangeId) {
      message.memberRangeId = Number(object.memberRangeId);
    }
    if (object.displayText) {
      message.displayText = String(object.displayText);
    }
    return message;
  },
  fromPartial(object: DeepPartial<MemberRange>): MemberRange {
    const message = Object.create(baseMemberRange) as MemberRange;
    if (object.memberRangeId) {
      message.memberRangeId = object.memberRangeId;
    }
    if (object.displayText) {
      message.displayText = object.displayText;
    }
    return message;
  },
  toJSON(message: MemberRange): unknown {
    const obj: any = {};
    obj.memberRangeId = message.memberRangeId || 0;
    obj.displayText = message.displayText || "";
    return obj;
  },
};

export const Fund = {
  encode(message: Fund, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).uint32(message.fundId);
    writer.uint32(18).string(message.displayText);
    return writer;
  },
  decode(reader: Reader, length?: number): Fund {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseFund) as Fund;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.fundId = reader.uint32();
          break;
        case 2:
          message.displayText = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): Fund {
    const message = Object.create(baseFund) as Fund;
    if (object.fundId) {
      message.fundId = Number(object.fundId);
    }
    if (object.displayText) {
      message.displayText = String(object.displayText);
    }
    return message;
  },
  fromPartial(object: DeepPartial<Fund>): Fund {
    const message = Object.create(baseFund) as Fund;
    if (object.fundId) {
      message.fundId = object.fundId;
    }
    if (object.displayText) {
      message.displayText = object.displayText;
    }
    return message;
  },
  toJSON(message: Fund): unknown {
    const obj: any = {};
    obj.fundId = message.fundId || 0;
    obj.displayText = message.displayText || "";
    return obj;
  },
};

export const FinancingSeries = {
  encode(message: FinancingSeries, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).uint32(message.seriesId);
    writer.uint32(18).string(message.name);
    return writer;
  },
  decode(reader: Reader, length?: number): FinancingSeries {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseFinancingSeries) as FinancingSeries;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.seriesId = reader.uint32();
          break;
        case 2:
          message.name = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): FinancingSeries {
    const message = Object.create(baseFinancingSeries) as FinancingSeries;
    if (object.seriesId) {
      message.seriesId = Number(object.seriesId);
    }
    if (object.name) {
      message.name = String(object.name);
    }
    return message;
  },
  fromPartial(object: DeepPartial<FinancingSeries>): FinancingSeries {
    const message = Object.create(baseFinancingSeries) as FinancingSeries;
    if (object.seriesId) {
      message.seriesId = object.seriesId;
    }
    if (object.name) {
      message.name = object.name;
    }
    return message;
  },
  toJSON(message: FinancingSeries): unknown {
    const obj: any = {};
    obj.seriesId = message.seriesId || 0;
    obj.name = message.name || "";
    return obj;
  },
};

export const ChatMessage = {
  encode(message: ChatMessage, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).int64(message.messageId);
    writer.uint32(18).string(message.senderAccountId);
    writer.uint32(24).int64(message.sentAt);
    writer.uint32(34).string(message.message);
    writer.uint32(40).bool(message.unread);
    if (message.file !== undefined && message.file !== undefined) {
      ChatFile.encode(message.file, writer.uint32(50).fork()).ldelim();
    }
    writer.uint32(56).bool(message.deleted);
    return writer;
  },
  decode(reader: Reader, length?: number): ChatMessage {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseChatMessage) as ChatMessage;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.messageId = longToNumber(reader.int64() as Long);
          break;
        case 2:
          message.senderAccountId = reader.string();
          break;
        case 3:
          message.sentAt = longToNumber(reader.int64() as Long);
          break;
        case 4:
          message.message = reader.string();
          break;
        case 5:
          message.unread = reader.bool();
          break;
        case 6:
          message.file = ChatFile.decode(reader, reader.uint32());
          break;
        case 7:
          message.deleted = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): ChatMessage {
    const message = Object.create(baseChatMessage) as ChatMessage;
    if (object.messageId) {
      message.messageId = Number(object.messageId);
    }
    if (object.senderAccountId) {
      message.senderAccountId = String(object.senderAccountId);
    }
    if (object.sentAt) {
      message.sentAt = Number(object.sentAt);
    }
    if (object.message) {
      message.message = String(object.message);
    }
    if (object.unread) {
      message.unread = Boolean(object.unread);
    }
    if (object.file) {
      message.file = ChatFile.fromJSON(object.file);
    }
    if (object.deleted) {
      message.deleted = Boolean(object.deleted);
    }
    return message;
  },
  fromPartial(object: DeepPartial<ChatMessage>): ChatMessage {
    const message = Object.create(baseChatMessage) as ChatMessage;
    if (object.messageId) {
      message.messageId = object.messageId;
    }
    if (object.senderAccountId) {
      message.senderAccountId = object.senderAccountId;
    }
    if (object.sentAt) {
      message.sentAt = object.sentAt;
    }
    if (object.message) {
      message.message = object.message;
    }
    if (object.unread) {
      message.unread = object.unread;
    }
    if (object.file) {
      message.file = ChatFile.fromPartial(object.file);
    }
    if (object.deleted) {
      message.deleted = object.deleted;
    }
    return message;
  },
  toJSON(message: ChatMessage): unknown {
    const obj: any = {};
    obj.messageId = message.messageId || 0;
    obj.senderAccountId = message.senderAccountId || "";
    obj.sentAt = message.sentAt || 0;
    obj.message = message.message || "";
    obj.unread = message.unread || false;
    obj.file = message.file ? ChatFile.toJSON(message.file) : undefined;
    obj.deleted = message.deleted || false;
    return obj;
  },
};

export const ChatRoom = {
  encode(message: ChatRoom, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.chatRoomId);
    writer.uint32(16).int64(message.updatedAt);
    writer.uint32(26).string(message.latestMessage);
    writer.uint32(32).int64(message.unreadCount);
    if (message.chatUser !== undefined && message.chatUser !== undefined) {
      ChatUser.encode(message.chatUser, writer.uint32(42).fork()).ldelim();
    }
    writer.uint32(48).bool(message.unpublished);
    writer.uint32(56).bool(message.admin);
    return writer;
  },
  decode(reader: Reader, length?: number): ChatRoom {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseChatRoom) as ChatRoom;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.chatRoomId = reader.string();
          break;
        case 2:
          message.updatedAt = longToNumber(reader.int64() as Long);
          break;
        case 3:
          message.latestMessage = reader.string();
          break;
        case 4:
          message.unreadCount = longToNumber(reader.int64() as Long);
          break;
        case 5:
          message.chatUser = ChatUser.decode(reader, reader.uint32());
          break;
        case 6:
          message.unpublished = reader.bool();
          break;
        case 7:
          message.admin = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): ChatRoom {
    const message = Object.create(baseChatRoom) as ChatRoom;
    if (object.chatRoomId) {
      message.chatRoomId = String(object.chatRoomId);
    }
    if (object.updatedAt) {
      message.updatedAt = Number(object.updatedAt);
    }
    if (object.latestMessage) {
      message.latestMessage = String(object.latestMessage);
    }
    if (object.unreadCount) {
      message.unreadCount = Number(object.unreadCount);
    }
    if (object.chatUser) {
      message.chatUser = ChatUser.fromJSON(object.chatUser);
    }
    if (object.unpublished) {
      message.unpublished = Boolean(object.unpublished);
    }
    if (object.admin) {
      message.admin = Boolean(object.admin);
    }
    return message;
  },
  fromPartial(object: DeepPartial<ChatRoom>): ChatRoom {
    const message = Object.create(baseChatRoom) as ChatRoom;
    if (object.chatRoomId) {
      message.chatRoomId = object.chatRoomId;
    }
    if (object.updatedAt) {
      message.updatedAt = object.updatedAt;
    }
    if (object.latestMessage) {
      message.latestMessage = object.latestMessage;
    }
    if (object.unreadCount) {
      message.unreadCount = object.unreadCount;
    }
    if (object.chatUser) {
      message.chatUser = ChatUser.fromPartial(object.chatUser);
    }
    if (object.unpublished) {
      message.unpublished = object.unpublished;
    }
    if (object.admin) {
      message.admin = object.admin;
    }
    return message;
  },
  toJSON(message: ChatRoom): unknown {
    const obj: any = {};
    obj.chatRoomId = message.chatRoomId || "";
    obj.updatedAt = message.updatedAt || 0;
    obj.latestMessage = message.latestMessage || "";
    obj.unreadCount = message.unreadCount || 0;
    obj.chatUser = message.chatUser ? ChatUser.toJSON(message.chatUser) : undefined;
    obj.unpublished = message.unpublished || false;
    obj.admin = message.admin || false;
    return obj;
  },
};

export const ChatUser = {
  encode(message: ChatUser, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.accountId);
    writer.uint32(18).string(message.name);
    writer.uint32(26).string(message.iconImageUrl);
    writer.uint32(34).string(message.affiliation);
    writer.uint32(40).bool(message.uncontactable);
    if (message.mission !== undefined && message.mission !== undefined) {
      ChatUser_Mission.encode(message.mission, writer.uint32(50).fork()).ldelim();
    }
    if (message.supporter !== undefined && message.supporter !== undefined) {
      ChatUser_Supporter.encode(message.supporter, writer.uint32(58).fork()).ldelim();
    }
    if (message.innovator !== undefined && message.innovator !== undefined) {
      ChatUser_Innovator.encode(message.innovator, writer.uint32(66).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): ChatUser {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseChatUser) as ChatUser;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.accountId = reader.string();
          break;
        case 2:
          message.name = reader.string();
          break;
        case 3:
          message.iconImageUrl = reader.string();
          break;
        case 4:
          message.affiliation = reader.string();
          break;
        case 5:
          message.uncontactable = reader.bool();
          break;
        case 6:
          message.mission = ChatUser_Mission.decode(reader, reader.uint32());
          break;
        case 7:
          message.supporter = ChatUser_Supporter.decode(reader, reader.uint32());
          break;
        case 8:
          message.innovator = ChatUser_Innovator.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): ChatUser {
    const message = Object.create(baseChatUser) as ChatUser;
    if (object.accountId) {
      message.accountId = String(object.accountId);
    }
    if (object.name) {
      message.name = String(object.name);
    }
    if (object.iconImageUrl) {
      message.iconImageUrl = String(object.iconImageUrl);
    }
    if (object.affiliation) {
      message.affiliation = String(object.affiliation);
    }
    if (object.uncontactable) {
      message.uncontactable = Boolean(object.uncontactable);
    }
    if (object.mission) {
      message.mission = ChatUser_Mission.fromJSON(object.mission);
    }
    if (object.supporter) {
      message.supporter = ChatUser_Supporter.fromJSON(object.supporter);
    }
    if (object.innovator) {
      message.innovator = ChatUser_Innovator.fromJSON(object.innovator);
    }
    return message;
  },
  fromPartial(object: DeepPartial<ChatUser>): ChatUser {
    const message = Object.create(baseChatUser) as ChatUser;
    if (object.accountId) {
      message.accountId = object.accountId;
    }
    if (object.name) {
      message.name = object.name;
    }
    if (object.iconImageUrl) {
      message.iconImageUrl = object.iconImageUrl;
    }
    if (object.affiliation) {
      message.affiliation = object.affiliation;
    }
    if (object.uncontactable) {
      message.uncontactable = object.uncontactable;
    }
    if (object.mission) {
      message.mission = ChatUser_Mission.fromPartial(object.mission);
    }
    if (object.supporter) {
      message.supporter = ChatUser_Supporter.fromPartial(object.supporter);
    }
    if (object.innovator) {
      message.innovator = ChatUser_Innovator.fromPartial(object.innovator);
    }
    return message;
  },
  toJSON(message: ChatUser): unknown {
    const obj: any = {};
    obj.accountId = message.accountId || "";
    obj.name = message.name || "";
    obj.iconImageUrl = message.iconImageUrl || "";
    obj.affiliation = message.affiliation || "";
    obj.uncontactable = message.uncontactable || false;
    obj.mission = message.mission ? ChatUser_Mission.toJSON(message.mission) : undefined;
    obj.supporter = message.supporter ? ChatUser_Supporter.toJSON(message.supporter) : undefined;
    obj.innovator = message.innovator ? ChatUser_Innovator.toJSON(message.innovator) : undefined;
    return obj;
  },
};

export const ChatUser_Mission = {
  encode(message: ChatUser_Mission, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.missionId);
    writer.uint32(18).string(message.companyName);
    writer.uint32(24).bool(message.unpublished);
    return writer;
  },
  decode(reader: Reader, length?: number): ChatUser_Mission {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseChatUser_Mission) as ChatUser_Mission;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.missionId = reader.string();
          break;
        case 2:
          message.companyName = reader.string();
          break;
        case 3:
          message.unpublished = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): ChatUser_Mission {
    const message = Object.create(baseChatUser_Mission) as ChatUser_Mission;
    if (object.missionId) {
      message.missionId = String(object.missionId);
    }
    if (object.companyName) {
      message.companyName = String(object.companyName);
    }
    if (object.unpublished) {
      message.unpublished = Boolean(object.unpublished);
    }
    return message;
  },
  fromPartial(object: DeepPartial<ChatUser_Mission>): ChatUser_Mission {
    const message = Object.create(baseChatUser_Mission) as ChatUser_Mission;
    if (object.missionId) {
      message.missionId = object.missionId;
    }
    if (object.companyName) {
      message.companyName = object.companyName;
    }
    if (object.unpublished) {
      message.unpublished = object.unpublished;
    }
    return message;
  },
  toJSON(message: ChatUser_Mission): unknown {
    const obj: any = {};
    obj.missionId = message.missionId || "";
    obj.companyName = message.companyName || "";
    obj.unpublished = message.unpublished || false;
    return obj;
  },
};

export const ChatUser_Supporter = {
  encode(message: ChatUser_Supporter, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.supporterId);
    writer.uint32(18).string(message.companyName);
    writer.uint32(24).bool(message.unpublished);
    return writer;
  },
  decode(reader: Reader, length?: number): ChatUser_Supporter {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseChatUser_Supporter) as ChatUser_Supporter;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.supporterId = reader.string();
          break;
        case 2:
          message.companyName = reader.string();
          break;
        case 3:
          message.unpublished = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): ChatUser_Supporter {
    const message = Object.create(baseChatUser_Supporter) as ChatUser_Supporter;
    if (object.supporterId) {
      message.supporterId = String(object.supporterId);
    }
    if (object.companyName) {
      message.companyName = String(object.companyName);
    }
    if (object.unpublished) {
      message.unpublished = Boolean(object.unpublished);
    }
    return message;
  },
  fromPartial(object: DeepPartial<ChatUser_Supporter>): ChatUser_Supporter {
    const message = Object.create(baseChatUser_Supporter) as ChatUser_Supporter;
    if (object.supporterId) {
      message.supporterId = object.supporterId;
    }
    if (object.companyName) {
      message.companyName = object.companyName;
    }
    if (object.unpublished) {
      message.unpublished = object.unpublished;
    }
    return message;
  },
  toJSON(message: ChatUser_Supporter): unknown {
    const obj: any = {};
    obj.supporterId = message.supporterId || "";
    obj.companyName = message.companyName || "";
    obj.unpublished = message.unpublished || false;
    return obj;
  },
};

export const ChatUser_Innovator = {
  encode(message: ChatUser_Innovator, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.innovatorId);
    writer.uint32(18).string(message.matterId);
    if (message.career !== undefined && message.career !== undefined) {
      ChatUser_CareerProfile.encode(message.career, writer.uint32(26).fork()).ldelim();
    }
    if (message.newGraduate !== undefined && message.newGraduate !== undefined) {
      ChatUser_NewGraduateProfile.encode(message.newGraduate, writer.uint32(34).fork()).ldelim();
    }
    writer.uint32(40).bool(message.unpublished);
    writer.uint32(48).bool(message.deleted);
    return writer;
  },
  decode(reader: Reader, length?: number): ChatUser_Innovator {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseChatUser_Innovator) as ChatUser_Innovator;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.innovatorId = reader.string();
          break;
        case 2:
          message.matterId = reader.string();
          break;
        case 3:
          message.career = ChatUser_CareerProfile.decode(reader, reader.uint32());
          break;
        case 4:
          message.newGraduate = ChatUser_NewGraduateProfile.decode(reader, reader.uint32());
          break;
        case 5:
          message.unpublished = reader.bool();
          break;
        case 6:
          message.deleted = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): ChatUser_Innovator {
    const message = Object.create(baseChatUser_Innovator) as ChatUser_Innovator;
    if (object.innovatorId) {
      message.innovatorId = String(object.innovatorId);
    }
    if (object.matterId) {
      message.matterId = String(object.matterId);
    }
    if (object.career) {
      message.career = ChatUser_CareerProfile.fromJSON(object.career);
    }
    if (object.newGraduate) {
      message.newGraduate = ChatUser_NewGraduateProfile.fromJSON(object.newGraduate);
    }
    if (object.unpublished) {
      message.unpublished = Boolean(object.unpublished);
    }
    if (object.deleted) {
      message.deleted = Boolean(object.deleted);
    }
    return message;
  },
  fromPartial(object: DeepPartial<ChatUser_Innovator>): ChatUser_Innovator {
    const message = Object.create(baseChatUser_Innovator) as ChatUser_Innovator;
    if (object.innovatorId) {
      message.innovatorId = object.innovatorId;
    }
    if (object.matterId) {
      message.matterId = object.matterId;
    }
    if (object.career) {
      message.career = ChatUser_CareerProfile.fromPartial(object.career);
    }
    if (object.newGraduate) {
      message.newGraduate = ChatUser_NewGraduateProfile.fromPartial(object.newGraduate);
    }
    if (object.unpublished) {
      message.unpublished = object.unpublished;
    }
    if (object.deleted) {
      message.deleted = object.deleted;
    }
    return message;
  },
  toJSON(message: ChatUser_Innovator): unknown {
    const obj: any = {};
    obj.innovatorId = message.innovatorId || "";
    obj.matterId = message.matterId || "";
    obj.career = message.career ? ChatUser_CareerProfile.toJSON(message.career) : undefined;
    obj.newGraduate = message.newGraduate ? ChatUser_NewGraduateProfile.toJSON(message.newGraduate) : undefined;
    obj.unpublished = message.unpublished || false;
    obj.deleted = message.deleted || false;
    return obj;
  },
};

export const ChatUser_CareerProfile = {
  encode(message: ChatUser_CareerProfile, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.companyName);
    if (message.preferredEmploymentType !== undefined && message.preferredEmploymentType !== undefined) {
      EmploymentType.encode(message.preferredEmploymentType, writer.uint32(18).fork()).ldelim();
    }
    if (message.academicBackground !== undefined && message.academicBackground !== undefined) {
      AcademicBackground.encode(message.academicBackground, writer.uint32(26).fork()).ldelim();
    }
    writer.uint32(34).string(message.lastName);
    writer.uint32(42).string(message.firstName);
    return writer;
  },
  decode(reader: Reader, length?: number): ChatUser_CareerProfile {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseChatUser_CareerProfile) as ChatUser_CareerProfile;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.companyName = reader.string();
          break;
        case 2:
          message.preferredEmploymentType = EmploymentType.decode(reader, reader.uint32());
          break;
        case 3:
          message.academicBackground = AcademicBackground.decode(reader, reader.uint32());
          break;
        case 4:
          message.lastName = reader.string();
          break;
        case 5:
          message.firstName = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): ChatUser_CareerProfile {
    const message = Object.create(baseChatUser_CareerProfile) as ChatUser_CareerProfile;
    if (object.companyName) {
      message.companyName = String(object.companyName);
    }
    if (object.preferredEmploymentType) {
      message.preferredEmploymentType = EmploymentType.fromJSON(object.preferredEmploymentType);
    }
    if (object.academicBackground) {
      message.academicBackground = AcademicBackground.fromJSON(object.academicBackground);
    }
    if (object.lastName) {
      message.lastName = String(object.lastName);
    }
    if (object.firstName) {
      message.firstName = String(object.firstName);
    }
    return message;
  },
  fromPartial(object: DeepPartial<ChatUser_CareerProfile>): ChatUser_CareerProfile {
    const message = Object.create(baseChatUser_CareerProfile) as ChatUser_CareerProfile;
    if (object.companyName) {
      message.companyName = object.companyName;
    }
    if (object.preferredEmploymentType) {
      message.preferredEmploymentType = EmploymentType.fromPartial(object.preferredEmploymentType);
    }
    if (object.academicBackground) {
      message.academicBackground = AcademicBackground.fromPartial(object.academicBackground);
    }
    if (object.lastName) {
      message.lastName = object.lastName;
    }
    if (object.firstName) {
      message.firstName = object.firstName;
    }
    return message;
  },
  toJSON(message: ChatUser_CareerProfile): unknown {
    const obj: any = {};
    obj.companyName = message.companyName || "";
    obj.preferredEmploymentType = message.preferredEmploymentType ? EmploymentType.toJSON(message.preferredEmploymentType) : undefined;
    obj.academicBackground = message.academicBackground ? AcademicBackground.toJSON(message.academicBackground) : undefined;
    obj.lastName = message.lastName || "";
    obj.firstName = message.firstName || "";
    return obj;
  },
};

export const ChatUser_NewGraduateProfile = {
  encode(message: ChatUser_NewGraduateProfile, writer: Writer = Writer.create()): Writer {
    if (message.academicBackground !== undefined && message.academicBackground !== undefined) {
      AcademicBackground.encode(message.academicBackground, writer.uint32(10).fork()).ldelim();
    }
    writer.uint32(18).string(message.lastName);
    writer.uint32(26).string(message.firstName);
    return writer;
  },
  decode(reader: Reader, length?: number): ChatUser_NewGraduateProfile {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseChatUser_NewGraduateProfile) as ChatUser_NewGraduateProfile;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.academicBackground = AcademicBackground.decode(reader, reader.uint32());
          break;
        case 2:
          message.lastName = reader.string();
          break;
        case 3:
          message.firstName = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): ChatUser_NewGraduateProfile {
    const message = Object.create(baseChatUser_NewGraduateProfile) as ChatUser_NewGraduateProfile;
    if (object.academicBackground) {
      message.academicBackground = AcademicBackground.fromJSON(object.academicBackground);
    }
    if (object.lastName) {
      message.lastName = String(object.lastName);
    }
    if (object.firstName) {
      message.firstName = String(object.firstName);
    }
    return message;
  },
  fromPartial(object: DeepPartial<ChatUser_NewGraduateProfile>): ChatUser_NewGraduateProfile {
    const message = Object.create(baseChatUser_NewGraduateProfile) as ChatUser_NewGraduateProfile;
    if (object.academicBackground) {
      message.academicBackground = AcademicBackground.fromPartial(object.academicBackground);
    }
    if (object.lastName) {
      message.lastName = object.lastName;
    }
    if (object.firstName) {
      message.firstName = object.firstName;
    }
    return message;
  },
  toJSON(message: ChatUser_NewGraduateProfile): unknown {
    const obj: any = {};
    obj.academicBackground = message.academicBackground ? AcademicBackground.toJSON(message.academicBackground) : undefined;
    obj.lastName = message.lastName || "";
    obj.firstName = message.firstName || "";
    return obj;
  },
};

export const ChatFile = {
  encode(message: ChatFile, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.url);
    writer.uint32(16).int64(message.size);
    return writer;
  },
  decode(reader: Reader, length?: number): ChatFile {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseChatFile) as ChatFile;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.url = reader.string();
          break;
        case 2:
          message.size = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): ChatFile {
    const message = Object.create(baseChatFile) as ChatFile;
    if (object.url) {
      message.url = String(object.url);
    }
    if (object.size) {
      message.size = Number(object.size);
    }
    return message;
  },
  fromPartial(object: DeepPartial<ChatFile>): ChatFile {
    const message = Object.create(baseChatFile) as ChatFile;
    if (object.url) {
      message.url = object.url;
    }
    if (object.size) {
      message.size = object.size;
    }
    return message;
  },
  toJSON(message: ChatFile): unknown {
    const obj: any = {};
    obj.url = message.url || "";
    obj.size = message.size || 0;
    return obj;
  },
};

export const InnovatorChatRoom = {
  encode(message: InnovatorChatRoom, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.chatRoomId);
    writer.uint32(16).int64(message.updatedAt);
    writer.uint32(26).string(message.latestMessage);
    writer.uint32(32).int64(message.unreadCount);
    if (message.chatUser !== undefined && message.chatUser !== undefined) {
      InnovatorChatUser.encode(message.chatUser, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): InnovatorChatRoom {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseInnovatorChatRoom) as InnovatorChatRoom;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.chatRoomId = reader.string();
          break;
        case 2:
          message.updatedAt = longToNumber(reader.int64() as Long);
          break;
        case 3:
          message.latestMessage = reader.string();
          break;
        case 4:
          message.unreadCount = longToNumber(reader.int64() as Long);
          break;
        case 5:
          message.chatUser = InnovatorChatUser.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): InnovatorChatRoom {
    const message = Object.create(baseInnovatorChatRoom) as InnovatorChatRoom;
    if (object.chatRoomId) {
      message.chatRoomId = String(object.chatRoomId);
    }
    if (object.updatedAt) {
      message.updatedAt = Number(object.updatedAt);
    }
    if (object.latestMessage) {
      message.latestMessage = String(object.latestMessage);
    }
    if (object.unreadCount) {
      message.unreadCount = Number(object.unreadCount);
    }
    if (object.chatUser) {
      message.chatUser = InnovatorChatUser.fromJSON(object.chatUser);
    }
    return message;
  },
  fromPartial(object: DeepPartial<InnovatorChatRoom>): InnovatorChatRoom {
    const message = Object.create(baseInnovatorChatRoom) as InnovatorChatRoom;
    if (object.chatRoomId) {
      message.chatRoomId = object.chatRoomId;
    }
    if (object.updatedAt) {
      message.updatedAt = object.updatedAt;
    }
    if (object.latestMessage) {
      message.latestMessage = object.latestMessage;
    }
    if (object.unreadCount) {
      message.unreadCount = object.unreadCount;
    }
    if (object.chatUser) {
      message.chatUser = InnovatorChatUser.fromPartial(object.chatUser);
    }
    return message;
  },
  toJSON(message: InnovatorChatRoom): unknown {
    const obj: any = {};
    obj.chatRoomId = message.chatRoomId || "";
    obj.updatedAt = message.updatedAt || 0;
    obj.latestMessage = message.latestMessage || "";
    obj.unreadCount = message.unreadCount || 0;
    obj.chatUser = message.chatUser ? InnovatorChatUser.toJSON(message.chatUser) : undefined;
    return obj;
  },
};

export const InnovatorChatUser = {
  encode(message: InnovatorChatUser, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.accountId);
    writer.uint32(18).string(message.iconImageUrl);
    if (message.mission !== undefined && message.mission !== undefined) {
      InnovatorChatUser_Mission.encode(message.mission, writer.uint32(26).fork()).ldelim();
    }
    if (message.consultant !== undefined && message.consultant !== undefined) {
      InnovatorChatUser_Consultant.encode(message.consultant, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): InnovatorChatUser {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseInnovatorChatUser) as InnovatorChatUser;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.accountId = reader.string();
          break;
        case 2:
          message.iconImageUrl = reader.string();
          break;
        case 3:
          message.mission = InnovatorChatUser_Mission.decode(reader, reader.uint32());
          break;
        case 4:
          message.consultant = InnovatorChatUser_Consultant.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): InnovatorChatUser {
    const message = Object.create(baseInnovatorChatUser) as InnovatorChatUser;
    if (object.accountId) {
      message.accountId = String(object.accountId);
    }
    if (object.iconImageUrl) {
      message.iconImageUrl = String(object.iconImageUrl);
    }
    if (object.mission) {
      message.mission = InnovatorChatUser_Mission.fromJSON(object.mission);
    }
    if (object.consultant) {
      message.consultant = InnovatorChatUser_Consultant.fromJSON(object.consultant);
    }
    return message;
  },
  fromPartial(object: DeepPartial<InnovatorChatUser>): InnovatorChatUser {
    const message = Object.create(baseInnovatorChatUser) as InnovatorChatUser;
    if (object.accountId) {
      message.accountId = object.accountId;
    }
    if (object.iconImageUrl) {
      message.iconImageUrl = object.iconImageUrl;
    }
    if (object.mission) {
      message.mission = InnovatorChatUser_Mission.fromPartial(object.mission);
    }
    if (object.consultant) {
      message.consultant = InnovatorChatUser_Consultant.fromPartial(object.consultant);
    }
    return message;
  },
  toJSON(message: InnovatorChatUser): unknown {
    const obj: any = {};
    obj.accountId = message.accountId || "";
    obj.iconImageUrl = message.iconImageUrl || "";
    obj.mission = message.mission ? InnovatorChatUser_Mission.toJSON(message.mission) : undefined;
    obj.consultant = message.consultant ? InnovatorChatUser_Consultant.toJSON(message.consultant) : undefined;
    return obj;
  },
};

export const InnovatorChatUser_Mission = {
  encode(message: InnovatorChatUser_Mission, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.missionId);
    writer.uint32(18).string(message.name);
    writer.uint32(26).string(message.companyName);
    writer.uint32(32).bool(message.unpublished);
    return writer;
  },
  decode(reader: Reader, length?: number): InnovatorChatUser_Mission {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseInnovatorChatUser_Mission) as InnovatorChatUser_Mission;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.missionId = reader.string();
          break;
        case 2:
          message.name = reader.string();
          break;
        case 3:
          message.companyName = reader.string();
          break;
        case 4:
          message.unpublished = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): InnovatorChatUser_Mission {
    const message = Object.create(baseInnovatorChatUser_Mission) as InnovatorChatUser_Mission;
    if (object.missionId) {
      message.missionId = String(object.missionId);
    }
    if (object.name) {
      message.name = String(object.name);
    }
    if (object.companyName) {
      message.companyName = String(object.companyName);
    }
    if (object.unpublished) {
      message.unpublished = Boolean(object.unpublished);
    }
    return message;
  },
  fromPartial(object: DeepPartial<InnovatorChatUser_Mission>): InnovatorChatUser_Mission {
    const message = Object.create(baseInnovatorChatUser_Mission) as InnovatorChatUser_Mission;
    if (object.missionId) {
      message.missionId = object.missionId;
    }
    if (object.name) {
      message.name = object.name;
    }
    if (object.companyName) {
      message.companyName = object.companyName;
    }
    if (object.unpublished) {
      message.unpublished = object.unpublished;
    }
    return message;
  },
  toJSON(message: InnovatorChatUser_Mission): unknown {
    const obj: any = {};
    obj.missionId = message.missionId || "";
    obj.name = message.name || "";
    obj.companyName = message.companyName || "";
    obj.unpublished = message.unpublished || false;
    return obj;
  },
};

export const InnovatorChatUser_Consultant = {
  encode(message: InnovatorChatUser_Consultant, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.consultantId);
    return writer;
  },
  decode(reader: Reader, length?: number): InnovatorChatUser_Consultant {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseInnovatorChatUser_Consultant) as InnovatorChatUser_Consultant;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.consultantId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): InnovatorChatUser_Consultant {
    const message = Object.create(baseInnovatorChatUser_Consultant) as InnovatorChatUser_Consultant;
    if (object.consultantId) {
      message.consultantId = String(object.consultantId);
    }
    return message;
  },
  fromPartial(object: DeepPartial<InnovatorChatUser_Consultant>): InnovatorChatUser_Consultant {
    const message = Object.create(baseInnovatorChatUser_Consultant) as InnovatorChatUser_Consultant;
    if (object.consultantId) {
      message.consultantId = object.consultantId;
    }
    return message;
  },
  toJSON(message: InnovatorChatUser_Consultant): unknown {
    const obj: any = {};
    obj.consultantId = message.consultantId || "";
    return obj;
  },
};

export const ConsultantChatRoom = {
  encode(message: ConsultantChatRoom, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.chatRoomId);
    writer.uint32(16).int64(message.updatedAt);
    writer.uint32(26).string(message.latestMessage);
    writer.uint32(32).int64(message.unreadCount);
    if (message.chatUser !== undefined && message.chatUser !== undefined) {
      ConsultantChatUser.encode(message.chatUser, writer.uint32(42).fork()).ldelim();
    }
    writer.uint32(48).bool(message.handled);
    writer.uint32(58).string(message.staffName);
    return writer;
  },
  decode(reader: Reader, length?: number): ConsultantChatRoom {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseConsultantChatRoom) as ConsultantChatRoom;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.chatRoomId = reader.string();
          break;
        case 2:
          message.updatedAt = longToNumber(reader.int64() as Long);
          break;
        case 3:
          message.latestMessage = reader.string();
          break;
        case 4:
          message.unreadCount = longToNumber(reader.int64() as Long);
          break;
        case 5:
          message.chatUser = ConsultantChatUser.decode(reader, reader.uint32());
          break;
        case 6:
          message.handled = reader.bool();
          break;
        case 7:
          message.staffName = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): ConsultantChatRoom {
    const message = Object.create(baseConsultantChatRoom) as ConsultantChatRoom;
    if (object.chatRoomId) {
      message.chatRoomId = String(object.chatRoomId);
    }
    if (object.updatedAt) {
      message.updatedAt = Number(object.updatedAt);
    }
    if (object.latestMessage) {
      message.latestMessage = String(object.latestMessage);
    }
    if (object.unreadCount) {
      message.unreadCount = Number(object.unreadCount);
    }
    if (object.chatUser) {
      message.chatUser = ConsultantChatUser.fromJSON(object.chatUser);
    }
    if (object.handled) {
      message.handled = Boolean(object.handled);
    }
    if (object.staffName) {
      message.staffName = String(object.staffName);
    }
    return message;
  },
  fromPartial(object: DeepPartial<ConsultantChatRoom>): ConsultantChatRoom {
    const message = Object.create(baseConsultantChatRoom) as ConsultantChatRoom;
    if (object.chatRoomId) {
      message.chatRoomId = object.chatRoomId;
    }
    if (object.updatedAt) {
      message.updatedAt = object.updatedAt;
    }
    if (object.latestMessage) {
      message.latestMessage = object.latestMessage;
    }
    if (object.unreadCount) {
      message.unreadCount = object.unreadCount;
    }
    if (object.chatUser) {
      message.chatUser = ConsultantChatUser.fromPartial(object.chatUser);
    }
    if (object.handled) {
      message.handled = object.handled;
    }
    if (object.staffName) {
      message.staffName = object.staffName;
    }
    return message;
  },
  toJSON(message: ConsultantChatRoom): unknown {
    const obj: any = {};
    obj.chatRoomId = message.chatRoomId || "";
    obj.updatedAt = message.updatedAt || 0;
    obj.latestMessage = message.latestMessage || "";
    obj.unreadCount = message.unreadCount || 0;
    obj.chatUser = message.chatUser ? ConsultantChatUser.toJSON(message.chatUser) : undefined;
    obj.handled = message.handled || false;
    obj.staffName = message.staffName || "";
    return obj;
  },
};

export const ConsultantChatUser = {
  encode(message: ConsultantChatUser, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.accountId);
    writer.uint32(18).string(message.matterId);
    writer.uint32(24).int64(message.matterPublishId);
    if (message.career !== undefined && message.career !== undefined) {
      ConsultantChatUser_CareerProfile.encode(message.career, writer.uint32(34).fork()).ldelim();
    }
    if (message.newGraduate !== undefined && message.newGraduate !== undefined) {
      ConsultantChatUser_NewGraduateProfile.encode(message.newGraduate, writer.uint32(42).fork()).ldelim();
    }
    if (message.status !== undefined && message.status !== undefined) {
      MatterPublishStatus.encode(message.status, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): ConsultantChatUser {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseConsultantChatUser) as ConsultantChatUser;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.accountId = reader.string();
          break;
        case 2:
          message.matterId = reader.string();
          break;
        case 3:
          message.matterPublishId = longToNumber(reader.int64() as Long);
          break;
        case 4:
          message.career = ConsultantChatUser_CareerProfile.decode(reader, reader.uint32());
          break;
        case 5:
          message.newGraduate = ConsultantChatUser_NewGraduateProfile.decode(reader, reader.uint32());
          break;
        case 6:
          message.status = MatterPublishStatus.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): ConsultantChatUser {
    const message = Object.create(baseConsultantChatUser) as ConsultantChatUser;
    if (object.accountId) {
      message.accountId = String(object.accountId);
    }
    if (object.matterId) {
      message.matterId = String(object.matterId);
    }
    if (object.matterPublishId) {
      message.matterPublishId = Number(object.matterPublishId);
    }
    if (object.career) {
      message.career = ConsultantChatUser_CareerProfile.fromJSON(object.career);
    }
    if (object.newGraduate) {
      message.newGraduate = ConsultantChatUser_NewGraduateProfile.fromJSON(object.newGraduate);
    }
    if (object.status) {
      message.status = MatterPublishStatus.fromJSON(object.status);
    }
    return message;
  },
  fromPartial(object: DeepPartial<ConsultantChatUser>): ConsultantChatUser {
    const message = Object.create(baseConsultantChatUser) as ConsultantChatUser;
    if (object.accountId) {
      message.accountId = object.accountId;
    }
    if (object.matterId) {
      message.matterId = object.matterId;
    }
    if (object.matterPublishId) {
      message.matterPublishId = object.matterPublishId;
    }
    if (object.career) {
      message.career = ConsultantChatUser_CareerProfile.fromPartial(object.career);
    }
    if (object.newGraduate) {
      message.newGraduate = ConsultantChatUser_NewGraduateProfile.fromPartial(object.newGraduate);
    }
    if (object.status) {
      message.status = MatterPublishStatus.fromPartial(object.status);
    }
    return message;
  },
  toJSON(message: ConsultantChatUser): unknown {
    const obj: any = {};
    obj.accountId = message.accountId || "";
    obj.matterId = message.matterId || "";
    obj.matterPublishId = message.matterPublishId || 0;
    obj.career = message.career ? ConsultantChatUser_CareerProfile.toJSON(message.career) : undefined;
    obj.newGraduate = message.newGraduate ? ConsultantChatUser_NewGraduateProfile.toJSON(message.newGraduate) : undefined;
    obj.status = message.status ? MatterPublishStatus.toJSON(message.status) : undefined;
    return obj;
  },
};

export const ConsultantChatUser_CareerProfile = {
  encode(message: ConsultantChatUser_CareerProfile, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.name);
    writer.uint32(18).string(message.companyName);
    return writer;
  },
  decode(reader: Reader, length?: number): ConsultantChatUser_CareerProfile {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseConsultantChatUser_CareerProfile) as ConsultantChatUser_CareerProfile;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.name = reader.string();
          break;
        case 2:
          message.companyName = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): ConsultantChatUser_CareerProfile {
    const message = Object.create(baseConsultantChatUser_CareerProfile) as ConsultantChatUser_CareerProfile;
    if (object.name) {
      message.name = String(object.name);
    }
    if (object.companyName) {
      message.companyName = String(object.companyName);
    }
    return message;
  },
  fromPartial(object: DeepPartial<ConsultantChatUser_CareerProfile>): ConsultantChatUser_CareerProfile {
    const message = Object.create(baseConsultantChatUser_CareerProfile) as ConsultantChatUser_CareerProfile;
    if (object.name) {
      message.name = object.name;
    }
    if (object.companyName) {
      message.companyName = object.companyName;
    }
    return message;
  },
  toJSON(message: ConsultantChatUser_CareerProfile): unknown {
    const obj: any = {};
    obj.name = message.name || "";
    obj.companyName = message.companyName || "";
    return obj;
  },
};

export const ConsultantChatUser_NewGraduateProfile = {
  encode(message: ConsultantChatUser_NewGraduateProfile, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.name);
    if (message.academicBackground !== undefined && message.academicBackground !== undefined) {
      ConsultantChatUser_AcademicBackground.encode(message.academicBackground, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): ConsultantChatUser_NewGraduateProfile {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseConsultantChatUser_NewGraduateProfile) as ConsultantChatUser_NewGraduateProfile;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.name = reader.string();
          break;
        case 2:
          message.academicBackground = ConsultantChatUser_AcademicBackground.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): ConsultantChatUser_NewGraduateProfile {
    const message = Object.create(baseConsultantChatUser_NewGraduateProfile) as ConsultantChatUser_NewGraduateProfile;
    if (object.name) {
      message.name = String(object.name);
    }
    if (object.academicBackground) {
      message.academicBackground = ConsultantChatUser_AcademicBackground.fromJSON(object.academicBackground);
    }
    return message;
  },
  fromPartial(object: DeepPartial<ConsultantChatUser_NewGraduateProfile>): ConsultantChatUser_NewGraduateProfile {
    const message = Object.create(baseConsultantChatUser_NewGraduateProfile) as ConsultantChatUser_NewGraduateProfile;
    if (object.name) {
      message.name = object.name;
    }
    if (object.academicBackground) {
      message.academicBackground = ConsultantChatUser_AcademicBackground.fromPartial(object.academicBackground);
    }
    return message;
  },
  toJSON(message: ConsultantChatUser_NewGraduateProfile): unknown {
    const obj: any = {};
    obj.name = message.name || "";
    obj.academicBackground = message.academicBackground ? ConsultantChatUser_AcademicBackground.toJSON(message.academicBackground) : undefined;
    return obj;
  },
};

export const ConsultantChatUser_AcademicBackground = {
  encode(message: ConsultantChatUser_AcademicBackground, writer: Writer = Writer.create()): Writer {
    if (message.school !== undefined && message.school !== undefined) {
      School.encode(message.school, writer.uint32(10).fork()).ldelim();
    }
    writer.uint32(18).string(message.faculty);
    writer.uint32(24).uint32(message.yearOfGraduation);
    writer.uint32(32).uint32(message.monthOfGraduation);
    return writer;
  },
  decode(reader: Reader, length?: number): ConsultantChatUser_AcademicBackground {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseConsultantChatUser_AcademicBackground) as ConsultantChatUser_AcademicBackground;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.school = School.decode(reader, reader.uint32());
          break;
        case 2:
          message.faculty = reader.string();
          break;
        case 3:
          message.yearOfGraduation = reader.uint32();
          break;
        case 4:
          message.monthOfGraduation = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): ConsultantChatUser_AcademicBackground {
    const message = Object.create(baseConsultantChatUser_AcademicBackground) as ConsultantChatUser_AcademicBackground;
    if (object.school) {
      message.school = School.fromJSON(object.school);
    }
    if (object.faculty) {
      message.faculty = String(object.faculty);
    }
    if (object.yearOfGraduation) {
      message.yearOfGraduation = Number(object.yearOfGraduation);
    }
    if (object.monthOfGraduation) {
      message.monthOfGraduation = Number(object.monthOfGraduation);
    }
    return message;
  },
  fromPartial(object: DeepPartial<ConsultantChatUser_AcademicBackground>): ConsultantChatUser_AcademicBackground {
    const message = Object.create(baseConsultantChatUser_AcademicBackground) as ConsultantChatUser_AcademicBackground;
    if (object.school) {
      message.school = School.fromPartial(object.school);
    }
    if (object.faculty) {
      message.faculty = object.faculty;
    }
    if (object.yearOfGraduation) {
      message.yearOfGraduation = object.yearOfGraduation;
    }
    if (object.monthOfGraduation) {
      message.monthOfGraduation = object.monthOfGraduation;
    }
    return message;
  },
  toJSON(message: ConsultantChatUser_AcademicBackground): unknown {
    const obj: any = {};
    obj.school = message.school ? School.toJSON(message.school) : undefined;
    obj.faculty = message.faculty || "";
    obj.yearOfGraduation = message.yearOfGraduation || 0;
    obj.monthOfGraduation = message.monthOfGraduation || 0;
    return obj;
  },
};

export const InnovatorChatMessage = {
  encode(message: InnovatorChatMessage, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).int64(message.messageId);
    writer.uint32(18).string(message.senderAccountId);
    writer.uint32(24).int64(message.sentAt);
    writer.uint32(34).string(message.message);
    writer.uint32(40).bool(message.unread);
    if (message.file !== undefined && message.file !== undefined) {
      ChatFile.encode(message.file, writer.uint32(50).fork()).ldelim();
    }
    writer.uint32(56).bool(message.deleted);
    writer.uint32(64).bool(message.information);
    return writer;
  },
  decode(reader: Reader, length?: number): InnovatorChatMessage {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseInnovatorChatMessage) as InnovatorChatMessage;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.messageId = longToNumber(reader.int64() as Long);
          break;
        case 2:
          message.senderAccountId = reader.string();
          break;
        case 3:
          message.sentAt = longToNumber(reader.int64() as Long);
          break;
        case 4:
          message.message = reader.string();
          break;
        case 5:
          message.unread = reader.bool();
          break;
        case 6:
          message.file = ChatFile.decode(reader, reader.uint32());
          break;
        case 7:
          message.deleted = reader.bool();
          break;
        case 8:
          message.information = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): InnovatorChatMessage {
    const message = Object.create(baseInnovatorChatMessage) as InnovatorChatMessage;
    if (object.messageId) {
      message.messageId = Number(object.messageId);
    }
    if (object.senderAccountId) {
      message.senderAccountId = String(object.senderAccountId);
    }
    if (object.sentAt) {
      message.sentAt = Number(object.sentAt);
    }
    if (object.message) {
      message.message = String(object.message);
    }
    if (object.unread) {
      message.unread = Boolean(object.unread);
    }
    if (object.file) {
      message.file = ChatFile.fromJSON(object.file);
    }
    if (object.deleted) {
      message.deleted = Boolean(object.deleted);
    }
    if (object.information) {
      message.information = Boolean(object.information);
    }
    return message;
  },
  fromPartial(object: DeepPartial<InnovatorChatMessage>): InnovatorChatMessage {
    const message = Object.create(baseInnovatorChatMessage) as InnovatorChatMessage;
    if (object.messageId) {
      message.messageId = object.messageId;
    }
    if (object.senderAccountId) {
      message.senderAccountId = object.senderAccountId;
    }
    if (object.sentAt) {
      message.sentAt = object.sentAt;
    }
    if (object.message) {
      message.message = object.message;
    }
    if (object.unread) {
      message.unread = object.unread;
    }
    if (object.file) {
      message.file = ChatFile.fromPartial(object.file);
    }
    if (object.deleted) {
      message.deleted = object.deleted;
    }
    if (object.information) {
      message.information = object.information;
    }
    return message;
  },
  toJSON(message: InnovatorChatMessage): unknown {
    const obj: any = {};
    obj.messageId = message.messageId || 0;
    obj.senderAccountId = message.senderAccountId || "";
    obj.sentAt = message.sentAt || 0;
    obj.message = message.message || "";
    obj.unread = message.unread || false;
    obj.file = message.file ? ChatFile.toJSON(message.file) : undefined;
    obj.deleted = message.deleted || false;
    obj.information = message.information || false;
    return obj;
  },
};

export const ConsultantChatMessage = {
  encode(message: ConsultantChatMessage, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).int64(message.messageId);
    writer.uint32(18).string(message.senderAccountId);
    writer.uint32(24).int64(message.sentAt);
    writer.uint32(34).string(message.message);
    writer.uint32(40).bool(message.unread);
    if (message.file !== undefined && message.file !== undefined) {
      ChatFile.encode(message.file, writer.uint32(50).fork()).ldelim();
    }
    writer.uint32(56).bool(message.deleted);
    return writer;
  },
  decode(reader: Reader, length?: number): ConsultantChatMessage {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseConsultantChatMessage) as ConsultantChatMessage;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.messageId = longToNumber(reader.int64() as Long);
          break;
        case 2:
          message.senderAccountId = reader.string();
          break;
        case 3:
          message.sentAt = longToNumber(reader.int64() as Long);
          break;
        case 4:
          message.message = reader.string();
          break;
        case 5:
          message.unread = reader.bool();
          break;
        case 6:
          message.file = ChatFile.decode(reader, reader.uint32());
          break;
        case 7:
          message.deleted = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): ConsultantChatMessage {
    const message = Object.create(baseConsultantChatMessage) as ConsultantChatMessage;
    if (object.messageId) {
      message.messageId = Number(object.messageId);
    }
    if (object.senderAccountId) {
      message.senderAccountId = String(object.senderAccountId);
    }
    if (object.sentAt) {
      message.sentAt = Number(object.sentAt);
    }
    if (object.message) {
      message.message = String(object.message);
    }
    if (object.unread) {
      message.unread = Boolean(object.unread);
    }
    if (object.file) {
      message.file = ChatFile.fromJSON(object.file);
    }
    if (object.deleted) {
      message.deleted = Boolean(object.deleted);
    }
    return message;
  },
  fromPartial(object: DeepPartial<ConsultantChatMessage>): ConsultantChatMessage {
    const message = Object.create(baseConsultantChatMessage) as ConsultantChatMessage;
    if (object.messageId) {
      message.messageId = object.messageId;
    }
    if (object.senderAccountId) {
      message.senderAccountId = object.senderAccountId;
    }
    if (object.sentAt) {
      message.sentAt = object.sentAt;
    }
    if (object.message) {
      message.message = object.message;
    }
    if (object.unread) {
      message.unread = object.unread;
    }
    if (object.file) {
      message.file = ChatFile.fromPartial(object.file);
    }
    if (object.deleted) {
      message.deleted = object.deleted;
    }
    return message;
  },
  toJSON(message: ConsultantChatMessage): unknown {
    const obj: any = {};
    obj.messageId = message.messageId || 0;
    obj.senderAccountId = message.senderAccountId || "";
    obj.sentAt = message.sentAt || 0;
    obj.message = message.message || "";
    obj.unread = message.unread || false;
    obj.file = message.file ? ChatFile.toJSON(message.file) : undefined;
    obj.deleted = message.deleted || false;
    return obj;
  },
};

export const CorporateAttribute = {
  encode(message: CorporateAttribute, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).uint32(message.id);
    writer.uint32(18).string(message.name);
    writer.uint32(26).string(message.nameShort);
    return writer;
  },
  decode(reader: Reader, length?: number): CorporateAttribute {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseCorporateAttribute) as CorporateAttribute;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.uint32();
          break;
        case 2:
          message.name = reader.string();
          break;
        case 3:
          message.nameShort = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): CorporateAttribute {
    const message = Object.create(baseCorporateAttribute) as CorporateAttribute;
    if (object.id) {
      message.id = Number(object.id);
    }
    if (object.name) {
      message.name = String(object.name);
    }
    if (object.nameShort) {
      message.nameShort = String(object.nameShort);
    }
    return message;
  },
  fromPartial(object: DeepPartial<CorporateAttribute>): CorporateAttribute {
    const message = Object.create(baseCorporateAttribute) as CorporateAttribute;
    if (object.id) {
      message.id = object.id;
    }
    if (object.name) {
      message.name = object.name;
    }
    if (object.nameShort) {
      message.nameShort = object.nameShort;
    }
    return message;
  },
  toJSON(message: CorporateAttribute): unknown {
    const obj: any = {};
    obj.id = message.id || 0;
    obj.name = message.name || "";
    obj.nameShort = message.nameShort || "";
    return obj;
  },
};

export const Feature = {
  encode(message: Feature, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.id);
    writer.uint32(18).string(message.value);
    writer.uint32(26).string(message.description);
    return writer;
  },
  decode(reader: Reader, length?: number): Feature {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseFeature) as Feature;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string();
          break;
        case 2:
          message.value = reader.string();
          break;
        case 3:
          message.description = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): Feature {
    const message = Object.create(baseFeature) as Feature;
    if (object.id) {
      message.id = String(object.id);
    }
    if (object.value) {
      message.value = String(object.value);
    }
    if (object.description) {
      message.description = String(object.description);
    }
    return message;
  },
  fromPartial(object: DeepPartial<Feature>): Feature {
    const message = Object.create(baseFeature) as Feature;
    if (object.id) {
      message.id = object.id;
    }
    if (object.value) {
      message.value = object.value;
    }
    if (object.description) {
      message.description = object.description;
    }
    return message;
  },
  toJSON(message: Feature): unknown {
    const obj: any = {};
    obj.id = message.id || "";
    obj.value = message.value || "";
    obj.description = message.description || "";
    return obj;
  },
};

export const ScreeningStatus = {
  encode(message: ScreeningStatus, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).uint32(message.id);
    writer.uint32(18).string(message.name);
    return writer;
  },
  decode(reader: Reader, length?: number): ScreeningStatus {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseScreeningStatus) as ScreeningStatus;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.uint32();
          break;
        case 2:
          message.name = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): ScreeningStatus {
    const message = Object.create(baseScreeningStatus) as ScreeningStatus;
    if (object.id) {
      message.id = Number(object.id);
    }
    if (object.name) {
      message.name = String(object.name);
    }
    return message;
  },
  fromPartial(object: DeepPartial<ScreeningStatus>): ScreeningStatus {
    const message = Object.create(baseScreeningStatus) as ScreeningStatus;
    if (object.id) {
      message.id = object.id;
    }
    if (object.name) {
      message.name = object.name;
    }
    return message;
  },
  toJSON(message: ScreeningStatus): unknown {
    const obj: any = {};
    obj.id = message.id || 0;
    obj.name = message.name || "";
    return obj;
  },
};

export const ScreeningCompany = {
  encode(message: ScreeningCompany, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).uint32(message.applicationId);
    writer.uint32(18).string(message.companyName);
    writer.uint32(26).string(message.companyUrl);
    if (message.country !== undefined && message.country !== undefined) {
      Country.encode(message.country, writer.uint32(34).fork()).ldelim();
    }
    if (message.corporateAttribute !== undefined && message.corporateAttribute !== undefined) {
      CorporateAttribute.encode(message.corporateAttribute, writer.uint32(42).fork()).ldelim();
    }
    if (message.fund !== undefined && message.fund !== undefined) {
      Fund.encode(message.fund, writer.uint32(50).fork()).ldelim();
    }
    writer.uint32(58).string(message.applicantName);
    writer.uint32(66).string(message.email);
    writer.uint32(74).string(message.reasonForApplication);
    if (message.screeningStatus !== undefined && message.screeningStatus !== undefined) {
      ScreeningStatus.encode(message.screeningStatus, writer.uint32(82).fork()).ldelim();
    }
    writer.uint32(90).string(message.phoneCountryCode);
    writer.uint32(98).string(message.phoneNumber);
    return writer;
  },
  decode(reader: Reader, length?: number): ScreeningCompany {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseScreeningCompany) as ScreeningCompany;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.applicationId = reader.uint32();
          break;
        case 2:
          message.companyName = reader.string();
          break;
        case 3:
          message.companyUrl = reader.string();
          break;
        case 4:
          message.country = Country.decode(reader, reader.uint32());
          break;
        case 5:
          message.corporateAttribute = CorporateAttribute.decode(reader, reader.uint32());
          break;
        case 6:
          message.fund = Fund.decode(reader, reader.uint32());
          break;
        case 7:
          message.applicantName = reader.string();
          break;
        case 8:
          message.email = reader.string();
          break;
        case 9:
          message.reasonForApplication = reader.string();
          break;
        case 10:
          message.screeningStatus = ScreeningStatus.decode(reader, reader.uint32());
          break;
        case 11:
          message.phoneCountryCode = reader.string();
          break;
        case 12:
          message.phoneNumber = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): ScreeningCompany {
    const message = Object.create(baseScreeningCompany) as ScreeningCompany;
    if (object.applicationId) {
      message.applicationId = Number(object.applicationId);
    }
    if (object.companyName) {
      message.companyName = String(object.companyName);
    }
    if (object.companyUrl) {
      message.companyUrl = String(object.companyUrl);
    }
    if (object.country) {
      message.country = Country.fromJSON(object.country);
    }
    if (object.corporateAttribute) {
      message.corporateAttribute = CorporateAttribute.fromJSON(object.corporateAttribute);
    }
    if (object.fund) {
      message.fund = Fund.fromJSON(object.fund);
    }
    if (object.applicantName) {
      message.applicantName = String(object.applicantName);
    }
    if (object.email) {
      message.email = String(object.email);
    }
    if (object.reasonForApplication) {
      message.reasonForApplication = String(object.reasonForApplication);
    }
    if (object.screeningStatus) {
      message.screeningStatus = ScreeningStatus.fromJSON(object.screeningStatus);
    }
    if (object.phoneCountryCode) {
      message.phoneCountryCode = String(object.phoneCountryCode);
    }
    if (object.phoneNumber) {
      message.phoneNumber = String(object.phoneNumber);
    }
    return message;
  },
  fromPartial(object: DeepPartial<ScreeningCompany>): ScreeningCompany {
    const message = Object.create(baseScreeningCompany) as ScreeningCompany;
    if (object.applicationId) {
      message.applicationId = object.applicationId;
    }
    if (object.companyName) {
      message.companyName = object.companyName;
    }
    if (object.companyUrl) {
      message.companyUrl = object.companyUrl;
    }
    if (object.country) {
      message.country = Country.fromPartial(object.country);
    }
    if (object.corporateAttribute) {
      message.corporateAttribute = CorporateAttribute.fromPartial(object.corporateAttribute);
    }
    if (object.fund) {
      message.fund = Fund.fromPartial(object.fund);
    }
    if (object.applicantName) {
      message.applicantName = object.applicantName;
    }
    if (object.email) {
      message.email = object.email;
    }
    if (object.reasonForApplication) {
      message.reasonForApplication = object.reasonForApplication;
    }
    if (object.screeningStatus) {
      message.screeningStatus = ScreeningStatus.fromPartial(object.screeningStatus);
    }
    if (object.phoneCountryCode) {
      message.phoneCountryCode = object.phoneCountryCode;
    }
    if (object.phoneNumber) {
      message.phoneNumber = object.phoneNumber;
    }
    return message;
  },
  toJSON(message: ScreeningCompany): unknown {
    const obj: any = {};
    obj.applicationId = message.applicationId || 0;
    obj.companyName = message.companyName || "";
    obj.companyUrl = message.companyUrl || "";
    obj.country = message.country ? Country.toJSON(message.country) : undefined;
    obj.corporateAttribute = message.corporateAttribute ? CorporateAttribute.toJSON(message.corporateAttribute) : undefined;
    obj.fund = message.fund ? Fund.toJSON(message.fund) : undefined;
    obj.applicantName = message.applicantName || "";
    obj.email = message.email || "";
    obj.reasonForApplication = message.reasonForApplication || "";
    obj.screeningStatus = message.screeningStatus ? ScreeningStatus.toJSON(message.screeningStatus) : undefined;
    obj.phoneCountryCode = message.phoneCountryCode || "";
    obj.phoneNumber = message.phoneNumber || "";
    return obj;
  },
};

export const Demand = {
  encode(message: Demand, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).uint32(message.id);
    writer.uint32(26).string(message.demandName);
    writer.uint32(34).string(message.interestName);
    return writer;
  },
  decode(reader: Reader, length?: number): Demand {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseDemand) as Demand;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.uint32();
          break;
        case 3:
          message.demandName = reader.string();
          break;
        case 4:
          message.interestName = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): Demand {
    const message = Object.create(baseDemand) as Demand;
    if (object.id) {
      message.id = Number(object.id);
    }
    if (object.demandName) {
      message.demandName = String(object.demandName);
    }
    if (object.interestName) {
      message.interestName = String(object.interestName);
    }
    return message;
  },
  fromPartial(object: DeepPartial<Demand>): Demand {
    const message = Object.create(baseDemand) as Demand;
    if (object.id) {
      message.id = object.id;
    }
    if (object.demandName) {
      message.demandName = object.demandName;
    }
    if (object.interestName) {
      message.interestName = object.interestName;
    }
    return message;
  },
  toJSON(message: Demand): unknown {
    const obj: any = {};
    obj.id = message.id || 0;
    obj.demandName = message.demandName || "";
    obj.interestName = message.interestName || "";
    return obj;
  },
};

export const Matter = {
  encode(message: Matter, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.matterId);
    writer.uint32(16).int64(message.publishedAt);
    if (message.humanResource !== undefined && message.humanResource !== undefined) {
      HumanResourceMatter.encode(message.humanResource, writer.uint32(34).fork()).ldelim();
    }
    writer.uint32(40).int64(message.matterPublishId);
    writer.uint32(48).bool(message.viewed);
    writer.uint32(64).bool(message.scouted);
    writer.uint32(72).uint32(message.accountsInScouted);
    writer.uint32(82).string(message.chatRoomId);
    writer.uint32(90).string(message.accountId);
    writer.uint32(96).int64(message.latestMessageSentAt);
    writer.uint32(104).bool(message.favorite);
    return writer;
  },
  decode(reader: Reader, length?: number): Matter {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseMatter) as Matter;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.matterId = reader.string();
          break;
        case 2:
          message.publishedAt = longToNumber(reader.int64() as Long);
          break;
        case 4:
          message.humanResource = HumanResourceMatter.decode(reader, reader.uint32());
          break;
        case 5:
          message.matterPublishId = longToNumber(reader.int64() as Long);
          break;
        case 6:
          message.viewed = reader.bool();
          break;
        case 8:
          message.scouted = reader.bool();
          break;
        case 9:
          message.accountsInScouted = reader.uint32();
          break;
        case 10:
          message.chatRoomId = reader.string();
          break;
        case 11:
          message.accountId = reader.string();
          break;
        case 12:
          message.latestMessageSentAt = longToNumber(reader.int64() as Long);
          break;
        case 13:
          message.favorite = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): Matter {
    const message = Object.create(baseMatter) as Matter;
    if (object.matterId) {
      message.matterId = String(object.matterId);
    }
    if (object.publishedAt) {
      message.publishedAt = Number(object.publishedAt);
    }
    if (object.humanResource) {
      message.humanResource = HumanResourceMatter.fromJSON(object.humanResource);
    }
    if (object.matterPublishId) {
      message.matterPublishId = Number(object.matterPublishId);
    }
    if (object.viewed) {
      message.viewed = Boolean(object.viewed);
    }
    if (object.scouted) {
      message.scouted = Boolean(object.scouted);
    }
    if (object.accountsInScouted) {
      message.accountsInScouted = Number(object.accountsInScouted);
    }
    if (object.chatRoomId) {
      message.chatRoomId = String(object.chatRoomId);
    }
    if (object.accountId) {
      message.accountId = String(object.accountId);
    }
    if (object.latestMessageSentAt) {
      message.latestMessageSentAt = Number(object.latestMessageSentAt);
    }
    if (object.favorite) {
      message.favorite = Boolean(object.favorite);
    }
    return message;
  },
  fromPartial(object: DeepPartial<Matter>): Matter {
    const message = Object.create(baseMatter) as Matter;
    if (object.matterId) {
      message.matterId = object.matterId;
    }
    if (object.publishedAt) {
      message.publishedAt = object.publishedAt;
    }
    if (object.humanResource) {
      message.humanResource = HumanResourceMatter.fromPartial(object.humanResource);
    }
    if (object.matterPublishId) {
      message.matterPublishId = object.matterPublishId;
    }
    if (object.viewed) {
      message.viewed = object.viewed;
    }
    if (object.scouted) {
      message.scouted = object.scouted;
    }
    if (object.accountsInScouted) {
      message.accountsInScouted = object.accountsInScouted;
    }
    if (object.chatRoomId) {
      message.chatRoomId = object.chatRoomId;
    }
    if (object.accountId) {
      message.accountId = object.accountId;
    }
    if (object.latestMessageSentAt) {
      message.latestMessageSentAt = object.latestMessageSentAt;
    }
    if (object.favorite) {
      message.favorite = object.favorite;
    }
    return message;
  },
  toJSON(message: Matter): unknown {
    const obj: any = {};
    obj.matterId = message.matterId || "";
    obj.publishedAt = message.publishedAt || 0;
    obj.humanResource = message.humanResource ? HumanResourceMatter.toJSON(message.humanResource) : undefined;
    obj.matterPublishId = message.matterPublishId || 0;
    obj.viewed = message.viewed || false;
    obj.scouted = message.scouted || false;
    obj.accountsInScouted = message.accountsInScouted || 0;
    obj.chatRoomId = message.chatRoomId || "";
    obj.accountId = message.accountId || "";
    obj.latestMessageSentAt = message.latestMessageSentAt || 0;
    obj.favorite = message.favorite || false;
    return obj;
  },
};

export const HumanResourceMatter = {
  encode(message: HumanResourceMatter, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.displayId);
    if (message.career !== undefined && message.career !== undefined) {
      CareerProfileForMatter.encode(message.career, writer.uint32(18).fork()).ldelim();
    }
    if (message.newGraduate !== undefined && message.newGraduate !== undefined) {
      NewGraduateProfileForMatter.encode(message.newGraduate, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.preferredWorkLocations) {
      WorkLocation.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.preferredOccupations) {
      Occupation.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.academicBackground !== undefined && message.academicBackground !== undefined) {
      AcademicBackground.encode(message.academicBackground, writer.uint32(58).fork()).ldelim();
    }
    if (message.englishSkill !== undefined && message.englishSkill !== undefined) {
      EnglishSkill.encode(message.englishSkill, writer.uint32(74).fork()).ldelim();
    }
    writer.uint32(82).string(message.selfIntroduction);
    for (const v of message.certifications) {
      writer.uint32(90).string(v!);
    }
    if (message.dttScore !== undefined && message.dttScore !== undefined) {
      Int32Value.encode({ value: message.dttScore! }, writer.uint32(98).fork()).ldelim();
    }
    if (message.dttRank !== undefined && message.dttRank !== undefined) {
      DttRank.encode(message.dttRank, writer.uint32(106).fork()).ldelim();
    }
    writer.uint32(114).string(message.lastName);
    writer.uint32(122).string(message.firstName);
    if (message.japanPrefecture !== undefined && message.japanPrefecture !== undefined) {
      JapanPrefecture.encode(message.japanPrefecture, writer.uint32(130).fork()).ldelim();
    }
    writer.uint32(138).string(message.lastNameKana);
    writer.uint32(146).string(message.firstNameKana);
    writer.uint32(152).uint32(message.yearOfBirth);
    writer.uint32(160).uint32(message.monthOfBirth);
    for (const v of message.outputs) {
      writer.uint32(170).string(v!);
    }
    return writer;
  },
  decode(reader: Reader, length?: number): HumanResourceMatter {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseHumanResourceMatter) as HumanResourceMatter;
    message.preferredWorkLocations = [];
    message.preferredOccupations = [];
    message.certifications = [];
    message.outputs = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.displayId = reader.string();
          break;
        case 2:
          message.career = CareerProfileForMatter.decode(reader, reader.uint32());
          break;
        case 3:
          message.newGraduate = NewGraduateProfileForMatter.decode(reader, reader.uint32());
          break;
        case 4:
          message.preferredWorkLocations.push(WorkLocation.decode(reader, reader.uint32()));
          break;
        case 5:
          message.preferredOccupations.push(Occupation.decode(reader, reader.uint32()));
          break;
        case 7:
          message.academicBackground = AcademicBackground.decode(reader, reader.uint32());
          break;
        case 9:
          message.englishSkill = EnglishSkill.decode(reader, reader.uint32());
          break;
        case 10:
          message.selfIntroduction = reader.string();
          break;
        case 11:
          message.certifications.push(reader.string());
          break;
        case 12:
          message.dttScore = Int32Value.decode(reader, reader.uint32()).value;
          break;
        case 13:
          message.dttRank = DttRank.decode(reader, reader.uint32());
          break;
        case 14:
          message.lastName = reader.string();
          break;
        case 15:
          message.firstName = reader.string();
          break;
        case 16:
          message.japanPrefecture = JapanPrefecture.decode(reader, reader.uint32());
          break;
        case 17:
          message.lastNameKana = reader.string();
          break;
        case 18:
          message.firstNameKana = reader.string();
          break;
        case 19:
          message.yearOfBirth = reader.uint32();
          break;
        case 20:
          message.monthOfBirth = reader.uint32();
          break;
        case 21:
          message.outputs.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): HumanResourceMatter {
    const message = Object.create(baseHumanResourceMatter) as HumanResourceMatter;
    message.preferredWorkLocations = [];
    message.preferredOccupations = [];
    message.certifications = [];
    message.outputs = [];
    if (object.displayId) {
      message.displayId = String(object.displayId);
    }
    if (object.career) {
      message.career = CareerProfileForMatter.fromJSON(object.career);
    }
    if (object.newGraduate) {
      message.newGraduate = NewGraduateProfileForMatter.fromJSON(object.newGraduate);
    }
    if (object.preferredWorkLocations) {
      for (const e of object.preferredWorkLocations) {
        message.preferredWorkLocations.push(WorkLocation.fromJSON(e));
      }
    }
    if (object.preferredOccupations) {
      for (const e of object.preferredOccupations) {
        message.preferredOccupations.push(Occupation.fromJSON(e));
      }
    }
    if (object.academicBackground) {
      message.academicBackground = AcademicBackground.fromJSON(object.academicBackground);
    }
    if (object.englishSkill) {
      message.englishSkill = EnglishSkill.fromJSON(object.englishSkill);
    }
    if (object.selfIntroduction) {
      message.selfIntroduction = String(object.selfIntroduction);
    }
    if (object.certifications) {
      for (const e of object.certifications) {
        message.certifications.push(String(e));
      }
    }
    if (object.dttScore) {
      message.dttScore = Number(object.dttScore);
    }
    if (object.dttRank) {
      message.dttRank = DttRank.fromJSON(object.dttRank);
    }
    if (object.lastName) {
      message.lastName = String(object.lastName);
    }
    if (object.firstName) {
      message.firstName = String(object.firstName);
    }
    if (object.japanPrefecture) {
      message.japanPrefecture = JapanPrefecture.fromJSON(object.japanPrefecture);
    }
    if (object.lastNameKana) {
      message.lastNameKana = String(object.lastNameKana);
    }
    if (object.firstNameKana) {
      message.firstNameKana = String(object.firstNameKana);
    }
    if (object.yearOfBirth) {
      message.yearOfBirth = Number(object.yearOfBirth);
    }
    if (object.monthOfBirth) {
      message.monthOfBirth = Number(object.monthOfBirth);
    }
    if (object.outputs) {
      for (const e of object.outputs) {
        message.outputs.push(String(e));
      }
    }
    return message;
  },
  fromPartial(object: DeepPartial<HumanResourceMatter>): HumanResourceMatter {
    const message = Object.create(baseHumanResourceMatter) as HumanResourceMatter;
    message.preferredWorkLocations = [];
    message.preferredOccupations = [];
    message.certifications = [];
    message.outputs = [];
    if (object.displayId) {
      message.displayId = object.displayId;
    }
    if (object.career) {
      message.career = CareerProfileForMatter.fromPartial(object.career);
    }
    if (object.newGraduate) {
      message.newGraduate = NewGraduateProfileForMatter.fromPartial(object.newGraduate);
    }
    if (object.preferredWorkLocations) {
      for (const e of object.preferredWorkLocations) {
        message.preferredWorkLocations.push(WorkLocation.fromPartial(e));
      }
    }
    if (object.preferredOccupations) {
      for (const e of object.preferredOccupations) {
        message.preferredOccupations.push(Occupation.fromPartial(e));
      }
    }
    if (object.academicBackground) {
      message.academicBackground = AcademicBackground.fromPartial(object.academicBackground);
    }
    if (object.englishSkill) {
      message.englishSkill = EnglishSkill.fromPartial(object.englishSkill);
    }
    if (object.selfIntroduction) {
      message.selfIntroduction = object.selfIntroduction;
    }
    if (object.certifications) {
      for (const e of object.certifications) {
        message.certifications.push(e);
      }
    }
    if (object.dttScore) {
      message.dttScore = object.dttScore;
    }
    if (object.dttRank) {
      message.dttRank = DttRank.fromPartial(object.dttRank);
    }
    if (object.lastName) {
      message.lastName = object.lastName;
    }
    if (object.firstName) {
      message.firstName = object.firstName;
    }
    if (object.japanPrefecture) {
      message.japanPrefecture = JapanPrefecture.fromPartial(object.japanPrefecture);
    }
    if (object.lastNameKana) {
      message.lastNameKana = object.lastNameKana;
    }
    if (object.firstNameKana) {
      message.firstNameKana = object.firstNameKana;
    }
    if (object.yearOfBirth) {
      message.yearOfBirth = object.yearOfBirth;
    }
    if (object.monthOfBirth) {
      message.monthOfBirth = object.monthOfBirth;
    }
    if (object.outputs) {
      for (const e of object.outputs) {
        message.outputs.push(e);
      }
    }
    return message;
  },
  toJSON(message: HumanResourceMatter): unknown {
    const obj: any = {};
    obj.displayId = message.displayId || "";
    obj.career = message.career ? CareerProfileForMatter.toJSON(message.career) : undefined;
    obj.newGraduate = message.newGraduate ? NewGraduateProfileForMatter.toJSON(message.newGraduate) : undefined;
    if (message.preferredWorkLocations) {
      obj.preferredWorkLocations = message.preferredWorkLocations.map(e => e ? WorkLocation.toJSON(e) : undefined);
    } else {
      obj.preferredWorkLocations = [];
    }
    if (message.preferredOccupations) {
      obj.preferredOccupations = message.preferredOccupations.map(e => e ? Occupation.toJSON(e) : undefined);
    } else {
      obj.preferredOccupations = [];
    }
    obj.academicBackground = message.academicBackground ? AcademicBackground.toJSON(message.academicBackground) : undefined;
    obj.englishSkill = message.englishSkill ? EnglishSkill.toJSON(message.englishSkill) : undefined;
    obj.selfIntroduction = message.selfIntroduction || "";
    if (message.certifications) {
      obj.certifications = message.certifications.map(e => e || "");
    } else {
      obj.certifications = [];
    }
    obj.dttScore = message.dttScore || undefined;
    obj.dttRank = message.dttRank ? DttRank.toJSON(message.dttRank) : undefined;
    obj.lastName = message.lastName || "";
    obj.firstName = message.firstName || "";
    obj.japanPrefecture = message.japanPrefecture ? JapanPrefecture.toJSON(message.japanPrefecture) : undefined;
    obj.lastNameKana = message.lastNameKana || "";
    obj.firstNameKana = message.firstNameKana || "";
    obj.yearOfBirth = message.yearOfBirth || 0;
    obj.monthOfBirth = message.monthOfBirth || 0;
    if (message.outputs) {
      obj.outputs = message.outputs.map(e => e || "");
    } else {
      obj.outputs = [];
    }
    return obj;
  },
};

export const CareerProfileForMatter = {
  encode(message: CareerProfileForMatter, writer: Writer = Writer.create()): Writer {
    for (const v of message.job) {
      CareerProfileForMatter_JobHistory.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.numberOfJobChanges !== undefined && message.numberOfJobChanges !== undefined) {
      NumberOfJobChanges.encode(message.numberOfJobChanges, writer.uint32(18).fork()).ldelim();
    }
    if (message.preferredEmploymentType !== undefined && message.preferredEmploymentType !== undefined) {
      EmploymentType.encode(message.preferredEmploymentType, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.skills) {
      Skill.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.expectations) {
      Expectation.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.annualIncome !== undefined && message.annualIncome !== undefined) {
      AnnualIncome.encode(message.annualIncome, writer.uint32(50).fork()).ldelim();
    }
    writer.uint32(58).string(message.achievement);
    return writer;
  },
  decode(reader: Reader, length?: number): CareerProfileForMatter {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseCareerProfileForMatter) as CareerProfileForMatter;
    message.job = [];
    message.skills = [];
    message.expectations = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.job.push(CareerProfileForMatter_JobHistory.decode(reader, reader.uint32()));
          break;
        case 2:
          message.numberOfJobChanges = NumberOfJobChanges.decode(reader, reader.uint32());
          break;
        case 3:
          message.preferredEmploymentType = EmploymentType.decode(reader, reader.uint32());
          break;
        case 4:
          message.skills.push(Skill.decode(reader, reader.uint32()));
          break;
        case 5:
          message.expectations.push(Expectation.decode(reader, reader.uint32()));
          break;
        case 6:
          message.annualIncome = AnnualIncome.decode(reader, reader.uint32());
          break;
        case 7:
          message.achievement = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): CareerProfileForMatter {
    const message = Object.create(baseCareerProfileForMatter) as CareerProfileForMatter;
    message.job = [];
    message.skills = [];
    message.expectations = [];
    if (object.job) {
      for (const e of object.job) {
        message.job.push(CareerProfileForMatter_JobHistory.fromJSON(e));
      }
    }
    if (object.numberOfJobChanges) {
      message.numberOfJobChanges = NumberOfJobChanges.fromJSON(object.numberOfJobChanges);
    }
    if (object.preferredEmploymentType) {
      message.preferredEmploymentType = EmploymentType.fromJSON(object.preferredEmploymentType);
    }
    if (object.skills) {
      for (const e of object.skills) {
        message.skills.push(Skill.fromJSON(e));
      }
    }
    if (object.expectations) {
      for (const e of object.expectations) {
        message.expectations.push(Expectation.fromJSON(e));
      }
    }
    if (object.annualIncome) {
      message.annualIncome = AnnualIncome.fromJSON(object.annualIncome);
    }
    if (object.achievement) {
      message.achievement = String(object.achievement);
    }
    return message;
  },
  fromPartial(object: DeepPartial<CareerProfileForMatter>): CareerProfileForMatter {
    const message = Object.create(baseCareerProfileForMatter) as CareerProfileForMatter;
    message.job = [];
    message.skills = [];
    message.expectations = [];
    if (object.job) {
      for (const e of object.job) {
        message.job.push(CareerProfileForMatter_JobHistory.fromPartial(e));
      }
    }
    if (object.numberOfJobChanges) {
      message.numberOfJobChanges = NumberOfJobChanges.fromPartial(object.numberOfJobChanges);
    }
    if (object.preferredEmploymentType) {
      message.preferredEmploymentType = EmploymentType.fromPartial(object.preferredEmploymentType);
    }
    if (object.skills) {
      for (const e of object.skills) {
        message.skills.push(Skill.fromPartial(e));
      }
    }
    if (object.expectations) {
      for (const e of object.expectations) {
        message.expectations.push(Expectation.fromPartial(e));
      }
    }
    if (object.annualIncome) {
      message.annualIncome = AnnualIncome.fromPartial(object.annualIncome);
    }
    if (object.achievement) {
      message.achievement = object.achievement;
    }
    return message;
  },
  toJSON(message: CareerProfileForMatter): unknown {
    const obj: any = {};
    if (message.job) {
      obj.job = message.job.map(e => e ? CareerProfileForMatter_JobHistory.toJSON(e) : undefined);
    } else {
      obj.job = [];
    }
    obj.numberOfJobChanges = message.numberOfJobChanges ? NumberOfJobChanges.toJSON(message.numberOfJobChanges) : undefined;
    obj.preferredEmploymentType = message.preferredEmploymentType ? EmploymentType.toJSON(message.preferredEmploymentType) : undefined;
    if (message.skills) {
      obj.skills = message.skills.map(e => e ? Skill.toJSON(e) : undefined);
    } else {
      obj.skills = [];
    }
    if (message.expectations) {
      obj.expectations = message.expectations.map(e => e ? Expectation.toJSON(e) : undefined);
    } else {
      obj.expectations = [];
    }
    obj.annualIncome = message.annualIncome ? AnnualIncome.toJSON(message.annualIncome) : undefined;
    obj.achievement = message.achievement || "";
    return obj;
  },
};

export const CareerProfileForMatter_JobHistory = {
  encode(message: CareerProfileForMatter_JobHistory, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.name);
    if (message.position !== undefined && message.position !== undefined) {
      Position.encode(message.position, writer.uint32(18).fork()).ldelim();
    }
    writer.uint32(24).uint32(message.startYear);
    writer.uint32(32).uint32(message.startMonth);
    if (message.endYear !== undefined && message.endYear !== undefined) {
      Int32Value.encode({ value: message.endYear! }, writer.uint32(42).fork()).ldelim();
    }
    if (message.endMonth !== undefined && message.endMonth !== undefined) {
      Int32Value.encode({ value: message.endMonth! }, writer.uint32(50).fork()).ldelim();
    }
    for (const v of message.occupations) {
      CareerProfileForMatter_ExperiencedOccupation.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): CareerProfileForMatter_JobHistory {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseCareerProfileForMatter_JobHistory) as CareerProfileForMatter_JobHistory;
    message.occupations = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.name = reader.string();
          break;
        case 2:
          message.position = Position.decode(reader, reader.uint32());
          break;
        case 3:
          message.startYear = reader.uint32();
          break;
        case 4:
          message.startMonth = reader.uint32();
          break;
        case 5:
          message.endYear = Int32Value.decode(reader, reader.uint32()).value;
          break;
        case 6:
          message.endMonth = Int32Value.decode(reader, reader.uint32()).value;
          break;
        case 7:
          message.occupations.push(CareerProfileForMatter_ExperiencedOccupation.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): CareerProfileForMatter_JobHistory {
    const message = Object.create(baseCareerProfileForMatter_JobHistory) as CareerProfileForMatter_JobHistory;
    message.occupations = [];
    if (object.name) {
      message.name = String(object.name);
    }
    if (object.position) {
      message.position = Position.fromJSON(object.position);
    }
    if (object.startYear) {
      message.startYear = Number(object.startYear);
    }
    if (object.startMonth) {
      message.startMonth = Number(object.startMonth);
    }
    if (object.endYear) {
      message.endYear = Number(object.endYear);
    }
    if (object.endMonth) {
      message.endMonth = Number(object.endMonth);
    }
    if (object.occupations) {
      for (const e of object.occupations) {
        message.occupations.push(CareerProfileForMatter_ExperiencedOccupation.fromJSON(e));
      }
    }
    return message;
  },
  fromPartial(object: DeepPartial<CareerProfileForMatter_JobHistory>): CareerProfileForMatter_JobHistory {
    const message = Object.create(baseCareerProfileForMatter_JobHistory) as CareerProfileForMatter_JobHistory;
    message.occupations = [];
    if (object.name) {
      message.name = object.name;
    }
    if (object.position) {
      message.position = Position.fromPartial(object.position);
    }
    if (object.startYear) {
      message.startYear = object.startYear;
    }
    if (object.startMonth) {
      message.startMonth = object.startMonth;
    }
    if (object.endYear) {
      message.endYear = object.endYear;
    }
    if (object.endMonth) {
      message.endMonth = object.endMonth;
    }
    if (object.occupations) {
      for (const e of object.occupations) {
        message.occupations.push(CareerProfileForMatter_ExperiencedOccupation.fromPartial(e));
      }
    }
    return message;
  },
  toJSON(message: CareerProfileForMatter_JobHistory): unknown {
    const obj: any = {};
    obj.name = message.name || "";
    obj.position = message.position ? Position.toJSON(message.position) : undefined;
    obj.startYear = message.startYear || 0;
    obj.startMonth = message.startMonth || 0;
    obj.endYear = message.endYear || undefined;
    obj.endMonth = message.endMonth || undefined;
    if (message.occupations) {
      obj.occupations = message.occupations.map(e => e ? CareerProfileForMatter_ExperiencedOccupation.toJSON(e) : undefined);
    } else {
      obj.occupations = [];
    }
    return obj;
  },
};

export const CareerProfileForMatter_ExperiencedOccupation = {
  encode(message: CareerProfileForMatter_ExperiencedOccupation, writer: Writer = Writer.create()): Writer {
    if (message.occupation !== undefined && message.occupation !== undefined) {
      Occupation.encode(message.occupation, writer.uint32(10).fork()).ldelim();
    }
    if (message.yearsOfExperience !== undefined && message.yearsOfExperience !== undefined) {
      YearsOfExperience.encode(message.yearsOfExperience, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): CareerProfileForMatter_ExperiencedOccupation {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseCareerProfileForMatter_ExperiencedOccupation) as CareerProfileForMatter_ExperiencedOccupation;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.occupation = Occupation.decode(reader, reader.uint32());
          break;
        case 2:
          message.yearsOfExperience = YearsOfExperience.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): CareerProfileForMatter_ExperiencedOccupation {
    const message = Object.create(baseCareerProfileForMatter_ExperiencedOccupation) as CareerProfileForMatter_ExperiencedOccupation;
    if (object.occupation) {
      message.occupation = Occupation.fromJSON(object.occupation);
    }
    if (object.yearsOfExperience) {
      message.yearsOfExperience = YearsOfExperience.fromJSON(object.yearsOfExperience);
    }
    return message;
  },
  fromPartial(object: DeepPartial<CareerProfileForMatter_ExperiencedOccupation>): CareerProfileForMatter_ExperiencedOccupation {
    const message = Object.create(baseCareerProfileForMatter_ExperiencedOccupation) as CareerProfileForMatter_ExperiencedOccupation;
    if (object.occupation) {
      message.occupation = Occupation.fromPartial(object.occupation);
    }
    if (object.yearsOfExperience) {
      message.yearsOfExperience = YearsOfExperience.fromPartial(object.yearsOfExperience);
    }
    return message;
  },
  toJSON(message: CareerProfileForMatter_ExperiencedOccupation): unknown {
    const obj: any = {};
    obj.occupation = message.occupation ? Occupation.toJSON(message.occupation) : undefined;
    obj.yearsOfExperience = message.yearsOfExperience ? YearsOfExperience.toJSON(message.yearsOfExperience) : undefined;
    return obj;
  },
};

export const NewGraduateProfileForMatter = {
  encode(message: NewGraduateProfileForMatter, writer: Writer = Writer.create()): Writer {
    for (const v of message.researchRooms) {
      ResearchRoom.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.programmingExperiences) {
      ProgrammingExperience.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    if (message.preferredWorkStyle !== undefined && message.preferredWorkStyle !== undefined) {
      PreferredWorkStyle.encode(message.preferredWorkStyle, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.preferredBusinessTypes) {
      PreferredBusinessType.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.pastExperienceAchievements) {
      PastExperienceAchievement.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    for (const v of message.internshipExperiences) {
      InternshipExperience.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    for (const v of message.studyAbroadExperiences) {
      StudyAbroadExperience.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    for (const v of message.clubExperiences) {
      ClubExperience.encode(v!, writer.uint32(66).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): NewGraduateProfileForMatter {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseNewGraduateProfileForMatter) as NewGraduateProfileForMatter;
    message.researchRooms = [];
    message.programmingExperiences = [];
    message.preferredBusinessTypes = [];
    message.pastExperienceAchievements = [];
    message.internshipExperiences = [];
    message.studyAbroadExperiences = [];
    message.clubExperiences = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.researchRooms.push(ResearchRoom.decode(reader, reader.uint32()));
          break;
        case 2:
          message.programmingExperiences.push(ProgrammingExperience.decode(reader, reader.uint32()));
          break;
        case 3:
          message.preferredWorkStyle = PreferredWorkStyle.decode(reader, reader.uint32());
          break;
        case 4:
          message.preferredBusinessTypes.push(PreferredBusinessType.decode(reader, reader.uint32()));
          break;
        case 5:
          message.pastExperienceAchievements.push(PastExperienceAchievement.decode(reader, reader.uint32()));
          break;
        case 6:
          message.internshipExperiences.push(InternshipExperience.decode(reader, reader.uint32()));
          break;
        case 7:
          message.studyAbroadExperiences.push(StudyAbroadExperience.decode(reader, reader.uint32()));
          break;
        case 8:
          message.clubExperiences.push(ClubExperience.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): NewGraduateProfileForMatter {
    const message = Object.create(baseNewGraduateProfileForMatter) as NewGraduateProfileForMatter;
    message.researchRooms = [];
    message.programmingExperiences = [];
    message.preferredBusinessTypes = [];
    message.pastExperienceAchievements = [];
    message.internshipExperiences = [];
    message.studyAbroadExperiences = [];
    message.clubExperiences = [];
    if (object.researchRooms) {
      for (const e of object.researchRooms) {
        message.researchRooms.push(ResearchRoom.fromJSON(e));
      }
    }
    if (object.programmingExperiences) {
      for (const e of object.programmingExperiences) {
        message.programmingExperiences.push(ProgrammingExperience.fromJSON(e));
      }
    }
    if (object.preferredWorkStyle) {
      message.preferredWorkStyle = PreferredWorkStyle.fromJSON(object.preferredWorkStyle);
    }
    if (object.preferredBusinessTypes) {
      for (const e of object.preferredBusinessTypes) {
        message.preferredBusinessTypes.push(PreferredBusinessType.fromJSON(e));
      }
    }
    if (object.pastExperienceAchievements) {
      for (const e of object.pastExperienceAchievements) {
        message.pastExperienceAchievements.push(PastExperienceAchievement.fromJSON(e));
      }
    }
    if (object.internshipExperiences) {
      for (const e of object.internshipExperiences) {
        message.internshipExperiences.push(InternshipExperience.fromJSON(e));
      }
    }
    if (object.studyAbroadExperiences) {
      for (const e of object.studyAbroadExperiences) {
        message.studyAbroadExperiences.push(StudyAbroadExperience.fromJSON(e));
      }
    }
    if (object.clubExperiences) {
      for (const e of object.clubExperiences) {
        message.clubExperiences.push(ClubExperience.fromJSON(e));
      }
    }
    return message;
  },
  fromPartial(object: DeepPartial<NewGraduateProfileForMatter>): NewGraduateProfileForMatter {
    const message = Object.create(baseNewGraduateProfileForMatter) as NewGraduateProfileForMatter;
    message.researchRooms = [];
    message.programmingExperiences = [];
    message.preferredBusinessTypes = [];
    message.pastExperienceAchievements = [];
    message.internshipExperiences = [];
    message.studyAbroadExperiences = [];
    message.clubExperiences = [];
    if (object.researchRooms) {
      for (const e of object.researchRooms) {
        message.researchRooms.push(ResearchRoom.fromPartial(e));
      }
    }
    if (object.programmingExperiences) {
      for (const e of object.programmingExperiences) {
        message.programmingExperiences.push(ProgrammingExperience.fromPartial(e));
      }
    }
    if (object.preferredWorkStyle) {
      message.preferredWorkStyle = PreferredWorkStyle.fromPartial(object.preferredWorkStyle);
    }
    if (object.preferredBusinessTypes) {
      for (const e of object.preferredBusinessTypes) {
        message.preferredBusinessTypes.push(PreferredBusinessType.fromPartial(e));
      }
    }
    if (object.pastExperienceAchievements) {
      for (const e of object.pastExperienceAchievements) {
        message.pastExperienceAchievements.push(PastExperienceAchievement.fromPartial(e));
      }
    }
    if (object.internshipExperiences) {
      for (const e of object.internshipExperiences) {
        message.internshipExperiences.push(InternshipExperience.fromPartial(e));
      }
    }
    if (object.studyAbroadExperiences) {
      for (const e of object.studyAbroadExperiences) {
        message.studyAbroadExperiences.push(StudyAbroadExperience.fromPartial(e));
      }
    }
    if (object.clubExperiences) {
      for (const e of object.clubExperiences) {
        message.clubExperiences.push(ClubExperience.fromPartial(e));
      }
    }
    return message;
  },
  toJSON(message: NewGraduateProfileForMatter): unknown {
    const obj: any = {};
    if (message.researchRooms) {
      obj.researchRooms = message.researchRooms.map(e => e ? ResearchRoom.toJSON(e) : undefined);
    } else {
      obj.researchRooms = [];
    }
    if (message.programmingExperiences) {
      obj.programmingExperiences = message.programmingExperiences.map(e => e ? ProgrammingExperience.toJSON(e) : undefined);
    } else {
      obj.programmingExperiences = [];
    }
    obj.preferredWorkStyle = message.preferredWorkStyle ? PreferredWorkStyle.toJSON(message.preferredWorkStyle) : undefined;
    if (message.preferredBusinessTypes) {
      obj.preferredBusinessTypes = message.preferredBusinessTypes.map(e => e ? PreferredBusinessType.toJSON(e) : undefined);
    } else {
      obj.preferredBusinessTypes = [];
    }
    if (message.pastExperienceAchievements) {
      obj.pastExperienceAchievements = message.pastExperienceAchievements.map(e => e ? PastExperienceAchievement.toJSON(e) : undefined);
    } else {
      obj.pastExperienceAchievements = [];
    }
    if (message.internshipExperiences) {
      obj.internshipExperiences = message.internshipExperiences.map(e => e ? InternshipExperience.toJSON(e) : undefined);
    } else {
      obj.internshipExperiences = [];
    }
    if (message.studyAbroadExperiences) {
      obj.studyAbroadExperiences = message.studyAbroadExperiences.map(e => e ? StudyAbroadExperience.toJSON(e) : undefined);
    } else {
      obj.studyAbroadExperiences = [];
    }
    if (message.clubExperiences) {
      obj.clubExperiences = message.clubExperiences.map(e => e ? ClubExperience.toJSON(e) : undefined);
    } else {
      obj.clubExperiences = [];
    }
    return obj;
  },
};

export const CareerMattersFilter = {
  encode(message: CareerMattersFilter, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).fork();
    for (const v of message.employmentTypeIds) {
      writer.uint32(v);
    }
    writer.ldelim();
    writer.uint32(18).fork();
    for (const v of message.occupationIds) {
      writer.uint32(v);
    }
    writer.ldelim();
    writer.uint32(26).fork();
    for (const v of message.skillIds) {
      writer.uint64(v);
    }
    writer.ldelim();
    for (const v of message.workLocations) {
      WorkLocation.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    writer.uint32(42).fork();
    for (const v of message.schoolIds) {
      writer.uint32(v);
    }
    writer.ldelim();
    if (message.yearOfGraduationFrom !== undefined && message.yearOfGraduationFrom !== undefined) {
      Int32Value.encode({ value: message.yearOfGraduationFrom! }, writer.uint32(50).fork()).ldelim();
    }
    if (message.yearOfGraduationTo !== undefined && message.yearOfGraduationTo !== undefined) {
      Int32Value.encode({ value: message.yearOfGraduationTo! }, writer.uint32(58).fork()).ldelim();
    }
    if (message.excludeLatestMessageWithinDays !== undefined && message.excludeLatestMessageWithinDays !== undefined) {
      Int32Value.encode({ value: message.excludeLatestMessageWithinDays! }, writer.uint32(66).fork()).ldelim();
    }
    if (message.favorite !== undefined && message.favorite !== undefined) {
      BoolValue.encode({ value: message.favorite! }, writer.uint32(74).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): CareerMattersFilter {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseCareerMattersFilter) as CareerMattersFilter;
    message.employmentTypeIds = [];
    message.occupationIds = [];
    message.skillIds = [];
    message.workLocations = [];
    message.schoolIds = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.employmentTypeIds.push(reader.uint32());
            }
          } else {
            message.employmentTypeIds.push(reader.uint32());
          }
          break;
        case 2:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.occupationIds.push(reader.uint32());
            }
          } else {
            message.occupationIds.push(reader.uint32());
          }
          break;
        case 3:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.skillIds.push(longToNumber(reader.uint64() as Long));
            }
          } else {
            message.skillIds.push(longToNumber(reader.uint64() as Long));
          }
          break;
        case 4:
          message.workLocations.push(WorkLocation.decode(reader, reader.uint32()));
          break;
        case 5:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.schoolIds.push(reader.uint32());
            }
          } else {
            message.schoolIds.push(reader.uint32());
          }
          break;
        case 6:
          message.yearOfGraduationFrom = Int32Value.decode(reader, reader.uint32()).value;
          break;
        case 7:
          message.yearOfGraduationTo = Int32Value.decode(reader, reader.uint32()).value;
          break;
        case 8:
          message.excludeLatestMessageWithinDays = Int32Value.decode(reader, reader.uint32()).value;
          break;
        case 9:
          message.favorite = BoolValue.decode(reader, reader.uint32()).value;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): CareerMattersFilter {
    const message = Object.create(baseCareerMattersFilter) as CareerMattersFilter;
    message.employmentTypeIds = [];
    message.occupationIds = [];
    message.skillIds = [];
    message.workLocations = [];
    message.schoolIds = [];
    if (object.employmentTypeIds) {
      for (const e of object.employmentTypeIds) {
        message.employmentTypeIds.push(Number(e));
      }
    }
    if (object.occupationIds) {
      for (const e of object.occupationIds) {
        message.occupationIds.push(Number(e));
      }
    }
    if (object.skillIds) {
      for (const e of object.skillIds) {
        message.skillIds.push(Number(e));
      }
    }
    if (object.workLocations) {
      for (const e of object.workLocations) {
        message.workLocations.push(WorkLocation.fromJSON(e));
      }
    }
    if (object.schoolIds) {
      for (const e of object.schoolIds) {
        message.schoolIds.push(Number(e));
      }
    }
    if (object.yearOfGraduationFrom) {
      message.yearOfGraduationFrom = Number(object.yearOfGraduationFrom);
    }
    if (object.yearOfGraduationTo) {
      message.yearOfGraduationTo = Number(object.yearOfGraduationTo);
    }
    if (object.excludeLatestMessageWithinDays) {
      message.excludeLatestMessageWithinDays = Number(object.excludeLatestMessageWithinDays);
    }
    if (object.favorite) {
      message.favorite = Boolean(object.favorite);
    }
    return message;
  },
  fromPartial(object: DeepPartial<CareerMattersFilter>): CareerMattersFilter {
    const message = Object.create(baseCareerMattersFilter) as CareerMattersFilter;
    message.employmentTypeIds = [];
    message.occupationIds = [];
    message.skillIds = [];
    message.workLocations = [];
    message.schoolIds = [];
    if (object.employmentTypeIds) {
      for (const e of object.employmentTypeIds) {
        message.employmentTypeIds.push(e);
      }
    }
    if (object.occupationIds) {
      for (const e of object.occupationIds) {
        message.occupationIds.push(e);
      }
    }
    if (object.skillIds) {
      for (const e of object.skillIds) {
        message.skillIds.push(e);
      }
    }
    if (object.workLocations) {
      for (const e of object.workLocations) {
        message.workLocations.push(WorkLocation.fromPartial(e));
      }
    }
    if (object.schoolIds) {
      for (const e of object.schoolIds) {
        message.schoolIds.push(e);
      }
    }
    if (object.yearOfGraduationFrom) {
      message.yearOfGraduationFrom = object.yearOfGraduationFrom;
    }
    if (object.yearOfGraduationTo) {
      message.yearOfGraduationTo = object.yearOfGraduationTo;
    }
    if (object.excludeLatestMessageWithinDays) {
      message.excludeLatestMessageWithinDays = object.excludeLatestMessageWithinDays;
    }
    if (object.favorite) {
      message.favorite = object.favorite;
    }
    return message;
  },
  toJSON(message: CareerMattersFilter): unknown {
    const obj: any = {};
    if (message.employmentTypeIds) {
      obj.employmentTypeIds = message.employmentTypeIds.map(e => e || 0);
    } else {
      obj.employmentTypeIds = [];
    }
    if (message.occupationIds) {
      obj.occupationIds = message.occupationIds.map(e => e || 0);
    } else {
      obj.occupationIds = [];
    }
    if (message.skillIds) {
      obj.skillIds = message.skillIds.map(e => e || 0);
    } else {
      obj.skillIds = [];
    }
    if (message.workLocations) {
      obj.workLocations = message.workLocations.map(e => e ? WorkLocation.toJSON(e) : undefined);
    } else {
      obj.workLocations = [];
    }
    if (message.schoolIds) {
      obj.schoolIds = message.schoolIds.map(e => e || 0);
    } else {
      obj.schoolIds = [];
    }
    obj.yearOfGraduationFrom = message.yearOfGraduationFrom || undefined;
    obj.yearOfGraduationTo = message.yearOfGraduationTo || undefined;
    obj.excludeLatestMessageWithinDays = message.excludeLatestMessageWithinDays || undefined;
    obj.favorite = message.favorite || undefined;
    return obj;
  },
};

export const NewGraduateMattersFilter = {
  encode(message: NewGraduateMattersFilter, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).fork();
    for (const v of message.preferredWorkStyleIds) {
      writer.uint32(v);
    }
    writer.ldelim();
    writer.uint32(18).fork();
    for (const v of message.yearOfGraduation) {
      writer.uint32(v);
    }
    writer.ldelim();
    writer.uint32(26).fork();
    for (const v of message.schoolIds) {
      writer.uint32(v);
    }
    writer.ldelim();
    writer.uint32(34).fork();
    for (const v of message.departmentIds) {
      writer.uint32(v);
    }
    writer.ldelim();
    writer.uint32(42).fork();
    for (const v of message.pastExperienceAchievementIds) {
      writer.uint32(v);
    }
    writer.ldelim();
    writer.uint32(50).fork();
    for (const v of message.preferredBusinessTypeIds) {
      writer.uint32(v);
    }
    writer.ldelim();
    writer.uint32(58).fork();
    for (const v of message.occupationIds) {
      writer.uint32(v);
    }
    writer.ldelim();
    for (const v of message.workLocations) {
      WorkLocation.encode(v!, writer.uint32(66).fork()).ldelim();
    }
    writer.uint32(74).fork();
    for (const v of message.dttRankIds) {
      writer.uint32(v);
    }
    writer.ldelim();
    if (message.excludeLatestMessageWithinDays !== undefined && message.excludeLatestMessageWithinDays !== undefined) {
      Int32Value.encode({ value: message.excludeLatestMessageWithinDays! }, writer.uint32(82).fork()).ldelim();
    }
    if (message.messageReplied !== undefined && message.messageReplied !== undefined) {
      BoolValue.encode({ value: message.messageReplied! }, writer.uint32(90).fork()).ldelim();
    }
    if (message.favorite !== undefined && message.favorite !== undefined) {
      BoolValue.encode({ value: message.favorite! }, writer.uint32(98).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): NewGraduateMattersFilter {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseNewGraduateMattersFilter) as NewGraduateMattersFilter;
    message.preferredWorkStyleIds = [];
    message.yearOfGraduation = [];
    message.schoolIds = [];
    message.departmentIds = [];
    message.pastExperienceAchievementIds = [];
    message.preferredBusinessTypeIds = [];
    message.occupationIds = [];
    message.workLocations = [];
    message.dttRankIds = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.preferredWorkStyleIds.push(reader.uint32());
            }
          } else {
            message.preferredWorkStyleIds.push(reader.uint32());
          }
          break;
        case 2:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.yearOfGraduation.push(reader.uint32());
            }
          } else {
            message.yearOfGraduation.push(reader.uint32());
          }
          break;
        case 3:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.schoolIds.push(reader.uint32());
            }
          } else {
            message.schoolIds.push(reader.uint32());
          }
          break;
        case 4:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.departmentIds.push(reader.uint32());
            }
          } else {
            message.departmentIds.push(reader.uint32());
          }
          break;
        case 5:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.pastExperienceAchievementIds.push(reader.uint32());
            }
          } else {
            message.pastExperienceAchievementIds.push(reader.uint32());
          }
          break;
        case 6:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.preferredBusinessTypeIds.push(reader.uint32());
            }
          } else {
            message.preferredBusinessTypeIds.push(reader.uint32());
          }
          break;
        case 7:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.occupationIds.push(reader.uint32());
            }
          } else {
            message.occupationIds.push(reader.uint32());
          }
          break;
        case 8:
          message.workLocations.push(WorkLocation.decode(reader, reader.uint32()));
          break;
        case 9:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.dttRankIds.push(reader.uint32());
            }
          } else {
            message.dttRankIds.push(reader.uint32());
          }
          break;
        case 10:
          message.excludeLatestMessageWithinDays = Int32Value.decode(reader, reader.uint32()).value;
          break;
        case 11:
          message.messageReplied = BoolValue.decode(reader, reader.uint32()).value;
          break;
        case 12:
          message.favorite = BoolValue.decode(reader, reader.uint32()).value;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): NewGraduateMattersFilter {
    const message = Object.create(baseNewGraduateMattersFilter) as NewGraduateMattersFilter;
    message.preferredWorkStyleIds = [];
    message.yearOfGraduation = [];
    message.schoolIds = [];
    message.departmentIds = [];
    message.pastExperienceAchievementIds = [];
    message.preferredBusinessTypeIds = [];
    message.occupationIds = [];
    message.workLocations = [];
    message.dttRankIds = [];
    if (object.preferredWorkStyleIds) {
      for (const e of object.preferredWorkStyleIds) {
        message.preferredWorkStyleIds.push(Number(e));
      }
    }
    if (object.yearOfGraduation) {
      for (const e of object.yearOfGraduation) {
        message.yearOfGraduation.push(Number(e));
      }
    }
    if (object.schoolIds) {
      for (const e of object.schoolIds) {
        message.schoolIds.push(Number(e));
      }
    }
    if (object.departmentIds) {
      for (const e of object.departmentIds) {
        message.departmentIds.push(Number(e));
      }
    }
    if (object.pastExperienceAchievementIds) {
      for (const e of object.pastExperienceAchievementIds) {
        message.pastExperienceAchievementIds.push(Number(e));
      }
    }
    if (object.preferredBusinessTypeIds) {
      for (const e of object.preferredBusinessTypeIds) {
        message.preferredBusinessTypeIds.push(Number(e));
      }
    }
    if (object.occupationIds) {
      for (const e of object.occupationIds) {
        message.occupationIds.push(Number(e));
      }
    }
    if (object.workLocations) {
      for (const e of object.workLocations) {
        message.workLocations.push(WorkLocation.fromJSON(e));
      }
    }
    if (object.dttRankIds) {
      for (const e of object.dttRankIds) {
        message.dttRankIds.push(Number(e));
      }
    }
    if (object.excludeLatestMessageWithinDays) {
      message.excludeLatestMessageWithinDays = Number(object.excludeLatestMessageWithinDays);
    }
    if (object.messageReplied) {
      message.messageReplied = Boolean(object.messageReplied);
    }
    if (object.favorite) {
      message.favorite = Boolean(object.favorite);
    }
    return message;
  },
  fromPartial(object: DeepPartial<NewGraduateMattersFilter>): NewGraduateMattersFilter {
    const message = Object.create(baseNewGraduateMattersFilter) as NewGraduateMattersFilter;
    message.preferredWorkStyleIds = [];
    message.yearOfGraduation = [];
    message.schoolIds = [];
    message.departmentIds = [];
    message.pastExperienceAchievementIds = [];
    message.preferredBusinessTypeIds = [];
    message.occupationIds = [];
    message.workLocations = [];
    message.dttRankIds = [];
    if (object.preferredWorkStyleIds) {
      for (const e of object.preferredWorkStyleIds) {
        message.preferredWorkStyleIds.push(e);
      }
    }
    if (object.yearOfGraduation) {
      for (const e of object.yearOfGraduation) {
        message.yearOfGraduation.push(e);
      }
    }
    if (object.schoolIds) {
      for (const e of object.schoolIds) {
        message.schoolIds.push(e);
      }
    }
    if (object.departmentIds) {
      for (const e of object.departmentIds) {
        message.departmentIds.push(e);
      }
    }
    if (object.pastExperienceAchievementIds) {
      for (const e of object.pastExperienceAchievementIds) {
        message.pastExperienceAchievementIds.push(e);
      }
    }
    if (object.preferredBusinessTypeIds) {
      for (const e of object.preferredBusinessTypeIds) {
        message.preferredBusinessTypeIds.push(e);
      }
    }
    if (object.occupationIds) {
      for (const e of object.occupationIds) {
        message.occupationIds.push(e);
      }
    }
    if (object.workLocations) {
      for (const e of object.workLocations) {
        message.workLocations.push(WorkLocation.fromPartial(e));
      }
    }
    if (object.dttRankIds) {
      for (const e of object.dttRankIds) {
        message.dttRankIds.push(e);
      }
    }
    if (object.excludeLatestMessageWithinDays) {
      message.excludeLatestMessageWithinDays = object.excludeLatestMessageWithinDays;
    }
    if (object.messageReplied) {
      message.messageReplied = object.messageReplied;
    }
    if (object.favorite) {
      message.favorite = object.favorite;
    }
    return message;
  },
  toJSON(message: NewGraduateMattersFilter): unknown {
    const obj: any = {};
    if (message.preferredWorkStyleIds) {
      obj.preferredWorkStyleIds = message.preferredWorkStyleIds.map(e => e || 0);
    } else {
      obj.preferredWorkStyleIds = [];
    }
    if (message.yearOfGraduation) {
      obj.yearOfGraduation = message.yearOfGraduation.map(e => e || 0);
    } else {
      obj.yearOfGraduation = [];
    }
    if (message.schoolIds) {
      obj.schoolIds = message.schoolIds.map(e => e || 0);
    } else {
      obj.schoolIds = [];
    }
    if (message.departmentIds) {
      obj.departmentIds = message.departmentIds.map(e => e || 0);
    } else {
      obj.departmentIds = [];
    }
    if (message.pastExperienceAchievementIds) {
      obj.pastExperienceAchievementIds = message.pastExperienceAchievementIds.map(e => e || 0);
    } else {
      obj.pastExperienceAchievementIds = [];
    }
    if (message.preferredBusinessTypeIds) {
      obj.preferredBusinessTypeIds = message.preferredBusinessTypeIds.map(e => e || 0);
    } else {
      obj.preferredBusinessTypeIds = [];
    }
    if (message.occupationIds) {
      obj.occupationIds = message.occupationIds.map(e => e || 0);
    } else {
      obj.occupationIds = [];
    }
    if (message.workLocations) {
      obj.workLocations = message.workLocations.map(e => e ? WorkLocation.toJSON(e) : undefined);
    } else {
      obj.workLocations = [];
    }
    if (message.dttRankIds) {
      obj.dttRankIds = message.dttRankIds.map(e => e || 0);
    } else {
      obj.dttRankIds = [];
    }
    obj.excludeLatestMessageWithinDays = message.excludeLatestMessageWithinDays || undefined;
    obj.messageReplied = message.messageReplied || undefined;
    obj.favorite = message.favorite || undefined;
    return obj;
  },
};

export const ReasonForClosingHumanResourcesMatter = {
  encode(message: ReasonForClosingHumanResourcesMatter, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).uint32(message.id);
    writer.uint32(18).string(message.name);
    return writer;
  },
  decode(reader: Reader, length?: number): ReasonForClosingHumanResourcesMatter {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseReasonForClosingHumanResourcesMatter) as ReasonForClosingHumanResourcesMatter;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.uint32();
          break;
        case 2:
          message.name = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): ReasonForClosingHumanResourcesMatter {
    const message = Object.create(baseReasonForClosingHumanResourcesMatter) as ReasonForClosingHumanResourcesMatter;
    if (object.id) {
      message.id = Number(object.id);
    }
    if (object.name) {
      message.name = String(object.name);
    }
    return message;
  },
  fromPartial(object: DeepPartial<ReasonForClosingHumanResourcesMatter>): ReasonForClosingHumanResourcesMatter {
    const message = Object.create(baseReasonForClosingHumanResourcesMatter) as ReasonForClosingHumanResourcesMatter;
    if (object.id) {
      message.id = object.id;
    }
    if (object.name) {
      message.name = object.name;
    }
    return message;
  },
  toJSON(message: ReasonForClosingHumanResourcesMatter): unknown {
    const obj: any = {};
    obj.id = message.id || 0;
    obj.name = message.name || "";
    return obj;
  },
};

export const Company = {
  encode(message: Company, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.id);
    writer.uint32(18).string(message.name);
    return writer;
  },
  decode(reader: Reader, length?: number): Company {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseCompany) as Company;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string();
          break;
        case 2:
          message.name = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): Company {
    const message = Object.create(baseCompany) as Company;
    if (object.id) {
      message.id = String(object.id);
    }
    if (object.name) {
      message.name = String(object.name);
    }
    return message;
  },
  fromPartial(object: DeepPartial<Company>): Company {
    const message = Object.create(baseCompany) as Company;
    if (object.id) {
      message.id = object.id;
    }
    if (object.name) {
      message.name = object.name;
    }
    return message;
  },
  toJSON(message: Company): unknown {
    const obj: any = {};
    obj.id = message.id || "";
    obj.name = message.name || "";
    return obj;
  },
};

export const BlockCompany = {
  encode(message: BlockCompany, writer: Writer = Writer.create()): Writer {
    writer.uint32(18).string(message.nameLike);
    return writer;
  },
  decode(reader: Reader, length?: number): BlockCompany {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseBlockCompany) as BlockCompany;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          message.nameLike = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): BlockCompany {
    const message = Object.create(baseBlockCompany) as BlockCompany;
    if (object.nameLike) {
      message.nameLike = String(object.nameLike);
    }
    return message;
  },
  fromPartial(object: DeepPartial<BlockCompany>): BlockCompany {
    const message = Object.create(baseBlockCompany) as BlockCompany;
    if (object.nameLike) {
      message.nameLike = object.nameLike;
    }
    return message;
  },
  toJSON(message: BlockCompany): unknown {
    const obj: any = {};
    obj.nameLike = message.nameLike || "";
    return obj;
  },
};

export const Location = {
  encode(message: Location, writer: Writer = Writer.create()): Writer {
    if (message.japanPrefecture !== undefined && message.japanPrefecture !== undefined) {
      JapanPrefecture.encode(message.japanPrefecture, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },
  decode(reader: Reader, length?: number): Location {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseLocation) as Location;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.japanPrefecture = JapanPrefecture.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): Location {
    const message = Object.create(baseLocation) as Location;
    if (object.japanPrefecture) {
      message.japanPrefecture = JapanPrefecture.fromJSON(object.japanPrefecture);
    }
    return message;
  },
  fromPartial(object: DeepPartial<Location>): Location {
    const message = Object.create(baseLocation) as Location;
    if (object.japanPrefecture) {
      message.japanPrefecture = JapanPrefecture.fromPartial(object.japanPrefecture);
    }
    return message;
  },
  toJSON(message: Location): unknown {
    const obj: any = {};
    obj.japanPrefecture = message.japanPrefecture ? JapanPrefecture.toJSON(message.japanPrefecture) : undefined;
    return obj;
  },
};

export const AcademicBackground = {
  encode(message: AcademicBackground, writer: Writer = Writer.create()): Writer {
    if (message.school !== undefined && message.school !== undefined) {
      School.encode(message.school, writer.uint32(10).fork()).ldelim();
    }
    writer.uint32(18).string(message.faculty);
    if (message.department !== undefined && message.department !== undefined) {
      Department.encode(message.department, writer.uint32(26).fork()).ldelim();
    }
    writer.uint32(32).uint32(message.yearOfGraduation);
    writer.uint32(40).uint32(message.monthOfGraduation);
    return writer;
  },
  decode(reader: Reader, length?: number): AcademicBackground {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseAcademicBackground) as AcademicBackground;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.school = School.decode(reader, reader.uint32());
          break;
        case 2:
          message.faculty = reader.string();
          break;
        case 3:
          message.department = Department.decode(reader, reader.uint32());
          break;
        case 4:
          message.yearOfGraduation = reader.uint32();
          break;
        case 5:
          message.monthOfGraduation = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): AcademicBackground {
    const message = Object.create(baseAcademicBackground) as AcademicBackground;
    if (object.school) {
      message.school = School.fromJSON(object.school);
    }
    if (object.faculty) {
      message.faculty = String(object.faculty);
    }
    if (object.department) {
      message.department = Department.fromJSON(object.department);
    }
    if (object.yearOfGraduation) {
      message.yearOfGraduation = Number(object.yearOfGraduation);
    }
    if (object.monthOfGraduation) {
      message.monthOfGraduation = Number(object.monthOfGraduation);
    }
    return message;
  },
  fromPartial(object: DeepPartial<AcademicBackground>): AcademicBackground {
    const message = Object.create(baseAcademicBackground) as AcademicBackground;
    if (object.school) {
      message.school = School.fromPartial(object.school);
    }
    if (object.faculty) {
      message.faculty = object.faculty;
    }
    if (object.department) {
      message.department = Department.fromPartial(object.department);
    }
    if (object.yearOfGraduation) {
      message.yearOfGraduation = object.yearOfGraduation;
    }
    if (object.monthOfGraduation) {
      message.monthOfGraduation = object.monthOfGraduation;
    }
    return message;
  },
  toJSON(message: AcademicBackground): unknown {
    const obj: any = {};
    obj.school = message.school ? School.toJSON(message.school) : undefined;
    obj.faculty = message.faculty || "";
    obj.department = message.department ? Department.toJSON(message.department) : undefined;
    obj.yearOfGraduation = message.yearOfGraduation || 0;
    obj.monthOfGraduation = message.monthOfGraduation || 0;
    return obj;
  },
};

export const ScoutCompany = {
  encode(message: ScoutCompany, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.missionId);
    writer.uint32(18).string(message.companyName);
    if (message.scoutContractStatus !== undefined && message.scoutContractStatus !== undefined) {
      ScoutContractStatus.encode(message.scoutContractStatus, writer.uint32(26).fork()).ldelim();
    }
    writer.uint32(32).uint64(message.numberOfViewsForUseExplanation);
    writer.uint32(40).uint64(message.numberOfSending);
    writer.uint32(50).string(message.staffName);
    return writer;
  },
  decode(reader: Reader, length?: number): ScoutCompany {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseScoutCompany) as ScoutCompany;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.missionId = reader.string();
          break;
        case 2:
          message.companyName = reader.string();
          break;
        case 3:
          message.scoutContractStatus = ScoutContractStatus.decode(reader, reader.uint32());
          break;
        case 4:
          message.numberOfViewsForUseExplanation = longToNumber(reader.uint64() as Long);
          break;
        case 5:
          message.numberOfSending = longToNumber(reader.uint64() as Long);
          break;
        case 6:
          message.staffName = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): ScoutCompany {
    const message = Object.create(baseScoutCompany) as ScoutCompany;
    if (object.missionId) {
      message.missionId = String(object.missionId);
    }
    if (object.companyName) {
      message.companyName = String(object.companyName);
    }
    if (object.scoutContractStatus) {
      message.scoutContractStatus = ScoutContractStatus.fromJSON(object.scoutContractStatus);
    }
    if (object.numberOfViewsForUseExplanation) {
      message.numberOfViewsForUseExplanation = Number(object.numberOfViewsForUseExplanation);
    }
    if (object.numberOfSending) {
      message.numberOfSending = Number(object.numberOfSending);
    }
    if (object.staffName) {
      message.staffName = String(object.staffName);
    }
    return message;
  },
  fromPartial(object: DeepPartial<ScoutCompany>): ScoutCompany {
    const message = Object.create(baseScoutCompany) as ScoutCompany;
    if (object.missionId) {
      message.missionId = object.missionId;
    }
    if (object.companyName) {
      message.companyName = object.companyName;
    }
    if (object.scoutContractStatus) {
      message.scoutContractStatus = ScoutContractStatus.fromPartial(object.scoutContractStatus);
    }
    if (object.numberOfViewsForUseExplanation) {
      message.numberOfViewsForUseExplanation = object.numberOfViewsForUseExplanation;
    }
    if (object.numberOfSending) {
      message.numberOfSending = object.numberOfSending;
    }
    if (object.staffName) {
      message.staffName = object.staffName;
    }
    return message;
  },
  toJSON(message: ScoutCompany): unknown {
    const obj: any = {};
    obj.missionId = message.missionId || "";
    obj.companyName = message.companyName || "";
    obj.scoutContractStatus = message.scoutContractStatus ? ScoutContractStatus.toJSON(message.scoutContractStatus) : undefined;
    obj.numberOfViewsForUseExplanation = message.numberOfViewsForUseExplanation || 0;
    obj.numberOfSending = message.numberOfSending || 0;
    obj.staffName = message.staffName || "";
    return obj;
  },
};

export const ScoutCompanyBillingAddress = {
  encode(message: ScoutCompanyBillingAddress, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.lastName);
    writer.uint32(18).string(message.firstName);
    writer.uint32(26).string(message.officialPosition);
    writer.uint32(34).string(message.email);
    writer.uint32(42).string(message.phoneNumber);
    writer.uint32(50).string(message.address);
    return writer;
  },
  decode(reader: Reader, length?: number): ScoutCompanyBillingAddress {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseScoutCompanyBillingAddress) as ScoutCompanyBillingAddress;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.lastName = reader.string();
          break;
        case 2:
          message.firstName = reader.string();
          break;
        case 3:
          message.officialPosition = reader.string();
          break;
        case 4:
          message.email = reader.string();
          break;
        case 5:
          message.phoneNumber = reader.string();
          break;
        case 6:
          message.address = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): ScoutCompanyBillingAddress {
    const message = Object.create(baseScoutCompanyBillingAddress) as ScoutCompanyBillingAddress;
    if (object.lastName) {
      message.lastName = String(object.lastName);
    }
    if (object.firstName) {
      message.firstName = String(object.firstName);
    }
    if (object.officialPosition) {
      message.officialPosition = String(object.officialPosition);
    }
    if (object.email) {
      message.email = String(object.email);
    }
    if (object.phoneNumber) {
      message.phoneNumber = String(object.phoneNumber);
    }
    if (object.address) {
      message.address = String(object.address);
    }
    return message;
  },
  fromPartial(object: DeepPartial<ScoutCompanyBillingAddress>): ScoutCompanyBillingAddress {
    const message = Object.create(baseScoutCompanyBillingAddress) as ScoutCompanyBillingAddress;
    if (object.lastName) {
      message.lastName = object.lastName;
    }
    if (object.firstName) {
      message.firstName = object.firstName;
    }
    if (object.officialPosition) {
      message.officialPosition = object.officialPosition;
    }
    if (object.email) {
      message.email = object.email;
    }
    if (object.phoneNumber) {
      message.phoneNumber = object.phoneNumber;
    }
    if (object.address) {
      message.address = object.address;
    }
    return message;
  },
  toJSON(message: ScoutCompanyBillingAddress): unknown {
    const obj: any = {};
    obj.lastName = message.lastName || "";
    obj.firstName = message.firstName || "";
    obj.officialPosition = message.officialPosition || "";
    obj.email = message.email || "";
    obj.phoneNumber = message.phoneNumber || "";
    obj.address = message.address || "";
    return obj;
  },
};

export const ScoutBillingStatus = {
  encode(message: ScoutBillingStatus, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).uint32(message.id);
    writer.uint32(18).string(message.name);
    return writer;
  },
  decode(reader: Reader, length?: number): ScoutBillingStatus {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseScoutBillingStatus) as ScoutBillingStatus;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.uint32();
          break;
        case 2:
          message.name = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): ScoutBillingStatus {
    const message = Object.create(baseScoutBillingStatus) as ScoutBillingStatus;
    if (object.id) {
      message.id = Number(object.id);
    }
    if (object.name) {
      message.name = String(object.name);
    }
    return message;
  },
  fromPartial(object: DeepPartial<ScoutBillingStatus>): ScoutBillingStatus {
    const message = Object.create(baseScoutBillingStatus) as ScoutBillingStatus;
    if (object.id) {
      message.id = object.id;
    }
    if (object.name) {
      message.name = object.name;
    }
    return message;
  },
  toJSON(message: ScoutBillingStatus): unknown {
    const obj: any = {};
    obj.id = message.id || 0;
    obj.name = message.name || "";
    return obj;
  },
};

export const ScoutContractStatus = {
  encode(message: ScoutContractStatus, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).uint32(message.id);
    writer.uint32(18).string(message.name);
    return writer;
  },
  decode(reader: Reader, length?: number): ScoutContractStatus {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseScoutContractStatus) as ScoutContractStatus;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.uint32();
          break;
        case 2:
          message.name = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): ScoutContractStatus {
    const message = Object.create(baseScoutContractStatus) as ScoutContractStatus;
    if (object.id) {
      message.id = Number(object.id);
    }
    if (object.name) {
      message.name = String(object.name);
    }
    return message;
  },
  fromPartial(object: DeepPartial<ScoutContractStatus>): ScoutContractStatus {
    const message = Object.create(baseScoutContractStatus) as ScoutContractStatus;
    if (object.id) {
      message.id = object.id;
    }
    if (object.name) {
      message.name = object.name;
    }
    return message;
  },
  toJSON(message: ScoutContractStatus): unknown {
    const obj: any = {};
    obj.id = message.id || 0;
    obj.name = message.name || "";
    return obj;
  },
};

export const MessageTemplate = {
  encode(message: MessageTemplate, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).uint64(message.id);
    writer.uint32(18).string(message.name);
    writer.uint32(26).string(message.template);
    writer.uint32(32).int64(message.updatedAt);
    return writer;
  },
  decode(reader: Reader, length?: number): MessageTemplate {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseMessageTemplate) as MessageTemplate;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = longToNumber(reader.uint64() as Long);
          break;
        case 2:
          message.name = reader.string();
          break;
        case 3:
          message.template = reader.string();
          break;
        case 4:
          message.updatedAt = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): MessageTemplate {
    const message = Object.create(baseMessageTemplate) as MessageTemplate;
    if (object.id) {
      message.id = Number(object.id);
    }
    if (object.name) {
      message.name = String(object.name);
    }
    if (object.template) {
      message.template = String(object.template);
    }
    if (object.updatedAt) {
      message.updatedAt = Number(object.updatedAt);
    }
    return message;
  },
  fromPartial(object: DeepPartial<MessageTemplate>): MessageTemplate {
    const message = Object.create(baseMessageTemplate) as MessageTemplate;
    if (object.id) {
      message.id = object.id;
    }
    if (object.name) {
      message.name = object.name;
    }
    if (object.template) {
      message.template = object.template;
    }
    if (object.updatedAt) {
      message.updatedAt = object.updatedAt;
    }
    return message;
  },
  toJSON(message: MessageTemplate): unknown {
    const obj: any = {};
    obj.id = message.id || 0;
    obj.name = message.name || "";
    obj.template = message.template || "";
    obj.updatedAt = message.updatedAt || 0;
    return obj;
  },
};

export const MissionJobOffer = {
  encode(message: MissionJobOffer, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.id);
    if (message.occupation !== undefined && message.occupation !== undefined) {
      Occupation.encode(message.occupation, writer.uint32(18).fork()).ldelim();
    }
    writer.uint32(26).string(message.jobDescription);
    writer.uint32(32).bool(message.published);
    return writer;
  },
  decode(reader: Reader, length?: number): MissionJobOffer {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseMissionJobOffer) as MissionJobOffer;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string();
          break;
        case 2:
          message.occupation = Occupation.decode(reader, reader.uint32());
          break;
        case 3:
          message.jobDescription = reader.string();
          break;
        case 4:
          message.published = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): MissionJobOffer {
    const message = Object.create(baseMissionJobOffer) as MissionJobOffer;
    if (object.id) {
      message.id = String(object.id);
    }
    if (object.occupation) {
      message.occupation = Occupation.fromJSON(object.occupation);
    }
    if (object.jobDescription) {
      message.jobDescription = String(object.jobDescription);
    }
    if (object.published) {
      message.published = Boolean(object.published);
    }
    return message;
  },
  fromPartial(object: DeepPartial<MissionJobOffer>): MissionJobOffer {
    const message = Object.create(baseMissionJobOffer) as MissionJobOffer;
    if (object.id) {
      message.id = object.id;
    }
    if (object.occupation) {
      message.occupation = Occupation.fromPartial(object.occupation);
    }
    if (object.jobDescription) {
      message.jobDescription = object.jobDescription;
    }
    if (object.published) {
      message.published = object.published;
    }
    return message;
  },
  toJSON(message: MissionJobOffer): unknown {
    const obj: any = {};
    obj.id = message.id || "";
    obj.occupation = message.occupation ? Occupation.toJSON(message.occupation) : undefined;
    obj.jobDescription = message.jobDescription || "";
    obj.published = message.published || false;
    return obj;
  },
};

export const MissionJobOfferToUpdate = {
  encode(message: MissionJobOfferToUpdate, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).uint32(message.occupationId);
    writer.uint32(18).string(message.jobDescription);
    writer.uint32(24).bool(message.published);
    return writer;
  },
  decode(reader: Reader, length?: number): MissionJobOfferToUpdate {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseMissionJobOfferToUpdate) as MissionJobOfferToUpdate;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.occupationId = reader.uint32();
          break;
        case 2:
          message.jobDescription = reader.string();
          break;
        case 3:
          message.published = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): MissionJobOfferToUpdate {
    const message = Object.create(baseMissionJobOfferToUpdate) as MissionJobOfferToUpdate;
    if (object.occupationId) {
      message.occupationId = Number(object.occupationId);
    }
    if (object.jobDescription) {
      message.jobDescription = String(object.jobDescription);
    }
    if (object.published) {
      message.published = Boolean(object.published);
    }
    return message;
  },
  fromPartial(object: DeepPartial<MissionJobOfferToUpdate>): MissionJobOfferToUpdate {
    const message = Object.create(baseMissionJobOfferToUpdate) as MissionJobOfferToUpdate;
    if (object.occupationId) {
      message.occupationId = object.occupationId;
    }
    if (object.jobDescription) {
      message.jobDescription = object.jobDescription;
    }
    if (object.published) {
      message.published = object.published;
    }
    return message;
  },
  toJSON(message: MissionJobOfferToUpdate): unknown {
    const obj: any = {};
    obj.occupationId = message.occupationId || 0;
    obj.jobDescription = message.jobDescription || "";
    obj.published = message.published || false;
    return obj;
  },
};

export const MatterPublishStatus = {
  encode(message: MatterPublishStatus, writer: Writer = Writer.create()): Writer {
    writer.uint32(8).uint32(message.id);
    writer.uint32(18).string(message.name);
    return writer;
  },
  decode(reader: Reader, length?: number): MatterPublishStatus {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseMatterPublishStatus) as MatterPublishStatus;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.uint32();
          break;
        case 2:
          message.name = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): MatterPublishStatus {
    const message = Object.create(baseMatterPublishStatus) as MatterPublishStatus;
    if (object.id) {
      message.id = Number(object.id);
    }
    if (object.name) {
      message.name = String(object.name);
    }
    return message;
  },
  fromPartial(object: DeepPartial<MatterPublishStatus>): MatterPublishStatus {
    const message = Object.create(baseMatterPublishStatus) as MatterPublishStatus;
    if (object.id) {
      message.id = object.id;
    }
    if (object.name) {
      message.name = object.name;
    }
    return message;
  },
  toJSON(message: MatterPublishStatus): unknown {
    const obj: any = {};
    obj.id = message.id || 0;
    obj.name = message.name || "";
    return obj;
  },
};

type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T[P] extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T[P] extends Date | Function | Uint8Array | undefined
  ? T[P]
  : T[P] extends infer U | undefined
  ? DeepPartial<U>
  : T[P] extends object
  ? DeepPartial<T[P]>
  : T[P]
};