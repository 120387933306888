import React, { FC, HTMLAttributes } from 'react';
import styled from 'styled-components/macro';
import theme from 'styles/theme';
import media from 'styles/media';
import * as MESSAGES from 'constants/messages';

const Wrapper = styled.h1`
  color: ${theme.baseWhite};
  /* stylelint-disable font-family-no-missing-generic-family-keyword */
  font-family: 'Gilroy-Bold';
  font-size: 24px;
  letter-spacing: 0;
  line-height: 24px;
  vertical-align: middle;

  ${media.mobile} {
    font-size: 22px;
    line-height: 22px;
  }
`;

const Logo: FC<HTMLAttributes<HTMLDivElement>> = props => {
  return <Wrapper {...props}>{MESSAGES.HEADER.TITLE}</Wrapper>;
};

export default Logo;
