import React, { FC } from 'react';
import * as MESSAGES from 'constants/messages';
import { Label, ProfileField, ProfileContent } from 'components/atoms';
import { Innovator } from 'proto/v1/apimodel/apimodel';

type DttScoreFieldProps = {
  dttScore: Innovator['dttScore'];
};

const DttScoreField: FC<DttScoreFieldProps> = props => {
  const { dttScore } = props;

  return (
    <ProfileField data-testid="dtt-score-field">
      <Label>{MESSAGES.PROFILE.FIELD.DTT.DTT_SCORE.LABEL}</Label>
      <ProfileContent>{dttScore ?? '-'}</ProfileContent>
    </ProfileField>
  );
};

export default DttScoreField;
