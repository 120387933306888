import React, { FC } from 'react';
import styled from 'styled-components/macro';
import media from 'styles/media';
import { FieldError } from 'react-hook-form';
import * as MESSAGES from 'constants/messages';
import { ProfileFormItems } from 'types/profile';
import { isEqual } from 'utils/object';
import { Form, ButtonM } from 'components/atoms';
import {
  EnglishSkillFormField,
  PreferredWorkLocationsFormField,
  CertificationsFormField,
  PreferredOccupationsFormField,
  NewGraduateDttFormField,
  OutputsFormField,
  NewGraduateProgrammingExperiencesFormField,
  NewGraduatePreferredWorkingStyleFormField,
  NewGraduatePreferredBusinessTypesFormField,
} from 'components/organisms';
import useNewGraduateProfileSkillsAndPreferredConditionsForm from './useNewGraduateProfileSkillsAndPreferredConditionsForm';

const Fields = styled.div``;

const Buttons = styled.div`
  display: grid;
  grid-column-gap: 16px;
  grid-template-columns: auto auto;

  ${media.smallMobile} {
    grid-row-gap: 16px;
    grid-template-columns: 1fr;
  }
`;

const CancelButton = styled(ButtonM)`
  justify-self: end;

  ${media.smallMobile} {
    order: 2;
    width: 100%;
  }
`;

const SubmitButton = styled(ButtonM)`
  ${media.smallMobile} {
    order: 1;
    width: 100%;
  }
`;

type NewGraduateProfileSkillsAndPreferredConditionsFormProps = {
  formItems: ProfileFormItems;
  onShowContent: () => void;
  onOpenCompletedModal: () => void;
};

const NewGraduateProfileSkillsAndPreferredConditionsForm: FC<NewGraduateProfileSkillsAndPreferredConditionsFormProps> = props => {
  const { formItems, onShowContent, onOpenCompletedModal } = props;

  const {
    formMethods,
    onSubmit,
    preferredOccupationsError,
    newGraduateDttScoreError,
    newGraduateRequiredAllDttFieldsError,
    newGraduateRequiredAllProgrammingExperienceFieldsErrors,
    newGraduatePreferredBusinessTypesError,
  } = useNewGraduateProfileSkillsAndPreferredConditionsForm({
    formItems,
    onShowContent,
    onOpenCompletedModal,
  });

  const {
    handleSubmit,
    errors,
    register,
    formState,
    control,
    trigger,
  } = formMethods;

  const { isValid, isSubmitting, isDirty, touched } = formState;
  const isDisabledSubmitButton = !isValid || isSubmitting || !isDirty;

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      data-testid="new-graduate-profile-skills-and-preferred-conditions-form"
    >
      <Fields>
        <EnglishSkillFormField
          error={touched.englishSkillId && errors.englishSkillId?.message}
          englishSkills={formItems.englishSkills}
          control={control}
        />

        <CertificationsFormField control={control} register={register} />

        <NewGraduateDttFormField
          dttRanks={formItems.dttRanks}
          trigger={trigger}
          control={control}
          errors={{
            dttScore: touched.dttScore && newGraduateDttScoreError,
            requiredInputAll:
              touched.dttScore &&
              touched.dttRankId &&
              newGraduateRequiredAllDttFieldsError,
          }}
          register={register}
        />

        <NewGraduateProgrammingExperiencesFormField
          register={register}
          control={control}
          trigger={trigger}
          errors={newGraduateRequiredAllProgrammingExperienceFieldsErrors}
          touched={touched.newGraduate?.programmingExperiences}
          programmingExperienceLevels={formItems.programmingExperienceLevels}
        />

        <OutputsFormField
          register={register}
          control={control}
          errors={errors.outputs}
          touched={touched.outputs}
        />

        <NewGraduatePreferredWorkingStyleFormField
          error={
            touched.newGraduate?.preferredWorkStyleId &&
            errors?.newGraduate?.preferredWorkStyleId?.message
          }
          preferredWorkStyles={formItems.preferredWorkStyles}
          control={control}
        />

        <NewGraduatePreferredBusinessTypesFormField
          error={
            touched.newGraduate?.preferredBusinessTypes &&
            newGraduatePreferredBusinessTypesError
          }
          preferredBusinessTypes={formItems.preferredBusinessTypes}
          control={control}
          trigger={trigger}
        />

        <PreferredOccupationsFormField
          error={touched.preferredOccupations && preferredOccupationsError}
          occupations={formItems.occupations}
          control={control}
          trigger={trigger}
        />

        <PreferredWorkLocationsFormField
          error={
            touched.preferredWorkLocationJapanPrefectureIds &&
            (errors.preferredWorkLocationJapanPrefectureIds as
              | FieldError
              | undefined)?.message
          }
          japanPrefectures={formItems.japanPrefectures}
          control={control}
          trigger={trigger}
        />
      </Fields>

      <Buttons>
        <CancelButton buttonTheme="cancel" onClick={onShowContent}>
          {MESSAGES.PROFILE_SETTING.BUTTON.CANCEL}
        </CancelButton>

        <SubmitButton type="submit" disabled={isDisabledSubmitButton}>
          {MESSAGES.PROFILE_SETTING.BUTTON.SAVE}
        </SubmitButton>
      </Buttons>
    </Form>
  );
};

export default React.memo(
  NewGraduateProfileSkillsAndPreferredConditionsForm,
  isEqual,
);
