import styled from 'styled-components/macro';
import { Textarea } from 'visits-style';
import theme from 'styles/theme';
import media from 'styles/media';

const StyledTextarea = styled(Textarea)`
  textarea {
    min-height: 180px;
    padding: 8px 12px;
    border: 1px solid
      ${({ error }) => (error ? theme.baseError : theme.borderDefault)};
    font-size: 14px;
    line-height: 1.5;

    &:hover,
    &:focus,
    &:active {
      border-color: ${({ error }) =>
        error ? theme.baseError : theme.borderActive};
      box-shadow: none;
    }

    &::placeholder {
      color: ${theme.borderDefault};
    }

    ${media.mobile} {
      font-size: 16px;
    }
  }

  /* error message */
  small {
    display: inline-block;
    font-size: 14px;
    line-height: 1.5;

    ${media.mobile} {
      font-size: 12px;
    }
  }

  &:hover {
    textarea:not(:disabled):not(:focus) {
      border-color: ${({ error }) =>
        error ? theme.baseError : theme.borderDefault};
    }
  }
`;

export default StyledTextarea;
