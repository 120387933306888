import React, { FC } from 'react';
import * as MESSAGES from 'constants/messages';
import { Label, ProfileField, ProfileContent } from 'components/atoms';
import { Innovator } from 'proto/v1/apimodel/apimodel';

type NameFieldProps = {
  lastName: Innovator['lastName'];
  firstName: Innovator['firstName'];
};

const NameField: FC<NameFieldProps> = props => {
  const { lastName, firstName } = props;

  return (
    <ProfileField data-testid="name-field">
      <Label>{MESSAGES.PROFILE.FIELD.NAME.LABEL}</Label>
      <ProfileContent>
        {lastName}
        {firstName}
      </ProfileContent>
    </ProfileField>
  );
};

export default NameField;
