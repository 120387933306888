import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components/macro';
import { GlobalLayout } from 'components/templates';
import Routes from 'routes/Routes';
import store from 'store';
import GlobalStyle from 'styles/global';
import theme from 'styles/theme';
import apiFirebase from './external/firebase/firebase';

apiFirebase.init();

export default function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <GlobalStyle />
          <GlobalLayout>
            <Routes />
          </GlobalLayout>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  );
}
