export default {
  BUTTON: {
    OK: 'OK',
    YES: 'はい',
    CANCEL: 'キャンセル',
    CLOSE: '閉じる',
    RELOAD: 'リロードする',
    RETRY_LOAD: '再読み込み',
    ADD_FIELD: '項目を追加する',
    REMOVE_FIELD: '項目を削除する',
  },
  SELECT_BOX: {
    NO_SELECT: '選択してください',
  },
  FORM: {
    FIELD: {
      OPTIONAL: '任意',
    },
    DESCRIPTION: {
      REQUIRED_MARK: 'マークが付いている項目は入力が必須です',
      LOCKED_MARK:
        'マークが付いている項目はあなたの許可無く企業側に公開されることはありません',
    },
  },
  DESCRIPTION: {
    NOT_REGISTERED: '登録されていません',
  },
};
