import React, { FC } from 'react';
import styled from 'styled-components/macro';
import media from 'styles/media';
import * as MESSAGES from 'constants/messages';
import { ProfileFormItems } from 'types/profile';
import { getPhoneCountryCode } from 'utils/profile/fields';
import { isEqual } from 'utils/object';
import { Form, ButtonM, GroupLabel, CardInner } from 'components/atoms';
import {
  MemberNumberFormField,
  FirstNameFormField,
  LastNameFormField,
  FirstNameKanaFormField,
  LastNameKanaFormField,
  YearMonthOfBirthFormField,
  PhoneNumberFormField,
  JapanPrefectureFormField,
  AcademicBackgroundSchoolFormField,
  AcademicBackgroundFacultyFormField,
  AcademicBackgroundDepartmentFormField,
  AcademicBackgroundYearMonthOfGraduationFormField,
  NewGraduateResearchRoomsFormField,
} from 'components/organisms';
import useNewGraduateProfileBasicInfoForm from './useNewGraduateProfileBasicInfoForm';

const Fields = styled.div``;

const NameFields = styled.div`
  display: grid;
  grid-column-gap: 16px;
  grid-template-columns: 1fr 1fr;

  ${media.mobile} {
    grid-template-columns: 1fr;
  }
`;

const Buttons = styled.div`
  display: grid;
  grid-column-gap: 16px;
  grid-template-columns: auto auto;

  ${media.smallMobile} {
    grid-row-gap: 16px;
    grid-template-columns: 1fr;
  }
`;

const CancelButton = styled(ButtonM)`
  justify-self: end;

  ${media.smallMobile} {
    order: 2;
    width: 100%;
  }
`;

const SubmitButton = styled(ButtonM)`
  ${media.smallMobile} {
    order: 1;
    width: 100%;
  }
`;

type Props = {
  formItems: ProfileFormItems;
  onShowContent: () => void;
  onOpenCompletedModal: () => void;
};

const NewGraduateProfileBasicInfoForm: FC<Props> = props => {
  const { formItems, onShowContent, onOpenCompletedModal } = props;

  const {
    innovator,
    formMethods,
    onSubmit,
    schoolOptionsState,
    handleInputChangeSchool,
    handleCreateSchoolOption,
    fetchMoreSchoolOptions,
  } = useNewGraduateProfileBasicInfoForm({
    formItems,
    onShowContent,
    onOpenCompletedModal,
  });

  const {
    handleSubmit,
    errors,
    register,
    formState,
    watch,
    control,
  } = formMethods;

  const { isValid, isSubmitting, isDirty, touched } = formState;

  const isDisabledSubmitButton = !isValid || isSubmitting || !isDirty;
  const watchPhoneCountryId = watch('phoneCountryId');

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      data-testid="new-graduate-profile-basic-info-form"
    >
      <Fields>
        <MemberNumberFormField innovatorId={innovator?.innovatorId} />

        <NameFields>
          <LastNameFormField
            error={touched.lastName && errors.lastName?.message}
            register={register}
          />
          <FirstNameFormField
            error={touched.firstName && errors.firstName?.message}
            register={register}
          />
        </NameFields>

        <NameFields>
          <LastNameKanaFormField
            error={touched.lastNameKana && errors.lastNameKana?.message}
            register={register}
          />
          <FirstNameKanaFormField
            error={touched.firstNameKana && errors.firstNameKana?.message}
            register={register}
          />
        </NameFields>

        <YearMonthOfBirthFormField
          errors={{
            yearOfBirth: touched.yearOfBirth && errors.yearOfBirth?.message,
            monthOfBirth: touched.monthOfBirth && errors.monthOfBirth?.message,
          }}
          control={control}
        />

        <PhoneNumberFormField
          countries={formItems.countries}
          error={touched.phoneNumber && errors.phoneNumber?.message}
          phoneCountryCode={getPhoneCountryCode(
            watchPhoneCountryId,
            formItems.countries,
          )}
          control={control}
          register={register}
        />

        <JapanPrefectureFormField
          error={touched.japanPrefectureId && errors.japanPrefectureId?.message}
          japanPrefectures={formItems.japanPrefectures}
          control={control}
        />

        <AcademicBackgroundSchoolFormField
          label={
            MESSAGES.PROFILE.FIELD.ACADEMIC_BACKGROUND_SCHOOL.LABEL_NEW_GRADUATE
          }
          inputValue={schoolOptionsState.inputValue}
          options={schoolOptionsState.options}
          fetchMore={fetchMoreSchoolOptions}
          isLoading={schoolOptionsState.isLoading}
          onInputChange={handleInputChangeSchool}
          onCreateOption={handleCreateSchoolOption}
          error={
            !schoolOptionsState.isCreating && touched.academicBackground?.school
              ? errors.academicBackground?.school?.message
              : undefined
          }
          control={control}
        />

        <AcademicBackgroundFacultyFormField
          label={
            MESSAGES.PROFILE.FIELD.ACADEMIC_BACKGROUND_FACULTY
              .LABEL_NEW_GRADUATE
          }
          error={
            touched.academicBackground?.faculty &&
            errors.academicBackground?.faculty?.message
          }
          register={register}
        />

        <AcademicBackgroundDepartmentFormField
          error={
            touched.academicBackground?.departmentId &&
            errors.academicBackground?.departmentId?.message
          }
          departments={formItems.departments}
          control={control}
        />

        <AcademicBackgroundYearMonthOfGraduationFormField
          label={
            MESSAGES.PROFILE.FIELD.ACADEMIC_BACKGROUND_YEAR_MONTH_OF_GRADUATION
              .LABEL_NEW_GRADUATE
          }
          errors={{
            yearOfGraduation:
              touched.academicBackground?.yearOfGraduation &&
              errors.academicBackground?.yearOfGraduation?.message,
            monthOfGraduation:
              touched.academicBackground?.monthOfGraduation &&
              errors.academicBackground?.monthOfGraduation?.message,
          }}
          control={control}
          isNewGraduate
        />

        <GroupLabel>
          {MESSAGES.PROFILE.FIELD.NEW_GRADUATE_RESEARCH_ROOMS.LABEL}
        </GroupLabel>
        <CardInner>
          <NewGraduateResearchRoomsFormField
            register={register}
            control={control}
          />
        </CardInner>
      </Fields>

      <Buttons>
        <CancelButton buttonTheme="cancel" onClick={onShowContent}>
          {MESSAGES.PROFILE_SETTING.BUTTON.CANCEL}
        </CancelButton>

        <SubmitButton type="submit" disabled={isDisabledSubmitButton}>
          {MESSAGES.PROFILE_SETTING.BUTTON.SAVE}
        </SubmitButton>
      </Buttons>
    </Form>
  );
};

export default React.memo(NewGraduateProfileBasicInfoForm, isEqual);
