import React, { FC } from 'react';
import * as MESSAGES from 'constants/messages';
import { interpolateMessage } from 'utils/string';
import { CareerJobHistory } from 'types/profile';
import { Label, ProfileField, ProfileContent } from 'components/atoms';

type WorkingPeriodFieldProps = {
  startYear: CareerJobHistory['startYear'];
  startMonth: CareerJobHistory['startMonth'];
  endYear: CareerJobHistory['endYear'];
  endMonth: CareerJobHistory['endMonth'];
};

const WorkingPeriodField: FC<WorkingPeriodFieldProps> = props => {
  const { startYear, startMonth, endYear, endMonth } = props;

  return (
    <ProfileField data-testid="career-job-working-period-field">
      <Label>{MESSAGES.PROFILE.FIELD.CAREER_JOB.WORKING_PERIOD.LABEL}</Label>
      <ProfileContent>
        {interpolateMessage(MESSAGES.PROFILE.YEAR_MONTH, {
          year: startYear,
          month: startMonth,
        })}
        {' 〜 '}
        {endYear && endMonth
          ? interpolateMessage(MESSAGES.PROFILE.YEAR_MONTH, {
              year: endYear,
              month: endMonth,
            })
          : MESSAGES.PROFILE.FIELD.CAREER_JOB.WORKING_PERIOD.CURRENTLY_ENROLLED}
      </ProfileContent>
    </ProfileField>
  );
};

export default WorkingPeriodField;
