import React, { FC, HTMLAttributes } from 'react';
import styled from 'styled-components/macro';
import * as MESSAGES from 'constants/messages';
import { MissionForInnovator } from 'types/mission';
import { WrapText } from 'components/atoms';
import { SubTitle } from '../../Typography';

const Address = styled(WrapText)`
  line-height: 1.5;
`;

type Props = {
  japanPrefecture: MissionForInnovator['japanPrefecture'] | undefined;
  otherAddress: MissionForInnovator['otherAddress'] | undefined;
} & HTMLAttributes<HTMLDivElement>;

const AddressField: FC<Props> = ({
  japanPrefecture,
  otherAddress,
  ...rest
}) => {
  return (
    <div {...rest}>
      <SubTitle>{MESSAGES.MISSION_CARD.FIELD.ADDRESS.LABEL}</SubTitle>
      <Address>
        {japanPrefecture?.name ?? ''}
        {otherAddress ?? ''}
      </Address>
    </div>
  );
};

export default AddressField;
