import React, { VFC, useCallback, useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components/macro';
import theme from 'styles/theme';
import media from 'styles/media';
import * as MESSAGES from 'constants/messages';
import { isCompanyCorporateAttribute } from 'utils/mission';
import { Tabs, Tab, TabList, TabPanel } from 'components/molecules';
import {
  StrengthPointField,
  MissionDescriptionField,
  CoverImageField,
  IconImageField,
  CompanyNameField,
  CompanyUrlField,
  MissionCategoriesField,
  CorporateAttributesField,
  CompanyEstablishedDateField,
  AddressField,
  FundField,
  MemberRangeField,
  MissionMembersField,
  MissionProductsField,
  MissionOfficeImagesField,
  MissionJobOffersField,
} from './Fields';
import { Props } from '.';

const TOP_TAB_INDEX = 0;

const Wrapper = styled.div`
  display: none;

  ${media.tablet} {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 9997;
    width: 100%;
    height: 100%;
    background-color: #fff;
  }
`;

const Contents = styled.div`
  padding: 12px 34px 52px 30px;
`;

const Divider = styled.div`
  border-bottom: 1px solid ${theme.borderDefault};
`;

const CompanyTopContents = styled.div`
  display: grid;
  position: relative;
  grid-template:
    'icon-image         .................. company-name      ' auto
    '.................. .................. ..................' 8px
    'mission-categories mission-categories mission-categories' auto
    '.................. .................. ..................' 28px
    / 68px 28px 1fr;
`;

const Content = styled.div`
  padding-top: 30px;
  padding-bottom: 24px;
`;

const TopContent = styled(Content)`
  display: grid;
  grid-template:
    'strength-point     ' auto
    '...................' 30px
    'mission-description' auto
    / auto;
`;

const CompanyContent = styled(Content)<{
  isCompany: boolean;
}>`
  display: grid;
  grid-template:
    'corporate-attributes' auto
    '....................' 24px
    ${({ isCompany }) =>
      isCompany &&
      css`
        'company-established-date' auto
        '........................' 24px
        `}
    'address             ' auto
    '....................' 24px
    'member-range        ' auto
    '....................' 24px
    'fund                ' auto
    '....................' 24px
    'company-url         ' auto
    / auto;
`;

const MissionCardMobile: VFC<Props> = ({ show, mission }) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleTabSelect = useCallback((tabIndex: number) => {
    setSelectedTabIndex(tabIndex);
  }, []);

  // Init display when the selected mission is changed.
  useEffect(() => {
    if (wrapperRef.current) {
      wrapperRef.current.scrollTop = 0;
      setSelectedTabIndex(TOP_TAB_INDEX);
    }
  }, [mission]);

  if (!show || !mission) return null;

  return (
    <Wrapper data-testid="mission-card-mobile" ref={wrapperRef}>
      <div>
        <CoverImageField
          coverImage={mission?.coverImage}
          name={mission?.name}
          data-testid="cover-image-field-mobile"
        />
        <Contents>
          <CompanyTopContents>
            <IconImageField
              iconImage={mission?.iconImage}
              size={68}
              style={{ gridArea: 'icon-image' }}
              data-testid="icon-image-field-mobile"
            />
            <CompanyNameField
              companyName={mission?.companyName}
              style={{ gridArea: 'company-name' }}
              data-testid="company-name-field-mobile"
            />
            <MissionCategoriesField
              missionCategories={mission?.missionCategories}
              style={{ gridArea: 'mission-categories' }}
              data-testid="mission-categories-field-mobile"
            />
          </CompanyTopContents>

          <Divider />

          <Tabs selectedIndex={selectedTabIndex} onSelect={handleTabSelect}>
            <TabList
              style={{
                marginBottom: '16px',
              }}
            >
              <Tab>{MESSAGES.MISSION_CARD.TAB.TOP}</Tab>
              <Tab
                disabled={mission?.missionProducts.length === 0}
                data-testid="tab-mission-products-mobile"
              >
                {MESSAGES.MISSION_CARD.TAB.MISSION_PRODUCTS}
              </Tab>
              <Tab
                disabled={mission?.missionMembers.length === 0}
                data-testid="tab-mission-members-mobile"
              >
                {MESSAGES.MISSION_CARD.TAB.MISSION_MEMBERS}
              </Tab>
              <Tab
                disabled={mission?.missionJobOffers.length === 0}
                data-testid="tab-mission-job-offers-mobile"
              >
                {MESSAGES.MISSION_CARD.TAB.MISSION_JOB_OFFERS}
              </Tab>
            </TabList>

            <TabPanel>
              <TopContent>
                <StrengthPointField
                  strengthPoint={mission?.strengthPoint}
                  style={{ gridArea: 'strength-point' }}
                  data-testid="strength-point-field-mobile"
                />
                <MissionDescriptionField
                  missionDescription={mission?.missionDescription}
                  style={{ gridArea: 'mission-description' }}
                  data-testid="mission-description-field-mobile"
                />
              </TopContent>

              <Divider />

              <CompanyContent
                isCompany={isCompanyCorporateAttribute(
                  mission?.corporateAttributes,
                )}
              >
                <CorporateAttributesField
                  corporateAttributes={mission?.corporateAttributes}
                  style={{ gridArea: 'corporate-attributes' }}
                  data-testid="corporate-attributes-field-mobile"
                />
                {isCompanyCorporateAttribute(mission?.corporateAttributes) && (
                  <CompanyEstablishedDateField
                    companyEstablishedDate={mission?.companyEstablishedDate}
                    style={{ gridArea: 'company-established-date' }}
                    data-testid="company-established-date-field-mobile"
                  />
                )}
                <AddressField
                  japanPrefecture={mission?.japanPrefecture}
                  otherAddress={mission?.otherAddress}
                  style={{ gridArea: 'address' }}
                  data-testid="address-field-mobile"
                />
                <MemberRangeField
                  memberRange={mission?.memberRange}
                  style={{ gridArea: 'member-range' }}
                  data-testid="member-range-field-mobile"
                />
                <FundField
                  fund={mission?.fund}
                  style={{ gridArea: 'fund' }}
                  data-testid="fund-field-mobile"
                />
                <CompanyUrlField
                  companyUrl={mission?.companyUrl}
                  style={{ gridArea: 'company-url' }}
                  data-testid="company-url-field-mobile"
                />
              </CompanyContent>

              {mission?.missionProducts && mission.missionProducts.length > 0 && (
                <>
                  <Divider />
                  <Content>
                    <MissionProductsField
                      missionProducts={mission?.missionProducts}
                    />
                  </Content>
                </>
              )}

              {mission?.missionOfficeImages &&
                mission.missionOfficeImages.length > 0 && (
                  <>
                    <Divider />
                    <Content>
                      <MissionOfficeImagesField
                        missionOfficeImages={mission?.missionOfficeImages}
                        data-testid="mission-office-images-field-mobile"
                      />
                    </Content>
                  </>
                )}
            </TabPanel>

            <TabPanel>
              <Content>
                <MissionProductsField
                  missionProducts={mission?.missionProducts}
                  data-testid="mission-products-field-mobile"
                />
              </Content>
            </TabPanel>

            <TabPanel>
              <Content>
                <MissionMembersField
                  missionMembers={mission?.missionMembers}
                  data-testid="mission-members-field-mobile"
                />
              </Content>
            </TabPanel>

            <TabPanel>
              <Content>
                <MissionJobOffersField
                  missionJobOffers={mission?.missionJobOffers}
                  data-testid="mission-job-offers-field-mobile"
                />
              </Content>
            </TabPanel>
          </Tabs>
        </Contents>
      </div>
    </Wrapper>
  );
};

export default MissionCardMobile;
