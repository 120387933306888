import { CancelToken } from 'axios';
import {
  RegisterInnovatorAccountRequest,
  RegisterInnovatorAccountResponse,
  GetInnovatorAccountResponse,
} from 'proto/v1/accountservice/accountservice';
import {
  InnovatorLoginRequest,
  InnovatorLoginResponse,
} from 'proto/v1/loginservice/loginservice';
import {
  OutOfBandRequest,
  OutOfBandResponse,
  ResetPasswordRequest,
  ResetPasswordResponse,
  SendPasswordResetEmailRequest,
  SendPasswordResetEmailResponse,
  VerifyEmailForInnovatorRequest,
  VerifyEmailForInnovatorResponse,
  PutUnsubscribeInnovatorEmailRequest,
  PutUnsubscribeInnovatorEmailResponse,
} from 'proto/v1/outofbandservice/outofbandservice';
import client from 'external/api/config/client';
import apiFirebase from 'external/firebase/firebase';

export default {
  async registerAccount(
    req: RegisterInnovatorAccountRequest,
  ): Promise<{ data: RegisterInnovatorAccountResponse }> {
    const url = `${process.env.REACT_APP_API_URL}/v1/innovator/account`;
    return client()
      .post(url, RegisterInnovatorAccountRequest.toJSON(req))
      .then(res => {
        return {
          data: RegisterInnovatorAccountResponse.fromJSON(res.data),
        };
      });
  },

  async login(
    req: InnovatorLoginRequest,
  ): Promise<{ data: InnovatorLoginResponse }> {
    const url = `${process.env.REACT_APP_API_URL}/v1/login/innovator`;
    return client()
      .post(url, InnovatorLoginRequest.toJSON(req))
      .then(res => {
        return {
          data: InnovatorLoginResponse.fromJSON(res.data),
        };
      });
  },

  async getInnovatorAccount(): Promise<{ data: GetInnovatorAccountResponse }> {
    const token = await apiFirebase.currentToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/innovator/account`;
    return client(token)
      .get(url)
      .then(x => {
        return {
          data: GetInnovatorAccountResponse.fromJSON(x.data),
        };
      });
  },

  sendPasswordResetEmail(
    req: SendPasswordResetEmailRequest,
  ): Promise<{ data: SendPasswordResetEmailResponse }> {
    const url = `${process.env.REACT_APP_API_URL}/v1/resetpassword/send`;
    return client()
      .post(url, SendPasswordResetEmailRequest.toJSON(req))
      .then(x => {
        return {
          data: SendPasswordResetEmailResponse.fromJSON(x.data),
        };
      });
  },

  resetPassword(
    req: ResetPasswordRequest,
  ): Promise<{ data: ResetPasswordResponse }> {
    const url = `${process.env.REACT_APP_API_URL}/v1/resetpassword`;
    return client()
      .post(url, ResetPasswordRequest.toJSON(req))
      .then(x => {
        return {
          data: ResetPasswordResponse.fromJSON(x.data),
        };
      });
  },

  checkOobCode(
    req: OutOfBandRequest,
    cancelToken?: CancelToken,
  ): Promise<{ data: OutOfBandResponse }> {
    const url = `${process.env.REACT_APP_API_URL}/v1/oob`;
    return client()
      .get(url, {
        params: req,
        cancelToken,
      })
      .then(x => {
        return {
          data: OutOfBandResponse.fromJSON(x.data),
        };
      });
  },

  verifyEmail(
    req: VerifyEmailForInnovatorRequest,
    cancelToken?: CancelToken,
  ): Promise<{ data: VerifyEmailForInnovatorResponse }> {
    const url = `${process.env.REACT_APP_API_URL}/v1/innovator/verification_email`;
    return client()
      .post(url, VerifyEmailForInnovatorRequest.toJSON(req), { cancelToken })
      .then(x => {
        return {
          data: VerifyEmailForInnovatorResponse.fromJSON(x.data),
        };
      });
  },

  unsubscribeEmail(
    req: PutUnsubscribeInnovatorEmailRequest,
  ): Promise<{ data: PutUnsubscribeInnovatorEmailResponse }> {
    const url = `${process.env.REACT_APP_API_URL}/v1/innovator/unsubscribe_email`;
    return client()
      .put(url, PutUnsubscribeInnovatorEmailRequest.toJSON(req))
      .then(x => {
        return {
          data: PutUnsubscribeInnovatorEmailResponse.fromJSON(x.data),
        };
      });
  },
};
