import React, { FC, HTMLAttributes } from 'react';
import styled from 'styled-components/macro';
import theme from 'styles/theme';
import media from 'styles/media';

const Header = styled.header`
  display: flex;
  padding: 26px 32px;
  color: ${theme.baseWhite};

  ${media.mobile} {
    padding: 24px 20px;
  }
`;

const BaseHeader: FC<HTMLAttributes<HTMLElement>> = ({ children, ...rest }) => (
  <Header {...rest}>{children}</Header>
);

export default BaseHeader;
