import React, { FC } from 'react';
import * as MESSAGES from 'constants/messages';
import { PROFILE_FIELDS_MAX_LENGTH } from 'constants/profile';
import { ProfileFormRegister, ProfileFormValues } from 'types/profile';
import { TextInput } from 'components/atoms';
import { Field } from 'components/molecules';

type CareerJobNameFormFieldProps = {
  error: string | undefined;
  register: ProfileFormRegister<{
    career: { job: { name: ProfileFormValues['career']['job'][0]['name'] }[] };
  }>;
  jobIndex: number;
};

const CareerJobNameFormField: FC<CareerJobNameFormFieldProps> = props => {
  const { error, register, jobIndex } = props;
  return (
    <Field
      label={MESSAGES.PROFILE.FIELD.CAREER_JOB.NAME.LABEL}
      note={MESSAGES.PROFILE.FIELD.CAREER_JOB.NAME.NOTE}
      required
    >
      <TextInput
        enterKeyHint="enter"
        name={`career.job[${jobIndex}].name`}
        error={error}
        maxLength={PROFILE_FIELDS_MAX_LENGTH.CAREER_JOB_NAME}
        placeholder={MESSAGES.PROFILE.FIELD.CAREER_JOB.NAME.PLACEHOLDER}
        aria-label={MESSAGES.PROFILE.FIELD.CAREER_JOB.NAME.LABEL}
        ref={register}
      />
    </Field>
  );
};

export default CareerJobNameFormField;
