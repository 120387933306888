export default {
  TITLE: 'Create Account',
  SUB_TITLE: '新規アカウントを作成',
  FORM: {
    FIELD: {
      EMAIL: 'メールアドレス',
      PASSWORD: 'パスワード',
      TERMS_OF_SERVICE: {
        LABEL: '利用規約',
        LINK: {
          TERMS_OF_SERVICE: '利用規約',
          PRIVACY_POLICY: '個人情報保護方針',
        },
        AGREE: 'に同意します',
      },
    },
    TO_LOGIN: {
      TEXT: 'すでにアカウントをお持ちの方は',
      LINK: 'こちら',
    },
    BUTTON: {
      SUBMIT: '新規登録',
    },
    COMPLETED: {
      MAIN: 'メールアドレス認証のためのメールを送信しました',
      DESCRIPTION:
        '届いたメールに記載のリンクから、メールアドレスの認証を行ってください。',
    },
  },
  COMPLETED: {
    MAIN: 'アカウント認証が完了しました',
    DESCRIPTION: 'ボタンを押すか5秒後に自動的にログイン画面に切り替わります。',
    BUTTON: {
      TO_LOGIN: 'ログインページへ',
    },
  },
};
