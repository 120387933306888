import { initializeApp } from 'firebase/app';
import {
  Auth,
  browserLocalPersistence,
  getAuth,
  getIdToken,
  setPersistence,
  inMemoryPersistence,
  signInWithCustomToken,
  signOut,
  UserCredential,
} from 'firebase/auth';
import {
  DocumentChange,
  Firestore,
  collection,
  getFirestore,
  onSnapshot,
} from 'firebase/firestore';
import firebaseConfig from 'external/firebase/config';

let auth: Auth;
let firestore: Firestore;

export default {
  init() {
    const firebaseApp = initializeApp(firebaseConfig);
    auth = getAuth(firebaseApp);
    firestore = getFirestore(firebaseApp);
  },
  setPersistence(persist: boolean) {
    const persistence = persist ? browserLocalPersistence : inMemoryPersistence;
    return setPersistence(auth, persistence);
  },
  login(token: string): Promise<UserCredential> {
    return signInWithCustomToken(auth, token);
  },
  currentToken(): Promise<string> {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return getIdToken(auth.currentUser!);
  },
  logout(): Promise<void> {
    return signOut(auth);
  },
  auth() {
    return auth;
  },
  getNotifications(
    accountId: string,
    messageType: string,
    onChanges: (changes: DocumentChange[]) => void,
  ) {
    if (!accountId) return;
    const docRef = collection(
      firestore,
      `account_notifications/${accountId}/${messageType}`,
    );
    return onSnapshot(docRef, snapshot => {
      onChanges(snapshot.docChanges());
    });
  },
};
