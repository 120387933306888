export default {
  TITLE: 'Login',
  SUB_TITLE: 'ログイン',
  FIELD: {
    EMAIL: 'メールアドレス',
    PASSWORD: 'パスワード',
    SAVE_LOGIN_STATUS: 'ログイン状態を保存する',
  },
  LINK: {
    RESET_PASSWORD: 'パスワードをお忘れですか？',
  },
  BUTTON: {
    SUBMIT: 'ログイン',
  },
};
