export default {
  TITLE: {
    DEFAULT: 'INNOVATORS CAREER',
    TERMS: '利用規約',
    NOT_FOUND: '404 Page Not Found',
    EXPIRED_URL: 'The Link Has Expired',
    UNSUBSCRIBE_EMAIL: 'メール配信停止',
    CREATE_ACCOUNT: '新規アカウント作成',
    LOGIN: 'ログイン',
    PASSWORD_RESET: 'パスワード再設定',
    RESISTER_PROFILE: {
      FORM_FIRST: 'プロフィール入力1',
      FORM_SECOND: 'プロフィール入力2',
      FORM_THIRD: 'プロフィール入力3',
      CONFIRM: '入力内容確認',
      COMPLETED: '登録完了',
    },
    PROFILE: 'プロフィール',
    PUBLISH_SETTING: '公開先設定',
    ACCOUNT_SETTING: 'アカウント設定',
    MESSAGES: 'メッセージ',
  },
};
