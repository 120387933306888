import { lazy } from 'react';
import RegisterProfilePage from 'components/pages/Private/RegisterProfile';
import ProfilePage from 'components/pages/Private/Profile';

export const RegisterProfile =
  process.env.CI === 'true'
    ? RegisterProfilePage
    : lazy(
        () =>
          import(
            /* webpackChunkName: 'RegisterProfile' */ 'components/pages/Private/RegisterProfile'
          ),
      );

export const Profile =
  process.env.CI === 'true'
    ? ProfilePage
    : lazy(
        () =>
          import(
            /* webpackChunkName: 'Profile' */ 'components/pages/Private/Profile'
          ),
      );

export const PublishSetting = lazy(
  () =>
    import(
      /* webpackChunkName: 'PublishSetting' */ 'components/pages/Private/PublishSetting'
    ),
);

export const AccountSetting = lazy(
  () =>
    import(
      /* webpackChunkName: 'AccountSetting' */ 'components/pages/Private/AccountSetting'
    ),
);

export const Messages = lazy(
  () =>
    import(
      /* webpackChunkName: 'messages' */ 'components/pages/Private/Messages'
    ),
);
