import { useMemo } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { ProfileFormMethods } from 'types/profile';

type Payload = {
  formMethods: UseFormMethods<any>;
};

const usePreferredOccupationsFormField = ({ formMethods }: Payload) => {
  const { errors } = (formMethods as unknown) as ProfileFormMethods<
    'preferredOccupations'
  >;

  const preferredOccupationsError = useMemo(() => {
    const preferredOccupationsErrors = errors.preferredOccupations;
    if (!preferredOccupationsErrors) return undefined;

    const messages = preferredOccupationsErrors
      .filter(error => !!error?.id?.message)
      .map(error => error?.id?.message);

    return [...new Set(messages)][0];
  }, [errors.preferredOccupations]);

  return {
    preferredOccupationsError,
  };
};

export default usePreferredOccupationsFormField;
