import { Innovator, CareerProfile } from 'proto/v1/apimodel/apimodel';
import {
  UpdateInnovatorRequest,
  CareerProfileToUpdate,
} from 'proto/v1/innovatorservice/innovatorservice';
import {
  ACADEMIC_BACKGROUND_YEAR_OF_GRADUATION_DEFAULT,
  ACADEMIC_BACKGROUND_MONTH_OF_GRADUATION_DEFAULT,
  PHONE_COUNTRY_CODE_DEFAULT,
} from 'constants/profile';
import { NO_SELECT_NUMBER } from 'constants/form';

const convertToCareerJobToUpdate = (
  job: CareerProfile['job'],
): CareerProfileToUpdate['job'] => {
  return job.map(jobHistory => ({
    name: jobHistory.name,
    positionId: jobHistory.position?.id ?? NO_SELECT_NUMBER,
    startYear: jobHistory.startYear,
    startMonth: jobHistory.startMonth,
    endYear: jobHistory.endYear,
    endMonth: jobHistory.endMonth,
    occupations: jobHistory.occupations.map(occupation => ({
      occupationId: occupation.occupation?.id ?? NO_SELECT_NUMBER,
      yearsOfExperienceId: occupation.yearsOfExperience?.id ?? NO_SELECT_NUMBER,
    })),
  }));
};

const convertToCareerToUpdate = (
  career: Innovator['career'],
): UpdateInnovatorRequest['career'] => {
  if (!career) return undefined;
  return {
    job: convertToCareerJobToUpdate(career.job),
    achievement: career.achievement,
    annualIncomeId: career.annualIncome?.id ?? NO_SELECT_NUMBER,
    monthlyUnitPrice: career.monthlyUnitPrice,
    numberOfJobChangesId: career.numberOfJobChanges?.id ?? NO_SELECT_NUMBER,
    preferredMonthlyUnitPriceId:
      career.preferredMonthlyUnitPrice?.id ?? NO_SELECT_NUMBER,
    fileKey: career.resumeFile?.key ?? '',
    expectationIds: career.expectations.map(e => e.id),
    skillIds: career.skills.map(skill => skill.id),
    preferredEmploymentTypeId:
      career.preferredEmploymentType?.id ?? NO_SELECT_NUMBER,
  };
};

const convertToNewGraduateToUpdate = (
  newGraduate: Innovator['newGraduate'],
): UpdateInnovatorRequest['newGraduate'] => {
  if (!newGraduate) return undefined;
  return {
    researchRooms: newGraduate.researchRooms,
    programmingExperiences: newGraduate.programmingExperiences.map(
      programmingExperience => ({
        languageName: programmingExperience.languageName,
        levelId: programmingExperience.level?.id ?? NO_SELECT_NUMBER,
      }),
    ),
    preferredWorkStyleId: newGraduate.preferredWorkStyle?.id,
    preferredBusinessTypeIds: newGraduate.preferredBusinessTypes.map(
      preferredBusinessType => preferredBusinessType.id,
    ),
    pastExperienceAchievementIds: newGraduate.pastExperienceAchievements.map(
      pastExperienceAchievement => pastExperienceAchievement.id,
    ),
    internshipExperiences: newGraduate.internshipExperiences,
    studyAbroadExperiences: newGraduate.studyAbroadExperiences,
    clubExperiences: newGraduate.clubExperiences,
  };
};

const convertToAcademicBackgroundToUpdate = (
  academicBackground: Innovator['academicBackground'],
): UpdateInnovatorRequest['academicBackground'] => {
  if (!academicBackground) return undefined;
  return {
    schoolId: academicBackground.school?.id ?? NO_SELECT_NUMBER,
    faculty: academicBackground.faculty,
    departmentId: academicBackground.department?.id ?? NO_SELECT_NUMBER,
    yearOfGraduation:
      academicBackground?.yearOfGraduation ??
      ACADEMIC_BACKGROUND_YEAR_OF_GRADUATION_DEFAULT,
    monthOfGraduation:
      academicBackground?.monthOfGraduation ??
      ACADEMIC_BACKGROUND_MONTH_OF_GRADUATION_DEFAULT,
  };
};

const convertToInnovatorToUpdate = (
  innovator: Innovator,
): UpdateInnovatorRequest => {
  return {
    innovatorId: innovator.innovatorId,
    lastName: innovator.lastName,
    firstName: innovator.firstName,
    lastNameKana: innovator.lastNameKana,
    firstNameKana: innovator.firstNameKana,
    genderId: 1, // TODO: Implement later
    yearOfBirth: innovator.yearOfBirth,
    monthOfBirth: innovator.monthOfBirth,
    dateOfBirth: 0, // TODO: Implement later
    phoneNumber: innovator.phoneNumber,
    phoneCountryCode: innovator.phoneCountryCode || PHONE_COUNTRY_CODE_DEFAULT,
    japanPrefectureId: innovator.japanPrefecture?.japanPrefectureId,
    academicBackground: convertToAcademicBackgroundToUpdate(
      innovator.academicBackground,
    ),
    career: convertToCareerToUpdate(innovator.career),
    newGraduate: convertToNewGraduateToUpdate(innovator.newGraduate),
    preferredWorkLocations: innovator.preferredWorkLocations,
    preferredOccupationIds: innovator.preferredOccupations.map(
      occupation => occupation.id,
    ),
    englishSkillId: innovator.englishSkill?.id ?? NO_SELECT_NUMBER,
    certifications: innovator.certifications,
    selfIntroduction: innovator.selfIntroduction,
    outputs: innovator.outputs,
    dttScore: innovator.dttScore,
    dttRankId: innovator.dttRank?.id,
  };
};

export default convertToInnovatorToUpdate;
