import axios from 'axios';
import { format, isSameDay, isSameYear } from 'date-fns';

export const formatChatMessageTimestamp = (timestamp: number) => {
  const now = new Date();
  const sentAt = new Date(timestamp);
  if (isSameDay(sentAt, now)) return format(sentAt, 'HH:mm');
  if (isSameYear(sentAt, now)) return format(sentAt, 'MM/dd');
  return format(new Date(timestamp), 'yyyy/MM/dd');
};

export const formatChatRoomTimestamp = (timestamp: number) => {
  const date = new Date(timestamp);
  const now = new Date();
  if (
    now.getFullYear() === date.getFullYear() &&
    now.getMonth() === date.getMonth() &&
    now.getDate() === date.getDate()
  ) {
    return format(new Date(timestamp), 'HH:mm');
  }
  if (now.getFullYear() === date.getFullYear()) {
    return format(new Date(timestamp), 'MM/dd');
  }
  return format(new Date(timestamp), 'yyyy/MM/dd');
};

export const formatFileSize = (size: number) => {
  // use Megabytes unit size（1MB = 1000000Byte, not 1048576Byte)
  if (size / 1000 < 1) return '1KB';
  if (size / 1000 < 1000) return `${Math.round(size / 1000)}KB`;

  return `${Math.round(size / 1000000)}MB`;
};

export const downloadChatFile = async (
  url: string,
): Promise<{ data: Blob }> => {
  const res = await axios.get(url, { responseType: 'blob' });
  return { data: res.data };
};
