import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';
import theme from 'styles/theme';
import media from 'styles/media';
import * as EXTERNAL_LINK from 'constants/externalLink';
import * as ROUTES from 'routes/constants/routes';
import * as MESSAGES from 'constants/messages';
import { CopyRight as BaseCopyRight } from 'components/atoms';

type FooterMode = 'default' | 'public';

const isPublic = (mode: FooterMode) => mode === 'public';

const StyledFooter = styled.footer<{ mode: FooterMode }>`
  display: grid;
  grid-template:
    'footer-items copy-right'
    / 1fr auto;
  padding: 24px 32px;
  color: ${theme.textSecondary};
  ${({ mode }) =>
    !isPublic(mode) &&
    css`
      background-color: ${theme.bgDefault};
    `};

  ${media.mobile} {
    grid-row-gap: 24px;
    grid-template:
      'footer-items' auto
      'copy-right' auto
      / 1fr;
    padding: 12px 0;
  }
`;

const FooterItems = styled.ul`
  display: flex;
  grid-area: 'footer-items';

  ${media.mobile} {
    justify-content: center;
  }
`;

const FooterItem = styled.li`
  &:not(:last-child) {
    margin-right: 32px;
  }
`;

const FooterItemLink = styled(Link)<{ mode: FooterMode }>`
  color: ${({ mode }) =>
    isPublic(mode) ? theme.textCardSecondary : theme.textSecondary};
  font-size: 12px;
  text-decoration: none;

  &:hover {
    color: ${({ mode }) =>
      isPublic(mode) ? theme.baseWhite : theme.textSecondaryHover};
  }
`;

const CopyRight = styled(BaseCopyRight)`
  grid-area: 'copy-right';

  ${media.mobile} {
    justify-content: center;
  }
`;

type FooterProps = {
  mode?: 'default' | 'public';
};

const Footer: FC<FooterProps> = ({ mode = 'default' }) => {
  return (
    <StyledFooter mode={mode}>
      <FooterItems>
        <FooterItem>
          <FooterItemLink
            mode={mode}
            to={EXTERNAL_LINK.COMPANY}
            target="_blank"
          >
            {MESSAGES.FOOTER.LINK.COMPANY}
          </FooterItemLink>
        </FooterItem>
        <FooterItem>
          <FooterItemLink mode={mode} to={ROUTES.TERMS} target="_blank">
            {MESSAGES.FOOTER.LINK.TERMS_OF_SERVICE}
          </FooterItemLink>
        </FooterItem>
        <FooterItem>
          <FooterItemLink
            mode={mode}
            to={EXTERNAL_LINK.PRIVACY_POLICY}
            target="_blank"
          >
            {MESSAGES.FOOTER.LINK.PRIVACY_POLICY}
          </FooterItemLink>
        </FooterItem>
      </FooterItems>
      <CopyRight />
    </StyledFooter>
  );
};

export default Footer;
