import { Reader, Writer } from 'protobufjs/minimal';
import * as Long from 'long';


export interface OutOfBandRequest {
  oobCode: string;
  mode: string;
}

export interface OutOfBandResponse {
}

export interface SendPasswordResetEmailRequest {
  email: string;
  role: string;
}

export interface SendPasswordResetEmailResponse {
}

export interface ResetPasswordRequest {
  newPassword: string;
  oobCode: string;
}

export interface ResetPasswordResponse {
}

export interface RegisterPasswordRequest {
  oobCode: string;
  password: string;
}

export interface RegisterPasswordResponse {
}

export interface VerifyEmailRequest {
  oobCode: string;
}

export interface VerifyEmailResponse {
}

export interface VerifyEmailForInnovatorRequest {
  oobCode: string;
}

export interface VerifyEmailForInnovatorResponse {
}

export interface PutUnsubscribeInnovatorEmailRequest {
  email: string;
}

export interface PutUnsubscribeInnovatorEmailResponse {
}

const baseOutOfBandRequest: object = {
  oobCode: "",
  mode: "",
};

const baseOutOfBandResponse: object = {
};

const baseSendPasswordResetEmailRequest: object = {
  email: "",
  role: "",
};

const baseSendPasswordResetEmailResponse: object = {
};

const baseResetPasswordRequest: object = {
  newPassword: "",
  oobCode: "",
};

const baseResetPasswordResponse: object = {
};

const baseRegisterPasswordRequest: object = {
  oobCode: "",
  password: "",
};

const baseRegisterPasswordResponse: object = {
};

const baseVerifyEmailRequest: object = {
  oobCode: "",
};

const baseVerifyEmailResponse: object = {
};

const baseVerifyEmailForInnovatorRequest: object = {
  oobCode: "",
};

const baseVerifyEmailForInnovatorResponse: object = {
};

const basePutUnsubscribeInnovatorEmailRequest: object = {
  email: "",
};

const basePutUnsubscribeInnovatorEmailResponse: object = {
};

export interface OutOfBandService {

  OutOfBand(request: OutOfBandRequest): Promise<OutOfBandResponse>;

  SendPasswordResetEmail(request: SendPasswordResetEmailRequest): Promise<SendPasswordResetEmailResponse>;

  ResetPassword(request: ResetPasswordRequest): Promise<ResetPasswordResponse>;

  RegisterPassword(request: RegisterPasswordRequest): Promise<RegisterPasswordResponse>;

  VerifyEmail(request: VerifyEmailRequest): Promise<VerifyEmailResponse>;

  VerifyEmailForInnovator(request: VerifyEmailForInnovatorRequest): Promise<VerifyEmailForInnovatorResponse>;

  PutUnsubscribeInnovatorEmail(request: PutUnsubscribeInnovatorEmailRequest): Promise<PutUnsubscribeInnovatorEmailResponse>;

}

export class OutOfBandServiceClientImpl implements OutOfBandService {

  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
  }

  OutOfBand(request: OutOfBandRequest): Promise<OutOfBandResponse> {
    const data = OutOfBandRequest.encode(request).finish();
    const promise = this.rpc.request("outofbandservice.OutOfBandService", "OutOfBand", data);
    return promise.then(data => OutOfBandResponse.decode(new Reader(data)));
  }

  SendPasswordResetEmail(request: SendPasswordResetEmailRequest): Promise<SendPasswordResetEmailResponse> {
    const data = SendPasswordResetEmailRequest.encode(request).finish();
    const promise = this.rpc.request("outofbandservice.OutOfBandService", "SendPasswordResetEmail", data);
    return promise.then(data => SendPasswordResetEmailResponse.decode(new Reader(data)));
  }

  ResetPassword(request: ResetPasswordRequest): Promise<ResetPasswordResponse> {
    const data = ResetPasswordRequest.encode(request).finish();
    const promise = this.rpc.request("outofbandservice.OutOfBandService", "ResetPassword", data);
    return promise.then(data => ResetPasswordResponse.decode(new Reader(data)));
  }

  RegisterPassword(request: RegisterPasswordRequest): Promise<RegisterPasswordResponse> {
    const data = RegisterPasswordRequest.encode(request).finish();
    const promise = this.rpc.request("outofbandservice.OutOfBandService", "RegisterPassword", data);
    return promise.then(data => RegisterPasswordResponse.decode(new Reader(data)));
  }

  VerifyEmail(request: VerifyEmailRequest): Promise<VerifyEmailResponse> {
    const data = VerifyEmailRequest.encode(request).finish();
    const promise = this.rpc.request("outofbandservice.OutOfBandService", "VerifyEmail", data);
    return promise.then(data => VerifyEmailResponse.decode(new Reader(data)));
  }

  VerifyEmailForInnovator(request: VerifyEmailForInnovatorRequest): Promise<VerifyEmailForInnovatorResponse> {
    const data = VerifyEmailForInnovatorRequest.encode(request).finish();
    const promise = this.rpc.request("outofbandservice.OutOfBandService", "VerifyEmailForInnovator", data);
    return promise.then(data => VerifyEmailForInnovatorResponse.decode(new Reader(data)));
  }

  PutUnsubscribeInnovatorEmail(request: PutUnsubscribeInnovatorEmailRequest): Promise<PutUnsubscribeInnovatorEmailResponse> {
    const data = PutUnsubscribeInnovatorEmailRequest.encode(request).finish();
    const promise = this.rpc.request("outofbandservice.OutOfBandService", "PutUnsubscribeInnovatorEmail", data);
    return promise.then(data => PutUnsubscribeInnovatorEmailResponse.decode(new Reader(data)));
  }

}

interface Rpc {

  request(service: string, method: string, data: Uint8Array): Promise<Uint8Array>;

}

function longToNumber(long: Long) {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new global.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

export const OutOfBandRequest = {
  encode(message: OutOfBandRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.oobCode);
    writer.uint32(18).string(message.mode);
    return writer;
  },
  decode(reader: Reader, length?: number): OutOfBandRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseOutOfBandRequest) as OutOfBandRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.oobCode = reader.string();
          break;
        case 2:
          message.mode = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): OutOfBandRequest {
    const message = Object.create(baseOutOfBandRequest) as OutOfBandRequest;
    if (object.oobCode) {
      message.oobCode = String(object.oobCode);
    }
    if (object.mode) {
      message.mode = String(object.mode);
    }
    return message;
  },
  fromPartial(object: DeepPartial<OutOfBandRequest>): OutOfBandRequest {
    const message = Object.create(baseOutOfBandRequest) as OutOfBandRequest;
    if (object.oobCode) {
      message.oobCode = object.oobCode;
    }
    if (object.mode) {
      message.mode = object.mode;
    }
    return message;
  },
  toJSON(message: OutOfBandRequest): unknown {
    const obj: any = {};
    obj.oobCode = message.oobCode || "";
    obj.mode = message.mode || "";
    return obj;
  },
};

export const OutOfBandResponse = {
  encode(message: OutOfBandResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): OutOfBandResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseOutOfBandResponse) as OutOfBandResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): OutOfBandResponse {
    const message = Object.create(baseOutOfBandResponse) as OutOfBandResponse;
    return message;
  },
  fromPartial(object: DeepPartial<OutOfBandResponse>): OutOfBandResponse {
    const message = Object.create(baseOutOfBandResponse) as OutOfBandResponse;
    return message;
  },
  toJSON(message: OutOfBandResponse): unknown {
    const obj: any = {};
    return obj;
  },
};

export const SendPasswordResetEmailRequest = {
  encode(message: SendPasswordResetEmailRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.email);
    writer.uint32(18).string(message.role);
    return writer;
  },
  decode(reader: Reader, length?: number): SendPasswordResetEmailRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseSendPasswordResetEmailRequest) as SendPasswordResetEmailRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.email = reader.string();
          break;
        case 2:
          message.role = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): SendPasswordResetEmailRequest {
    const message = Object.create(baseSendPasswordResetEmailRequest) as SendPasswordResetEmailRequest;
    if (object.email) {
      message.email = String(object.email);
    }
    if (object.role) {
      message.role = String(object.role);
    }
    return message;
  },
  fromPartial(object: DeepPartial<SendPasswordResetEmailRequest>): SendPasswordResetEmailRequest {
    const message = Object.create(baseSendPasswordResetEmailRequest) as SendPasswordResetEmailRequest;
    if (object.email) {
      message.email = object.email;
    }
    if (object.role) {
      message.role = object.role;
    }
    return message;
  },
  toJSON(message: SendPasswordResetEmailRequest): unknown {
    const obj: any = {};
    obj.email = message.email || "";
    obj.role = message.role || "";
    return obj;
  },
};

export const SendPasswordResetEmailResponse = {
  encode(message: SendPasswordResetEmailResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): SendPasswordResetEmailResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseSendPasswordResetEmailResponse) as SendPasswordResetEmailResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): SendPasswordResetEmailResponse {
    const message = Object.create(baseSendPasswordResetEmailResponse) as SendPasswordResetEmailResponse;
    return message;
  },
  fromPartial(object: DeepPartial<SendPasswordResetEmailResponse>): SendPasswordResetEmailResponse {
    const message = Object.create(baseSendPasswordResetEmailResponse) as SendPasswordResetEmailResponse;
    return message;
  },
  toJSON(message: SendPasswordResetEmailResponse): unknown {
    const obj: any = {};
    return obj;
  },
};

export const ResetPasswordRequest = {
  encode(message: ResetPasswordRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.newPassword);
    writer.uint32(18).string(message.oobCode);
    return writer;
  },
  decode(reader: Reader, length?: number): ResetPasswordRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseResetPasswordRequest) as ResetPasswordRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.newPassword = reader.string();
          break;
        case 2:
          message.oobCode = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): ResetPasswordRequest {
    const message = Object.create(baseResetPasswordRequest) as ResetPasswordRequest;
    if (object.newPassword) {
      message.newPassword = String(object.newPassword);
    }
    if (object.oobCode) {
      message.oobCode = String(object.oobCode);
    }
    return message;
  },
  fromPartial(object: DeepPartial<ResetPasswordRequest>): ResetPasswordRequest {
    const message = Object.create(baseResetPasswordRequest) as ResetPasswordRequest;
    if (object.newPassword) {
      message.newPassword = object.newPassword;
    }
    if (object.oobCode) {
      message.oobCode = object.oobCode;
    }
    return message;
  },
  toJSON(message: ResetPasswordRequest): unknown {
    const obj: any = {};
    obj.newPassword = message.newPassword || "";
    obj.oobCode = message.oobCode || "";
    return obj;
  },
};

export const ResetPasswordResponse = {
  encode(message: ResetPasswordResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): ResetPasswordResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseResetPasswordResponse) as ResetPasswordResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): ResetPasswordResponse {
    const message = Object.create(baseResetPasswordResponse) as ResetPasswordResponse;
    return message;
  },
  fromPartial(object: DeepPartial<ResetPasswordResponse>): ResetPasswordResponse {
    const message = Object.create(baseResetPasswordResponse) as ResetPasswordResponse;
    return message;
  },
  toJSON(message: ResetPasswordResponse): unknown {
    const obj: any = {};
    return obj;
  },
};

export const RegisterPasswordRequest = {
  encode(message: RegisterPasswordRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.oobCode);
    writer.uint32(18).string(message.password);
    return writer;
  },
  decode(reader: Reader, length?: number): RegisterPasswordRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseRegisterPasswordRequest) as RegisterPasswordRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.oobCode = reader.string();
          break;
        case 2:
          message.password = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): RegisterPasswordRequest {
    const message = Object.create(baseRegisterPasswordRequest) as RegisterPasswordRequest;
    if (object.oobCode) {
      message.oobCode = String(object.oobCode);
    }
    if (object.password) {
      message.password = String(object.password);
    }
    return message;
  },
  fromPartial(object: DeepPartial<RegisterPasswordRequest>): RegisterPasswordRequest {
    const message = Object.create(baseRegisterPasswordRequest) as RegisterPasswordRequest;
    if (object.oobCode) {
      message.oobCode = object.oobCode;
    }
    if (object.password) {
      message.password = object.password;
    }
    return message;
  },
  toJSON(message: RegisterPasswordRequest): unknown {
    const obj: any = {};
    obj.oobCode = message.oobCode || "";
    obj.password = message.password || "";
    return obj;
  },
};

export const RegisterPasswordResponse = {
  encode(message: RegisterPasswordResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): RegisterPasswordResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseRegisterPasswordResponse) as RegisterPasswordResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): RegisterPasswordResponse {
    const message = Object.create(baseRegisterPasswordResponse) as RegisterPasswordResponse;
    return message;
  },
  fromPartial(object: DeepPartial<RegisterPasswordResponse>): RegisterPasswordResponse {
    const message = Object.create(baseRegisterPasswordResponse) as RegisterPasswordResponse;
    return message;
  },
  toJSON(message: RegisterPasswordResponse): unknown {
    const obj: any = {};
    return obj;
  },
};

export const VerifyEmailRequest = {
  encode(message: VerifyEmailRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.oobCode);
    return writer;
  },
  decode(reader: Reader, length?: number): VerifyEmailRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseVerifyEmailRequest) as VerifyEmailRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.oobCode = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): VerifyEmailRequest {
    const message = Object.create(baseVerifyEmailRequest) as VerifyEmailRequest;
    if (object.oobCode) {
      message.oobCode = String(object.oobCode);
    }
    return message;
  },
  fromPartial(object: DeepPartial<VerifyEmailRequest>): VerifyEmailRequest {
    const message = Object.create(baseVerifyEmailRequest) as VerifyEmailRequest;
    if (object.oobCode) {
      message.oobCode = object.oobCode;
    }
    return message;
  },
  toJSON(message: VerifyEmailRequest): unknown {
    const obj: any = {};
    obj.oobCode = message.oobCode || "";
    return obj;
  },
};

export const VerifyEmailResponse = {
  encode(message: VerifyEmailResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): VerifyEmailResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseVerifyEmailResponse) as VerifyEmailResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): VerifyEmailResponse {
    const message = Object.create(baseVerifyEmailResponse) as VerifyEmailResponse;
    return message;
  },
  fromPartial(object: DeepPartial<VerifyEmailResponse>): VerifyEmailResponse {
    const message = Object.create(baseVerifyEmailResponse) as VerifyEmailResponse;
    return message;
  },
  toJSON(message: VerifyEmailResponse): unknown {
    const obj: any = {};
    return obj;
  },
};

export const VerifyEmailForInnovatorRequest = {
  encode(message: VerifyEmailForInnovatorRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.oobCode);
    return writer;
  },
  decode(reader: Reader, length?: number): VerifyEmailForInnovatorRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseVerifyEmailForInnovatorRequest) as VerifyEmailForInnovatorRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.oobCode = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): VerifyEmailForInnovatorRequest {
    const message = Object.create(baseVerifyEmailForInnovatorRequest) as VerifyEmailForInnovatorRequest;
    if (object.oobCode) {
      message.oobCode = String(object.oobCode);
    }
    return message;
  },
  fromPartial(object: DeepPartial<VerifyEmailForInnovatorRequest>): VerifyEmailForInnovatorRequest {
    const message = Object.create(baseVerifyEmailForInnovatorRequest) as VerifyEmailForInnovatorRequest;
    if (object.oobCode) {
      message.oobCode = object.oobCode;
    }
    return message;
  },
  toJSON(message: VerifyEmailForInnovatorRequest): unknown {
    const obj: any = {};
    obj.oobCode = message.oobCode || "";
    return obj;
  },
};

export const VerifyEmailForInnovatorResponse = {
  encode(message: VerifyEmailForInnovatorResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): VerifyEmailForInnovatorResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(baseVerifyEmailForInnovatorResponse) as VerifyEmailForInnovatorResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): VerifyEmailForInnovatorResponse {
    const message = Object.create(baseVerifyEmailForInnovatorResponse) as VerifyEmailForInnovatorResponse;
    return message;
  },
  fromPartial(object: DeepPartial<VerifyEmailForInnovatorResponse>): VerifyEmailForInnovatorResponse {
    const message = Object.create(baseVerifyEmailForInnovatorResponse) as VerifyEmailForInnovatorResponse;
    return message;
  },
  toJSON(message: VerifyEmailForInnovatorResponse): unknown {
    const obj: any = {};
    return obj;
  },
};

export const PutUnsubscribeInnovatorEmailRequest = {
  encode(message: PutUnsubscribeInnovatorEmailRequest, writer: Writer = Writer.create()): Writer {
    writer.uint32(10).string(message.email);
    return writer;
  },
  decode(reader: Reader, length?: number): PutUnsubscribeInnovatorEmailRequest {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(basePutUnsubscribeInnovatorEmailRequest) as PutUnsubscribeInnovatorEmailRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.email = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): PutUnsubscribeInnovatorEmailRequest {
    const message = Object.create(basePutUnsubscribeInnovatorEmailRequest) as PutUnsubscribeInnovatorEmailRequest;
    if (object.email) {
      message.email = String(object.email);
    }
    return message;
  },
  fromPartial(object: DeepPartial<PutUnsubscribeInnovatorEmailRequest>): PutUnsubscribeInnovatorEmailRequest {
    const message = Object.create(basePutUnsubscribeInnovatorEmailRequest) as PutUnsubscribeInnovatorEmailRequest;
    if (object.email) {
      message.email = object.email;
    }
    return message;
  },
  toJSON(message: PutUnsubscribeInnovatorEmailRequest): unknown {
    const obj: any = {};
    obj.email = message.email || "";
    return obj;
  },
};

export const PutUnsubscribeInnovatorEmailResponse = {
  encode(message: PutUnsubscribeInnovatorEmailResponse, writer: Writer = Writer.create()): Writer {
    return writer;
  },
  decode(reader: Reader, length?: number): PutUnsubscribeInnovatorEmailResponse {
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = Object.create(basePutUnsubscribeInnovatorEmailResponse) as PutUnsubscribeInnovatorEmailResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },
  fromJSON(object: any): PutUnsubscribeInnovatorEmailResponse {
    const message = Object.create(basePutUnsubscribeInnovatorEmailResponse) as PutUnsubscribeInnovatorEmailResponse;
    return message;
  },
  fromPartial(object: DeepPartial<PutUnsubscribeInnovatorEmailResponse>): PutUnsubscribeInnovatorEmailResponse {
    const message = Object.create(basePutUnsubscribeInnovatorEmailResponse) as PutUnsubscribeInnovatorEmailResponse;
    return message;
  },
  toJSON(message: PutUnsubscribeInnovatorEmailResponse): unknown {
    const obj: any = {};
    return obj;
  },
};

type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T[P] extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T[P] extends Date | Function | Uint8Array | undefined
  ? T[P]
  : T[P] extends infer U | undefined
  ? DeepPartial<U>
  : T[P] extends object
  ? DeepPartial<T[P]>
  : T[P]
};