import styled from 'styled-components/macro';
import theme from 'styles/theme';
import media from 'styles/media';

const ProfileContent = styled.p`
  color: ${theme.textBody};
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;

  ${media.mobile} {
    font-size: 14px;
  }
`;

export default ProfileContent;
