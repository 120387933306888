import { string } from 'yup';
import * as MESSAGES from 'constants/messages';

const isUsableCharacters = (value: string): boolean =>
  /^[0-9a-zA-Z"#$%&'()=~|\-^\\@[;:\],./`{+*}<>?_!]+$/.test(value);

const isMultipleTypeCharacters = (value: string): boolean =>
  !/^[0-9]+$|^[a-z]+$|^[A-Z]+$|^["#$%&'()=~|\-^\\@[;:\],./`{+*}<>?_!]+$/.test(
    value,
  );

export const passwordSchema = string()
  .min(8, MESSAGES.ERROR.INVALID_PASSWORD)
  .max(64, MESSAGES.ERROR.INVALID_PASSWORD)
  .test(
    'password',
    MESSAGES.ERROR.INVALID_PASSWORD,
    value =>
      !!value && isUsableCharacters(value) && isMultipleTypeCharacters(value),
  );

export const phoneNumberSchema = string().test(
  'phoneNumber',
  MESSAGES.ERROR.INVALID_PHONE_NUMBER,
  value => typeof value === 'string' && /^([0-9０-９])*$/.test(value),
);
