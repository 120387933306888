import React, { FC, HTMLAttributes } from 'react';
import styled from 'styled-components/macro';
import media from 'styles/media';

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  text-align: center;

  ${media.mobile} {
    flex-direction: column-reverse;
  }

  > button {
    &:not(:first-child) {
      margin-left: 16px;
    }

    ${media.mobile} {
      width: 100%;

      &:not(:first-child) {
        margin-left: 0;
      }

      &:not(:last-child) {
        margin-top: 16px;
      }
    }
  }
`;

const ModalFooter: FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
  ...rest
}) => <Footer {...rest}>{children}</Footer>;

export default ModalFooter;
