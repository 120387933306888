import React, { FC } from 'react';
import * as MESSAGES from 'constants/messages';
import { Label, ProfileField, ProfileContent } from 'components/atoms';
import { Innovator } from 'proto/v1/apimodel/apimodel';
import { interpolateMessage } from 'utils/string';

type AcademicBackground = NonNullable<Innovator['academicBackground']>;

type AcademicBackgroundYearMonthOfGraduationFieldProps = {
  yearOfGraduation: AcademicBackground['yearOfGraduation'] | undefined;
  monthOfGraduation: AcademicBackground['monthOfGraduation'] | undefined;
  label?: string;
};

const AcademicBackgroundYearMonthOfGraduationField: FC<AcademicBackgroundYearMonthOfGraduationFieldProps> = props => {
  const {
    yearOfGraduation,
    monthOfGraduation,
    label = MESSAGES.PROFILE.FIELD.ACADEMIC_BACKGROUND_YEAR_MONTH_OF_GRADUATION
      .LABEL,
  } = props;

  return (
    <ProfileField data-testid="academic-background-year-month-of-graduation-field">
      <Label>{label}</Label>
      <ProfileContent>
        {interpolateMessage(MESSAGES.PROFILE.YEAR_MONTH, {
          year: yearOfGraduation,
          month: monthOfGraduation,
        })}
      </ProfileContent>
    </ProfileField>
  );
};

export default AcademicBackgroundYearMonthOfGraduationField;
