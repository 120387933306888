import React, { useCallback, FC } from 'react';
import styled from 'styled-components/macro';
import {
  ErrorNotificationActions,
  getShow,
  getOptions,
} from 'modules/errorNotification';
import { useSelector, useDispatch } from 'react-redux';
import media from 'styles/media';
import theme from 'styles/theme';
import { CircleCrossRed } from 'assets/svg';

const Notification = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  width: 100%;
  height: fit-content;
  padding: 20px 40px;
  background-color: #da5353;
  color: ${theme.baseWhite};
  font-size: 16px;
  line-height: 1.5;

  ${media.mobile} {
    padding: 8px 16px;
    font-size: 14px;
  }
`;

const Message = styled.p`
  margin-right: 20px;
  word-wrap: break-word;
  white-space: pre-wrap;
`;

const CloseIcon = styled(CircleCrossRed)`
  display: inline-block;
  align-self: center;
  width: 20px;
  min-width: 20px;
  height: 20px;
  min-height: 20px;

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
`;

const ErrorNotification: FC = () => {
  const dispatch = useDispatch();
  const showNotification = useSelector(getShow);
  const options = useSelector(getOptions);

  const { message, isClosable } = options || {};

  const handleClickClose = useCallback(() => {
    dispatch(ErrorNotificationActions.close());
  }, [dispatch]);

  if (!showNotification) return null;

  return (
    <Notification data-testid="error-notification">
      <Message>{message}</Message>
      {isClosable && <CloseIcon onClick={handleClickClose} />}
    </Notification>
  );
};

export default ErrorNotification;
