import { DocumentChange } from 'firebase/firestore';
import { createSelector } from 'reselect';
import { ActionsUnion, createAction } from './helper';

/*
 * Redux Action actionTypes
 */

export const SET_MESSAGE_NOTIFICATION = 'notifications/message/SET_CONTACT';
export const CLEAR_NOTIFICATION = 'notifications/CLEAR';

/*
 * Redux Actions
 */
export const NotificationActions = {
  setMessageNotifications: (firestoreDocumentChange: DocumentChange[]) =>
    createAction(SET_MESSAGE_NOTIFICATION, firestoreDocumentChange),
  clearNotification: () => createAction(CLEAR_NOTIFICATION),
};
export type Actions = ActionsUnion<typeof NotificationActions>;

/*
 * Selectors
 */
export const getNotification = (state: any): NotificationState =>
  state.notification;

export const getMessageNotificationsTotalCount = createSelector(
  [getNotification],
  notificationState =>
    notificationState.messageNotifications
      ? notificationState.messageNotifications.reduce(
          (prev, current) => prev + current.count,
          0,
        )
      : undefined,
);

/*
 * Reducer
 */
export type Notification = {
  id: string;
  count: number;
};

export type NotificationState = {
  messageNotifications: Notification[] | undefined;
};

const initState: NotificationState = {
  messageNotifications: undefined,
};

const updateNotifications = (
  firestoreDocumentChange: DocumentChange[],
  currentNotifications: Notification[],
): Notification[] => {
  return firestoreDocumentChange.reduce((prev, current) => {
    if (current.type === 'added' && current.doc.data().count) {
      prev.push({
        id: current.doc.id,
        count: current.doc.data().count,
      });
    }
    if (current.type === 'modified') {
      const removedTargetIndex = prev.findIndex(
        prevCurrent => prevCurrent.id === current.doc.id,
      );
      removedTargetIndex !== -1 && prev.splice(removedTargetIndex, 1);
      prev.push({
        id: current.doc.id,
        count: current.doc.data().count,
      });
    }
    if (current.type === 'removed') {
      const removedTargetIndex = prev.findIndex(
        prevCurrent => prevCurrent.id === current.doc.id,
      );
      prev.splice(removedTargetIndex, 1);
    }
    return prev;
  }, currentNotifications);
};

export default function reducer(state = initState, action: Actions) {
  if (!action) return state;

  switch (action.type) {
    case SET_MESSAGE_NOTIFICATION: {
      const messageNotifications = [...(state.messageNotifications ?? [])];
      if (!action.payload) {
        return state;
      }
      return {
        ...state,
        messageNotifications: updateNotifications(
          action.payload,
          messageNotifications,
        ),
      };
    }
    case CLEAR_NOTIFICATION: {
      return initState;
    }
    default:
      return state;
  }
}
