import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as PROTECTED_ROUTES from 'routes/constants/protected';
import apiInnovator from 'external/api/innovator';
import { MatterActions } from 'modules/matter';
import { MatterStatusId } from 'types/matter';
import { MATTER_STATUS_ID } from 'constants/matter';
import { checkShouldReviewProfile } from 'utils/matter';

const useReloadMatterStatus = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const reloadMatterStatus = useCallback(async () => {
    let data;
    try {
      ({ data } = await apiInnovator.getMatterStatus());
    } catch (e: unknown) {
      // Nothing to do.
      // eslint-disable-next-line no-console
      console.error(e);
      return;
    }
    dispatch(
      MatterActions.setMatterStatus({
        matterId: data.matterId ?? '',
        statusId: (data.statusId ?? MATTER_STATUS_ID.NONE) as MatterStatusId,
        registered: data.registered,
      }),
    );

    // Use the checkShouldReviewProfile util not the shouldReviewProfile in redux store
    // because the redux store shouldReviewProfile does not reflect the latest state in this function.
    const shouldReviewProfile = checkShouldReviewProfile({
      registered: data.registered,
      statusId: (data.statusId ?? MATTER_STATUS_ID.NONE) as MatterStatusId,
    });

    if (
      shouldReviewProfile &&
      !history.location.pathname.includes(PROTECTED_ROUTES.ACCOUNT_SETTING) &&
      !history.location.pathname.includes(PROTECTED_ROUTES.MESSAGES)
    ) {
      history.push(PROTECTED_ROUTES.REGISTER_PROFILE);
    }
  }, [dispatch, history]);

  return {
    reloadMatterStatus,
  };
};

export default useReloadMatterStatus;
