import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components/macro';
import media from 'styles/media';
import theme from 'styles/theme';
import * as MESSAGES from 'constants/messages';
import { ButtonM, Card, TextInput } from 'components/atoms';
import { Field } from 'components/molecules';
import useUnsubscribeEmail from './useUnsubscribeEmail';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 128px;

  ${media.smallMobile} {
    padding-top: 40px;
    padding-right: 4px;
    padding-left: 4px;
  }
`;

const Content = styled.div`
  margin: 0 auto;
`;

const Title = styled.p`
  margin-bottom: 20px;
  color: ${theme.basePrimary};
  font-size: 20px;
  line-height: 1.5;

  ${media.smallMobile} {
    margin-bottom: 16px;
    font-size: 16px;
  }
`;

const StyledCard = styled(Card)`
  width: 522px;
  padding: 40px 32px;

  ${media.smallMobile} {
    width: 100%;
    padding: 32px 12px;
  }
`;

const Form = styled.form``;

const Description = styled.p`
  color: ${theme.textBody};
  font-size: 16px;
  line-height: 1.5;
  word-wrap: break-word;
  white-space: pre-wrap;

  ${media.smallMobile} {
    font-size: 14px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const SubmitButton = styled(ButtonM)`
  ${media.smallMobile} {
    width: 100%;
  }
`;

const UnsubscribeEmail: FC = () => {
  const {
    formMethods,
    onSubmit,
    isDisabledSubmitButton,
    isCompleted,
  } = useUnsubscribeEmail();

  const { handleSubmit, register, errors, formState } = formMethods;
  const { touched } = formState;

  return (
    <>
      <Helmet>
        <title>{MESSAGES.HEAD.TITLE.UNSUBSCRIBE_EMAIL}</title>
      </Helmet>

      <Wrapper onSubmit={handleSubmit(onSubmit)}>
        <Content>
          <Title>{MESSAGES.UNSUBSCRIBE_EMAIL.TITLE}</Title>

          <StyledCard>
            {isCompleted ? (
              <Description data-testid="unsubscribe-email-completed">
                {MESSAGES.UNSUBSCRIBE_EMAIL.COMPLETED.DESCRIPTION}
              </Description>
            ) : (
              <Form>
                <Description style={{ marginBottom: '24px' }}>
                  {MESSAGES.UNSUBSCRIBE_EMAIL.FORM.DESCRIPTION}
                </Description>
                <Field
                  label={MESSAGES.UNSUBSCRIBE_EMAIL.FORM.FIELD.EMAIL}
                  required
                >
                  <TextInput
                    enterKeyHint="enter"
                    name="email"
                    error={touched.email && errors.email?.message}
                    ref={register}
                    aria-label={MESSAGES.UNSUBSCRIBE_EMAIL.FORM.FIELD.EMAIL}
                  />
                </Field>

                <ButtonWrapper>
                  <SubmitButton type="submit" disabled={isDisabledSubmitButton}>
                    {MESSAGES.UNSUBSCRIBE_EMAIL.FORM.BUTTON.SUBMIT}
                  </SubmitButton>
                </ButtonWrapper>
              </Form>
            )}
          </StyledCard>
        </Content>
      </Wrapper>
    </>
  );
};

export default UnsubscribeEmail;
